import React from "react";
import downloadIcon from "../assets/images/ship-download-document.png";
import Toast from "../components/Toast";

const ViewModal = ({
    showViewModal,
    modalheader,
    filePath,
    onCloseClick,
    onDownloadClick,
    toastVisible,
    type,
    message,
    closeToast
}) => {
    return (
        <section>
            <div className="supplier_on_hold">
                <div className="modal-backdrop fade show" style={{ display: showViewModal ? 'block' : 'none' }}></div>
                <div className="modal" id="view-document-Modal" style={{ display: showViewModal ? 'block' : 'none', zIndex: "999999" }} tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-box modal-xl">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">{modalheader}</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" onClick={onCloseClick} />
                                </button>
                            </div>
                            <div className="row content">
                                <div className="table-responsive" style={{ marginBottom: "20px" }}>
                                    <img
                                        className="download-cursor-class"
                                        src={downloadIcon}
                                        style={{cursor: "pointer", width:"28px"}}
                                        data-bs-toggle="modal"
                                        data-bs-target="#download-icon"
                                        onClick={onDownloadClick}
                                    />
                                    <br />
                                    {/* "https://docs.google.com/gview?url=" + filePath + "&embedded=true" */}
                                    <iframe
                                        src={filePath.includes(".docx") || filePath.includes(".xlsx") || filePath.includes(".xls") ? `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                                            filePath
                                        )}` : filePath}
                                        loading="eager"
                                        style={{ width: '100%', height: '70vh' }}></iframe>
                                </div>

                                {toastVisible && (
                                    <Toast
                                        toastVisible={toastVisible}
                                        type={type}
                                        message={message}
                                        closeToast={closeToast}
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default ViewModal;