import React from "react";
import { SettingsTab } from "../../helpers/enums";

const SettingsSideBar = ({ OnActiveTabChange, activeTab }) => {
  return (
    <div className="col-md-3 tab-right">
      <div className="tab">
        <button
          className={`tablinks activity-link-tab ${
            activeTab == SettingsTab.ACTIVITY_LOG ? "active" : ""
          }`}
          onClick={() => OnActiveTabChange(SettingsTab.ACTIVITY_LOG)}
          id="defaultOpen"
        >
          <img src="images/activity-log.png" alt="activity log" />
          <span>Activity Log</span>
        </button>
        <button
          className={`tablinks ${
            activeTab == SettingsTab.EMAIL_SETTINGS ? "active" : ""
          }`}
          onClick={() => OnActiveTabChange(SettingsTab.EMAIL_SETTINGS)}
        >
          <img src="images/email-setting.png" alt="email setting" />
          <span>Email Settings</span>
        </button>
        <button
          className={`tablinks ${
            activeTab == SettingsTab.SD_REGULATIONS ? "active" : ""
          }`}
          onClick={() => OnActiveTabChange(SettingsTab.SD_REGULATIONS)}
        >
          <img src="images/Sdoc-regulations.png" alt="Sdoc regulations" />
          <span>SDoC Regulations</span>
        </button>
        <button
          className={`tablinks ${
            activeTab == SettingsTab.REPORTS_SETTINGS ? "active" : ""
          }`}
          onClick={() => OnActiveTabChange(SettingsTab.REPORTS_SETTINGS)}
        >
          <img src="images/reports-settings.png" alt="report setting" />
          <span>Reports Settings</span>
        </button>
        <button
          className={`tablinks ${
            activeTab == SettingsTab.LOGIN_SETTINGS ? "active" : ""
          }`}
          onClick={() => OnActiveTabChange(SettingsTab.LOGIN_SETTINGS)}
        >
          <img src="images/login-settings.png" alt="login setting" />
          <span>Login Settings</span>
        </button>
      </div>
    </div>
  );
};

export default SettingsSideBar;
