import React, { Component } from "react";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { helperText } from "../../helpers/helperText";
import Loader from "../../components/Loader";
import DirtyModal from "../../components/DirtyModal";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import editIcon from "../../assets/images/profile-feather-edit.png";
import {
  generateRandomString,
  withRouter,
  isEmailValid,
  isValidFile,
  isPasswordValid,
  changePageTitle,
  AddLocalStorageData,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
} from "../../helpers";
import SupplierOnHold from "../Suppliers/SupplierOnHold.jsx";
import Toast from "../../components/Toast";
import Logout from "../../helpers/Logout";
import UserCredentials from "../../components/UserCredentials";
import {
  SupplierStatus,
  TitlePageNames,
  UserRole,
  UserAction,
} from "../../helpers/enums";
import { SupplierService } from "../../services/supplier.service";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Dropdown from "../../components/Dropdown";
import { CommonService } from "../../services/common.service";
import DeleteModal from "../../components/DeleteModel";
import ClientChangeRequestModal from "../ClientShipPortal/ClientChangeRequestModal.jsx";
import ProceedModal from "../../components/ProceedModal.jsx";
import ConfirmationModal from "../../components/ConfirmationModal.jsx";
import { UserService } from "../../services/user.service.js";
import addProfileIcon from "../../assets/images/plus.png";
import deleteIcon from "../../assets/images/delete icon.png";
import { UncontrolledTooltip } from "reactstrap";

const validFileFormats = ["png, jpg, jpeg"];
//const fileUrl = `${process.env.REACT_FILE_SERVER_URL}/ProfileImages/`;
const NUMBER_REGEX = /^[0-9()+-\s]+$/;
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";

class AddEditSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      errorMessages: {},
      userId: 0,
      supplierId: 0,
      userName: "",
      supplierPassword: "",
      profilePictureUrl: "",
      companyName: "",
      companyContact: "",
      branchName: "",
      faxNumber: "",
      supplierName: "",
      supplierEmail: "",
      supplierContact: "",
      division: "",
      supplierAddress: "",
      issuerName: "",
      issuerAddress: "",
      statusName: "",
      statusId: "",
      createdOn: "",
      digitalSignUrl: "",
      companyStampUrl: "",
      dropdown_clientList: [],
      clientList: [],
      dd_suppCompList: [
        {
          index: Math.random(),
          companyAddress: "",
          companyContactNo: "",
          companyDivision: "",
          companyEmailAddress: "",
          companyId: 0,
          companyName: "",
          supplierCompanyBridgeID: 0,
          isUpdated: false,
        },
      ],
      supplierList: [],
      originalSupplierList: [],
      companyContactNo: "",
      companyDivision: "",
      companyEmailAddress: "",
      companyAddress: "",
      documentSignFile: "",
      documentStampFile: "",
      documentProfileFile: "",
      fileSignName: "No File Chosen",
      fileStampName: "No File Chosen",
      fileProfileName: "No File Chosen",
      fileSignPath: "",
      fileStampPath: "",
      fileProfilePath: "",
      fileInputKey: generateRandomString(),
      showPassword: false,
      showOnHoldModal: false,
      onHoldFromDate: "",
      onHoldToDate: "",
      showErrorMessage: false,
      showInvalidEmailErrorMessage: false,
      usernameExistError: false,
      dropdown_supplierCompany: "",
      showDeleteModal: false,
      rowIdToDelete: 0,
      isSupplier: false,
      hideAddMoreButton: true,
      dataToBeDeleted: [],
      companyAlreadySelectedError: false,
      showSupplierRequestModal: false,
      showProceedModal: false,
      showChangePasswordConfirmationModal: false,
      typeId: 0,
      showOnHoldErrorMessage: false,
      onHoldSupplierLst: [],
      accessList: [],
      userRolePermissionRead: false,
      showDirtyPopup: false,
      path: "",
      showDeleteProfileModal: false,
      isProfileImgDeleted: false,
    };
  }

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: onLoad bind data of the supplier  
    <summary> */
  componentDidMount() {
    try {
      //HV - 20/02/2023 - changing browser title name
      changePageTitle(TitlePageNames.SUPPLIERDETAILS);
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      if (userData != null) {
        this.setState(
          {
            typeId: userData.typeId,
            isSupplier: userData.typeId == UserRole.SUPPLIER,
            userId: userData.id,
            accessList: GetLocalStorageDataWithParse("userRolePermission"),
          },
          () => {
            this.SupplierProfileDropdowns();
          }
        );

        let isAddSupplier =
          GetLocalStorageDataWithoutParse("selectedSupplier") === "" ? true : false;

        if (!isAddSupplier) {

          var editSupplierData = JSON.parse(
            GetLocalStorageDataWithoutParse("selectedSupplier")
          );
          this.BindSupplierProfileData(editSupplierData);
        } else {
          this.BindAddSupplierProfileData();
        }
      }
    } catch (error) { }
  }
  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState(
      {
        showDirtyPopup: false,
      },
      () => {
        AddLocalStorageData("isDirty", false);
        AddLocalStorageData("location", null);
        if (this.state.path != "") this.props.navigate(this.state.path);
      }
    );
  };
  SupplierProfileDropdowns = async () => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.supplierAccess == UserAction.READ,
      });
      //save the status of the supplier in db
      const response = await SupplierService.GetSupplierClientData(
        this.state.userId,
        this.state.supplierId,
        this.state.typeId
      );


      if (response.data != null && response.data != undefined) {
        const dd_suppCompList = this.state.dd_suppCompList;
        const sortedSupplierList = response.data.supplierList.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        const updatedSupplierList = dd_suppCompList.length > 0 ? (sortedSupplierList.filter(
          (supplier) => !dd_suppCompList.some((ddSupplier) => ddSupplier.companyId === supplier.value)
        )) : sortedSupplierList;
        this.setState({
          clientList: response.data.clientList.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
          originalSupplierList: sortedSupplierList,
          supplierList: updatedSupplierList,
          onHoldSupplierLst: response.data.supplierOnHoldStatusData,
        });
      } else {

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.setState({ isLoading: false });
    } catch (error) { }
  };

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: Show hide password on eye icon click
    <summary> */
  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: On add new supplier, pre fill creation date, password, set status as Active  
    <summary> */
  BindAddSupplierProfileData = () => {
    this.setState({
      createdOn: convertDate(new Date()),
      statusId: parseInt(SupplierStatus.ACTIVE, 10),
    });
    this.onChangePasswordClick(null);
    //this.getSystemGeneratedPasswordForNewUser();
  };

  getManageSupplierData = async () => {
    const { supplierId } = this.state;
    const response = await SupplierService.GetManageSupplierData(
      supplierId,
      this.state.userId
    );
    if (response.data != undefined) {
      //SYJ - 21/08/2023 - Removed the !isStringEmpty(response.data) as after save all fields were getting disabled
      let supplierData = response.data.length > 0 && response.data.filter(
        (s) => s.supplierId === supplierId
      );
      await this.BindSupplierProfileData(supplierData[0]);
    }
  };

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: On edit supplier show data of the supplier 
    <summary> */
  BindSupplierProfileData = async (supplierData) => {
    try {
      this.setState({
        isLoading: true,
      });
      if (supplierData != null) {
        var companyDetails = [];
        supplierData.assignedSuppliers.forEach((x) => {
          var objComponenet = {
            companyAddress: x.companyAddress,
            companyContactNo: x.companyContactNo,
            companyDivision: x.companyDivision,
            companyEmailAddress: x.companyEmailAddress,
            companyId: x.companyId,
            companyName: x.companyName,
            supplierCompanyBridgeID: x.supplierCompanyBridgeID,
            index: Math.random(),
            isUpdated: false,
          };
          companyDetails.push(objComponenet);
        });

        const dd_suppCompList = companyDetails;
        const sortedSupplierList = this.state.originalSupplierList.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        const updatedSupplierList = dd_suppCompList.length > 0 ? (sortedSupplierList.filter(
          (supplier) => !dd_suppCompList.some((ddSupplier) => ddSupplier.companyId === supplier.value)
        )) : sortedSupplierList;

        this.setState(
          {
            supplierId: supplierData.supplierId,
            userName: supplierData.username,
            supplierPassword: supplierData.password,
            profilePictureUrl: supplierData.profilePictureUrl,
            companyName:
              supplierData.companyName == null ? "" : supplierData.companyName,
            companyContact:
              supplierData.companyContactNo == null
                ? ""
                : supplierData.companyContactNo,
            branchName:
              supplierData.companyBranchName == null
                ? ""
                : supplierData.companyBranchName,
            faxNumber:
              supplierData.faxNumber == null ? "" : supplierData.faxNumber,
            supplierName:
              supplierData.supplierName == null
                ? ""
                : supplierData.supplierName,
            supplierEmail:
              supplierData.emailAddress == null
                ? ""
                : supplierData.emailAddress,
            supplierContact:
              supplierData.supplierContactNo == null
                ? ""
                : supplierData.supplierContactNo,
            division:
              supplierData.division == null ? "" : supplierData.division,
            supplierAddress:
              supplierData.supplierAddress == null ? "" : supplierData.division,
            issuerName:
              supplierData.issuerName == null ? "" : supplierData.issuerName,
            issuerAddress:
              supplierData.issuerAddress == null
                ? ""
                : supplierData.issuerAddress,
            statusName:
              supplierData.statusName == null ? "" : supplierData.statusName,
            statusId: supplierData.statusId,
            createdOn: supplierData.createdOn,
            digitalSignUrl: supplierData.digitalSignatureUrl,
            companyStampUrl: supplierData.companyStampUrl,
            dropdown_clientList: supplierData.assignedClients,
            supplierList: updatedSupplierList,
            dd_suppCompList:
              companyDetails.length == 0
                ? this.state.dd_suppCompList
                : companyDetails,
          }
        );
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };


  /* <summary>
    date: 27/11/2022
    Name: DN
    description: on Click password Change get new password
    <summary> */
  onChangePasswordClick = async (setUsername) => {
    try {
      this.setState({
        isLoading: true,
      });

      var userData = GetLocalStorageDataWithParse("loggedInUser");
      var roleid = 0;
      var username = "";
      if (userData != null) {
        roleid = userData.typeId;
        username = userData.name;
      }
      var response = await SupplierService.GenerateNewPassword(
        this.state.supplierId,
        roleid,
        username
      );
      if (response == undefined) {

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          showChangePasswordConfirmationModal: false,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          this.setState(
            {
              supplierPassword: response.data,
              showChangePasswordConfirmationModal: false,
              toastVisible: setUsername != null ? true : false,
              toastType: helperText.global.toastSuccess,
              toastMessage: helperText.Supplier.supplierUpdated,
            },
            () => { }
          );
        }
      } else {

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          showChangePasswordConfirmationModal: false,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
    date: 09/01/2023
    Name: SYJ
    description: Validate fields on Save Click
    <summary> */
  isValidate = () => {
    try {
      const {
        userName,
        supplierName,
        supplierEmail,
        division,
        errorMessages,
        supplierPassword,
      } = this.state;
      let hasErrors = false;
      let hasSupNameErrors = false;
      let errors = errorMessages;
      let hasInvalidEmailErrors = false;

      var suppName = "";
      suppName = supplierName;
      // this.setState({ isLoading: true });

      if (isStringEmpty(userName)) {
        hasErrors = true;
        errors["userNameValidation"] = helperText.Supplier.userNameValidation;
      } else {
        hasErrors = false;
        errors["userNameValidation"] = "";
        this.setState({ showErrorMessage: false });
      }

      if (
        isStringEmpty(supplierPassword) ||
        supplierPassword.length < 8 ||
        !isPasswordValid(supplierPassword)
      ) {
        hasErrors = true;
        errors["passwordValidation"] = helperText.Supplier.errorPassword;
      } else {
        errors["passwordValidation"] = "";
        this.setState({ showErrorMessage: false });
      }

      if (isStringEmpty(supplierName)) {
        hasErrors = true;
        hasSupNameErrors = false;
        errors["supplierNameValidation"] =
          helperText.Supplier.supplierNameValidation;
      }
      else if (!isStringEmpty(supplierName) && suppName.trim().length < 3) {
        hasSupNameErrors = true;
        errors["supplierNameValidation"] =
          helperText.Supplier.supplierNameValidation;
        this.setState({
          showErrorMessage: false,
          shortEmployeeNameError: true,
        });
      }
      else {
        hasSupNameErrors = false;
        errors["supplierNameLength"] = "";
        errors["supplierNameValidation"] = "";
        this.setState({ showErrorMessage: false, shortEmployeeNameError: false });
      }

      if (isStringEmpty(supplierEmail)) {
        hasErrors = true;
        this.setState({ showInvalidEmailErrorMessage: false });
        errors["emailValidation"] = helperText.Supplier.emailValidation;
      } else if (!isEmailValid(supplierEmail)) {
        hasInvalidEmailErrors = true;
        hasErrors = false;
        errors["emailValidation"] = helperText.ContactUs.wrongEmail;
      } else {
        errors["emailValidation"] = "";
        this.setState({
          showErrorMessage: false,
          showInvalidEmailErrorMessage: false
        });
      }

      if (isStringEmpty(division)) {
        hasErrors = true;
        errors["divisionValidation"] = helperText.Supplier.contactValidation;
      } else {
        errors["divisionValidation"] = "";
        this.setState({ showErrorMessage: false });
      }

      this.setState({ errorMessages: errors });

      if (hasSupNameErrors) {
        this.setState({ shortEmployeeNameError: true });
      }
      else {
        this.setState({ shortEmployeeNameError: false });
      }

      if (hasErrors) {
        this.setState({ showErrorMessage: true });
      }
      else {
        this.setState({ showErrorMessage: false });
      }

      if (hasSupNameErrors) {
        hasErrors = true;
      }

      if (hasInvalidEmailErrors) {
        hasErrors = true;
        this.setState({ showInvalidEmailErrorMessage: true });
      }
      return hasErrors;
    } catch (error) { }
  };

  isValidateOnHoldModal = () => {
    const { onHoldFromDate, onHoldToDate, errorMessages } = this.state;

    let hasErrors = false;
    let errors = errorMessages;

    errors["onHoldFromDateValidation"] = onHoldFromDate == "" ? "true" : "";
    errors["onHoldToDateValidation"] = onHoldToDate == "" ? "true" : "";

    if (
      errors["onHoldFromDateValidation"] != "" ||
      errors["onHoldToDateValidation"] != ""
    ) {
      hasErrors = true;
      this.setState({ showOnHoldErrorMessage: true, isLoading: false });
    } else {
      hasErrors = false;
      this.setState({ showOnHoldErrorMessage: false });
    }

    this.setState({ errorMessages: errors });
    return hasErrors;
  };

  onSendEmail = async () => {
    const { supplierId } = this.state;
    if (supplierId != 0) {
      this.setState({ isLoading: true });
      var response = await CommonService.SendEmailToUser(
        supplierId,
        UserRole.SUPPLIER
      );
      if (response != null && response != undefined && response.status == 200) {
        var emailSent = response.data;

        if (emailSent == true) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.emailSent,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          });
        } else {

          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            isLoading: false,
          });
        }
      }
    }
  };

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: On save the edited or new data of the supplier
    <summary> */
  async onSaveClick() {
    try {
      const {
        supplierId,
        userName,
        supplierPassword,
        companyName,
        companyContact,
        branchName,
        faxNumber,
        supplierName,
        supplierEmail,
        supplierContact,
        division,
        supplierAddress,
        issuerName,
        issuerAddress,
        statusId,
        createdOn,
        userId,
        fileSignName,
        documentSignFile,
        documentStampFile,
        documentProfileFile,
        dropdown_clientList,
        dd_suppCompList,
        companyContactNo,
        companyDivision,
        companyEmailAddress,
        companyAddress,
        errorMessages,
        typeId,
        isSupplier,
        isProfileImgDeleted,
        originalSupplierList,
      } = this.state;


      this.setState({ isLoading: true });

      //only save if there are no validation errors
      if (!this.isValidate()) {
        let errors = errorMessages;
        var data = {
          userName: userName,
          tableName: "Suppliers",
          userId: supplierId,
        };

        var responeData = await CommonService.CheckIfUserNameExist(data);
        if (
          responeData != null &&
          responeData != undefined &&
          responeData.status == 200
        ) {
          var usernameExist = responeData.data;
          if (usernameExist == true) {
            errors["userNameValidation"] = "true";
            this.setState({
              errorMessages: errors,
              usernameExistError: usernameExist,
              isLoading: false,
            });
            return;
          }
        }

        var suppCompList = dd_suppCompList
          .map((x) => {
            if (x.companyId != 0) return x;
          })
          .filter((x) => x != undefined);

        let supplierData = new FormData();
        supplierData.append("typeId", typeId);
        supplierData.append("SupplierId", supplierId);
        supplierData.append("Username", userName.trim());
        supplierData.append("Password", supplierPassword.trim());
        supplierData.append("CompanyName", companyName.trim());
        supplierData.append("CompanyContactNo", companyContact.trim());
        supplierData.append("CompanyBranchName", division.trim());
        supplierData.append("FaxNumber", faxNumber.trim());
        supplierData.append("SupplierName", supplierName.trim());
        supplierData.append("EmailAddress", supplierEmail.trim());
        supplierData.append("SupplierContactNo", supplierContact.trim());
        supplierData.append("Division", division.trim());
        supplierData.append("SupplierAddress", supplierAddress.trim());
        supplierData.append("IssuerName", issuerName.trim());
        supplierData.append("IssuerAddress", issuerAddress.trim());
        supplierData.append("StatusId", statusId);
        supplierData.append(
          "ClientAssigned",
          JSON.stringify(dropdown_clientList)
        );
        // supplierData.append("SupplierCompany",JSON.stringify(dropdown_supplierCompanyList));
        supplierData.append("SupplierCompany", JSON.stringify(suppCompList));
        supplierData.append("CreatedOn", createdOn);
        supplierData.append("CreatedBy", userId);
        supplierData.append("EditedBy", userId);
        let fileProfile = documentProfileFile;

        if (
          fileProfile != "" && fileProfile != null &&
          fileProfile.size !== null &&
          fileProfile.size !== undefined
        ) {
          supplierData.append("ProfilePicAttached", fileProfile);
          supplierData.append("profilePictureExists", false);
        } else if (fileProfile == "" && this.state.profilePictureUrl != "") {
          supplierData.append("profilePictureExists", true);
        } else supplierData.append("profilePictureExists", false);

        let fileSignature = documentSignFile;

        if (
          fileSignature != "" &&
          fileSignature.size !== null &&
          fileSignature.size !== undefined
        ) {
          supplierData.append("DigiSignatureAttached", fileSignature);
          supplierData.append("DigitalSignatureExists", false);
        } else if (fileSignature == "" && this.state.digitalSignUrl != "") {
          supplierData.append("DigitalSignatureExists", true);
        } else supplierData.append("DigitalSignatureExists", false);

        let fileStamp = documentStampFile;
        if (
          fileStamp != "" &&
          fileStamp.size !== null &&
          fileStamp.size !== undefined
        ) {
          supplierData.append("CompStampAttached", fileStamp);
          supplierData.append("CompStampExists", false);
        } else if (fileStamp == "" && this.state.companyStampUrl != "") {
          supplierData.append("CompStampExists", true);
        } else supplierData.append("CompStampExists", false);

        if (isProfileImgDeleted) {
          this.deleteSupplierProfileImage(this.state.supplierId, UserRole.SUPPLIER, this.state.userId);
          this.setState({
            isProfileImgDeleted: false
          })
        }

        var responseFilters = await SupplierService.SaveSupplierProfileData(
          supplierData
        );

        if (responseFilters != null && responseFilters != undefined) {
          if (responseFilters.data.errorMessage != null) {
            this.setState({
              isLoading: false,
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: responseFilters.data.errorMessage,
            });
          }
          else {
            AddLocalStorageData("isDirty", false);
            this.setState(
              {
                supplierId: responseFilters.data.supplierId,
              },
              async () => {
                await this.SupplierProfileDropdowns();
                await this.getManageSupplierData();
                this.setState({
                  isLoading: false,
                  toastVisible: true,
                  toastType: helperText.global.toastSuccess,
                  toastMessage:
                    supplierId === 0
                      ? helperText.Supplier.supplierAdded
                      : helperText.Supplier.supplierUpdated,
                });
              }
            );
          }
        }
        else {
          this.setState({
            isLoading: false,
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
          });
        }
      }


      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  }

  onClientAssignedDropdownChange = (option) => {
    var dropdown_client = [];
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_client.push(item);
        });
    }
    AddLocalStorageData("isDirty", true);
    this.setState({ dropdown_clientList: dropdown_client });
  };

  /* <summary>
   date: 14/02/2023
   Name: HV
   description: on username change
   <summary> */

  onUsernameChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage, errorMessages } = this.state;
    let errors = errorMessages;
    AddLocalStorageData("isDirty", true);
    this.setState({
      [name]: value,
      usernameExistError: false,
    });
    errors["userNameValidation"] = "";
  };

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: On text change of any field change set the typed in data
    <summary> */
  onTextChange = (e) => {
    //SYJ -05/01/2023- Validate the field on change.
    const { errorMessages, shortEmployeeNameError } = this.state;
    const { name, value } = e.target;
    const txtName = e.target.name;

    let errors = errorMessages;
    if (txtName === "supplierPassword") {
      // this.setState({
      //   [name]: value,
      // });
      errors["passwordValidation"] = "";
    } else if (txtName === "supplierName") {
      // this.setState({
      //   [name]: value,
      // });
      errors["supplierNameValidation"] = "";
    } else if (txtName === "supplierEmail") {
      // this.setState({
      //   [name]: value,
      // });
      errors["emailValidation"] = "";
    } else if (txtName === "division") {
      // this.setState({
      //   [name]: value,
      // });
      errors["divisionValidation"] = "";
    } //else {
    // this.setState({
    //   [name]: value,
    // });
    //  }

    this.setState(
      {
        [name]: value,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (
          this.state.showErrorMessage ||
          this.state.showInvalidEmailErrorMessage ||
          shortEmployeeNameError
        )
          this.isValidate();
      }
    );
    // if (
    //   errors["userNameValidation"] === "" &&
    //   errors["passwordValidation"] === "" &&
    //   errors["supplierNameValidation"] === "" &&
    //   errors["emailValidation"] === "" &&
    //   errors["divisionValidation"] === ""
    // ) {
    //   this.setState({ showErrorMessage: false });
    // }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  onAttachmentChange = async (e) => {
    const { name, value } = e.target;
    if (!isValidFile(value, validFileFormats)) {
      if (this.state.fileProfilePath == "") {
        AddLocalStorageData("isDirty", true);
        this.setState({
          fileInputKey: generateRandomString(),
          [name]: null,
          errorFile: true,
        });
      }

      //HV - 06/06/2023 - if after attaching correct file, wrong file is attached then retain the older file but show error message
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.wrongFileTypeError,
        toastType: helperText.global.toastError,
      });

      return;
    }

    let files = e.target.files[0];
    AddLocalStorageData("isDirty", true);
    this.setState({
      errorFile: false,
      [name]: files,
    });

    if (name === "documentProfileFile") {
      this.setState({
        fileProfileName: files.name,
        fileProfilePath: value,
        isProfileImgDeleted: false,
        profilePictureUrl:
          URL.createObjectURL(
            files
          ) /*SYJ - 09/01/2023 - set image URL to State*/,
      });
    } else if (name === "documentSignFile") {
      this.setState({
        fileSignName: files.name,
        fileSignPath: value,
        digitalSignUrl:
          URL.createObjectURL(
            files
          ) /*SYJ - 09/01/2023 - set image URL to State*/,
      });
    } else if (name === "documentStampFile") {
      this.setState({
        fileStampName: files.name,
        fileStampPath: value,
        companyStampUrl:
          URL.createObjectURL(
            files
          ) /*SYJ - 09/01/2023 - set image URL to State*/,
      });
    }
  };

  /* <summary>
     date: 28/11/2022
     Name: DN
     description: On status dropdown change save the new assigned status and bind the grid again. If Onhold clicked set hold time for supplier
     <summary> */
  onStatusChanged = async (e) => {
    var statusId = e.target.value;
    AddLocalStorageData("isDirty", true);
    if (parseInt(statusId, 10) == parseInt(SupplierStatus.ONHOLD, 10)) {
      this.setState({
        onHoldFromDate: "",
        onHoldToDate: "",
        comments: "",
        showOnHoldModal: true,
        errorMessages: {},
      });
    } else {
      this.setState({ showOnHoldModal: false });
    }

    this.setState({
      statusId: parseInt(statusId, 10),
    });
  };

  /* <summary>
   date: 28/11/2022
   Name: DN
   description: When supplier is put on hold save the date and time of the onhold period
   <summary> */
  onHoldSaveClicked = async (supplierId) => {
    const {
      onHoldFromDate,
      onHoldToDate,
      comments,
      userId,
      isSupplier,
      typeId,
    } = this.state;

    this.setState({
      isLoading: true,
    });

    if (!this.isValidateOnHoldModal()) {
      let supplierData = new FormData();
      supplierData.append("IsSupplier", isSupplier);
      supplierData.append("SupplierId", supplierId);
      supplierData.append("FromDate", convertDate(onHoldFromDate));
      supplierData.append("ToDate", convertDate(onHoldToDate));
      supplierData.append("Comment", comments);
      supplierData.append("EditedBy", userId);
      supplierData.append("typeId", typeId);

      var response = await SupplierService.SetSupplierOnHold(supplierData);

      if (response == undefined || response == null) {

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        this.setState(
          {
            statusId: parseInt(SupplierStatus.ONHOLD, 10),
            showOnHoldModal: false,
            toastVisible: true,
            toastType: helperText.global.toastSuccess,
            toastMessage: helperText.Supplier.setOnHold,
            showOnHoldErrorMessage: false,
          },
          () => {
            this.SupplierProfileDropdowns(); //SYJ 17/04/2023 - Show/Hide From,To Date after Save
          }
        );
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  /* <summary>
    date: 28/11/2022
    Name: DN
    description: From Date selected from on hold modal to be saved in supplierOnHold table
    <summary> */
  onHoldFromDateChanged = (e) => {
    this.setState(
      {
        onHoldFromDate: e,
      },
      () => {
        this.isValidateOnHoldModal();
      }
    );
  };

  /* <summary>
    date: 28/11/2022
    Name: DN
    description: To Date selected from on hold modal to be saved in supplierOnHold table
    <summary> */
  onHoldToDateChanged = (e) => {
    this.setState(
      {
        onHoldToDate: e,
      },
      () => {
        this.isValidateOnHoldModal();
      }
    );
  };

  goback = () => {
    var isDirty = GetLocalStorageDataWithoutParse("isDirty");
    if (isDirty === "true") {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (this.state.isSupplier) {
        if (loc == "/ActivityLog") {
          this.setState({ path: "/ActivityLog" });
        } else {
          this.setState({ path: "/SupplierDashboard" });
        }
      } else {
        if (loc == "/ActionRequired") {
          this.setState({ path: "/ActionRequired" });
        } else if (loc == "/ActivityLog") {
          this.setState({ path: "/ActivityLog" });
        } else if (loc == "/TrackingTool") {
          this.setState({ path: "/TrackingTool" });
        } else {
          this.setState({ path: "/ManageSuppliers" });
        }
      }
      this.setState({ showDirtyPopup: true });
    } else {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (this.state.isSupplier) {
        if (loc == "/ActivityLog") {
          this.props.navigate("/ActivityLog");
        } else {
          this.props.navigate("/SupplierDashboard");
        }
      } else {
        if (loc == "/ActionRequired") {
          this.props.navigate("/ActionRequired");
        } else if (loc == "/ActivityLog") {
          this.props.navigate("/ActivityLog");
        } else if (loc == "/TrackingTool") {
          this.props.navigate("/TrackingTool");
        } else {
          this.props.navigate("/ManageSuppliers");
        }
      }
      AddLocalStorageData("location", null);
    }
  };

  // onDropDownSelected = (e) => {
  //   var { name, value } = e.target;
  //   this.setState(
  //     {
  //       [name]: value,
  //       dropdown_supplierCompany: value,
  //     },
  //     async () => {
  //       AddLocalStorageData("isDirty", true);
  //       if (parseInt(value, 10) > 0) {
  //         var supplierData = this.state.supplierList.filter(
  //           (t) => t.value === parseInt(value, 10)
  //         );
  //         var dropdown_suppCompList = [];

  //         if (supplierData.length > 0) {
  //           await this.getSupplierData(parseInt(value, 10));
  //         }
  //       }
  //     }
  //   );
  // };

  onDropDownSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
        dropdown_supplierCompany: value,
      },
      async () => {
        AddLocalStorageData("isDirty", true);
        if (parseInt(value, 10) > 0) {
          var supplierData = this.state.supplierList.filter(
            (t) => t.value === parseInt(value, 10)
          );

          // Filter out the selected supplier from the supplierList
          var updatedSupplierList = this.state.supplierList.filter(
            (t) => t.value !== parseInt(value, 10)
          );

          if (supplierData.length > 0) {
            await this.getSupplierData(parseInt(value, 10));
          }

          // Update the state with the filtered supplier list
          this.setState({
            supplierList: updatedSupplierList,
          });
        }
      }
    );
  };

  getSupplierData = async (suppID) => {
    try {
      this.setState({ isLoading: true });
      //save the status of the supplier in db
      const response = await SupplierService.GetSupplierData(suppID);

      if (response.data != null && response.data != undefined) {
        var companyList = this.state.dd_suppCompList.filter(
          (t) => t.companyId != 0
        );
        var companyDetails = [];

        var objComponent = {
          companyAddress: response.data.companyAddress,
          companyContactNo: response.data.companyContactNo,
          companyDivision: response.data.companyDivision,
          companyEmailAddress: response.data.companyEmailAddress,
          companyId: response.data.companyId,
          companyName: response.data.companyName,
          supplierCompanyBridgeID: response.data.supplierCompanyBridgeID,
          index: Math.random(),
        };
        var dd_suppCompList = companyList.push(objComponent);

        this.setState({
          dd_suppCompList: companyList,
          hideAddMoreButton: false,
        });
      } else {

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.setState({ isLoading: false });
    } catch (error) { }
  };

  onAddMoreClick = () => {
    this.setState((prevState) => ({
      dd_suppCompList: [
        ...prevState.dd_suppCompList,
        {
          companyAddress: "",
          companyContactNo: "",
          companyDivision: "",
          companyEmailAddress: "",
          companyId: 0,
          companyName: "",
          supplierCompanyBridgeID: 0,
          index: Math.random(),
        },
      ],
      hideAddMoreButton: true,
    }));
  };

  /* <summary>
    date: 4/3/2023
    Name: DP
    description: passed data to be deleted and even delete modal displayed 
    <summary> */
  deleteCompany = (data) => {
    this.setState({
      showDeleteModal: true,
      dataToBeDeleted: data,
      hideAddMoreButton: false,
    });
  };

  onDeleteModalYesClick = () => {
    this.deleteCompanyDetails(this.state.dataToBeDeleted);
  };

  onDeleteModalCloseClick = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  /* <summary>
    date: 4/3/2023
    Name: DP
    description: checked if data added and user wants to delete it or data already present than delete from backend  
    <summary> */
  deleteCompanyDetails = async (dataToBeDeleted) => {
    try {
      this.setState({ isLoading: true });

      if (
        this.state.dd_suppCompList.length == 1 &&
        dataToBeDeleted.supplierCompanyBridgeID == 0
      ) {
        this.setState({
          dd_suppCompList: [
            {
              index: Math.random(),
              companyAddress: "",
              companyContactNo: "",
              companyDivision: "",
              companyEmailAddress: "",
              companyId: 0,
              companyName: "",
              supplierCompanyBridgeID: 0,
            },
          ],
          showDeleteModal: false,
          isLoading: false,
        });
      } else {
        if (dataToBeDeleted.supplierCompanyBridgeID == 0) {
          var companyFilterList = this.state.dd_suppCompList.filter(
            (item) => item.index !== dataToBeDeleted.index
          );
          this.setState({
            dd_suppCompList: companyFilterList,
            showDeleteModal: false,
            isLoading: false,
          });
        } else {
          var existingCompanyFilterList = this.state.dd_suppCompList.filter(
            (item) =>
              item.supplierCompanyBridgeID !==
              dataToBeDeleted.supplierCompanyBridgeID
          );
          this.setState(
            {
              dd_suppCompList:
                existingCompanyFilterList.length == 0
                  ? [
                    {
                      index: Math.random(),
                      companyAddress: "",
                      companyContactNo: "",
                      companyDivision: "",
                      companyEmailAddress: "",
                      companyId: 0,
                      companyName: "",
                      supplierCompanyBridgeID: 0,
                    },
                  ]
                  : existingCompanyFilterList,
              showDeleteModal: false,
            },
            async () => {
              //save the status of the supplier in db
              const response = await SupplierService.DeleteSupplierCompanyData(
                dataToBeDeleted.supplierCompanyBridgeID
              );
              if (response.data === null && response.data === undefined) {

                this.setState({
                  toastVisible: true,
                  toastType: helperText.global.toastError,
                  toastMessage: helperText.global.somethingWentWrong,
                });
              } else {
                this.BindSupplierProfileData(response.data);
              }
              this.setState({ isLoading: false });
            }
          );
        }
      }
    } catch (error) { }
  };

  onRequestNewPasswordClick = () => {
    this.setState({
      showSupplierRequestModal: true,
      requestType: "Change Password",
    });
  };

  onCloseChangeRequestModalClick = () => {
    this.setState({ showSupplierRequestModal: false, requestType: "" });
  };

  onShowProceedModalClick = () => {
    this.setState({ showProceedModal: true });
  };

  onProceeModalCloseClick = () => {
    this.setState({
      showProceedModal: false,
    });
  };

  SaveSupplierChangeRequestData = async () => {
    const { supplierId, userId, requestType } = this.state;

    this.setState({ isLoading: true, showProceedModal: false });
    let supplierChangeRequestData = new FormData();
    supplierChangeRequestData.append("RequestType", requestType);
    supplierChangeRequestData.append("EditedBy", userId);
    supplierChangeRequestData.append("SupplierId", supplierId);
    supplierChangeRequestData.append("ModuleName", "Supplier");
    supplierChangeRequestData.append("NavigateTo", "Supplier Details");

    var responseSaved = await SupplierService.SaveSupplierChangeRequestData(
      supplierChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.Supplier.changeRequestSent,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        },
        () => {
          this.onCloseChangeRequestModalClick();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {

      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  onPhoneNumberChange = (e) => {
    const { name, value } = e.target;

    if (value.trim() == "") {
      AddLocalStorageData("isDirty", true);
      this.setState({
        [name]: value,
      });
    }

    if (NUMBER_REGEX.test(value)) {
      AddLocalStorageData("isDirty", true);
      this.setState({
        [name]: value,
      });
    }
  };

  /* <summary>
  date: 11/3/2023
  Name: DP
  description: if company address must be editable and if change must be updated
  <summary> */
  onCompanyAddressChange = (i, e) => {
    let dd_suppCompValues = [...this.state.dd_suppCompList];
    this.state.dd_suppCompList[i][e.target.name] = e.target.value;
    this.state.dd_suppCompList[i]["isUpdated"] = true;
    AddLocalStorageData("isDirty", true);
    this.setState({
      dd_suppCompList: dd_suppCompValues,
    });
  };

  toggleModal = () => {
    this.setState({
      showOnHoldModal: false,
      showOnHoldErrorMessage: false,
      onHoldFromDate: "",
      onHoldToDate: "",
    });
  };

  showChangePasswordConfirmationModal = () => {
    this.setState({
      showChangePasswordConfirmationModal: true,
    });
  };

  onModalCloseClick = () => {
    this.setState({
      showChangePasswordConfirmationModal: false,
    });
  };

  /* <summary>
   date: 19/06/2023
   Name: SYJ
   description: Delete profile image
   <summary> */
  onDeleteProfilePic = () => {
    if (!this.state.userRolePermissionRead) {
      this.setState({
        showDeleteProfileModal: true,
      });
    }
  };

  onDeleteProfileModalYesClick = () => {
    AddLocalStorageData("isDirty", true);
    this.setState({
      profilePictureUrl: null,
      isProfileImgDeleted: true,
      showDeleteProfileModal: false,
      toastVisible: true,
      toastMessage: helperText.global.deletedProfile,
      toastType: helperText.global.toastSuccess,
    })
  };

  onDeleteProfileModalCloseClick = () => {
    this.setState({
      showDeleteProfileModal: false,
      isProfileImgDeleted: false
    });
  };

  deleteSupplierProfileImage = async (userId, userTypeId, loggedUserId) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteProfileModal: false,
      });
      var response = await UserService.DeleteProfileImage(userId, userTypeId, loggedUserId);
      if (response != undefined) {
        if (userTypeId == UserRole.SUPPLIER && this.state.userId != 1 && this.state.userId != 2 && this.state.userId != 3 && this.state.userId != 4
          && this.state.userId != 5 && this.state.userId != 7) {
          AddLocalStorageData("loggedInUserPic", null);
        }
        this.getManageSupplierData();
        this.setState(
          {
            profilePictureUrl: null,
            documentProfileFile: null,
            fileInputKey: generateRandomString(),
            // toastVisible: true,
            // toastMessage: helperText.global.deletedProfile,
            // toastType: helperText.global.toastSuccess,
          });
      } else {

        this.setState({
          toastVisible: true,
          isLoading: false,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      showPassword,

      supplierId,
      userName,
      supplierPassword,
      profilePictureUrl,
      companyName,
      companyContact,
      branchName,
      faxNumber,
      supplierName,
      supplierEmail,
      supplierContact,
      division,
      supplierAddress,
      issuerName,
      issuerAddress,
      statusName,
      statusId,
      createdOn,
      digitalSignUrl,
      companyStampUrl,

      dropdown_clientList,
      clientList,
      //  dropdown_supplierCompanyList,
      supplierList,
      dropdown_supplierCompany,
      dd_suppCompList,

      fileSignName,
      fileStampName,
      fileInputKey,
      showOnHoldModal,
      onHoldFromDate,
      onHoldToDate,
      comments,

      errorMessages,
      showErrorMessage,
      showInvalidEmailErrorMessage,
      usernameExistError,
      showDeleteModal,
      hideAddMoreButton,
      dataToBeDeleted,
      isSupplier,
      showSupplierRequestModal,
      showProceedModal,
      showOnHoldErrorMessage,
      showChangePasswordConfirmationModal,
      onHoldSupplierLst,
      shortEmployeeNameError,
      showDeleteProfileModal
    } = this.state;
    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header />

        <SupplierOnHold
          onCloseClick={this.toggleModal}
          showModal={showOnHoldModal}
          onSaveClicked={() => this.onHoldSaveClicked(supplierId)}
          fromDate={onHoldFromDate}
          toDate={onHoldToDate}
          fromDateChanged={(e) => this.onHoldFromDateChanged(e)}
          toDateChanged={(e) => this.onHoldToDateChanged(e)}
          comments={comments}
          onCommentsChange={(e) => this.onTextChange(e)}
          showOnHoldErrorMessage={showOnHoldErrorMessage}
          errorMessages={errorMessages}
        />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div className="admin-main-body">
          <div className="profile-page-header">
            <div className="container">
              <div className="admin-profile-detail-back">
                <img
                  src="images/back button image.png"
                  alt="Back"
                  height="22px"
                  style={{ pointerEvents: "all" }}
                  onClick={this.goback}
                />
                <h5>Supplier Profile Details</h5>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="admin-supplier-profile-detail-img edit-supplier-profile-img">
                    <div className="admin-supplier-profile-detail-bg-img">
                      {profilePictureUrl == "" ||
                        profilePictureUrl == undefined ? (
                        <img
                          src={"images/add-photo.png"}
                          className="admin-supplier-profile"
                          style={{ objectFit: "contain" }} //SYJ - Fit image to the Contain Fix for https://jiraapp.edotteam.com/browse/IHMM-114
                        />
                      ) : (
                        <img
                          src={profilePictureUrl}
                          style={{ objectFit: "contain" }}
                          className="admin-supplier-profile"
                        />
                      )}

                      {profilePictureUrl != "" && profilePictureUrl != undefined && profilePictureUrl != null ? (
                        <div className="admin-profile-icon">
                          <label style={{ left: "66%", top: "53%" }}>
                            <img
                              title={"Delete"}
                              src={deleteIcon}
                              alt="profile-delete"
                              className="img-fluid cursor-class test-Pankaj"
                              style={{ width: "15px", height: "20px", opacity: this.state.userRolePermissionRead ? "0.6" : "1" }}
                              onClick={this.onDeleteProfilePic}
                              disabled={this.state.userRolePermissionRead}
                            />
                          </label>
                        </div>) : null}
                      <div className="admin-supplier-profile-icon">
                        <label htmlFor="myfile">
                          <img
                            title={
                              profilePictureUrl == "" ||
                                profilePictureUrl == undefined
                                ? "Add"
                                : "Edit"
                            }
                            src={
                              profilePictureUrl == "" ||
                                profilePictureUrl == undefined
                                ? addProfileIcon
                                : editIcon
                            }
                            alt="profile-edit "
                            className="img-fluid cursor-class"
                            style={{
                              opacity: this.state.userRolePermissionRead
                                ? "0.6"
                                : "1",
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          //accept=".png, .jpeg, .jpg"
                          accept={validFileFormatsReadable}
                          id="myfile"
                          name="documentProfileFile"
                          onChange={this.onAttachmentChange}
                          key={fileInputKey || ""}
                          style={{
                            display: "none",
                            visibility: "none",
                            objectFit: "contain",
                          }}
                          disabled={
                            (statusName != "Active" && supplierId != 0) ||
                            this.state.userRolePermissionRead
                          }
                        />
                        <div id="display-image"></div>
                      </div>
                    </div>
                    <h2>{supplierName}</h2>
                  </div>
                  <div className="admin-profile-login-detail admin-supplier-login-detail">
                    <UserCredentials
                      userId={supplierId}
                      username={userName}
                      password={supplierPassword}
                      name="userName"
                      passwordName="supplierPassword"
                      onPasswordButtonClick={
                        isSupplier
                          ? this.onRequestNewPasswordClick
                          : this.showChangePasswordConfirmationModal //this.onChangePasswordClick
                      }
                      showPassword={showPassword}
                      togglePassword={this.togglePassword}
                      label={
                        isSupplier ? "Request New Password" : "Change Password"
                      }
                      onTextChange={this.onUsernameChange}
                      errorPassword={errorMessages.passwordValidation}
                      errorUsername={errorMessages.userNameValidation}
                      disabled={
                        isSupplier ||
                        (statusName != "Active" && supplierId != 0) ||
                        this.state.userRolePermissionRead
                      }
                      disabledButton={supplierId == 0 || this.state.userRolePermissionRead}
                    />
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="admin-supplier-profile-info">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientdetails"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Supplier Details
                          </button>
                        </h2>
                        <div
                          id="clientdetails"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6"
                                      style={{ alignSelf: "center" }}>
                                      <h5
                                        className={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                            ? ""
                                            : "required-field"
                                        }
                                      >
                                        Supplier Name:
                                      </h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.supplierNameValidation
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="name"
                                          className="form-control input-validation"
                                          id="supplierName"
                                          name="supplierName"
                                          value={supplierName}
                                          onChange={this.onTextChange}
                                          maxLength={100}
                                          autoComplete={"off"}
                                          disabled={
                                            (statusName != "Active" &&
                                              supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                        />
                                        {!isStringEmpty(
                                          errorMessages.supplierNameValidation
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Supplier Contact Number:</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        type="text"
                                        className="form-control input-validation"
                                        id="supplierContact"
                                        name="supplierContact"
                                        value={supplierContact}
                                        //onChange={this.onTextChange}
                                        onChange={this.onPhoneNumberChange}
                                        autoFocus={false}
                                        autoComplete={"off"}
                                        maxLength={20}
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6"
                                      style={{ alignSelf: "center" }}>
                                      <h5
                                        className={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                            ? ""
                                            : "required-field"
                                        }
                                      >
                                        Division/Branch Name:
                                      </h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.divisionValidation
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                        style={{ alignSelf: "center" }}
                                      >
                                        <input
                                          type="name"
                                          className="form-control input-validation"
                                          id="division"
                                          name="division"
                                          autoComplete="new-password"
                                          value={division}
                                          onChange={this.onTextChange}
                                          maxLength={50}
                                          disabled={
                                            (statusName != "Active" &&
                                              supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                        />
                                        {!isStringEmpty(
                                          errorMessages.divisionValidation
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Fax Number:</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        type="text"
                                        className="form-control input-validation"
                                        id="faxNumber"
                                        name="faxNumber"
                                        autoComplete="new-password"
                                        value={faxNumber}
                                        onChange={this.onPhoneNumberChange}
                                        maxLength={20}
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      // onChange={this.onTextChange}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3"
                                      style={{ alignSelf: "center" }}>
                                      <h5
                                        className={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                            ? ""
                                            : "required-field"
                                        }
                                      >
                                        Supplier Email Address:
                                      </h5>
                                    </div>
                                    <div className="col-md-9">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.emailValidation
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control input-validation"
                                          id="supplierEmail"
                                          name="supplierEmail"
                                          autoComplete="new-password"
                                          value={supplierEmail}
                                          onChange={this.onTextChange}
                                          maxLength={100}
                                          disabled={
                                            (statusName != "Active" &&
                                              supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                        />
                                        {!isStringEmpty(
                                          errorMessages.emailValidation
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Issuer Name:</h5>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="name"
                                        className="form-control input-validation"
                                        id="issuerName"
                                        name="issuerName"
                                        autoComplete="new-password"
                                        value={issuerName}
                                        onChange={this.onTextChange}
                                        maxLength={100}
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Issuer Address:</h5>
                                    </div>
                                    <div className="col-md-9">
                                      <textarea
                                        className="textarea-address"
                                        rows="3"
                                        name="issuerAddress"
                                        id="issuerAddress"
                                        autoComplete="new-password"
                                        value={issuerAddress}
                                        onChange={this.onTextChange}
                                        maxLength={250}
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="pb-3">
                                    <div className="row">
                                      <div className="col-md-3"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Client Associated:</h5>
                                      </div>
                                      <div
                                        className="col-md-9"
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      >
                                        <MultiSelectDropdown
                                          selectedOptions={
                                            dropdown_clientList == undefined
                                              ? []
                                              : dropdown_clientList
                                          }
                                          options={
                                            clientList == undefined
                                              ? []
                                              : clientList
                                          }
                                          onChange={
                                            this.onClientAssignedDropdownChange
                                          }
                                          isMulti={true}
                                          disabled={
                                            (statusName != "Active" &&
                                              supplierId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                        />
                                      </div>
                                      <div className="col-md-3" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Status:</h5>
                                    </div>
                                    <div
                                      className="col-md-6"
                                      disabled={
                                        (statusName != "Active" &&
                                          supplierId != 0) ||
                                        this.state.userRolePermissionRead
                                      }
                                    >
                                      <select
                                        className="form-select custom-dropdown-class"
                                        aria-label="Status Dropdown"
                                        data-bs-toggle="modal"
                                        data-bs-target="#supplieronhold-modal"
                                        onChange={(e) =>
                                          this.onStatusChanged(e)
                                        }
                                        value={statusId}
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      >
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                        <option
                                          value="3"
                                          className="onhold-link"
                                        >
                                          On Hold
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                {statusId == "3" && onHoldSupplierLst != null
                                  ? onHoldSupplierLst.map(
                                    (item, key, index) => {
                                      return (
                                        <div className="col-md-6">
                                          <div className="row" key={key}>
                                            <div
                                              className="col-md-6"
                                              style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                              }}
                                            >
                                              <div
                                                style={{ display: "flex", alignSelf: "center" }}
                                              >
                                                <h5>
                                                  <b>From:</b>
                                                </h5>
                                                <input
                                                  type="name"
                                                  className="form-control input-validation"
                                                  id="createdDate"
                                                  name="createdDate"
                                                  style={{
                                                    marginLeft: "5px",
                                                  }}
                                                  value={item.fromDate}
                                                  readOnly
                                                  disabled={
                                                    (statusName != "Active" &&
                                                      supplierId != 0) ||
                                                    this.state
                                                      .userRolePermissionRead
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div
                                                style={{ display: "flex", alignSelf: "center" }}
                                              >
                                                <h5>
                                                  <b>To:</b>
                                                </h5>
                                                <input
                                                  type="name"
                                                  className="form-control input-validation"
                                                  id="createdDate"
                                                  name="createdDate"
                                                  value={item.toDate}
                                                  style={{
                                                    marginLeft: "5px",
                                                  }}
                                                  readOnly
                                                  disabled={
                                                    (statusName != "Active" &&
                                                      supplierId != 0) ||
                                                    this.state
                                                      .userRolePermissionRead
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                  : null}

                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Date of Creation:</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        type="name"
                                        className="form-control input-validation"
                                        id="createdDate"
                                        name="createdDate"
                                        value={createdOn}
                                        readOnly
                                        disabled={
                                          (statusName != "Active" &&
                                            supplierId != 0) ||
                                          this.state.userRolePermissionRead
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Digital Signature:</h5>
                                    </div>

                                    <div className="col-md-9">
                                      <div className="row">
                                        {digitalSignUrl == "" ? null : (
                                          <div className="col-md-4">
                                            <div className="divasinput">
                                              <img
                                                src={digitalSignUrl}
                                                alt="digital signature"
                                              />
                                              <div className="supplierSignature-info">
                                                <img
                                                  src="images/info.png"
                                                  alt="info"
                                                  className="info"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="right"
                                                  title="Digital signatures create a virtual fingerprint that is unique to a person or entity and are used to identify users and protect information in messages or documents."
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {!this.state.userRolePermissionRead ? (<div
                                          className="col-md-8"
                                        >
                                          <div>
                                            <label
                                              onClick={() =>
                                                this.refs.fileSignInput.click()
                                              }
                                              className={
                                                "attach-btn attach-btn-responsive chs-btn "
                                              }
                                            >
                                              <img
                                                className="upload-img"
                                                src={
                                                  "images/Icon awesome-upload.png"
                                                }
                                                width="18px"
                                              />
                                              &nbsp;Choose File
                                            </label>

                                            <input
                                              ref="fileSignInput"
                                              type="file"
                                              //accept=".png, .jpeg, .jpg"
                                              accept={validFileFormatsReadable}
                                              id="fileSignInput"
                                              name="documentSignFile"
                                              onChange={this.onAttachmentChange}
                                              key={fileInputKey || ""}
                                              style={{
                                                display: "none",
                                                objectFit: "contain",
                                              }}
                                              disabled={
                                                (statusName != "Active" &&
                                                  supplierId != 0) ||
                                                this.state
                                                  .userRolePermissionRead
                                              }
                                            />
                                          </div>
                                          <label>
                                            &nbsp; (Please select an image file)
                                          </label>
                                        </div>) : null}
                                      </div>
                                    </div>
                                    {digitalSignUrl == "" ? null : (
                                      <>
                                        <div className="col-md-3">

                                        </div>
                                        <div className="col-md-9">
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              marginBottom: "8px",
                                              color: "gray",
                                            }}
                                          >
                                            This is a preview of how your digital
                                            signature will appear in the MD/SDOC
                                            document when signed.
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </div>

                                </div>

                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Supplier Stamp:</h5>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="row">
                                        {companyStampUrl == "" ? null : (
                                          <div className="col-md-3">
                                            <div className="divasinput">
                                              <img
                                                src={companyStampUrl}
                                                alt="company stamp"
                                                style={{
                                                  objectFit: "contain",
                                                  width: "100%",
                                                  height: "62px",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}
                                        {!this.state.userRolePermissionRead ? (<div className="col-md-8">
                                          <div>
                                            <label
                                              onClick={() =>
                                                this.refs.fileStampInput.click()
                                              }
                                              className={
                                                "attach-btn attach-btn-responsive chs-btn "
                                              }
                                            >
                                              <img
                                                className="upload-img"
                                                src="images/Icon awesome-upload.png"
                                                width="18px"
                                              />
                                              &nbsp;Choose File
                                            </label>

                                            <input
                                              ref="fileStampInput"
                                              type="file"
                                              accept={validFileFormatsReadable}
                                              id="fileStampInput"
                                              name="documentStampFile"
                                              onChange={(e) =>
                                                this.onAttachmentChange(e)
                                              }
                                              key={fileInputKey || ""}
                                              style={{
                                                display: "none",
                                                objectFit: "contain",
                                              }}
                                              disabled={
                                                (statusName != "Active" &&
                                                  supplierId != 0) ||
                                                this.state
                                                  .userRolePermissionRead
                                              }
                                            />
                                          </div>
                                          <label>
                                            &nbsp; (Please select an image file)
                                          </label>
                                        </div>) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#supplierdetails"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Company Details
                          </button>
                        </h2>
                        <div
                          id="supplierdetails"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {dd_suppCompList != undefined &&
                              dd_suppCompList.length > 0 ? (
                              dd_suppCompList.map((item, key, index) => {
                                return (
                                  <div className="supplierblock" key={key}>
                                    <div className="supplierblockimg">
                                      {(statusName != "Active" &&
                                        supplierId != 0) ||
                                        this.state.userRolePermissionRead ||
                                        dd_suppCompList.length == 1 ? (
                                        <div
                                          style={{ paddingTop: "30px" }}
                                        ></div>
                                      ) : (
                                        <img
                                          title="Delete"
                                          src="images/delete icon.png"
                                          alt="delete icon"
                                          onClick={() => {
                                            this.deleteCompany(item);
                                          }}
                                          className="cursor-class"
                                        //onClick={(e) => this.deleteCompany(e, item.supplierCompanyBridgeID)}
                                        />
                                      )}
                                    </div>
                                    <div className="supplier-block-detail">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-3"
                                              style={{ alignSelf: "center" }}>
                                              <h5>Company Name:</h5>
                                            </div>
                                            {item.companyName != "" ? (
                                              <div
                                                className="col-md-9"
                                                disabled
                                                id={"companyName"}
                                              >
                                                <input
                                                  type="name"
                                                  className="form-control input-validation"
                                                  id="userName"
                                                  name="userName"
                                                  value={item.companyName}
                                                  readOnly
                                                />
                                                {item.companyName != null ? (
                                                  <UncontrolledTooltip
                                                    autohide={false}
                                                    target={"companyName"}
                                                    aria-haspopup="true"
                                                    role="example"
                                                    effect="solid"
                                                    placement="bottom"
                                                    style={{ width: "350px" }}
                                                  >
                                                    {item.companyName}
                                                  </UncontrolledTooltip>) : null}
                                              </div>
                                            ) : (
                                              <div className="col-md-9">
                                                <Dropdown
                                                  dropdownLabel=""
                                                  name="supplierCompany"
                                                  onChange={
                                                    this.onDropDownSelected
                                                  }
                                                  style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "pre",
                                                    overflow: "hidden",
                                                  }}
                                                  disabled={
                                                    (statusName != "Active" &&
                                                      supplierId != 0) ||
                                                    this.state
                                                      .userRolePermissionRead
                                                  }
                                                //selected={dropdown_supplierCompany}
                                                >
                                                  {supplierList == null
                                                    ? null
                                                    : supplierList.map(
                                                      (item, key) => (
                                                        <option
                                                          key={key}
                                                          value={item.value}
                                                        >
                                                          {item.label}
                                                        </option>
                                                      )
                                                    )}
                                                </Dropdown>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="row">
                                            <div className="col-md-6"
                                              style={{ alignSelf: "center" }}>
                                              <h5>Division/Branch Name:</h5>
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                type="name"
                                                className="form-control input-validation"
                                                id="userName"
                                                name="userName"
                                                value={item.companyDivision}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <h5>Company Contact Number:</h5>
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                type="name"
                                                className="form-control input-validation"
                                                id="userName"
                                                name="userName"
                                                autoComplete="new-password"
                                                value={item.companyContactNo}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-3"
                                              style={{ alignSelf: "center" }}>
                                              <h5>Company Email Address:</h5>
                                            </div>
                                            <div className="col-md-9">
                                              <div className="addextraemail">
                                                <input
                                                  type="name"
                                                  className="form-control input-validation"
                                                  id="userName"
                                                  name="userName"
                                                  autoComplete="new-password"
                                                  value={
                                                    item.companyEmailAddress
                                                  }
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3"
                                          style={{ alignSelf: "center" }}>
                                          <h5>Company Address:</h5>
                                        </div>
                                        <div className="col-md-9">
                                          <textarea
                                            className="textarea-address"
                                            rows="3"
                                            name="companyAddress"
                                            id="companyAddress"
                                            autoComplete="new-password"
                                            value={item.companyAddress}
                                            onChange={(e) =>
                                              this.onCompanyAddressChange(
                                                key,
                                                e
                                              )
                                            }
                                            maxLength={250}
                                            disabled={
                                              (statusName != "Active" &&
                                                supplierId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div></div>
                            )}

                            {(!hideAddMoreButton ||
                              (statusName == "Active" &&
                                !this.state.userRolePermissionRead)) && (
                                <div
                                  className="addmoresupplier"
                                  onClick={this.onAddMoreClick}
                                >
                                  {" "}
                                  <img
                                    src="./images/add.png"
                                    alt="add_img"
                                    width="25px"
                                  />{" "}
                                  <span>Add more</span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* SYJ - 05/01/2022 - Show Hide error message Div */}
                <div
                  className="invalid-message-icon-box"
                  style={{ display: showErrorMessage ? "" : "none" }}
                >
                  <img src="images\icon material-error.png" alt="invalid"></img>
                  <p>Please fill in all the mandatory fields.</p>
                </div>

                {showInvalidEmailErrorMessage ? (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images\icon material-error.png"
                      alt="invalidEmail"
                    ></img>
                    <p>Please enter a valid Email.</p>
                  </div>
                ) : null}

                {shortEmployeeNameError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>
                      Supplier Name must be atleast 3 characters long.
                    </p>
                  </div>
                )}

                {usernameExistError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>{helperText.global.usernameAlreadyExists}</p>
                  </div>
                )}

                <div className="admin-ship-settings-save-btn">
                  <button
                    type="button"
                    className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn"
                    onClick={this.onSaveClick.bind(this)}
                    disabled={
                      (statusName != "Active" && supplierId != 0) ||
                      this.state.userRolePermissionRead
                    }
                  >
                    Save
                  </button>

                  {supplierId > 0 && !isSupplier ? (
                    <button
                      type="button"
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive add-user-btn manage-users-btn"
                      onClick={this.onSendEmail}
                      disabled={
                        statusName != "Active" ||
                        this.state.userRolePermissionRead
                      }
                    >
                      Send Email
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDirtyPopup && (
          <DirtyModal
            onModalCloseClick={this.onDirtyModalCloseClick}
            onProceedClick={this.onProceedClick}
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteModalCloseClick}
            onYesClick={this.onDeleteModalYesClick}
            onNoClick={this.onDeleteModalCloseClick}
            idToDelete={dataToBeDeleted}
            modalheader="Delete Company Details"
          ></DeleteModal>
        )}

        {showDeleteProfileModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteProfileModalCloseClick}
            onYesClick={this.onDeleteProfileModalYesClick}
            onNoClick={this.onDeleteProfileModalCloseClick}
            modalheader="Delete Profile Image"
          ></DeleteModal>
        )}

        {showSupplierRequestModal && (
          <ClientChangeRequestModal
            onCloseClick={this.onCloseChangeRequestModalClick}
            modalTitle={"Request admin to setup new password"}
            clientEmailId={supplierEmail}
            onChangeRequestClick={this.onShowProceedModalClick}
          />
        )}

        {showProceedModal && (
          <ProceedModal
            showProceedModal={showProceedModal}
            onNoClick={this.onProceeModalCloseClick}
            onYesClick={this.SaveSupplierChangeRequestData}
          />
        )}

        {showChangePasswordConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onChangePasswordClick}
            modalText={"change password? An email will be sent to the user with a new password after clicking the Yes button."}
          />
        )}

        <Footer />
      </div>
    );
  }
}

export default withRouter(AddEditSupplier);
