//import ArchiveModal from "../Models/ArchiveModal";
import { archiveService } from "../services";
//import { history } from "./history";
import React from "react";
import DOMPurify from "dompurify";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Bowser from "bowser";
import { BROWSER_TYPE_ID, UserAction, UserRole } from "./enums";
import CryptoJS from 'crypto-js';
// Import specific modules
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

//-- 128 bits
// const key = CryptoJS.enc.Utf8.parse("1203199320052021");
// const iv = CryptoJS.enc.Utf8.parse("9876543210123456");

//-- 256 bits
const key = CryptoJS.enc.Utf8.parse("00112233445566778899aabbccddeeff");
const iv = CryptoJS.enc.Utf8.parse("0123456789abcdef");
//const iv = CryptoJS.lib.WordArray.random(16);

const XLSX = require("xlsx");
export const sortOnLastAndFirst = (
  listToSort,
  lastNameProperty,
  firstNameProperty
) => {
  listToSort.sort((a, b) => {
    let aa =
      a[lastNameProperty].toLowerCase() +
      ", " +
      a[firstNameProperty].toLowerCase(),
      bb =
        b[lastNameProperty].toLowerCase() +
        ", " +
        b[firstNameProperty].toLowerCase();
    if (aa > bb) return 1;
    else if (aa < bb) return -1;
    return 0;
  });

  return listToSort;
};

export const SortListByPropertyDescending = (listToSort, sortProperty) => {
  listToSort.sort((a, b) => {
    if (a[sortProperty] < b[sortProperty]) return 1;
    if (a[sortProperty] > b[sortProperty]) return -1;
    return 0;
  });
  return listToSort;
};

export const SortListByPropertyAscending = (listToSort, sortProperty) => {
  listToSort.sort((a, b) => {
    if (a[sortProperty] > b[sortProperty]) return 1;
    if (a[sortProperty] < b[sortProperty]) return -1;
    return 0;
  });
  return listToSort;
};

/* <summary>
date: 06-10-2020
Name: Gm
description: BoothID is used only when downloading the Setup documentation
<summary>*/

export const AddArchiveAction = (
  ArchiveAction,
  eventID,
  ArchiveData,
  BoothID
) => {
  // var archiveModal = new ArchiveModal();
  // archiveModal.EmployeeId =
  //   BoothID == undefined ? (user == undefined ? 0 : user.EmployeeId) : BoothID;
  // archiveModal.ArchiveActionId = ArchiveAction;
  // archiveModal.SecondaryId = eventID;
  // archiveModal.ArchiveData = ArchiveData;
  // archiveService.logArchiveAction(archiveModal);
};

export const GetBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};


/* <summary>
date: 17-06-2020
Name: Gm
description: Validates the Email address
<summary>*/

export const isEmailValid = (emailAddress) => {
  const emailRegexString = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  return emailRegexString.test(emailAddress);
};

/* <summary>
date: 17-06-2020
Name: Gm
description: Validates the Password for NIST requirement
<summary>*/

export const isPasswordValid = (password) => {
  // const NIStPasswordRegexString =
  //   /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+-=])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
  // return NIStPasswordRegexString.test(passowrd);

  // check for at least one letter, one number and one special character
  if (password.length < 8) {
    return false;
  }
  else if (!/[A-Z]/.test(password)) {
    return false;
  }
  else if (!/[a-z]/.test(password)) {
    return false;
  }
  else if (!/\d/.test(password)) {
    return false;
  }
  else if (!/[!@#$%^&*(),.?":{}|<>_=]/.test(password)) {
    return false;
  }
  else {
    return true;
  }
};

export function getFileName(path) {
  return path.substring(path.lastIndexOf("/") + 1);
}

export function removeQueryStringFromFileName(fileName) {
  return fileName.split("?")[0];
}

/* <summary>
date: 15-Aug-20
Name: S.A
description: To calculate countdowntimer animation styles and return it in an array.
<summary>*/
export function SetTimerAnimationStyles(seconds, minutes, hours, days) {
  try {
    var countdownstyles = [];
    var secleft,
      secright,
      minleft,
      minright,
      hrsleft,
      hrsright,
      daysleft,
      daysright;
    //Seconds Animation Logic
    if (seconds < 30) {
      var calculateright = (180 * seconds) / 30;
      var calculateleft = 0;
      secleft = "rotate(" + calculateleft + "deg)";
      secright = "rotate(" + calculateright + "deg)";
    } else {
      var calculateright = 180;
      var calculateleft = (180 * seconds) / 30 - 180;
      secleft = "rotate(" + calculateleft + "deg)";
      secright = "rotate(" + calculateright + "deg)";
    }
    //Minutes Animation Logic
    if (minutes < 30) {
      var calculateright = (180 * minutes) / 30;
      var calculateleft = 0;
      minleft = "rotate(" + calculateleft + "deg)";
      minright = "rotate(" + calculateright + "deg)";
    } else {
      var calculateright = 180;
      var calculateleft = (180 * minutes) / 30 - 180;
      minleft = "rotate(" + calculateleft + "deg)";
      minright = "rotate(" + calculateright + "deg)";
    }
    //Hours Animation Logic
    if (hours < 12) {
      var calculateright = (180 * hours) / 12;
      var calculateleft = 0;
      hrsleft = "rotate(" + calculateleft + "deg)";
      hrsright = "rotate(" + calculateright + "deg)";
    } else {
      var calculateright = 180;
      var calculateleft = (180 * hours) / 12 - 180;
      hrsleft = "rotate(" + calculateleft + "deg)";
      hrsright = "rotate(" + calculateright + "deg)";
    }
    //Days Animation Logic
    if (days < 182) {
      var calculateright = (180 * days) / 182;
      var calculateleft = 0;
      daysleft = "rotate(" + calculateleft + "deg)";
      daysright = "rotate(" + calculateright + "deg)";
    } else {
      var calculateright = 180;
      var calculateleft = (180 * days) / 182 - 180;
      daysleft = "rotate(" + calculateleft + "deg)";
      daysright = "rotate(" + calculateright + "deg)";
    }
    //Fill countdownstyles Array:
    countdownstyles.push({
      secondsleft: secleft,
      secondsright: secright,
      minutesleft: minleft,
      minutesright: minright,
      hoursleft: hrsleft,
      hoursright: hrsright,
      daysleft: daysleft,
      daysright: daysright,
    });
    return countdownstyles;
  } catch (error) {
    console.log(error, "SetTimerAnimationStyles");
  }
}

export const sortOnInteger = (listToSort, propertyName) => {
  listToSort.sort((a, b) => {
    const item1 = a[propertyName];
    const item2 = b[propertyName];

    if (item1 > item2) return 1;

    if (item1 < item2) return -1;

    return 0;
  });

  return listToSort;
};
export function CheckURLPrefix(url) {
  try {
    if (url) {
      if (
        url.toLowerCase().substr(0, "http://".length) !==
        "http://".toLowerCase() &&
        url.toLowerCase().substr(0, "https://".length) !=
        "https://".toLowerCase()
      ) {
        return "https://" + url;
      }
      return url;
    }
  } catch (error) { }
  return "";
}
export function logOutUnAuthorizedUser(eventGuid) {
  localStorage.removeItem("client");
  // history.push(`/Event/${eventGuid}`);
  window.location.reload();
}

export const getHostnameFromRegex = (url) => {
  try {
    // run against regex
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1];
  } catch (e) {
    console.log(e);
    return "";
  }
};
export function hasAlpha(context, canvas) {
  var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
    hasAlphaPixels = false;
  for (var i = 3, n = data.length; i < n; i += 4) {
    if (data[i] < 255) {
      hasAlphaPixels = true;
      break;
    }
  }
  return hasAlphaPixels;
}

export function useInterval(callback, delay) {
  const intervalId = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalId.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalId.current);
    }
  }, [delay]);
  return intervalId.current;
}
export const SheetToArrayBuffer = (s) => {
  try {
    if (typeof ArrayBuffer !== "undefined") {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    } else {
      var buf = new Array(s.length);
      for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
  } catch (error) { }
};

/* <summary>
date: 07-07-2020
Name: Gm
description: Converts Grid (Columnn and Rows to Sheet for excel)
<summary>*/

export const UIGridToSheet = (data, columns) => {
  var o = [],
    oo = [],
    i = 0,
    j = 0;
  try {
    /* column headers */
    for (j = 0; j < columns.length; ++j) oo.push(columns[j]);
    o.push(oo);

    /* table data */
    for (i = 0; i < data.length; ++i) {
      oo = [];
      for (j = 0; j < data[i].length; ++j) oo.push(data[i][j]);
      o.push(oo);
    }
  } catch (error) { }

  /* aoa_to_sheet converts an array of arrays into a worksheet object */
  return XLSX.utils.aoa_to_sheet(o);
};

export const GetRandomTExt = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isAppleDevice = () => {
  if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) return true;
  else return false;
};

export const isAndroidDevice = () => {
  if (/Android/i.test(window.navigator.userAgent)) return true;
  else return false;
};

export const getBrowserDetails = () => {
  try {
    //Create a parser instance
    const browser = Bowser.getParser(window.navigator.userAgent);

    //Get browser's name
    const browserName = browser.getBrowserName();

    //Get os object
    const os = browser.getOS();

    //Get platform name
    const platformType = browser.getPlatformType();

    const browserDetails = {
      os: os,
      browserName: browserName,
      platformType: platformType,
    };

    return browserDetails;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getEventIdFromStorage = () => {
  let eventId = 0;
  try {
    const eventIdFromStorage = localStorage.getItem("eventId");
    if (eventIdFromStorage) eventId = parseInt(eventIdFromStorage, 10) || 0;
  } catch (e) {
    console.log(e);
  }
  return eventId;
};
export const getFileAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getBrowserTypeID = () => {
  let userAgent = navigator.userAgent;
  let browserID = 0;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserID = BROWSER_TYPE_ID.CHROME;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserID = BROWSER_TYPE_ID.FIREFOX;
  } else if (userAgent.match(/safari/i)) {
    browserID = BROWSER_TYPE_ID.SAFARI;
  } else if (userAgent.match(/opr\//i)) {
    browserID = BROWSER_TYPE_ID.CHROME;
  } else if (userAgent.match(/edg/i)) {
    browserID = BROWSER_TYPE_ID.EDGE;
  }
  return browserID;
};


export const isValidFile = (value, validFileFormats) => {
  if (!value) return false;

  //regex to get file extension
  var re = /(?:\.([^.]+))?$/;
  //second item in the array will be the extension
  let ext = re.exec(value.toLowerCase())[1];
  return validFileFormats[0].includes(ext);
};

export const generateRandomString = () => {
  return Math.random().toString(36);
};


export const isEmptyObject = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

/* <summary>
date: 31/01/2023
Name: HV
description: sorting code
<summary> */
export function customComparator(valueA, valueB) {
  var valA = valueA == null ? "" : valueA;
  var valB = valueB == null ? "" : valueB;
  return valA.trim().toLowerCase().localeCompare(valB.trim().toLowerCase());
};

/* <summary>
date: 20/02/2023
Name: HV
description: method to show page name on hover of browser tab
<summary> */
export function changePageTitle(pageName) {
  function insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return string;
  }
  const title = "IHM & Ship Recycling Services";
  var path = pageName == "" ? window.location.pathname : pageName;
  var page = path.split("/").pop();
  page = insertSpaces(page)
  if (page != "")
    document.title = page + " | " + title;
}

/* <summary>
date: 2/03/2023
Name: DP
description: siterights to hide show tab or button  
<summary> */
export function HasSiteRights(siteRightId) {

  var SiteRights = GetLocalStorageDataWithParse('SiteRights');
  const data = SiteRights != null ? SiteRights.map((x) => {
    if (x.siteRightId == siteRightId)
      return x;
  }).filter((t) => t != undefined) : null;

  if (data != null && data.length != 0)
    return true;
  else
    return false;
}


/* <summary>
date: 17/06/2023
Name: HV
description: method for getting screen allowed for logged in user  
<summary> */

export function GetAllowedScreensForLoggedInUser(userTypeId, componentFor) {
  const accessList = GetLocalStorageDataWithParse("userRolePermission");
  const accessAllowed = accessList === null || accessList === undefined;

  const allowedScreens = {
    [UserRole.ADMIN]: ["Dashboard", "ActionRequired", "ManageClient", "Communication", "IHMDatabaseRegistry", "MiscellaneousOperations", "TrackingTool", "Reports", "ManageSupplier", "Training", "ManageUsers", "ManageShip", "ContactUs", "ManageStatistics", "StatisticsReport", "ManageClientUsers"],
    [UserRole.INTERNALUSER]: ["Dashboard", "ActionRequired", "ManageClient", "Communication", "IHMDatabaseRegistry", "MiscellaneousOperations", "TrackingTool", "Reports", "ManageSupplier", "Training", "ManageUsers", "ManageShip", "ContactUs", "ManageStatistics", "StatisticsReport", "ManageClientUsers"],
    [UserRole.ACCOUNTS]: ["Dashboard", "ActionRequired", "ManageClient", "Communication", "IHMDatabaseRegistry", "MiscellaneousOperations", "TrackingTool", "Reports", "ManageSupplier", "Training", "ManageUsers", "ManageShip", "ContactUs", "ManageStatistics", "StatisticsReport", "ManageClientUsers"],
    [UserRole.SUPPLIER]: ["SupplierDashboard", "SupplierDocuments", "TrainingDocument", "ContactUs"],
    [UserRole.CLIENT]: ["ClientDashboard", "ManageShip", "TrainingDocument", "Reports", "TrackingTool", "ManageClientUsers", "ContactUs", "ManageStatistics", "ClientDocuments", "StatisticsReport"],
    [UserRole.CLIENTUSER]: ["ClientDashboard", "ManageShip", "TrainingDocument", "Reports", "TrackingTool", "ManageClientUsers", "ContactUs", "ManageStatistics", "ClientDocuments", "StatisticsReport"],
    [UserRole.SHIPCREW]: ["ShipCrewDashboard", "TrainingDocument", "Reports", "TrackingTool", "ContactUs", "ManageStatistics", "StatisticsReport"]
  };

  const userRole = {
    "ManageClient": "clientAndShipAccess",
    "ManageShip": (userTypeId == UserRole.CLIENT || userTypeId == UserRole.CLIENTUSER) ? "shipParticularsAccess" : "clientAndShipAccess",
    "ManageClientUsers": userTypeId == UserRole.CLIENT ? "addClientAccess" : "clientAndShipAccess",
    "TrackingTool": "trackingToolAccess",
    "Communication": "communicationAccess",
    "Reports": (userTypeId == UserRole.CLIENT || userTypeId == UserRole.CLIENTUSER) ? "reportsAccess" : "reportAccess",
    "ManageStatistics": "statisticAccess",
    "ManageSupplier": "supplierAccess",
    "Training": "trainingAccess",
    "ManageUsers": "manageUserAccess",
    "IHMDatabaseRegistry": "ihmdatabaseAccess",
    "MiscellaneousOperations": "financeAndAccountAccess",
    "ClientDocuments": "documentsAccess"
  };

  if (!accessAllowed) {
    const accessKey = userRole[componentFor];
    if (accessKey && accessList[accessKey] === UserAction.DENY) {
      return [];
    }
  }

  return allowedScreens[userTypeId] || [];
}

/* <summary>
date: 17/05/2023
Name: HV
description: method for checking if user has access
<summary> */

export function CheckIfUserHasAccess(userTypeId, accessList, notificationText, navigateTo) {
  let text = "";
  if (notificationText != undefined && (userTypeId == UserRole.INTERNALUSER || userTypeId == UserRole.ACCOUNTS)) {
    if (notificationText.includes("NEW_CLIENT_") || notificationText.includes("CLIENT_STATUS") || notificationText.includes("Client User") ||
      notificationText.includes("NEW_SHIP_") || notificationText.includes("SHIP_STATUS") || notificationText.includes("Client") ||
      notificationText.includes("Ship") || notificationText.includes("Internal User") || notificationText.includes("Client User")
      || notificationText.includes("Roles And Permissions") || notificationText.includes("IU_ASSIGNED_CLIENT") || notificationText.includes("IU_UNASSIGNED_CLIENT")
      || notificationText.includes("IU_ASSIGNED_SHIP") || notificationText.includes("IU_UNASSIGNED_SHIP") || notificationText.includes("EDIT_USER")
      || notificationText.includes("EDIT_CLIENT") || notificationText.includes("EDIT_CLIENT_USER") || notificationText.includes("EDIT_SUPPLIER")
      || notificationText.includes("EDIT_USER_PROFILE") || notificationText.includes("EDIT_TRACKING_TOOL")) {
      text = accessList.clientAndShipAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("NEW_SUPPLIER_") || notificationText.includes("SUPPLIER_STATUS") || notificationText.includes("Supplier:")) {
      text = accessList.supplierAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("NEW_USER_") || notificationText.includes("USER_STATUS") || notificationText.includes("Roles and Permissions")) {
      text = accessList.manageUserAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("NEW_CLIENTUSER_")) {
      text = accessList.clientAndShipAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("PO_ADDED_CLIENT") || notificationText.includes("MANUAL_LINE_ITEM_ADDED") || notificationText.includes("MD/SDoC") || notificationText.includes("MD / SDoC") || notificationText.includes("Declaration") || notificationText.includes("TRACKING_TOOL_")) {
      text = accessList.trackingToolAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("COMMUNICATION_")) {
      text = accessList.communicationAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("Report") || notificationText.includes("REPORT_OR_DOCUMENT_DELETED")) {
      text = accessList.reportAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("TRAINING")) {
      text = accessList.trainingAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("IHM")) {
      text = accessList.ihmdatabaseAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("MISC")) {
      text = accessList.financeAndAccountAccess == UserAction.DENY ? "" : "View";
    }
    if (notificationText.includes("Change Request")) {
      if (navigateTo != undefined) {
        if (navigateTo.includes("Client Details") || navigateTo.includes("Ship Details") || navigateTo.includes("Manage Users") || navigateTo.includes("Manage Client Users")) {
          text = accessList.clientAndShipAccess == UserAction.DENY ? "" : "View";
        }
        else if (navigateTo.includes("Tracking Tool")) {
          text = accessList.trackingToolAccess == UserAction.DENY ? "" : "View";
        }
        else if (navigateTo.includes("Reports")) {
          text = accessList.reportAccess == UserAction.DENY ? "" : "View";
        }
        else if (navigateTo.includes("Training")) {
          text = accessList.trainingAccess == UserAction.DENY ? "" : "View";
        }
      }
    }
  }

  else if (notificationText != undefined && userTypeId == UserRole.CLIENT) {
    if (notificationText.includes("NEW_SHIP_") || notificationText.includes("SHIP_STATUS") || notificationText.includes("Ship")) {
      text = accessList.shipParticularsAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("MD/SDoC") || notificationText.includes("Declaration") || notificationText.includes("TRACKING_TOOL_")) {
      text = accessList.trackingToolAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("NEW_CLIENTUSER_CREATED") || notificationText.includes("EDIT_CLIENT_USER")) {
      text = accessList.addClientAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("EDIT_CLIENT") || notificationText.includes("Client: [Client_Name] has uploaded [Document_Type_Name]") || notificationText.includes("Client: [Client_Name] password changed by [User_Name].")) {
      text = "View";
    }
    else if (notificationText.includes("Report") || notificationText.includes("REPORT_OR_DOCUMENT_DELETED")) {
      text = accessList.reportsAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("TRAINING")) {
      text = accessList.trainingAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("IHM_DOCUMENT_UPLOAD")) {
      text = accessList.documentsAccess == UserAction.DENY ? "" : "View";
    }

    else if (notificationText.includes("Client User:")) {
      text = accessList.addClientAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("Change Request") && navigateTo != undefined) {
      if (navigateTo.includes("Client Details")) {
        text = "View";
      }
      else if (navigateTo.includes("Ship Details")) {
        text = accessList.shipParticularsAccess == UserAction.DENY ? "" : "View";
      }
      else if (navigateTo.includes("Tracking Tool")) {
        text = accessList.trackingToolAccess == UserAction.DENY ? "" : "View";
      }
      else if (navigateTo.includes("Reports")) {
        text = accessList.reportsAccess == UserAction.DENY ? "" : "View";
      }
      else if (navigateTo.includes("Manage Users") || navigateTo.includes("Manage Client Users")) {
        text = accessList.addClientAccess == UserAction.DENY ? "" : "View";
      }
      else if (navigateTo.includes("Training")) {
        text = accessList.trainingAccess == UserAction.DENY ? "" : "View";
      }
      else if (navigateTo.includes("Documents")) {
        text = accessList.documentsAccess == UserAction.DENY ? "" : "View";
      }
    }

  }

  else if (notificationText != undefined && userTypeId == UserRole.CLIENTUSER) {
    if (notificationText.includes("NEW_SHIP_") || notificationText.includes("SHIP_STATUS") || notificationText.includes("Ship")) {
      text = accessList.shipParticularsAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("NEW_CLIENTUSER_") || notificationText.includes("EDIT_CLIENT_USER")) {
      text = accessList.addClientAccess == UserAction.DENY ? "" : "View";

    }
    else if (notificationText.includes("MD/SDoC") || notificationText.includes("Declaration") || notificationText.includes("TRACKING_TOOL_")) {
      text = accessList.trackingToolAccess == UserAction.DENY ? "" : "View";
    }

    else if (notificationText.includes("Report") || notificationText.includes("REPORT_OR_DOCUMENT_DELETED")) {
      text = accessList.reportsAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("TRAINING")) {
      text = accessList.trainingAccess == UserAction.DENY ? "" : "View";
    }
    else if (notificationText.includes("IHM_DOCUMENT_UPLOAD")) {
      text = accessList.documentsAccess == UserAction.DENY ? "" : "View";
    }

    else if (notificationText.includes("Client User:")) {
      if (notificationText.includes("Document")) {
        text = accessList.documentsAccess == UserAction.DENY ? "" : "View";
      }
    }

  }

  else if (notificationText != undefined && userTypeId == UserRole.SHIPCREW) {
    if (notificationText.includes("IHM_DOCUMENT_DELETED")) {
      text = "";
    }
    else {
      text = "View";
    }
  }

  else if (notificationText != undefined && userTypeId == UserRole.SUPPLIER) {
    if (notificationText.includes("IHM_DOCUMENT_DELETED")) {
      text = "";
    }
    else {
      text = "View";
    }
  }

  else {
    text = "View";
  }
  return text;

}

/* <summary>
date: 03/08/2023
Name: HV
description: method for checking if user has module access for action required
<summary> */

export function CheckIfUserHasActionRequiredModuleAccess(accessList, module, navigateTo) {
  const accessMapping = {
    'Client': 'clientAndShipAccess',
    'Ship': 'clientAndShipAccess',
    'Client User:': 'clientAndShipAccess',
    'Document': 'clientAndShipAccess',
    'Manage Users': 'clientAndShipAccess',
    'Supplier': navigateTo && navigateTo.includes('TrackingTool') ? 'trackingToolAccess' : 'supplierAccess',
    'Tracking Tool': 'trackingToolAccess',
    'Internal User': navigateTo && navigateTo.includes('TrackingTool') ? 'trackingToolAccess' : 'manageUserAccess',
    'Training': 'trainingAccess',
    'Reports': 'reportAccess',
    "ManageUsers": "manageUserAccess",
    "IHMDatabaseRegistry": "ihmdatabaseAccess",
    "MiscellaneousOperations": "financeAndAccountAccess",
    "ClientDocuments": "documentsAccess",
    "ManageStatistics": "statisticAccess",
  };
  if (module && accessMapping.hasOwnProperty(module)) {
    const accessProperty = accessMapping[module];

    if (accessList[accessProperty] !== UserAction.DENY) {
      return accessList[accessProperty] === UserAction.READWRITE ? 'Action Needed' : 'View';
    }
  }

  return '';
}


/* <summary>
date: 11-04-2023
Name: GM
description: get decrypted data from local storage
<summary>*/
export const GetLocalStorageDataWithParse = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return JSON.parse(DecryptUsingAES256(encryptedValue));
    }
  } catch (error) { }
  return encryptedValue;
};

export const GetLocalStorageDataWithoutParse = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return DecryptUsingAES256(encryptedValue);
    }
  } catch (error) { }
  return encryptedValue;
};

/* <summary>
date: 11-04-2023
Name: GM
description: set encrypted data into local storage
<summary>*/
export const AddLocalStorageData = (storageKey, value) => {
  const encryptedValue = EncryptUsingAES256(value);
  const encryptedKey = EncryptUsingAES256(storageKey);

  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  }

  localStorage.setItem(encryptedKey, encryptedValue);
};

/* <summary>
date: 11-04-2023
Name: GM
description: get decrypted data from local storage
<summary>*/
export const RemoveLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  localStorage.removeItem(encryptedKey);
};

/* <summary>
date: 11-04-2023
Name: GM
description: Encryption using CryptoJS
<summary>*/
export const EncryptUsingAES256 = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    iv,
    keySize: 256 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};


/* <summary>
date: 11-04-2023
Name: GM
description: Decryption using CryptoJS
<summary>*/
export const DecryptUsingAES256 = (decString) => {
  const decrypted = CryptoJS.AES.decrypt(decString, key, {
    iv,
    keySize: 256 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};


export const sanitizeHTML = (htmlContent) => {
  return DOMPurify.sanitize(htmlContent)
}

export const ConvertToHtml = (editorState) => {
  try {
    debugger;
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState, "", true);
    debugger;
    return markup;
  } catch (error) {
    debugger;
    return "";
  }
};

export const ConvertFromHTML = (html) => {
  try {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    } else {
      return EditorState.createEmpty();
    }
  } catch (error) {
    return EditorState.createEmpty();
  }
};