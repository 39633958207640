import React, { Component } from 'react'
import AGGrid from '../../components/AGGrid'

export default class ViewPOFile extends Component {
    render() {
        const { showModal, onCloseClick,colDefs, rowData,importedPOFileId ,importedPOFilename, onDownloadPOClick} = this.props
        return (
            <div className="view-po-list" style={{ display: showModal ? "block" : "none" }}>
                <div className="modal-backdrop fade show" style={{ display: showModal ? "block" : "none" }}></div>
                <div className="modal" id="viewPOlist" style={{ display: showModal ? "block" : "none" }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"> PO List</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: "all" }} onClick={() => onCloseClick("showViewModal",false)} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body">
                                    <div className="PO-list">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 doc-name">{importedPOFilename}</div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 PO-dwnld-icon"><img src="images/admin-tracking-download.png" alt="admin download icon" className="admin-download" style={{pointerEvents:"all",cursor:'pointer'}} onClick={()=>onDownloadPOClick(importedPOFileId)} /></div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                    <AGGrid rowData={rowData} colDefs={colDefs} rowSelection="single" pagination="10" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
