import React, { Component } from "react";
import { CommunicationService } from "../../services/communication.service";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { helperText } from "../../helpers/helperText";
import {
  isStringEmpty
} from "../../helpers/string-helper";
import { GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle } from "../../helpers/utilities";
import Dropdown from "../../components/Dropdown";
import {
  withRouter, isValidFile, ConvertToHtml,
  sanitizeHTML,
  ConvertFromHTML
} from "../../helpers";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Logout from "../../helpers/Logout";
import { UserAction, toolbarOptions } from "../../helpers/enums";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import RecievedSection from "./RecievedSection";
import { UncontrolledTooltip } from "reactstrap";
import CKEditor from "react-ckeditor-component";

const validFileFormatsReadable =
  "application/pdf,application/PDF,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/png, image/jpeg";
const validFileFormatsExt = [".pdf, .xls, .xlsx, .docx, .PDF, .png, .jpg, .jpeg, .PNG"];
class Communication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDefaultId: 0,
      subject: "",
      emailBody: EditorState.createEmpty(),
      emailType: "",
      clientDataList: [],
      shipDataList: [],
      emailTypeDataList: [],
      defaultShipDataList: [],
      toastVisible: false,
      isLoading: true,
      toastResponseType: "",
      toastMessage: "",
      errorMessages: {},
      fileInputKey: this.generateRandomString(),
      errorFile: false,
      documentFile: [],
      docFileName: [],
      fileName: "No File Chosen",
      filePath: "",
      accessList: null,
      sendSection: true,
      receivedSection: false,
      listToEmail: [],
      listCcEmail: [],
      listBccEmail: [],
      showErrorMessage: false,
      loggedInUserId: 0,
      showSaveTemplateButton: false,
      dropdown_clientFilter: 0,
      dropdown_shipFilter: 0,
      dropdown_emailTypeFilter: 0,
      clientName: "",
      shipName: "",
      templateName: "",
      hasFocus: false,
      shipNameValidation: false,
      clientNameValidation: false,
      userRolePermissionRead: false,
      userTypeId: 0,
    };
  }

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: Get data to bind on the screen on load
    <summary> */
  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          loggedInUserId: userData.id,
          userTypeId: userData.typeId
        },
        () => {
          this.GetAllFilterDropdown(false);
        }
      );
    }
  }

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: Get data to bind in the dropdown filter
    <summary> */
  GetAllFilterDropdown = async (clearData) => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.communicationAccess == UserAction.READ,
      });

      var responseFilters =
        await CommunicationService.GetAllCommunicationFilters(
          this.state.loggedInUserId, this.state.userTypeId
        );

      if (responseFilters == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      } else if (responseFilters != null) {
        if (responseFilters.status == 401) {
          Logout(this.props.navigate);
        } else if (responseFilters.status == 200) {
          var defaultCreateCustomEmail = {
            emailDefaultId: -1,
            emailType: "Create Custom Email",
            bccaddress: "",
            ccaddress: "",
            communications: [],
            createdBy: this.state.loggedInUserId,
            createdOn: "",
            editedBy: this.state.loggedInUserId,
            editedOn: "",
            emailSubject: "",
            emailText: "",
          };
          var listEmailType = responseFilters.data.emailList.push(
            defaultCreateCustomEmail
          );
          this.setState({
            emailTypeDataList: responseFilters.data.emailList,
            clientDataList: responseFilters.data.clientList,
            defaultShipDataList: responseFilters.data.shipList,
            isLoading: false,
          }, () => {
            if (clearData == true)
              this.clearAllFilter();
          });
        }
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      }
    } catch (error) { }
  };

  /* <summary>
    date: 22/02/2023
    Name: HV
    description: On email type selected, bind all the fields with respective data
    <summary> */
  onEmailTypeSelected = (e) => {
    const {
      errorMessages,
      dropdown_clientFilter,
      dropdown_shipFilter,
      shipName,
      clientName,
      shipDataList,
    } = this.state;
    var { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        var getEmailDefaultData = this.state.emailTypeDataList.filter(
          (e) => e.emailDefaultId === parseInt(value, 10)
        )[0];

        if (getEmailDefaultData != null) {
          let emailText = getEmailDefaultData.emailText;
          let emailSubject = getEmailDefaultData.emailSubject;

          emailText = emailText.replace("[listItems]", "");
          if (
            parseInt(dropdown_clientFilter, 10) != 0 ||
            parseInt(dropdown_shipFilter, 10) != 0
          ) {
            emailText = emailText.replace("[shipName]", shipName);
            emailText = emailText.replace("[clientName]", clientName);

            emailSubject = emailSubject.replace("[shipName]", shipName);
            emailSubject = emailSubject.replace("[clientName]", clientName);
          }


          errorMessages["toValidation"] = "";
          errorMessages["ccValidation"] = "";
          errorMessages["bccValidation"] = "";
          errorMessages["subjValidation"] = "";
          errorMessages["bodyValidation"] = "";
          errorMessages["templateNameValidation"] = "";

          let emailBodyToDisplay = ConvertFromHTML(emailText);
          this.setState({
            emailDefaultId: getEmailDefaultData.emailDefaultId,
            listCcEmail:
              (getEmailDefaultData.ccaddress != "" && getEmailDefaultData.ccaddress != null)
                ? getEmailDefaultData.ccaddress.split(",")
                : [],
            listBccEmail:
              (getEmailDefaultData.bccaddress != "" && getEmailDefaultData.bccaddress != null)
                ? getEmailDefaultData.bccaddress.split(",")
                : [],
            subject: emailSubject,
            emailBody: emailBodyToDisplay,
            emailType: getEmailDefaultData.emailType,
            showSaveTemplateButton: parseInt(value, 10) == -1,
            dropdown_clientFilter:
              parseInt(value, 10) == -1 ? "0" : dropdown_clientFilter,
            dropdown_shipFilter:
              parseInt(value, 10) == -1 ? "0" : dropdown_shipFilter,
            shipDataList: parseInt(value, 10) == -1 ? [] : shipDataList,
            errorMessages: errorMessages,
            shipNameValidation: false,
            clientNameValidation: false,
          });
        } else {
          this.clearAllFilter();
        }
      }
    );
  };

  /* <summary>
    date: 22/02/2023
    Name: HV
    description: On client selected, set to address and also replace the data of the client in email body
    <summary> */
  onClientSelected = (e) => {
    const { name, value } = e.target;
    const {
      clientDataList,
      defaultShipDataList,
      listToEmail,
      dropdown_clientFilter,
    } = this.state;

    let clientList = [],
      filteredToList = [];

    if (parseInt(dropdown_clientFilter, 10) != parseInt(value, 10)) {
      let list = clientDataList.filter(
        (x) => x.clientId == parseInt(dropdown_clientFilter, 10)
      )[0];
      clientList = clientDataList.filter(
        (x) => x.clientId != parseInt(dropdown_clientFilter, 10)
      );
      filteredToList =
        list != null
          ? listToEmail.filter((x) => x != list.primaryEmailAddress)
          : listToEmail;
    }

    this.setState(
      {
        [name]: value,
        shipName: "",
        dropdown_shipFilter: "0",
      },
      () => {
        var clientSelectedData = clientList.filter(
          (e) => e.clientId === parseInt(value, 10)
        )[0];

        let primaryEmailAddress = "",
          clientName = "";

        if (clientSelectedData != null) {
          primaryEmailAddress =
            clientSelectedData.primaryEmailAddress != null &&
              clientSelectedData.primaryEmailAddress != ""
              ? clientSelectedData.primaryEmailAddress.split(",")
              : "";

          clientName =
            clientSelectedData.clientName != null &&
              clientSelectedData.clientName != ""
              ? clientSelectedData.clientName
              : "";
        }

        this.replaceClientAndShipName(clientName, "");

        this.setState({
          listToEmail:
            primaryEmailAddress != ""
              ? [...filteredToList, primaryEmailAddress]
              : filteredToList,
          shipDataList: defaultShipDataList.filter(
            (e) => e.clientId === parseInt(value, 10)
          ),
          clientName: clientName,
          clientNameValidation: false,
        });
      }
    );
  };

  /* <summary>
    date: 23/02/2023
    Name: HV
    description: On ship selected, set to address and also replace the data of the ship in email body
    <summary> */
  onShipSelected = (e) => {
    const { name, value } = e.target;
    const { shipDataList, dropdown_shipFilter, listToEmail, clientName } =
      this.state;

    let shipList = [],
      filteredToList = [];

    if (parseInt(dropdown_shipFilter, 10) != parseInt(value, 10)) {
      let list = shipDataList.filter(
        (x) => x.shipId == parseInt(dropdown_shipFilter, 10)
      )[0];
      shipList = shipDataList.filter(
        (x) => x.shipId != parseInt(dropdown_shipFilter, 10)
      );
      filteredToList =
        list != null
          ? listToEmail.filter((x) => x != list.shipEmailAddress)
          : listToEmail;
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        var shipSelectedData = shipList.filter(
          (e) => e.shipId === parseInt(value, 10)
        )[0];
        let primaryEmailAddress = "",
          shipName = "";

        if (shipSelectedData != null) {
          primaryEmailAddress =
            shipSelectedData.shipEmailAddress != null &&
              shipSelectedData.shipEmailAddress != ""
              ? shipSelectedData.shipEmailAddress.split(",")
              : "";
          shipName =
            shipSelectedData.shipName != null && shipSelectedData.shipName != ""
              ? shipSelectedData.shipName
              : "";
        }

        this.replaceClientAndShipName(clientName, shipName);

        this.setState({
          listToEmail:
            primaryEmailAddress != ""
              ? [...filteredToList, primaryEmailAddress]
              : filteredToList,
          shipName: shipName,
          shipNameValidation: false,
        });
      }
    );
  };

  /* <summary>
  date: 23/02/2023
  Name: HV
  description:  replace the data of the ship and client in email body
  <summary> */

  replaceClientAndShipName = (clientName, shipName) => {
    const { emailTypeDataList, dropdown_emailTypeFilter } = this.state;

    if (parseInt(dropdown_emailTypeFilter, 10) != 0) {
      var getEmailDefaultData = emailTypeDataList.filter(
        (e) => e.emailDefaultId === parseInt(dropdown_emailTypeFilter, 10)
      )[0];

      let emailText = getEmailDefaultData.emailText;
      let emailSubject = getEmailDefaultData.emailSubject;

      if (clientName != "") {
        emailText = emailText.replace("[clientName]", clientName);
        emailSubject = emailSubject.replace("[clientName]", clientName);
      }

      if (shipName != "") {
        emailText = emailText.replace("[shipName]", shipName);
        emailSubject = emailSubject.replace("[shipName]", shipName);
      }

      let emailBodyToDisplay = ConvertFromHTML(emailText);
      this.setState({
        emailBody: emailBodyToDisplay,
        subject: emailSubject,
      });
    }
  };

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: On text changed, fill in all typed text
    <summary> */
  onTextChange = (e) => {
    const { showErrorMessage } = this.state;
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  /* <summary>
    date: 22/02/2023
    Name: HV
    description: method for validating fields
  <summary> */

  validateFields = () => {
    const {
      subject,
      errorMessages,
      listToEmail,
      showSaveTemplateButton,
      templateName,
      emailBody,
    } = this.state;

    let hasErrors = false;
    let errors = errorMessages;
    if (!showSaveTemplateButton) {
      errors["toValidation"] =
        listToEmail.length > 0
          ? ""
          : helperText.Communication.toAddressValidation;
    } else {
      errors["templateNameValidation"] = isStringEmpty(templateName)
        ? helperText.Communication.templateNameValidation
        : "";
    }

    errors["subjValidation"] = isStringEmpty(subject)
      ? helperText.Communication.subjectValidation
      : "";

    const contentState = emailBody.getCurrentContent();
    const plainText = contentState.getPlainText().trim();
    errors["bodyValidation"] =
      isStringEmpty(plainText)
        ? helperText.Communication.emailBodyValidation
        : "";

    if (
      errors["toValidation"] != "" ||
      errors["subjValidation"] != "" ||
      errors["bodyValidation"] != "" ||
      errors["templateNameValidation"]
    ) {
      hasErrors = true;
    }

    if (parseInt(this.state.dropdown_emailTypeFilter, 10) != 0) {
      let emailText = plainText;
      let emailSubject = subject;

      if (
        !isStringEmpty(this.state.clientName) &&
        isStringEmpty(this.state.shipName) &&
        ((!isStringEmpty(plainText) &&
          emailText.includes("[shipName]")) ||
          (!isStringEmpty(subject) && emailSubject.includes("[shipName]")))
      ) {
        if (this.state.shipDataList.length == 0) {
          emailText = emailText.replace("[shipName]", "");
          emailSubject = emailSubject.replace("[shipName]", "");
          this.setState({
            emailBody: emailText,
            subject: emailSubject,
            shipNameValidation: false,
          });
        } else {
          hasErrors = true;
          this.setState({ shipNameValidation: true });
        }
      } else {
        this.setState({ shipNameValidation: false });
      }

      if (
        isStringEmpty(this.state.clientName) &&
        ((!isStringEmpty(plainText) &&
          emailText.includes("[clientName]")) ||
          (!isStringEmpty(subject) && emailSubject.includes("[clientName]")))
      ) {
        hasErrors = true;
        this.setState({
          clientNameValidation: true,
          shipNameValidation: false,
        });
      } else {
        this.setState({ clientNameValidation: false });
      }
    }

    this.setState({
      errorMessages: errors,
      showErrorMessage: hasErrors,
    });

    return hasErrors;

  };

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: On send click, save the data in communication also send on email
    <summary> */
  onSendClick = async () => {
    try {
      if (parseInt(this.state.dropdown_emailTypeFilter, 10) != 0) {
        const {
          subject,
          emailType,
          dropdown_emailTypeFilter,
          documentFile,
          dropdown_clientFilter,
          dropdown_shipFilter,
          loggedInUserId,
          listToEmail,
          listCcEmail,
          listBccEmail,
          emailBody
        } = this.state;

        if (!this.validateFields()) {
          this.setState({
            isLoading: true,
          });

          let emailBodyToSave = sanitizeHTML(ConvertToHtml(emailBody));
          let communicationData = new FormData();
          communicationData.append(
            "ClientId",
            parseInt(dropdown_clientFilter, 10)
          );
          communicationData.append("ShipId", parseInt(dropdown_shipFilter, 10));
          communicationData.append(
            "EmailDefaultId",
            parseInt(dropdown_emailTypeFilter, 10)
          );
          communicationData.append("EmailType", emailType);
          communicationData.append("EmailSubject", "IHMM - " + subject.trim());
          communicationData.append("EmailText", emailBodyToSave);
          communicationData.append(
            "ToAddress",
            listToEmail.length > 0 ? listToEmail.join(",") : ""
          );
          communicationData.append(
            "Ccaddress",
            listCcEmail.length > 0 ? listCcEmail.join(",") : ""
          );
          communicationData.append(
            "Bccaddress",
            listBccEmail.length > 0 ? listBccEmail.join(",") : ""
          );
          communicationData.append("CreatedBy", loggedInUserId);
          communicationData.append("isCommunication", true);

          let files = documentFile;
          var ins = files.length;

          for (var x = 0; x < ins; x++) {
            if (files[x].size !== null && files[x].size !== undefined) {
              communicationData.append("fileList", files[x]);
            }
          }

          var responseFilters = await CommunicationService.AddCommunication(
            communicationData
          );

          if (responseFilters == undefined) {
            this.setState({
              toastVisible: true,
              toastMessage: helperText.global.somethingWentWrong,
              toastResponseType: helperText.global.toastError,
              isLoading: false,
            });
          } else if (responseFilters != null) {
            this.setState(
              {
                toastVisible: true,
                toastMessage: helperText.Communication.communicationAdded,
                toastResponseType: helperText.global.toastSuccess,
                isLoading: false,
              },
              () => {
                this.GetAllFilterDropdown(true);
              }
            );
          } else {
            this.setState({
              toastVisible: true,
              toastMessage: helperText.global.somethingWentWrong,
              toastResponseType: helperText.global.toastError,
              isLoading: false,
            });
          }
        }
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.selectSubject,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } catch (error) { }
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: On replace or change of attached file
    <summary> */
  onAttachmentChange = async (e) => {
    const { name, value } = e.target;
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const uploadedFile = [];

    chosenFiles.some((x) => {
      if (!isValidFile(x.name, validFileFormatsExt)) {
        this.setState({
          errorFile: true,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.wrongCommunicationFileError,
        });
      }
      else {
        uploadedFile.push(x);
        this.setState({
          errorFile: false,
          documentFile: uploadedFile,
          fileName: e.target.files[0].name,
          docFileName: e.target.files[0].name,
          filePath: e.target.value,
        });
      }
    });
  };

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: On editer text entered convert it to html and apply the styles selected from editor
    <summary> */
  HandleTextChange = (evt) => {
    var newContent = evt;//.editor.getData();
    this.setState(
      {
        emailBody: newContent,
      },
      () => {
        if (this.state.showErrorMessage) this.validateFields();
      }
    );
  };

  /* <summary>
    date: 15/11/2022
    Name: DN
    description: Open communication history
    <summary> */
  onViewHistoryClick = () => {
    this.props.navigate("/CommunicationHistory");
  };

  /* <summary>
 date: 13/02/2023
 Name: HV
 description: clearing filter
 <summary> */

  clearAllFilter = () => {
    this.setState({
      dropdown_clientFilter: "0",
      dropdown_shipFilter: "0",
      dropdown_emailTypeFilter: "0",
      emailDefaultId: 0,
      listToEmail: [],
      listCcEmail: [],
      listBccEmail: [],
      shipDataList: [],
      shipName: "",
      clientName: "",
      subject: "",
      emailBody: EditorState.createEmpty(),
      emailType: "",
      showSaveTemplateButton: false,
      documentFile: [],
    });

  };

  /* <summary>
   date: 21/02/2023
   Name: HV
   description: function for clicking send and receive tab
   <summary> */

  onTabClicked = (type) => {
    if (type == "send") {
      this.setState({ sendSection: true, receivedSection: false });
    } else {
      //this.props.navigate("/OutlookTest")
      this.setState({ sendSection: false, receivedSection: true });
    }
  };

  /* <summary>
   date: 22/02/2023
   Name: HV
   description: email change
   <summary> */

  onEmailChange = (emailId, name) => {
    const { errorMessages } = this.state;
    if (name == "to") {
      errorMessages["toValidation"] = "";
      this.setState({ listToEmail: emailId });
    } else if (name == "cc") {
      errorMessages["ccValidation"] = "";
      this.setState({ listCcEmail: emailId });
    } else if (name == "bcc") {
      errorMessages["bccValidation"] = "";
      this.setState({ listBccEmail: emailId });
    }

    this.setState({ errorMessages: errorMessages });
  };

  /* <summary>
 date: 22/02/2023
 Name: HV
 description: validating email
 <summary> */

  validateEmail = (email, name) => {
    const { errorMessages } = this.state;

    let errors = errorMessages;
    let validEmail = false;

    if (name == "to") {
      if (isEmail(email)) {
        errors["toValidation"] = "";
        validEmail = true;
      } else {
        errors["toValidation"] =
          helperText.Communication.invalidEmailValidation;
        validEmail = false;
      }
    }
    else if (name == "cc") {
      if (isEmail(email)) {
        errors["ccValidation"] = "";
        validEmail = true;
      } else {
        errors["ccValidation"] =
          helperText.Communication.invalidEmailValidation;
        validEmail = false;
      }
    } else if (name == "bcc") {
      if (isEmail(email)) {
        errors["bccValidation"] = "";
        validEmail = true;
      } else {
        errors["bccValidation"] =
          helperText.Communication.invalidEmailValidation;
        validEmail = false;
      }
    }

    this.setState({ errorMessages: errors }); //, showErrorMessage: !validEmail
    return validEmail;
  };

  /* <summary>
   date: 22/02/2023
   Name: HV
   description: on cross icon click for files
   <summary> */

  onClearSingleFileCapsule = (name) => {
    var array = this.state.documentFile.filter((x) => x.name != name);
    this.setState({ documentFile: array });
  };

  /* <summary>
   date: 22/02/2023
   Name: HV
   description: saving template
   <summary> */

  onSaveTemplateClick = async () => {
    try {
      const {
        listCcEmail,
        listBccEmail,
        subject,
        templateName,
        emailDefaultId,
        emailBody,
        loggedInUserId,
      } = this.state;

      if (!this.validateFields()) {
        this.setState({ isLoading: true });

        let emailBodyToSave = sanitizeHTML(ConvertToHtml(emailBody));
        const emailModel = {
          EmailDefaultId: emailDefaultId == -1 ? 0 : emailDefaultId,
          EmailType: templateName,
          EmailSubject: subject,
          EmailText: emailBodyToSave,
          Ccaddress: listCcEmail.length > 0 ? listCcEmail.join(",") : "",
          Bccaddress: listBccEmail.length > 0 ? listBccEmail.join(",") : "",
          CreatedBy: loggedInUserId,
          EditedBy: loggedInUserId,
        };

        var responseFilters = await CommunicationService.AddEditEmailTemplate(
          emailModel
        );

        if (responseFilters == undefined) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastResponseType: helperText.global.toastError,
            isLoading: false,
          });
        } else if (responseFilters != null) {
          this.setState(
            {
              toastVisible: true,
              toastMessage: helperText.global.saveSuccess,
              toastResponseType: helperText.global.toastSuccess,
              isLoading: false,
            },
            () => {
              this.GetAllFilterDropdown(true);
            }
          );
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastResponseType: helperText.global.toastError,
            isLoading: false,
          });
        }
      }
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({
      toastVisible: false,
      toastMessage: "",
      toastResponseType: "",
    });
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");
    if (loc == "/Training") {
      this.props.navigate("/Training");
    } else this.props.navigate("/Dashboard");
  };

  render() {
    const {
      clientDataList,
      shipDataList,
      emailTypeDataList,
      errorMessages,
      subject,
      emailBody,
      toastVisible,
      toastResponseType,
      toastMessage,
      fileInputKey,
      dropdown_clientFilter,
      dropdown_shipFilter,
      dropdown_emailTypeFilter,
      accessList,
      sendSection,
      receivedSection,
      listToEmail,
      listCcEmail,
      listBccEmail,
      isLoading,
      showSaveTemplateButton,
      templateName,
      loggedInUserId
    } = this.state;

    const ckEditorRemoveButtons = "ImageButton,Button,Undo,SelectAll,Spell Checker,Checkbox,Redo,Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Form,Radio,TextField,Textarea,Select,HiddenField,About,CopyFormatting,RemoveFormat,Language,Flash,Iframe";

    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"Communication"} />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="communications communications-page">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8 heading-bottom-space">
                  <div className="page-title-class">
                    <img
                      className="back-btn-responsive"
                      src="images/back button image.png"
                      height="22px"
                      style={{ pointerEvents: "all" }}
                      onClick={this.goback}
                    />
                    <span className="title-font">Communications</span>
                  </div>
                </div>
              </div>
              <div className="tab">
                <button
                  className="tablinks"
                  onClick={() => this.onTabClicked("send")}
                  style={{
                    backgroundColor: sendSection ? "#1c3d74" : "",
                    color: sendSection ? "white" : "",
                    borderTopLeftRadius: 8,
                  }}
                >
                  Send
                </button>
                <button
                  className="tablinks"
                  onClick={() => this.onTabClicked("received")}
                  style={{
                    backgroundColor: receivedSection ? "#1c3d74" : "",
                    color: receivedSection ? "white" : "",
                    borderTopRightRadius: 8,
                  }}
                >
                  Received
                </button>
              </div>
              {sendSection && (
                <div id="send-communications" className="tabcontent">
                  <div className="accordion-filter-dropdown communications-history-form">
                    <div className="accordion" id="myAccordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            type="button"
                            className="accordion-button collapsed filter-toggle-btn"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                          >
                            Filter By
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="card-body filter-dropdwn-body">
                            <div className="form-group dropdown-form" id={"clientName"}>
                              <Dropdown
                                dropdownLabel="Client Name:"
                                name="clientFilter"
                                onChange={this.onClientSelected}
                                selected={dropdown_clientFilter}
                                disabled={
                                  showSaveTemplateButton ||
                                  this.state.userRolePermissionRead
                                }
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre",
                                  overflow: "hidden",
                                }}
                              >
                                {clientDataList == null
                                  ? null
                                  : clientDataList.map((item) => (
                                    <option
                                      wrap="true"
                                      key={item.clientId}
                                      value={item.clientId}
                                    >
                                      {item.clientName}
                                    </option>
                                  ))}
                              </Dropdown>
                              {dropdown_clientFilter > 0 ? (
                                <UncontrolledTooltip
                                  autohide={false}
                                  target={"clientName"}
                                  aria-haspopup="true"
                                  role="example"
                                  effect="solid"
                                  placement="bottom"
                                  style={{ width: "350px" }}
                                >
                                  {clientDataList != null && clientDataList.filter(x => x.clientId == dropdown_clientFilter)[0]?.clientName}
                                </UncontrolledTooltip>) : null}
                            </div>
                            <div className="form-group dropdown-form" id={"shipName"}>
                              <Dropdown
                                dropdownLabel="Ship Name:"
                                name="shipFilter"
                                onChange={this.onShipSelected}
                                selected={dropdown_shipFilter}
                                disabled={
                                  showSaveTemplateButton ||
                                  this.state.userRolePermissionRead
                                }
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre",
                                  overflow: "hidden",
                                }}
                              >
                                {shipDataList == null
                                  ? null
                                  : shipDataList.map((item) => (
                                    <option
                                      wrap="true"
                                      key={item.shipId}
                                      value={item.shipId}
                                    >
                                      {item.shipName}
                                    </option>
                                  ))}
                              </Dropdown>
                              {dropdown_shipFilter > 0 ? (
                                <UncontrolledTooltip
                                  autohide={false}
                                  target={"shipName"}
                                  aria-haspopup="true"
                                  role="example"
                                  effect="solid"
                                  placement="bottom"
                                  style={{ width: "350px" }}
                                >
                                  {shipDataList != null && shipDataList.filter(x => x.shipId == dropdown_shipFilter)[0]?.shipName}
                                </UncontrolledTooltip>) : null}
                            </div>
                            <div className="form-group" id={"subject"}>
                              <Dropdown
                                dropdownLabel="Subject:"
                                name="emailTypeFilter"
                                disabled={this.state.userRolePermissionRead}
                                onChange={this.onEmailTypeSelected}
                                selected={dropdown_emailTypeFilter}
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre",
                                  overflow: "hidden",
                                }}
                              >
                                {emailTypeDataList == null
                                  ? null
                                  : emailTypeDataList.map((item) => (
                                    <option
                                      wrap="true"
                                      key={item.emailDefaultId}
                                      value={item.emailDefaultId}
                                      style={{
                                        backgroundColor: item.isCustomEmail
                                          ? "#C4DAF5"
                                          : "",
                                      }}
                                    >
                                      {/* <li> */}
                                      {item.emailType}
                                      {/* </li> */}
                                    </option>
                                  ))}
                              </Dropdown>
                              {dropdown_emailTypeFilter > 0 ? (
                                <UncontrolledTooltip
                                  autohide={false}
                                  target={"subject"}
                                  aria-haspopup="true"
                                  role="example"
                                  effect="solid"
                                  placement="bottom"
                                  style={{ width: "350px" }}
                                >
                                  {emailTypeDataList != null && emailTypeDataList.filter(x => x.emailDefaultId == dropdown_emailTypeFilter)[0]?.emailType}
                                </UncontrolledTooltip>) : null}
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 clear-all-btn">
                              <button
                                type="button"
                                className="btn btn-blue-bg"
                                onClick={this.clearAllFilter}
                              >
                                Reset
                              </button>
                            </div>
                            <div className="filter-btn manage-add-button">
                              <button
                                type="button"
                                className="btn btn-background-color  buttons-alignment view-history-responsive view-btn-responsive "
                                onClick={this.onViewHistoryClick}
                              >
                                View History
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="desktop-class">
                    <div className="row">
                      <div className="col-lg-12 filter-flex-container wrap">
                        <div className="filter-box filter-flex-item form-label" id={"clientName1"}>
                          <Dropdown
                            dropdownLabel="Client Name:"
                            name="clientFilter"
                            onChange={this.onClientSelected}
                            selected={dropdown_clientFilter}
                            disabled={
                              showSaveTemplateButton ||
                              this.state.userRolePermissionRead
                            }
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {clientDataList == null
                              ? null
                              : clientDataList.map((item) => (
                                <option
                                  wrap="true"
                                  key={item.clientId}
                                  value={item.clientId}
                                >
                                  {item.clientName}
                                </option>
                              ))}
                          </Dropdown>
                          {dropdown_clientFilter > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"clientName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {clientDataList != null && clientDataList.filter(x => x.clientId == dropdown_clientFilter)[0]?.clientName}
                            </UncontrolledTooltip>) : null}
                        </div>
                        <div className="filter-box filter-flex-item form-label" id={"shipName1"}>
                          <Dropdown
                            dropdownLabel="Ship Name:"
                            name="shipFilter"
                            onChange={this.onShipSelected}
                            selected={dropdown_shipFilter}
                            disabled={
                              showSaveTemplateButton ||
                              this.state.userRolePermissionRead
                            }
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {shipDataList == null
                              ? null
                              : shipDataList.map((item) => (
                                <option
                                  wrap="true"
                                  key={item.shipId}
                                  value={item.shipId}
                                >
                                  {item.shipName}
                                </option>
                              ))}
                          </Dropdown>
                          {dropdown_shipFilter > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"shipName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {shipDataList != null && shipDataList.filter(x => x.shipId == dropdown_shipFilter)[0]?.shipName}
                            </UncontrolledTooltip>) : null}
                        </div>

                        <div className="filter-box filter-flex-item form-label" id={"subject1"}>
                          <Dropdown
                            dropdownLabel="Subject:"
                            name="emailTypeFilter"
                            onChange={this.onEmailTypeSelected}
                            disabled={this.state.userRolePermissionRead}
                            selected={dropdown_emailTypeFilter}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {emailTypeDataList == null
                              ? null
                              : emailTypeDataList.map((item) => (
                                <option
                                  wrap="true"
                                  key={item.emailDefaultId}
                                  value={item.emailDefaultId}
                                  style={{
                                    backgroundColor: item.isCustomEmail
                                      ? "#C4DAF5"
                                      : "",
                                  }}
                                >
                                  {item.emailType}
                                </option>
                              ))}
                          </Dropdown>
                          {dropdown_emailTypeFilter > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"subject1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {emailTypeDataList != null && emailTypeDataList.filter(x => x.emailDefaultId == dropdown_emailTypeFilter)[0]?.emailType}
                            </UncontrolledTooltip>) : null}
                        </div>
                        <div className="filter-box clear-all-btn">
                          <button
                            type="button"
                            className="btn btn-blue-bg"
                            onClick={this.clearAllFilter}
                          >
                            Reset
                          </button>
                        </div>
                        <div className="filter-btn manage-add-button">
                          <button
                            type="button"
                            className="btn btn-background-color  buttons-alignment view-history-responsive view-btn-responsive "
                            onClick={this.onViewHistoryClick}
                          >
                            View History
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="message-template">
                    <div className="communications-form">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="template-title" style={{ marginTop: "5px" }}>
                            <strong> Message Template </strong>{" "}
                          </h5>
                          <div className="row">
                            <div className="col-md-12">
                              <form className="form-inline communication-send-tab-width">
                                <div className="row ticket-email-form">
                                  {showSaveTemplateButton && (
                                    <>
                                      <div className="col-12 col-sm-12 col-lg-2 email-template"
                                        style={{ alignSelf: "center", paddingBottom: "24px" }}>
                                        <label
                                          htmlFor="templateName"
                                          className="label-class label-alignment required-field"
                                        >
                                          Template Name:
                                        </label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-lg-10 email-template">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.templateNameValidation
                                            )
                                              ? "invalid-position-multi-email invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            className="field-width"
                                            type="text"
                                            id="templateName"
                                            name="templateName"
                                            value={templateName}
                                            onChange={this.onTextChange}
                                          />
                                          {!isStringEmpty(
                                            errorMessages.templateNameValidation
                                          ) && (
                                              <>
                                                <div className="invalid-message-icon">
                                                  <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                                <div className="invalid-login-email-message">
                                                  {
                                                    errorMessages.templateNameValidation
                                                  }
                                                </div>
                                              </>
                                            )}
                                        </div>
                                        <br />
                                      </div>
                                    </>
                                  )}
                                  {!showSaveTemplateButton && (
                                    <>
                                      <div className="col-12 col-sm-12 col-lg-2 "
                                        style={{ alignSelf: "center", paddingBottom: "24px" }}>
                                        <label
                                          className="label-class required-field"
                                          htmlFor="to"
                                        >
                                          To:
                                        </label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-lg-10">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.toValidation
                                            )
                                              ? "invalid-position-multi-email"
                                              : ""
                                          }
                                        >
                                          <ReactMultiEmail
                                            placeholder="Enter Email ID"
                                            emails={listToEmail}
                                            onChange={(_emails) => this.onEmailChange(_emails, "to")}
                                            validateEmail={(email) =>
                                              this.validateEmail(email, "to")
                                            }
                                            style={{
                                              resize: "none",
                                              fontSize: "14px",
                                            }}
                                            getLabel={(
                                              email,
                                              index,
                                              removeEmail = index()
                                            ) => {
                                              return (
                                                <div data-tag key={index}>
                                                  {email}
                                                  <span
                                                    data-tag-handle
                                                    onClick={() =>
                                                      removeEmail(index)
                                                    }
                                                  >
                                                    ×
                                                  </span>
                                                </div>
                                              );
                                            }}
                                          />

                                          {!isStringEmpty(
                                            errorMessages.toValidation
                                          ) && (
                                              <div>
                                                <div className="invalid-message-icon">
                                                  <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                                <div className="invalid-login-email-message">
                                                  {errorMessages.toValidation}
                                                </div>
                                              </div>
                                            )}
                                        </div>

                                        <br />
                                      </div>
                                    </>
                                  )}
                                  <div className="col-12 col-sm-12 col-lg-2 "
                                    style={{ alignSelf: "center", paddingBottom: "24px" }}>
                                    <label
                                      htmlFor="cc"
                                      className="label-class label-alignment"
                                    >
                                      {/* required-field */}
                                      CC:
                                    </label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-lg-10">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.ccValidation
                                        )
                                          ? "invalid-position-multi-email"
                                          : ""
                                      }
                                    >
                                      <ReactMultiEmail
                                        placeholder="Enter CC Email ID"
                                        emails={listCcEmail}
                                        onChange={(_emails) =>
                                          this.onEmailChange(_emails, "cc")
                                        }
                                        validateEmail={(email) =>
                                          this.validateEmail(email, "cc")
                                        }
                                        style={{
                                          resize: "none",
                                          fontSize: "14px",
                                        }}
                                        getLabel={(
                                          email,
                                          index,
                                          removeEmail = index()
                                        ) => {
                                          return (
                                            <div data-tag key={index}>
                                              {email}
                                              <span
                                                data-tag-handle
                                                onClick={() =>
                                                  removeEmail(index)
                                                }
                                              >
                                                ×
                                              </span>
                                            </div>
                                          );
                                        }}
                                      />

                                      {!isStringEmpty(
                                        errorMessages.ccValidation
                                      ) && (
                                          <>
                                            <div className="invalid-message-icon">
                                              <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="invalid-login-email-message">
                                              {errorMessages.ccValidation}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                    <br />
                                  </div>
                                  <div className="  col-12 col-sm-12 col-lg-2 "
                                    style={{ alignSelf: "center", paddingBottom: "24px" }}>
                                    <label
                                      htmlFor="bcc"
                                      className="label-class label-alignment"
                                    >
                                      {/* required-field */}
                                      BCC:
                                    </label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-lg-10">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.bccValidation
                                        )
                                          ? "invalid-position-multi-email"
                                          : ""
                                      }
                                    >
                                      <ReactMultiEmail
                                        placeholder="Enter BCC Email ID"
                                        emails={listBccEmail}
                                        onChange={(_emails) =>
                                          this.onEmailChange(_emails, "bcc")
                                        }
                                        validateEmail={(email) =>
                                          this.validateEmail(email, "bcc")
                                        }
                                        style={{
                                          resize: "none",
                                          fontSize: "14px",
                                        }}
                                        getLabel={(
                                          email,
                                          index,
                                          removeEmail = index()
                                        ) => {
                                          return (
                                            <div data-tag key={index}>
                                              {email}
                                              <span
                                                data-tag-handle
                                                onClick={() =>
                                                  removeEmail(index)
                                                }
                                              >
                                                ×
                                              </span>
                                            </div>
                                          );
                                        }}
                                      />

                                      {!isStringEmpty(
                                        errorMessages.bccValidation
                                      ) && (
                                          <>
                                            <div className="invalid-message-icon">
                                              <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="invalid-login-email-message">
                                              {errorMessages.bccValidation}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                    <br />
                                  </div>
                                  <div className="col-12 col-sm-12 col-lg-2 email-template"
                                    style={{ alignSelf: "center", paddingBottom: "24px" }}>
                                    <label
                                      htmlFor="subject"
                                      className="label-class label-alignment required-field"
                                    >
                                      Subject:
                                    </label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-lg-10 email-template">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.subjValidation
                                        )
                                          ? "invalid-position-multi-email invalid-position"
                                          : ""
                                      }
                                    >
                                      <input
                                        maxLength={200}
                                        className="field-width"
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        autoComplete="new-password"
                                        value={subject}
                                        onChange={this.onTextChange}
                                      />
                                      {!isStringEmpty(
                                        errorMessages.subjValidation
                                      ) && (
                                          <>
                                            <div className="invalid-message-icon">
                                              <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="invalid-login-email-message">
                                              {errorMessages.subjValidation}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                    <br />
                                  </div>
                                  <div className="col-12 col-sm-12 col-lg-2 email-template"
                                    style={{ alignSelf: "center" }}>
                                    <label
                                      htmlFor="message"
                                      className="label-class label-alignment required-field"
                                    >
                                      Message:
                                    </label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-lg-10 email-template-bottomspace input-validation">
                                    <Editor
                                      editorState={emailBody}
                                      placeholder="Enter Comments"
                                      editorStyle={{ height: 200, border: '1px solid #ced4da', paddingLeft: '10px', background: '#ffffff' }}
                                      wrapperClassName="demo-wrapper "
                                      editorClassName="editer-content editor-height"
                                      toolbarClassName="toolbar-class candrag"
                                      onEditorStateChange={(e) => {
                                        this.HandleTextChange(e);
                                      }}
                                      handlePastedText={(val) => {
                                        const textLength =
                                          emailBody
                                            .getCurrentContent()
                                            .getPlainText().length;
                                        return (
                                          val.length + textLength
                                        );
                                      }}
                                      toolbar={toolbarOptions}
                                    />

                                    {!isStringEmpty(
                                      errorMessages.bodyValidation
                                    ) && (
                                        <div className="invalid-message">
                                          <div className="invalid-login-email-message">
                                            {errorMessages.bodyValidation}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  {!showSaveTemplateButton && (
                                    <>
                                      <div className=" col-12 col-sm-12 col-lg-2 email-template-bottomspace">
                                        <label
                                          htmlFor="attachments"
                                          className="label-class "
                                        >
                                          Attachments:
                                        </label>
                                      </div>

                                      <div className="col-12 col-sm-12 col-lg-10 email-template-bottomspace">
                                        <div
                                          className="choosefile-btn"
                                          onClick={() =>
                                            this.refs.fileInput.click()
                                          }
                                          style={{ width: "160px" }}
                                        >
                                          <label
                                            className={"attach-btn chs-btn "}
                                          >
                                            <img
                                              className="upload-img"
                                              src="images/Icon awesome-upload.png"
                                              width="18px"
                                            />
                                            &nbsp;Choose File
                                          </label>
                                          <input
                                            ref="fileInput"
                                            type="file"
                                            accept={validFileFormatsReadable}
                                            id="files"
                                            name="documentFile"
                                            onChange={this.onAttachmentChange}
                                            key={fileInputKey || ""}
                                            style={{
                                              display: "none",
                                            }}
                                            multiple
                                          />
                                        </div>
                                        {/*   &nbsp; (Please select PDF, Excel, Word or Image files) */}
                                        <label>
                                          &nbsp; (Please select PDF, Excel, Word
                                          or Image files)
                                        </label>

                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            paddingLeft: 0,
                                          }}
                                        >
                                          {this.state.documentFile.map(
                                            (file, index) => (
                                              <li key={index}>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    marginRight: "6px",
                                                    borderRadius: "3px",
                                                    padding: ".5833em .833em",
                                                    backgroundColor: "#e3e1e1",
                                                    fontSize: ".8rem",
                                                    border: "none",
                                                    margin: "8px 8px 8px 0px",
                                                  }}
                                                >
                                                  <span className="label-breakword"
                                                    style={{ marginRight: 5 }}
                                                  >
                                                    {" "}
                                                    {file.name}
                                                  </span>
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      this.onClearSingleFileCapsule(
                                                        file.name
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: 4,
                                                      border: "none",
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="admin-ship-settings-save-btn text-center">
                    {this.state.shipNameValidation && (
                      <div className="invalid-message-icon-box">
                        <img
                          src="images/icon material-error.png"
                          alt="invalid-icon"
                        />
                        <p>Please select Ship Name.</p>
                      </div>
                    )}
                    {this.state.clientNameValidation && (
                      <div className="invalid-message-icon-box">
                        <img
                          src="images/icon material-error.png"
                          alt="invalid-icon"
                        />
                        <p>Please select Client Name.</p>
                      </div>
                    )}
                    {!showSaveTemplateButton && (
                      <button
                        type="button"
                        disabled={
                          accessList != null
                            ? accessList.communicationAccess == UserAction.READ
                              ? true
                              : false
                            : false
                        }
                        className="btn btn-background-color send-btn"
                        onClick={this.onSendClick}
                        style={{ cursor: "pointer" }}
                      >
                        Send
                      </button>
                    )}
                    {showSaveTemplateButton && (
                      <button
                        type="button"
                        className="btn btn-background-color send-template"
                        style={{ cursor: "pointer" }}
                        onClick={this.onSaveTemplateClick}
                      >
                        Save as Template
                      </button>
                    )}
                  </div>
                </div>
              )}
              {receivedSection && (
                <RecievedSection
                  userID={loggedInUserId}
                  emailTypeDataList={emailTypeDataList.map((e) => e.emailType)}
                  setIsLoading={(val) => this.setState({ isLoading: val })}
                />
              )}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Communication);
