import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { helperText } from "../../helpers/helperText";
import ViewModal from "../../components/ViewModal";
import DatePicker from "../../components/DatePickerComponent";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import deleteIcon from "../../assets/images/delete icon.png";
import admindownload from "../../assets/images/admin-download.png";
import admineye from "../../assets/images/admin-eye.png";
import magnifyingglass from "../../assets/images/magnifying-glass.png";
import ReportsModal from "./ReportsModal";
import { ReportService } from "../../services/report.service";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
  TitlePageNames,
  UserAction,
  REPORT_DOC_TYPE,
  UserRole,
  DocumentTypeName,
  ReportName,
  ReportsDescription,
} from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { saveAs } from 'file-saver';
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TextRun,
  AlignmentType,
} from "docx";


var currentDate = new Date();
let htmlString = "";
let fileNameDownload = "";
const tableData = [];


class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedReports: [],
      defaultReports: [],
      clientNameList: [],
      dropdown_clientName: 0,
      shipNameList: [],
      defaultShipNameList: [],
      dropdown_shipName: 0,
      regionList: [],
      dropdown_region: 0,
      defaultRegionList: [],
      supplierList: [],
      dropdown_supplier: 0,
      documentTypeList: [],
      dropdown_documenttype: 0,
      dropdown_hazmatpresent: 0,
      documentNameList: [],
      dropdown_documentname: 0,
      lineNameList: [],
      dropdown_linename: 0,
      showUploadModal: false,
      userId: 0,
      flagStateList: [],
      trackingToolYearList: [],
      showViewModal: false,
      filePathToView: "",
      selectedDocListIds: [],
      lineNameOBJList: [],
      saveData: [],
      showConfirmationPopup: false,
      selectdDocDeleteId: 0,
      accessList: [],
      searchedText: "",
      classificationSocietyList: [],
      tableAList: [],
      tableBList: [],
      dropdown_tableAFilter: 0,
      dropdown_tableBFilter: 0,
      filter_fromDate: "",
      filter_toDate: "",
      isGenerateReport: false,
      clientRegionBrigdeList: [],
      customOverlayTemplate: `<div> <img src="images/import.png" alt="import" style="height:70px" /> <p style="font-size: 16px;color: #1861ac;padding-top: 14px;">The selected type of report can only be generated.<p></div>`,
      isShipCrew: false,
      clientUserId: 0,
      isClientOrClientUser: false,
      nextFromDate: "",
      previousToDate: currentDate,
      typeId: "",
      gridApi: null,
      userRolePermissionRead: false,
      isUploadDocClick: false,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.REPORTS);

    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      let isClientUser = userData.typeId == UserRole.CLIENTUSER;
      this.setState(
        {
          typeId: userData.typeId,
          userId: isClientUser ? userData.clientId : userData.id,
          name: userData.name,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          isClientOrClientUser:
            userData.typeId == UserRole.CLIENT || isClientUser,
          isShipCrew: userData.typeId == UserRole.SHIPCREW,
          clientUserId: isClientUser ? userData.id : 0,
        },
        () => {
          this.GetReportsData();
        }
      );
    }
  }

  // componentDidUpdate() {
  //   if (this.state.gridApi) {
  //     this.state.gridApi.paginationGoToPage(0);
  //   }
  // }

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:gets ihm database and  registry grid data
   <summary> */
  GetReportsData = async () => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.reportAccess == UserAction.READ,
      });

      const { clientUserId, userId, typeId } = this.state;

      const response = await ReportService.GetReportData(
        userId,
        clientUserId,
        typeId
      );

      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        }
        var client = response.data.dropdownList.clientList;
        var ship = response.data.dropdownList.shipList;
        var region = response.data.dropdownList.regionList;
        var supplier = response.data.dropdownList.supplierList;
        var flagstate = response.data.dropdownList.flagStateList;
        var documenttype = response.data.dropdownList.documentTypeList;
        var tableAList = response.data.dropdownList.tableA;
        var tableBList = response.data.dropdownList.tableB;
        var clientRegionBrigdeList = response.data.clientRegionBrigdeList;
        var classificationSocietyList =
          response.data.dropdownList.classificationSocietyList;
        var linename = Array.from([
          ...new Set(
            response.data.dropdownList.lineNameList.map((l) => l.label)
          ),
        ]);
        var linenameobj = response.data.dropdownList.lineNameList;
        var year = response.data.dropdownList.year;

        let documentNames = Array.from([
          ...new Set(
            response.data.reportList.map((l) => l.customeDocumentName)
          ),
        ]);

        this.setState({
          mappedReports: this.state.isClientOrClientUser
            ? response.data.reportList.filter(
              (x) => x.clientId == this.state.userId
            )
            : this.state.isShipCrew
              ? response.data.reportList
                .filter((x) => x.shipId)
                .filter((x) => x.shipId == this.state.userId)
              : response.data.reportList,
          defaultReports: this.state.isClientOrClientUser
            ? response.data.reportList.filter(
              (x) => x.clientId == this.state.userId
            )
            : this.state.isShipCrew
              ? response.data.reportList
                .filter((x) => x.shipId)
                .filter((x) => x.shipId == this.state.userId)
              : response.data.reportList,
          clientNameList: client,
          shipNameList: this.state.isClientOrClientUser
            ? ship.length > 0
              ? ship.filter((x) => x.parentValue == this.state.userId)
              : []
            : ship,
          //SYJ Code Commented as default the dropdown name were getting empty on DD select and if Document Uploaded
          dropdown_clientName: this.state.isUploadDocClick ? this.state.dropdown_clientName : this.state.isClientOrClientUser
            ? this.state.userId
            : this.state.isShipCrew
              ? ship.length > 0
                ? ship.filter((x) => x.value == this.state.userId)[0].parentValue
                : 0
              : 0,
          dropdown_shipName: this.state.isUploadDocClick ? this.state.dropdown_shipName : this.state.isShipCrew ? this.state.userId : 0,
          regionList: region,
          supplierList: supplier,
          documentTypeList: documenttype,
          flagStateList: flagstate,
          lineNameList: linename,
          trackingToolYearList: year,
          lineNameOBJList: linenameobj,
          documentNameList: documentNames,
          defaultShipNameList: ship,
          defaultRegionList: region,
          tableAList: tableAList,
          tableBList: tableBList,
          classificationSocietyList: classificationSocietyList,
          clientRegionBrigdeList: clientRegionBrigdeList,
          isUploadDocClick: false
        },
          () => {
            this.filterGridView();
          }
        );
      } else {
        this.setState({
          mappedReports: [],
          defaultReports: [],
          clientNameList: [],
          shipNameList: [],
          regionList: [],
          supplierList: [],
          documentTypeList: [],
          flagStateList: [],
          lineNameList: [],
          trackingToolYearList: [],
          lineNameOBJList: [],
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          documentNameList: [],
          defaultShipNameList: [],
          defaultRegionList: [],
          tableAList: [],
          tableBList: [],
          classificationSocietyList: [],
          clientRegionBrigdeList: [],
          isUploadDocClick: false
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
    }
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:closes upload document modal
       <summary> */
  onCloseClick = () => {
    this.setState({ showUploadModal: false });
  };


  onSaveNewTypeClick = async (typeName) => {

    var docData = {
      TypeName: typeName
    }

    this.setState({
      isLoading: true,
    });

    var responseSaved =
      await ReportService.AddReportDocType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data);
      const { clientUserId, userId, typeId } = this.state;

      const response = await ReportService.GetReportData(
        userId,
        clientUserId,
        typeId
      );
      if (response != null && response != undefined && response.status == 200) {

        this.setState(
          {
            documentTypeList: response.data.dropdownList.documentTypeList,
            isLoading: false,
          })
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:show upload document modal 
   <summary> */
  showDocumentUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  showDeleteDocModal = (id) => {
    this.setState({ showConfirmationPopup: true, selectdDocDeleteId: id });
  };
  gridColumns = (hideColumn) => {
    return [
      {
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        unSortIcon: false,
      },
      {
        headerName: "Serial Number",
        //field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "Report Name",
        field: "customFileName",
        tooltipField: "customFileName",
        flex: hideColumn ? 1 : 0,
        width: 190,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Type of Report",
        field: "documentTypeName",
        tooltipField: "documentTypeName",
        width: 190,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Client Name",
        field: "clientName",
        tooltipField: "clientName",
        width: 150,
        hide: hideColumn,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Ship Name",
        field: "shipName",
        tooltipField: "shipName",
        width: 150,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Region",
        field: "regionName",
        tooltipField: "regionName",
        width: 150,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Created By",
        field: "createdByName",
        tooltipField: "createdByName",
        width: 150,
      },

      {
        headerName: "Date of Creation",
        field: "createdOnStr",
        tooltipField: "createdOnStr",
        width: 190,
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        width: 40,
        sortable: false,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={admineye}
            title="View"
            onClick={() => this.viewReportDocData(params.data.reportDocsId)}
            style={{ width: "20px" }}
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        width: 40,
        sortable: false,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={admindownload}
            title="Download"
            onClick={() => this.downloadReportDocData(params.data.reportDocsId)}
            style={{ width: "15px" }}
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        width: 40,
        sortable: false,
        hide: hideColumn || this.state.userRolePermissionRead,
        cellRenderer: (params) => (
          //(this.state.defaultReports.length != 0 ? !(this.state.defaultReports.filter((x) => x.documentTypeId === params.data.documentTypeId).length === 1) : true) ?
          <img
            className="cursor-class"
            src={deleteIcon}
            title="Delete"
            style={{ opacity: this.state.userRolePermissionRead ? "0.6" : "1", width: "13px" }}
            onClick={(e) => {
              this.state.userRolePermissionRead
                ? e.preventDefault()
                : this.showDeleteDocModal(params.data.reportDocsId);
            }}
          />
          // :
          // <></>
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
    ];
  };

  /* <summary>
   date: 7/3/2023
   Name:SP
   description:view report documents
   <summary> */
  viewReportDocData = (id) => {
    var filterList = [];
    filterList = this.state.mappedReports.filter(
      (x) => x.reportDocsId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };

  /* <summary>
   date: 7/3/2023
   Name:SP
   description:download report documents
   <summary> */
  downloadReportDocData = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });
      let tFileIDlist = [];
      if (id == 0) {
        this.state.selectedDocListIds.forEach((element) => {
          var tId = element;
          tFileIDlist.push(tId);
        });
      } else {
        tFileIDlist.push(id);
      }
      if (tFileIDlist.length == 0) {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.noDocumentsSelected,
        });
        return;
      }
      const response = await ReportService.DownloadReportDoc(tFileIDlist);
      if (response.status == 200) {
        response.data.forEach((element) => {
          var fname = "";
          var lastItem = element.split("/").pop();
          fname = lastItem;

          const link = document.createElement("a");
          // link.href = element;
          link.setAttribute("href", element);
          link.setAttribute("download", element);

          var ext = element.substring(element.lastIndexOf(".") + 1);
          if (ext == "pdf" || ext == "PDF") {
            link.setAttribute("target", "_blank");
          } else {
            link.setAttribute("target", "");
          }
          link.download = fname;
          document.body.appendChild(link);

          link.click();
        });
        //	document.body.removeChild( link );

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:deletes ihm document
   <summary> */
  deleteReportDocData = async () => {
    try {
      const { userId, typeId, selectdDocDeleteId } = this.state;
      this.setState({
        isLoading: true,
      });
      var response = await ReportService.DeleteReportDocData(
        selectdDocDeleteId,
        userId,
        typeId
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        this.setState(
          {
            mappedReports: response.data.reportList,
            defaultReports: response.data.reportList,
            toastVisible: true,
            toastMessage: helperText.global.deletedDocument,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };

  /* <summary>
   date: 7/3/2023
   Name:SP
   description:saves report document data
   <summary> */
  onUploadClick = async (data) => {
    const { userId, typeId } = this.state;
    this.setState({ isLoading: true });
    let reportDocumentData = new FormData();
    reportDocumentData.append("DocumentTypeId", data.dropdown_documenttype);
    reportDocumentData.append("ClientId", data.dropdown_client);
    reportDocumentData.append("ShipId", data.dropdown_ship);
    reportDocumentData.append(
      "ClassificationSocietyId",
      data.dropdown_classificationSociety
    );
    reportDocumentData.append("TableAid", data.dropdown_tableA);
    reportDocumentData.append("TableBid", data.dropdown_tableB);
    reportDocumentData.append("CustomFileName", data.customFileName);
    reportDocumentData.append(
      "IssueDate",
      data.issueDate == "" ? "" : convertDate(data.issueDate)
    );
    reportDocumentData.append("ApprovedBy", data.approvedBy);
    reportDocumentData.append("PreparedBy", data.preparedBy);
    reportDocumentData.append("Remarks", data.remarks);
    reportDocumentData.append(
      "IhmmaintenanceStartDate",
      data.ihmmaintenanceStartDate == ""
        ? ""
        : convertDate(data.ihmmaintenanceStartDate)
    );
    reportDocumentData.append(
      "IhmmaintenanceEndDate",
      data.ihmmaintenanceEndDate == ""
        ? ""
        : convertDate(data.ihmmaintenanceEndDate)
    );
    reportDocumentData.append("ProjectNo", data.project_num);
    reportDocumentData.append("loggedInUserID", userId);

    let file = data.TFile;
    if (file != "" && file.size !== null && file.size !== undefined) {
      reportDocumentData.append("IHMDocumentAttached", file);
      var fileName = file.name.substring(0, file.name.lastIndexOf("."));
      // reportDocumentData.append("CustomeDocumentName", fileName);
    }
    reportDocumentData.append("typeId", typeId);

    var responseSaved = await ReportService.AddReportData(reportDocumentData);

    AddLocalStorageData("selectedShipTTool", null);
    if (data.mdSoc) {
      this.setState({ saveData: responseSaved.data });
      let dropdownData = {
        clientId: parseInt(data.dropdown_client, 10),
        shipId: parseInt(data.dropdown_ship, 10),
        supplierId: parseInt(data.dropdown_supplier, 10),
      };
      AddLocalStorageData("selectedShipTTool", JSON.stringify(dropdownData));
      this.props.navigate("/TrackingTool");
    }
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        showUploadModal: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.documnetUploadedSuccess,
        isLoading: false,
        isUploadDocClick: true,
      });
      this.GetReportsData();

    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };


  resetFilters = () => {
    this.setState(
      {
        dropdown_clientName:
          this.state.isClientOrClientUser || this.state.isShipCrew
            ? this.state.dropdown_clientName
            : "0",
        dropdown_shipName: this.state.isShipCrew ? this.state.dropdown_shipName : "",
        dropdown_region: "0",
        dropdown_supplier: "0",
        dropdown_documentname: "0",
        dropdown_documenttype: "0",
        dropdown_linename: "0",
        dropdown_hazmatpresent: "0",
        searchedText: "",
        dropdown_tableAFilter: "",
        dropdown_tableBFilter: "",
        filter_fromDate: "",
        filter_toDate: "",
        nextFromDate: "",
        previousToDate: currentDate,
      }, () => {
        this.GetReportsData();
      });
  };


  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = [];

      selectedRows.forEach((element) => {
        listSelectedTTId.push(element.reportDocsId);
      });
      this.setState({ selectedDocListIds: listSelectedTTId });
    } else {
      this.setState({ selectedDocListIds: [] });
    }
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
    });
  };

  onFilterSelected = (e) => {
    const {
      shipNameList,
      regionList,
      clientNameList,
      defaultShipNameList,
      defaultRegionList,
      mappedReports,
    } = this.state;
    var { name, value } = e.target;

    if (e.target.name == "dropdown_clientName") {
      if (e.target.value != "0") {
        var filteredShipList = defaultShipNameList.filter(
          (x) => x.parentValue == e.target.value
        );

        this.setState({ shipNameList: filteredShipList }); //, regionList: filteredYearList
        if (filteredShipList.length == 0)
          this.setState({ dropdown_shipName: 0 });
      } else {
        this.setState({ shipNameList: defaultShipNameList });
      }
    }

    if (e.target.name == "dropdown_documenttype") {
      const targetValue = e.target.value;
      if (
        targetValue == "19" ||
        targetValue == "21" ||
        targetValue == "23" ||
        targetValue == "24" ||
        targetValue == "38" ||
        targetValue == "39"
      ) {
        this.setState({ isGenerateReport: true });
      } else {
        this.setState({ isGenerateReport: false });
      }
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:filters grid based on selected filter
   <summary> */
  filterGridView = () => {
    const {
      dropdown_clientName,
      dropdown_shipName,
      dropdown_region,
      dropdown_supplier,
      dropdown_documentname,
      dropdown_documenttype,
      dropdown_linename,
      dropdown_hazmatpresent,
      dropdown_tableAFilter,
      dropdown_tableBFilter,
      filter_fromDate,
      filter_toDate,
      searchedText,
    } = this.state;

    var filterList = [];
    var tempReportList = this.state.defaultReports;

    if (
      dropdown_clientName == 0 &&
      dropdown_shipName == 0 &&
      dropdown_region == 0 &&
      dropdown_documentname == 0 &&
      dropdown_documenttype == 0 &&
      dropdown_tableAFilter == 0 &&
      dropdown_tableBFilter == 0 &&
      filter_fromDate == "" &&
      filter_toDate == ""
    )
      filterList = this.state.defaultReports;
    else {
      var isFilterAdded = false;
      if (dropdown_clientName != "" && dropdown_clientName != 0) {
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.clientId == parseInt(dropdown_clientName, 10)
            )
            : tempReportList.filter(
              (x) => x.clientId == parseInt(dropdown_clientName, 10)
            );
        isFilterAdded = true;
      }

      if (dropdown_shipName != "" && dropdown_shipName != "0") {
        filterList = isFilterAdded
          ? filterList.filter(
            (x) => x.shipId == parseInt(dropdown_shipName, 10)
          )
          : tempReportList.filter((x) => x.shipId == dropdown_shipName);
        isFilterAdded = true;
      }

      if (dropdown_region != "" && dropdown_region != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.regionID == dropdown_region)
          : tempReportList.filter((e) => e.regionID == dropdown_region);
        isFilterAdded = true;
      }

      if (dropdown_documentname != "" && dropdown_documentname != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) =>
            e.customeDocumentName.includes(dropdown_documentname)
          )
          : tempReportList.filter((e) =>
            e.customeDocumentName.includes(dropdown_documentname)
          );
        isFilterAdded = true;
      }

      if (dropdown_documenttype != "" && dropdown_documenttype != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.documentTypeId == dropdown_documenttype)
          : tempReportList.filter(
            (e) => e.documentTypeId == dropdown_documenttype
          );
        isFilterAdded = true;
      }

      //SYJ - Code Commented as not required
      // if (dropdown_tableAFilter != "" && dropdown_tableAFilter != "0")
      // {
      //   filterList =
      //   isFilterAdded
      //       ? filterList.filter((e) => e.tableAid == dropdown_tableAFilter)
      //       : tempReportList.filter((e) => e.tableAid == dropdown_tableAFilter);
      //   isFilterAdded = true;
      // }

      // if (dropdown_tableBFilter != "" && dropdown_tableBFilter != "0")
      // {
      //   filterList =
      //   isFilterAdded
      //       ? filterList.filter((e) => e.tableBid == dropdown_tableBFilter)
      //       : tempReportList.filter((e) => e.tableBid == dropdown_tableBFilter);
      //   isFilterAdded = true;
      // }

      if (filter_fromDate != "" && filter_toDate != "") {
        filterList = isFilterAdded
          ? filterList.filter((e) => {
            let date = new Date(e.createdOn);
            date.setHours(0, 0, 0, 0);
            return date >= filter_fromDate && date <= filter_toDate;
          })
          : tempReportList.filter((e) => {
            let date = new Date(e.createdOn);
            date.setHours(0, 0, 0, 0);
            return date >= filter_fromDate && date <= filter_toDate;
          });
        isFilterAdded = true;
      }
    }

    if (searchedText == "") {
    } else {
      let value = searchedText.toString().toLowerCase();
      filterList = filterList.filter(
        (t) =>
          t?.clientName?.toString().toLowerCase().includes(value) ||
          t?.shipName?.toString().toLowerCase().includes(value) ||
          t?.regionName?.toString().toLowerCase().includes(value) ||
          t?.documentTypeName?.toString().toLowerCase().includes(value) ||
          t?.customFileName?.toString().toLowerCase().includes(value) ||
          // t.hazmatTableA?.toString().toLowerCase().includes(value) ||
          // t.hazmatTableB?.toString().toLowerCase().includes(value) ||
          t.createdOnStr?.toString().toLowerCase().includes(value) ||
          t.createdByName?.toString().toLowerCase().includes(value) ||
          t.documentTypeName?.toString().toLowerCase().includes(value)


      );
    }

    this.setState({
      mappedReports: filterList,
    });
  };

  onSearch = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        searchedText: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  onViewFileDownload = (filePath) => {
    try {
      const { downloadFileName } = this.state;

      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess
      })
    } catch (e) {
    }
  };

  /* <summary>
     date: 13/02/2023
     Name: HV
     description: clearing filter
     <summary> */

  clearAllFilter = () => {
    this.setState(
      {
        dropdown_clientName:
          this.state.isClientOrClientUser || this.state.isShipCrew
            ? this.state.dropdown_clientName
            : "0",
        dropdown_shipName: this.state.isShipCrew
          ? this.state.dropdown_shipName
          : "",
        //dropdown_shipName: "", //SYJ Fix for https://jiraapp.edotteam.com/browse/IHMM-1186
        shipNameList: this.state.defaultShipNameList,//SYJ Fix for https://jiraapp.edotteam.com/browse/IHMM-1186
        dropdown_region: "0",
        dropdown_supplier: "0",
        dropdown_documentname: "0",
        dropdown_documenttype: "0",
        dropdown_linename: "0",
        dropdown_hazmatpresent: "0",
        searchedText: "",
        dropdown_tableAFilter: "",
        dropdown_tableBFilter: "",
        filter_fromDate: "",
        filter_toDate: "",
        nextFromDate: "",
        previousToDate: currentDate,
        isGenerateReport: false,
        mappedReports: this.state.defaultReports,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  /* <summary>
    date: 16/03/2023
    Name: HV
    description: handled to date should be greater than from date scenario
    <summary> */
  onDateChanged = (id, e) => {
    if (id == "fromDate") {
      var nextFromDate = new Date(e);
      nextFromDate.setDate(e.getDate());
      nextFromDate.toLocaleDateString();
      this.setState({ filter_fromDate: e, nextFromDate: nextFromDate }, () => {
        this.filterGridView();
      });
    } else if (id == "toDate") {
      var previousToDate = new Date(e);
      previousToDate.setDate(e.getDate() - 1);
      previousToDate.toLocaleDateString();
      this.setState(
        { filter_toDate: e, previousToDate: previousToDate },
        () => {
          this.filterGridView();
        }
      );
    }
  };

  /* <summary>
    date: 16/02/2023
    Name: HV
    description: method triggered when generate report clicked
    <summary> */

  onGenerateReportClicked = () => {
    const {
      dropdown_documenttype,
      dropdown_clientName,
      documentTypeList,
      filter_fromDate,
      filter_toDate,
      dropdown_shipName,
    } = this.state;

    let dropdown_Client =
      dropdown_clientName == undefined ? 0 : parseInt(dropdown_clientName, 10);

    let dropdown_Ship =
      dropdown_shipName == undefined ? 0 : parseInt(dropdown_shipName, 10);

    let documentTypeName = documentTypeList.filter(
      (x) => x.value == dropdown_documenttype
    )[0].label;
    if (documentTypeName == DocumentTypeName.ClientOverviewReport || documentTypeName == DocumentTypeName.Nonresponsivesupplier) {
      if (
        filter_fromDate == "" ||
        filter_toDate == "" ||
        dropdown_Client == 0
      ) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.selectClientAndDate,
          toastType: helperText.global.toastError,
        });
        return;
      }
      this.GenerateReport();
    } else if (
      documentTypeName == DocumentTypeName.IHMMaintenanceReadyReckonerReport
    ) {
      this.GenerateReport();
    } else if (
      documentTypeName == DocumentTypeName.AnnualMaintenanceReport
    ) {
      if (
        dropdown_Ship == 0 ||
        dropdown_Client == 0 ||
        filter_fromDate == "" ||
        filter_toDate == ""
      ) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.selectClientShip,
          toastType: helperText.global.toastError,
        });
        return;
      }
      this.GenerateAnnualReport();
    } else {
      if (dropdown_Client == 0) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.selectClient,
          toastType: helperText.global.toastError,
        });
      } else {
        this.GenerateReport();
      }
    }
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
     date: 16/02/2023
     Name: HV
     description: method call api service to generate report
     <summary> */

  GenerateReport = async () => {
    const {
      dropdown_documenttype,
      dropdown_clientName,
      dropdown_shipName,
      documentTypeList,
      clientNameList,
      shipNameList,
      filter_fromDate,
      filter_toDate,
      typeId,
      userId,
    } = this.state;
    this.setState({ isLoading: true });

    let documentTypeName =
      dropdown_documenttype == null || dropdown_documenttype == 0
        ? ""
        : documentTypeList.filter((x) => x.value == dropdown_documenttype)[0]
          .label;
    let clientName =
      dropdown_clientName == null || dropdown_clientName == 0
        ? ""
        : clientNameList.filter((x) => x.value == dropdown_clientName)[0].label;

    let shipName =
      dropdown_shipName == null || dropdown_shipName == 0
        ? ""
        : shipNameList.filter((x) => x.value == dropdown_shipName)[0].label;

    var fileName = "",
      reportName = "",
      reportsDescription = "";

    if (documentTypeName == DocumentTypeName.SOCExpiryReport) {
      fileName = "SocReport.xlsx";
      reportName = ReportName.SOCReport;
      reportsDescription = ReportsDescription.SOCReportDescription;
    } else if (
      documentTypeName == DocumentTypeName.ClientSpecificDocumentTrackingReport
    ) {
      fileName = "ClientSpecificDocumentTrackingReport.xlsx";
      reportName = ReportName.DocumentStatus;
      reportsDescription = ReportsDescription.DocumentStatusDescription;
    } else if (
      documentTypeName == DocumentTypeName.IHMMaintenanceReadyReckonerReport
    ) {
      fileName = "IHMMaintenanceReadyReckonerReport.xlsx";
      reportName = ReportName.IHMMaintenanceReadyReckonerReport;
    } else if (documentTypeName == DocumentTypeName.ClientOverviewReport) {
      fileName = "ClientOverviewReport.xlsx";
      reportName = ReportName.ClientOverviewReport;
      reportsDescription = ReportsDescription.ClientOverviewReportDescription;
    } else if (documentTypeName == DocumentTypeName.Nonresponsivesupplier) {
      fileName = "Nonresponsivesupplier.xlsx";
      reportName = ReportName.Nonresponsivesupplier;
      reportsDescription = ReportsDescription.NonresponsivesupplierDescription;
    }

    let reportData = new FormData();
    reportData.append("DocumentTypeId", dropdown_documenttype);
    reportData.append(
      "ClientId",
      dropdown_clientName == null ? 0 : dropdown_clientName
    );
    reportData.append(
      "ShipId",
      dropdown_shipName == null ? 0 : dropdown_shipName
    );
    reportData.append("DocumentTypeName", documentTypeName);
    reportData.append("ClientName", clientName);
    reportData.append("ShipName", shipName);

    reportData.append("FileName", fileName);
    reportData.append("ReportsDescription", reportsDescription);
    reportData.append("ReportName", reportName);
    reportData.append(
      "FromDate",
      filter_fromDate != "" ? convertDate(filter_fromDate) : ""
    );
    reportData.append(
      "ToDate",
      filter_toDate != "" ? convertDate(filter_toDate) : ""
    );
    reportData.append("UserTypeId", typeId);
    reportData.append("UserId", userId);
    var response = await ReportService.GenerateReport(reportData);
    if (response.status == 200) {
      if (response.data != null) {
        var generatedReportResponse = response.data;
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.reportGenerateSuccess,
          toastType: helperText.global.toastSuccess,
          showViewModal: true,
          filePathToView: generatedReportResponse?.filePath,
          isLoading: false,
        });
      }
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  GenerateAnnualReport = async () => {
    const {
      dropdown_documenttype,
      dropdown_clientName,
      dropdown_shipName,
      documentTypeList,
      clientNameList,
      shipNameList,
      filter_fromDate,
      filter_toDate,
      typeId,
      userId,
    } = this.state;
    this.setState({ isLoading: true });

    let documentTypeName =
      dropdown_documenttype == null || dropdown_documenttype == 0
        ? ""
        : documentTypeList.filter((x) => x.value == dropdown_documenttype)[0]
          .label;

    let clientName =
      dropdown_clientName == null || dropdown_clientName == 0
        ? ""
        : clientNameList.filter((x) => x.value == dropdown_clientName)[0].label;

    let shipName =
      dropdown_shipName == null || dropdown_shipName == 0
        ? ""
        : shipNameList.filter((x) => x.value == dropdown_shipName)[0].label;

    var fileName = "",
      reportName = "",
      reportsDescription = "";

    if (documentTypeName == DocumentTypeName.SOCExpiryReport) {
      fileName = "SocReport.xlsx";
      reportName = ReportName.SOCReport;
      reportsDescription = ReportsDescription.SOCReportDescription;
    } else if (
      documentTypeName == DocumentTypeName.ClientSpecificDocumentTrackingReport
    ) {
      fileName = "ClientSpecificDocumentTrackingReport.xlsx";
      reportName = ReportName.DocumentStatus;
      reportsDescription = ReportsDescription.DocumentStatusDescription;
    } else if (
      documentTypeName == DocumentTypeName.IHMMaintenanceReadyReckonerReport
    ) {
      fileName = "IHMMaintenanceReadyReckonerReport.xlsx";
      reportName = ReportName.IHMMaintenanceReadyReckonerReport;
    } else if (documentTypeName == DocumentTypeName.ClientOverviewReport) {
      fileName = "ClientOverviewReport.xlsx";
      reportName = ReportName.ClientOverviewReport;
      reportsDescription = ReportsDescription.ClientOverviewReportDescription;
    } else if (documentTypeName == DocumentTypeName.Nonresponsivesupplier) {
      fileName = "Nonresponsivesupplier.xlsx";
      reportName = ReportName.Nonresponsivesupplier;
      reportsDescription = ReportsDescription.NonresponsivesupplierDescription;
    }

    let reportData = new FormData();
    reportData.append("DocumentTypeId", dropdown_documenttype);
    reportData.append(
      "ClientId",
      dropdown_clientName == null ? 0 : dropdown_clientName
    );
    reportData.append(
      "ShipId",
      dropdown_shipName == null ? 0 : dropdown_shipName
    );
    reportData.append("DocumentTypeName", documentTypeName);
    reportData.append("ClientName", clientName);
    reportData.append("ShipName", shipName);
    reportData.append("FileName", fileName);
    reportData.append("ReportsDescription", reportsDescription);
    reportData.append("ReportName", reportName);
    reportData.append(
      "FromDate",
      filter_fromDate != "" ? convertDate(filter_fromDate) : ""
    );
    reportData.append(
      "ToDate",
      filter_toDate != "" ? convertDate(filter_toDate) : ""
    );
    reportData.append("UserTypeId", typeId);
    reportData.append("UserId", userId);
    var response = await ReportService.GenerateAnnualReport(reportData);
    if (response.status == 200) {
      if (response.data != null) {
        var generatedReportResponse = response.data;

        var fileNameToDownload = "AnnualReport_" + shipName + "_" + convertDate(filter_fromDate) + "-" + convertDate(filter_toDate) + ".docx";

        await this.generateAnnualReportPdf(generatedReportResponse, fileNameToDownload);

        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.reportGenerateSuccess,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        });
      }
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  extractTableData = (htmlString) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, "text/html");
    const table = parsedHtml.getElementsByTagName("table")[0]; // Assuming only one table in the HTML

    // Extract table headers (th)
    const thTags = table.getElementsByTagName("th");
    const headers = [];
    for (let i = 0; i < thTags.length; i++) {
      headers.push(thTags[i].textContent);
    }
    console.log(headers);
    tableData.push(headers);

    // Extract table rows (td)
    const trTags = table.getElementsByTagName("tr");
    for (let i = 0; i < trTags.length; i++) {
      const rowCells = trTags[i].getElementsByTagName("td");
      const rowData = [];

      for (let j = 0; j < rowCells.length; j++) {
        rowData.push(rowCells[j].textContent);
      }

      if (rowData.length > 0) {
        tableData.push(rowData); // Push row data to tableData array
      }
    }

    return tableData;
  };

  generate = (rowCount) => {
    const rows = [];
    // Dynamically create TableRow instances based on the rowCount
    for (let i = 0; i < rowCount; i++) {
      const cells = [];
      for (let j = 0; j < tableData[0].length; j++) {
        cells.push(
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(tableData[i][j])],
          })
        );
      }
      rows.push(
        new TableRow({
          children: cells,
        })
      );
    }

    const table = new Table({
      columnWidths: [
        4505, 4505, 4505, 4505, 4505, 4505, 4505, 4505, 4505, 4505, 4505,
      ],
      rows: rows,
    });

    const convertHtmlToDocx = (html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      const elements = doc.body.childNodes;
      const docContent = [];

      elements.forEach((element) => {
        let style = {};

        // Convert HTML paragraphs to DOCX paragraphs
        if (element.nodeName === "P") {
          const paragraph = new Paragraph({
            text: element.textContent,
            spacing: {
              after: 100,
            },
          });
          docContent.push(paragraph);
        }

        // Handle bold text - <strong> and <b> elements
        if (element.nodeName === "STRONG" || element.nodeName === "B") {
          style = { bold: true };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(new Paragraph({ children: textRuns }));
        }

        if (element.nodeName === "H1") {
          const style = { bold: true, color: "#000000", size: 48 };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(
            new Paragraph({
              children: textRuns,
              alignment: AlignmentType.CENTER,
            })
          );
        }
        if (element.nodeName === "H2") {
          const style = { bold: true, color: "#000000", size: 36 };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(
            new Paragraph({
              children: textRuns,
              alignment: AlignmentType.LEFT,
            })
          );
        }
        if (element.nodeName === "H3") {
          const style = { bold: true, color: "#000000", size: 28 };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(
            new Paragraph({
              children: textRuns,
              alignment: AlignmentType.CENTER,
            })
          );
        }
        if (element.nodeName === "H4") {
          const style = { bold: true };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(
            new Paragraph({
              children: textRuns,
              alignment: AlignmentType.CENTER,
            })
          );
        }
        if (element.nodeName === "H5") {
          const style = { bold: true };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(
            new Paragraph({
              children: textRuns,
              alignment: AlignmentType.CENTER,
            })
          );
        }
        if (element.nodeName === "H6") {
          const style = { bold: true };
          const textRuns = [
            new TextRun({ text: element.textContent, ...style }),
          ];
          docContent.push(
            new Paragraph({
              children: textRuns,
              alignment: AlignmentType.CENTER,
            })
          );
        }
      });

      return docContent;
    };

    const docContent = convertHtmlToDocx(htmlString);
    const doc = new Document({
      sections: [
        {
          children: [
            ...docContent, // Include the converted HTML content
            table, // Include the table
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, fileNameDownload);
    });
  };

  generateAnnualReportPdf = async (generatedReportResponse, fileNameToDownload) => {
    try {
      htmlString = generatedReportResponse;
      fileNameDownload = fileNameToDownload;

      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(htmlString, "text/html");
      const trTags = parsedHtml.getElementsByTagName("tr");
      let trCount = trTags.length;

      await this.extractTableData(generatedReportResponse);

      this.generate(trCount);
    }
    catch (error) {
      console.log("Catch", error);
    }
  }

  goback = () => {
    const { isClientOrClientUser, isShipCrew } = this.state;
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    }
    else if (loc == "/ActionRequired") {
      this.props.navigate("/ActionRequired");
    }
    else if (isShipCrew) {
      this.props.navigate("/ShipCrewDashboard")
    }
    else if (isClientOrClientUser) {
      this.props.navigate("/ClientDashboard")
    } else {
      this.props.navigate("/Dashboard");
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      mappedReports,
      colDefs,
      clientNameList,
      dropdown_clientName,
      shipNameList,
      dropdown_shipName,
      regionList,
      dropdown_region,
      supplierList,
      dropdown_supplier,
      documentTypeList,
      dropdown_documenttype,
      dropdown_hazmatpresent,
      showUploadModal,
      documentNameList,
      dropdown_documentname,
      lineNameList,
      dropdown_linename,
      flagStateList,
      trackingToolYearList,
      showViewModal,
      filePathToView,
      lineNameOBJList,
      defaultShipNameList,
      defaultRegionList,
      showConfirmationPopup,
      accessList,
      searchedText,
      tableAList,
      dropdown_tableAFilter,
      tableBList,
      dropdown_tableBFilter,
      filter_fromDate,
      filter_toDate,
      classificationSocietyList,
      isGenerateReport,
      clientRegionBrigdeList,
      isShipCrew,
      isClientOrClientUser,
      isClientUser,
      previousToDate,
      nextFromDate,
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"Reports"} />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="manage-supplier">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="admin-profile-detail-back">
                    {/* <a
                      onClick={() =>
                        isClientOrClientUser
                          ? this.props.navigate("/ClientDashboard")
                          : isShipCrew
                            ? this.props.navigate("/ShipCrewDashboard")
                            : this.props.navigate("/Dashboard")
                      }
                    > */}
                    <img
                      src="images/back.png"
                      alt="Back"
                      style={{ cursor: "pointer" }}
                      onClick={this.goback}
                    />
                    {/* </a> */}
                    <h5>Reports</h5>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="searchBar">
                    <input
                      id="searchQueryInput"
                      type="text"
                      name="searchQueryInput"
                      placeholder="Report Name, Type of Report, Region etc.."
                      onChange={this.onSearch}
                      value={searchedText}
                      className="search-placeholder-text"
                    />
                    <button
                      id="searchQuerySubmit"
                      type="submit"
                      name="searchQuerySubmit"
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- Mobile View --> */}
              <div className="training-mobile reports-mobile">
                <div className="accordion-filter-dropdown">
                  <div id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="supplier-filter">
                            <div className="row">
                              <div className="col-lg-12 filter-flex-container wrap">
                                <div className="filter-box filter-flex-item" id={"clientName"}>
                                  <Dropdown
                                    dropdownLabel="Client Name:"
                                    name="clientName"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_clientName}
                                    disabled={
                                      isClientOrClientUser || isShipCrew
                                    }
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {clientNameList == null
                                      ? null
                                      : clientNameList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_clientName > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"clientName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {clientNameList != null && clientNameList.filter(x => x.value == dropdown_clientName)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="filter-box filter-flex-item" id={"shipName"}>
                                  <Dropdown
                                    dropdownLabel="Ship Name:"
                                    name="shipName"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_shipName}
                                    disabled={isShipCrew}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {shipNameList == null
                                      ? null
                                      : shipNameList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_shipName > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"shipName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {clientNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="filter-box filter-flex-item" id={"region"}>
                                  <Dropdown
                                    dropdownLabel="Region:"
                                    name="region"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_region}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {regionList == null
                                      ? null
                                      : regionList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_region > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"region"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="filter-box filter-flex-item" id={"typeReport"}>
                                  <label className="label-class label-mobile label-dropdown">
                                    Type of Report:
                                  </label>
                                  <select
                                    name={`dropdown_${"documenttype"}`}
                                    id="ddlDropdown"
                                    className="form-select custom-dropdown-class"
                                    onChange={(ev) => this.onFilterSelected(ev)}
                                    aria-label="dropdown-select"
                                    value={dropdown_documenttype}
                                  >
                                    <option key={"0"} value={"0"}>
                                      Select
                                    </option>
                                    {!isClientOrClientUser && !isShipCrew ? (
                                      <>
                                        <optgroup label="Generate Report">
                                          {documentTypeList == null
                                            ? null
                                            : documentTypeList
                                              .filter(
                                                (x) =>
                                                  x.parentValue ==
                                                  REPORT_DOC_TYPE.GENERATE_REPORT
                                              )
                                              .map((item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              ))}
                                        </optgroup>
                                        <optgroup label="Ready Report">
                                          {documentTypeList == null
                                            ? null
                                            : documentTypeList
                                              .filter(
                                                (x) =>
                                                  x.parentValue ==
                                                  REPORT_DOC_TYPE.READY_REPORT
                                                  && x.label != "Other"
                                              )
                                              .map((item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              ))}
                                        </optgroup>
                                        {dropdown_documenttype > 0 ? (
                                          <UncontrolledTooltip
                                            autohide={false}
                                            target={"typeReport"}
                                            aria-haspopup="true"
                                            role="example"
                                            effect="solid"
                                            placement="bottom"
                                            style={{ width: "350px" }}
                                          >
                                            {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                                          </UncontrolledTooltip>) : null}
                                      </>
                                    ) : (
                                      <>
                                        <optgroup label="Ready Report">
                                          {documentTypeList == null
                                            ? null
                                            : documentTypeList
                                              .filter(
                                                (x) =>
                                                  x.parentValue ==
                                                  REPORT_DOC_TYPE.READY_REPORT
                                                  &&
                                                  x.label != "Other"
                                              )
                                              .map((item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              ))}
                                        </optgroup>
                                        {dropdown_documenttype > 0 ? (
                                          <UncontrolledTooltip
                                            autohide={false}
                                            target={"typeReport"}
                                            aria-haspopup="true"
                                            role="example"
                                            effect="solid"
                                            placement="bottom"
                                            style={{ width: "350px" }}
                                          >
                                            {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                                          </UncontrolledTooltip>) : null}
                                      </>
                                    )}
                                  </select>
                                </div>
                                {/* <div className="filter-box filter-flex-item">
                                  <Dropdown
                                    dropdownLabel="Hazmat Table A:"
                                    name="tableAFilter"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_tableAFilter}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {tableAList == null
                                      ? null
                                      : tableAList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                </div> */}
                                {/* <div className="filter-box filter-flex-item">
                                  <Dropdown
                                    dropdownLabel="Hazmat Table B:"
                                    name="tableBFilter"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_tableBFilter}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {tableBList == null
                                      ? null
                                      : tableBList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                </div> */}

                                <div className="filter-box filter-flex-item">
                                  <label
                                    htmlFor="exampleDataList"
                                    className="form-label"
                                  >
                                    Date of Creation:
                                  </label>
                                  <br />
                                  <span className="from-to-box">
                                    <span className="from-date">
                                      <label
                                        htmlFor="fromdate"
                                        className="form-label"
                                      >
                                        From:
                                      </label>
                                      <DatePicker
                                        selectedDate={filter_fromDate}
                                        onDateChange={(e) =>
                                          this.onDateChanged("fromDate", e)
                                        }
                                        maxDate={previousToDate}
                                      >
                                        {" "}
                                      </DatePicker>
                                    </span>
                                    <span className="to-date">
                                      <label
                                        htmlFor="todate"
                                        className="form-label"
                                      >
                                        To:
                                      </label>
                                      <DatePicker
                                        selectedDate={filter_toDate}
                                        onDateChange={(e) =>
                                          this.onDateChanged("toDate", e)
                                        }
                                        minDate={nextFromDate}
                                        maxDate={currentDate}
                                      >
                                        {" "}
                                      </DatePicker>
                                    </span>
                                  </span>
                                  {/* <div className="filter-box filter-flex-item">
                                    <button
                                      type="button"
                                      className="btn btn-blue-bg"
                                      onClick={this.clearAllFilter}
                                      style={{ marginTop: 24 }}
                                    >
                                      Reset
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter-btn training-add-button">
                      {!isClientOrClientUser && !isShipCrew ? (
                        <button
                          type="button"
                          disabled={
                            accessList != null
                              ? accessList.reportAccess == UserAction.READ
                                ? true
                                : false
                              : false
                          }
                          id="upld-btn-training"
                          className="btn btn-blue-bg "
                          data-bs-toggle="modal"
                          data-bs-target="#uploadreport-modal"
                          onClick={this.showDocumentUploadModal}
                        >
                          Upload Report
                        </button>
                      ) : null}
                      &nbsp;{" "}
                      {mappedReports.length > 0 ? (
                        <span>
                          <img
                            id="training-admin-dwnld-icon"
                            src="./images/admin-tracking-download.png"
                            alt="download-document"
                            style={{ cursor: "pointer" }}
                            title="Multi Download"
                            onClick={() => {
                              this.downloadReportDocData(0);
                            }}
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- DESKTOP --> */}
              <div className="training-desktop">
                <div className="desktop-class">
                  <div className="supplier-filter">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 dropdown-class-responsive" id={"clientName1"}>
                        <Dropdown
                          dropdownLabel="Client Name:"
                          name="clientName"
                          onChange={this.onFilterSelected}
                          selected={dropdown_clientName}
                          disabled={isClientOrClientUser || isShipCrew}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {clientNameList == null
                            ? null
                            : clientNameList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_clientName > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"clientName1"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {clientNameList != null && clientNameList.filter(x => x.value == dropdown_clientName)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div className="col-lg-3 col-md-4 dropdown-class-responsive" id={"shipName1"}>
                        <Dropdown
                          dropdownLabel="Ship Name:"
                          name="shipName"
                          onChange={this.onFilterSelected}
                          selected={dropdown_shipName}
                          disabled={isShipCrew}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {shipNameList == null
                            ? null
                            : shipNameList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_shipName > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"shipName1"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div className="col-lg-3 col-md-4 dropdown-class-responsive" id={"region1"}>
                        <Dropdown
                          dropdownLabel="Region:"
                          name="region"
                          onChange={this.onFilterSelected}
                          selected={dropdown_region}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {regionList == null
                            ? null
                            : regionList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_region > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"region1"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div className="col-lg-3 col-md-4 dropdown-class-responsive">
                        <label className="label-class label-mobile label-dropdown">
                          Type of Report:
                        </label>
                        {!isClientOrClientUser && !isShipCrew ? (
                          <div id={"typeReport2"}>
                            <select
                              name={`dropdown_${"documenttype"}`}
                              id="ddlDropdown"
                              className="form-select custom-dropdown-class"
                              onChange={(ev) => this.onFilterSelected(ev)}
                              aria-label="dropdown-select"
                              value={dropdown_documenttype}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              <option key={"0"} value={"0"}>
                                Select
                              </option>
                              <optgroup label="Generate Report">
                                {documentTypeList == null
                                  ? null
                                  : documentTypeList
                                    .filter(
                                      (x) =>
                                        x.parentValue ==
                                        REPORT_DOC_TYPE.GENERATE_REPORT
                                    )
                                    .map((item, key) => (
                                      <option key={key} value={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                              </optgroup>
                              <optgroup label="Ready Report">
                                {documentTypeList == null
                                  ? null
                                  : documentTypeList
                                    .filter(
                                      (x) =>
                                        x.parentValue ==
                                        REPORT_DOC_TYPE.READY_REPORT
                                        &&
                                        x.label != "Other"
                                    )
                                    .map((item, key) => (
                                      <option key={key} value={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                              </optgroup>
                            </select>

                            {dropdown_documenttype > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"typeReport2"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                              </UncontrolledTooltip>) : null}
                          </div>
                        ) : (
                          <div id={"typeReport3"}>
                            <select
                              name={`dropdown_${"documenttype"}`}
                              id="ddlDropdown"
                              className="form-select custom-dropdown-class"
                              onChange={(ev) => this.onFilterSelected(ev)}
                              aria-label="dropdown-select"
                              value={dropdown_documenttype}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              <option key={"0"} value={"0"}>
                                Select
                              </option>
                              {documentTypeList == null
                                ? null
                                : documentTypeList
                                  .filter(
                                    (x) =>
                                      x.parentValue ==
                                      REPORT_DOC_TYPE.READY_REPORT
                                      && x.label != "Other"
                                  )
                                  .map((item, key) => (
                                    <option key={key} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                            </select>
                            {dropdown_documenttype > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"typeReport3"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                              </UncontrolledTooltip>) : null}
                          </div>
                        )}
                      </div>
                      {/* <div className="col-lg-3 col-md-4 dropdown-class-responsive">
                        <div className="filter-box filter-flex-item">
                          <Dropdown
                            dropdownLabel="Hazmat Table A:"
                            name="tableAFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_tableAFilter}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {tableAList == null
                              ? null
                              : tableAList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-3 col-md-4 dropdown-class-responsive">
                        <div className="filter-box filter-flex-item">
                          <Dropdown
                            dropdownLabel="Hazmat Table B:"
                            name="tableBFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_tableBFilter}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {tableBList == null
                              ? null
                              : tableBList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                        </div>
                      </div> */}

                      <div className="col-lg-5 col-md-6 dropdown-class-responsive">
                        <label htmlFor="exampleDataList" className="form-label">
                          Date of Creation:
                        </label>
                        <br />
                        <span className="from-to-box">
                          <span className="from-date">
                            <label htmlFor="fromdate" className="form-label">
                              From:
                            </label>
                            <DatePicker
                              selectedDate={filter_fromDate}
                              onDateChange={(e) =>
                                this.onDateChanged("fromDate", e)
                              }
                              maxDate={previousToDate}
                            >
                              {" "}
                            </DatePicker>
                          </span>
                          <span className="to-date">
                            <label htmlFor="todate" className="form-label">
                              To:
                            </label>
                            <DatePicker
                              selectedDate={filter_toDate}
                              onDateChange={(e) =>
                                this.onDateChanged("toDate", e)
                              }
                              minDate={nextFromDate}
                              maxDate={currentDate}
                            >
                              {" "}
                            </DatePicker>
                          </span>
                        </span>
                      </div>
                      <div className="col-lg-2 col-md-2 filter-box clear-all-btn">
                        <button
                          type="button"
                          className="btn btn-blue-bg "
                          onClick={this.clearAllFilter}
                        //style={{marginTop: -30}}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-lg-8 col-md-7 dropdown-class-responsive"></div>
                      <div className="col-lg-4 col-md-5 training-add-button">
                        {isGenerateReport ? (
                          <button
                            type="button"
                            disabled={
                              accessList != null
                                ? accessList.reportAccess == UserAction.READ
                                  ? true
                                  : false
                                : false
                            }
                            id="upld-btn-training"
                            className="btn btn-blue-bg "
                            data-bs-toggle="modal"
                            data-bs-target="#uploadreport-modal"
                            onClick={this.onGenerateReportClicked}
                          >
                            Generate Report
                          </button>
                        ) : (
                          <>
                            {!isClientOrClientUser && !isShipCrew ? (
                              <button
                                type="button"
                                disabled={
                                  accessList != null
                                    ? accessList.reportAccess == UserAction.READ
                                      ? true
                                      : false
                                    : false
                                }
                                id="upld-btn-training"
                                className="btn btn-blue-bg "
                                data-bs-toggle="modal"
                                data-bs-target="#uploadreport-modal"
                                onClick={this.showDocumentUploadModal}
                              >
                                Upload Report
                              </button>
                            ) : null}{" "}
                            &nbsp;{" "}
                            {mappedReports.length > 0 ? (
                              <span>
                                <img
                                  id="training-admin-dwnld-icon"
                                  src="./images/admin-tracking-download.png"
                                  alt="download-document"
                                  style={{ cursor: "pointer", width: "28px" }}
                                  title="Multi Download"
                                  onClick={() => {
                                    this.downloadReportDocData(0);
                                  }}
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              {/* <!--   grid Code for manage supplier --> */}
              <div className="training-grid">
                <div className="row">
                  <div className="col-md-12">
                    {isGenerateReport ? (
                      <AGGrid
                        rowData={[]}
                        colDefs={this.gridColumns(
                          isClientOrClientUser || isShipCrew
                        )}
                        onGridReady={this.onGridReady}
                        onSelectionChanged={(e) => this.onSelectionChanged(e)}
                        rowSelection="multiple"
                        pagination="10"
                        autoHeight={false}
                        overlayNoRowsTemplate={this.state.customOverlayTemplate}
                        rowMultiSelectWithClick={true}
                        onSortChanged={(e) => this.onSortChanged(e)}
                      />
                    ) : (
                      <AGGrid
                        rowData={mappedReports}
                        colDefs={this.gridColumns(
                          isClientOrClientUser || isShipCrew
                        )}
                        onGridReady={this.onGridReady}
                        onSelectionChanged={(e) => this.onSelectionChanged(e)}
                        rowSelection="multiple"
                        pagination="10"
                        autoHeight={false}
                        rowMultiSelectWithClick={true}
                        onSortChanged={(e) => this.onSortChanged(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {showUploadModal && (
          <ReportsModal
            onCloseClick={this.onCloseClick}
            flagStateList={flagStateList}
            clientList={clientNameList}
            shipList={defaultShipNameList}
            documentTypeList={documentTypeList.filter(
              (x) => x.parentValue == REPORT_DOC_TYPE.READY_REPORT
            )}
            supplierList={supplierList}
            lineNameList={lineNameList}
            onUploadClick={this.onUploadClick}
            trackingToolYearList={trackingToolYearList}
            classificationSocietyList={classificationSocietyList}
            regionList={regionList}
            tableAList={tableAList}
            tableBList={tableBList}
            clientRegionBrigdeList={clientRegionBrigdeList}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
          />
        )}
        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Reports File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          ></ViewModal>
        )}

        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.deleteReportDocData}
          />
        )}

        <Footer />
      </>
    );
  }
}
export default withRouter(Reports);
