import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { GetLocalStorageDataWithParse, changePageTitle, withRouter } from "../../helpers";
import Footer from "../../components/Footer";
import { dashboardservice } from "../../services/dashboard.service";

class ShipCrewDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            shipImoNo: 0,
            userId: 0,
            shipName: "",
            clientName: ""
        };
    }

    /* <summary>
    date: 08/03/2023
    Name: HV
    description: handled ship crew dashboard functionality and navigation
    <summary>*/

    componentDidMount = () => {

        var userData = GetLocalStorageDataWithParse("loggedInUser");
        //HV - 20/02/2023 - changing browser title name 
        changePageTitle("");
        //HV - 17/02/2023 - handled browser back button
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
        if (userData != null) {
            this.setState({ userId: userData.id, clientName: userData.clientName, shipName: userData.name, shipImoNo: parseInt(userData.username, 10) }, () => {
                this.GetShipDashboardInfo(userData.id);
            })
        }

    };

    GetShipDashboardInfo = async (shipId) => {
        this.setState({ isLoading: true });
        var response = await dashboardservice.GetShipDashboardInfo(shipId);
        if (response.status == 200) {
          let shipData = response.data;
          
          this.setState(
            {
              shipName: shipData?.shipName,
              clientName: shipData?.clientName,
              //.client?.clientName
            });
    
        } else if (response.status == 401) {
          Logout(this.props.navigate);
        }
        this.setState({ isLoading: false });
      };

    navigateTo = (path) => {
        this.props.navigate(path);
    };

    render() {
        const {
            isLoading,
            shipName,
        } = this.state;
        return (
            <div>
                {isLoading && <Loader></Loader>}
                <Header componentFor={"ShipCrewDashboard"} />
                <main className="page-height dashboard-bg">
                    <section className="admin-dashboard">
                        <div className="container">
                            <div className="row quick-link">
                                <div className="col-12 col-md-5 col-xl-3 heading-bottom-space">
                                    <div className="row">
                                        {/* <div className="col-5 col-lg-4">
                                            <label className="label-class">Select Ship:</label>
                                        </div> */}
                                        <div className="col-7 col-lg-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="shipName"
                                                name="shipName"
                                                value={shipName}
                                                readOnly
                                                disabled
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "pre",
                                                    overflow: "hidden"
                                                }}
                                                title={shipName}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 col-xl-4 heading-bottom-space">
                                    <div className="row">
                                        <div className="col-3 col-lg-1" style={{ alignSelf: 'center' }}>
                                            <label className="label-class">of</label>
                                        </div>
                                        <div className="col-7 col-lg-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="shipName"
                                                name="shipName"
                                                value={this.state.clientName}
                                                readOnly
                                                disabled
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "pre",
                                                    overflow: "hidden"
                                                }}
                                                title={this.state.clientName}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <fieldset className="dashboard-fieldset-client">
                                    <legend className="dashboard-legend">Quick Access</legend>
                                    <div className="row">

                                        <div
                                            className="col-sm-6 col-lg-4"
                                            //onClick={() => this.navigateTo("/TrackingTool")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="quick-link-box-client" onClick={() => this.navigateTo("/TrackingTool")}>
                                                <h3>Tracking Tool</h3>
                                                <img src="images/tracking.png" className="tracking-eye-image" alt="tracking" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4" onClick={() => this.navigateTo("/Reports")}>
                                            <div className="quick-link-box-client" style={{ cursor: "pointer" }}>
                                                <h3>Reports</h3>
                                                <img src="images/seo-report.png" className="ihm-database-img" alt="reports" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4"
                                            onClick={() => this.navigateTo("/TrainingDocument")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className="quick-link-box-client">
                                                <h3>Training</h3>
                                                <img src="images/training.png" alt="Training" />
                                            </div>
                                        </div>
{/* 
                                        <div className="col-sm-0 col-lg-4 ship-crew-tab-hide"></div> */}
                                        <div className="col-sm-6 col-lg-4"
                                            onClick={() => this.navigateTo("/ShipDocument")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className="quick-link-box-client">
                                                <h3>Documents</h3>
                                                <img src="images/Documents.png" alt="Documents" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6 col-lg-4"
                                              onClick={() => this.navigateTo("/ManageStatistics")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className="quick-link-box-client">
                                                <h3>Statistics</h3>
                                                <img src="images/statistics.png" alt="Documents" />
                                            </div>
                                        </div>
                                    </div>

                                </fieldset>
                            </div>
                            {/* <div>
                                <div className="row dashboard-box2"
                                onClick={() => this.navigateTo("/ManageStatistics")}
                                style={{ cursor: "pointer" }}
                                >
                                    <div className="col-12 col-sm-3  col-lg-3 p-0 statistics"
                                    >
                                        <h3>Statistics</h3>
                                        <img src="images/analytics.png" className="img-fluid" alt="Communications" />
                                    </div>
                                    <div className="col-12 col-sm-9 col-lg-9 statistics-display">
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6">
                                                <img
                                                    src="images/dummy-statistic.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm-6 col-lg-6">
                                                <img
                                                    src="images/dummy-statistic-2.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ShipCrewDashboard);
