import React from "react";
import { config } from "../helpers/config";
import DirtyModal from "./DirtyModal";
import { AddLocalStorageData, GetLocalStorageDataWithoutParse, withRouter } from "../helpers";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDirtyPopup: false,
    };
  }
  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState({
      showDirtyPopup: false,
    }, () => {
      AddLocalStorageData("isDirty", false);
      this.props.navigate("/ContactUs");
    });
  };
  onContactUsClick = () => {

    var isDirty = GetLocalStorageDataWithoutParse("isDirty");
    if (isDirty === "true")
      this.setState({ showDirtyPopup: true, });
    else {

      this.setState({
        showDirtyPopup: false,
      }, () => {
        AddLocalStorageData("isDirty", false)
        this.props.navigate("/ContactUs");
      });
    }
  }
  render() {
    return (
      <footer
        id="footer"
        style={{
          marginTop:
            this.props.margin == -1
              ? "150px"
              : this.props.margin == 1
                ? "220px"
                : this.props.margin == 2
                  ? "70px"
                  : this.props.margin == 0
                    ? "320px"
                    : "0px",
        }}>
        <div className="admin-footer admin-footer-responsive">
          <div className="container-fluid">
            <div className="contactus-footer">
              <p>© CTI-MARITEC. All rights reserved </p>
              <a target={"_blank"} href="/TermsAndConditions">Privacy Policy</a>
              <a target={"_blank"} href={config.maritecSiteUrl}>About Us</a>
              <a onClick={() => this.onContactUsClick()}>Contact Us</a>
            </div>
          </div>
        </div>
        {this.state.showDirtyPopup && (
          <DirtyModal
            onModalCloseClick={this.onDirtyModalCloseClick}
            onProceedClick={this.onProceedClick}
          />
        )}
      </footer>
    );
  }
}
export default withRouter(Footer);
