import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { GetLocalStorageDataWithoutParse, withRouter } from "../../helpers";
import DatePicker from "../../components/DatePickerComponent";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import Toast from "../../components/Toast";
import { ReportService } from "../../services/report.service";
import {
  generateRandomString,
  helperText,
  isEmptyObject,
  isValidFile,
} from "../../helpers";
import { error } from "jquery";
import { UncontrolledTooltip } from "reactstrap";

const validFileFormats = ["xlsx,xls,docx,pdf,PDF"];
const validFileFormatsReadable =
  "application/pdf,application/PDF,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon-modal"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};
class ReportsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_client: 0,
      dropdown_ship: 0,
      dropdown_region: 0,
      dropdown_tableA: 0,
      dropdown_tableB: 0,
      dropdown_classificationSociety: 0,
      dropdown_documenttype: 0,
      dropdown_hazmatpresent: 0,
      dropdown_supplier: 0,
      dropdown_flagstate: 0,
      issuedBy: "",
      preparedBy: "",
      issueDate: "",
      ihmmaintenanceStartDate: "",
      ihmmaintenanceEndDate: "",
      expiryDate: "",
      fileInputKey: "",
      fileName: "",
      filePath: "",
      TFile: null,
      showErrorMessage: false,
      errorMessages: {},
      dropdown_lineName: "",
      mdSoc: false,
      lineName: "",
      fileErrorMessage: false,
      shipNameList: [],
      yearList: [],
      dropdown_year: 0,
      remarks: "",
      approvedBy: "",
      customFileName: "",

      showIssueDateField: false,
      showClassificationSocietyField: false,
      classificationSocietyDisabled: false,
      showApprovedByField: false,
      showPreparedByField: false,
      showRemarkField: false,
      showIHMMaintenancePeriodField: false,
      regionNameList: [],
      nextIhmmaintenanceStartDate: "",
      previousIhmmaintenanceEndDate: "",
      projectno: "",
      displayprojectno: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      showTypeNameModal: false,
      typeName: "",
    };
  }

  componentDidMount() {
    const { trackingToolYearList, regionList } = this.props;
    this.setState({
      yearList: trackingToolYearList,
      regionNameList: regionList,
    });

  }

  /* <summary>
    date: 3/2/2023
    Name:NGG
    description:sets file state on File upload 
    <summary> */
  onFileUpload = async (e) => {
    const { name, value } = e.target;
    let files = e.target.files[0];

    if (!isValidFile(e.target.value, validFileFormats)) {
      this.setState({
        [name]: null,
        fileName: null,
        fileErrorMessage: true,
        showErrorMessage: false,
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileError,
        //fileInputKey: generateRandomString,
      });
      return;
    }

    this.setState(
      {
        [name]: files,
        fileName: files.name,
        filePath: value,
        fileErrorMessage: false,
      },
      () => {

        if (this.state.showErrorMessage)
          this.validateFields();
      }
    );
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:validates the required fields
       <summary> */
  validateFields = () => {
    const {
      errorMessages,
      dropdown_client,
      dropdown_ship,
      dropdown_documenttype,
      //issueDate,
      dropdown_classificationSociety,
      ihmmaintenanceStartDate,
      ihmmaintenanceEndDate,
      TFile,
      showIssueDateField,
      showClassificationSocietyField,
      showIHMMaintenancePeriodField,
      fileErrorMessage,
    } = this.state;

    let errors = errorMessages;

    errors["errorTFile"] =
      TFile == null || TFile == undefined || TFile.length == 0 ? "true" : "";
    errors["errorClient"] = parseInt(dropdown_client, 10) === 0 ? "true" : "";
    errors["errorShip"] = parseInt(dropdown_ship, 10) === 0 ? "true" : "";
    errors["errorDocumentType"] =
      parseInt(dropdown_documenttype, 10) == 0 ? "true" : "";

    // if (showIssueDateField && (issueDate == "" || issueDate == null)) {
    //   errors["errorIssueDate"] = "true";
    // } else {
    //   errors["errorIssueDate"] = "";
    // }

    errors["errorClassificationSociety"] =
      showClassificationSocietyField &&
        parseInt(dropdown_classificationSociety, 10) == 0
        ? "true"
        : "";

    if (
      showIHMMaintenancePeriodField &&
      (ihmmaintenanceStartDate == "" || ihmmaintenanceStartDate == null)
    ) {
      errors["errorIHMMaintenancePeriodStartDate"] = "true";
    } else {
      errors["errorIHMMaintenancePeriodStartDate"] = "";
    }

    if (
      showIHMMaintenancePeriodField &&
      (ihmmaintenanceEndDate == "" || ihmmaintenanceEndDate == null)
    ) {
      errors["errorIHMMaintenancePeriodEndDate"] = "true";
    } else {
      errors["errorIHMMaintenancePeriodEndDate"] = "";
    }

    if (
      errors["errorClient"] != "" ||
      errors["errorDocumentType"] != "" ||
      //errors["errorIssueDate"] != "" ||
      errors["errorClassificationSociety"] != "" ||
      errors["errorIHMMaintenancePeriodStartDate"] != "" ||
      errors["errorIHMMaintenancePeriodEndDate"] != "" ||
      errors["errorShip"] != "" || errors["errorTFile"] != ""
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: true,
        fileErrorMessage: false,
      });
      return true;
    }

    this.setState({
      errorMessages,
      showErrorMessage: false,
      fileErrorMessage: false,
    });

    return false;
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:sets date value on date change
       <summary> */
  //HV - 24/03/2023 - handled ihmmaintenanceEndDate should be greater than ihmmaintenanceStartDate
  onDateChanged = (id, e) => {
    const { showErrorMessage } = this.state;

    if (id == "issuedate" && e !== null)
      this.setState({ issueDate: e }, () => {
        if (showErrorMessage) this.validateFields();
      });

    if (id == "ihmmaintenanceStartDate" && e !== null) {
      var nextIhmmaintenanceStartDate = new Date(e);
      nextIhmmaintenanceStartDate.setDate(e.getDate() + 1);
      nextIhmmaintenanceStartDate.toLocaleDateString();
      this.setState(
        {
          ihmmaintenanceStartDate: e,
          nextIhmmaintenanceStartDate: nextIhmmaintenanceStartDate,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    }

    if (id == "ihmmaintenanceEndDate" && e !== null) {
      var previousIhmmaintenanceEndDate = new Date(e);
      previousIhmmaintenanceEndDate.setDate(e.getDate() - 1);
      previousIhmmaintenanceEndDate.toLocaleDateString();
      this.setState(
        {
          ihmmaintenanceEndDate: e,
          previousIhmmaintenanceEndDate: previousIhmmaintenanceEndDate,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  onTextChange = (e) => {
    const { showErrorMessage } = this.state;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    if (showErrorMessage) this.validateFields();
  };

  /* <summary>
     date: 3/2/2023
     Name:NGG
     description:sets dropdown value on drodown value change
     <summary> */
  onDropdownItemSelect = async (e) => {
    const { showErrorMessage } = this.state;
    const {
      shipList,
      clientList,
      trackingToolYearList,
      regionList,
      clientRegionBrigdeList,
    } = this.props;

    var { name, value } = e.target;
    if (name == "dropdown_documenttype" && value != "0") {
      var othersSelected = this.props.documentTypeList.filter(x => x.value == value)[0].label;
      this.setState({ showTypeNameModal: othersSelected === 'Other' });
    }
    this.setState(
      {
        [name]: value,
      },
      () => {

        if (e.target.name == "dropdown_documenttype") {
          if (
            e.target.value == "9" ||
            e.target.value == "14" ||
            e.target.value == "20" ||
            e.target.value == "39" ||
            e.target.value == "36"
          ) {
            if (this.state.dropdown_ship != "0") {

              var shipclassificationid = shipList.filter(
                (x) => x.value == this.state.dropdown_ship
              );
              if (shipclassificationid.length > 0 && shipclassificationid[0].classificationID != null && shipclassificationid[0].classificationID != "") {
                var filteredclassificationList = this.props.classificationSocietyList.filter(
                  (x) => x.value == shipclassificationid[0].classificationID
                )[0].value;
                this.setState({
                  dropdown_classificationSociety: filteredclassificationList,
                  classificationSocietyDisabled: true,
                });
              }
              else {
                this.setState({
                  dropdown_classificationSociety: 0,
                  classificationSocietyDisabled: false,
                });
              }
            }
            this.setState({
              showIssueDateField: true,
              showClassificationSocietyField: true,
              showApprovedByField: true,
              showPreparedByField: true,
              showRemarkField: true,
              showIHMMaintenancePeriodField: false,
              ihmmaintenanceStartDate: "",
              ihmmaintenanceEndDate: "",
              displayprojectno: true,
            });


          } else if (
            e.target.value == "19" ||
            e.target.value == "21" ||
            e.target.value == "22" ||
            e.target.value == "23" ||
            e.target.value == "24"
          ) {
            this.setState({
              showIssueDateField: true,
              showClassificationSocietyField: false,
              showApprovedByField: false,
              showPreparedByField: false,
              showRemarkField: true,
              showIHMMaintenancePeriodField: true,

              dropdown_classificationSociety: 0,
              approvedBy: "",
              preparedBy: "",
              displayprojectno: false,
              projectno: "",
            });
          } else
            this.setState({
              showIssueDateField: false,
              showClassificationSocietyField: false,
              showApprovedByField: false,
              showPreparedByField: false,
              showRemarkField: false,
              showIHMMaintenancePeriodField: false,

              issueDate: "",
              dropdown_classificationSociety: 0,
              approvedBy: "",
              preparedBy: "",
              remarks: "",
              ihmmaintenanceStartDate: "",
              ihmmaintenanceEndDate: "",
              displayprojectno: false,
              projectno: "",
            });
        }
        if (e.target.name == "dropdown_client") {
          if (e.target.value != "0") {
            var filteredShipList = shipList.filter(
              (x) => x.parentValue == e.target.value
            );
            var filteredYearList = trackingToolYearList.filter(
              (x) => x.parentValue == e.target.value
            );


            var filteredClientRegionList = clientRegionBrigdeList
              .filter((x) => x.clientId == e.target.value)
              .map((x) => x.regionID)
              .filter((x) => x);

            var filteredRegionList = regionList.filter((x) =>
              filteredClientRegionList.includes(x.value)
            );


            this.setState({
              shipNameList: filteredShipList,
              yearList: filteredYearList,
              regionNameList: filteredRegionList,
              dropdown_region:
                filteredRegionList.length > 0 ? filteredRegionList[0].value : 0,
            });
          } else {
            this.setState({
              shipNameList: shipList,
              yearList: trackingToolYearList,
              regionNameList: regionList,
              dropdown_region: 0,
            });
          }
        }

        if (e.target.name == "dropdown_ship" && value != "0") {

          var shipclassificationid = shipList.filter(
            (x) => x.value == value
          );
          if (shipclassificationid.length > 0 && shipclassificationid[0].classificationID != null && shipclassificationid[0].classificationID != "") {
            var filteredclassificationList = this.props.classificationSocietyList.filter(
              (x) => x.value == shipclassificationid[0].classificationID
            )[0].value;
            this.setState({
              dropdown_classificationSociety: filteredclassificationList,
              classificationSocietyDisabled: true,
            });
          }
          else {
            this.setState({
              dropdown_classificationSociety: 0,
              classificationSocietyDisabled: false,
            });
          }
        }
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:hits on file upload
   <summary> */
  onUploadBtnClick = () => {
    const { onUploadClick } = this.props;
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_region,
      dropdown_tableA,
      dropdown_tableB,
      dropdown_documenttype,
      issueDate,
      TFile,
      mdSoc,
      preparedBy,
      dropdown_classificationSociety,
      customFileName,
      approvedBy,
      remarks,
      ihmmaintenanceStartDate,
      ihmmaintenanceEndDate,
      projectno,
    } = this.state;

    var hasErrors = this.validateFields();
    if (hasErrors) return;

    const data = {
      dropdown_documenttype: dropdown_documenttype,
      dropdown_client: dropdown_client,
      dropdown_ship: dropdown_ship == 0 ? "" : dropdown_ship,
      dropdown_region: dropdown_region == 0 ? "" : dropdown_region,
      dropdown_classificationSociety:
        dropdown_classificationSociety == 0
          ? ""
          : dropdown_classificationSociety,
      dropdown_tableA: dropdown_tableA == 0 ? 1 : dropdown_tableA,
      dropdown_tableB: dropdown_tableB == 0 ? 1 : dropdown_tableB,
      customFileName: customFileName.trim(),
      issueDate: issueDate,
      approvedBy: approvedBy,
      preparedBy: preparedBy,
      remarks: remarks.trim(),
      ihmmaintenanceStartDate: ihmmaintenanceStartDate,
      ihmmaintenanceEndDate: ihmmaintenanceEndDate,
      TFile: TFile,
      mdSoc: mdSoc,
      project_num: projectno,
    };
    onUploadClick(data);
  };
  onCloseTypeClick = () => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    errors["errorTypeName"] = "";
    errorMessages["errorDocumentType"] = "";
    this.setState({ dropdown_documenttype: 0, showTypeNameModal: false, typeName: "", errorMessages: errors, });
  };

  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };

  ontypeNameChange = (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;

    this.setState({ typeName: e.target.value, }, () => {
      if (!isStringEmpty(errorMessages.errorTypeName)) {
        errors["errorTypeName"] = e.target.value.trim() === "" ? "true" : "";
        this.setState({
          errorMessages: errors,
        })
      }
      else if (!isStringEmpty(errorMessages.DocTypeExist)) {
        this.CheckIfDocTypeExist(e.target.value.trim());
      }
    });
  }
  
  ontypeNameSaveClicked = async (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    if (this.state.typeName.trim() != "") {

      await this.CheckIfDocTypeExist(this.state.typeName.trim());

      if (isStringEmpty(errorMessages.DocTypeExist)) {
        errors["errorTypeName"] = "";
        errorMessages["errorDocumentType"] = "";
        await this.props.onSaveNewTypeClick(this.state.typeName.trim());

        this.setState({ showTypeNameModal: false, dropdown_documenttype: GetLocalStorageDataWithoutParse('selecteddoctype') != null ? GetLocalStorageDataWithoutParse('selecteddoctype') : 0, typeName: "", errorMessages: errors, })
      }
    }
    else {
      errors["errorTypeName"] = "true";
      this.setState({
        errorMessages: errors,
      });
    }
  }
  CheckIfDocTypeExist = async (typeName) => {

    let errors = this.state.errorMessages;
    var docData = {
      TypeName: typeName
    }

    const response = await ReportService.CheckIfDocTypeExist(docData);

    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["DocTypeExist"] = "true";
      } else {
        errors["DocTypeExist"] = "";
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };
  render() {
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_region,
      dropdown_tableA,
      dropdown_tableB,
      dropdown_documenttype,
      dropdown_classificationSociety,
      dropdown_supplier,
      dropdown_flagstate,
      issuedBy,
      issueDate,
      ihmmaintenanceStartDate,
      ihmmaintenanceEndDate,
      expiryDate,
      fileInputKey,
      fileName,
      errorMessages,
      showErrorMessage,
      dropdown_hazmatpresent,
      dropdown_lineName,
      mdSoc,
      fileErrorMessage,
      shipNameList,
      yearList,
      dropdown_year,
      preparedBy,
      customFileName,
      approvedBy,
      remarks,

      showIssueDateField,
      showClassificationSocietyField,
      showApprovedByField,
      showPreparedByField,
      showRemarkField,
      showIHMMaintenancePeriodField,
      nextIhmmaintenanceStartDate,
      previousIhmmaintenanceEndDate,
      toastVisible,
      toastType,
      toastMessage
    } = this.state;

    const {
      onCloseClick,
      clientList,
      shipList,
      documentTypeList,
      classificationSocietyList,
      supplierList,
      flagStateList,
      issuedByList,
      preparedByList,
      lineNameList,
      onUploadClick,
      regionList,
      tableAList,
      tableBList,
    } = this.props;

    return (
      <div className="Upload_report" style={{ display: "block" }}>
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal"
          id="uploadreport-modal"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
            <div className="modal-content box">
              {/* <!-- Modal Header --> */}
              <div className="modal-header">
                <h4 className="modal-title">Upload Report</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick()}
                  />
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="modal-body">
                <div className="row ">
                  <div className="col-lg-4 col-md-12 col-sm-12 data">
                    <div
                      className={
                        !isStringEmpty(errorMessages.errorClient)
                          ? "invalid-position"
                          : ""
                      }
                      id={"clientName"}
                    >
                      <label className="required-field">Client:</label>
                      <Dropdown
                        // dropdownLabel="Client:"
                        name="client"
                        onChange={this.onDropdownItemSelect}
                        selected={dropdown_client}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {clientList == null
                          ? null
                          : clientList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_client > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"clientName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {clientList != null && clientList.filter(x => x.value == dropdown_client)[0]?.label}
                        </UncontrolledTooltip>) : null}
                      {!isStringEmpty(errorMessages.errorClient) && (
                        <ErrorView errorFor="emptyDropdown" />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data">
                    <div
                      className={
                        !isStringEmpty(errorMessages.errorShip)
                          ? "invalid-position"
                          : ""
                      }
                      id={"shipName"}
                    >
                      <label className="required-field">Ship:</label>
                      <Dropdown
                        // dropdownLabel="Ship:"
                        name="ship"
                        onChange={this.onDropdownItemSelect}
                        selected={dropdown_ship}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {shipNameList == null
                          ? null
                          : shipNameList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_ship > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"shipName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {shipNameList != null && shipNameList.filter(x => x.value == dropdown_ship)[0]?.label}
                        </UncontrolledTooltip>) : null}
                      {!isStringEmpty(errorMessages.errorShip) && (
                        <ErrorView errorFor="emptyDropdown" />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data" id={"region"}>
                    <Dropdown
                      dropdownLabel="Region:"
                      name="region"
                      onChange={this.onDropdownItemSelect}
                      selected={dropdown_region}
                      disabled={true}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre",
                        overflow: "hidden",
                      }}
                    >
                      {regionList == null
                        ? null
                        : regionList.map((item, key) => (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      ;
                    </Dropdown>
                    {dropdown_region > 0 ? (
                      <UncontrolledTooltip
                        autohide={false}
                        target={"region"}
                        aria-haspopup="true"
                        role="example"
                        effect="solid"
                        placement="right"
                        style={{ width: "350px" }}
                      >
                        {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                      </UncontrolledTooltip>) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 data" id={"tableA"}>
                    <Dropdown
                      dropdownLabel="Hazmat Table A:"
                      name="tableA"
                      onChange={this.onDropdownItemSelect}
                      selected={dropdown_tableA}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre",
                        overflow: "hidden",
                      }}
                    >
                      {tableAList == null
                        ? null
                        : tableAList.map((item, key) => (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      ;
                    </Dropdown>
                    {dropdown_tableA > 0 ? (
                      <UncontrolledTooltip
                        autohide={false}
                        target={"tableA"}
                        aria-haspopup="true"
                        role="example"
                        effect="solid"
                        placement="right"
                        style={{ width: "350px" }}
                      >
                        {tableAList != null && tableAList.filter(x => x.value == dropdown_tableA)[0]?.label}
                      </UncontrolledTooltip>) : null}
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data" id={"tableB"}>
                    <Dropdown
                      dropdownLabel="Hazmat Table B:"
                      name="tableB"
                      onChange={this.onDropdownItemSelect}
                      selected={dropdown_tableB}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre",
                        overflow: "hidden",
                      }}
                    >
                      {tableBList == null
                        ? null
                        : tableBList.map((item, key) => (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      ;
                    </Dropdown>
                    {dropdown_tableB > 0 ? (
                      <UncontrolledTooltip
                        autohide={false}
                        target={"tableB"}
                        aria-haspopup="true"
                        role="example"
                        effect="solid"
                        placement="right"
                        style={{ width: "350px" }}
                      >
                        {tableBList != null && tableBList.filter(x => x.value == dropdown_tableB)[0]?.label}
                      </UncontrolledTooltip>) : null}
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data">
                    <div
                      className={
                        !isStringEmpty(errorMessages.errorDocumentType)
                          ? "invalid-position"
                          : ""
                      }
                      id={"docType"}
                    >
                      <label className="required-field">Type of Report:</label>
                      <Dropdown
                        //  dropdownLabel="Type of Report:"
                        name="documenttype"
                        onChange={this.onDropdownItemSelect}
                        selected={dropdown_documenttype}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {documentTypeList == null
                          ? null
                          : documentTypeList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_documenttype > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"docType"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                        </UncontrolledTooltip>) : null}
                      {!isStringEmpty(errorMessages.errorDocumentType) && (
                        <ErrorView errorFor="emptyDropdown" />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data">
                    <label htmlFor="Report-Name">Report Name: </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Report-Name"
                      name="customFileName"
                      value={customFileName}
                      onChange={this.onTextChange}
                      maxLength={20}
                    />
                  </div>
                  <div
                    className="col-lg-4 col-md-6"
                    style={{
                      display: this.state.displayprojectno ? "" : "none",
                    }}
                  >
                    <div className="approved-by">
                      <label htmlFor="projectno">Project Number:</label>
                      <input
                        type="text"
                        className="form-control "
                        id="projectno"
                        name="projectno"
                        value={this.state.projectno}
                        onChange={this.onTextChange}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </div>
                {showIssueDateField ||
                  showClassificationSocietyField ||
                  showApprovedByField ||
                  showPreparedByField ||
                  showRemarkField ||
                  showIHMMaintenancePeriodField ? (
                  <fieldset className="p-2 admin-ship-fieldset">
                    <legend className="float-none w-auto p-2 admin-ship-legend">
                      Additional Details
                    </legend>
                    <div className="row">
                      {showIssueDateField ? (
                        <div className=" col-lg-4 col-md-12 ">
                          <div className="issue-date">
                            <label
                              htmlFor="issuedate"
                            //className="required-field"
                            >
                              Issue Date:
                            </label>
                            {/* <div
                              className={
                                !isStringEmpty(errorMessages.errorIssueDate)
                                  ? "invalid-position"
                                  : ""
                              }
                            > */}
                            <DatePicker
                              min="0"
                              selectedDate={issueDate}
                              onDateChange={(e) =>
                                this.onDateChanged("issuedate", e)
                              }
                              isNotRoot={true}
                            >
                              {" "}
                            </DatePicker>
                            {/* {!isStringEmpty(errorMessages.errorIssueDate) && (
                                <div className="invalid-message-icon">
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>
                      ) : null}
                      {showClassificationSocietyField ? (
                        <div className="col-lg-4 col-md-12 ">
                          <div className="flag-state">
                            <div
                              className={
                                !isStringEmpty(
                                  errorMessages.errorClassificationSociety
                                )
                                  ? "invalid-position"
                                  : ""
                              }
                              id={"classi"}
                            >
                              <label className="required-field">
                                Classification Society:
                              </label>
                              <Dropdown
                                // dropdownLabel="Classification Society:"
                                className="required-field"
                                name="classificationSociety"
                                disabled={this.state.classificationSocietyDisabled}
                                onChange={this.onDropdownItemSelect}
                                selected={dropdown_classificationSociety}
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre",
                                  overflow: "hidden",
                                }}
                              >
                                {classificationSocietyList == null
                                  ? null
                                  : classificationSocietyList.map(
                                    (item, key) => (
                                      <option key={key} value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  )}
                                ;
                              </Dropdown>
                              {dropdown_classificationSociety > 0 ? (
                                <UncontrolledTooltip
                                  autohide={false}
                                  target={"classi"}
                                  aria-haspopup="true"
                                  role="example"
                                  effect="solid"
                                  placement="right"
                                  style={{ width: "350px" }}
                                >
                                  {classificationSocietyList != null && classificationSocietyList.filter(x => x.value == dropdown_classificationSociety)[0]?.label}
                                </UncontrolledTooltip>) : null}
                              {!isStringEmpty(
                                errorMessages.errorClassificationSociety
                              ) && <ErrorView errorFor="emptyDropdown" />}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {showApprovedByField ? (
                        <div className="col-lg-4 col-md-12 ">
                          <div className="flag-state">
                            <label htmlFor="Approved-By">Approved By: </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Approved-By"
                              name="approvedBy"
                              value={approvedBy}
                              onChange={this.onTextChange}
                            />
                          </div>
                        </div>
                      ) : null}
                      {showPreparedByField ? (
                        <div className="col-lg-4 col-md-12 ">
                          <div className="flag-state">
                            <label htmlFor="Prepared-By">Prepared By: </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Prepared-By"
                              name="preparedBy"
                              value={preparedBy}
                              onChange={this.onTextChange}
                            />
                          </div>
                        </div>
                      ) : null}
                      {showRemarkField ? (
                        <div className="col-lg-4 col-md-12 ">
                          <div className="flag-state">
                            <label htmlFor="Remarks">Remarks: </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Remarks"
                              name="remarks"
                              value={remarks}
                              onChange={this.onTextChange}
                              maxLength={500}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {showIHMMaintenancePeriodField ? (
                      <div className="row ">
                        <div className=" col-lg-4 col-md-12">
                          <div className="expiry-date">
                            <label
                              htmlFor="ihmmaintenanceStartDate"
                              className="required-field"
                            >
                              IHM Maintenance Start Date:
                            </label>
                            <div
                              className={
                                !isStringEmpty(
                                  errorMessages.errorIHMMaintenancePeriodStartDate
                                )
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <DatePicker
                                min="0"
                                selectedDate={ihmmaintenanceStartDate}
                                onDateChange={(e) =>
                                  this.onDateChanged(
                                    "ihmmaintenanceStartDate",
                                    e
                                  )
                                }
                                isNotRoot={true}
                                maxDate={previousIhmmaintenanceEndDate}
                              >
                                {" "}
                              </DatePicker>
                              {!isStringEmpty(
                                errorMessages.errorIHMMaintenancePeriodStartDate
                              ) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="expiry-date">
                            <label
                              htmlFor="expirydate"
                              className="required-field"
                            >
                              IHM Maintenance End Date:
                            </label>
                            <div
                              className={
                                !isStringEmpty(
                                  errorMessages.errorIHMMaintenancePeriodEndDate
                                )
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <DatePicker
                                min="0"
                                selectedDate={ihmmaintenanceEndDate}
                                onDateChange={(e) =>
                                  this.onDateChanged("ihmmaintenanceEndDate", e)
                                }
                                isNotRoot={true}
                                minDate={nextIhmmaintenanceStartDate}
                              >
                                {" "}
                              </DatePicker>
                              {!isStringEmpty(
                                errorMessages.errorIHMMaintenancePeriodEndDate
                              ) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </fieldset>
                ) : null}

                <div className="choosefile-btn">
                  <div className="file-upload-modal">
                    <input
                      type="file"
                      id="actual-btn"
                      accept={validFileFormatsReadable}
                      name="TFile"
                      onChange={this.onFileUpload}
                      key={fileInputKey || ""}
                      hidden
                    />
                    {/* <!-- our custom upload button --> */}
                    <label
                      htmlFor="actual-btn"
                      className={
                        "attach-btn attach-btn-responsive chs-btn " +
                        (!isStringEmpty(errorMessages.errorTFile)
                          ? "invalid-position-border"
                          : "")
                      }
                    >
                      <img
                        className="upload-img"
                        src="images/Icon awesome-upload.png"
                        width="16px"
                      />
                      &nbsp;<span>Choose File</span>
                    </label>
                    {!isStringEmpty(errorMessages.errorTFile) && (
                      <img
                        src="images\icon material-error.png"
                        alt="invalid"
                        className="invalid-position-img"
                        style={{ width: "16px" }}
                      />
                    )}
                  </div>
                  <label className="required-field">
                    &nbsp; (Please select Excel, Doc or PDF file)
                  </label>
                  {/*  &nbsp; (Please select Excel, Doc or PDF file) */}
                </div>
                <label className="label-breakword">&nbsp;&nbsp;&nbsp;{fileName}</label>

                {/* {fileErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please upload a valid file.</p>
                  </div>
                )} */}

                {showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}

                <div className="center">
                  <button
                    type="button"
                    className="btn btn-background-color ship-detail-upload-btn"
                    onClick={this.onUploadBtnClick}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showTypeNameModal && (
            <div className="supplier_on_hold">
              <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
              <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Add Type</h4>
                      <button type="button" data-bs-dismiss="modal">
                        <img
                          src="images/modal-close.png"
                          alt="modal-close"
                          onClick={this.onCloseTypeClick}
                        />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row content">
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                          <div className={
                            !isStringEmpty(errorMessages.errorTypeName) || !isStringEmpty(errorMessages.DocTypeExist)
                              ? "invalid-position"
                              : ""
                          }>
                            <label htmlFor="todate">Type Name:</label>
                            <br />
                            <input
                              type="typeName"
                              className="form-control"
                              id="typeName"
                              name="typeName"
                              placeholder="Enter Type Name"
                              value={this.state.typeName}
                              onChange={this.ontypeNameChange}
                              maxLength={50}
                            />
                            {(!isStringEmpty(errorMessages.errorTypeName)) || (!isStringEmpty(errorMessages.DocTypeExist)) && (
                              <div className="misc-invalid-message-icon ">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.errorTypeName) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.DocTypeExist) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Document type already exists.</p>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-background-color save-btn"
                          onClick={this.ontypeNameSaveClicked}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
export default withRouter(ReportsModal);
