import webserviceWrapper from "../helpers/webserviceWrapper";


export const LoginService = {
    LoginUserService,
    GetTermsAndConditionData,
    SaveRequestNewPasswordData,
    LogoutUserService,
    ResetPasswordService,
};

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to authenticate valid user login
    <summary> */
function LoginUserService(data) {

    return webserviceWrapper.post("/Login/LoginUser", data, 60000);
}

function GetTermsAndConditionData() {
    return webserviceWrapper.get(`/Login/GetTermsAndConditionData`);
}

function SaveRequestNewPasswordData(changeRequestData) {
    return webserviceWrapper.post(`/Login/SaveRequestNewPasswordData`, changeRequestData, 60000);
}

function LogoutUserService(data) {
    return webserviceWrapper.post("/Login/LogoutUser", data, 60000);
}

function ResetPasswordService(data) {
    return webserviceWrapper.post('/Login/ResetPassword', data, 60000);
}