

import React, { Component } from 'react'

export default function Searchbar(props) {
    return (
        <div className="searchBar">
            <input id="searchQueryInput" type="text" name="searchQueryInput" placeholder={props.placeholder} onChange={props.onChange} value={props.value} className="search-placeholder-text" />
            <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                <img src="images/magnifying-glass.png" width="27px" />
            </button>
        </div>

    )
}

