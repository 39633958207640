import webserviceWrapper from "../helpers/webserviceWrapper";

export const dashboardservice={
    GetAllDashboardinfo,
    GetUserPermissionDashBoard,
    GetClientDashboardInfo,
    GetClientPermissionDashBoard,
    GetSupplierDashboardInfo,
    GetShipDashboardInfo,
    GetSupplierSummaryGridInfo,
    DownloadPOSummaryDoc,
    GetClientUserDashboardInfo,
}

function GetAllDashboardinfo(userID){
    return webserviceWrapper.get(
        `/Dashboard/GetAllDashboardinfo?userID=${userID}`,
    );
}

function GetUserPermissionDashBoard(userId){
    return webserviceWrapper.get(
        `/Dashboard/GetUserPermissionDashBoard?userId=${userId}`,
    );
}

function GetClientDashboardInfo(clientId, shipId){
    return webserviceWrapper.get(
        `/Dashboard/GetClientDashboardInfo?clientId=${clientId}&shipId=${shipId}`,
    );
}

function GetClientPermissionDashBoard(clientId, clientUserId){
    return webserviceWrapper.get(
        `/Dashboard/GetClientPermissionDashBoard?clientId=${clientId}&clientUserId=${clientUserId}`,
    );
}

function GetSupplierDashboardInfo(supplierId){
    return webserviceWrapper.get(
        `/Dashboard/GetSupplierDashboardInfo?supplierId=${supplierId}`,
    );
}

function GetShipDashboardInfo(shipId){
    return webserviceWrapper.get(
        `/Dashboard/GetShipDashboardInfo?shipId=${shipId}`,
    );
}

function GetSupplierSummaryGridInfo(userId,selectValue){
    return webserviceWrapper.get(
        `/Dashboard/GetSupplierSummaryGridInfo?supplierId=${userId}&SupplierSummaryValue=${selectValue}`,
    );

}
function DownloadPOSummaryDoc(FileIDlist) {
    return webserviceWrapper.post(
        `/Dashboard/DownloadPOSummaryDoc`,
        FileIDlist,
        600000
    );
}

function GetClientUserDashboardInfo(clientUserId, clientId, shipId){
    return webserviceWrapper.get(
        `/Dashboard/GetClientUserDashboardInfo?clientUserId=${clientUserId}&clientId=${clientId}&shipId=${shipId}`,
    );
}