import React, { Component } from "react";
import { LoginService } from "../../services/login.service";
import { isStringEmpty, isValidEmail } from "../../helpers/string-helper";
import { helperText } from "../../helpers/helperText";
/* import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha"; */

import Loader from "../../components/Loader";
import LoginHeader from "../../components/LoginHeader";
import { AddLocalStorageData, withRouter } from "../../helpers";
import Toast from "../../components/Toast";
import { UserRole, KEY } from "../../helpers/enums";
import ForgotPasswordModal from "../../components/ForgotPasswordModal";
import ProceedModal from "../../components/ProceedModal";
import Logout from "../../helpers/Logout";
import ReCAPTCHA from "react-google-recaptcha";
import { CommonService } from "../../services/common.service";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      boxcheck: false,
      errorUsername: false,
      errorPassword: false,
      errorCheckbox: false,
      showPassword: false,
      toastVisible: false,
      isLoading: false,
      toastMessage: "",
      captcha: "",
      errorCaptcha: false,
      background: "login-blue-theme",
      logo: "images/maritec-logo.png",
      hasErrors: false,
      randomNumber: 0,
      showForgotPassModal: false,
      userEmailId: "",
      showProceedModal: false,
      errorMessages: {},
      recaptchaValue: "",
      isDevUrl: window.location.href.includes('dev') ? true : false,
    };

    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);
  }

  //AN 25/04/2023 
  handleRecaptchaChange(value) {
    this.setState({ recaptchaValue: value, errorCaptcha: "" });
  }

  componentDidUpdate() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);

    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    this.onReload();
  }

  onTextChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
    if (name == "username" && value != "") {
      this.setState({
        errorUsername: "",
      });
    }
    if (name == "password" && value != "") {
      this.setState({
        errorPassword: "",
      });
    }
    if (name == "captcha" && value != "") {
      this.setState({
        errorCaptcha: "",
      });
    }
  };

  CheckboxClick = (event) => {
    if (event.target.checked) {
      this.setState({
        errorCheckbox: "",
      });
    } else {
    }
    this.setState((prevState) => ({ boxcheck: !prevState.boxcheck }));
  };

  /* <summary>
         date: 16/02/2023
         Name: VK
         description: Generates random number to display required background
         <summary> */
  onReload = () => {
    const Num = Math.floor(Math.random() * 9) + 1;

    this.setState({ randomNumber: Num });
    if (Num % 2 == 0) {
      this.setState({
        background: "login-white-theme",
        logo: " images/maritec-logo-white.png",
      });

      //loadCaptchaEnginge(6, "#1C4254", "white");
    } else {
      //loadCaptchaEnginge(6, "white", "#1C4254");
    }
  };

  /* <summary>
      date: 16/02/2023
      Name: VK
      description: Button click event for SignIn
      <summary> */
  /* <summary>
     date: 1/03/2023
     Name: DP
     description: validation message not coming
     <summary> */
  onSignInClicked = async () => {
    const { username, password, recaptchaValue, isDevUrl } = this.state;

    let hasErrors = false;

    if (isStringEmpty(username)) {
      hasErrors = true;
      this.setState({ errorUsername: helperText.loginPageText.errorUsername });
    } else
      this.setState({
        errorUsername: "",
      });

    if (isStringEmpty(password)) {
      hasErrors = true;
      this.setState({
        errorPassword: helperText.loginPageText.errorPassword,
      });
    } else
      this.setState({
        errorPassword: "",
      });

    if (isStringEmpty(recaptchaValue) && !isDevUrl) {
      hasErrors = true;
      this.setState({
        errorCaptcha: helperText.loginPageText.errorEmptyCaptcha,
      });
    } else {
      this.setState({
        errorCaptcha: "",
      });
    }

    if (!this.state.boxcheck) {
      hasErrors = true;
      this.setState({
        errorCheckbox: helperText.loginPageText.errorCheckbox,
      });
    } else {
      this.setState({
        errorCheckbox: "",
      });
    }

    this.setState({
      hasErrors: hasErrors,
    });

    if (hasErrors) return;

    this.setState({ isLoading: true }, async () => {
      var data = {
        Username: username,
        Password: password,
      };
      const response = await LoginService.LoginUserService(data);
      if (response.status !== 200 && response.data == null) {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.loginPageText.maxLoginLimitExceeded,
        });
        return;
      }
      let responseData = response.data;

      if (response.status === 200 && responseData?.message == "Success") {

        AddLocalStorageData("loggedInUser", JSON.stringify(response.data));
        AddLocalStorageData(
          "SiteRights",
          JSON.stringify(response.data.listSightRightData)
        );
        AddLocalStorageData("SessionToken", response.data.jwtToken);
        this.setState({ isLoading: false }, () => {
          if (
            response.data.typeId == UserRole.CLIENT ||
            response.data.typeId == UserRole.CLIENTUSER
          ) {
            this.props.navigate("/ClientDashboard");
          } else if (response.data.typeId == UserRole.SUPPLIER) {
            this.props.navigate("/SupplierDashboard");
          } else if (response.data.typeId == UserRole.SHIPCREW) {
            this.props.navigate("/ShipCrewDashboard");
          } else {
            this.props.navigate("/Dashboard");
          }
        });
      }
      if (responseData?.message == "Username or password is incorrect") {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.loginPageText.LoginFailed,
        });
      }
      else if (responseData?.message == "Max login limit") {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.loginPageText.maxLoginLimitExceeded,
        });
      }
      else if (responseData?.status != undefined && responseData?.status?.code == "ECONNABORTED") {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.loginPageText.SessionExpired,
        });
      } else {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: responseData?.message,
        });
      }
    });
  };

  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /*   onReloadCaptchaClick = () => {
  
      const Num = this.state.randomNumber;
      if (Num % 2 == 0) {
        loadCaptchaEnginge(6, "#1C4254", "white");
      } else {
        loadCaptchaEnginge(6, "white", "#1C4254");
      }
    }; */

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.onSignInClicked();
    }
  };

  /* <summary>
    date: 02/03/2023
    Name: HV
    description: open password modal
    <summary>*/

  openResetPasswordModal = () => {
    this.setState({ showForgotPassModal: true });
  };

  /* <summary>
    date: 28/02/2023
    Name: HV
    description: on close modal click
    <summary>*/

  onCloseModalClick = () => {
    this.setState({
      showForgotPassModal: false,
      userEmailId: "",
      errorMessages: {},
      showErrorMessage: false,
    });
  };

  /* <summary>
   date: 01/03/2023
   Name: HV
   description: on close proceed modal
   <summary>*/

  onProceeModalCloseClick = () => {
    this.setState({
      showProceedModal: false,
    });
  };

  /* <summary>
   date: 01/03/2023
   Name: HV
   description: on changing description text
   <summary>*/

  onChange = (e) => {
    const { name, value } = e.target;

    const { errorMessages } = this.state;
    let errors = errorMessages;

    this.setState({ [name]: value }, () => {
      if (!isValidEmail(value) && value != "") {
        errors["errorUserEmailId"] =
          helperText.loginPageText.invalidEmailValidation;
      } else {
        errors["errorUserEmailId"] = "";
      }
      this.setState({
        errorMessages: errors,
        showErrorMessage: errors["errorUserEmailId"] == "" ? false : true,
      });
    });
  };

  /* <summary>
   date: 02/03/2023
   Name: HV
   description: on Request new password click
   <summary>*/

  onRequestNewPasswordClick = async () => {
    const { errorMessages, userEmailId, showErrorMessage } = this.state;
    let errors = errorMessages;
    let hasErrors = false;
    if (!showErrorMessage) {
      errors["errorUserEmailId"] = isStringEmpty(userEmailId)
        ? helperText.loginPageText.emailValidation
        : "";
      if (errors["errorUserEmailId"] != "" || showErrorMessage) {
        hasErrors = true;
      }
    } else {
      hasErrors = true;
    }
    if (!hasErrors) {
      var email = userEmailId;
      var isAdminMail = await CommonService.CheckIfUserIsAdmin(email);
      var isNotAdminMail = await CommonService.CheckIfUserNotAdmin(email);
      if (isAdminMail.data == true) {
        errors["errorUserEmailId"] = helperText.loginPageText.adminEmailPasswordChange;
        hasErrors = true;
        this.setState({ showProceedModal: false, errorMessages: errors, showErrorMessage: hasErrors });
      }
      else if (isNotAdminMail.data == true) {
        errors["errorUserEmailId"] = helperText.loginPageText.supplierOnHold;
        hasErrors = true;
        this.setState({ showProceedModal: false, errorMessages: errors, showErrorMessage: hasErrors });
      }
      else {
        errors["errorUserEmailId"] = "";
        hasErrors = false;
        this.setState(
          { errorMessages: errors, showErrorMessage: hasErrors },
          () => {
            if (!hasErrors) {
              this.setState({ showProceedModal: true });
            }
          }
        );
      }
    }
    this.setState(
      { errorMessages: errors, showErrorMessage: hasErrors },
    );
  };

  /* <summary>
   date: 02/03/2023
   Name: HV
   description: on yes proceed modal click to check if email exist and send email to admin
   <summary> */

  SaveRequestNewPasswordData = async () => {
    const { userEmailId, errorMessages } = this.state;
    let errors = errorMessages;

    this.setState({ isLoading: true, showProceedModal: false });

    var changeRequestData = {
      RequestType: "Change Password",
      UserEmailId: userEmailId,
    };

    var responseSaved = await LoginService.SaveRequestNewPasswordData(
      changeRequestData
    );

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      if (responseSaved.data == null) {
        errors["errorUserEmailId"] = helperText.loginPageText.emailNotRegister;

        this.setState({
          errorMessages: errors,
          isLoading: false,
          showErrorMessage: true,
        });
      } else {
        this.setState(
          {
            showForgotPassModal: false,
            toastVisible: true,
            toastMessage: helperText.loginPageText.forgotRequestSent,
            toastResponseType: helperText.global.toastSuccess,
            isLoading: false,
          },
          () => {
            this.onCloseModalClick();
          }
        );
      }
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  render() {
    const {
      boxcheck,
      errorUsername,
      errorPassword,
      errorCaptcha,
      errorCheckbox,
      showPassword,
      isLoading,
      toastVisible,
      toastResponseType,
      toastMessage,
      background,
      hasErrors,
      logo,
      showForgotPassModal,
      userEmailId,
      errorMessages,
      showProceedModal,
      isDevUrl
    } = this.state;
    return (
      <main className={background}>
        {isLoading && <Loader></Loader>}
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showForgotPassModal && (
          <ForgotPasswordModal
            onCloseClick={this.onCloseModalClick}
            modalTitle={"Forgot Password"}
            onRequestNewPasswordClick={this.onRequestNewPasswordClick}
            onChange={this.onChange}
            userEmailId={userEmailId}
            errorMessages={errorMessages}
          />
        )}

        {showProceedModal && (
          <ProceedModal
            showProceedModal={showProceedModal}
            onNoClick={this.onProceeModalCloseClick}
            onYesClick={this.SaveRequestNewPasswordData}
          />
        )}

        <section className="login-page-height">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-top-header">
                  <LoginHeader />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 col-xl-6 description-details">
                <h1 className="description" style={{ whiteSpace: "pre-line" }}>
                  IHM
                  <br />
                  &
                  <br />
                  SHIP
                  <br />
                  RECYCLING
                  <br />
                  SERVICES
                </h1>
              </div>

              <div className="col-12 col-md-1 col-xl-1 col-xxl-2"></div>
              <div className="col-12 col-md-6 col-xl-5 col-xxl-4">
                <div
                  className={
                    !hasErrors
                      ? "login-main-form"
                      : "login-main-form login-form-margin"
                  }
                >
                  <img
                    src={logo}
                    className="img-fluid mx-auto d-block maritec-img"
                    alt="maritec-logo"
                  />
                  <div className="login-form-input">
                    <h6>Username</h6>
                    <div className="input-validation">
                      <input
                        type="name"
                        className="form-control"
                        name="username"
                        onChange={this.onTextChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder="Enter username"
                        id="username"
                        required
                      />
                      {!isStringEmpty(errorUsername) && (
                        <div className="invalid-message">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          <div className="invalid-login-input">
                            {errorUsername}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="login-form-input">
                    <h6>Password</h6>
                    <div className="input-validation">
                      <div className="password-input-icon">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="password"
                          onChange={this.onTextChange}
                          onKeyDown={this.handleKeyDown}
                          placeholder="Enter password"
                          id="id_password"
                          required
                        />
                        <span onClick={this.togglePassword}>
                          {" "}
                          <i
                            className={
                              showPassword
                                ? "fa fa-eye eye-icon"
                                : "fa eye-icon fa-eye-slash"
                            }
                            id="togglePassword"
                          ></i>{" "}
                        </span>
                      </div>
                      {!isStringEmpty(errorPassword) && (
                        <div className="invalid-message">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          <div className="invalid-login-input">
                            {errorPassword}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {!isDevUrl ? ( */}
                    <div
                    id="captchaBackground"
                    className="captch-checkbox-validation"
                  >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <ReCAPTCHA
                        sitekey={KEY.RECAPTCHA_SITE_KEY}
                        onChange={this.handleRecaptchaChange}
                      />
                    </div>
                    <span id="output"></span>
                    {!isStringEmpty(errorCaptcha) && (
                      <div className="invalid-message">
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>
                        <div className="invalid-login-input">
                          {errorCaptcha}
                        </div>
                      </div>
                    )}
                  </div>
                 {/*  ) : null}*/}

                  <div
                    id="login-checkbox"
                    className="captch-checkbox-validation"
                  >
                    <span>
                      <input
                        className="form-check-input mb-3"
                        type="checkbox"
                        id="check1"
                        name="boxcheck"
                        value={boxcheck}
                        onChange={this.CheckboxClick}
                        onKeyDown={this.handleKeyDown}
                      />
                      <span className="termsNcondition ">
                        <b>I agree to the</b>
                        &nbsp;
                        <span>
                          <a
                            style={{ color: "blue", cursor: "pointer" }}
                            className="termsNconditionlink"
                            href="/TermsAndConditions"
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>
                        </span>
                      </span>

                      {!isStringEmpty(errorCheckbox) && (
                        <div className="invalid-message">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          <div className="invalid-login-input">
                            {errorCheckbox}
                          </div>
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="center">
                    <button
                      type="button "
                      className="btn btn-block"
                      onClick={this.onSignInClicked}
                    >
                      Sign In
                    </button>
                  </div>
                  <p className="mt-2 mb-0 next-link">
                    <a
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={this.openResetPasswordModal}
                    >
                      Forgot Password?
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="login-main-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="login-right-footer">
                  <p>© CTI-MARITEC. All rights reserved </p>
                  <a href="/TermsAndConditions" target="_blank">
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(Login);
