import React from "react";
import { helperText, withRouter } from "../helpers";
import { config } from "../helpers/config";
import { UserService } from "../services/user.service";
import { UserLevel, UserRole, SITERIGHTS } from "../helpers/enums";
import { dashboardservice } from "../services/dashboard.service";
import ClientChangeRequestModal from "../pages/ClientShipPortal/ClientChangeRequestModal";
import ProceedModal from "./ProceedModal";
import { isStringEmpty, isValidEmail } from "../helpers/string-helper";
import { ClientService } from "../services/client.service";
import DirtyModal from "./DirtyModal";
import Loader from "./Loader";
import * as globalFunctions from "../helpers/utilities";
import { SupplierService } from "../services/supplier.service";
import Toast from "./Toast";
import Logout from "../helpers/Logout";
import LogoutModal from "../components/LogoutModal";
import { LoginService } from "../services/login.service";
import ResetPassword from "./ResetPasswordModal";
import { UserAction } from "../helpers/enums";
import blankPic from "../assets/images/BlankProfile.png";
import { NotificationService } from "../services/notification.service";

const moduleList = [
  {
    id: 1,
    moduleName: "Profile",
    navigateTo: "Client Details",
    showClient: true,
  },
  {
    id: 2,
    moduleName: "Ship Crew",
    navigateTo: "Ship Details",
    showClient: true,
  },
  {
    id: 3,
    moduleName: "Tracking Tool",
    navigateTo: "Tracking Tool",
    showClient: true,
  },
  { id: 4, moduleName: "Reports", navigateTo: "Reports", showClient: true },
  {
    id: 5,
    moduleName: "Manage Users",
    navigateTo: "Manage Users",
    showClient: true,
  },
  { id: 6, moduleName: "Training", navigateTo: "Training", showClient: true },
  { id: 7, moduleName: "Documents", navigateTo: "Documents", showClient: true },
  {
    id: 8,
    moduleName: "Profile",
    navigateTo: "Client User Details",
    showClient: false,
  },
  {
    id: 9,
    moduleName: "Add New Client User",
    navigateTo: "Manage Client Users",
    showClient: false,
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserData:
        globalFunctions.GetLocalStorageDataWithoutParse("loggedInUser"),
      userId: 0,
      userProfilePicUrl:
        this.props.profileUrl != null && this.props.profileUrl != ""
          ? this.props.profileUrl
          : globalFunctions.GetLocalStorageDataWithoutParse(
            "loggedInUserPic"
          ) != null &&
            globalFunctions.GetLocalStorageDataWithoutParse(
              "loggedInUserPic"
            ) != ""
            ? globalFunctions.GetLocalStorageDataWithoutParse("loggedInUserPic")
            : blankPic,
      userProfileName:
        globalFunctions.GetLocalStorageDataWithoutParse("loggedInUserName"),
      showNotification: false,
      notificationData: [],
      notificationCount: 0,
      actionRequiredCount: 0,
      isClient: false,
      isAdminInternalLoggedIn: false,
      showClientRequestModal: false,
      showProceedModal: false,
      description: "",
      errorMessages: {},
      isLoading: false,
      dropdown_moduleName: 0,
      showErrorMessage: false,
      isSupplier: false,
      isShipCrew: false,
      shipList: [],
      userTypeId: 0,
      dropdown_ship: 0,
      isClientUser: false,
      clientId: 0,
      componentFor: this.props.componentFor || "",
      userEmailId: "",
      showLogoutModal: false,
      showResetPasswordModal: false,
      accessList: [],
      showDirtyPopup: false,
      path: "",
      notificationText: "",
      viewedData: [],
      notificationID: 0,
    };
  }

  //SYJ - 06/01/2023 Load User Data
  componentDidMount = async () => {
    const { componentFor } = this.state;
    var userData = globalFunctions.GetLocalStorageDataWithParse("loggedInUser");
    const currentURL = window.location.href;

    if (this.CheckIfUserLoggedInAllowed(userData, componentFor)) {
      this.setState(
        {
          userId: userData.id,
          userTypeId: userData.typeId,
          isClientUser: userData.typeId == UserRole.CLIENTUSER,
          isShipCrew: userData.typeId == UserRole.SHIPCREW,
          isSupplier: userData.typeId == UserRole.SUPPLIER,
          isClient: userData.typeId == UserRole.CLIENT,
          isAdminInternalLoggedIn:
            userData.typeId == UserRole.ADMIN ||
            userData.typeId == UserRole.INTERNALUSER ||
            userData.typeId == UserRole.ACCOUNTS,
          isDashboardActive: currentURL.toLowerCase().includes("dashboard"),
          isActionRequiredActive: currentURL
            .toLowerCase()
            .includes("actionrequired"),
          accessList:
            globalFunctions.GetLocalStorageDataWithParse("userRolePermission"),
        },
        async () => {
          if (this.state.isClient) {
            this.GetClientHeaderInfo(this.state.userId, userData.name);
          } else if (this.state.isSupplier) {
            this.GetSupplierHeaderInfo(this.state.userId, userData.name);
          } else if (this.state.isShipCrew) this.GetShipHeaderData(userData);
          else if (this.state.isClientUser)
            this.GetClientUserHeaderInfo(userData);
          else {
            await this.getHeaderData(userData);
          }
        }
      );
    } else if (
      componentFor !== "ContactUs" &&
      componentFor !== "TermsAndCondition"
    ) {
      this.setState({ isLoading: false }, () => {
        Logout(this.props.navigate);
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.profileUrl != this.props.profileUrl) {
      this.setState({
        userProfilePicUrl:
          this.props.profileUrl != null && this.props.profileUrl != ""
            ? this.props.profileUrl
            : globalFunctions.GetLocalStorageDataWithoutParse(
              "loggedInUserPic"
            ) != null &&
              globalFunctions.GetLocalStorageDataWithoutParse(
                "loggedInUserPic"
              ) != ""
              ? globalFunctions.GetLocalStorageDataWithoutParse("loggedInUserPic")
              : blankPic,
      });
    }
  };

  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState(
      {
        showDirtyPopup: false,
      },
      async () => {
        if (this.state.path != "") {
          globalFunctions.AddLocalStorageData("isDirty", false);
          if (this.state.path === "Logout") this.onLogOutClick();
          else if (this.state.path === "Dashboard") this.onDashboardClick();
          else if (this.state.path === "ActionRequired")
            this.onActionRequiredClick();
          else if (this.state.path === "Ships") this.onShipsClick();
          else if (this.state.path === "ManageSupplier")
            this.onManageSuppliersClick();
          else if (this.state.path === "ManageShips")
            this.navigateToSection(path);
          else if (this.state.path === "/TrackingTool")
            this.navigateToSection(path);
          else if (this.state.path === "Client") this.onClientsClick();
          else if (this.state.path === "ActivityLog") this.onViewAllClick();
          else if (this.state.path === "TrackingTool")
            this.onTrackingToolClick();
          else if (this.state.path === "Logo") this.onLogoClick();
          else if (this.state.path === "Profile") this.onProfileClick();
          else if (this.state.path === "Training") this.navigateToTraining();
          else if (this.state.path === "Documents") this.navigateToDocuments();
          else if (this.state.path === "Statistics") this.navigateToStatistics();
          else if (this.state.path === "Notification")
            this.onViewNotificationClick(
              this.state.notificationText,
              this.state.viewedData,
              this.state.notificationID
            );
          else if (this.state.path === "/Settings")
            this.navigateTo("/Settings");
          else if (
            this.state.path === "/Settings" ||
            this.state.path === "/Communication" ||
            this.state.path === "/Reports" ||
            this.state.path === "/ManageIHMDatabaseRegistry" ||
            this.state.path === "/MiscellaneousOperations" ||
            this.state.path === "/ContactUs"
          )
            this.navigateTo(this.state.path);
          else if (this.state.path === "https://www.maritec.com.sg/")
            window.open(this.state.path);
          else this.navigateTo(this.state.path);
        }
      }
    );
  };
  onNavigateClick = (pathvalue) => {
    var isDirty = globalFunctions.GetLocalStorageDataWithoutParse("isDirty");

    if (isDirty === "true") {
      this.setState({ showDirtyPopup: true, path: pathvalue });
    } else {
      if (pathvalue === "Logout") this.onLogOutClick();
      else if (pathvalue === "Dashboard") this.onDashboardClick();
      else if (pathvalue === "ActionRequired") this.onActionRequiredClick();
      else if (pathvalue === "Ships") this.onShipsClick();
      else if (pathvalue === "ManageSupplier") this.onManageSuppliersClick();
      else if (pathvalue === "ManageShips") this.navigateToSection(pathvalue);
      else if (pathvalue === "/TrackingTool") this.navigateToSection(pathvalue);
      else if (pathvalue === "Client") this.onClientsClick();
      else if (pathvalue === "ActivityLog") this.onViewAllClick();
      else if (pathvalue === "TrackingTool") this.onTrackingToolClick();
      else if (pathvalue === "Logo") this.onLogoClick();
      else if (pathvalue === "Profile") this.onProfileClick();
      else if (pathvalue === "Training") this.navigateToTraining();
      else if (pathvalue === "Documents") this.navigateToDocuments();
      else if (pathvalue === "Statistics") this.navigateToStatistics();
      else if (pathvalue === "/Settings") this.navigateTo(pathvalue);
      else if (pathvalue === "/Communication") this.navigateTo(pathvalue);
      else if (pathvalue === "/Reports") this.navigateTo(pathvalue);
      else if (pathvalue === "/ManageIHMDatabaseRegistry")
        this.navigateTo(pathvalue);
      else if (pathvalue === "/MiscellaneousOperations")
        this.navigateTo(pathvalue);
      else if (pathvalue === "/ContactUs") this.navigateTo(pathvalue);
      else if (pathvalue === "https://www.maritec.com.sg/")
        window.open(pathvalue);
      else this.navigateTo(pathvalue);
    }
  };
  onViewNotifiationNavigateClick = async (
    pathvalue,
    notificationText,
    viewedData,
    notificationID
  ) => {
    var isDirty = globalFunctions.GetLocalStorageDataWithoutParse("isDirty");

    if (isDirty == "true") {
      this.setState({
        showDirtyPopup: true,
        path: pathvalue,
        notificationText: notificationText,
        viewedData: viewedData,
        notificationID: notificationID,
      });
    } else {
      this.onViewNotificationClick(
        notificationText,
        viewedData,
        notificationID
      );
    }
  };

  onViewDisabledNotificationClicked = () => {
    this.setState({
      toastVisible: true,
      toastType: helperText.global.toastError,
      toastMessage: helperText.global.accessError,
    });
    return;
  };

  CheckIfUserLoggedInAllowed = (userData, componentFor) => {
    let loginAllowed = null;
    if (userData != null) {
      if (componentFor != null && componentFor != "") {
        var screenAllowed = globalFunctions.GetAllowedScreensForLoggedInUser(
          userData.typeId,
          componentFor
        );
        if (screenAllowed != null && screenAllowed.includes(componentFor)) {
          loginAllowed = true;
        }
      } else {
        loginAllowed = true;
      }
    } else {
      loginAllowed = false;
    }
    return loginAllowed;
  };

  getHeaderData = async (userData) => {
    this.setState({ isLoading: true });
    var response = await UserService.GetUserProfileData(
      userData.id,
      userData.typeId
    );
    if (response.status == undefined || response.status == 401 || (response.error != null && response.error.includes('401'))) {     
      Logout(this.props.navigate);
    }
    else if (response.status == 200) {
      var DefaultRolePermissionList =
        response.data.lstDefaultUserRolePermission;
      var RolePermissionList = response.data.lstUserRolePermissions;

      var accessList =
        response.data.userRoleId != UserRole.ADMIN
          ? response.data.levelId == UserLevel.CUSTOM
            ? RolePermissionList != null
              ? RolePermissionList[0]
              : null
            : DefaultRolePermissionList != null
              ? DefaultRolePermissionList[0]
              : null
          : null;
      globalFunctions.AddLocalStorageData(
        "userRolePermission",
        JSON.stringify(accessList)
      );
      globalFunctions.AddLocalStorageData(
        "loggedInUserPic",
        response.data.userPic
      );
      globalFunctions.AddLocalStorageData(
        "loggedInUserName",
        response.data.name
      );
      globalFunctions.AddLocalStorageData(
        "loggedInLoginUserMail",
        response.data.outlookUsername
      );
      globalFunctions.AddLocalStorageData(
        "loggedInLoginUserMailPassword",
        response.data.outlookPassword
      );
      this.setState(
        {
          userProfilePicUrl:
            response.data.userPic != null
              ? response.data.userPic
              : this.state.userProfilePicUrl,
          userProfileName: response.data.name,
          actionRequiredCount: response.data.actionRequiredCount,
          accessList: accessList,
        },
        () => {
          this.getNotificationData();
        }
      );
    }
  };

  /* <summary>
    date: 27/02/2023
    Name: HV
    description: This is used to get client details
    <summary>*/

  GetClientHeaderInfo = async (clientId, clientName) => {
    var response = await dashboardservice.GetClientDashboardInfo(clientId, 0);
    if (response.status == 200 && response.data != null) {
      let clientDashboardData = response.data;
      this.setState(
        {
          userProfilePicUrl: clientDashboardData.clientProfilePic,
          userProfileName: clientDashboardData.clientName,
          shipList: clientDashboardData.shipList,
          dropdown_ship:
            clientDashboardData.clientDefaultShipId == null
              ? 0
              : clientDashboardData.clientDefaultShipId,
          accessList:
            response.data.clientRoleId == 5
              ? clientDashboardData?.listCustomClientRolePermissions
              : clientDashboardData?.listDefaultClientRolePermission,
        },
        () => {
          globalFunctions.AddLocalStorageData(
            "loggedInUserPic",
            this.state.userProfilePicUrl
          );
          globalFunctions.AddLocalStorageData(
            "loggedInUserName",
            this.state.userProfileName
          );
          globalFunctions.AddLocalStorageData(
            "userRolePermission",
            JSON.stringify(
              this.state.accessList != undefined ? this.state.accessList : []
            )
          );
          this.getNotificationData();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
    this.setState({ isLoading: false });
  };

  /* <summary>
   date: 15/03/2023
   Name: HV
   description: This is used to get client user details
   <summary>*/

  GetClientUserHeaderInfo = async (userData) => {
    var response = await dashboardservice.GetClientUserDashboardInfo(
      userData.id,
      userData.clientId,
      0
    );
    if (response.status == 200 && response.data != null) {
      let clientDashboardData = response.data;
      globalFunctions.AddLocalStorageData(
        "loggedInUserPic",
        clientDashboardData.clientProfilePic
      );
      globalFunctions.AddLocalStorageData(
        "loggedInUserName",
        clientDashboardData.clientUserName
      );
      this.setState(
        {
          userProfilePicUrl: clientDashboardData.clientProfilePic,
          userProfileName: clientDashboardData.clientUserName,
          shipList: clientDashboardData.shipList,
          dropdown_ship:
            clientDashboardData.clientDefaultShipId == null
              ? 0
              : clientDashboardData.clientDefaultShipId,
          clientId: userData.clientId,
          accessList:
            response.data.clientRoleId == 5
              ? response.data.listCustomClientRolePermissions
              : response.data.listDefaultClientRolePermission,
        },
        () => {
          globalFunctions.AddLocalStorageData(
            "userRolePermission",
            JSON.stringify(this.state.accessList)
          );
          this.getNotificationData();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
    this.setState({ isLoading: false });
  };

  GetSupplierHeaderInfo = async (supplierId, supplierName) => {
    var response = await dashboardservice.GetSupplierDashboardInfo(supplierId);

    if (response.status == 200 && response.data != null) {
      let supplierDashboardData = response.data;
      this.setState(
        {
          userProfilePicUrl: supplierDashboardData.supplierProfilePic,
          userProfileName: supplierDashboardData.supplierProfileName,
        },
        () => {
          globalFunctions.AddLocalStorageData(
            "loggedInUserPic",
            this.state.userProfilePicUrl
          );
          globalFunctions.AddLocalStorageData(
            "loggedInUserName",
            this.state.userProfileName
          );
          this.getNotificationData();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
    this.setState({ isLoading: false });
  };

  getNotificationData = async () => {
    if (this.state.userId > 0) {
      var response = await NotificationService.GetNotificationData(
        this.state.userId,
        this.state.userTypeId,
        false
      );
      if (response.status == 200) {
        this.setState(
          {
            notificationData: response.data.listNotificationData,
            notificationCount: response.data.total,
            isLoading: false,
          },
          () => { }
        );
      }
    }
    this.setState({ isLoading: false });
  };

  /* <summary>
  date: 08/03/2023
  Name: HV
  description: This is used to get ship details
  <summary>*/

  GetShipHeaderData = async (userData) => {
    var response = await dashboardservice.GetShipDashboardInfo(userData.id);
    if (response.status == 200) {
      let shipData = response.data;
      this.setState(
        {
          userProfilePicUrl: shipData?.shipProfilePictureUrl,
          //.shipProfileFileMedia?.path,
          userProfileName: shipData?.shipName,
        },
        () => {
          globalFunctions.AddLocalStorageData(
            "loggedInUserPic",
            this.state.userProfilePicUrl
          );
          globalFunctions.AddLocalStorageData(
            "loggedInUserName",
            this.state.userProfileName
          );

          let clientData = {
            clientId: shipData?.clientId,
            clientName: shipData?.clientName,
          };

          globalFunctions.AddLocalStorageData(
            "selectedClient",
            shipData != null ? JSON.stringify(clientData) : []
          );

          this.getNotificationData();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
    this.setState({ isLoading: false });
  };

  //SYJ - 06/01/2023 Clear Localstorage on LogOut click
  onLogOutClick = async () => {
    var userData = globalFunctions.GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) this.setState({ showLogoutModal: true });
    else {
      var data = {
        userId: 0,
        userTypeId: 0,
        jwtToken:
          globalFunctions.GetLocalStorageDataWithoutParse("SessionToken"),
      };
      await LoginService.LogoutUserService(data);
      Logout(this.props.navigate);
    }
    // try {
    //   this.setState({  isLoading: false  });

    //   var data = {
    //     userId: this.state.userId,
    //     userTypeId: this.state.userTypeId,
    //     jwtToken: globalFunctions.GetLocalStorageDataWithoutParse('SessionToken'),
    //   }

    //   await LoginService.LogoutUserService(data);
    //   Logout(this.props.navigate);

    // } catch (error) {
    //   console.log(error);
    // }
  };

  onDashboardClick = () => {
    globalFunctions.AddLocalStorageData("location", "");
    if (this.state.isClient || this.state.isClientUser)
      this.props.navigate("/ClientDashboard");
    else if (this.state.isSupplier) this.props.navigate("/SupplierDashboard");
    else if (this.state.isShipCrew) this.props.navigate("/ShipCrewDashboard");
    else this.props.navigate("/Dashboard");
  };

  onActionRequiredClick = () => {
    this.props.navigate("/ActionRequired");
  };

  onShipsClick = () => {
    globalFunctions.AddLocalStorageData("navigatedFromManageClient", false);
    globalFunctions.AddLocalStorageData("location", "");
    globalFunctions.AddLocalStorageData("selectedClient", null);
    this.props.navigate("/ManageShips");
  };

  onManageSuppliersClick = () => {
    globalFunctions.AddLocalStorageData("location", "");
    this.props.navigate("/ManageSuppliers");
  };

  /* <summary>
  date: 06/03/2023
  Name: HV
  description: hamdled navigating to sjip and if no ships assigned the show toast mesage
  <summary> */

  navigateToSection = (moduleName) => {
    globalFunctions.AddLocalStorageData("location", "");
    if (this.state.shipList.length == 0) {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.Client.noShipsPresent,
      });
    } else {
      if (moduleName == "ManageShips") {
        globalFunctions.AddLocalStorageData("navigatedFromManageClient", false);
        globalFunctions.AddLocalStorageData(
          "selectedShip",
          this.state.dropdown_ship
        );
        this.props.navigate("/ManageShips");
      } else {
        this.props.navigate("/TrackingTool");
      }
    }
  };

  onClientsClick = () => {
    globalFunctions.AddLocalStorageData("location", "");
    this.props.navigate("/ManageClient");
  };

  onNotificationClick = () => {
    const { notificationCount } = this.state;

    this.getNotificationData();
    this.setState({
      showNotification:
        notificationCount == null ? false : !this.state.showNotification,
    });
  };

  onViewAllClick = () => {
    this.props.navigate("/ActivityLog");
  };

  onTrackingToolClick = () => {
    this.props.navigate("/TrackingTool");
  };

  onLogoClick = () => {
    globalFunctions.AddLocalStorageData("location", "");
    if (this.state.isClient || this.state.isClientUser)
      this.props.navigate("/ClientDashboard");
    else if (this.state.isSupplier) this.props.navigate("/SupplierDashboard");
    else if (this.state.isShipCrew) this.props.navigate("/ShipCrewDashboard");
    else this.props.navigate("/Dashboard");
  };

  /* <summary>
   date: 28/02/2023
   Name: HV
   description: handled navigation to client profile details
   <summary>*/

  /* <summary>
   date: 6/03/2023
   Name: DP
   description: set location to blank as - if location is blank will navigate to respective pages or else will navigate to action required or activity log page based on condition
   <summary>*/

  onProfileClick = async () => {
    this.setState({ isLoading: true });
    globalFunctions.AddLocalStorageData("location", "");
    if (this.state.isClient) {
      globalFunctions.AddLocalStorageData("selectedClient", this.state.userId);
      this.setState({ isLoading: false });
      this.props.navigate("/AddEditClient");
    } else if (this.state.isSupplier) {
      const response = await SupplierService.GetManageSupplierData(
        this.state.userId,
        0
      );

      if (response.data != null && response.data != undefined) {
        let supplierData = response.data.filter(
          (s) => s.supplierId === this.state.userId
        );
        globalFunctions.AddLocalStorageData(
          "selectedSupplier",
          JSON.stringify(supplierData[0])
        );
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.setState({ isLoading: false });
      this.props.navigate("/AddEditSupplier");
    } else if (this.state.isShipCrew) {
      globalFunctions.AddLocalStorageData("selectedShip", this.state.userId);
      this.setState({ isLoading: false });
      this.props.navigate("/AddEditShip");
    } else if (this.state.isClientUser) {
      globalFunctions.AddLocalStorageData(
        "selectedClientUserId",
        this.state.userId
      );
      globalFunctions.AddLocalStorageData(
        "selectedClientID",
        this.state.clientId
      );
      this.setState({ isLoading: false });
      this.props.navigate("/AddEditClientUser");
    } else {
      this.setState({ isLoading: false });
      this.props.navigate("/UserProfile");
    }
  };

  /* <summary>
  date: 28/02/2023
  Name: HV
  description: handled navigation to client profile details
  <summary>*/

  onCloseChangeRequestModalClick = () => {
    this.setState({
      showClientRequestModal: false,
      description: "",
      dropdown_moduleName: 0,
      errorMessages: {},
      showErrorMessage: false,
      isLoading: false,
    });
  };

  /* <summary>
  date: 28/02/2023
  Name: HV
  description: handled navigation to client profile details
  <summary>*/

  onShowProceedModalClick = () => {
    const { errorMessages, description, dropdown_moduleName, userEmailId } =
      this.state;

    let errors = errorMessages;
    let hasErrors = false;
    let moduleId = parseInt(dropdown_moduleName, 10);
    errors["errorModuleName"] =
      moduleId == undefined || moduleId == 0 ? "true" : "";
    errors["errordescription"] =
      moduleId != 9 && isStringEmpty(description) ? "true" : "";
    errors["errorUserEmailId"] =
      moduleId == 9 &&
        (isStringEmpty(userEmailId) || !isValidEmail(userEmailId))
        ? "true"
        : "";

    if (
      errors["errorModuleName"] != "" ||
      errors["errordescription"] != "" ||
      errors["errorUserEmailId"] != ""
    ) {
      hasErrors = true;
    }

    this.setState(
      { errorMessages: errors, showErrorMessage: hasErrors },
      () => {
        if (!hasErrors) {
          this.setState({ showProceedModal: true });
        }
      }
    );
  };

  /* <summary>
  date: 01/03/2023
  Name: HV
  description: hopen request modal
  <summary>*/

  openChangeRequestModal = () => {
    this.setState({ showClientRequestModal: true });
  };

  /* <summary>
  date: 01/03/2023
  Name: HV
  description: on close proceed modal
  <summary>*/

  onProceeModalCloseClick = () => {
    this.setState({
      showProceedModal: false,
    });
  };

  /* <summary>
  date: 01/03/2023
  Name: HV
  description: on changing description text
  <summary>*/

  onChange = (e) => {
    const { name, value } = e.target;
    const { errorMessages, showErrorMessage } = this.state;

    let errors = errorMessages;
    let hasErrors = false;

    if (name == "description") {
      errors["errordescription"] = "";
    }

    if (name == "dropdown_moduleName") {
      errors["errorModuleName"] = "";
    }

    if (name == "userEmailId") {
      errors["errorUserEmailId"] = isValidEmail(value) ? "" : "true";
    }

    if (
      (errors["errordescription"] != "" ||
        errors["errorModuleName"] != "" ||
        errors["errorUserEmailId"] != "") &&
      showErrorMessage
    ) {
      hasErrors = true;
    }

    this.setState({
      [name]: value,
      errorMessages: errors,
      showErrorMessage: hasErrors,
    });
  };

  /* <summary>
  date: 01/03/2023
  Name: HV
  description: on change request click
  <summary> */

  SaveClientChangeRequestData = async () => {
    const { userId, description, dropdown_moduleName } = this.state;

    this.setState({ isLoading: true, showProceedModal: false });
    let clientChangeRequestData = new FormData();
    clientChangeRequestData.append("RequestType", description);
    clientChangeRequestData.append("EditedBy", userId);
    clientChangeRequestData.append("ClientId", userId);

    var modulesData = moduleList.filter(
      (x) => x.id == parseInt(dropdown_moduleName, 10)
    )[0];

    if (modulesData != null) {
      clientChangeRequestData.append(
        "ModuleName",
        modulesData.moduleName == "Profile" ? "Client" : modulesData.moduleName
      );
      clientChangeRequestData.append("NavigateTo", modulesData.navigateTo);
    }

    var responseSaved = await ClientService.SaveClientChangeRequestData(
      clientChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.Client.changeRequestSent,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        },
        () => {
          this.onCloseChangeRequestModalClick();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  navigateToTraining = () => {
    globalFunctions.AddLocalStorageData("location", "");
    if (
      this.state.isClient ||
      this.state.isSupplier ||
      this.state.isShipCrew ||
      this.state.isClientUser
    ) {
      globalFunctions.AddLocalStorageData("selectedClient", this.state.userId);
      this.props.navigate("/TrainingDocument");
    } else this.props.navigate("/Training");
  };

  closeToast = () => {
    this.setState({
      toastVisible: false,
    });
  };

  navigateToStatistics = () => {
    this.props.navigate("/ManageStatistics");
  };

  navigateToDocuments = () => {
    globalFunctions.AddLocalStorageData("location", "");
    if (this.state.isShipCrew) {
      this.props.navigate("/ShipDocument");
    } else if (this.state.isSupplier) {
      this.props.navigate("/SupplierDocument");
    } else {
      this.props.navigate("/ClientDocuments");
    }
  };

  /* <summary>
date: 10/03/2023
Name: DP
description: on click of notification link navigate to respective tab
<summary> */
  onViewNotificationClick = async (
    notificationText,
    viewedData,
    notificationID
  ) => {
    globalFunctions.AddLocalStorageData("location", "");
    globalFunctions.AddLocalStorageData(
      "selectedClient",
      JSON.stringify(viewedData)
    );
    var data = {
      notificationID: notificationID,
      userTypeId: this.state.userTypeId,
      userId: this.state.userId,
    };
    var response = await NotificationService.SaveNotificationIsViewedStatus(
      data
    );

    if (response.status == 200) {
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
    if (
      notificationText.includes("NEW_CLIENT_") ||
      notificationText.includes("CLIENT_STATUS")
    )
      this.navigateTo("/ManageClient");
    else if (
      notificationText.includes("NEW_SHIP_") ||
      notificationText.includes("SHIP_STATUS")
    )
      this.navigateTo("/ManageShips");
    else if (
      !this.state.isSupplier &&
      (notificationText.includes("NEW_SUPPLIER_") ||
        notificationText.includes("SUPPLIER_STATUS"))
    )
      this.navigateTo("/ManageSuppliers");
    else if (
      notificationText.includes("NEW_USER_") ||
      notificationText.includes("USER_STATUS")
    )
      this.navigateTo("/ManageUsers");
    else if (notificationText.includes("NEW_CLIENTUSER_"))
      this.navigateTo("/ManageClientUsers");
    else if (
      (notificationText.includes("MD/SDoC") ||
        notificationText.includes("Declaration") ||
        notificationText.includes("PO_ADDED_CLIENT") ||
        notificationText.includes("MANUAL_LINE_ITEM_ADDED") ||
        notificationText.includes("EDIT_TRACKING_TOOL")) &&
      !this.state.isSupplier
    ) {
      if (viewedData?.clientID != null && viewedData?.clientID != 0) {
        let clientData = {
          clientId: viewedData?.clientID,
          shipId: 0,
        };
        globalFunctions.AddLocalStorageData(
          "selectedShipTTool",
          JSON.stringify(clientData)
        );
      }
      this.navigateTo("/TrackingTool");
    } else if (
      notificationText.includes("REQUEST_FOR_MDSDOC_SENT") &&
      this.state.isSupplier
    ) {
      globalFunctions.AddLocalStorageData("SummaryClickValue", 2);
      this.navigateTo("/SupplierViewItems");
    } else if (notificationText.includes("MD/SDoC") && this.state.isSupplier)
      this.navigateTo("/SupplierDocument");
    else if (
      notificationText.includes("TRACKING_TOOL_") &&
      !this.state.isSupplier
    ) {
      if (viewedData?.clientID != null && viewedData?.clientID != 0) {
        let clientData = {
          clientId: viewedData?.clientID,
          shipId: 0,
        };

        globalFunctions.AddLocalStorageData(
          "selectedShipTTool",
          JSON.stringify(clientData)
        );
      }
      this.navigateTo("/TrackingTool");
    } else if (
      notificationText.includes("TRACKING_TOOL_") &&
      this.state.isSupplier
    )
      this.navigateTo("/SupplierDocument");
    else if (
      notificationText.includes("COMMUNICATION_") &&
      this.state.isAdminInternalLoggedIn
    )
      this.navigateTo("/Communication");
    else if (
      notificationText.includes("REPORT") ||
      notificationText.includes("Report")
    ) {
      this.navigateTo("/Reports");
    } else if (notificationText.includes("TRAINING") && this.state.isShipCrew)
      this.navigateTo("/TrainingDocument");
    else if (
      notificationText.includes("TRAINING_DOCUMENT_UPLOADED") &&
      !this.state.isAdminInternalLoggedIn
    )
      this.navigateTo("/TrainingDocument");
    else if ((notificationText.includes("TRAINING") && !this.state.isShipCrew) || notificationText.includes("TRAINING_DOCUMENT_DELETED"))
      this.navigateTo("/Training");
    else if (notificationText.includes("IHM") && this.state.isClient) {
      if (viewedData.text.includes("MD_SDOC_"))
        this.navigateTo("/TrackingTool");
      else
        this.navigateTo("/ClientDocuments");
    }
    else if (notificationText.includes("IHM") && this.state.isShipCrew) {
      if (viewedData.text.includes("MD_SDOC_"))
        this.navigateTo("/TrackingTool");
      else
        this.navigateTo("/ShipDocument");
    }
    else if (notificationText.includes("IHM") && this.state.isClientUser) {
      if (viewedData.text.includes("MD_SDOC_"))
        this.navigateTo("/TrackingTool");
      else
        this.navigateTo("/ClientDocuments");
    }
    else if (notificationText.includes("IHM") && this.state.isSupplier)
      this.navigateTo("/SupplierDocument");
    else if (notificationText.includes("IHM") && !this.state.isSupplier && !this.state.isClientUser && !this.state.isShipCrew && !this.state.isClient)
      this.navigateTo("/ManageIHMDatabaseRegistry");
    else if (notificationText.includes("MISC"))
      this.navigateTo("/MiscellaneousOperations");
    else if (
      notificationText.includes(
        "Client: [Client_Name] sent a request to [Request_Type]"
      ) &&
      !viewedData.text.includes("Change Password")
    ) {
      this.navigateTo("/ManageChangeRequest");
    } else if ((notificationText.includes("Client") && !notificationText.includes("Client user")) || (notificationText.includes("Client") || notificationText.includes("EDIT_CLIENT") && !notificationText.includes("EDIT_CLIENT_USER"))) { //:
      globalFunctions.AddLocalStorageData(
        "selectedClient",
        viewedData.clientID
      );
      this.navigateTo("/AddEditClient");
    } else if (
      notificationText.includes("Supplier") || notificationText.includes("EDIT_SUPPLIER") || (this.state.isSupplier && notificationText.includes("SUPPLIER_STATUS"))
    ) {
      const response = await SupplierService.GetManageSupplierData(
        viewedData.supplierID,
        0
      );
      if (response.data != null && response.data != undefined) {
        let supplierData = response.data.filter(
          (s) => s.supplierId === viewedData.supplierID
        );
        globalFunctions.AddLocalStorageData(
          "selectedSupplier",
          JSON.stringify(supplierData[0])
        );
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.navigateTo("/AddEditSupplier");
    } else if (notificationText.includes("Ship") || notificationText.includes("EDIT_Ship")) {
      globalFunctions.AddLocalStorageData("selectedShip", viewedData.shipID);
      this.navigateTo("/AddEditShip");
    } else if (notificationText.includes("Internal User") || (notificationText.includes("EDIT_USER") && !notificationText.includes("EDIT_USER_PROFILE")) || notificationText.includes("IU_ASSIGNED_CLIENT") || notificationText.includes("IU_UNASSIGNED_CLIENT")
      || notificationText.includes("IU_ASSIGNED_SHIP") || notificationText.includes("IU_UNASSIGNED_SHIP")) {
      globalFunctions.AddLocalStorageData(
        "selectedUser", viewedData.internalUserID == 0 && (
          notificationText.includes("IU_ASSIGNED_CLIENT") || notificationText.includes("IU_UNASSIGNED_CLIENT")
          || notificationText.includes("IU_ASSIGNED_SHIP") || notificationText.includes("IU_UNASSIGNED_SHIP") || (notificationText.includes("EDIT_USER") && !notificationText.includes("EDIT_USER_PROFILE"))) ? this.state.userId : viewedData.internalUserID
      );
      this.props.navigate("/AddUser");
    } else if (notificationText.includes("Client User") || notificationText.includes("EDIT_CLIENT_USER")) {
      if (notificationText.includes("Document")) {
        if (this.state.isClientUser) {
          this.navigateTo("/ClientDocuments");
        } else {
          globalFunctions.AddLocalStorageData(
            "selectedClient",
            viewedData.clientID
          );
          this.navigateTo("/AddEditClient");
        }
      } else {
        globalFunctions.AddLocalStorageData(
          "selectedClientID",
          viewedData.clientID
        );
        if (
          viewedData?.text?.includes("sent a request to Add New Client User")
        ) {
          globalFunctions.AddLocalStorageData("selectedClientUser", 0);
          globalFunctions.AddLocalStorageData("selectedClientUserId", 0);
        } else {
          globalFunctions.AddLocalStorageData(
            "selectedClientUserId",
            viewedData.clientUserID
          );
          globalFunctions.AddLocalStorageData("selectedClientUser", viewedData);
        }
        this.props.navigate("/AddEditClientUser");
      }
    } else if (notificationText.includes("Change Request")) {
      const { isClient } = this.state;
      let path = "";
      if (
        viewedData?.navigateTo?.includes("Client Details") ||
        viewedData?.navigateTo?.includes("Documents")
      ) {
        globalFunctions.AddLocalStorageData(
          "selectedClient",
          viewedData.clientID
        );
        path = "/AddEditClient";
      } else if (viewedData?.navigateTo?.includes("Ship Details")) {
        if (isClient) {
          let clientData = {
            clientId: viewedData.clientID,
            clientName:
              globalFunctions.GetLocalStorageDataWithoutParse(
                "loggedInUserName"
              ),
          };
          globalFunctions.AddLocalStorageData(
            "selectedClient",
            JSON.stringify(clientData)
          );
        } else {
          globalFunctions.AddLocalStorageData(
            "selectedClient",
            viewedData.clientID
          );
        }
        path = "/ManageShips";
      } else if (viewedData?.navigateTo?.includes("Tracking Tool")) {
        if (!isClient) {
          let clientData = {
            clientId: viewedData.clientID,
            shipId: 0,
          };
          globalFunctions.AddLocalStorageData(
            "selectedShipTTool",
            JSON.stringify(clientData)
          );
        } else
          globalFunctions.AddLocalStorageData(
            "selectedClient",
            viewedData.clientID
          );

        path = "/TrackingTool";
      } else if (viewedData?.navigateTo?.includes("Reports")) {
        path = "/Reports";
      } else if (viewedData?.navigateTo?.includes("Manage Users")) {
        if (isClient) {
          let clientData = {
            clientId: viewedData.clientID,
            clientName: this.state.userProfileName,
          };
          globalFunctions.AddLocalStorageData(
            "selectedClient",
            JSON.stringify(clientData)
          );
        }

        path = isClient ? "/ManageClientUsers" : "/ManageUsers";
      } else if (viewedData?.navigateTo?.includes("Training")) {
        path = isClient ? "/TrainingDocument" : "/Training";
      }
      if (path != "") {
        this.navigateTo(path);
      }
    } else if (notificationText.includes("Roles And Permissions")) {
      if (viewedData?.navigateTo?.includes("Client Roles & Permissions")) {
        this.props.navigate("/ManageClientRolesAndPermission");
      } else if (
        viewedData?.navigateTo?.includes("Manage Users Roles & Permissions")
      ) {
        this.props.navigate("/ManageRolesAndPermission");
      }
    } else if (
      notificationText.includes("EDIT_USER_PROFILE") && this.state.isAdminInternalLoggedIn
    ) {
      var userId = viewedData.adminID == 0 ? viewedData.internalUserID : viewedData.adminID
      globalFunctions.AddLocalStorageData(
        "selectedUser", userId
      );
      this.navigateTo("/AddUser");
    }
  };

  navigateTo(path) {
    this.props.navigate(path);
  }

  /* <summary>
 date: 23/03/2023
 Name: HV
 description: saving data for documnet upload
 <summary> */

  SaveClientUserChangeRequestData = async () => {
    const { userId, clientId, description, dropdown_moduleName, userEmailId } =
      this.state;

    this.setState({ isLoading: true, showProceedModal: false });
    let clientUserChangeRequestData = new FormData();

    clientUserChangeRequestData.append("EditedBy", userId);
    clientUserChangeRequestData.append("ClientId", clientId);
    clientUserChangeRequestData.append("ClientUserId", userId);

    var modulesData = moduleList.filter(
      (x) => x.id == parseInt(dropdown_moduleName, 10)
    )[0];

    if (modulesData != null) {
      clientUserChangeRequestData.append("ModuleName", "Client User");
      clientUserChangeRequestData.append("NavigateTo", modulesData.navigateTo);
      clientUserChangeRequestData.append(
        "RequestType",
        modulesData.id == 9 ? modulesData.moduleName : description
      );
      clientUserChangeRequestData.append(
        "Description",
        modulesData.id == 9 ? description : ""
      );
      clientUserChangeRequestData.append("NewUserEmailId", userEmailId);
    }

    var responseSaved = await ClientService.SaveClientUserChangeRequestData(
      clientUserChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.onCloseChangeRequestModalClick();
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  openResetPasswordModal = () => {
    this.setState({
      showResetPasswordModal: true,
    });
  };

  onCloseClick = async (value) => {
    if (value) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.ResetPasswordPageText.resetPasswordSuccess,
        toastType: helperText.global.toastSuccess,
        isLoading: false,
      });
    }

    this.setState({
      showResetPasswordModal: false,
    });
  };

  render() {
    const {
      userId,
      userProfilePicUrl,
      userProfileName,
      showNotification,
      notificationData,
      notificationCount,
      actionRequiredCount,
      isClient,
      showClientRequestModal,
      showProceedModal,
      description,
      errorMessages,
      showErrorMessage,
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      isShipCrew,
      isClientUser,
      userEmailId,
      dropdown_moduleName,
      showLogoutModal,
      showResetPasswordModal,
      isDashboardActive,
      isActionRequiredActive,
      accessList,
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <section className="main-header">
          <div className="blue-header">
            <div className="container">
              <div className="row">
                <div className="col-4 col-lg-4">
                  <div className="social-media-icons">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/cti-maritec/"
                      data-placement="top"
                      data-toggle="tooltip"
                      className="tooltips cursor-class"
                      data-original-naam="LinkedIn"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/maritec_sg/"
                      data-placement="top"
                      data-toggle="tooltip"
                      className="tooltips cursor-class"
                      data-original-naam="Instagram"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Maritec.SG"
                      data-placement="top"
                      data-toggle="tooltip"
                      className="tooltips cursor-class"
                      data-original-naam="Facebook"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://twitter.com/Maritec_SG"
                      data-placement="top"
                      data-toggle="tooltip"
                      className="tooltips cursor-class"
                      data-original-naam="Twitter"
                    >
                      <i className="fa-brands fa-x-twitter" aria-hidden="true"></i>

                    </a>
                  </div>
                </div>
                <div className="col-8 col-lg-8">
                  <div className="header-profile">
                    <nav className="navbar navbar-expand-lg">
                      <div
                        className="notification"
                        style={{ display: userId != 0 ? "" : "none" }}
                      >
                        <a
                          className="nav-link dropdown-toggle header-notification-box"
                          role="button"
                          aria-expanded="false"
                          onClick={this.onNotificationClick}
                        >
                          <div className="notify-icon">
                            <img
                              src="images/notification-icon.png"
                              className="notificationIconHeader"
                              alt="notification"
                            />
                            {notificationCount > 0 ? (
                              <span className="notification-count">
                                {notificationCount}
                              </span>
                            ) : null}
                          </div>
                        </a>
                        {showNotification ? (
                          <div
                            className="notification-box dropdown-menu"
                            style={{
                              display: showNotification ? "block" : "none",
                            }}
                          >
                            <div className="notify-header">
                              <h4>
                                Notifications
                                <span>
                                  <img
                                    src="images/modal-close.png"
                                    onClick={this.onNotificationClick}
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </h4>
                            </div>
                            <div className="notify-body">
                              {notificationData.map((item, i) => {
                                return (
                                  <div className="notify-per-date" key={i}>
                                    <p>{item.notificationDateDisplay}</p>
                                    <ul>
                                      {item.notificationText.map(
                                        (itemText, i) => {
                                          return (
                                            <li key={i}>
                                              {/* {this.state.accessList != null && */}
                                              {globalFunctions.CheckIfUserHasAccess(
                                                this.state.userTypeId,
                                                accessList,
                                                itemText.archiveActionName,
                                                itemText.navigateTo
                                              ) == "" ? (
                                                <span
                                                  style={{ cursor: "pointer" }}
                                                  onClick={
                                                    this.onViewDisabledNotificationClicked
                                                  }
                                                >
                                                  {itemText.text}
                                                </span>
                                              ) : (
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    this.onViewNotifiationNavigateClick(
                                                      "Notification",
                                                      itemText.archiveActionName,
                                                      itemText,
                                                      itemText.notificationID
                                                    )
                                                  }
                                                >
                                                  {itemText.text}
                                                </a>
                                              )}
                                              {/* } */}
                                              {itemText.isViewed == 1 ? (
                                                <img
                                                  className="notificationicon"
                                                  src="./images/grey-bulb.png"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  className="notificationicon"
                                                  src="./images/orange-bulb.png"
                                                  alt=""
                                                />
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="notify-footer">
                              {notificationData.length > 0 ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.onNavigateClick("ActivityLog")
                                  }
                                >
                                  View all
                                </a>
                              ) : (
                                <p style={{ textAlign: "center" }}>
                                  No data to display
                                </p>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="" id="navbarNavDarkDropdown">
                        <ul
                          className="navbar-nav"
                          style={{ display: userId != 0 ? "" : "none" }}
                        >
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle header-profile-box"
                              id="headerProfileDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  userProfilePicUrl != "null" &&
                                    userProfilePicUrl != "" &&
                                    userProfilePicUrl != null &&
                                    userProfilePicUrl != "undefined"
                                    ? userProfilePicUrl
                                    : globalFunctions.GetLocalStorageDataWithoutParse(
                                      "loggedInUserPic"
                                    ) != "" &&
                                      globalFunctions.GetLocalStorageDataWithoutParse(
                                        "loggedInUserPic"
                                      ) != "null" &&
                                      globalFunctions.GetLocalStorageDataWithoutParse(
                                        "loggedInUserPic"
                                      ) != null &&
                                      globalFunctions.GetLocalStorageDataWithoutParse(
                                        "loggedInUserPic"
                                      ) != "undefined"
                                      ? globalFunctions.GetLocalStorageDataWithoutParse(
                                        "loggedInUserPic"
                                      )
                                      : blankPic
                                }
                                className="header-profile-img"
                                alt=""
                                style={{
                                  borderRadius: "40px",
                                  objectFit: "contain",
                                  backgroundColor: "#fff",
                                }}
                              />
                              {userProfileName != undefined
                                ? userProfileName
                                : "Admin"}
                            </a>
                            <ul
                              className="dropdown-menu profile-dropdown"
                              aria-labelledby="headerProfileDropdown"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    this.onNavigateClick("Profile")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>
                                    <i
                                      className="fa fa-address-card"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  Profile
                                </a>
                              </li>
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.ResetPassword
                              ) && (
                                  <>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={this.openResetPasswordModal}
                                      >
                                        <span>
                                          <img
                                            src="images/reset-password.png"
                                            alt="reset-password"
                                          />
                                        </span>
                                        Reset Password
                                      </a>
                                    </li>
                                  </>
                                )}
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.Settings
                              ) && (
                                  <>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          this.onNavigateClick("/Settings")
                                        }
                                      >
                                        <span>
                                          <i
                                            className="fa fa-cog"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        Settings
                                      </a>
                                    </li>
                                  </>
                                )}
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.ChangeRequest
                              ) && (
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={this.openChangeRequestModal}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span>
                                        <img
                                          src="images/changeRequest.png"
                                          alt="changeRequest"
                                        />
                                      </span>
                                      {isClient ? "Help & Support" : "Change Request"}
                                    </a>
                                  </li>
                                )}
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.onNavigateClick("Logout")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>
                                    <i
                                      className="fa fa-sign-out"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  Logout
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul
                          className="navbar-nav"
                          style={{ display: userId != 0 ? "none" : "" }}
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              style={{ color: "white", cursor: "pointer" }}
                              onClick={() => this.onNavigateClick("Logout")}
                            >
                              Login
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-header">
              <div className="container menu-box">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <img
                      src="images/logo.png"
                      className="header-logo"
                      alt="Logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.onNavigateClick("Logo")}
                    />
                  </div>
                  <div className="col-12 col-lg-9">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <button
                        className="navbar-toggler nav-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav menu-ul">
                          {globalFunctions.HasSiteRights(
                            SITERIGHTS.DashboardWithSubMenus
                          ) ? (
                            <li
                              className="nav-item dropdown"
                              style={{ display: userId != 0 ? "" : "none" }}
                            >
                              <a
                                className={
                                  isDashboardActive
                                    ? "nav-link dropdown-toggle active"
                                    : "nav-link dropdown-toggle"
                                }
                                onClick={() =>
                                  this.onNavigateClick("Dashboard")
                                }
                                role="button"
                                aria-hidden="true"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Dashboard{" "}
                              </a>
                              <span className="fa fa-caret-down drop-icon"></span>
                              {isShipCrew ? (
                                <ul className="dropdown-menu ul-level-3 ">
                                  <li className="nav-item dropdown">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="nav-link"
                                      onClick={() =>
                                        accessList != null &&
                                          accessList
                                            .trackingToolAccess == UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick("TrackingTool")
                                      }
                                    >
                                      Tracking Tool
                                    </a>
                                  </li>
                                </ul>
                              ) : isClient || isClientUser ? (
                                <ul className="dropdown-menu ul-level-2">
                                  <li className="nav-item dropdown menu-level-3">
                                    <a
                                      className="dropdown-item dropdown-toggle"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList
                                            .shipParticularsAccess ==
                                          UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick("ManageShips")
                                      }
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Ships
                                    </a>
                                    <span
                                      className="fa fa-caret-down drop-icon"
                                      aria-hidden="true"
                                    ></span>
                                    <ul className="dropdown-menu ul-level-3">
                                      <li className="nav-item dropdown">
                                        <a
                                          className="nav-link"
                                          onClick={(e) =>
                                            accessList != null &&
                                              accessList
                                                .trackingToolAccess ==
                                              UserAction.DENY
                                              ? e.preventDefault()
                                              : this.onNavigateClick(
                                                "/TrackingTool"
                                              )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          Tracking Tool
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="dropdown-menu ul-level-2">
                                  <li className="nav-item dropdown menu-level-2">
                                    <a
                                      className="dropdown-item dropdown-toggle dropdown-level-2"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList
                                            .clientAndShipAccess ==
                                          UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick("Client")
                                      }
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Clients
                                    </a>
                                    <span
                                      className="fa fa-caret-down drop-icon"
                                      aria-hidden="true"
                                    ></span>
                                    <ul className="dropdown-menu ul-level-3">
                                      <li className="nav-item dropdown menu-level-3">
                                        <a
                                          className="dropdown-item dropdown-toggle"
                                          onClick={(e) =>
                                            accessList != null &&
                                              accessList
                                                .clientAndShipAccess ==
                                              UserAction.DENY
                                              ? e.preventDefault()
                                              : this.onNavigateClick("Ships")
                                          }
                                          role="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Ships
                                        </a>
                                        <span
                                          className="fa fa-caret-down drop-icon"
                                          aria-hidden="true"
                                        ></span>
                                        <ul className="dropdown-menu ul-level-3">
                                          <li className="nav-item dropdown">
                                            <a
                                              className="nav-link"
                                              onClick={(e) =>
                                                accessList != null &&
                                                  accessList
                                                    .trackingToolAccess ==
                                                  UserAction.DENY
                                                  ? e.preventDefault()
                                                  : this.onTrackingToolClick()
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              Tracking Tool
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList
                                            .manageUserAccess == UserAction.DENY
                                          ? e.preventDefault()
                                          : this.navigateTo("/ManageUsers")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Users
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      className="nav-link "
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList.supplierAccess ==
                                          UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick(
                                            "ManageSupplier"
                                          )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Suppliers
                                    </a>
                                  </li>
                                </ul>
                              )}
                            </li>
                          ) : (
                            <li
                              className="nav-item dropdown"
                              style={{ display: userId != 0 ? "" : "none" }}
                            >
                              <a
                                className={
                                  isDashboardActive
                                    ? "nav-link dropdown-toggle active"
                                    : "nav-link dropdown-toggle"
                                }
                                onClick={this.onDashboardClick}
                                role="button"
                                aria-hidden="true"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Dashboard{" "}
                              </a>
                            </li>
                          )}
                          {globalFunctions.HasSiteRights(
                            SITERIGHTS.ActionRequired
                          ) && (
                              <li
                                className="nav-item"
                                style={{ display: userId != 0 ? "" : "none" }}
                              >
                                <a
                                  className={
                                    isActionRequiredActive
                                      ? "nav-link dropdown-toggle active"
                                      : "nav-link dropdown-toggle"
                                  }
                                  onClick={() =>
                                    this.onNavigateClick("ActionRequired")
                                  }
                                  role="button"
                                  aria-hidden="true"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Action Required{" "}
                                  {actionRequiredCount == 0 ? (
                                    <span></span>
                                  ) : (
                                    <span className="action-notify">
                                      {actionRequiredCount}
                                    </span>
                                  )}
                                </a>
                              </li>
                            )}
                          <li
                            className="nav-item dropdown"
                            style={{ display: userId != 0 ? "" : "none" }}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Quick Links
                            </a>
                            <span
                              className="fa fa-caret-down drop-icon"
                              aria-hidden="true"
                            ></span>
                            <ul className="dropdown-menu ul-level-2">
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.CommunicationOtherLink
                              ) && (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link "
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList
                                            .communicationAccess == UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick("/Communication")
                                      }
                                    >
                                      Communications
                                    </a>
                                  </li>
                                )}
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.ReportsOtherLink
                              ) && (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link "
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          (this.state.isAdminInternalLoggedIn
                                            ? accessList.reportAccess
                                            : accessList
                                              .reportsAccess) == UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick("/Reports")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Reports
                                    </a>
                                  </li>
                                )}
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.IHMDatabaseRegistryOtherLink
                              ) && (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link "
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList.ihmdatabaseAccess ==
                                          UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick(
                                            "/ManageIHMDatabaseRegistry"
                                          )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      IHM Database & Registry
                                    </a>
                                  </li>
                                )}

                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  aria-current="page"
                                  onClick={(e) =>
                                    accessList != null &&
                                      accessList.trainingAccess ==
                                      UserAction.DENY
                                      ? e.preventDefault()
                                      : this.onNavigateClick("Training")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  Training
                                </a>
                              </li>

                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.MiscellaneousOperationsOtherLink
                              ) && (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList
                                            .financeAndAccountAccess ==
                                          UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick(
                                            "/MiscellaneousOperations"
                                          )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Miscellaneous Operations
                                    </a>
                                  </li>
                                )}
                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.DocumentsOtherLink
                              ) && (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          accessList.documentsAccess ==
                                          UserAction.DENY
                                          ? e.preventDefault()
                                          : this.onNavigateClick("Documents")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Documents
                                    </a>
                                  </li>
                                )}

                              {globalFunctions.HasSiteRights(
                                SITERIGHTS.StatisticsOtherLink
                              ) && (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      aria-current="page"
                                      onClick={(e) =>
                                        accessList != null &&
                                          (accessList.statisticsAccess ==
                                          UserAction.DENY || accessList.statisticAccess ==
                                          UserAction.DENY)
                                          ? e.preventDefault()
                                          : this.onNavigateClick("Statistics")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Statistics
                                    </a>
                                  </li>
                                )}
                            </ul>
                          </li>

                          <li
                            className="nav-item dropdown"
                            style={{ display: userId != 0 ? "" : "none" }}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Other Links
                            </a>
                            <span
                              className="fa fa-caret-down drop-icon"
                              aria-hidden="true"
                            ></span>
                            <ul className="dropdown-menu ul-level-2">
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  aria-current="page"
                                  target={"_blank"}
                                  onClick={() =>
                                    this.onNavigateClick(config.maritecSiteUrl)
                                  }
                                  //  href={config.maritecSiteUrl}
                                  style={{ cursor: "pointer" }}
                                >
                                  About Us
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  aria-current="page"
                                  // href="/ContactUs"
                                  onClick={() =>
                                    this.onNavigateClick("/ContactUs")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  Contact Us
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            className="nav-item"
                            style={{ display: userId != 0 ? "none" : "" }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              target={"_blank"}
                              onClick={() =>
                                this.onNavigateClick(config.maritecSiteUrl)
                              }
                              // href={config.maritecSiteUrl}
                              style={{ cursor: "pointer" }}
                            >
                              About Us
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            style={{ display: userId != 0 ? "none" : "" }}
                          >
                            <a
                              className="nav-link active"
                              data-toggle="toggle"
                              aria-current="page"
                              // href="/ContactUs"
                              onClick={() => this.onNavigateClick("/ContactUs")}
                              style={{ cursor: "pointer" }}
                            >
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          {toastVisible && (
            <Toast
              toastVisible={toastVisible}
              type={toastType}
              message={toastMessage}
              closeToast={this.closeToast}
            />
          )}
          {showClientRequestModal && (
            <ClientChangeRequestModal
              onCloseClick={this.onCloseChangeRequestModalClick}
              modalTitle={isClient ? "Help & Support" : "Change Request"}
              onChangeRequestClick={this.onShowProceedModalClick}
              onChange={this.onChange}
              description={description}
              errorMessages={errorMessages}
              showErrorMessage={showErrorMessage}
              moduleList={moduleList}
              userEmailId={userEmailId}
              dropdown_moduleName={dropdown_moduleName}
            />
          )}
          {this.state.showDirtyPopup && (
            <DirtyModal
              onModalCloseClick={this.onDirtyModalCloseClick}
              onProceedClick={this.onProceedClick}
            />
          )}
          {showProceedModal && (
            <ProceedModal
              showProceedModal={showProceedModal}
              onNoClick={this.onProceeModalCloseClick}
              onYesClick={
                isClientUser
                  ? this.SaveClientUserChangeRequestData
                  : this.SaveClientChangeRequestData
              }
              modalFor={isClient ? "Help & Support" : "Change Request"}
            />
          )}
          {showLogoutModal && (
            <LogoutModal
              userId={this.state.userId}
              userTypeId={this.state.userTypeId}
              setIsLoading={(val) => this.setState({ isLoading: val })}
              closeLogoutModal={() => this.setState({ showLogoutModal: false })}
              navigate={this.props.navigate}
            />
          )}

          {showResetPasswordModal && (
            <ResetPassword onCloseClick={this.onCloseClick} />
          )}
        </div>
      </>
    );
  }
}

export default withRouter(Header);
