import webserviceWrapper from "../helpers/webserviceWrapper";

export const MiscellaneousOperationsService = {
    GetMiscellaneousData,
    DeleteMiscellaneousDocData,
    AddMiscellaneousDocsData,
    DownloadMiscellaneousDoc
}

/* <summary>
date: 30/12/2022
Name: SYJ
description: service api call to get training data
<summary> */
function GetMiscellaneousData(userID) {
    return webserviceWrapper.get(`/MiscellaneousOperation/GetMiscellaneousDocsData?userID=${userID}`);
}

/* <summary>
date: 30/01/2023
Name: SYJ
description: service api call to delete training data
<summary> */
function DeleteMiscellaneousDocData(miscellaneousdocID) {
    return webserviceWrapper.get(`/MiscellaneousOperation/DeleteMiscellaneousDocData?miscellaneousOperationID=${miscellaneousdocID}`);
}

/* <summary>
date: 30/01/2023
Name: SYJ
description: service api call to save file
<summary> */
function AddMiscellaneousDocsData(miscellaneousData) {
    return webserviceWrapper.postWithHeader(
        `/MiscellaneousOperation/AddMiscellaneousDocDetails`,
        miscellaneousData, { "Content-Type": "multipart/form-data", },
        600000
    );
}

/* <summary>
date: 30/01/2023
Name: SYJ
description: service api call to download training file
<summary> */

function DownloadMiscellaneousDoc(tFileIDlist) {
    return webserviceWrapper.post(
        `/MiscellaneousOperation/DownloadMiscellaneousOperationFiles`,
        tFileIDlist,
        600000
    );
}