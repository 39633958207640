import React, { Component } from 'react'

export default class AddAsHazmat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            POItemsImportedCount: this.props.POItemsImportedCount,
            suspectedHazmatItemsCount: this.props.suspectedHazmatItemsCount,
            isAPIImport: this.props.isAPIImport,
            fromAPI: this.props.isAPIImport ? "API" : "Excel"
        }
    }

    // onTextChange = (e) => {
    //     const { name, value } = e.target;
    //     this.setState({
    //         [name]: value,
    //     });
    // };

    //HV - 14/03/2023 - mapping data based on ships for imported po
    
    render() {
        const { showModal, onCloseClick, onConfirmClick, filteredShipList,  } = this.props;
        var { POItemsImportedCount, suspectedHazmatItemsCount, fromAPI, isAPIImport  } = this.state;

        return (
            <div className="add_as_hazmat" style={{ display: showModal ? "block" : "none" }}>
                <div className="modal-backdrop fade show" style={{ display: showModal ? "block" : "none" }}></div>
                <div className="modal" id="addHazmat-modal" style={{ display: showModal ? "block" : "none" }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">Add as Suspected Hazmat</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: "all" }} onClick={() => onCloseClick("showAddAsHazmatModal", false)} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="hazmatDetails">
                                    <p>The PO list imported from {fromAPI} is inspected and there are {POItemsImportedCount} PO items containing
                                        Hazmat Materials.</p>
                                </div>
                                {filteredShipList.map((x, index) => {
                                    return (<div className="hazmat-data" key={index}>
                                        <div className="row">
                                            <div className="col-6 col-lg-6 ">
                                                <div className="Items">
                                                    <label className="labelone" htmlFor="no.of po items">
                                                        Number of PO Items for {x.shipName}:
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-6">
                                                <input type="number" className="form-control "name="POItemsImportedCount" id="no.of po items" disabled value={x.poItemsCount} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-lg-6">
                                                <div className="Items">
                                                    <label className="labelone" htmlFor="no.of suspected items">
                                                        Total Suspected Hazmat Items for {x.shipName}:
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-6">
                                                <input type="number" className="form-control " name="suspectedHazmatItemsCount" id="no.of suspected items" disabled value={x.suspectedHazmatItemsCount} />
                                            </div>
                                        </div>
                                    </div>)
                                })}

                                <div className="reverifyText">
                                    <p>Please "Confirm" to proceed and "Cancel" to reverify the PO items.</p>
                                    {isAPIImport && (<p>Note: The data imported from the API will be deleted on clicking "Confirm".</p>)}
                                </div>
                                
                                <div className="text-center">
                                    <button type="button" className="btn btn-background-color confirm-btn" onClick={onConfirmClick}>Confirm</button>
                                    <button type="button" className="btn btn-background-color cancel-btn" onClick={() => onCloseClick("showAddAsHazmatModal", false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
