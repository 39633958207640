import React, { Component } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TagRenderer from "../../components/TagRenderer";
import { GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter } from "../../helpers";
import { TitlePageNames } from "../../helpers/enums";
import AGGrid from "../../components/AGGrid";
import { TrackingToolService } from "../../services/trackingTool.service";
import AddHazmatItem from "./AddHazmatItem"
import Searchbar from "../../components/Searchbar";
import Toast from "../../components/Toast";

class AddSuspectedHazmat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      colDefs: this.gridColumns,
      rowData: [],
      defaultRowData: [],
      frameworkComponents: {
        tagRenderer: TagRenderer,
      },
      showUploadModal: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      hazmatvalue: "",
      searchedText: "",
    }
    this.onGridReady = this.onGridReady.bind(this);
  }

  onSortChanged(e) {
    e.api.refreshCells();
  }

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
  };

  gridColumns = [
    {
      headerName: "Serial Number",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
      //suppressSizeToFit: true
    },
    {
      headerName: "Name",
      width: 1000,
      field: "hazmatComponent",
      tooltipField: "hazmatComponent",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    }
  ];

  componentDidMount() {
    changePageTitle(TitlePageNames.IMPORTPOAddHazmat);
    this.getAllHazmatItemsDataFromDB();
  }


  getAllHazmatItemsDataFromDB = async () => {
    this.setState({
      isLoading: true,
    });

    var response = await TrackingToolService.GetAllHazmatFromDB();
    if (response != null && response != undefined && response.status == 200) {
      this.setState(
        {
          rowData: response.data,
          defaultRowData: response.data,
          isLoading: false,
        });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  toggleModal = (name, value) => {
    this.setState({ [name]: value });
  };


  onSearch = (e) => {
    var inputValue = e.target.value;
    this.setState({
      searchedText: inputValue,
    }, () => {
      this.filterGridView();
    });
  };

  filterGridView = () => {
    const {
      searchedText,
      defaultRowData
    } = this.state;

    var filterList = defaultRowData;
    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.hazmatComponent?.toString().toLowerCase().includes(value));
    }

    this.setState({
      rowData: filterList,
    });
  }


  saveHazmatValue = async (data) => {
    this.setState({
      showUploadModal: false,
      isLoading: true,
    });
    var userData = GetLocalStorageDataWithParse("loggedInUser");

    let hazmatData = {
      HazmatName: data.hazmatvalue.trim(),
      UserID: userData.id
    }
    var responseSaved = await TrackingToolService.AddHazmatDataToDB(hazmatData);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        showUploadModal: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.hazmatAddSuccess,
        isLoading: false,
      }, () =>
        this.getAllHazmatItemsDataFromDB(),
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  goBack = () => {
    this.props.navigate("/ImportPO");
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  render() {
    const { isLoading, colDefs, frameworkComponents, rowData, showUploadModal, toastVisible, toastType, toastMessage } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        <main className="page-height">
          <section className="communications po-items-list">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button cursor-class"
                    onClick={this.goBack}
                    src="images/back button image.png"
                    alt="Back"
                  />

                  <span className="title-font">Suspected Hazmat List</span>

                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <Searchbar
                    placeholder={"Name..."}
                    onChange={this.onSearch}
                  />
                </div>
                <div className="col-12 col-md-12">
                  <div className="float-btn manage-client-button" style={{ marginTop: "10px" }}>
                    <button
                      type="button"
                      style={{
                        cursor: "pointer",
                      }}
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() => this.toggleModal("showUploadModal", true)}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      rowData={rowData}
                      colDefs={colDefs}
                      rowSelection="multiple"
                      pagination="30"
                      frameworkComponents={frameworkComponents}
                      onGridReady={this.onGridReady}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {showUploadModal && (
          <AddHazmatItem
            onCloseClick={this.toggleModal}
            onUploadClick={this.saveHazmatValue}
          />
        )}
        <Footer />
      </>
    );
  }
}

export default withRouter(AddSuspectedHazmat);
