import React, { Component } from "react";
import { ActionService } from "../../services/actionRequired.service";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import { helperText } from "../../helpers/helperText";
import { AddLocalStorageData, CheckIfUserHasActionRequiredModuleAccess, GetLocalStorageDataWithParse, changePageTitle, withRouter } from "../../helpers";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Toast from "../../components/Toast";
import Dropdown from '../../components/Dropdown';
import MobileDropdown from '../../components/Mobile/MobileDropdown';
import DatePicker from "../../components/DatePickerComponent";
import DataTable from 'react-data-table-component';
import { UserAction } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";

let loadRows = 3;
var currentDate = new Date();

class ActionRequired extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            toastVisible: false,
            toastType: '',
            toastMessage: '',
            userId: 0,
            defaultactionRequiredData: [],
            userNameList: [],
            dropdown_category: "",
            filter_fromDate: "",
            filter_toDate: "",
            actionRequiredData: [],
            totalrows: 0,
            supplierList: [],
            prevpageval: 0,
            currentPage: 1,
            rowsPerPage: 20,
            filteredActionRequiredList: [],
            isFilterSelected: false,
            resetPaginationToggle: false,
            nextFromDate: "",
            previousToDate: currentDate,
            accessList: [],
            usertypeID: 0,
            dropdown_readunread: "",
            readUnreadOptions: [
                { id: 1, value: 'Read' },
                { id: 2, value: 'Unread' },
            ],
        };
    }

    componentDidMount() {

        changePageTitle("");
        var userData = GetLocalStorageDataWithParse("loggedInUser");
        if (userData != null) {
            this.setState({ userId: userData.id, accessList: GetLocalStorageDataWithParse("userRolePermission"), usertypeID: userData.typeId }, () => {
                this.GetAllActionRequiredData()
            });
        }
    }

    GetAllActionRequiredData = async () => {
        this.setState({ isLoading: true });
        if (this.state.userId > 0) {
            var response = await ActionService.GetAllActionRequiredData(this.state.userId, this.state.prevpageval, this.state.usertypeID);

            if (response.status == 200) {
                const offset = (this.state.currentPage - 1) * this.state.rowsPerPage;
                //const paginatedData = filteredData.slice(offset, offset + this.state.rowsPerPage);     
                this.setState({
                    actionRequiredData: response.data.listActionRequired.slice(offset, offset + this.state.rowsPerPage),
                    isLoading: false,
                    defaultactionRequiredData: response.data.listActionRequired,
                    userNameList: response.data.listUserRole,
                    totalrows: response.data.total,
                    supplierList: response.data.supplierModel
                }, () => {
                });
            }
            else {
                this.setState({
                    isLoading: false,
                    toastVisible: true,
                    toastType: helperText.global.toastError,
                    toastMessage: helperText.ActionRequired.fetchActionRequiredError
                })
            }
        }
        this.setState({ isLoading: false, isFilterSelected: false })
    }

    handlePageChange = async page => {

        this.setState({ isLoading: true });
        if (this.state.userId > 0) {
            this.setState({ prevpageval: ((page - 1) * this.state.rowsPerPage), currentPage: page });

            const offset = (page - 1) * this.state.rowsPerPage;
            this.setState({
                actionRequiredData: this.state.isFilterSelected == false ? this.state.defaultactionRequiredData.slice(offset, offset + this.state.rowsPerPage) : this.state.filteredActionRequiredList.slice(offset, offset + this.state.rowsPerPage),
            }, async () => {
            })
        }
        this.setState({ isLoading: false })
    }

    navigateTo(path) {
        this.props.navigate(path);
    }

    onViewNotificationClick = async (navigateTo, module, clientId, shipId, supplierId, actionRequiredId, itemText) => {
        this.setState({ isLoading: true });

        AddLocalStorageData('location', '/ActionRequired');

        var data = {
            'actionRequiredId': actionRequiredId,
            'userId': this.state.userId
        }

        var response = await ActionService.SaveIsViewedByAdminStatus(data);
        if (response.status == 200) {
            this.setState({
                isLoading: false,
            })
        }
        else {
            this.setState({
                toastVisible: true,
                toastMessage: helperText.global.somethingWentWrong,
                toastType: helperText.global.toastError,
                isLoading: false,
            })
        }


        if (module.includes("Client")) {

            AddLocalStorageData("selectedClient", clientId);
            navigateTo = "/AddEditClient";
        }
        else if (module.includes("Supplier")) {
            navigateTo = navigateTo.includes("TrackingTool") ? "/TrackingTool" : "/AddEditSupplier";

            var supplierData = this.state.supplierList.filter((s) => s.supplierId === supplierId);
            AddLocalStorageData("selectedSupplier", JSON.stringify(supplierData[0]));
        }
        else if (module.includes("Ship")) {
            navigateTo = "/AddEditShip";
            AddLocalStorageData("selectedClient", JSON.stringify(itemText));
            AddLocalStorageData("selectedShip", shipId);
        }
        else if (module.includes("Internal User")) {
            navigateTo = navigateTo.includes("TrackingTool") ? "/TrackingTool" : "/AddUser";
            AddLocalStorageData("selectedUser", itemText.internalUserId);
        }
        else if (module.includes("Client User:")) {
            if (module.includes("Document")) {
                AddLocalStorageData("selectedClient", itemText.clientId);
                this.navigateTo("/AddEditClient")
            }
            else {
                AddLocalStorageData("selectedClientUserId", itemText.clientUserID);
                AddLocalStorageData("selectedClientID", itemText.clientId);
                this.props.navigate("/AddEditClientUser");
            }

        }
        else if (module.includes("Document")) {
            if (navigateTo.includes("Ship")) {
                AddLocalStorageData("selectedClient", JSON.stringify(itemText));
                AddLocalStorageData("selectedShip", shipId);
                navigateTo = "/AddEditShip";
            }
            else if (navigateTo.includes("Document")) {
                AddLocalStorageData("selectedClient", itemText.clientId);
                navigateTo = "/AddEditClient";
            }
        }
        else if (module.includes("Manage Users")) {
            if (navigateTo.includes("Manage Users")) {
                let name = itemText.text.split("Client: ")
                name = name[1]?.split(" sent")

                let clientData = {
                    clientId: itemText.clientId,
                    clientName: name[0],
                };
                AddLocalStorageData("selectedClient", JSON.stringify(clientData));

                navigateTo = "/ManageClientUsers";
            }
        }
        else if (module.includes("Tracking Tool")) {
            let clientData = {
                clientId: itemText.clientId,
                shipId: 0
            };
            AddLocalStorageData("selectedShipTTool", JSON.stringify(clientData));
            navigateTo = "/TrackingTool";
        }
        else if (module.includes("Training")) {
            navigateTo = "/Training";
        }
        else if (module.includes("Reports")) {
            navigateTo = "/Reports";
        }

        this.navigateTo(navigateTo);
    }

    closeToast = () => {
        this.setState({ toastVisible: false });
    };

    onDropdownSelect = (e) => {

        var { name, value } = e.target;
        this.setState(
            {
                [name]: value,
                currentPage: 1
            },
            () => {
                this.filterGridView()
            }
        );
    };

    filterGridView = () => {
        const {
            dropdown_category,
            filter_fromDate,
            filter_toDate,
            filteredActionRequiredList,
            dropdown_readunread
        } = this.state;

        const isread = dropdown_readunread === "1" ? true : null;

        var filterList = [];

        var actionRequiredList = this.state.defaultactionRequiredData;

        if ((dropdown_category == "" || dropdown_category == "0") && (dropdown_readunread == "" || dropdown_readunread == "0") && filter_fromDate == "" && filter_toDate == "")
            filterList = actionRequiredList;
        else {

            if ((dropdown_category != "" && dropdown_category != "0") && dropdown_readunread != "" &&
                dropdown_readunread != "0" && (filter_fromDate != "" || filter_toDate != "")) {
                var roleName = this.state.userNameList.length > 0 ? this.state.userNameList.filter(p => p.userRoleId == dropdown_category)[0].name : "";

                let fromDate = convertDate(filter_fromDate);
                let toDate = convertDate(filter_toDate);

                filterList = filterList.length > 0 ? filterList.map((e) => ({
                    actionDateDisplay: e.actionDateDisplay, actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                        let date = convertDate(new Date(e2.createdOn));
                        return e2.module.trim().toLowerCase() === roleName.trim().toLowerCase() && new Date(date).getTime() >= new Date(fromDate).getTime() && new Date(date).getTime() <= new Date(toDate).getTime();
                    })
                }))

                    : actionRequiredList.map((e) => ({
                        actionDateDisplay: e.actionDateDisplay, actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                            let date = convertDate(new Date(e2.createdOn));
                            return e2.module.trim().toLowerCase() === roleName.trim().toLowerCase() && new Date(date).getTime() >= new Date(fromDate).getTime() && new Date(date).getTime() <= new Date(toDate).getTime();
                        })
                    }))

                filterList = filterList.filter((e) => e.actionRequiredDataList.length != 0)

            } else {
                if (dropdown_category != "" && dropdown_category != "0") {

                    var roleName = this.state.userNameList.length > 0 ? this.state.userNameList.filter(p => p.userRoleId == dropdown_category)[0].name : "";

                    filterList = filterList.length > 0 ? filterList.map((e) => ({
                        actionDateDisplay: e.actionDateDisplay, actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                            return e2.module.trim().toLowerCase() === roleName.trim().toLowerCase()
                        })
                    }))

                        : actionRequiredList.map((e) => ({
                            actionDateDisplay: e.actionDateDisplay, actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                                return e2.module.trim().toLowerCase() === roleName.trim().toLowerCase()
                            })
                        }))

                    filterList = filterList.filter((e) => e.actionRequiredDataList.length != 0)
                }

                if (dropdown_readunread != "" && dropdown_readunread != "0") {
                    filterList =
                        filterList.length > 0
                            ? filterList.map((e) => ({
                                actionDateDisplay: e.actionDateDisplay,
                                actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                                    return (
                                        e2.isViewedAdmin == isread
                                    );
                                }),
                            }))
                            : actionRequiredList.map((e) => ({
                                actionDateDisplay: e.actionDateDisplay,
                                actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                                    return (
                                        e2.isViewedAdmin === isread
                                    );
                                }),
                            }));

                    filterList = filterList.filter((e) => e.actionRequiredDataList.length != 0);
                }

                if (filter_fromDate != "" && filter_toDate != "") {

                    let fromDate = convertDate(filter_fromDate);
                    let toDate = convertDate(filter_toDate);

                    filterList = filterList.length > 0 ? filterList.map((e) => ({
                        actionDateDisplay: e.actionDateDisplay, actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                            let date = convertDate(new Date(e2.createdOn));
                            return new Date(date).getTime() >= new Date(fromDate).getTime() && new Date(date).getTime() <= new Date(toDate).getTime();
                        })
                    }))

                        : actionRequiredList.map((e) => ({
                            actionDateDisplay: e.actionDateDisplay, actionRequiredDataList: e.actionRequiredDataList.filter((e2) => {
                                let date = convertDate(new Date(e2.createdOn));
                                return new Date(date).getTime() >= new Date(fromDate).getTime() && new Date(date).getTime() <= new Date(toDate).getTime();
                            })
                        }))
                    filterList = filterList.filter((e) => e.actionRequiredDataList.length != 0)
                }
            }
        }

        const offset = (this.state.currentPage - 1) * this.state.rowsPerPage;
        this.setState(prevState => ({
            actionRequiredData: filterList.slice(offset, offset + this.state.rowsPerPage),
            filteredActionRequiredList: filterList,
            totalrows: filterList.length,
            isLoading: false,
            isFilterSelected: true,
            resetPaginationToggle: !prevState.resetPaginationToggle,
        }));

    };

    //HV - 24/03/2023 - handled from date should be greater than to date
    onDateChanged = (id, e) => {
        if (id == "fromDate") {
            var nextFromDate = new Date(e);
            nextFromDate.setDate(e.getDate());
            nextFromDate.toLocaleDateString();
            this.setState({ filter_fromDate: e, currentPage: 1, nextFromDate: nextFromDate }, () => {
                this.filterGridView();
            });
        }
        else if (id == "toDate") {
            var previousToDate = new Date(e);
            previousToDate.setDate(e.getDate() - 1);
            previousToDate.toLocaleDateString();
            this.setState({ filter_toDate: e, currentPage: 1, previousToDate: previousToDate }, () => {
                this.filterGridView();
            });
        }

    };

    goback = () => {
        this.props.navigate("/Dashboard");
    };

    getActionRequiredColumns = [
        {
            width: '100%',
            selector: 'actionRequiredId',
            cell: row => (
                <div className="row">
                    <div className="col-12">
                        <div className=" dates">
                            <p className="action-required-date">{row.actionDateDisplay}</p>
                        </div>
                        {row.actionRequiredDataList.map((itemText, i) => {
                            return (
                                <div className="action-data" key={i}>
                                    <div className="role">{itemText.module}
                                        {itemText.isViewedAdmin == 1 ? <img src="./images/grey-bulb.png" alt="" /> : <img src="./images/orange-bulb.png" alt="" />
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-10 content">{itemText.text}</div>
                                        <div className=" col-12 col-lg-2"><a style={{ cursor: 'pointer' }} onClick={() => this.onViewNotificationClick(itemText.navigateTo, itemText.module, itemText.clientId, itemText.shipId, itemText.supplierId, itemText.actionRequiredId, itemText)}>
                                            {this.state.accessList != null ? CheckIfUserHasActionRequiredModuleAccess(this.state.accessList, itemText?.module, itemText?.navigateTo) : "Action Needed"}</a></div>
                                    </div>
                                    <div className="hours">
                                        {itemText.duration}
                                    </div>
                                    <hr />
                                </div>
                            )
                        }
                        )}
                    </div></div>

            ),
            ignoreRowClick: true,
            center: false,
        }
    ];

    clearAllFilter = () => {
        this.setState(
            {
                dropdown_category: "",
                filter_fromDate: "",
                filter_toDate: "",
                currentPage: 1,
                dropdown_readunread: "",
                filteredActionRequiredList: this.state.defaultactionRequiredData,
                nextFromDate: "",
                previousToDate: currentDate
            },
            () => {
                this.filterGridView();
            }
        );
    };

    render() {
        const {
            isLoading,
            toastVisible,
            toastType,
            toastMessage,
            actionRequiredData,
            userNameList,
            dropdown_category,
            filter_fromDate,
            filter_toDate,
            nextFromDate,
            previousToDate,
            dropdown_readunread,
            readUnreadOptions
        } = this.state;

        return (
            <div>
                {isLoading && <Loader></Loader>}
                <Header componentFor={"ActionRequired"} />
                {
                    toastVisible && (
                        <Toast toastVisible type={toastType} message={toastMessage} closeToast={this.closeToast} />
                    )
                }
                <main className="page-height">
                    <div className="container ">
                        <div className="actionRequired-admin">
                            <div className="action-required">
                                <div className="row">
                                    <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">

                                        <img className="back-btn-responsive action-required-back-button" src="images/back button image.png" alt="Back" style={{ pointerEvents: "all", height: '22px' }} onClick={this.goback} />
                                        <span className="title-font"> <h5>&nbsp; Action Required</h5></span>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-filter-dropdown action-required-filter">
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button type="button" className="accordion-button collapsed filter-toggle-btn" data-bs-toggle="collapse" data-bs-target="#collapseOne">Filter By</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body filter-dropdwn-body">
                                                <div className="filter-box filter-flex-item form-label">
                                                    <MobileDropdown
                                                        dropdownLabel="Category:"
                                                        name="category"
                                                        onChange={this.onDropdownSelect}
                                                        selected={dropdown_category}
                                                    >
                                                        {userNameList == null
                                                            ? null
                                                            : userNameList.map((obj, index) => (
                                                                <option key={index} value={obj.userRoleId}>
                                                                    {obj.name}
                                                                </option>
                                                            ))}
                                                        ;
                                                    </MobileDropdown>
                                                </div>
                                                <div className="filter-box filter-flex-item form-label">
                                                    <MobileDropdown
                                                        dropdownLabel="Read/Unread:"
                                                        name="readunread"
                                                        onChange={this.onDropdownSelect}
                                                        selected={dropdown_readunread}
                                                    >
                                                        {readUnreadOptions == null
                                                            ? null
                                                            : readUnreadOptions.map((obj, key) => (
                                                                <option
                                                                    key={obj.id}
                                                                    value={obj.id}
                                                                >
                                                                    {obj.value}
                                                                </option>
                                                            ))}
                                                        ;
                                                    </MobileDropdown>
                                                </div>
                                                <div className="filter-date-from">
                                                    <label htmlFor="exampleDataList" className="form-label">
                                                        Date:
                                                    </label>
                                                    <span className="from-to-box">
                                                        <span className="from-date">
                                                            <label>From:</label>
                                                            <DatePicker
                                                                selectedDate={filter_fromDate}
                                                                onDateChange={(e) =>
                                                                    this.onDateChanged("fromDate", e)
                                                                }
                                                                maxDate={previousToDate}
                                                            >
                                                                {" "}
                                                            </DatePicker>
                                                        </span>
                                                        <span className="to-date">
                                                            <label>To:</label>
                                                            <DatePicker
                                                                selectedDate={filter_toDate}
                                                                onDateChange={(e) =>
                                                                    this.onDateChanged("toDate", e)
                                                                }
                                                                minDate={nextFromDate}
                                                                maxDate={currentDate}
                                                            >
                                                                {" "}
                                                            </DatePicker>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-3 clear-all-btn">
                                                    <button
                                                        type="button"
                                                        className="btn btn-blue-bg"
                                                        onClick={this.clearAllFilter}
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="action-required-filter desktop-className">
                                <div className="supplier-filter">
                                    <div className="row">
                                        <div className="col-lg-12 filter-flex-container wrap">
                                            <div className="filter-box filter-flex-item form-label" id={"category"}>
                                                <Dropdown
                                                    dropdownLabel="Category:"
                                                    name="category"
                                                    onChange={this.onDropdownSelect}
                                                    selected={dropdown_category}
                                                    style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                                                >
                                                    {userNameList == null
                                                        ? null
                                                        : userNameList.map((obj, index) => (
                                                            <option key={index} value={obj.userRoleId}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                    ;
                                                </Dropdown>
                                                {dropdown_category > 0 ? (
                                                    <UncontrolledTooltip
                                                        autohide={false}
                                                        target={"category"}
                                                        aria-haspopup="true"
                                                        role="example"
                                                        effect="solid"
                                                        placement="right"
                                                        style={{ width: "350px" }}
                                                    >
                                                        {userNameList != null && userNameList.filter(x => x.userRoleId == dropdown_category)[0]?.label}
                                                    </UncontrolledTooltip>) : null}
                                            </div>
                                            <div
                                                className="filter-box filter-flex-item form-label"
                                                id={"readunread"}
                                            >
                                                <Dropdown
                                                    dropdownLabel="Read/Unread:"
                                                    name="readunread"
                                                    onChange={this.onDropdownSelect}
                                                    selected={dropdown_readunread}
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "pre",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    {readUnreadOptions == null
                                                        ? null
                                                        : readUnreadOptions.map((obj) => (
                                                            <option
                                                                key={obj.id}
                                                                value={obj.id}
                                                            >
                                                                {obj.value}
                                                            </option>
                                                        ))}
                                                    ;
                                                </Dropdown>
                                                {dropdown_readunread > 0 ? (
                                                    <UncontrolledTooltip
                                                        autohide={false}
                                                        target={"readunread"}
                                                        aria-haspopup="true"
                                                        role="example"
                                                        effect="solid"
                                                        placement="bottom"
                                                        style={{ width: "350px" }}
                                                    >
                                                        {readUnreadOptions != null &&
                                                            readUnreadOptions.filter(
                                                                (x) =>
                                                                    x.id == dropdown_readunread
                                                            )[0]?.value}
                                                    </UncontrolledTooltip>
                                                ) : null}
                                            </div>
                                            <div className="filter-date-from">
                                                <label htmlFor="exampleDataList" className="form-label">
                                                    Date:
                                                </label>
                                                <span className="from-to-box">
                                                    <span className="from-date">
                                                        <label>From:</label>
                                                        <DatePicker
                                                            selectedDate={filter_fromDate}
                                                            onDateChange={(e) =>
                                                                this.onDateChanged("fromDate", e)
                                                            }
                                                            maxDate={previousToDate}
                                                        >
                                                            {" "}
                                                        </DatePicker>
                                                    </span>
                                                    <span className="to-date">
                                                        <label>To:</label>
                                                        <DatePicker
                                                            selectedDate={filter_toDate}
                                                            onDateChange={(e) =>
                                                                this.onDateChanged("toDate", e)
                                                            }
                                                            minDate={nextFromDate}
                                                            maxDate={currentDate}
                                                        >
                                                            {" "}
                                                        </DatePicker>
                                                    </span>
                                                </span>
                                            </div>

                                            <div className="col-12 col-md-2 col-lg-2 desktop-clear-btn clear-all-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-blue-bg"
                                                    onClick={this.clearAllFilter}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="action-required-datatable" style={{ display: 'block' }}>
                                <DataTable
                                    columns={this.getActionRequiredColumns}
                                    data={actionRequiredData}
                                    highlightOnHover={false}
                                    pointerOnHover={false}
                                    noHeader={true}
                                    noDataComponent={isLoading ? '' : 'No data to display.'}
                                    noTableHead={true}
                                    overflowY={true}
                                    style={{ backgroundColor: 'transparent', height: '100%' }}
                                    pagination
                                    paginationServer
                                    paginationPerPage={20}
                                    paginationTotalRows={this.state.totalrows}
                                    paginationComponentOptions={{
                                        rowsPerPageText: '',
                                        rangeSeparatorText: 'of',
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={this.handlePageChange}
                                    paginationResetDefaultPage={this.state.resetPaginationToggle}
                                />
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ActionRequired);
