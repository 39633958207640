import webserviceWrapper from "../helpers/webserviceWrapper";

export const MdsdocService = {
  GetMdsdocData,
  SaveMdsdocData,
  AddDeclarationMDSDoC,
  DeleteDeclarationMDSDoC,
  AddRemarkMDSDoC,
  DeleteRemarkMDSDoC,
  AddLineItemMdsdoc,
  DeleteLineItemMdsdoc,
  GetLineItemData,
  GetCTIRegulations,
  CheckIfLinkIsAccessible,
  VerifyMdsdocLinkPassword,
  GetSupplierData,
  GetPOAndLineItemData,
};

function GetMdsdocData(mdsdocId) {
  return webserviceWrapper.get(`/Mdsdoc/GetMdsdocData?mdsdocId=${mdsdocId}`);
}

function SaveMdsdocData(data) {
  return webserviceWrapper.postWithHeader(
    `/Mdsdoc/SaveMdsdocData`,
    data,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function AddDeclarationMDSDoC(msdsoc, declarationText) {
  return webserviceWrapper.get(
    `/Mdsdoc/AddDeclarationMDSDoC?msdsocID=${msdsoc}&declarationText=${declarationText}`
  );
}

function DeleteDeclarationMDSDoC(declarationId) {
  return webserviceWrapper.get(
    `/Mdsdoc/DeleteDeclarationMDSDoC?mdsdocDeclarationID=${declarationId}`
  );
}

function AddRemarkMDSDoC(msdsoc, remarkText) {
  return webserviceWrapper.get(
    `/Mdsdoc/AddRemarkMDSDoC?msdsocID=${msdsoc}&remarkText=${remarkText}`
  );
}

function DeleteRemarkMDSDoC(remarkId) {
  return webserviceWrapper.get(
    `/Mdsdoc/DeleteRemarkMDSDoC?mdsdocRemarkID=${remarkId}`
  );
}

function AddLineItemMdsdoc(mdsdocId, lineItemId) {
  return webserviceWrapper.get(
    `/Mdsdoc/AddLineItemMdsdoc?mdsdocId=${mdsdocId}&lineItemId=${lineItemId}`
  );
}

function DeleteLineItemMdsdoc(mdsdocId, lineItemId) {
  return webserviceWrapper.get(
    `/Mdsdoc/DeleteLineItemMdsdoc?mdsdocId=${mdsdocId}&lineItemId=${lineItemId}`
  );
}

function GetLineItemData(data) {
  return webserviceWrapper.postWithHeader(
    `/Mdsdoc/GetLineItemData`,
    data,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function GetCTIRegulations() {
  return webserviceWrapper.get(`/Mdsdoc/GetCTIRegulations`);
}

function CheckIfLinkIsAccessible(supplierId, mdsdocId) {
  return webserviceWrapper.get(
    `/Mdsdoc/CheckIfLinkIsAccessible?supplierId=${supplierId}&mdsdocId=${mdsdocId}`
  );
}

function VerifyMdsdocLinkPassword(data) {
  return webserviceWrapper.postWithHeader(
    `/Mdsdoc/VerifyMdsdocLinkPassword`,
    data,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function GetSupplierData(supplierId) {
  return webserviceWrapper.get(
    `/Mdsdoc/GetSupplierData?supplierId=${supplierId}`
  );
}

function GetPOAndLineItemData(supplierId) {
  return webserviceWrapper.get(
    `/Mdsdoc/GetPOAndLineItemData?supplierId=${supplierId}`
  );
}
