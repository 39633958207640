import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import 'ag-grid-community/styles//ag-theme-balham.css';

export default function AGGrid(props) {
    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: props.height ?? "400px"
            }}
            onClick={props.onOverlayClick}>
            <AgGridReact
                onGridReady={props.onGridReady}
                suppressMenuHide={true}
                pagination={true}
                paginationPageSize={props.pagination}
                defaultColDef={{ sortable: true, editable: false, unSortIcon: true, filter: true, resizable: true, autoHeight:props.autoHeight, wrapHeaderText: true, autoHeaderHeight: true, wrapText: true, 
                    textOverflow: "ellipsis", whiteSpace: "pre", overflow: "hidden", }}
                rowData={props.rowData}
                columnDefs={props.colDefs}
                suppressNoRowsOverlay={false}
                animateRows={true}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                rowHeight={50}
                autoHeight={false}
                rowSelection={props.rowSelection ?? false}
                rowMultiSelectWithClick={props.rowMultiSelectWithClick ?? false}
                onCellContextMenu={props.onCellContextMenu}
                onSelectionChanged={props.onSelectionChanged}
                onCellClicked={props.onCellClicked}
                onCellKeyPress={props.onCellKeyPress}
                isRowSelectable={props.isRowSelectable}
                components={props.frameworkComponents?? null}
                overlayNoRowsTemplate={props.overlayNoRowsTemplate}
                onSortChanged={props.onSortChanged}
            >
            </AgGridReact>
        </div>


    )
}
