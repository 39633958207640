import React, { Component } from "react";
import DatePickerComponent from "../../components/DatePickerComponent";
import Dropdown from "../../components/Dropdown";
import {
  generateRandomString,
  helperText,
  isEmptyObject,
  isValidFile,
} from "../../helpers";
import { isStringEmpty } from "../../helpers/string-helper";
import DeleteModal from "../../components/DeleteModel";
import Toast from "../../components/Toast";

const validFileFormatsExt = ["pdf,PDF"];
const validFileFormatsReadableExt = ["xlsx,xls"];
const validFileFormats =
  "application/pdf,application/PDF, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const validFileFormatsReadable =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const validPdfFileFormats = "application/pdf,application/PDF";

const InputField = (props) => {
  return (
    <div className="col-12 col-sm-6  col-lg-4 PO-data ">
      <label htmlFor="">{props.label}</label>
      <div className={!isStringEmpty(props.error) ? "invalid-position" : ""}>
        <input
          autoFocus={false}
          autoComplete={"off"}
          type={props.type}
          className="form-control"
          name={props.name}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
        />
        {!isStringEmpty(props.error) && <ErrorView errorFor="emptyText" />}
      </div>
    </div>
  );
};

const DateField = (props) => {
  return (
    <div className="col-12 col-sm-6  col-lg-4 PO-data ">
      <label htmlFor="">{props.label}</label>
      <div className={!isStringEmpty(props.error) ? "invalid-position" : ""}>
        <DatePickerComponent
          name={props.name}
          selectedDate={props.selectedDate}
          onDateChange={props.onDateChange}
          isNotRoot={true}
        >
          {" "}
        </DatePickerComponent>
        {!isStringEmpty(props.error) && <ErrorView errorFor="emptyText" />}
      </div>
    </div>
  );
};

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon-modal"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

export default class TTPOFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInputKey: "",
      filePath: "",
      validateError: false,
      fileName: "",
      errorMessages: {},
      unreadablePODataList: [],
      dropdown_fileProperty: "unreadable",
      dropdown_fileType: 0,
      unreadableErrorMessages: [],
      showDeleteModal: false,
      rowIdToDelete: 0,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
    };
  }

  componentDidMount = () => {};

  onUploadClick = () => {
    const { dropdown_fileType, dropdown_fileProperty, POFile } = this.state;
    const { onUploadClick } = this.props;
    var hasErrors = this.validateFields();

    if (hasErrors) return;

    // if (dropdown_fileProperty == "readable" && dropdown_fileType == "excel") {
    //  hasErrors = this.validateFields();
    // if (hasErrors)
    //   return;
    // const data = {
    //   dropdown_filetype: dropdown_fileType,
    //   dropdown_fileProperty: dropdown_fileProperty,
    //   POFile: POFile
    // }
    // onUploadClick(data);
    // }

    // else if (dropdown_fileProperty == "unreadable") {
    //   hasErrors = this.validateUnreadableFields();

    //   if (hasErrors)
    //     return;
    var { unreadablePODataList } = this.state;
    const data = {
      dropdown_filetype: dropdown_fileType,
      dropdown_fileProperty: dropdown_fileProperty,
      unreadablePODataList: unreadablePODataList,
    };
    onUploadClick(data);
    // }
  };

  validateUnreadableFields = () => {
    let hasErrors = false;
    const {
      dropdown_fileProperty,
      unreadablePODataList,
      errorMessages,
      dropdown_fileType,
    } = this.state;
    let errors = errorMessages;

    errors["errorFileType"] =
      dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";
    errors["errorFileProperty"] =
      dropdown_fileProperty == undefined || dropdown_fileProperty == 0
        ? "true"
        : "";

    if (dropdown_fileProperty == "unreadable") {
      var errorArray = [];

      unreadablePODataList.map((item) => {
        var arrayInternal = [];
        arrayInternal.push({
          errorId: "errorShip",
          value:
            item.dropdown_modalShip == undefined || item.dropdown_modalShip == 0
              ? "true"
              : "",
        });
        arrayInternal.push({
          errorId: "errorSupplier",
          value:
            item.dropdown_modalSupplier == undefined ||
            item.dropdown_modalSupplier == 0
              ? "true"
              : "",
        });
        arrayInternal.push({
          errorId: "errorPONumber",
          value: item.poNumber == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorTotalPOItems",
          value: item.totalPOItems == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorTotalSuspectedHazmatItems",
          value: item.totalSuspectedHazmatItems == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorCustomFileName",
          value: item.customFileName == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorPODate",
          value: item.poDate == null || item.poDate == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorPOFile",
          value: item.poFile == null ? "true" : "",
        });

        errorArray.push(arrayInternal);

        return arrayInternal;
      });
      if (
        errors["errorFileType"] != "" ||
        errors["errorFileProperty"] != "" ||
        errorArray.some((t) => t.some((o) => o.value == "true"))
      )
        hasErrors = true;
    }

    this.setState({
      validateError: hasErrors,
      unreadableErrorMessages: errorArray,
      errorMessages: errors,
      validateFileError: false,
      unreadablePODataList: unreadablePODataList,
    });
    return hasErrors;
  };

  validateFields = () => {
    var {
      dropdown_fileType,
      dropdown_fileProperty,
      POFile,
      errorMessages,
      fileName,
      validateFileError,
    } = this.state;
    var hasErrors = false;
    var correctFile = false;
    let errors = errorMessages;

    errors["errorFileType"] =
      dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";
    errors["errorFileProperty"] =
      dropdown_fileProperty == undefined || dropdown_fileProperty == 0
        ? "true"
        : "";
    errors["errorPOFile"] =
      POFile == undefined || POFile == null || validateFileError ? "true" : "";

    if (
      errors["errorFileType"] != "" ||
      errors["errorFileProperty"] != "" ||
      errors["errorPOFile"] != ""
    ) {
      hasErrors = true;
    } else {
      if (dropdown_fileType == "excel") {
        correctFile =
          fileName.includes("xlsx") || fileName.includes("xls") ? true : false;
      } else if (dropdown_fileType == "pdf") {
        correctFile = fileName.includes("pdf") ? true : false;
      }
    }

    this.setState({
      validateError: hasErrors,
      errorMessages: errors,
      validateFileError: false,
    });
    return hasErrors;
  };

  onFileUpload = async (e) => {
    const { name, value } = e.target;
    var { dropdown_fileType, unreadablePODataList, errorMessages } = this.state;
    let errors = errorMessages;
    var correctFile = false;
    var hasErrors = false;
    errors["errorFileType"] =
      dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";

    let files = e.target.files[0];
    if (files != undefined && files != null) {
      //errors['errorFileProperty'] = dropdown_fileProperty == undefined || dropdown_fileProperty == 0 ? "true" : "";

      if (errors["errorFileType"] != "") {
        this.setState({
          fileInputKey: generateRandomString,
          [name]: null,
          validateFileError: false,
          validateError: true,
          errorMessages: errors,
        });
        return;
      }
      if (errors["errorFileType"] != "") hasErrors = true;

      this.setState({
        validateError: hasErrors,
      });

      if (!hasErrors) {
        // if (dropdown_fileType == "excel") {
        //   correctFile = (files.name.includes('xlsx') || files.name.includes('xls')) ? true : false;
        // }
        // else if (dropdown_fileType == "pdf") {
        //     correctFile = (files.name.includes('pdf')) ? true : false;
        // }
        // if (correctFile) {
        var rowIndex = unreadablePODataList.length > 0 ? 0 : -1;

        if (dropdown_fileType == "excel") {
          if (!isValidFile(e.target.value, validFileFormatsReadableExt)) {
            // if (this.state.filePath == "") {
            if (rowIndex > -1) {
              unreadablePODataList[rowIndex]["poFile"] = null;
              unreadablePODataList[rowIndex].poFileInputKey =
                generateRandomString;
            }

            this.setState({
              fileInputKey: generateRandomString,
              [name]: null,
              validateFileError: false,
              fileName: "",
              validateError: false,
              unreadablePODataList: unreadablePODataList,
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage:
                dropdown_fileType == "excel"
                  ? helperText.TrackingTool.wrongPOFileTpeExcelError
                  : helperText.TrackingTool.wrongPOFileTpePDFError,
            });
            // }
          } else {
            errors["errorPOFile"] = "";

            if (rowIndex > -1) {
              unreadablePODataList[rowIndex]["poFile"] = files;
              unreadablePODataList[rowIndex].poFileName = files.name;
              unreadablePODataList[rowIndex].poFilePath = value;
            }

            this.setState({
              [name]: files,
              fileName: files.name,
              filePath: value,
              validateFileError: false,
              validateError: false,
              unreadablePODataList: unreadablePODataList,
            });
          }
        } else if (dropdown_fileType == "pdf") {
          if (!isValidFile(e.target.value, validFileFormatsExt)) {
            // if (this.state.filePath == "") {
            if (rowIndex > -1) {
              unreadablePODataList[rowIndex]["poFile"] = null;
              unreadablePODataList[rowIndex].poFileInputKey =
                generateRandomString;
            }

            this.setState({
              fileInputKey: generateRandomString,
              [name]: null,
              validateFileError: false,
              fileName: "",
              validateError: false,
              unreadablePODataList: unreadablePODataList,
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage:
                dropdown_fileType == "excel"
                  ? helperText.TrackingTool.wrongPOFileTpeExcelError
                  : helperText.TrackingTool.wrongPOFileTpePDFError,
            });
            // }
          } else {
            errors["errorPOFile"] = "";

            if (rowIndex > -1) {
              unreadablePODataList[rowIndex]["poFile"] = files;
              unreadablePODataList[rowIndex].poFileName = files.name;
              unreadablePODataList[rowIndex].poFilePath = value;
            }

            this.setState({
              [name]: files,
              fileName: files.name,
              filePath: value,
              validateFileError: false,
              validateError: false,
              unreadablePODataList: unreadablePODataList,
            });
          }
        }
        // else {
        //   errors['errorPOFile'] = "";

        //   if (rowIndex > -1) {
        //     unreadablePODataList[rowIndex]["poFile"] = files;
        //     unreadablePODataList[rowIndex].poFileName = files.name;
        //     unreadablePODataList[rowIndex].poFilePath = value;
        //   }

        //   this.setState({
        //     [name]: files,
        //     fileName: files.name,
        //     filePath: value,
        //     validateFileError: false,
        //     validateError: false,
        //     unreadablePODataList: unreadablePODataList
        //   });
        // }
        // }
        // else {
        //   this.setState({
        //     validateFileError: true,
        //     validateError: false,
        //     fileName: "",
        //   })

        // }
      }
    }
  };

  onDropdownChange = (e) => {
    const { name, value } = e.target;

    var unreadablePODataList = [];
    this.setState(
      {
        [name]: value,
        unreadablePODataList: [],
      },
      () => {
        const { dropdown_fileProperty, errorMessages, dropdown_fileType } =
          this.state;
        // if (name == "dropdown_fileProperty") {
        //   errorMessages['errorFileProperty'] = dropdown_fileProperty == undefined || dropdown_fileProperty == 0 ? "true" : "";
        // }
        // else
        if (name == "dropdown_fileType") {
          errorMessages["errorFileType"] =
            dropdown_fileType == undefined || dropdown_fileType == 0
              ? "true"
              : "";
        }

        // if (dropdown_fileProperty == "unreadable") {
        //  if (errorMessages['errorFileType'] == "" && errorMessages['errorFileProperty'] == "") {
        // if (errorMessages['errorFileType'] == "") {
        //   errorMessages['errorPOFile'] = "";
        //   this.setState({ validateError: false })
        // }
        if (this.state.unreadablePODataList.length == 0) {
          this.props.selectedPOUpload.forEach((element) => {
            this.onAddRowClick();
          });

          unreadablePODataList = this.state.unreadablePODataList;
          this.setState({ unreadablePODataList, fileName: "", POFile: null });
        }

        //}
        this.setState({ errorMessages: errorMessages });
      }
    );
  };

  addNewRow = (rowId, unreadablePODataList) => {
    const { dropdownList, selectedPOUpload } = this.props;
    const rowPo = rowId - 1;
    var shipName = dropdownList.shipList.filter(
      (s) => s.value == selectedPOUpload[rowPo]?.shipId
    )[0]?.label;
    var supplierName = dropdownList.supplierList.filter(
      (s) => s.value == selectedPOUpload[rowPo]?.supplierId
    )[0]?.label;
    var addRow = {
      rowId: rowId,
      shipList: dropdownList.shipList.filter(
        (x) => x.parentValue == selectedPOUpload[rowPo]?.clientId
      ),
      dropdown_modalShip: selectedPOUpload[rowPo]?.shipId,
      dropdown_modalShipName: shipName,
      poNumber: selectedPOUpload[rowPo]?.ponumber,
      totalPOItems: "",
      totalSuspectedHazmatItems: "",
      poDate: selectedPOUpload[rowPo]?.poDate,
      customFileName: "",
      supplierList: dropdownList.supplierList,
      dropdown_modalSupplier: selectedPOUpload[rowPo]?.supplierId,
      dropdown_modalSupplierName: supplierName,
      poFile: null,
      poFileName: "",
      poFilePath: "",
      poFileInputKey: generateRandomString,
    };
    unreadablePODataList.push(addRow);
    return unreadablePODataList;
  };

  onRowDataChange = (rowId, e) => {
    var { unreadablePODataList, validateError } = this.state;
    var { name, value } = e.target;

    var rowIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);
    if (rowIndex > -1) {
      unreadablePODataList[rowIndex][name] = value;
      this.setState({ unreadablePODataList }, () => {
        if (validateError) this.validateUnreadableFields();
      });
    }
  };

  onRowDateChanged = (rowId, e) => {
    var { unreadablePODataList, validateError } = this.state;
    var rowIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);

    if (rowIndex > -1) {
      unreadablePODataList[rowIndex].poDate = e;
      this.setState({ unreadablePODataList }, () => {
        if (validateError) this.validateUnreadableFields();
      });
    }
  };

  onRowFileUpload = async (e, rowId) => {
    const { name, value } = e.target;
    var {
      dropdown_fileProperty,
      unreadablePODataList,
      validateError,
      dropdown_fileType,
    } = this.state;
    var correctFile = false;

    let files = e.target.files[0];
    if (dropdown_fileType == "excel") {
      correctFile =
        files.name.includes("xlsx") || files.name.includes("xls")
          ? true
          : false;
    } else if (dropdown_fileType == "pdf") {
      correctFile = files.name.includes("pdf") ? true : false;
    }

    var rowIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);
    if (correctFile) {
      if (
        !isValidFile(
          value,
          dropdown_fileProperty == "readable"
            ? validFileFormatsReadableExt
            : validFileFormatsExt
        )
      ) {
        if (this.state.filePath == "") {
          if (rowIndex > -1) {
            unreadablePODataList[rowIndex][name] = null;
            (unreadablePODataList[rowIndex].poFileInputKey =
              generateRandomString),
              this.setState(
                { validateFileError: false, unreadablePODataList },
                () => {
                  if (validateError) this.validateUnreadableFields();
                }
              );
          }
        }
        return;
      }

      if (rowIndex > -1) {
        unreadablePODataList[rowIndex][name] = files;
        unreadablePODataList[rowIndex].poFileName = files.name;
        unreadablePODataList[rowIndex].poFilePath = value;
        this.setState(
          { validateFileError: false, unreadablePODataList },
          () => {
            if (validateError) this.validateUnreadableFields();
          }
        );
      }
    } else {
      this.setState({
        validateFileError: true,
      });
    }
  };

  onAddRowClick = () => {
    var { unreadablePODataList } = this.state;
    var lastInsertedRowId = 0;
    if (unreadablePODataList.length > 0) {
      lastInsertedRowId =
        unreadablePODataList[unreadablePODataList.length - 1].rowId;
    }
    unreadablePODataList = this.addNewRow(
      lastInsertedRowId + 1,
      unreadablePODataList
    );
    this.setState({ unreadablePODataList });
  };

  onDeleteRowClick = (rowId) => {
    const { unreadablePODataList, dropdown_fileProperty, validateError } =
      this.state;
    if (unreadablePODataList.length > 0) {
      var itemIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);
      if (itemIndex > -1) unreadablePODataList.splice(itemIndex, 1);
      this.setState({
        unreadablePODataList,
        dropdown_fileProperty:
          unreadablePODataList.length == 0 ? 0 : dropdown_fileProperty,
        validateError: unreadablePODataList.length == 0 ? false : validateError,
        rowIdToDelete: 0,
        showDeleteModal: false,
      });
    }
  };

  onDeleteModalYesClick = () => {
    this.onDeleteRowClick(this.state.rowIdToDelete);
  };

  onDeleteModalCloseClick = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  onDeleteModalOpenClick = (id) => {
    this.setState({
      showDeleteModal: true,
      rowIdToDelete: id,
    });
  };

  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };

  render() {
    const { onCloseClick } = this.props;

    const {
      fileInputKey,
      validateError,
      dropdown_fileProperty,
      fileName,
      unreadablePODataList,
      unreadableErrorMessages,
      errorMessages,
      showDeleteModal,
      dropdown_fileType,
      toastVisible,
      toastMessage,
      toastType,
    } = this.state;

    return (
      <div className="upload-po-list" style={{ display: "block" }}>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload PO List</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick("showUploadModal", false)}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="PO-list-text">
                  <p> Select the PO list you want to upload</p>
                </div>
                <div className="PO-list-topLabel">
                  <div className="row">
                    <div className=" col-sm-6 col-lg-5">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorFileType)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <Dropdown
                          dropdownLabel="File Type:"
                          name="fileType"
                          onChange={this.onDropdownChange}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                          selected={dropdown_fileType}
                        >
                          <option value="pdf">PDF</option>
                          <option value="excel">Excel</option>
                        </Dropdown>
                        {!isStringEmpty(errorMessages.errorFileType) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                    {/* <div className=" col-sm-6  col-lg-5">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorFileProperty)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <Dropdown
                          dropdownLabel="File Property:"
                          name="fileProperty"
                          onChange={this.onDropdownChange}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                          selected={dropdown_fileProperty}
                        >
                          <option value="readable">Readable</option>
                          <option value="unreadable">Unreadable</option>
                        </Dropdown>
                        {!isStringEmpty(errorMessages.errorFileProperty) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6  col-lg-8">
                      <div className="choosefile-btn">
                        <label
                          htmlFor="myfile"
                          className={
                            "attach-btn attach-btn-responsive chs-btn " +
                            (!isStringEmpty(errorMessages.errorPOFile)
                              ? "invalid-position-border"
                              : "")
                          }
                        >
                          <img
                            className="upload-img"
                            src="images/Icon awesome-upload.png"
                            width="18px"
                          />
                          &nbsp;Choose File
                        </label>

                        {!isStringEmpty(errorMessages.errorPOFile) && (
                          <img
                            src="images\icon material-error.png"
                            alt="invalid"
                            className="invalid-position-img"
                          />
                        )}
                        <label className="required-field">
                          &nbsp; (Please select an pdf, excel file)
                        </label>
                        <input
                          type="file"
                          accept={
                            dropdown_fileType == "excel"
                              ? validFileFormatsReadable
                              : validPdfFileFormats
                          }
                          id="myfile"
                          name="POFile"
                          onChange={this.onFileUpload}
                          key={fileInputKey || ""}
                          style={{ display: "none", visibility: "none" }}
                        />
                        <label className="label-breakword">&nbsp;&nbsp;&nbsp;{fileName}</label>
                      </div>
                    </div>
                  </div>

                  {/* {unreadablePODataList.map((item, i) => {
                        let unreadableListLength = unreadableErrorMessages.length;
                        var errorObj = (i >= unreadableListLength) ? {} : unreadableErrorMessages[i];
                        return (
                          <div key={item.rowId} className="PO-list-upperBlock">
                            <div className="close-btn">
                              <img
                                className="cross-btn"
                                src="./images/Icon ionic-ios-close.png"
                                alt="cancel"
                                style={{
                                  pointerEvents: "all",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.onDeleteModalOpenClick(item.rowId)
                                }
                              />
                            </div>
                            <div className="row">
                              <InputField
                                type={"text"}
                                label="Ship Name:"
                                name="shipName"
                                value={item.dropdown_modalShipName}
                              />
                              <InputField
                                type={"text"}
                                label="PO Number:"
                                name="poNumber"
                                value={item.poNumber}
                              />

                              <InputField
                                type={"number"}
                                label="Number of PO Items:"
                                name="totalPOItems"
                                value={item.totalPOItems}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[3].value
                                }
                              />
                              <InputField
                                type={"number"}
                                label="Total Suspected Hazmat Items:"
                                name="totalSuspectedHazmatItems"
                                value={item.totalSuspectedHazmatItems}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[4].value
                                }
                              />
                              <DateField
                                label="PO Date:"
                                name="poDate"
                                selectedDate={item.poDate}
                                onDateChange={(e) =>
                                  this.onRowDateChanged(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[6].value
                                }
                              />
                              <InputField
                                label="Custom File Name:"
                                name="customFileName"
                                value={item.customFileName}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[5].value
                                }
                              />

                              <InputField
                                type={"text"}
                                label="Supplier Name:"
                                name="supplierName"
                                value={item.dropdown_modalSupplierName}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[2].value
                                }
                              />
                              <div className="col-12 col-sm-6  col-lg-8 PO-data">
                                <div className="choosefile-btn">
                                  <input
                                    type="file"
                                    accept={validFileFormats}
                                    id={`poFile${i}`}
                                    name="poFile"
                                    onChange={(e) =>
                                      this.onRowFileUpload(e, item.rowId)
                                    }
                                    key={item.poFileInputKey || ""}
                                    style={{
                                      display: "none",
                                      visibility: "none",
                                    }}
                                  />
                                  <label
                                    htmlFor={`poFile${i}`}
                                    className={
                                      "attach-btn attach-btn-responsive chs-btn " +
                                      (!isStringEmpty(
                                        isEmptyObject(errorObj)
                                          ? ""
                                          : errorObj[7].value
                                      )
                                        ? "invalid-position-border"
                                        : "")
                                    }
                                  >
                                    <img
                                      className="upload-img"
                                      src="images/Icon awesome-upload.png"
                                      width="18px"
                                    />
                                    &nbsp;Choose File
                                  </label>

                                  {!isStringEmpty(
                                    isEmptyObject(errorObj)
                                      ? ""
                                      : errorObj[7].value
                                  ) && (
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                        className="invalid-position-img"
                                      />
                                    )}
                                  &nbsp; (Please select an pdf, excel file)
                                  <label>
                                    &nbsp;&nbsp;&nbsp;{item.poFileName}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })} */}
                </div>

                {validateError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-background-color upload-btn"
                    onClick={this.onUploadClick}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDeleteModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteModalCloseClick}
            onYesClick={this.onDeleteModalYesClick}
            onNoClick={this.onDeleteModalCloseClick}
            modalheader="Delete PO Item"
          />
        )}
      </div>
    );
  }
}
