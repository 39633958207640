import webserviceWrapper from "../helpers/webserviceWrapper";
export const settingsService = {
  GetSettingsData,
  UpdateActivityLogSettings,
  UpdateLoginSettings,
  UpdateReportsSettings,
  UpdateEmailSettings,
  DeleteCTIRegulationItem,
  SaveCTIRegulationsSettings,
};

function GetSettingsData() {
  return webserviceWrapper.get("/Settings/GetSettingsData");
}

function UpdateActivityLogSettings(payload) {
  return webserviceWrapper.post("/Settings/UpdateActivityLogSettings", payload);
}
function UpdateLoginSettings(payload) {
  return webserviceWrapper.post("/Settings/UpdateLoginSettings", payload);
}
function UpdateReportsSettings(payload) {
  return webserviceWrapper.post("/Settings/UpdateReportsSettings", payload);
}
function UpdateEmailSettings(payload) {
  return webserviceWrapper.post("/Settings/UpdateEmailSettings", payload);
}
function DeleteCTIRegulationItem(ctiRegulationsID) {
  return webserviceWrapper.get(
    `/Settings/DeleteCTIRegulationItem?ctiRegulationsID=${ctiRegulationsID}`
  );
}
function SaveCTIRegulationsSettings(payload) {
  return webserviceWrapper.post(
    "/Settings/SaveCTIRegulationsSettings",
    payload
  );
}
