//this file will contain wrapper functions for http get/post.
import axios from "axios";
import { config, authHeader } from "./config";
import { isStringEmpty } from "./string-helper";
import {
    DecryptUsingAES256,
    EncryptUsingAES256,
    GetLocalStorageDataWithoutParse,
} from "../helpers/utilities";

const defaultTimeout = 900000;
let postServiceTimeout = null;
let postServiceWithHeaderTimeout = null;
let getServiceTimeout = null;
class webserviceWrapper {
    constructor() {
        let service = axios.create();
        // Add a request interceptor
        service.interceptors.request.use(this.HandleRequest.bind(this), function (error) {
            // Do something with request error
            return Promise.reject(error);
        });

        service.interceptors.response.use(this.HandleSuccessResponse, this.HandleErrorResponse);
        service.defaults.timeout = defaultTimeout;
        this.service = service;        
    }
        
    buildUrl = (path) => {
        return `${config.apiUrl}${path}`;
    };
    buildLongRunningUrl = (path) => {
        return `${config.longRunniongApiUrl}${path}`;
    };

    HandleRequest(req) {
        const ExcludeURLList = [
            config.apiUrl + "/TrackingTool/DownloadPOReadableFiles",
            config.apiUrl + "/TrackingTool/DownloadTrackingToolData",
            config.apiUrl + "/TrackingTool/DownloadAllTrackingToolData",
            config.apiUrl + "/TrackingTool/DownloadApiImport",
        ];

        let exludeFound = ExcludeURLList.filter((element) => {
            return req.url.includes(element);
        });

        const authToken = this.retreiveAuthToken();
        if (authToken) {
            req.headers.common['Authorization'] = authToken
        }

        if (!(exludeFound && exludeFound.length > 0)) {
            // const BASE_URL = "https://localhost:44406/"
            if (req.method == "get") {
                if (req.url.indexOf("?") > 0) {
                    let encriptURL =
                        req.url.substr(0, req.url.indexOf("?") + 1) +
                        EncryptUsingAES256(
                            req.url.substr(req.url.indexOf("?") + 1, req.url.length)
                        );
                    let cloneReq = req;
                    cloneReq.url = encriptURL;
                    return cloneReq;
                }
                return req;
            } else if (req.method == "post") {
                if (req.header && req.header["Content-Type"] == "multipart/form-data") {
                    var object = {};
                    var formData = new FormData();
                    req.data.forEach((value, key) => {
                        if (value instanceof File) {
                            //add files directly to formdata
                            formData.append(key, value);
                        } else {
                            //assign the property values to encrypt in next step
                            object[key] = value;
                        }
                    });
                    var json = JSON.stringify(object);
                    let cloneReq = req;
                    let payload = EncryptUsingAES256(json);
                    formData.append("payload", payload);
                    cloneReq.data = formData;
                    return cloneReq;
                }
                else if (req.data || req.data.length > 0) {
                    // console.log(req);
                    let cloneReq = req;
                    let payload = EncryptUsingAES256(JSON.stringify(req.data));
                    let encryptedPayload = {
                        payload,
                    };
                    cloneReq.data = encryptedPayload;
                    return cloneReq;
                }
                return req;
            }
        }

        return req;
    }

    /* <summary>
    date: 03-06-2020
    Name: Gm
    description: Handle Success Response from WebAPI
    <summary>*/
    HandleSuccessResponse(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        const ExcludeURLList = [
            config.apiUrl + "/TrackingTool/DownloadPOReadableFiles",
            config.apiUrl + "/TrackingTool/DownloadTrackingToolData",
            config.apiUrl + "/TrackingTool/DownloadAllTrackingToolData",
            config.apiUrl + "/TrackingTool/DownloadApiImport",
            config.apiUrl + "/Test/FetchEmails",
        ];
        // Do something before request is sent
        let exludeFound = ExcludeURLList.filter((element) => {
            return response.config.url.includes(element);
        });

        if (!(exludeFound && exludeFound.length > 0)) {
            let cloneRes = response;

            let decryptedData = isStringEmpty(response.data)
                ? ""
                : JSON.parse(DecryptUsingAES256(response.data));
            cloneRes.data = decryptedData;
            let responsePayload = {
                status: 200,
                error: null,
                data: cloneRes.data,
                headers: cloneRes.headers,
            };
            return responsePayload;
        }

        let responsePayload = {
            status: 200,
            error: null,
            data: response.data,
            headers: response.headers,
        };
        return responsePayload;
    }

    /* <summary>
    date: 03-06-2020
    Name: Gm
    description: Handle  Error response from WebAPI 
    <summary>*/
    HandleErrorResponse = (error) => {
        try {
            let decryptedData = isStringEmpty(error.response)
                ? ""
                : JSON.parse(DecryptUsingAES256(error.response));

            error.response = decryptedData;

            var response = {
                status: error.response !== undefined ? error.response.status : error,
                error: error,
                data: error.response,
            };
            if (response.status == 401) {
                /* <summary>
                date: 18/10/2022
                Name: AS
                description: Sets the Session Timeout Modal Visibility to true
                <summary>*/

                // store.dispatch(eventAction.SetMessageModalVisible(true));
            }
            return response;
        } catch (error) {
            var response = {
                status: 500,
                error: error,
                data: null,
            };
            return response;
        }
    };

    async getUsingLongRunningUrl(path, timeout, responseType, useExternalPath) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );

        if (!useExternalPath) path = this.buildLongRunningUrl(path);

        const response = await this.service.get(
            path,
            { responseType: responseType == undefined ? "json" : responseType },
            {
                cancelToken: source.token,
            }
        );
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }

    async get(path, timeout, responseType, useExternalPath) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );

        if (!useExternalPath) path = this.buildUrl(path);

        const response = await this.service.get(
            path,
            { responseType: responseType == undefined ? "json" : responseType },
            {
                cancelToken: source.token,
            }
        );
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }

    async post(path, payload, timeout) {
        clearTimeout(postServiceTimeout);
        var source = axios.CancelToken.source();
        postServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: this.buildUrl(path),
            responseType: "json",
            data: payload,
            timeout: timeout === undefined ? defaultTimeout : timeout,
        });
        postServiceTimeout = null;
        return response;
    }
    /* <summary>
    date: 18/10/2022
    Name: AS
    description: Post API Call with Header Data
    <summary>*/

    async postWithHeader(path, payload, header, timeout) {

        clearTimeout(postServiceWithHeaderTimeout);
        let source = axios.CancelToken.source();
        postServiceWithHeaderTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );

        let response = await this.service.request({
            method: "POST",
            url: this.buildUrl(path),
            responseType: "json",
            data: payload,
            cancelToken: source.token,
            header,
            timeout: timeout === undefined ? defaultTimeout : timeout
        });
        postServiceWithHeaderTimeout = null;
        return response;
    }

    async postLongRunningUrl(path, payload, timeout) {
        clearTimeout(postServiceTimeout);
        var source = axios.CancelToken.source();
        postServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: this.buildLongRunningUrl(path),
            responseType: "json",
            data: payload,
            timeout: timeout === undefined ? defaultTimeout : timeout,
        });
        postServiceTimeout = null;
        return response;
    }

    async downloadBlobPostLongRunningUrl(path, payload, timeout) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        const response = await this.service.request({
            method: "POST",
            url: this.buildLongRunningUrl(path),
            responseType: "blob",
            data: payload,
            cancelToken: source.token,
        });
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }
    async downloadBlobPost(path, payload, timeout) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        const response = await this.service.request({
            method: "POST",
            url: this.buildUrl(path),
            responseType: "blob",
            data: payload,
            cancelToken: source.token,
        });
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }

    async downloadBlob(path, timeout) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );

        const url = this.buildUrl(path);
        const response = await this.service.get(url, {
            cancelToken: source.token,
            responseType: "blob",
        });
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }
        
    /* <summary>
    date: 13-12-2022
    Name: VD
    description: To get the auth token for authorisation
    <summary>*/
    retreiveAuthToken = () => {
        let authToken = "";
        try {
            const value = GetLocalStorageDataWithoutParse('SessionToken');
            if (value != undefined && !isStringEmpty(value)) authToken = `Bearer ${value}`;
        } catch (e) { }
        return authToken;
    };
}

export default new webserviceWrapper();
