import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import { dashboardservice } from "../../services/dashboard.service";
import { UserAction, UserLevel, UserRole } from "../../helpers/enums";
import Footer from "../../components/Footer";
import Dropdown from "../../components/Dropdown";
import SupplierDocumentModal from "../SupplierPortal/SupplierDocumentModal";
import { SupplierService } from "../../services/supplier.service";

class SupplierDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      accessList: [],
      shipList: [],
      dropdown_ship: 0,
      userId: 0,
      supplierProfilePic: "",
      supplierName: "",
      totalPOItems: 0,
      MDDocPendingResendCount: 0,
      MDDocPendingCount: 0,
      suspectedHazmatCount: 0,
      confirmedHazmatCount: 0,
      totalMDSDocCompleted: 0,
      pendingResendList: [],
      defaultPendingResendList: [],
      completedList: [],
      defaultCompletedList: [],
      pendingSearchWord: "",
      completedSearchWord: "",
      showDocumentUploadModal: false,
      TrackingToolId: 0,
      selectedPendingItemsList: [],
      mdsdocId: 0,
    };
  }

  componentDidMount = () => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");

    changePageTitle("");

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    if (userData != null) {
      this.setState(
        { userId: userData.id, supplierName: userData.name },
        () => {
          this.GetSupplierDashboardInfo(userData.id);
        }
      );
    }
  };

  GetSupplierDashboardInfo = async (supplierId) => {
    this.setState({ isLoading: true });
    var response = await dashboardservice.GetSupplierDashboardInfo(supplierId);
    if (response.status == 200 && response.data != null) {
      let supplierDashboardData = response.data;
      this.setState(
        {
          totalPOItems: supplierDashboardData.totalPOItems,
          MDDocPendingResendCount:
            supplierDashboardData.totalPendingResendMDSDoc,
          MDDocPendingCount: supplierDashboardData.totalPendingMDSDoc,
          suspectedHazmatCount: supplierDashboardData.totalSuspectedHazmat,
          confirmedHazmatCount: supplierDashboardData.totalConfirmedHazmat,
          totalMDSDocCompleted: supplierDashboardData.totalMDSDocCompleted,
          pendingResendList: supplierDashboardData.pendingResendList,
          defaultPendingResendList: supplierDashboardData.pendingResendList,
          completedList: supplierDashboardData.completedList,
          defaultCompletedList: supplierDashboardData.completedList,
          isLoading: false,
        },
        () => {
          AddLocalStorageData("loggedInUserPic", this.state.clientProfilePic);
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
      this.setState({ isLoading: false });
    }
    this.setState({ isLoading: false });
  };

  navigateTo = (path) => {
    this.props.navigate(path);
  };

  onSearch = (e) => {
    var inputValue = e.target.value;
    var pendingResendList = this.state.defaultPendingResendList;

    if (inputValue.trim() !== "") {
      var value = inputValue.trim().toString().toLowerCase();

      var pendingResendFilteredList = pendingResendList.filter(
        (t) =>
          t.clientName.toString().toLowerCase().includes(value) ||
          t.shipName.toString().toLowerCase().includes(value) ||
          t.poNumber.toString().toLowerCase().includes(value) ||
          t.description.toString().toLowerCase().includes(value) ||
          t.duration.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      pendingResendList:
        inputValue.trim() == "" ? pendingResendList : pendingResendFilteredList,
      pendingSearchWord: inputValue,
    });
  };

  onCompleteSearch = (e) => {
    var inputValue = e.target.value;
    var CompletedList = this.state.defaultCompletedList;

    if (inputValue.trim() !== "") {
      var value = inputValue.trim().toString().toLowerCase();

      var completedFilteredList = CompletedList.filter(
        (t) =>
          t.clientName.toString().toLowerCase().includes(value) ||
          t.shipName.toString().toLowerCase().includes(value) ||
          t.poNumber.toString().toLowerCase().includes(value) ||
          t.description.toString().toLowerCase().includes(value) ||
          t.duration.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      completedList:
        inputValue.trim() == "" ? CompletedList : completedFilteredList,
      completedSearchWord: inputValue,
    });
  };

  onSummaryClick = (value) => {
    AddLocalStorageData("SummaryClickValue", value);
    this.navigateTo("/SupplierViewItems");
  };

  onViewListClick = (value) => {
    if (value == "Pending") {
      AddLocalStorageData(
        "PendingCompletedList",
        JSON.stringify(this.state.pendingResendList)
      );
      //this.onSummaryClick(2);
    } else if (value == "Completed") {
      AddLocalStorageData(
        "PendingCompletedList",
        JSON.stringify(this.state.completedList)
      );
    }
    AddLocalStorageData("viewFrom", value);
    this.navigateTo("/SupplierPendingCompletedItems");
  };

  onDocumentModalCloseClick = () => {
    this.setState({ showDocumentUploadModal: false });
  };

  onSaveMDSDOCDeclarationData = async (mdsdocData) => {
    this.setState({ showDocumentUploadModal: false, isLoading: true });

    const { selectedPendingItemsList, userId } = this.state;
    mdsdocData.append("ClientId", selectedPendingItemsList?.clientId);
    mdsdocData.append("ShipId", selectedPendingItemsList?.shipId);
    mdsdocData.append("loggedInUserID", userId);
      mdsdocData.append("fromPage", "Supplier");

    var responseSaved = await SupplierService.SaveUploadMDSDocData(mdsdocData);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        isLoading: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.TrackingTool.documnetUploadedSuccess,
      }, () => {
        this.GetSupplierDashboardInfo(userId);
      });
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onAddEditDocumentClicked = async (obj) => {
    this.setState({
      showDocumentUploadModal: true,
      TrackingToolId: obj?.trackingToolId,
      mdsdocId: obj?.mdsdocId,
      selectedPendingItemsList: obj,
    });
  };

  // onViewCompletedDocumentClicked = async (obj) => {
  //  AddLocalStorageData("completedDocument", JSON.stringify(obj));

  //  this.navigateTo("/SupplierDocument");
  // };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      totalPOItems,
      MDDocPendingResendCount,
      MDDocPendingCount,
      suspectedHazmatCount,
      confirmedHazmatCount,
      totalMDSDocCompleted,
      pendingResendList,
      completedList,
      pendingSearchWord,
      completedSearchWord,
      showDocumentUploadModal,
      supplierName,
      userId,
      TrackingToolId,
      selectedPendingItemsList,
      mdsdocId
    } = this.state;
    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"SupplierDashboard"} />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="supplier-dashboard">
            <div className="container">
              <div className="row ">
                <div className="col-12 col-md-12 col-lg-9 summary">
                  <fieldset className="dashboard-fieldset">
                    <legend className="dashboard-legend">Summary</legend>
                    <div className="row">
                      <div
                        className="summary-box"
                        onClick={() => this.onSummaryClick(1)}
                        style={{ cursor: "pointer" }}
                      >
                        <h1>{totalPOItems}</h1>
                        <p>Number of PO Items</p>
                      </div>
                      <div
                        className="summary-box"
                        onClick={() => this.onSummaryClick(2)}
                        style={{ cursor: "pointer" }}
                      >
                        <h1 className="orange-text">
                          {MDDocPendingResendCount}
                        </h1>
                        <p>Pending MD/SDoC with Supplier</p>
                      </div>
                      <div
                        className="summary-box"
                        onClick={() => this.onSummaryClick(3)}
                        style={{ cursor: "pointer" }}
                      >
                        <h1>{suspectedHazmatCount}</h1>
                        <p>Number of Suspected Hazmat</p>
                      </div>
                      <div
                        className="summary-box"
                        onClick={() => this.onSummaryClick(4)}
                        style={{ cursor: "pointer" }}
                      >
                        <h1>{confirmedHazmatCount}</h1>
                        <p>Number of Confirmed Hazmat</p>
                      </div>
                      <div
                        className="summary-box"
                        onClick={() => this.onSummaryClick(5)}
                        style={{ cursor: "pointer" }}
                      >
                        <h1>{MDDocPendingCount}</h1>
                        <p>Pending MD/SDoC with CTI</p>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3 sup-link-row">
                  <div className="row">
                    <div className="col-6 col-lg-6">
                      <div className="sup-link-box">
                        <img
                          src="images/training.png"
                          className=""
                          alt="Training"
                          onClick={() => this.navigateTo("/TrainingDocument")}
                          style={{ cursor: "pointer" }}
                        />
                        <h5>Training</h5>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6">
                      <div className="sup-link-box">
                        <img
                          src="images/Documents.png"
                          className=""
                          alt="Documents"
                          onClick={() => this.navigateTo("/SupplierDocument")}
                          style={{ cursor: "pointer" }}
                        />
                        <h5>Documents</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="status-sec">
            <div className="container">
              <div className="row status-bg">
                <div className="col-lg-6">
                  <div className="pending-box">
                    <fieldset className="dashboard-fieldset">
                      <legend className="dashboard-legend">
                        PENDING{" "}
                        <span className="status-count">
                          {MDDocPendingResendCount}
                        </span>
                      </legend>
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="status-all"
                            onClick={() => this.onViewListClick("Pending")}
                          >
                            <a className="">View All</a>
                          </div>
                          <div className="status-search mt-3">
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="text"
                                value={pendingSearchWord}
                                id="example-search-input"
                                placeholder="Search"
                                onChange={this.onSearch}
                              />
                              <span className="input-group-append search-btn">
                                <button className="btn " type="button">
                                  <i className="fa fa-search"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="status-log-content">
                            <div className="timeline-box">
                              <div className="timeline">
                                {pendingResendList == null
                                  ? null
                                  : pendingResendList.map((obj, key) => (
                                    <div
                                      className="timeline-container"
                                      key={key}
                                    >
                                      <div className="timeline-icon yellow-bg">
                                        <img
                                          src="images/timeline-icon.png"
                                          className=""
                                          alt=""
                                        />
                                      </div>
                                      <div className="timeline-body">
                                        <h4 className="timeline-title">
                                          <span className="category-tag">
                                            {obj.addResendCompletedText}
                                          </span>
                                        </h4>
                                        <p>Client Name: {obj.clientName}</p>
                                        <p>Ship Name: {obj.shipName}</p>
                                        <p>PO Number: {obj.poNumber}</p>
                                        <p>Description: {obj.description}</p>
                                        <a
                                          onClick={() =>
                                            this.onAddEditDocumentClicked(obj)
                                          }
                                          className="link-page cursor-class"
                                        >
                                          {obj.isEditDoc == true
                                            ? "Edit Document"
                                            : "Add Document"}
                                        </a>
                                        <p className="timeline-time">
                                          {obj.duration}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="completed-box">
                    <fieldset className="dashboard-fieldset">
                      <legend className="dashboard-legend">
                        COMPLETED{" "}
                        <span className="status-count">
                          {totalMDSDocCompleted}
                        </span>
                      </legend>
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="status-all"
                            onClick={() => this.onViewListClick("Completed")}
                          >
                            <a className="">View All</a>
                          </div>
                          <div className="status-search mt-3">
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="text"
                                value={completedSearchWord}
                                id="example-search-input"
                                placeholder="Search"
                                onChange={this.onCompleteSearch}
                              />
                              <span className="input-group-append search-btn">
                                <button className="btn " type="button">
                                  <i className="fa fa-search"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="status-log-content">
                            <div className="timeline-box">
                              <div className="timeline">
                                {completedList == null
                                  ? null
                                  : completedList.map((obj, key) => (
                                    <div
                                      className="timeline-container"
                                      key={key}
                                    >
                                      <div className="timeline-icon green-bg">
                                        <img
                                          src="images/timeline-icon.png"
                                          className=""
                                          alt=""
                                        />
                                      </div>
                                      <div className="timeline-body">
                                        <h4 className="timeline-title">
                                          <span className="category-tag">
                                            {obj.addResendCompletedText}
                                          </span>
                                        </h4>
                                        <p>Client Name: {obj.clientName}</p>
                                        <p>Ship Name: {obj.shipName}</p>
                                        <p>PO Number: {obj.poNumber}</p>
                                        <p>Description: {obj.description}</p>
                                        <a
                                          onClick={() =>
                                            this.navigateTo(
                                              "/SupplierDocument"
                                            )
                                            // this.onViewCompletedDocumentClicked(
                                            //   obj
                                            // )
                                          }
                                          className="link-page"
                                        >
                                          View Document
                                        </a>
                                        <p className="timeline-time">
                                          {obj.duration}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {showDocumentUploadModal && (
          <SupplierDocumentModal
            onDocumentModalCloseClick={this.onDocumentModalCloseClick}
            supplierName={supplierName}
            supplierId={userId}
            TrackingToolId={TrackingToolId}
            onSaveMDSDOCDeclarationData={this.onSaveMDSDOCDeclarationData}
            selectedPendingItemsList={selectedPendingItemsList}
            mdsdocId={mdsdocId}
          />
        )}

        <Footer />
      </div>
    );
  }
}

export default withRouter(SupplierDashboard);
