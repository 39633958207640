import React, { Component } from "react";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import { helperText } from "../../helpers/helperText";
import {
  AddLocalStorageData,
  CheckIfUserHasAccess,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
  changePageTitle,
  withRouter,
} from "../../helpers";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Toast from "../../components/Toast";
import Dropdown from "../../components/Dropdown";
import MobileDropdown from "../../components/Mobile/MobileDropdown";
import DatePicker from "../../components/DatePickerComponent";
import DataTable from "react-data-table-component";
import { SupplierService } from "../../services/supplier.service";
import { UserLevel, UserRole, SITERIGHTS } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { NotificationService } from "../../services/notification.service";

var currentDate = new Date();
let loadRows = 3;
class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      userId: 0,
      defaultNotificationData: [],
      categoryList: [],
      notificationData: [],
      dropdown_category: "",
      filter_fromDate: "",
      filter_toDate: "",
      userTypeId: 0,
      totalrows: 0,
      prevpageval: 0,
      currentPage: 1,
      rowsPerPage: 20,
      isFilterSelected: false,
      resetPaginationToggle: false,
      filteredNotificationList: [],
      isClient: false,
      isSupplier: false,
      isShipCrew: false,
      viewButtonClicked: false,
      isClientUser: false,
      nextFromDate: "",
      previousToDate: currentDate,
      accessList: [],
      isAdminInternalLoggedIn: false,
      dropdown_readunread: "",
      readUnreadOptions: [
        { id: 1, value: 'Read' },
        { id: 2, value: 'Unread' },
      ],
    };
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name

    changePageTitle("");
    AddLocalStorageData("location", "");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          isClientUser: userData.typeId == UserRole.CLIENTUSER,
          isShipCrew: userData.typeId == UserRole.SHIPCREW,
          isSupplier: userData.typeId == UserRole.SUPPLIER,
          isClient: userData.typeId == UserRole.CLIENT,
          userId: userData.id,
          userTypeId: userData.typeId,
          isAdminInternalLoggedIn:
            userData.typeId == UserRole.ADMIN ||
            userData.typeId == UserRole.INTERNALUSER ||
            userData.typeId == UserRole.ACCOUNTS,
        },
        async () => {
          await this.getAllNotificationData();
        }
      );
    }
  }

  componentWillUnmount() {
    if (!this.state.viewButtonClicked) {
      AddLocalStorageData("location", "");
    }
  }

  getAllNotificationData = async () => {
    this.setState({ isLoading: true });
    if (this.state.userId > 0) {
      var response = await NotificationService.GetNotificationData(
        this.state.userId,
        this.state.userTypeId,
        true
      );
      if (response.status == 200) {
        const offset = (this.state.currentPage - 1) * this.state.rowsPerPage;
        this.setState(
          {
            notificationData: response.data.listNotificationData.slice(
              offset,
              offset + this.state.rowsPerPage
            ),
            defaultNotificationData: response.data.listNotificationData,
            categoryList: response.data.listNotificationCategory,
            totalrows: response.data.total,
            isLoading: false,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.Notification.fetchNotificationError,
          isLoading: false,
        });
      }
    }
  };

  navigateTo(path) {
    this.props.navigate(path);
  }

  onViewNotificationClick = async (
    notificationText,
    viewedData,
    notificationID
  ) => {
    AddLocalStorageData("location", "/ActivityLog");
    AddLocalStorageData("selectedClient", JSON.stringify(viewedData));
    var data = {
      notificationID: notificationID,
      userTypeId: this.state.userTypeId,
      userId: this.state.userId,
    };

    this.setState({ viewButtonClicked: true });

    var response = await NotificationService.SaveNotificationIsViewedStatus(
      data
    );
    if (response.status == 200) {
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }

    if (
      notificationText.includes("NEW_CLIENT_") ||
      notificationText.includes("CLIENT_STATUS")
    )
      this.navigateTo("/ManageClient");
    else if (
      notificationText.includes("NEW_SHIP_") ||
      notificationText.includes("SHIP_STATUS")
    )
      this.navigateTo("/ManageShips");
    else if (
      !this.state.isSupplier &&
      (notificationText.includes("NEW_SUPPLIER_") ||
        notificationText.includes("SUPPLIER_STATUS"))
    )
      this.navigateTo("/ManageSuppliers");
    else if (
      notificationText.includes("NEW_USER_") ||
      notificationText.includes("USER_STATUS")
    )
      this.navigateTo("/ManageUsers");
    else if (notificationText.includes("NEW_CLIENTUSER_")) {
      AddLocalStorageData("selectedClient", JSON.stringify(viewedData));
      this.navigateTo("/ManageClientUsers");
    } else if (
      (notificationText.includes("MD/SDoC") ||
        notificationText.includes("Declaration") ||
        notificationText.includes("PO_ADDED_CLIENT") ||
        notificationText.includes("CLIENT_PO_API_IMPORT") ||
        notificationText.includes("MANUAL_LINE_ITEM_ADDED")) &&
      !this.state.isSupplier
    ) {
      if (viewedData?.clientID != null && viewedData?.clientID != 0) {
        let clientData = {
          clientId: viewedData?.clientID,
          shipId: 0,
        };
        AddLocalStorageData("selectedShipTTool", JSON.stringify(clientData));
      }
      this.navigateTo("/TrackingTool");
    } else if (notificationText.includes("MD/SDoC") && this.state.isSupplier)
      this.navigateTo("/SupplierDocument");
    else if (
      notificationText.includes("TRACKING_TOOL_") &&
      !this.state.isSupplier
    ) {
      if (viewedData?.clientID != null && viewedData?.clientID != 0) {
        let clientData = {
          clientId: viewedData?.clientID,
          shipId: 0,
        };
        AddLocalStorageData("selectedShipTTool", JSON.stringify(clientData));
      }
      this.navigateTo("/TrackingTool");
    } else if (
      notificationText.includes("TRACKING_TOOL_") &&
      this.state.isSupplier
    )
      this.navigateTo("/SupplierDocument");
    else if (
      notificationText.includes("COMMUNICATION_") &&
      this.state.isAdminInternalLoggedIn
    )
      this.navigateTo("/Communication");
    else if (notificationText.includes("TRACKING_TOOL_") || notificationText.includes("EDIT_TRACKING_TOOL")) {
      if (viewedData?.clientID != null && viewedData?.clientID != 0) {
        let clientData = {
          clientId: viewedData?.clientID,
          shipId: 0,
        };
        AddLocalStorageData("selectedShipTTool", JSON.stringify(clientData));
      }
      this.navigateTo("/TrackingTool");
    } else if (notificationText.includes("COMMUNICATION_"))
      this.navigateTo("/Communication");
    else if ((notificationText.includes("Report")) || (notificationText.includes("REPORT"))) this.navigateTo("/Reports");
    else if (notificationText.includes("TRAINING")) {
      this.navigateTo(
        this.state.isAdminInternalLoggedIn ? "/Training" : "/TrainingDocument"
      );
    } else if (notificationText.includes("IHM")) {
      if (this.state.isAdminInternalLoggedIn)
        this.navigateTo("/ManageIHMDatabaseRegistry");
      else if (this.state.isClient) this.navigateTo("/ClientDocuments");
    } else if (notificationText.includes("MISC"))
      this.navigateTo("/MiscellaneousOperations");
    else if (notificationText.includes("Client") || notificationText.includes("CLIENT")) {
      AddLocalStorageData("selectedClient", viewedData.clientID);
      this.navigateTo("/AddEditClient");
    } else if (
      notificationText.includes("Supplier") ||
      notificationText.includes("SUPPLIER") ||
      (this.state.isSupplier && notificationText.includes("SUPPLIER_STATUS"))
    ) {
      const response = await SupplierService.GetManageSupplierData(
        viewedData.supplierID,
        0
      );

      if (response.data != null && response.data != undefined) {
        let supplierData = response.data.filter(
          (s) => s.supplierId === viewedData.supplierID
        );

        AddLocalStorageData(
          "selectedSupplier",
          JSON.stringify(supplierData[0])
        );
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.navigateTo("/AddEditSupplier");
    } else if (notificationText.includes("Ship")) {
      AddLocalStorageData("selectedShip", viewedData.shipID);
      this.navigateTo("/AddEditShip");
      // AddLocalStorageData("selectedClient", JSON.stringify(itemText));
      // AddLocalStorageData("selectedShip", shipId);
    } else if (notificationText.includes("Internal User") || notificationText.includes("Client User") || notificationText.includes("EDIT_USER")
      || notificationText.includes("Roles And Permissions") || notificationText.includes("IU_ASSIGNED_CLIENT") || notificationText.includes("IU_UNASSIGNED_CLIENT")
      || notificationText.includes("IU_ASSIGNED_SHIP") || notificationText.includes("IU_UNASSIGNED_SHIP")) {
      AddLocalStorageData("selectedUser", viewedData.internalUserID == 0 && (
        notificationText.includes("IU_ASSIGNED_CLIENT") || notificationText.includes("IU_UNASSIGNED_CLIENT")
        || notificationText.includes("IU_ASSIGNED_SHIP") || notificationText.includes("IU_UNASSIGNED_SHIP")) ? this.state.userId : viewedData.internalUserID);
      this.navigateTo("/AddUser");
      //this.navigateTo("/AddEditShip");
    } else if (notificationText.includes("Client User") || notificationText.includes("EDIT_CLIENT_USER")) {
      if (notificationText.includes("Document")) {
        if (this.state.isClientUser) {
          this.navigateTo("/ClientDocuments");
        } else {
          AddLocalStorageData("selectedClient", viewedData.clientID);
          this.navigateTo("/AddEditClient");
        }
      } else {
        AddLocalStorageData("selectedClientID", viewedData.clientID);
        if (
          viewedData?.text?.includes("sent a request to Add New Client User")
        ) {
          AddLocalStorageData("selectedClientUser", 0);
          AddLocalStorageData("selectedClientUserId", 0);
        } else {
          AddLocalStorageData("selectedClientUserId", viewedData.clientUserID);
          AddLocalStorageData("selectedClientUser", viewedData);
        }
        this.props.navigate("/AddEditClientUser");
      }
    } else if (notificationText.includes("Change Request")) {
      const { isClient } = this.state;
      let path = "";
      if (
        viewedData?.navigateTo?.includes("Client Details") ||
        viewedData?.navigateTo?.includes("Documents")
      ) {
        AddLocalStorageData("selectedClient", viewedData.clientID);
        path = "/AddEditClient";
      } else if (viewedData?.navigateTo?.includes("Ship Details")) {
        if (isClient) {
          let clientData = {
            clientId: viewedData.clientID,
            clientName: GetLocalStorageDataWithoutParse("loggedInUserName"),
          };
          AddLocalStorageData("selectedClient", JSON.stringify(clientData));
        } else {
          AddLocalStorageData("selectedClient", viewedData.clientID);
        }
        path = "/ManageShips";
      } else if (viewedData?.navigateTo?.includes("Tracking Tool")) {
        if (!isClient) {
          let clientData = {
            clientId: viewedData.clientID,
            shipId: 0,
          };
          AddLocalStorageData("selectedShipTTool", JSON.stringify(clientData));
        } else AddLocalStorageData("selectedClient", viewedData.clientID);

        path = "/TrackingTool";
      } else if (viewedData?.navigateTo?.includes("Reports")) {
        path = "/Reports";
      } else if (viewedData?.navigateTo?.includes("Manage Users")) {
        if (isClient) {
          let clientData = {
            clientId: viewedData.clientID,
            clientName: GetLocalStorageDataWithoutParse("loggedInUserName"),
          };
          AddLocalStorageData("selectedClient", JSON.stringify(clientData));
        }

        path = isClient ? "/ManageClientUsers" : "/ManageUsers";
      } else if (viewedData?.navigateTo?.includes("Training")) {
        path = isClient ? "/TrainingDocument" : "/Training";
      } else if (notificationText.includes("Roles And Permissions")) {
        if (viewedData?.navigateTo?.includes("Client Roles & Permissions")) {
          path = "/ManageClientRolesAndPermission";
        } else if (
          viewedData?.navigateTo?.includes("Manage Users Roles & Permissions")
        ) {
          path = "/ManageRolesAndPermission";
        }
      }

      if (path != "") {
        this.navigateTo(path);
      }
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  onDropdownSelect = (e) => {
    var { name, value } = e.target;

    if (name == "category") {
      this.setState(
        {
          [name]: value,
          currentPage: 1,
        },
        () => {
          this.filterGridView();
        }
      );
    }
    else {
      this.setState(
        {
          [name]: value,
          currentPage: 1,
        },
        () => {
          this.filterGridView();
        }
      );
    }

  };

  filterGridView = () => {
    const { dropdown_category, filter_fromDate, filter_toDate, dropdown_readunread } = this.state;

    const isread = dropdown_readunread === "1" ? true : null;

    var filterList = [];
    var notificationList = this.state.defaultNotificationData;

    if (
      (dropdown_category == "" || dropdown_category == "0") && (dropdown_readunread == "" || dropdown_readunread == "0") &&
      filter_fromDate == "" &&
      filter_toDate == ""
    )
      filterList = notificationList;
    else {
      if (
        dropdown_category != "" &&
        dropdown_category != "0" && dropdown_readunread != "" &&
        dropdown_readunread != "0" &&
        (filter_fromDate != "" || filter_toDate != "")
      ) {
        let fromDate = convertDate(new Date(filter_fromDate));
        let toDate = convertDate(new Date(filter_toDate));

        filterList =
          filterList.length > 0
            ? filterList.map((e) => ({
              notificationDateDisplay: e.notificationDateDisplay,
              notificationText: e.notificationText.filter((e2) => {
                let date = convertDate(new Date(e2.notificationDate));
                return (
                  JSON.parse(e2.categoryID) ===
                  JSON.parse(dropdown_category) &&
                  e2.isViewed == isread &&
                  new Date(date).getTime() >= new Date(fromDate).getTime() &&
                  new Date(date).getTime() <= new Date(toDate).getTime()
                );
              }),
            }))
            : notificationList.map((e) => ({
              notificationDateDisplay: e.notificationDateDisplay,
              notificationText: e.notificationText.filter((e2) => {
                let date = convertDate(new Date(e2.notificationDate));

                return (
                  JSON.parse(e2.categoryID) ===
                  JSON.parse(dropdown_category) &&
                  e2.isViewed == isread &&
                  new Date(date).getTime() >= new Date(fromDate).getTime() &&
                  new Date(date).getTime() <= new Date(toDate).getTime()
                );
              }),
            }));

        filterList = filterList.filter((e) => e.notificationText.length != 0);
      } else {
        if (dropdown_category != "" && dropdown_category != "0") {
          filterList =
            filterList.length > 0
              ? filterList.map((e) => ({
                notificationDateDisplay: e.notificationDateDisplay,
                notificationText: e.notificationText.filter((e2) => {
                  return (
                    JSON.parse(e2.categoryID) ===
                    JSON.parse(dropdown_category)
                  );
                }),
              }))
              : notificationList.map((e) => ({
                notificationDateDisplay: e.notificationDateDisplay,
                notificationText: e.notificationText.filter((e2) => {
                  return (
                    JSON.parse(e2.categoryID) ===
                    JSON.parse(dropdown_category)
                  );
                }),
              }));

          filterList = filterList.filter((e) => e.notificationText.length != 0);
        }

        if (dropdown_readunread != "" && dropdown_readunread != "0") {
          filterList =
            filterList.length > 0
              ? filterList.map((e) => ({
                notificationDateDisplay: e.notificationDateDisplay,
                notificationText: e.notificationText.filter((e2) => {
                  return (
                    e2.isViewed == isread
                  );
                }),
              }))
              : notificationList.map((e) => ({
                notificationDateDisplay: e.notificationDateDisplay,
                notificationText: e.notificationText.filter((e2) => {
                  return (
                    e2.isViewed === isread
                  );
                }),
              }));

          filterList = filterList.filter((e) => e.notificationText.length != 0);
        }

        if (filter_fromDate != "" && filter_toDate != "") {
          let fromDate = convertDate(new Date(filter_fromDate));
          let toDate = convertDate(new Date(filter_toDate));

          filterList =
            filterList.length > 0
              ? filterList.map((e) => ({
                notificationDateDisplay: e.notificationDateDisplay,
                notificationText: e.notificationText.filter((e2) => {
                  let date = convertDate(new Date(e2.notificationDate));
                  return (
                    new Date(date).getTime() >=
                    new Date(fromDate).getTime() &&
                    new Date(date).getTime() <= new Date(toDate).getTime()
                  );
                }),
              }))
              : notificationList.map((e) => ({
                notificationDateDisplay: e.notificationDateDisplay,
                notificationText: e.notificationText.filter((e2) => {
                  let date = convertDate(new Date(e2.notificationDate));

                  return (
                    new Date(date).getTime() >=
                    new Date(fromDate).getTime() &&
                    new Date(date).getTime() <= new Date(toDate).getTime()
                  );
                }),
              }));

          filterList = filterList.filter((e) => e.notificationText.length != 0);
        }
      }
    }

    const offset = (this.state.currentPage - 1) * this.state.rowsPerPage;

    this.setState((prevState) => ({
      notificationData: filterList.slice(
        offset,
        offset + this.state.rowsPerPage
      ),
      filteredNotificationList: filterList,
      totalrows: filterList.length,
      isLoading: false,
      isFilterSelected: true,
      resetPaginationToggle: !prevState.resetPaginationToggle,
    }));
  };

  //HV - 24/03/2023 - handled from date should be greater than to date

  onDateChanged = (id, e) => {
    if (id == "fromDate") {
      var nextFromDate = new Date(e);
      nextFromDate.setDate(e.getDate());
      nextFromDate.toLocaleDateString();
      this.setState(
        { filter_fromDate: e, currentPage: 1, nextFromDate: nextFromDate },
        () => {
          this.filterGridView();
        }
      );
    } else if (id == "toDate") {
      var previousToDate = new Date(e);
      previousToDate.setDate(e.getDate() - 1);
      previousToDate.toLocaleDateString();
      this.setState(
        { filter_toDate: e, currentPage: 1, previousToDate: previousToDate },
        () => {
          this.filterGridView();
        }
      );
    }
  };

  //HV - 09/03/2023 - handled navigation for ship crew
  goback = () => {
    const { isClient, isSupplier, isShipCrew, isClientUser } = this.state;
    if (isClient || isClientUser) this.props.navigate("/ClientDashboard");
    else if (isSupplier) this.props.navigate("/SupplierDashboard");
    else if (isShipCrew) this.props.navigate("/ShipCrewDashboard");
    else this.props.navigate("/Dashboard");
  };

  getNotificationColumns = [
    {
      width: "100%",
      selector: "notificationID",
      cell: (row) => (
        <div className="row">
          <div className="col-12">
            <div className=" dates">
              <p className="action-required-date">
                {row.notificationDateDisplay}
              </p>
            </div>
            {row.notificationText.map((itemText, i) => {
              return (
                <div className="action-data" key={i}>
                  <div className="role">
                    {itemText.categoryName}
                    {itemText.isViewed == 1 ? (
                      <img src="./images/grey-bulb.png" alt="" />
                    ) : (
                      <img src="./images/orange-bulb.png" alt="" />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-10 content">
                      {itemText.text}
                    </div>
                    <div className=" col-12 col-lg-2">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.onViewNotificationClick(
                            itemText.archiveActionName,
                            itemText,
                            itemText.notificationID
                          )
                        }
                      >
                        {this.state.accessList != null
                          ? CheckIfUserHasAccess(
                            this.state.userTypeId,
                            this.state.accessList,
                            itemText.archiveActionName,
                            itemText.navigateTo
                          )
                          : "View"}
                      </a>
                    </div>
                  </div>
                  <div className="hours">{itemText.notificationDuration}</div>
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      ),
      ignoreRowClick: true,
      center: false,
    },
  ];

  handlePageChange = async (page) => {
    this.setState({ isLoading: true });
    if (this.state.userId > 0) {
      this.setState({
        prevpageval: (page - 1) * this.state.rowsPerPage,
        currentPage: page,
      });

      const offset = (page - 1) * this.state.rowsPerPage;
      this.setState(
        {
          notificationData:
            this.state.isFilterSelected == false
              ? this.state.defaultNotificationData.slice(
                offset,
                offset + this.state.rowsPerPage
              )
              : this.state.filteredNotificationList.slice(
                offset,
                offset + this.state.rowsPerPage
              ),
        },
        async () => { }
      );
    }
    this.setState({ isLoading: false });
  };

  clearAllFilter = () => {
    this.setState(
      {
        dropdown_category: "",
        filter_fromDate: "",
        filter_toDate: "",
        dropdown_readunread: "",
        currentPage: 1,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      notificationData,
      defaultNotificationData,
      categoryList,
      dropdown_category,
      filter_fromDate,
      filter_toDate,
      nextFromDate,
      previousToDate,
      dropdown_readunread,
      readUnreadOptions
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <div className="container ">
            <div className="actionRequired-admin">
              <div className="action-required">
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                    <img
                      style={{ height: "22px" }}
                      className="back-btn-responsive action-required-back-button"
                      src="images/back button image.png"
                      alt="Back"
                      onClick={this.goback}
                    />
                    <span className="title-font">
                      {" "}
                      <h5>&nbsp; Activity log</h5>
                    </span>
                  </div>
                </div>
              </div>
              <div className="accordion-filter-dropdown action-required-filter">
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="filter-box filter-flex-item form-label">
                          <MobileDropdown
                            dropdownLabel="Category:"
                            name="category"
                            onChange={this.onDropdownSelect}
                            selected={dropdown_category}
                          >
                            {categoryList == null
                              ? null
                              : categoryList.map((obj, key) => (
                                <option
                                  key={key}
                                  value={obj.notificationCategoryId}
                                >
                                  {obj.text}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="filter-box filter-flex-item form-label">
                          <MobileDropdown
                            dropdownLabel="Read/Unread:"
                            name="readunread"
                            onChange={this.onDropdownSelect}
                            selected={dropdown_readunread}
                          >
                            {readUnreadOptions == null
                              ? null
                              : readUnreadOptions.map((obj, key) => (
                                <option
                                  key={obj.id}
                                  value={obj.id}
                                >
                                  {obj.value}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="filter-date-from">
                          <label
                            htmlFor="exampleDataList"
                            className="form-label"
                          >
                            Date:
                          </label>
                          <span className="from-to-box">
                            <span className="from-date">
                              <label>From:</label>
                              <DatePicker
                                selectedDate={filter_fromDate}
                                onDateChange={(e) =>
                                  this.onDateChanged("fromDate", e)
                                }
                                maxDate={previousToDate}
                              >
                                {" "}
                              </DatePicker>
                            </span>
                            <span className="to-date">
                              <label>To:</label>
                              <DatePicker
                                selectedDate={filter_toDate}
                                onDateChange={(e) =>
                                  this.onDateChanged("toDate", e)
                                }
                                minDate={nextFromDate}
                                maxDate={currentDate}
                              >
                                {" "}
                              </DatePicker>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action-required-filter desktop-class">
                <div className="supplier-filter">
                  <div className="row">
                    <div className="col-lg-12 filter-flex-container wrap">
                      <div
                        className="filter-box filter-flex-item form-label"
                        id={"category"}
                      >
                        <Dropdown
                          dropdownLabel="Category:"
                          name="category"
                          onChange={this.onDropdownSelect}
                          selected={dropdown_category}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {categoryList == null
                            ? null
                            : categoryList.map((obj) => (
                              <option
                                key={obj.notificationCategoryId}
                                value={obj.notificationCategoryId}
                              >
                                {obj.text}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_category > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"category"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {categoryList != null &&
                              categoryList.filter(
                                (x) =>
                                  x.notificationCategoryId == dropdown_category
                              )[0]?.text}
                          </UncontrolledTooltip>
                        ) : null}
                      </div>
                      <div
                        className="filter-box filter-flex-item form-label"
                        id={"readunread"}
                      >
                        <Dropdown
                          dropdownLabel="Read/Unread:"
                          name="readunread"
                          onChange={this.onDropdownSelect}
                          selected={dropdown_readunread}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {readUnreadOptions == null
                            ? null
                            : readUnreadOptions.map((obj) => (
                              <option
                                key={obj.id}
                                value={obj.id}
                              >
                                {obj.value}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_readunread > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"readunread"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {readUnreadOptions != null &&
                              readUnreadOptions.filter(
                                (x) =>
                                  x.id == dropdown_readunread
                              )[0]?.value}
                          </UncontrolledTooltip>
                        ) : null}
                      </div>
                      <div className="filter-date-from">
                        <label htmlFor="exampleDataList" className="form-label">
                          Date:
                        </label>
                        <span className="from-to-box">
                          <span className="from-date">
                            <label>From:</label>
                            <DatePicker
                              selectedDate={filter_fromDate}
                              onDateChange={(e) =>
                                this.onDateChanged("fromDate", e)
                              }
                              maxDate={previousToDate}
                            >
                              {" "}
                            </DatePicker>
                          </span>
                          <span className="to-date">
                            <label>To:</label>
                            <DatePicker
                              selectedDate={filter_toDate}
                              onDateChange={(e) =>
                                this.onDateChanged("toDate", e)
                              }
                              minDate={nextFromDate}
                              maxDate={currentDate}
                            >
                              {" "}
                            </DatePicker>
                          </span>
                        </span>
                      </div>

                      <div className="col-12 col-md-2 col-lg-2 desktop-clear-btn clear-all-btn">
                        <button
                          type="button"
                          className="btn btn-blue-bg"
                          onClick={this.clearAllFilter}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              <div
                className="action-required-datatable activitylog-footer"
                style={{ display: "block" }}
              >
                <DataTable
                  columns={this.getNotificationColumns}
                  data={notificationData}
                  highlightOnHover={false}
                  pointerOnHover={false}
                  noHeader={true}
                  noDataComponent={isLoading ? null : <div>No data to display.</div>}
                  noTableHead={true}
                  overflowY={true}
                  style={{ backgroundColor: "transparent", height: "100%" }}
                  pagination
                  paginationServer
                  paginationPerPage={20}
                  paginationTotalRows={this.state.totalrows}
                  paginationComponentOptions={{
                    rowsPerPageText: "",
                    rangeSeparatorText: "of",
                    noRowsPerPage: true,
                  }}
                  onChangePage={this.handlePageChange}
                  paginationResetDefaultPage={this.state.resetPaginationToggle}
                />
              </div>

              {/*  */}
            </div>
          </div>
        </main>

        <Footer />
      </>
    );
  }
}

export default withRouter(ActivityLog);
