import React, { Component } from "react";
import DatePickerComponent from "../../components/DatePickerComponent";
import DatePicker from "../../components/DatePickerComponent";
import DeleteModal from "../../components/DeleteModel";
import Dropdown from "../../components/Dropdown";
import Toast from "../../components/Toast";
import { UncontrolledTooltip } from "reactstrap";

const validFileFormatsExt = ["pdf,PDF,xlsx,xls"];
const validFileFormatsReadableExt = ["xlsx,xls"];
const validFileFormats =
  "application/pdf,application/PDF";
const validFileFormatsReadable =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
import {
  generateRandomString,
  helperText,
  isEmptyObject,
  isValidFile,
} from "../../helpers";
import {
  convertDate,
  convertPOMinMaxDate, isStringEmpty
} from "../../helpers/string-helper";
const NUMBER_REGEX = /^[0-9]{0,9}$/;
const InputField = (props) => {
  return (
    <div className="col-12 col-sm-6  col-lg-4 PO-data ">
      <label htmlFor="" className="required-field">
        {props.label}
      </label>
      <div className={!isStringEmpty(props.error) ? "invalid-position" : ""}>
        <input
          autoFocus={false}
          autoComplete={"off"}
          type={props.type}
          className="form-control"
          name={props.name}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
        />
        {!isStringEmpty(props.error) && <ErrorView errorFor="emptyText" />}
      </div>
    </div>
  );
};

const DateField = (props) => {
  return (
    <div className="col-12 col-sm-6  col-lg-4 PO-data ">
      <label htmlFor="" className="required-field">
        {props.label}
      </label>
      <div className={!isStringEmpty(props.error) ? "invalid-position" : ""}>
        <DatePickerComponent
          name={props.name}
          selectedDate={props.selectedDate ? new Date(props.selectedDate) : null}
          onDateChange={props.onDateChange}
          isNotRoot={true}
          minDate={props.minDate}
          maxDate={props.maxDate}
        >
          {" "}
        </DatePickerComponent>
        {!isStringEmpty(props.error) && <ErrorView errorFor="emptyText" />}
      </div>
    </div>
  );
};

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon-modal"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

export default class UploadPOFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInputKey: "",
      filePath: "",
      validateError: false,
      validateFileError: false,
      fileName: "",
      errorMessages: {},
      unreadablePODataList: [],
      dropdown_fileProperty: 0,
      dropdown_fileType: 0,
      unreadableErrorMessages: [],
      showDeleteModal: false,
      rowIdToDelete: 0,
      filePropertyError: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      minDate: new Date(),
      maxDate: new Date()
    };
  }

  componentDidMount = () => {
    //Year min max date setting    
    const { yearList, yearFilter, clientId } = this.props;
    if (yearFilter != 0 && yearFilter != null) {
      var yearListData = yearList.filter(
        (x) => x.value == yearFilter && x.parentValue == clientId );

      var currentDate = convertDate(new Date());
      let contractStartDate = new Date(yearListData[0]?.contractStartDate);

      let minPoDate = convertPOMinMaxDate(
        yearListData[0]?.contractStartDate,
        yearFilter,
        0
      );

      var endYear = contractStartDate.getFullYear() + 1;
      let maxPoDate = convertPOMinMaxDate(
        yearListData[0]?.contractStartDate,
        endYear,
        1
      );
      this.setState({ minPoDate: minPoDate, maxPoDate: maxPoDate });
    }


  }

  onUploadClick = () => {
    var { dropdown_fileType, dropdown_fileProperty, POFile } = this.state;
    const { onUploadClick } = this.props;
    var hasErrors = this.validateFields();

    if (hasErrors) return;

    if (dropdown_fileProperty == "readable" && dropdown_fileType == "excel") {
      hasErrors = this.validateFields();
      if (hasErrors) return;
      const data = {
        dropdown_filetype: dropdown_fileType,
        dropdown_fileProperty: dropdown_fileProperty,
        POFile: POFile,
      };
      onUploadClick(data);
    } else if (dropdown_fileProperty == "unreadable") {
      hasErrors = this.validateUnreadableFields();

      if (hasErrors) return;
      var { unreadablePODataList } = this.state;
      const data = {
        dropdown_filetype: dropdown_fileType,
        dropdown_fileProperty: dropdown_fileProperty,
        unreadablePODataList: unreadablePODataList,
      };
      onUploadClick(data);
    }
  };

  /* <summary>
        date: 16/02/2023
        Name: HV
        description: validating unreadable file
        <summary> */

  validateUnreadableFields = () => {
    let hasErrors = false;
    const {
      dropdown_fileProperty,
      unreadablePODataList,
      errorMessages,
      dropdown_fileType,
      filePropertyError,
    } = this.state;
    let errors = errorMessages;

    errors["errorFileType"] =
      dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";
    errors["errorFileProperty"] =
      dropdown_fileProperty == undefined ||
        dropdown_fileProperty == 0 ||
        filePropertyError
        ? "true"
        : "";

    if (dropdown_fileProperty == "unreadable") {
      var errorArray = [];

      unreadablePODataList.map((item) => {
        var arrayInternal = [];
        arrayInternal.push({
          errorId: "errorShip",
          value:
            item.dropdown_modalShip == undefined || item.dropdown_modalShip == 0
              ? "true"
              : "",
        });
        arrayInternal.push({
          errorId: "errorSupplier",
          value:
            // item.dropdown_modalSupplier == undefined ||
            // item.dropdown_modalSupplier == 0
            //   ? "true"
            //   :
            "",
        });
        arrayInternal.push({
          errorId: "errorPONumber",
          value: item.poNumber == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorTotalPOItems",
          value: item.totalPOItems == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorTotalSuspectedHazmatItems",
          value: item.totalSuspectedHazmatItems == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorCustomFileName",
          value: /* item.customFileName == "" ? "true" : */ "",
        });
        arrayInternal.push({
          errorId: "errorPODate",
          value: item.poDate == null || item.poDate == "" ? "true" : "",
        });
        arrayInternal.push({
          errorId: "errorPOFile",
          value: item.poFile == null ? "true" : "",
        });

        errorArray.push(arrayInternal);
        return arrayInternal;
      });
      if (
        errors["errorFileType"] != "" ||
        errors["errorFileProperty"] != "" ||
        errorArray.some((t) => t.some((o) => o.value == "true"))
      )
        hasErrors = true;
    }

    this.setState({
      validateError: hasErrors,
      unreadableErrorMessages: errorArray,
      errorMessages: errors,
      validateFileError: false,
      unreadablePODataList: unreadablePODataList,
    });
    return hasErrors;
  };

  //HV - 17/02/2023 - validation handled

  validateFields = () => {
    var {
      dropdown_fileType,
      dropdown_fileProperty,
      POFile,
      errorMessages,
      fileName,
      validateFileError,
      filePropertyError,
    } = this.state;
    var hasErrors = false;
    var correctFile = false;
    let errors = errorMessages;

    errors["errorFileType"] =
      dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";
    errors["errorFileProperty"] =
      dropdown_fileProperty == undefined ||
        dropdown_fileProperty == 0 ||
        filePropertyError
        ? "true"
        : "";
    errors["errorPOFile"] =
      (dropdown_fileProperty == "readable" ||
        errors["errorFileProperty"] != "") &&
        (POFile == undefined || POFile == null || validateFileError)
        ? "true"
        : "";

    if (
      errors["errorFileType"] != "" ||
      errors["errorFileProperty"] != "" ||
      errors["errorPOFile"] != ""
    ) {
      hasErrors = true;
    } else {
      if (dropdown_fileType == "excel") {
        correctFile =
          fileName.includes("xlsx") || fileName.includes("xls") ? true : false;
      } else if (dropdown_fileType == "pdf") {
        correctFile = fileName.includes("pdf") ? true : false;
      }
    }

    this.setState({
      validateError: hasErrors,
      errorMessages: errors,
      validateFileError: false,
    });
    return hasErrors;
  };

  //HV - 17/02/2023 - validation was not throwing for incorrect file

  onFileUpload = async (e) => {
    const { name, value } = e.target;
    var {
      dropdown_fileType,
      dropdown_fileProperty,
      errorMessages,
      filePropertyError,
    } = this.state;
    let errors = errorMessages;
    var correctFile = false;

    let files = e.target.files[0];
    if (files != undefined && files != null) {
      errors["errorFileType"] =
        dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";
      errors["errorFileProperty"] =
        dropdown_fileProperty == undefined ||
          dropdown_fileProperty == 0 ||
          filePropertyError
          ? "true"
          : "";

      if (errors["errorFileType"] != "" || errors["errorFileProperty"] != "") {
        this.setState({
          fileInputKey: generateRandomString,
          [name]: null,
          validateFileError: false,
          validateError: true,
          errorMessages: errors,
        });
        return;
      }

      if (dropdown_fileType == "excel") {
        correctFile =
          files.name.includes("xlsx") || files.name.includes("xls")
            ? true
            : false;
      } else if (dropdown_fileType == "pdf") {
        if (dropdown_fileProperty == "unreadable")
          correctFile = files.name.includes("pdf") ? true : false;
      }
      if (correctFile) {
        if (
          !isValidFile(
            e.target.value,
            dropdown_fileProperty == "readable"
              ? validFileFormatsReadableExt
              : validFileFormatsExt
          )
        ) {
          if (this.state.filePath == "") {
            this.setState({
              fileInputKey: generateRandomString,
              [name]: null,
              validateFileError: false,
              fileName: "",
              validateError: false,
            });
          }
        } else {
          errors["errorPOFile"] = "";
          this.setState({
            [name]: files,
            fileName: files.name,
            filePath: value,
            validateFileError: false,
            validateError: false,
          });
        }
      } else {
        this.setState({
          validateFileError: true,
          validateError: false,
          fileName: "",
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: validFileFormatsReadableExt ? helperText.TrackingTool.wrongExcelFile : helperText.TrackingTool.wrongPOFileTpePDFError,
        });
      }
    }
  };

  onDropdownChange = (e) => {
    const { name, value } = e.target;
    var unreadablePODataList = [];
    let wrongFileProperty = false;

    this.setState(
      {
        [name]: value, unreadableErrorMessages: []
      },
      () => {
        const { dropdown_fileProperty, errorMessages, dropdown_fileType } = this.state;
        if (name == "dropdown_fileProperty" && (dropdown_fileType != undefined || dropdown_fileType != 0)) {
          if (dropdown_fileType == "pdf" && value == "readable") {
            wrongFileProperty = true;
          }
          errorMessages["errorFileProperty"] = dropdown_fileProperty == undefined || dropdown_fileProperty == 0 || wrongFileProperty == true ? "true" : "";
        }
        else if (name == "dropdown_fileType") {
          if (dropdown_fileProperty != undefined || dropdown_fileProperty != 0) {
            if (value == "pdf" && dropdown_fileProperty == "readable") {
              wrongFileProperty = true;
            }
            errorMessages["errorFileProperty"] = wrongFileProperty == true ? "true" : "";
          }

          errorMessages["errorFileType"] = dropdown_fileType == undefined || dropdown_fileType == 0 ? "true" : "";
        }

        if (dropdown_fileProperty == "unreadable") {
          if (
            errorMessages["errorFileType"] == "" &&
            errorMessages["errorFileProperty"] == ""
          ) {
            errorMessages["errorPOFile"] = "";

            this.setState({ validateError: false });
          }
          unreadablePODataList = this.addNewRow(1, unreadablePODataList);
        }
        this.setState({
          unreadablePODataList,
          errorMessages: errorMessages,
          filePropertyError: wrongFileProperty,
        });
      }
    );
  };

  addNewRow = (rowId, unreadablePODataList) => {
    const { dropdownList, clientId } = this.props;

    var addRow = {
      rowId: rowId,
      shipList: dropdownList.shipList.filter((x) => x.parentValue == clientId),
      dropdown_modalShip: 0,
      poNumber: "",
      totalPOItems: "",
      totalSuspectedHazmatItems: "",
      poDate: "",
      customFileName: "",
      supplierList: dropdownList.supplierList,
      dropdown_modalSupplier: 0,
      poFile: null,
      poFileName: "",
      poFilePath: "",
      poFileInputKey: generateRandomString,
      fileInputKey: generateRandomString,
    };
    unreadablePODataList.push(addRow);
    return unreadablePODataList;
  };

  onRowDataChange = (rowId, e) => {
    var { unreadablePODataList, validateError } = this.state;
    var { name, value } = e.target;

    var rowIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);
    if (rowIndex > -1) {
      if (name === "totalSuspectedHazmatItems" || name === "totalPOItems") {
        if (!NUMBER_REGEX.test(value)) {
          return;
        }
      }
      unreadablePODataList[rowIndex][name] = value;
      this.setState({ unreadablePODataList }, () => {
        if (validateError) this.validateUnreadableFields();
      });
    }
  };

  onRowDateChanged = (rowId, e) => {

    var { unreadablePODataList, validateError } = this.state;
    var rowIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);

    if (rowIndex > -1) {
      unreadablePODataList[rowIndex].poDate = e;
      this.setState({ unreadablePODataList }, () => {
        if (validateError) this.validateUnreadableFields();
      });
    }
  };

  onRowFileUpload = async (e, rowId) => {
    const { name, value } = e.target;
    var {
      dropdown_fileProperty,
      unreadablePODataList,
      validateError,
      dropdown_fileType,
    } = this.state;
    var correctFile = false;

    let files = e.target.files[0];
    if (dropdown_fileType == "excel") {
      correctFile =
        files.name.includes("xlsx") || files.name.includes("xls")
          ? true
          : false;
    } else if (dropdown_fileType == "pdf") {
      correctFile = files.name.includes("pdf") ? true : false;
    }

    var rowIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);
    if (correctFile) {
      if (
        !isValidFile(
          value,
          dropdown_fileProperty == "readable"
            ? validFileFormatsReadableExt
            : validFileFormatsExt
        )
      ) {
        if (this.state.filePath == "") {
          if (rowIndex > -1) {
            unreadablePODataList[rowIndex][name] = null;
            unreadablePODataList[rowIndex].poFileName = files.name;
            (unreadablePODataList[rowIndex].poFileInputKey =
              generateRandomString),
              this.setState(
                {
                  validateFileError: false, unreadablePODataList,
                },
                () => {
                  if (validateError) this.validateUnreadableFields();
                }
              );
          }
        }
        return;
      }

      if (rowIndex > -1) {
        unreadablePODataList[rowIndex][name] = files;
        unreadablePODataList[rowIndex].poFileName = files.name;
        unreadablePODataList[rowIndex].poFilePath = value;
        this.setState(
          { validateFileError: false, unreadablePODataList },
          () => {
            if (validateError) this.validateUnreadableFields();
          }
        );
      }
    } else {
      this.setState({
        validateFileError: true,
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.TrackingTool.wrongPOFileTpePDFError,
      });
    }
  };

  onAddRowClick = () => {
    var { unreadablePODataList } = this.state;
    var lastInsertedRowId = 0;
    if (unreadablePODataList.length > 0) {
      lastInsertedRowId =
        unreadablePODataList[unreadablePODataList.length - 1].rowId;
    }
    unreadablePODataList = this.addNewRow(
      lastInsertedRowId + 1,
      unreadablePODataList
    );
    this.setState({ unreadablePODataList: unreadablePODataList });
  };

  //HV - 16/02/2023 - handled closing delete modal and resetting dropdown property field
  onDeleteRowClick = (rowId) => {
    var { unreadablePODataList, dropdown_fileProperty, validateError } =
      this.state;
    if (unreadablePODataList.length > 0) {
      var itemIndex = unreadablePODataList.findIndex((t) => t.rowId == rowId);
      if (itemIndex > -1) unreadablePODataList.splice(itemIndex, 1);
      this.setState({
        unreadablePODataList,
        dropdown_fileProperty:
          unreadablePODataList.length == 0 ? 0 : dropdown_fileProperty,
        showDeleteModal: false,
        validateError: unreadablePODataList.length == 0 ? false : validateError,
      });
    }
  };

  /* <summary>
          date: 16/02/2023
          Name: HV
          description: delete modal yes click.
          <summary> */

  onDeleteModalYesClick = () => {
    this.onDeleteRowClick(this.state.rowIdToDelete);
  };

  /* <summary>
            date: 16/02/2023
             Name: HV
             description: delete modal close.
             <summary> */

  onDeleteModalCloseClick = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  /* <summary>
        date: 16/02/2023
         Name: HV
         description: open delete modal.
         <summary> */

  onDeleteModalOpenClick = (id) => {
    this.setState({
      showDeleteModal: true,
      rowIdToDelete: id,
    });
  };


  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };

  render() {
    const { onCloseClick } = this.props;
    const {
      fileInputKey,
      validateError,
      validateFileError,
      dropdown_fileProperty,
      fileName,
      unreadablePODataList,
      unreadableErrorMessages,
      errorMessages,
      showDeleteModal,
      filePropertyError,
      toastVisible,
      toastMessage,
      toastType,
      dropdown_fileType,
      minPoDate,
      maxPoDate
    } = this.state;

    return (
      <div className="upload-po-list" style={{ display: "block" }}>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload PO List</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick("showUploadModal", false)}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="PO-list-text">
                  <p> Select the PO list you want to upload</p>
                </div>
                <div className="PO-list-topLabel">
                  <div className="row">
                    <div className=" col-sm-6 col-lg-5">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorFileType)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <label className="required-field">File Type:</label>
                        <Dropdown
                          // dropdownLabel="File Type:"
                          name="fileType"
                          onChange={this.onDropdownChange}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          <option value="pdf">PDF</option>
                          <option value="excel">Excel</option>
                        </Dropdown>
                        {!isStringEmpty(errorMessages.errorFileType) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                    <div className=" col-sm-6  col-lg-5">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorFileProperty)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <label className="required-field">File Property:</label>
                        <Dropdown
                          // dropdownLabel="File Property:"
                          name="fileProperty"
                          onChange={this.onDropdownChange}
                          selected={dropdown_fileProperty}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          <option value="readable">Readable</option>
                          <option value="unreadable">Unreadable</option>
                        </Dropdown>
                        {!isStringEmpty(errorMessages.errorFileProperty) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                  </div>
                  {dropdown_fileType == "excel" && dropdown_fileProperty == "readable" ? (
                    <div className="row">
                      <div className="col-12 col-sm-6  col-lg-8">
                        <div className="choosefile-btn">
                          <label
                            htmlFor="myfile"
                            className={
                              "attach-btn attach-btn-responsive chs-btn " +
                              (!isStringEmpty(errorMessages.errorPOFile)
                                ? "invalid-position-border"
                                : "")
                            }
                          >
                            <img
                              className="upload-img"
                              src="images/Icon awesome-upload.png"
                              width="18px"
                            />
                            &nbsp;Choose File
                          </label>
                          {!isStringEmpty(errorMessages.errorPOFile) && (
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                              className="invalid-position-img"
                            />
                          )}
                          <label className="required-field">
                            {" "}
                            &nbsp; (Please select an pdf, excel file)
                          </label>
                          {/*  &nbsp; (Please select an Excel file)*/}
                          <input
                            type="file"
                            accept={validFileFormatsReadable}
                            id="myfile"
                            name="POFile"
                            //onChange={this.onFileUpload}
                            key={fileInputKey || ""}
                            style={{ display: "none", visibility: "none" }}
                            onChange={(event) => {
                              this.onFileUpload(event);
                              event.target.value = null;
                            }}
                          />
                          <label className="label-breakword">&nbsp;&nbsp;&nbsp;{fileName}</label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {dropdown_fileProperty == "unreadable" && unreadablePODataList.map((item, i) => {
                        let unreadableListLength =
                          unreadableErrorMessages.length;
                        var errorObj =
                          i >= unreadableListLength
                            ? {}
                            : unreadableErrorMessages[i];
                        return (
                          <div key={item.rowId} className="PO-list-upperBlock">
                            <div className="close-btn">
                              <img
                                className="cross-btn"
                                src="./images/Icon ionic-ios-close.png"
                                alt="cancel"
                                style={{
                                  pointerEvents: "all",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.onDeleteModalOpenClick(item.rowId)
                                }
                              />
                            </div>
                            <div className="row">
                              <div className="col-12 col-sm-6  col-lg-4 PO-data">
                                <div
                                  className={
                                    !isStringEmpty(
                                      isEmptyObject(errorObj)
                                        ? ""
                                        : errorObj[0].value
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                  id={"shipName"}
                                >
                                  <label className="required-field">
                                    Ship Name:
                                  </label>
                                  <Dropdown
                                    //  dropdownLabel="Ship Name:"
                                    name="modalShip"
                                    onChange={(e) =>
                                      this.onRowDataChange(item.rowId, e)
                                    }
                                    selected={item.dropdown_modalShip}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.shipList == null
                                      ? null
                                      : item.shipList.map((obj, key) => (
                                        <option key={key} value={obj.value}>
                                          {obj.label}
                                        </option>
                                      ))}
                                  </Dropdown>
                                  {item.dropdown_modalShip != "" ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"shipName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="right"
                                      style={{ width: "350px" }}
                                    >
                                      {item.shipList != null && item.shipList.filter(x => x.value == item.dropdown_modalShip)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                  {!isStringEmpty(
                                    isEmptyObject(errorObj)
                                      ? ""
                                      : errorObj[0].value
                                  ) && <ErrorView errorFor="emptyDropdown" />}
                                </div>
                              </div>
                              <InputField
                                type={"text"}
                                label="PO Number:"
                                name="poNumber"
                                value={item.poNumber}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[2].value
                                }
                              />
                              <InputField
                                type={"text"}
                                label="Number of PO Items:"
                                name="totalPOItems"
                                value={item.totalPOItems}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[3].value
                                }
                              />
                              <InputField
                                type={"text"}
                                label="Total Suspected Hazmat Items:"
                                name="totalSuspectedHazmatItems"
                                value={item.totalSuspectedHazmatItems}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[4].value
                                }
                              />

                              <DateField
                                label="PO Date:"
                                name="poDate"
                                selectedDate={item.poDate}
                                onDateChange={(e) =>
                                  this.onRowDateChanged(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[6].value
                                }
                                minDate={new Date(minPoDate)}
                                maxDate={new Date(maxPoDate)}

                              />

                              {/* <div className="row">
                                <div className="col-xl-6 col-lg-4 align-self-class">
                                  <label>
                                    PO Date:
                                  </label>
                                </div>
                                <DatePicker
                                  min="0"
                                  selectedDate={
                                    item.poDate
                                      ? new Date(item.poDate)
                                      : null
                                  }
                                  onDateChange={(e) =>
                                    this.onRowDateChanged(item.rowId, e)
                                  }
                                  disabled={true}
                                >
                                  {" "}
                                </DatePicker>
                              </div> */}
                              {/* <InputField
                                label="Custom File Name:"
                                name="customFileName"
                                value={item.customFileName}
                                onChange={(e) =>
                                  this.onRowDataChange(item.rowId, e)
                                }
                                error={
                                  isEmptyObject(errorObj)
                                    ? ""
                                    : errorObj[5].value
                                }
                              /> */}
                              <div className="col-12 col-sm-6  col-lg-4 PO-data">
                                <div id={"supName"}>
                                  <label>Supplier Name:</label>
                                  <Dropdown
                                    //dropdownLabel="Supplier Name:"
                                    name="modalSupplier"
                                    onChange={(e) =>
                                      this.onRowDataChange(item.rowId, e)
                                    }
                                    selected={item.dropdown_modalSupplier}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.supplierList == null
                                      ? null
                                      : item.supplierList.map((obj, key) => (
                                        <option key={key} value={obj.value}>
                                          {obj.label}
                                        </option>
                                      ))}
                                  </Dropdown>
                                  {item.dropdown_modalSupplier != "" ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"supName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="right"
                                      style={{ width: "350px" }}
                                    >
                                      {item.supplierList != null && item.supplierList.filter(x => x.value == item.dropdown_modalSupplier)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6  col-lg-8 PO-data">
                                <div className="choosefile-unreadable-btn">
                                  <input
                                    type="file"
                                    accept={dropdown_fileType == "pdf" ? validFileFormats : validFileFormatsReadable}
                                    id={`poFile${i}`}
                                    name="poFile"
                                    onChange={(e) =>
                                      this.onRowFileUpload(e, item.rowId)
                                    }
                                    key={item.poFileInputKey || ""}
                                    style={{
                                      display: "none",
                                      visibility: "none",
                                    }}
                                  />
                                  <label
                                    htmlFor={`poFile${i}`}
                                    className={
                                      "attach-btn attach-btn-responsive chs-btn " +
                                      (!isStringEmpty(
                                        isEmptyObject(errorObj)
                                          ? ""
                                          : errorObj[7].value
                                      )
                                        ? "invalid-position-border"
                                        : "")
                                    }
                                  >
                                    <img
                                      className="upload-img"
                                      src="images/Icon awesome-upload.png"
                                      width="18px"
                                    />
                                    &nbsp;Choose File
                                  </label>
                                  {!isStringEmpty(
                                    isEmptyObject(errorObj)
                                      ? ""
                                      : errorObj[7].value
                                  ) && (
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                        className="invalid-position-img"
                                      />
                                    )}
                                  <label className="required-field">
                                    {" "}
                                    &nbsp; (Please select an pdf, excel file)
                                  </label>
                                  <label>
                                    &nbsp;&nbsp;&nbsp;{item.poFileName}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="addNewPO-file" style={{ display: dropdown_fileProperty == "unreadable" ? "" : "none" }}>
                        <img
                          src="./images/add-document.png"
                          alt="add_img"
                          width="15px"
                          style={{ pointerEvents: "all", cursor: "pointer" }}
                          onClick={this.onAddRowClick}
                        />{" "}
                        <span
                          style={{
                            color: "#0000EE",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={this.onAddRowClick}
                        >
                          Add New PO File
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {validateError && !filePropertyError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                {/* {validateFileError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>You have attached the wrong File type.</p>
                  </div>
                )} */}
                {filePropertyError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>You have selected wrong File property.</p>
                  </div>
                )}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-background-color upload-btn"
                    onClick={this.onUploadClick}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDeleteModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteModalCloseClick}
            onYesClick={this.onDeleteModalYesClick}
            onNoClick={this.onDeleteModalCloseClick}
            modalheader="Delete PO Item"
          ></DeleteModal>
        )}
      </div>
    );
  }
}
