import React from 'react';


class Loader extends React.Component {
    render() {          
      return ( 
         <div id="load"></div>
      );
    }
  }

export default Loader;