import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';
import requestCrossIcon from '../assets/images/requestCrossIcon.png'
import { useEffect } from 'react';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';

  /* <summary>
  date: 27/01/2023
  Name: HV
  description: input field component for inline editing
  <summary> */

export default forwardRef((props, ref) => {

    const getInitialValue = (props) => {
        let startValue = props.params.value;
        const isBackspaceOrDelete =
            props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE;
        if (isBackspaceOrDelete) {
            startValue = '';
        } else if (props.charPress) {
            startValue = props.charPress;
        }
        if (startValue !== null && startValue !== undefined) {
            return startValue;
        }
        return '';
    };
    const [value, setValue] = useState("");
    
    useEffect(() => {
        let startValue = props.params.value;
        const isBackspaceOrDelete =
            props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE;
        if (isBackspaceOrDelete) {
            startValue = '';
        } else if (props.charPress) {
            startValue = props.charPress;
        }
        setValue(startValue)
      }, [props]);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },

            myCustomFunction() {
                return {
                    rowIndex: props.params.rowIndex,
                    colId: props.params.column.getId(),
                };
            },
        };
    });

    const clickfunction= (event)=>{
        props.onclear(props.params.rowIndex);
    }
   const handleChange = event => {
    setValue(event.target.value);
  };
  const handleKeyDown = event => {
    if (event.key === "Enter" && value.trim() != "" ) {
      props.onEnterKeyPress(value,props.params.rowIndex);
    }
    return true;
  };    
    return (
        <div style={{ display: 'flex', marginTop: "-3px" }}>
            <input
                value={value}
                onChange={handleChange}
                className="form-control"
                autoComplete={"off"}
                placeholder=""
                type="text"
              //  autoFocus={false}
                onKeyDown={handleKeyDown}
            />
            <img
                src={requestCrossIcon}
                alt="cancel"
                className='cross-icon cursor-class'
                width="20px"
                height="20px"
                title="Clear"
                style={{
                    alignSelf: 'center', margin: "0 10px"
                }}
                onClick={(e) => clickfunction(e)}
            />
        </div>
    );
});