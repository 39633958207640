//prod
// export const config = {
//   apiUrl: window.location.origin,
//maritecSiteUrl: "https://www.maritec.com.sg/",
//    auth: {
//       clientId: "2775d9db-f001-468e-a209-db21e3869180",
//       authority: "https://login.microsoftonline.com/592ed120-3b68-493e-b204-e202e5e6692d",
//       redirectUri: "https://dev.ihmm.edotteam.com/Communication/"
//    },
// };

//dev
export const config = {
    apiUrl: window.location.origin,
    maritecSiteUrl: "https://www.maritec.com.sg/",
    auth: {
        clientId: "2775d9db-f001-468e-a209-db21e3869180",
        authority: "https://login.microsoftonline.com/592ed120-3b68-493e-b204-e202e5e6692d",
        redirectUri: "https://dev.ihmm.edotteam.com/Communication/"
    },
};

//local
//export const config = {
//apiUrl: "https://localhost:7053",
//maritecSiteUrl: "https://www.maritec.com.sg/",
//auth: {
//  clientId: "2775d9db-f001-468e-a209-db21e3869180",
//  authority: "https://login.microsoftonline.com/592ed120-3b68-493e-b204-e202e5e6692d",
//  redirectUri: "https://localhost:44417/Communication/"
//},
//};
