import React, { Component } from "react";

const Checkbox = ({ obj, onChange, disabled }) => {
    return (
        <>
            <input
                type="checkbox"
                className="form-check-input mb-3"
                id={`custom-checkbox-${obj.value}`}
                name={obj.label}
                value={obj.isChecked}
                onChange={() => onChange({ ...obj, isChecked: !obj.isChecked })}
                checked={obj.isChecked}
                disabled={disabled}
            />
            <label className="form-check-label" htmlFor={`custom-checkbox-${obj.value}`}>{obj.label}</label>
        </>
    );
};

/* <summary>
date: 03/03/2023
Name: HV
description: common page for multi select checkbox
<summary> */

export default class MultiSelectCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
        };
    }

    componentDidUpdate = (prevProps) => {
        //code to update state when props change
        if (prevProps.options !== this.props.options) {
            var options = [...this.props.options];
            this.setState({ options });
        }
    };

    onChange = (item) => {
        const { options } = this.state;
        const newsRows = options.map(row => {
            if (row.value === item.value) {
                row.isChecked = !row.isChecked;
                return {
                    ...row
                };
            }
            return row;
        });
        this.setState({ options: newsRows }, () => {
            this.props.onChange(this.state.options);
        });
    };

    render() {
        const { options } = this.state;
        const { disabled } = this.props;

        return (
            <div className="service-category-parent">
                {options.map((obj, index) => (
                    <div className="service-category-checkbox" key={index}>
                        <Checkbox
                            obj={obj}
                            onChange={(item) => this.onChange(item)}
                            disabled={disabled}
                        />
                    </div>
                ))}
            </div>
        );
    }
}
