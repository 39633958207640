import React, { Component } from 'react'
import AGGrid from '../../components/AGGrid'


const ViewPOHistory = ({
    rowData, colDefs, colDefsUnreadable, poFileName, fileProperty,importedPOFileId,closeModal, onDownloadPOReadableFilesClick

}) => {
    return (
        <div className="view-po-list" style={{ display: "block" }}>
            <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
            <div className="modal" style={{ display: "block" }}>
                <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"> PO List</h4>
                            <button type="button" data-bs-dismiss="modal">
                                <img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => closeModal("showHistoryModal", false)} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-body">
                                {fileProperty != "unreadable" && (
                                    <div className="PO-list">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 doc-name">{poFileName}</div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 PO-dwnld-icon"><img src="images/admin-tracking-download.png" alt="admin download icon" className="admin-download" style={{pointerEvents:"all",cursor:'pointer'}} onClick={()=> onDownloadPOReadableFilesClick(poFileName, rowData)} /></div>
                                        </div>
                                    </div>
                                )}
                                <div className="table-responsive">
                                    <AGGrid
                                        rowData={rowData}
                                        colDefs={fileProperty == "unreadable" ? colDefsUnreadable : colDefs}
                                        pagination="10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewPOHistory;