import React, { Component, Fragment } from 'react';

const AttachmentPreview = ({
    showModal,
    srcUrl
}) => {
    return showModal == false ? null : (
        <div className="modal fade" id="manage-template" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog custom-modal-dialog-width">
                <div className="modal-content custom-modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"></h5>
                        <a href="" data-bs-dismiss="modal"><img className="close-img-icon" src="images/modal-close.png" width="25px" /></a>
                    </div>

                    <div className="modal-body">
                        <div className="row content">
                            <iframe src={srcUrl} style={{ height: 400, width: 400, margin: 40 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttachmentPreview;

