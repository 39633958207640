import webserviceWrapper from "../helpers/webserviceWrapper";
export const contactUsService = {
    saveContactInformation,
    getAllContactUsDetailsList,
};

function saveContactInformation(contactUsModel){
    return webserviceWrapper.post(
        `/ContactUs/SaveContactInformation`,
        contactUsModel,
        60000
    );
}

function getAllContactUsDetailsList(){
    return webserviceWrapper.get(
        `/ContactUs/GetAllContactUsDetailsList`,
    );
}