import React, { Component } from "react";
import { EmailService } from "../../services/email.service";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { helperText } from "../../helpers/helperText";
import { CommunicationService } from "../../services/communication.service";
import Loader from "../../components/Loader";
import {
    isStringEmpty,
} from "../../helpers/string-helper";
import {
    AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, withRouter, ConvertToHtml,
    sanitizeHTML,
    ConvertFromHTML
} from "../../helpers";
import Logout from "../../helpers/Logout";
import { Email_Default_ID, toolbarOptions } from "../../helpers/enums";
import Toast from "../../components/Toast";
import AlertModal from "../../components/AlertModal";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const validFileFormatsReadable =
    "application/pdf,application/PDF,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const tableItems = `<tr style="text-align: center;">
    <td style="border-bottom: 1px solid black;border-left: 1px solid black;text-align: center;">[serialNo]</td>
      <td style="border-bottom: 1px solid black;border-left: 1px solid black; text-align: center;">[year]</td>
      <td style="border-bottom: 1px solid black;border-left: 1px solid black;text-align: center;">[purchase_Order_No]</td>
      <td style="border-bottom: 1px solid black;border-left: 1px solid black;text-align: center;">[Supplier_Name]</td>
      <td style="border-bottom: 1px solid black;border-left: 1px solid black;text-align: center;">[Line_Item_No]</td>
      <td style="border-bottom: 1px solid black;border-left: 1px solid black;text-align: center;">[Category]</td>
      <td style="border-bottom: 1px solid black;border-left: 1px solid black;border-right: 1px solid black;text-align: center;">[Description]</td>
    </tr>`;

class MultipleCommunicationReminder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileInputKey: '',
            userId: 0,
            isLoading: false,
            toastMessage: "",
            errorMessages: {},
            toastType: "",
            toastVisible: false,

            selectedLineItemList: [],
            emailSentModal: false,
            messageText: "",
            listFile: [],
            groupedLineItems: [],
            lineItemList: [],
            attachmentFiles: []
        };
    }

    /* <summary>
      date: 15/11/2022
      Name: DN
      description: Get data to bind on the screen on load
      <summary> */
    componentDidMount() {
        AddLocalStorageData("SelectedTrackingTool", 0);
        var userData = GetLocalStorageDataWithParse("loggedInUser");
        if (userData != null) {
            this.setState({ userId: userData.id }, () => {
                this.filterLineITemsBasedOnPONumber();
            })
        }
    }

    /* <summary>
       date: 28/03/2023
       Name: HV
       description: handled grouping of data
       <summary> */
    filterLineITemsBasedOnPONumber = () => {
        this.setState({ isLoading: true })
        var selectedItemList = GetLocalStorageDataWithParse("selectedLineItems");

        const groupedLineItems = {};
        if (selectedItemList.length > 0) {
            selectedItemList.forEach((item) => {
                if (!groupedLineItems[item.ponumber]) {
                    groupedLineItems[item.ponumber] = [];
                }
                groupedLineItems[item.ponumber].push(item);
            });

            this.setState({ groupedLineItems: groupedLineItems, lineItemList: selectedItemList }, () => {
                this.GetEmailDefaultTemplateByID();
            })
        }
    }

    /* <summary>
      date: 15/11/2022
      Name: DN
      description: Get data to bind in the dropdown filter
      <summary> */
    //HV - 28/03/2023  - handled multiple po number binding
    GetEmailDefaultTemplateByID = async () => {
        try {
            this.setState({
                isLoading: true,
            });

            const responseFilters = await EmailService.GetEmailDefaultTemplateByID(Email_Default_ID.MDSDoC);

            if (responseFilters == undefined || responseFilters == null) {
                this.setState({
                    toastVisible: true,
                    toastType: helperText.global.toastError,
                    toastMessage: helperText.global.somethingWentWrong,
                });
            } else if (responseFilters != null) {
                if (responseFilters.status == 401) {
                    Logout(this.props.navigate);
                } else if (responseFilters.status == 200) {

                    let emailData = responseFilters.data.emailDefaultList;

                    var attachmentFiles = responseFilters.data.attachmentList;

                    let emailBodyToDisplay = ConvertFromHTML(emailData?.emailText);
                    this.setState(
                        {
                            subject: emailData?.emailSubject,
                            emailBody: emailBodyToDisplay,
                            cc: emailData?.ccaddress,
                            bcc: emailData?.bccaddress,
                            attachmentFiles: attachmentFiles,
                        },
                        () => {
                            const { groupedLineItems } = this.state;
                            let item = []
                            let selectedItem = Object.keys(groupedLineItems).map((type, index) => {
                                let lineItemsToadd = "";
                                groupedLineItems[type].map((element, id) => {
                                    var itemsToadd = tableItems;

                                    itemsToadd = itemsToadd.replace('[serialNo]', id + 1);
                                    itemsToadd = itemsToadd.replace('[year]', element?.podate ?? "");
                                    itemsToadd = itemsToadd.replace('[purchase_Order_No]', element?.ponumber ?? "");
                                    itemsToadd = itemsToadd.replace('[Supplier_Name]', element?.supplierName ?? "");
                                    itemsToadd = itemsToadd.replace('[Line_Item_No]', element?.lineItemNumber ?? "");
                                    itemsToadd = itemsToadd.replace('[Category]', element?.masterCategory ?? "");
                                    itemsToadd = itemsToadd.replace('[Description]', element?.description ?? "");

                                    lineItemsToadd = lineItemsToadd + itemsToadd;
                                    if (id == 0) {
                                        var bindEmailBody = emailData?.emailText.replace('[shipName]', element.shipName);
                                        bindEmailBody = bindEmailBody.replace('[clientName]', element.clientName);

                                        var bindEmailBodyNoUrl = emailData?.emailText.replace('[shipName]', element.shipName);
                                        bindEmailBodyNoUrl = bindEmailBodyNoUrl.replace('[clientName]', element.clientName);

                                        bindEmailBodyNoUrl = emailData?.emailText.replace('</br><b>OTP Password : [guidPassword]</b> </br></br>', "");
                                        bindEmailBodyNoUrl = emailData?.emailText.replace('<a href="[link]"><b>CLICK HERE</b></a>', "");
                                        bindEmailBodyNoUrl = emailData?.emailText.replace('<a href="[siteLink]"><b>CLICK HERE</b></a>', "");

                                        var emailSubj = emailData?.emailSubject.replace('[shipName]', element.shipName);
                                        emailSubj = emailSubj.replace('[clientName]', element.clientName);

                                        let emailBodyToShow = ConvertFromHTML(bindEmailBody);
                                        element.subject = emailSubj,
                                            element.emailDefaultId = emailData?.emailDefaultId,
                                            element.emailType = emailData?.emailType,
                                            element.to = element.supplierEmail,
                                            element.cc = emailData?.ccaddress,
                                            element.bcc = emailData?.bccaddress,
                                            element.emailBody = emailBodyToShow,
                                            element.emailHTMLBodyURLNone = bindEmailBodyNoUrl

                                        item.push(element)
                                    }
                                });
                                groupedLineItems[type]['listItem'] = lineItemsToadd;
                                return groupedLineItems;
                            });
                            item.map((itm, index) => {
                                let itemsToadd = groupedLineItems[itm.ponumber]['listItem'];
                                const contentState = itm.emailBody.getCurrentContent();
                                const plainText = contentState.getPlainText().trim();

                                var bindEmailBodyToDisplay = plainText;
                                bindEmailBodyToDisplay = bindEmailBodyToDisplay.replace('[listItems]', itemsToadd);


                                var bindEmailBodyURL = itm.emailHTMLBodyURLNone;
                                bindEmailBodyURL = bindEmailBodyURL.replace('[listItems]', itemsToadd);
                                itm.emailBodyNoURL = bindEmailBodyURL;

                                let emailBodyDisplay = ConvertFromHTML(bindEmailBodyToDisplay);
                                itm.emailBody = emailBodyDisplay;

                                itm.file = attachmentFiles;
                                return itm;
                            });
                            this.setState({ selectedLineItemList: item });
                        }
                    );
                }
            }
            this.setState({
                isLoading: false,
            });
        } catch (error) { }
    };

    /* <summary>
      date: 15/11/2022
      Name: DN
      description: On text changed, fill in all typed text
      <summary> */
    onTextChange = (rowId, e) => {
        const { selectedLineItemList } = this.state;
        const { name, value } = e.target;
        var rowIndex = selectedLineItemList.findIndex(t => t.trackingToolId == rowId);
        if (rowIndex > -1) {
            selectedLineItemList[rowIndex][name] = value;
            this.setState({ selectedLineItemList: selectedLineItemList });
        }
    };

    /* <summary>
      date: 15/11/2022
      Name: DN
      description: On send click, save the data in communication also send on email
      <summary> */
    //HV - 20/03/2023 - handled saving in bridge tables
    onSendClick = async () => {
        try {
            let canSave = true;
            this.setState({
                isLoading: true,
            });
            var supplierListCommaSeperated = "";
            var poNoListCommaSeperated = "";
            var frameAlertModalMessage = "";
            let count = 0;

            this.state.selectedLineItemList.forEach(async (element) => {
                //only save if there are no validation errors
                if (canSave) {

                    supplierListCommaSeperated = supplierListCommaSeperated.concat(element.supplierName + ", ");
                    poNoListCommaSeperated = poNoListCommaSeperated.concat(element.ponumber + ", ");

                    let emailBodyToSave = sanitizeHTML(ConvertToHtml(element.emailBody));
                    let communicationData = new FormData();
                    communicationData.append("ClientId", element.clientId);
                    communicationData.append("ShipId", element.shipId);
                    communicationData.append("SupplierId", element.supplierId);
                    communicationData.append("EmailDefaultId", element.emailDefaultId);
                    communicationData.append("EmailType", element.emailType);
                    communicationData.append("EmailSubject", element.subject);
                    communicationData.append("EmailText", emailBodyToSave);
                    communicationData.append("ToAddress", element.to);
                    communicationData.append("Ccaddress", element.cc);
                    communicationData.append("Bccaddress", element.bcc);
                    communicationData.append("CreatedBy", this.state.userId);
                    communicationData.append("LineItemsList", GetLocalStorageDataWithoutParse("selectedLineItems"));
                    communicationData.append("Ponumber", element.ponumber);
                    communicationData.append("isCommunication", false);


                    if (element.file != null) {
                        element.file.forEach((fileElement) => { communicationData.append("fileList", fileElement); });
                    }
                    var responseFilters = await CommunicationService.AddCommunication(communicationData);
                    if (responseFilters == undefined || responseFilters == null) {
                        this.setState({
                            emailSentModal: false,
                            messageText: "",
                            toastVisible: true,
                            toastType: helperText.global.toastError,
                            toastMessage: helperText.global.somethingWentWrong,
                        });
                    } else if (responseFilters != null && responseFilters.status == 200) {
                        count = count + 1;
                        if (count == this.state.selectedLineItemList.length) {
                            frameAlertModalMessage = helperText.TrackingTool.multipleReminderEmail.replace('[supplierNames]', supplierListCommaSeperated.trim().slice(0, -1));
                            frameAlertModalMessage = frameAlertModalMessage.replace('[poNumbers]', poNoListCommaSeperated.trim().slice(0, -1))
                            this.setState({
                                emailSentModal: true,
                                messageText: frameAlertModalMessage,
                                isLoading: false,
                            });
                        }
                    }
                }
            })

        } catch (error) { }
    }

    onModalCloseClick = () => {
        this.setState({
            emailSentModal: false,
            messageText: "",
        }, () => {
            this.props.navigate("/TrackingTool");
        });
    }

    /* <summary>
      date: 15/11/2022
      Name: DN
      description: On replace or change of attached file
      <summary> */
    //HV - 28/03/2023 - handled multiple file selecttion

    onAttachmentChange = (id, e) => {
        const { selectedLineItemList } = this.state;
        const chosenFiles = Array.from(e.target.files);

        const uploadedFile = this.state.attachmentFiles || [];
        const updatedFiles = uploadedFile.concat(chosenFiles);

        const rowIndex = selectedLineItemList.findIndex(t => t.trackingToolId === id);
        if (rowIndex > -1) {
            const updatedLineItemList = [...selectedLineItemList];
            updatedLineItemList[rowIndex]['file'] = updatedFiles;

            this.setState({
                selectedLineItemList: updatedLineItemList,
            });
        }
    };


    /* <summary>
   date: 28/03/2023
   Name: HV
   description: on cross icon click for files
   <summary> */

    onClearSingleFileCapsule = (name, index) => {

        const { selectedLineItemList } = this.state;
        var array = selectedLineItemList[index]['file'].filter(x => x.name != name);
        selectedLineItemList[index]['file'] = array;

        this.setState({ selectedLineItemList: selectedLineItemList });
    }

    HandleTextChange = (evt, rowIndex) => {
        const { selectedLineItemList } = this.state;
        var newContent = evt;//.editor.getData();
        selectedLineItemList[rowIndex].emailBody = newContent;
        this.setState({
            selectedLineItemList: selectedLineItemList
        });
    };

    closeToast = () => {
        this.setState({ toastVisible: false });
    };

    /* <summary>
  date: 29/03/2023
  Name: HV
  description: navigating to tracking tool if view email clicked
  <summary> */
    onViewEmailClick = () => {
        AddLocalStorageData('location', '/MultipleCommunicationReminder');
        this.props.navigate("/CommunicationHistory");
    }


    render() {
        const {
            errorMessages,
            isLoading,
            toastVisible,
            toastType,
            toastMessage,
            fileInputKey,
            selectedLineItemList,
            emailSentModal,
            messageText,
        } = this.state;

        const ckEditorRemoveButtons = "ImageButton,Button,Undo,SelectAll,Spell Checker,Checkbox,Redo,Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Form,Radio,TextField,Textarea,Select,HiddenField,About,CopyFormatting,RemoveFormat,Language,Flash,Iframe";

        return (
            <div>
                {isLoading && <Loader></Loader>}
                <Header />
                {toastVisible && (
                    <Toast toastVisible type={toastType} message={toastMessage} closeToast={this.closeToast} />
                )}
                {emailSentModal && (
                    <AlertModal
                        emailSentModal
                        onOkClick={this.onModalCloseClick}
                        msgToDisplay={messageText}
                        viewLink={this.onViewEmailClick}
                    />
                )}

                <main className="page-height">
                    <section className="communications">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-5 col-lg-8 ">
                                    <div className="admin-profile-detail-back">
                                        <a onClick={() => this.props.navigate("/TrackingTool")}>
                                            <img src="images/back.png" alt="Back" />
                                        </a>
                                        <h5>Send Email</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedLineItemList.length > 0 &&
                            selectedLineItemList.map((item, index) => {
                                return (
                                    <div className="container message-template" key={index}>
                                        <div className="communications-form">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <form className="form-inline">
                                                        <div className="row ticket-email-form">
                                                            <h5 className="template-title">
                                                                <strong> <span>Email to</span> <span>{item?.supplierName ?? ""}</span> <span>requesting for MD / SDoC</span></strong>{" "}
                                                            </h5>
                                                            <div className="col-12 col-md-2 col-lg-1 email-template-bottomspace">
                                                                <label className="label-class" htmlFor="to">
                                                                    To:
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-10 col-lg-11 email-template-bottomspace input-validation">
                                                                <input
                                                                    className="field-width"
                                                                    type="text"
                                                                    id="to"
                                                                    name="to"
                                                                    value={item?.supplierEmail ?? ""}
                                                                    onChange={(e) => this.onTextChange(item.trackingToolId, e)}
                                                                />
                                                                {!isStringEmpty(errorMessages.toValidation) && (
                                                                    <div className="invalid-message">
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <div className="invalid-login-input">
                                                                            {errorMessages.toValidation}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <br />
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-1 email-template-bottomspace">
                                                                <label
                                                                    htmlFor="subject"
                                                                    className="label-class label-alignment"
                                                                >
                                                                    CC:
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-10 col-lg-11 email-template-bottomspace input-validation">
                                                                <input
                                                                    className="field-width"
                                                                    type="text"
                                                                    id="cc"
                                                                    name="cc"
                                                                    value={item?.cc ?? ""}
                                                                    onChange={(e) => this.onTextChange(item.trackingToolId, e)}
                                                                />
                                                                {!isStringEmpty(errorMessages.ccValidation) && (
                                                                    <div className="invalid-message">
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <div className="invalid-login-input">
                                                                            {errorMessages.ccValidation}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <br />
                                                            </div>
                                                            <div className="  col-12 col-md-2 col-lg-1 email-template-bottomspace">
                                                                <label
                                                                    htmlFor="subject"
                                                                    className="label-class label-alignment"
                                                                >
                                                                    BCC:
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-10 col-lg-11 email-template-bottomspace input-validation">
                                                                <input
                                                                    className="field-width"
                                                                    type="text"
                                                                    id="bcc"
                                                                    name="bcc"
                                                                    value={item?.bcc ?? ""}
                                                                    onChange={(e) => this.onTextChange(item.trackingToolId, e)}
                                                                />
                                                                {!isStringEmpty(errorMessages.bccValidation) && (
                                                                    <div className="invalid-message">
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <div className="invalid-login-input">
                                                                            {errorMessages.bccValidation}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <br />
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-1 email-template-bottomspace">
                                                                <label
                                                                    htmlFor="subject"
                                                                    className="label-class label-alignment"
                                                                >
                                                                    Subject:
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-10 col-lg-11 email-template-bottomspace input-validation">
                                                                <input
                                                                    className="field-width"
                                                                    type="text"
                                                                    id="subject"
                                                                    name="subject"
                                                                    value={item?.subject ?? ""}
                                                                    onChange={(e) => this.onTextChange(item.trackingToolId, e)}
                                                                />
                                                                {!isStringEmpty(errorMessages.subjValidation) && (
                                                                    <div className="invalid-message">
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <div className="invalid-login-input">
                                                                            {errorMessages.subjValidation}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <br />
                                                            </div>
                                                            <div className="col-12 col-md-2 col-lg-1 email-template-bottomspace ">
                                                                <label
                                                                    htmlFor="subject"
                                                                    className="label-class label-alignment"
                                                                >
                                                                    Message:
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-md-10 col-lg-11 email-template-bottomspace input-validation bordermessage" >
                                                                {/* <CKEditor
                                                                    activeClass="p10"
                                                                    scriptUrl="https://cdn.ckeditor.com/4.6.2/full/ckeditor.js"
                                                                    content={item?.emailBody}
                                                                    config={{
                                                                        removeButtons: ckEditorRemoveButtons,
                                                                    }}
                                                                    events={{
                                                                        "change": (e) => this.HandleTextChange(e, index)
                                                                    }}

                                                                /> */}

                                                                {/* <RichTextEditor
                                                                    value={item?.emailBody}
                                                                    onChange={(e) => this.HandleTextChange(e, index)}
                                                                /> */}

                                                                <Editor
                                                                    editorState={item?.emailBody}
                                                                    placeholder="Enter Comments"
                                                                    editorStyle={{ height: 200, border: '1px solid #ced4da', paddingLeft: '10px', background: '#ffffff' }}
                                                                    wrapperClassName="demo-wrapper "
                                                                    editorClassName="editer-content editor-height"
                                                                    toolbarClassName="toolbar-class candrag"
                                                                    onEditorStateChange={(e) => {
                                                                        this.HandleTextChange(e, index);
                                                                    }}
                                                                    handlePastedText={(val) => {
                                                                        const textLength =
                                                                            item?.emailBody
                                                                                .getCurrentContent()
                                                                                .getPlainText().length;
                                                                        return (
                                                                            val.length + textLength
                                                                        );
                                                                    }}
                                                                    toolbar={toolbarOptions}
                                                                />
                                                                {!isStringEmpty(errorMessages.bodyValidation) && (
                                                                    <div className="invalid-message">
                                                                        <i
                                                                            className="fa fa-exclamation-circle"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <div className="invalid-login-input">
                                                                            {errorMessages.bodyValidation}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className=" col-12 col-md-2 col-lg-1 email-template-bottomspace">
                                                                <label
                                                                    htmlFor="subject"
                                                                    className="label-class label-alignment"
                                                                >
                                                                    Attach:
                                                                </label>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-lg-10 email-template-bottomspace">
                                                                <div className="choosefile-btn" onClick={() => this.refs['fileInput' + item.trackingToolId].click()} style={{ width: "160px" }}>
                                                                    <label className={"attach-btn chs-btn "}>
                                                                        <img className="upload-img" src="images/Icon awesome-upload.png" width="18px" />&nbsp;Choose File
                                                                    </label>
                                                                    <input
                                                                        ref={"fileInput" + item.trackingToolId}
                                                                        type="file"
                                                                        accept={validFileFormatsReadable}
                                                                        id={"files" + item.trackingToolId}
                                                                        name={"documentFile" + item.trackingToolId}
                                                                        onChange={(e) => this.onAttachmentChange(item.trackingToolId, e)}
                                                                        key={fileInputKey || ""}
                                                                        style={{
                                                                            display: 'none',
                                                                        }}
                                                                        multiple
                                                                    />
                                                                </div>
                                                                <label>&nbsp; (Please select PDF, Excel, Word or Image files)</label>

                                                                <ul style={{ listStyleType: 'none', display: 'flex', flexWrap: 'wrap', paddingLeft: 0 }}>
                                                                    {item.file != null && item.file.length > 0 &&
                                                                        item.file.map((f, id) =>
                                                                        (
                                                                            <li key={id}>
                                                                                <div style={{ fontWeight: 'bold', marginRight: '6px', borderRadius: '3px', padding: '.5833em .833em', backgroundColor: '#e3e1e1', fontSize: ".8rem", border: "none", margin: '8px 8px 8px 0px' }}>
                                                                                    <span style={{ marginRight: 5 }}> {f.name}</span>
                                                                                    {!f.hideDeleteIcon && <button type='button' onClick={() => this.onClearSingleFileCapsule(f.name, index)} style={{ marginLeft: 4, border: 'none' }}>X</button>}
                                                                                </div>
                                                                            </li>
                                                                        ))

                                                                    }
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>)
                            }
                            )}
                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-background-color send-btn"
                                onClick={this.onSendClick}
                            >
                                Send
                            </button>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        );
    }
}

export default withRouter(MultipleCommunicationReminder);
