import React, { Component } from "react";
import { UserService } from "../../services/user.service";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { helperText } from "../../helpers/helperText";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { isStringEmpty } from "../../helpers/string-helper";
import backIcon from "../../assets/images/back button image.png";
import editIcon from "../../assets/images/profile-feather-edit.png";
import deleteIcon from "../../assets/images/delete icon.png";
import DirtyModal from "../../components/DirtyModal";
import {
  AddLocalStorageData,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
  changePageTitle,
  generateRandomString,
  isValidFile,
} from "../../helpers";
import { withRouter, isEmailValid } from "../../helpers";
import Logout from "../../helpers/Logout";
import { TitlePageNames } from "../../helpers/enums";
import ConfirmationModal from "../../components/ConfirmationModal";
import addProfileIcon from "../../assets/images/plus.png";

const validFileFormats = ["png, jpg, jpeg"];
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";
import UserRolesNPermissionModal from "../../components/UserRolesNPermissionModal";
import RolesPermissionModal from "../Users/RolesPermissionModal";
import DeleteModal from "../../components/DeleteModel";

const NUMBER_REGEX = /^[0-9()+-\s]+$/;
///[^\d]|\.|e/gi; 

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      errorMessages: {},
      userId: 0,
      userProfileName: "",
      userDesignation: "",
      userEmail: "",
      userPhoneNo: "",
      userAddress: "",
      userType: "",
      userCreatedOn: "",
      userName: "",
      userPassword: "",
      userPic: "",
      documentFile: "",
      fileName: "No File Chosen",
      filePath: "",
      showPassword: false,
      showErrorMessage: false,
      showInvalidEmailErrorMessage: false,
      showOutlookPassword: false,
      outlookId: "",
      outlookPass: "",
      showConfirmationPopup: false,
      profilePictureFileMediaId: 0,
      region: "",
      serviceCategory: "",
      usertypeid: 0,
      userLevel: "",
      viewRolesPermissionVisible: false,
      showDirtyPopup: false,
      path: "",
      defaultRolesPermissions: [],
      userRolesPermissions: [],
      rolesPermissionVisible: false,
      userLevelList: [],
      showDeleteModal: false,
      isDeleteProfile: false,
      shortEmployeeNameError: false,
      showErrorMessageEmail: false,
    };
  }

  /* <summary>
    date: 22/11/2022
    Name: DN
    description: Bind data on user profile page
    <summary> */
  componentDidMount() {
    try {
      //HV - 20/02/2023 - changing browser title name
      changePageTitle(TitlePageNames.USERPROFILEDETAILS);
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      if (userData != null) {
        this.setState({ userId: userData.id, usertypeid: userData.typeId });
        this.GetUserProfileDataToDisplay(userData.id, userData.typeId);
      }
    } catch (error) { }
  }

  /* <summary>
    date: 22/11/2022
    Name: DN
    description: Show hide password on eye icon click
    <summary> */
  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  /* <summary>
    date: 22/11/2022
    Name: DN
    description: Get profile data to show 
    <summary> */
  GetUserProfileDataToDisplay = async (userId, usertypeid) => {
    try {
      this.setState({
        isLoading: true,
      });
      var response = await UserService.GetUserProfileData(userId, usertypeid);

      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      } else if (response != null) {
        if (response.status == 200) {
          AddLocalStorageData("loggedInUserPic", response.data.userPic);
          AddLocalStorageData("loggedInUserName", response.data.name);
          this.setState({
            userId: response.data.userId,
            userProfileName: response.data.name,
            userDesignation: response.data.designation,
            userEmail: response.data.emailAddress,
            userPhoneNo: response.data.phoneNumber,
            userAddress: response.data.address,
            userType: response.data.userType,
            userCreatedOn: response.data.createdOn.split("T")[0],
            userName: response.data.userName,
            userPassword: response.data.password,
            userPic: (response.data.userPic = null
              ? "images/admin-profile.png"
              : response.data.userPic),
            isLoading: false,
            showErrorMessage: false,
            showInvalidEmailErrorMessage: false,
            outlookId: response.data.outlookUsername,
            outlookPass: response.data.outlookPassword,
            profilePictureFileMediaId: response.data.profilePictureFileMediaId,
            region: response.data.regionName,
            serviceCategory: response.data.serviceCategoryName,
            userLevel: response.data.level,
            userLevelList: response.data.userLevelList,
            defaultRolesPermissions: response.data?.lstDefaultUserRolePermission != null ? response.data.lstDefaultUserRolePermission : [],
            userRolesPermissions: response.data?.lstUserRolePermissions != null ? response.data.lstUserRolePermissions[0] : [],
          });
        } else if (response.status == 401) {
          Logout(this.props.navigate);
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      }
    } catch (error) { }
  };

  /* <summary>
    date: 22/11/2022
    Name: DN
    description: Change the password and set the new password in the field
    <summary> */
  onChangePasswordClick = async () => {
    try {
      this.setState({ isLoading: true, showConfirmationPopup: false });

      var response = await UserService.GenerateNewPassword(this.state.userId);
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          this.setState(
            {
              userPassword: response.data,
            },
            () => { }
          );
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };


  isValidate() {
    const {
      userProfileName,
      userDesignation,
      userEmail,
      userPhoneNo,
      errorMessages,
      userAddress,
      userName,
      outlookId,
      outlookPass,
    } = this.state;
    let errors = errorMessages;

    var empName = "";
    empName = userProfileName;

    errors["profileNameValidation"] =
      isStringEmpty(empName) || empName.trim().length < 3 ? "true" : "";
    errors["phoneNumberValidation"] = isStringEmpty(userPhoneNo) ? "true" : "";
    errors["emailValidation"] = isStringEmpty(userEmail) ? "true" : "";
    errors["designationValidation"] = isStringEmpty(userDesignation) ? "true" : "";
    errors["userNameValidation"] = isStringEmpty(userName) ? "true" : "";


    if (
      errors["profileNameValidation"] != "" ||
      errors["phoneNumberValidation"] != "" ||
      errors["emailValidation"] != "" ||
      errors["designationValidation"] != "" ||
      errors["userNameValidation"] != ""
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: true,
        shortEmployeeNameError: false,
        showErrorMessageEmail: false,
      });
      return true;
    } else if (!isEmailValid(userEmail)) {
      errors["emailValidation"] = !isEmailValid(userEmail) ? "true" : "";
      this.setState({
        errorMessages,
        showErrorMessageEmail: true,
        shortEmployeeNameError: false,
        showErrorMessage: false,
      });
      return true;
    }

    if (!isStringEmpty(outlookId) && isStringEmpty(outlookPass)) {
      errors["outlookIdValidation"] = "";
      errors["outlookPwdValidation"] = 'true';
      this.setState({
        errorMessages,
        showErrorMessage: true,
        shortEmployeeNameError: false,
        showErrorMessageEmail: false,
      });
      return true;
    }

    if (isStringEmpty(outlookId) && !isStringEmpty(outlookPass)) {
      errors["outlookIdValidation"] = 'true';
      this.setState({
        errorMessages,
        showErrorMessage: true,
        shortEmployeeNameError: false,
        showErrorMessageEmail: false,
      });
      return true;
    }

    if (isStringEmpty(outlookId) && !isStringEmpty(outlookPass)) {
      errors["outlookPwdValidation"] = "";
      this.setState({
        errorMessages,
        showErrorMessage: true,
        shortEmployeeNameError: false,
        showErrorMessageEmail: false,
      });
      return true;
    }

    if (!isStringEmpty(outlookId) && !isEmailValid(outlookId)) {
      errors["outlookIdValidation"] = 'true';
      this.setState({
        errorMessages,
        showErrorMessageEmail: true,
        shortEmployeeNameError: false,
        showErrorMessage: false,
      });
      return true;
    }


    if (errors["userNameValidation"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: false,
        shortEmployeeNameError: true,
        showErrorMessageEmail: false,
      });
      return true;
    }

    this.setState({
      errorMessages,
      showErrorMessage: false,
      shortEmployeeNameError: false,
      usernameExistError: false,
      emailExistError: false,
      showErrorMessageEmail: false,
    });
    return false;
  }

  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState({
      showDirtyPopup: false,
      isDirty: false
    }, () => {
      AddLocalStorageData("isDirty", false);
      if (this.state.path != "")
        this.props.navigate(this.state.path);
    });
  };
  /* <summary>
    date: 22/11/2022
    Name: DN
    description: Save the newly edited data
    <summary> */
  async onSaveClick() {
    try {
      const {
        userId,
        userProfileName,
        userDesignation,
        userEmail,
        userPhoneNo,
        userAddress,
        userType,
        userName,
        userPassword,
        documentFile,
        outlookId,
        outlookPass,
        profilePictureFileMediaId,
        isDeleteProfile
      } = this.state;

      this.setState({
        isLoading: true,
      });

      //only save if there are no validation errors
      if (!this.isValidate()) {
        let userData = new FormData();
        userData.append("UserId", userId);
        userData.append("ProfileName", userProfileName);
        userData.append("Designation", userDesignation);
        userData.append("Email", userEmail);
        userData.append("PhoneNo", userPhoneNo);
        userData.append("Address", userAddress);
        userData.append("Type", userType);
        userData.append("Name", userName);
        userData.append("Password", userPassword);
        userData.append("OutlookUsername", outlookId);
        userData.append("OutlookPassword", outlookPass);
        userData.append("ProfilePictureFileMediaId", profilePictureFileMediaId);

        let file = documentFile;

        if (file != "" && file.size !== null && file !== undefined && !isDeleteProfile) {
          userData.append("ProfilePicAttached", file);
        }

        if (isDeleteProfile) {
          this.deleteUserProfileImage(this.state.userId, this.state.usertypeid, this.state.userId);
          this.setState({
            isDeleteProfile: false,
          });
        }


        var responseFilters = await UserService.UpdateUserProfileData(userData);

        if (responseFilters != null && responseFilters != undefined) {
          AddLocalStorageData("isDirty", false);
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastSuccess,
            toastMessage: helperText.UserProfile.userUpdated,
            showErrorMessage: false,
            showInvalidEmailErrorMessage: false,
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /* <summary>
    date: 22/11/2022
    Name: DN
    description: On text changed set the new typed in text in respective fields
    <summary> */
  onTextChange = (e) => {
    //SYJ -05/01/2023- Validate the field on change.
    const { errorMessages } = this.state;
    const { name, value } = e.target;
    const txtName = e.target.name;
    let errors = errorMessages;
    if (txtName === "userProfileName") {
      AddLocalStorageData("isDirty", true);
      this.setState({
        [name]: value,
      });
      errors["profileNameValidation"] = "";
    } else if (txtName === "userDesignation") {
      AddLocalStorageData("isDirty", true);
      this.setState({
        [name]: value,
      });
      errors["designationValidation"] = "";
    } else if (txtName === "userEmail") {
      AddLocalStorageData("isDirty", true);
      this.setState({
        [name]: value,
      });
      errors["emailValidation"] = "";
    }
    else {
      AddLocalStorageData("isDirty", true);
      this.setState({
        [name]: value,
      });
    }
  };


  onPhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    if (value.trim() == "") {
      this.setState({
        [name]: value,
      });
      AddLocalStorageData("isDirty", true);
    }

    if (NUMBER_REGEX.test(value)) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          AddLocalStorageData("isDirty", true);
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  /* <summary>
    date: 07/06/2023
    Name: SYJ
    description: Delete the profile image
    <summary> */
  onDeleteProfilePic = () => {
    this.setState({
      showDeleteModal: true,
    });
  };

  onDeleteModalYesClick = () => {
    AddLocalStorageData("isDirty", true);
    this.setState({
      userPic: null,
      isDeleteProfile: true,
      showDeleteModal: false,
      toastVisible: true,
      toastMessage: helperText.global.deletedProfile,
      toastType: helperText.global.toastSuccess,
    })
  };

  onDeleteModalCloseClick = () => {
    this.setState({
      showDeleteModal: false,
      isDeleteProfile: false
    });
  };

  deleteUserProfileImage = async (userId, userTypeId, loggedInUserID) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteModal: false,
      });
      var response = await UserService.DeleteProfileImage(userId, userTypeId, loggedInUserID);
      this.setState({
        isLoading: false,
      });
      if (response != undefined) {
        this.GetUserProfileDataToDisplay(userId, userTypeId), () => {
          this.setState(
            {
              userPic: null,
              documentFile: null,
              fileInputKey: generateRandomString()
            })
        };

      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };




  /* <summary>
    date: 22/11/2022
    Name: DN
    description: Get new profile pic changed
    <summary> */
  onProfilePicChange = async (e) => {
    if (!isValidFile(e.target.value, validFileFormats)) {
      if (this.state.filePath == "") {
        this.setState({
          fileInputKey: generateRandomString,
          documentFile: null,
          errorFile: true,
        });
        AddLocalStorageData("isDirty", true);
      }

      //HV - 06/06/2023 - if after attaching correct file, wrong file is attached then retain the older file but show error message
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileTypeError,
      })

      return;
    }
    let files = e.target.files[0];
    AddLocalStorageData("isDirty", true);
    this.setState({
      errorFile: false,
      documentFile: files,
      userPic:
        URL.createObjectURL(
          files
        ) /*SYJ - 07/01/2023 - set image URL to State*/,
      fileName: files.name,
      filePath: e.target.value,
      isDeleteProfile: false
    });
  };

  closeToast = () => {
    this.setState({
      toastVisible: false,
    });
  };

  /* <summary>
     date: 14/02/2023
     Name: HV
     description: Show hide password on eye icon click
     <summary> */
  toggleOutlookPassword = () => {
    this.setState((prevState) => ({
      showOutlookPassword: !prevState.showOutlookPassword,
    }));
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
    });
  };

  showConfirmationPopup = () => {
    this.setState({
      showConfirmationPopup: true,
    });
  };

  closeUserPermissionsModal = () => {
    this.setState({
      viewRolesPermissionVisible: false,
      rolesPermissionVisible: false
    });
  };

  goToRolesPermission = (userLevel) => {
    if (userLevel == "Custom")
      this.setState({ rolesPermissionVisible: true })
    else
      this.setState({ viewRolesPermissionVisible: true });
  };

  goback = () => {
    var isDirty = GetLocalStorageDataWithoutParse('isDirty');
    if (isDirty === 'true') {
      this.setState({ showDirtyPopup: true, path: "/Dashboard" });
    }
    else {
      this.props.navigate("/Dashboard")
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      showPassword,
      userProfileName,
      userDesignation,
      userEmail,
      userPhoneNo,
      userAddress,
      userType,
      userCreatedOn,
      userName,
      userPassword,
      userPic,
      fileInputKey,
      errorMessages,
      showErrorMessage,
      showInvalidEmailErrorMessage,
      outlookPass,
      outlookId,
      showOutlookPassword,
      showConfirmationPopup,
      viewRolesPermissionVisible,
      rolesPermissionVisible,
      defaultRolesPermissions,
      userRolesPermissions,
      userLevelList,
      showDeleteModal,
      shortEmployeeNameError,
      showErrorMessageEmail
    } = this.state;
    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {rolesPermissionVisible && (
          <RolesPermissionModal
            showModal={rolesPermissionVisible}
            userLevelList={userLevelList}
            userRolesPermissions={userRolesPermissions}
            defaultRolesPermissions={defaultRolesPermissions}
            closeModal={this.closeUserPermissionsModal}
            isDataEditable={false}
          />
        )}

        {viewRolesPermissionVisible && (
          <UserRolesNPermissionModal
            showModal={viewRolesPermissionVisible}
            closeModal={this.closeUserPermissionsModal}
          />
        )}

        <div className="admin-main-body profile-page-header-bg">
          <div className="profile-page-header">
            <div className="container">
              <div className="admin-profile-detail-back">
                <img
                  src={backIcon}
                  alt="Back"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.goback()}
                />
                <h5>Profile Details</h5>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="admin-profile-detail-img">
                    <div className="admin-profile-detail-bg-img">
                      {userPic == "" || userPic == undefined ? (
                        <img
                          src={"images/add-photo.png"}
                          className="admin-profile"
                          style={{ objectFit: "contain" }} //SYJ - Fit image to the Contain Fix for https://jiraapp.edotteam.com/browse/IHMM-114
                        />
                      ) : (
                        <div>
                          <img
                            src={userPic}
                            className="admin-profile"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      )}
                      {userPic != "" && userPic != undefined && userPic != null ? (
                        <div className="admin-profile-icon">
                          <label style={{ left: "66%", top: "53%" }}>
                            <img
                              title={"Delete"}
                              src={deleteIcon}
                              alt="profile-delete"
                              className="img-fluid cursor-class test-Pankaj"
                              style={{ width: "15px", height: "20px" }}
                              onClick={this.onDeleteProfilePic}
                            />
                          </label>
                        </div>) : null}

                      <div className="admin-profile-icon">
                        <label htmlFor="myfile">
                          <img
                            title={
                              userPic == "" || userPic == undefined
                                ? "Add"
                                : "Edit"
                            }
                            src={
                              userPic == "" || userPic == undefined
                                ? addProfileIcon
                                : editIcon
                            }
                            alt="profile-edit "
                            className="img-fluid cursor-class"
                          />
                        </label>
                        <input
                          type="file"
                          accept={validFileFormatsReadable}
                          id="myfile"
                          name="myfile"
                          onChange={this.onProfilePicChange}
                          key={fileInputKey || ""}
                          style={{ display: "none", visibility: "none" }}
                        />
                      </div>

                    </div>
                    <h2>{userProfileName}</h2>
                  </div>
                  <div className="admin-profile-login-detail">
                    <h1>Login</h1>
                    <h4 className="mb-1 required-field">Username:</h4>
                    <div className="admin-profile-username input-validation">
                      <div
                        className={
                          !isStringEmpty(errorMessages.userNameValidation)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <input
                          type="name"
                          className="form-control"
                          id="userName"
                          name="userName"
                          value={userName}
                          onChange={this.onTextChange}
                        />
                        {/* {!isStringEmpty(errorMessages.userNameValidation) && (
                        <div className="invalid-message">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>
                          <div className="invalid-login-input">
                            {errorMessages.userNameValidation}
                          </div> 
                        </div>
                      )} */}

                        {!isStringEmpty(errorMessages.userNameValidation) && (
                          <div className="invalid-message-icon">
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>
                    <h4 className="mb-1 mt-3 required-field">Password:</h4>
                    <div
                      style={{ paddingLeft: "unset" }}
                      className="admin-change-password"
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        name="password"
                        value={userPassword}
                        placeholder="********"
                        required
                        id="id_password"
                        readOnly
                      />
                      <span onClick={this.togglePassword}>
                        {" "}
                        <i
                          className={
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          id="togglePassword"
                        ></i>{" "}
                      </span>

                    </div>
                    <span>(Auto generated by system)</span>
                    <div className="admin-profile-login-button">

                      <button
                        type="button"
                        className="btn"
                        onClick={this.showConfirmationPopup}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="admin-profile-info" style={{ padding: "20px" }}>
                    <div className="row">
                      <div className="col-md-3"
                        style={{ alignSelf: "center" }}>
                        <h5 className="required-field">Employee Name:</h5>
                      </div>
                      <div className="col-md-9  input-validation">
                        <div
                          className={
                            !isStringEmpty(errorMessages.profileNameValidation)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <input
                            type="name"
                            className="form-control"
                            id="userProfileName"
                            name="userProfileName"
                            value={userProfileName}
                            onChange={this.onTextChange}
                          />
                          {!isStringEmpty(
                            errorMessages.profileNameValidation
                          ) && (
                              <div className="invalid-message-icon">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-3"
                        style={{ alignSelf: "center" }}>
                        <h5 className="required-field">Email Address:</h5>
                      </div>
                      <div className="col-md-9 input-validation">
                        <div
                          className={
                            !isStringEmpty(errorMessages.emailValidation)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <input
                            type="email"
                            className="form-control"
                            id="userEmail"
                            name="userEmail"
                            value={userEmail}
                            onChange={this.onTextChange}
                          />
                          {!isStringEmpty(errorMessages.emailValidation) && (
                            <div className="invalid-message-icon">
                              <img
                                src="images\icon material-error.png"
                                alt="invalid"
                              ></img>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6"
                            style={{ alignSelf: "center" }}>
                            <h5 className="required-field">Contact Number:</h5>
                          </div>
                          <div className="col-md-6 input-validation">
                            <div
                              className={
                                !isStringEmpty(
                                  errorMessages.phoneNumberValidation
                                )
                                  ? "invalid-position"
                                  : ""
                              }
                              style={{ alignSelf: "center" }}
                            >
                              <input
                                type="number"
                                className="form-control admin-profile-number"
                                id="userPhoneNo"
                                name="userPhoneNo"
                                value={userPhoneNo}
                                onChange={this.onPhoneNumberChange}
                                maxLength={20}
                              />
                              {!isStringEmpty(
                                errorMessages.phoneNumberValidation
                              ) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4"
                            style={{ alignSelf: "center" }}>
                            <h5 className="required-field">Designation:</h5>
                          </div>
                          <div className="col-md-8 input-validation">
                            <div
                              className={
                                !isStringEmpty(
                                  errorMessages.designationValidation
                                )
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="userDesignation"
                                name="userDesignation"
                                value={userDesignation}
                                onChange={this.onTextChange}
                                maxLength={50}
                              />
                              {!isStringEmpty(
                                errorMessages.designationValidation
                              ) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3"
                        style={{ alignSelf: "center" }}>
                        <h5>Address:</h5>
                      </div>
                      <div className="col-md-9 input-validation">
                        <div
                          className={
                            !isStringEmpty(errorMessages.addressValidation)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <textarea
                            className="admin-profile-textarea"
                            rows="3"
                            id="userAddress"
                            name="userAddress"
                            value={userAddress}
                            onChange={this.onTextChange}
                            maxLength={250}
                          ></textarea>

                          {!isStringEmpty(errorMessages.addressValidation) && (
                            <div className="invalid-message-icon">
                              <img
                                src="images\icon material-error.png"
                                alt="invalid"
                              ></img>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6"
                            style={{ alignSelf: "center" }}>
                            <h5>Region:</h5>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.region}
                              disabled
                              readOnly
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4"
                            style={{ alignSelf: "center" }}>
                            <h5>User Role:</h5>
                          </div>
                          <div className="col-md-8  ">
                            <input
                              type="name"
                              className="form-control"
                              value={userType}
                              disabled
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6"
                            style={{ alignSelf: "center" }}>
                            <h5>User Level:</h5>
                          </div>
                          <div className="col-md-6  ">
                            <input
                              type="name"
                              className="form-control"
                              value={this.state.userLevel}
                              disabled
                              readOnly
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4"
                            style={{ alignSelf: "center", paddingTop: "8px" }}>
                            <h5>Permission:</h5>
                          </div>
                          <div className="col-md-8"
                            style={{ alignSelf: "center" }}>
                            <a onClick={() => this.goToRolesPermission(this.state.userLevel)}>
                              View Roles and Permissions
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6"
                            style={{ alignSelf: "center" }}>
                            <h5>Service Category:</h5>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="name"
                              className="form-control"
                              value={this.state.serviceCategory}
                              disabled
                              readOnly
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4"
                            style={{ alignSelf: "center" }}>
                            <h5>Date of Creation:</h5>
                          </div>
                          <div className="col-md-8  ">
                            <input
                              type="text"
                              className="form-control"
                              value={userCreatedOn}
                              disabled
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                      <h4 style={{ marginTop: "10px" }}>
                        Email Account Configuration
                      </h4>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3"
                            style={{ alignSelf: "center" }}>
                            <h5>User ID:</h5>
                          </div>
                          <div className="col-md-9 input-validation">
                            <div
                              className={
                                !isStringEmpty(
                                  errorMessages.outlookIdValidation
                                )
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                type="name"
                                className="form-control"
                                id="id_outlookId"
                                name="outlookId"
                                value={outlookId}
                                onChange={this.onTextChange}
                                autoComplete="new-password"
                                autoFocus={false}
                              />
                              {!isStringEmpty(
                                errorMessages.outlookIdValidation
                              ) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3"
                            style={{ alignSelf: "center" }}>
                            <h5>Password:</h5>
                          </div>
                          <div className="col-md-9 input-validation">
                            <div className="admin-change-password">
                              <div
                                className={
                                  !isStringEmpty(
                                    errorMessages.outlookPwdValidation
                                  )
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type={
                                    showOutlookPassword ? "text" : "password"
                                  }
                                  className="form-control"
                                  name="outlookPass"
                                  value={outlookPass}
                                  placeholder="********"
                                  id="id_EmailPassword"
                                  onChange={this.onTextChange}
                                  autoComplete="new-password"
                                  autoFocus={false}
                                  minLength={8}
                                />
                                <span onClick={this.toggleOutlookPassword}>
                                  {" "}
                                  <i
                                    className={
                                      showOutlookPassword
                                        ? "fa fa-eye"
                                        : "fa fa-eye-slash"
                                    }
                                    id="togglePassword"
                                  ></i>{" "}
                                </span>
                                {!isStringEmpty(
                                  errorMessages.outlookPwdValidation
                                ) && (
                                    <div className="invalid-dropdown-icon">
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* SYJ - 05/01/2022 - Show Hide error message Div */}
                      <div
                        className="invalid-message-icon-box"
                        style={{ display: showErrorMessage ? "" : "none" }}
                      >
                        <img
                          src="images\icon material-error.png"
                          alt="invalid"
                        //style={{marginTop:"-20px"}}
                        ></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>

                      {shortEmployeeNameError && (
                        <div className="invalid-message-icon-box">
                          <img
                            src="images/icon material-error.png"
                            alt="invalid-icon"
                          />
                          <p>
                            Employee Name must be atleast 3 characters long.
                          </p>
                        </div>
                      )}

                      {showErrorMessageEmail && (
                        <div className="invalid-message-icon-box">
                          <img
                            src="images/icon material-error.png"
                            alt="invalid-icon"
                          />
                          <p>Please enter valid email address.</p>
                        </div>
                      )}

                      {showInvalidEmailErrorMessage ? (
                        <div className="invalid-message-icon-box">
                          <img
                            src="images\icon material-error.png"
                            alt="invalidEmail"
                          //style={{marginTop:"-10px"}}
                          ></img>
                          <p>Please enter a valid Email.</p>
                        </div>
                      ) : null}

                      <div className="admin-ship-settings-save-btn text-center save-btn-row otherprofile-fixed ">
                        <button
                          type="button"
                          className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn"
                          onClick={this.onSaveClick.bind(this)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDirtyPopup && (
          <DirtyModal
            onModalCloseClick={this.onDirtyModalCloseClick}
            onProceedClick={this.onProceedClick}
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteModalCloseClick}
            onYesClick={this.onDeleteModalYesClick}
            onNoClick={this.onDeleteModalCloseClick}
            modalheader="Delete Profile Image"
          ></DeleteModal>
        )}

        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onChangePasswordClick.bind(this)}
            modalText={"change password? An email will be sent to the user with a new password after clicking the Yes button."}
          />
        )}

        <Footer />
      </div>
    );
  }
}
export default withRouter(UserProfile);
