import React, { useEffect, useState } from "react";
import { GetLocalStorageDataWithParse, helperText } from "../../helpers";
import { ErrorViewFor } from "../../helpers/enums";
import { settingsService } from "../../services/settings.service";
import ErrorElementView from "../../components/ErrorElementView";
import ErrorMessageBox from "../../components/ErrorMessageBox";

const ActivityLogSettings = ({
  SettingsData,
  ToggleLoader,
  ShowToastModal,
}) => {
  const [activityLogDays, setActivityLogDays] = useState(0);
  const [activityLogUnitID, setActivityLogUnitID] = useState(0);
  const [settingsID, setSettingsID] = useState(0);
  const [activityLogUnits, setActivityLogUnits] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    errorActivityLogDays: false,
    errorActivityLogUnitID: false,
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [useID, setUseID] = useState(0);

  useEffect(() => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (SettingsData) {
      var { activityLogDays, activityLogUnitID, settingsID } =
        SettingsData.activityLog;
      setActivityLogDays(activityLogDays);
      setActivityLogUnitID(activityLogUnitID);
      setSettingsID(settingsID);
      setActivityLogUnits(SettingsData.activityLogUnits);
    }
    setUseID(userData ? userData.id : 0);
    return () => { };
  }, [SettingsData]);

  const IsPageDataValid = () => {
    let isValid = true;
    let _errorMessage = errorMessage;
    if (activityLogDays > 0 && activityLogUnitID == 0) {
      isValid = false;
      _errorMessage.errorActivityLogDays = true;
      _errorMessage.errorActivityLogUnitID = true;
    }
    setErrorMessage({ ..._errorMessage });
    setHasErrors(!isValid);
    return isValid;
  };

  const SaveActivityLogSettings = async () => {
    if (IsPageDataValid()) {
      ToggleLoader();
      var response = await settingsService.UpdateActivityLogSettings({
        activityLogDays,
        activityLogUnitID,
        editedBy: useID,
        settingsID,
      });
      ToggleLoader();
      if (response.status == 200) {
        ShowToastModal(
          helperText.SettingsPageText.activityLogUpdateSuccess,
          helperText.global.toastSuccess
        );
      } else {
        ShowToastModal(
          helperText.global.somethingWentWrong,
          helperText.global.toastError
        );
      }
    }
  };
  const OnActivityLogDaysUnitChange = (e) => {
    var { value } = e.target;
    setActivityLogUnitID(value);
    setErrorMessage((prevState) => ({
      ...prevState,
      errorActivityLogUnitID:
        value == 0 ? prevState.errorActivityLogDays : false,
    }));
  };
  const OnActivityLogDaysChange = (e) => {
    var { value } = e.target;

    setActivityLogDays(value);
    setErrorMessage((prevState) => ({
      ...prevState,
      errorActivityLogDays: value <= 0 ? prevState.errorActivityLogDays : false,
    }));
  };
  return (
    <div id="Activity-log" className="tabcontent">
      <div className="tabcontent-data table-responsive-xxl">
        <h3>Notification Expiry</h3>
        <p>Notifications will expire after time specified below.</p>
        {/* <table>
          <thead>
            <tr>
              <th className="units">
                <h3>Activity Log Expiry:</h3>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={`${
                  errorMessage.errorActivityLogUnitID ? "invalid-element" : ""
                }`}
              >
                <select
                  className="form-select"
                  aria-label="form-select-lg example"
                  value={activityLogUnitID}
                  onChange={OnActivityLogDaysUnitChange}
                >
                  <option value={0}>Select</option>
                  {activityLogUnits.map((item, index) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errorMessage.errorActivityLogUnitID && (
                  <ErrorElementView
                    errorFor={ErrorViewFor.INVALID_DROPDOWN_ICON}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table> */}
        <div >
          <h3>Activity Log Expiry:</h3>
        </div>
        <div
          className={`${errorMessage.errorActivityLogUnitID ? "invalid-element" : ""
            }`}
        >
          <select
            className="form-select"
            aria-label="form-select-lg example"
            value={activityLogUnitID}
            onChange={OnActivityLogDaysUnitChange}
            style={{width:"180px"}}
          >
            <option value={0}>Select</option>
            {activityLogUnits.map((item, index) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
          {errorMessage.errorActivityLogUnitID && (
            <ErrorElementView
              errorFor={ErrorViewFor.INVALID_DROPDOWN_ICON}
            />
          )}
        </div>
        {hasErrors && <ErrorMessageBox />}
      </div>
      <div className="admin-ship-settings-save-btn settings-save-btn">
        <button
          className="btn btn-block"
          type="button"
          onClick={SaveActivityLogSettings}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ActivityLogSettings;
