import DOMPurify from "dompurify";
import React, { Component } from "react";

export default class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: this.props.toastVisible,
    };

    this.showToast();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.toastVisible !== this.props.toastVisible) {
      this.setState({ toastVisible: this.props.toastVisible }, () => {
        this.showToast();
      });
    }
  };

  showToast = () => {
    setTimeout(() => {
      this.setState({ toastVisible: false });
      this.props.closeToast();
    }, 3000);
  };
  render() {
    return this.props.type == "htmlContent" ? (
      <div
        id={"toast-bar-success"}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(this.props.message),
        }}
      />
    ) : (
      <div
        id={
          this.props.type == "success" ? "toast-bar-success" : "toast-bar-error"
        }
      >
        {this.props.message}
      </div>
    );
  }
}
