import Axios from "axios";
import webserviceWrapper from "../helpers/webserviceWrapper";


export const StatisticsService = {
    GetStatisticsReportToken,
    GetStatisticsDropdownData,
    GetReport,
    GetEmbeddedTokenData,
    LoadStatisticsReports
};

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get authtoken for powerbi reports
    <summary> */

function GetStatisticsReportToken(loggedInUserID, userTypeId) {
    var result = webserviceWrapper.get(`/Statistics/GetStatisticsReportToken?loggedInUserID=${loggedInUserID}&userTypeId=${userTypeId}`);
    return result;
}


/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get statistics dropdown data
    <summary> */
function GetStatisticsDropdownData(userTypeId) {
    return webserviceWrapper.get(`/Statistics/GetStatisticsDropdownData?userTypeId=${userTypeId}`);
}

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to return powerbi report
    <summary> */
function GetReport(reportId, groupId, userId, userTypeId) {
    return webserviceWrapper.get(`/Statistics/GetEmbedReportModel?groupId=${groupId}&reportId=${reportId}&userId=${userId}&userTypeId=${userTypeId}`);
}

function GetEmbeddedTokenData(reportId, groupId, userId, userTypeId) {
    return webserviceWrapper.get(`/Statistics/GenerateEmbedToken?groupId=${groupId}&reportId=${reportId}&userId=${userId}&userTypeId=${userTypeId}`);
}


function LoadStatisticsReports(userID, userTypeId, reportName) {
    var response = null;
    if (userTypeId == 2 || userTypeId == 3) {
        if (reportName == 'Number of IHM Executives IU') // 1 
            response = webserviceWrapper.get(`/Statistics/GetNoOfIHMExecutivesInIUNewData?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat items per client/ship IU') // 2 
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatItemClientShipInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'List of ships by date last tracking sheet updated IU') // 3 
            response = webserviceWrapper.get(`/Statistics/GetShipSortedByLastTrackingSheetInIUNew1?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per IU') // 4 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientShipPerUserInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat present per ship IU') // 5
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatPresentShipInIUNewChanges?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items pending for MD/SDoC IU') // 6 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemPendingForMDSDOCNewInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items with 0 communication, with 1,2,3 reminder IU') // 7 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemWith0CommunicationInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC resent by IHM Executive for corrections IU') // 8 
            response = webserviceWrapper.get(`/Statistics/GetNoOfRFCMDSDoCForUserNewInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and pending for IHM Executive review IU') // 9 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPendingMDSDoCForUserInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and reviewed by IHM Executive IU') // 10 
            response = webserviceWrapper.get(`/Statistics/GetNoOfReviewedMDSDoCForUserNewInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC received from supplier per ship IU') // 11 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDOCReceivedPerShipNewInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of non responsive supplier IU') // 12
            response = webserviceWrapper.get(`/Statistics/GetNoOfNonResponsiveSupplierInIUAPI?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of PO line items checked per client/ship/handler/year/month IU') // 13 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOLineItemCheckedNewChangesInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of POs per client/ship/year/month IU') // 14
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOPerClientShipInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of SoC due for renewal IU') // 15 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSOCDueForRenewalInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suppliers per client/ship focused IU') // 16 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSupplierPerClientShipInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items pending for MD/SDoC submission IU') // 17 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatPendingMDSDoCNewChangesInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items shortlisted in a ship IU') // 18 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatInShipNewInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of updated IHM Profiles IU') // 19 
            response = webserviceWrapper.get(`/Statistics/GetNoOfUpdatedUsersInIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of PO Items Shortlisted IU') // 20 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOShortListedInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active vs Passive Ships / Clients IU') // 21
            response = webserviceWrapper.get(`/Statistics/GetNoOfActivePassiveShipClientInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by Hazmat & types of Hazmat present IU')
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithHazmatInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Total number of user accounts IU') // 23 
            response = webserviceWrapper.get(`/Statistics/GetNoOfTotalUserAccountInIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Total Historical Data for Client Vessels IU') // 24 
            response = webserviceWrapper.get(`/Statistics/GetTotalHistoricDataForClientShipInIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Types(Number of ship with Asbestos,lead etc.) IU') // 25 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithTableABHazmatInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of supplier with no email contacts IU') // 26 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuppliersWithNoEmailInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients IU') // 27 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsInTypeIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients sorted by ship counts IU') // 28 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsByShipInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of clients sorted/grouped by total number of contract year IU') // 29 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsContractDurationInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients Year or Month wise to see the progress IU') // 30 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsYearMonthWiseInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD Received IU') // 31 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDReceivedNewInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per Client IU') // 32 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsInTypeIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == "Number of ship added during client's 1st contract period IU") // 33 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipAddedInClientInitialContractYearInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships completed Year1, Year2 IU') // 34 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipCompletedYearInIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships due for contract renewal IU') // 35 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipDueForRenewalInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by date of construction IU') // 36 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByDateOfConstructionInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of ships containing hazmat present IU') // 37
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByHazmatInIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipbuilder IU') // 38 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipBuilderInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipowner / Manager IU') // 39 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipOwnerInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the classification society IU') // 40
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByClassificationSocietyInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the port of registery IU') // 41 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByPortOfRegistryInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Client SOC Expiry Notification IU') // 42
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipBySOCValidityExpiryNewChangesInIU1?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by type of vessel IU') // 43
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByVesselTypeInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Outstanding PO Items wherein MD / SDoC not received IU') // 44 
            response = webserviceWrapper.get(`/Statistics/GetNoMDSDoCRcvdForPOInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == "Number of Ships sorted/grouped by number of documents pending IU") // 45 
            response = webserviceWrapper.get(`/Statistics/GetDocumentsUploadedByShipInIU?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Ships sorted by more number of non responsive suppliers & no email contact suppliers IU') // 46 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByNonResponsiveSupplierInIUUpdated?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC per ship IU') // 47
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDoCPerShipInIUNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
    }
    else {
        if (reportName == 'Number of IHM Executives') // 1 
            response = webserviceWrapper.get(`/Statistics/GetNoOfIHMExecutives?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat items per client/ship') // 2 
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatItemClientShip?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'List of ships sorted by date last tracking sheet updated') // 3 
            response = webserviceWrapper.get(`/Statistics/GetShipSortedByLastTrackingSheetNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per Client') // 4 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientShipPerUser?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of hazmat present per ship') // 5
            response = webserviceWrapper.get(`/Statistics/GetNoOfHazmatPresentShipNewChanges?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items pending for MD/SDoC') // 6 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemPendingForMDSDOCNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of items with 0 communication, with 1,2,3 reminder') // 7 
            response = webserviceWrapper.get(`/Statistics/GetNoOfItemWith0CommunicationNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC resent by IHM Executive for corrections') // 8 
            response = webserviceWrapper.get(`/Statistics/GetNoOfRFCMDSDoCForUserNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and pending for IHM Executive review') // 9 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPendingMDSDoCForUser?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC sent and reviewed by IHM Executive') // 10 
            response = webserviceWrapper.get(`/Statistics/GetNoOfReviewedMDSDoCForUserNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC received from supplier per ship') // 11 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDOCReceivedPerShipNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of non responsive supplier') // 12
            response = webserviceWrapper.get(`/Statistics/GetNoOfNonResponsiveSupplier?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == 'Number of PO line items checked per client/ship/handler/year/month') // 13 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOLineItemUpdated?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of POs per client/ship/year/month') // 14
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOPerClientShip?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of SoC due for renewal') // 15 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSOCDueForRenewal?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suppliers per client/ship focused') // 16 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSupplierPerClientShip?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items pending for MD/SDoC submission') // 17 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatPendingMDSDoCNewChanges?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of suspected hazmat items shortlisted in a ship') // 18 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuspectedHazmatInShipNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of updated IHM Profiles') // 19 
            response = webserviceWrapper.get(`/Statistics/GetNoOfUpdatedUsers?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of PO Items Shortlisted') // 20 
            response = webserviceWrapper.get(`/Statistics/GetNoOfPOShortListed?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active vs Passive Ships / Clients') // 21
            response = webserviceWrapper.get(`/Statistics/GetNoOfActivePassiveShipClient?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by Hazmat & types of Hazmat present') // 22 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithHazmat?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == 'Total number of user accounts') // 23 
            response = webserviceWrapper.get(`/Statistics/GetNoOfTotalUserAccount?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Total Historical Data for Client Vessels') // 24 
            response = webserviceWrapper.get(`/Statistics/GetTotalHistoricDataForClientShip?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Types(Number of ship with Asbestos,lead etc.)') // 25 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipWithTableABHazmat?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of supplier with no email contacts') // 26 
            response = webserviceWrapper.get(`/Statistics/GetNoOfSuppliersWithNoEmail?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients') // 27 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsNewData?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients sorted by ship counts') // 28 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsByShip?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of clients sorted/grouped by total number of contract year') // 29 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsContractDuration?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Clients Year or Month wise to see the progress') // 30 
            response = webserviceWrapper.get(`/Statistics/GetNoOfClientsYearMonthWise?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD Received') // 31 
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDReceivedNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Active / InActive Ships per Client') // 32 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShips?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == "Number of ship added during client's 1st contract period") // 33 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipAddedInClientInitialContractYearNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships completed Year1, Year2') // 34 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipCompletedYear?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships due for contract renewal') // 35 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipDueForRenewal?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by date of construction') // 36 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByDateOfConstruction?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of ships containing hazmat present') // 37
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByHazmat?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipbuilder') // 38 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipBuilder?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by shipowner') // 39 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByShipOwnerNewData?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the classification society') // 40
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipGroupedByClassificationSociety?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of Ships grouped by the port of registery') // 41 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByPortOfRegistry?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Client SOC Expiry Notification') // 42
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipBySOCValidityExpiryNewChanges1?LoggedInUserID=${userID}&UserRole=${userTypeId}`);

        else if (reportName == 'Number of Ships grouped by type of vessel') // 43
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipsByVesselType?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Outstanding PO Items wherein MD / SDoC not received') // 44 
            response = webserviceWrapper.get(`/Statistics/GetNoMDSDoCRcvdForPONew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == "Number of Ships sorted/grouped by number of documents pending") // 45 
            response = webserviceWrapper.get(`/Statistics/GetDocumentsUploadedByShipNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Ships sorted by more number of non responsive suppliers & no email contact suppliers') // 46 
            response = webserviceWrapper.get(`/Statistics/GetNoOfShipByNonResponsiveSupplierUpdated?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
        else if (reportName == 'Number of MD/SDoC per ship') // 47
            response = webserviceWrapper.get(`/Statistics/GetNoOfMDSDoCPerShipNew?LoggedInUserID=${userID}&UserRole=${userTypeId}`);
    }
    return response;
}