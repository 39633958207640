import React, { Component } from 'react'

export default class TagRenderer extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        
        let classPath = this.props.path === "" ? "pending-width manual-status"  : this.props.path.toLowerCase().includes('pdf') ? "pending-width pdf-status" : this.props.path.includes('excel') ? "pending-width excel-status" : this.props.path.includes('xlsx') ? "pending-width excel-status" : "pending-width api-status";
        return (
            <div className='manage-client-head'>
                <span className={classPath}></span>
            </div>
        )
    }
}
