import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { helperText } from "../../helpers/helperText";
import ViewModal from "../../components/ViewModal";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import deleteIcon from "../../assets/images/delete icon.png";
import admindownload from "../../assets/images/admin-download.png";
import admineye from "../../assets/images/admin-eye.png";
import IHMDatabaseRegistryModal from "./IHMDatabaseRegistryModal";
import { IHMDatabaseRegistryService } from "../../services/ihmdatabaseregistry.service";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Module, TitlePageNames } from "../../helpers/enums";
import { UserAction } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";

class ManageIHMDatabaseRegistry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedIHMDocument: [],
      defaultIHMDoc: [],
      // colDefs: this.gridColumns,
      clientNameList: [],
      dropdown_clientName: 0,
      shipNameList: [],
      defaultShipNameList: [],
      dropdown_shipName: 0,
      regionList: [],
      dropdown_region: 0,
      defaultRegionList: [],
      supplierList: [],
      dropdown_supplier: 0,
      documentTypeList: [],
      dropdown_documenttype: 0,
      dropdown_hazmatpresent: 0,
      documentNameList: [],
      defaultDocumentList: [],
      dropdown_documentname: 0,
      lineNameList: [],
      dropdown_linename: 0,
      showUploadModal: false,
      userId: 0,
      flagStateList: [],
      trackingToolYearList: [],
      showViewModal: false,
      filePathToView: "",
      selectedDocFilePath: [],
      lineNameOBJList: [],
      showConfirmationPopup: false,
      selectdDocDeleteId: 0,
      accessList: [],
      typeId: 0,
      searchedText: "",
      gridApi: null,
      userRolePermissionRead: false,
      trackingToolList: []
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.IHMDATABASEREGISTRY);

    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          userId: userData.id,
          typeId: userData.typeId,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        () => {
          this.manageIHMDatabaseRegistryData();
        }
      );
    }
  }

  /* componentDidUpdate() {
    if (this.state.gridApi) {
      this.state.gridApi.paginationGoToPage(0);
    }
  } */

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:gets ihm database and  registry grid data
   <summary> */
  manageIHMDatabaseRegistryData = async () => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.ihmdatabaseAccess == UserAction.READ,
      });

      const { userId, typeId } = this.state;

      const response =
        await IHMDatabaseRegistryService.GetIHMDatabaseRegistryData(userId, typeId);

      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        }
        var client = response.data.dropdownList.clientList;
        var ship = response.data.dropdownList.shipList;
        var region = response.data.dropdownList.regionList;
        var supplier = response.data.dropdownList.supplierList;
        var flagstate = response.data.dropdownList.flagStateList;
        var documenttype = response.data.dropdownList.documentTypeList;
        var trackingToolList = response.data.dropdownList.trackingtoolList;

        var linename = Array.from([
          ...new Set(
            response.data.dropdownList.lineNameList.map((l) => l.label)
          ),
        ]);
        var linenameobj = response.data.dropdownList.lineNameList;
        var year = response.data.dropdownList.year;
        let documentNames = Array.from([
          ...new Set(
            response.data.ihmDatabaseRegistryList.map((l) => ({
              documentName: l.customeDocumentName,
              clientId: l.clientId
            }))
          ),
        ]).sort((a, b) => a.documentName.localeCompare(b.documentName));
        this.setState(
          {
            mappedIHMDocument: response.data.ihmDatabaseRegistryList,
            defaultIHMDoc: response.data.ihmDatabaseRegistryList,
            clientNameList: client,
            shipNameList: ship,
            regionList: region,
            supplierList: supplier,
            documentTypeList: documenttype,
            flagStateList: flagstate,
            lineNameList: linename,
            trackingToolYearList: year,
            lineNameOBJList: linenameobj,
            defaultDocumentList: documentNames,
            documentNameList: documentNames,
            defaultShipNameList: ship,
            defaultRegionList: region,
            trackingToolList: trackingToolList
          },
          () => {
            this.filterGridView();
          }
        );
      } else {
        this.setState({
          mappedIHMDocument: [],
          defaultIHMDoc: [],
          clientNameList: [],
          shipNameList: [],
          regionList: [],
          supplierList: [],
          documentTypeList: [],
          flagStateList: [],
          lineNameList: [],
          trackingToolYearList: [],
          lineNameOBJList: [],
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          documentNameList: [],
          defaultDocumentList: [],
          defaultShipNameList: [],
          defaultRegionList: [],
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:closes upload document modal
       <summary> */
  onCloseClick = () => {
    this.setState({ showUploadModal: false });
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:show upload document modal 
   <summary> */
  showDocumentUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  showDeleteDocModal = (id) => {
    this.setState({ showConfirmationPopup: true, selectdDocDeleteId: id });
  };

  gridColumns = () => [
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: (params) => params.data.documentFileMediaId != null ? true : false,
      width: 50,
      unSortIcon: false,
    },
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      tooltipField: "serialNumber",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Document Name",
      field: "customeDocumentName",
      tooltipField: "customeDocumentName",
      width: 190,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Document Type",
      field: "documentTypeName",
      tooltipField: "documentTypeName",
      width: 190,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client Name",
      field: "clientName",
      tooltipField: "clientName",
      width: 150,
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Region",
      field: "regionName",
      tooltipField: "regionName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Supplier Name",
      field: "supplierName",
      tooltipField: "supplierName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Issued By",
      field: "issuedBy",
      tooltipField: "issuedBy",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Product Description",
      field: "productDescription",
      tooltipField: "productDescription",
      width: 200,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Item Name",
      field: "lineName",
      tooltipField: "lineName",
      width: 180,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Hazmat Present",
      field: "hazmatPresentName",
      tooltipField: "hazmatPresentName",
      width: 180,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Created By",
      field: "createdByName",
      tooltipField: "createdByName",
      width: 150,
    },

    {
      headerName: "Date of Creation",
      field: "dateCreatedOn",
      tooltipField: "dateCreatedOn",
      width: 190,
    },
    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admineye}
          title="View"
          onClick={() => this.viewIHMDocData(params.data.filePath, params.data)}
          width="20px"
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => {
        return (
          params.data.documentFileMediaId != null ?
            <img
              className="cursor-class"
              src={admindownload}
              title="Download"
              onClick={() =>
                this.downloadIHMDocData(params.data.filePath)
              }
              width="15px"
            />
            : <></>)
      },
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      hide: this.state.userRolePermissionRead,
      cellRenderer: (params) => {
        return (
          // params.data.documentFileMediaId != null &&
          // (
          <img
            className="cursor-class"
            src={deleteIcon}
            style={{ opacity: this.state.userRolePermissionRead ? "0.6" : "1" }}
            title="Delete"
            onClick={(e) => {
              this.state.userRolePermissionRead
                ? e.preventDefault()
                : this.showDeleteDocModal(params.data.documentFileMediaId == null || params.data.documentFileMediaId == undefined ? params.data.mdSDocId : params.data.documentFileMediaId);
            }}
            width="13px"
          />
        )
        //)
      },
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
  ];
  onSaveNewTypeClick = async (typeName) => {

    this.setState({
      isLoading: true,
    });

    var docData = {
      TypeName: typeName,
      Module: Module.IHMRegistry
    }

    var responseSaved = await CommonService.AddNewDocumentType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data)

      const { userId, typeId } = this.state;

      const response =
        await IHMDatabaseRegistryService.GetIHMDatabaseRegistryData(userId, typeId);

      if (response.data != null && response.data != undefined) {
        this.setState({
          documentTypeList: response.data.dropdownList.documentTypeList,
          isLoading: false,
        });
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };
  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:view ihm documents
   <summary> */
  viewIHMDocData = (filePath, documentData) => {

    if (filePath != null && filePath != "")
      this.setState({ showViewModal: true, filePathToView: filePath });
    else if (documentData?.mdSDocId != 0) {
      AddLocalStorageData("mdsdocId", documentData.mdSDocId);
      AddLocalStorageData("disableMdsdocFormFields", true);
      AddLocalStorageData("location", "IHMDatabaseRegistry");
      this.props.navigate(`/MDSDoC`);
    }

  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:download ihm documents
   <summary> */
  downloadIHMDocData = async (filePath) => {
    try {
      this.setState({
        isLoading: true,
      });

      let tFileIDlist = [];
      if (filePath == "") {
        this.state.selectedDocFilePath.forEach((element) => {
          var tId = element;
          tFileIDlist.push(tId);
        });
      } else {
        tFileIDlist.push(filePath);
      }
      if (tFileIDlist.length == 0) {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.noDocumentsSelected,
        });
        return;
      }

      if (tFileIDlist.length > 0) {
        tFileIDlist.forEach((element) => {
          var fname = "";
          var lastItem = element.split("/").pop();
          fname = lastItem;

          const link = document.createElement("a");
          // link.href = element;
          link.setAttribute("href", element);
          link.setAttribute("download", element);

          var ext = element.substring(element.lastIndexOf(".") + 1);
          if (ext == "pdf" || ext == "PDF") {
            link.setAttribute("target", "_blank");
          } else {
            link.setAttribute("target", "");
          }
          link.download = fname;
          document.body.appendChild(link);

          link.click();
        });
        //	document.body.removeChild( link );

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:deletes ihm document
   <summary> */
  deleteIHMDocData = async () => {
    try {
      const { selectdDocDeleteId, typeId, userId } = this.state;
      this.setState({
        isLoading: true,
      });
      var response = await IHMDatabaseRegistryService.DeleteIHMDocData(selectdDocDeleteId, typeId, userId);

      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      } else if (response != null) {
        this.setState(
          {
            mappedIHMDocument: response.data.ihmDatabaseRegistryList,
            defaultIHMDoc: response.data.ihmDatabaseRegistryList,
            toastVisible: true,
            toastMessage: helperText.global.deletedDocument,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:saves ihm document data
   <summary> */
  onUploadClick = async (data) => {
    const { userId, typeId } = this.state;

    this.setState({ isLoading: true });

    let ihmDocumentData = new FormData();
    ihmDocumentData.append("DocumentTypeId", data.dropdown_documenttype);
    ihmDocumentData.append("ClientId", data.dropdown_client);
    ihmDocumentData.append("ShipId", data.dropdown_ship);
    ihmDocumentData.append("SupplierId", data.dropdown_supplier);
    ihmDocumentData.append("LineName", data.lineName);
    ihmDocumentData.append(
      "HazmatPresent",
      data.dropdown_hazmatpresent == ""
        ? ""
        : data.dropdown_hazmatpresent == "yes"
          ? true
          : false
    );
    //ihmDocumentData.append("CustomeDocumentName",data.dropdown_hazmatpresent);
    ihmDocumentData.append("FlagStateId", data.dropdown_flagstate);
    ihmDocumentData.append(
      "IssueDate",
      data.issueDate == "" ? "" : convertDate(data.issueDate)
    );
    ihmDocumentData.append(
      "ExpiryDate",
      data.expiryDate == "" ? "" : convertDate(data.expiryDate)
    );
    ihmDocumentData.append("TrackingToolYear", data.dropdown_year);
    // ihmDocumentData.append("IssuedBy",data.expiryDate);
    // ihmDocumentData.append("PreparedBy",data.expiryDate);
    ihmDocumentData.append("loggedInUserID", userId);
    ihmDocumentData.append("IssuedBy", data.issuedBy);
    ihmDocumentData.append("PreparedBy", data.preparedBy);
    ihmDocumentData.append("ProjectNo", data.project_num);
    ihmDocumentData.append("typeId", typeId);

    let file = data.TFile;
    if (file != "" && file.size !== null && file.size !== undefined) {
      ihmDocumentData.append("IHMDocumentAttached", file);
      var fileName = file.name.substring(0, file.name.lastIndexOf("."));
      ihmDocumentData.append("CustomeDocumentName", fileName);
    }

    var responseSaved =
      await IHMDatabaseRegistryService.AddIHMDatabaseRegistryData(
        ihmDocumentData
      );
    AddLocalStorageData("selectedShipTTool", null);
    if (data.mdSoc && data.dropdown_client != 0) {
      let dropdownData = {
        clientId: parseInt(data.dropdown_client, 10),
        shipId: parseInt(data.dropdown_ship, 10),
        supplierId: parseInt(data.dropdown_supplier, 10),
      };
      AddLocalStorageData("selectedShipTTool", JSON.stringify(dropdownData));

      AddLocalStorageData("location", "/ManageIHMDatabaseRegistry");
      this.props.navigate("/TrackingTool");
    }
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        showUploadModal: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.documnetUploadedSuccess,
        isLoading: false,
      });
      await this.manageIHMDatabaseRegistryData();
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = [];

      selectedRows.forEach((element) => {
        if (element.filePath != null && element.filePath != "")
          listSelectedTTId.push(element.filePath);
      });
      this.setState({ selectedDocFilePath: listSelectedTTId });
    } else {
      this.setState({ selectedDocFilePath: [] });
    }
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
    });
  };

  onFilterSelected = (e) => {
    const {
      defaultShipNameList,
      defaultDocumentList
    } = this.state;
    var { name, value } = e.target;
    if (e.target.name == "dropdown_clientName") {
      if (e.target.value != "0") {
        var filteredShipList = defaultShipNameList.filter(
          (x) => x.parentValue == e.target.value
        );

        var filteredDocumentList = defaultDocumentList.filter(x => x.clientId == value)

        this.setState({ shipNameList: filteredShipList, documentNameList: filteredDocumentList }); //, regionList: filteredYearList
        if (filteredShipList.length == 0)
          this.setState({ dropdown_shipName: 0 });
      } else {
        this.setState({ shipNameList: defaultShipNameList });
      }
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:filters grid based on selected filter
   <summary> */
  filterGridView = () => {
    const {
      dropdown_clientName,
      dropdown_shipName,
      dropdown_region,
      dropdown_supplier,
      dropdown_documentname,
      dropdown_documenttype,
      dropdown_linename,
      dropdown_hazmatpresent,
      searchedText,
    } = this.state;

    var filterList = [];
    var ihmDocList = this.state.defaultIHMDoc;

    if (
      dropdown_clientName == 0 &&
      dropdown_shipName == 0 &&
      dropdown_region == 0 &&
      dropdown_supplier == 0 &&
      dropdown_documentname == 0 &&
      dropdown_documenttype == 0 &&
      dropdown_linename == 0 &&
      dropdown_hazmatpresent == 0
    )
      filterList = this.state.defaultIHMDoc;
    else {
      var isFilterAdded = false;
      if (dropdown_clientName != "" && dropdown_clientName != 0) {
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.clientId == parseInt(dropdown_clientName, 10)
            )
            : ihmDocList.filter(
              (x) => x.clientId == parseInt(dropdown_clientName, 10)
            );
        isFilterAdded = true;
      }
      if (dropdown_shipName != "" && dropdown_shipName != "0") {
        filterList = isFilterAdded
          ? filterList.filter(
            (x) => x.shipId == parseInt(dropdown_shipName, 10)
          )
          : ihmDocList.filter((x) => x.shipId == dropdown_shipName);
        isFilterAdded = true;
      }

      if (dropdown_region != "" && dropdown_region != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.regionID == dropdown_region)
          : ihmDocList.filter((e) => e.regionID == dropdown_region);
        isFilterAdded = true;
      }

      if (dropdown_supplier != "" && dropdown_supplier != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.supplierId == dropdown_supplier)
          : ihmDocList.filter((e) => e.supplierId == dropdown_supplier);
        isFilterAdded = true;
      }

      if (dropdown_documentname != "" && dropdown_documentname != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) =>
            e.customeDocumentName.includes(dropdown_documentname)
          )
          : ihmDocList.filter((e) =>
            e.customeDocumentName.includes(dropdown_documentname)
          );
        isFilterAdded = true;
      }

      if (dropdown_documenttype != "" && dropdown_documenttype != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.documentTypeId == dropdown_documenttype)
          : ihmDocList.filter((e) => e.documentTypeId == dropdown_documenttype);
        isFilterAdded = true;
      }

      if (dropdown_linename != "" && dropdown_linename != null && dropdown_linename != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.lineName != null && e.lineName.includes(dropdown_linename))
          : ihmDocList.filter((e) => e.lineName != null && e.lineName.includes(dropdown_linename));
        isFilterAdded = true;
      }

      if (dropdown_hazmatpresent != "" && dropdown_hazmatpresent != "0") {
        filterList = isFilterAdded
          ? filterList.filter(
            (e) => e.hazmatPresentName == dropdown_hazmatpresent
          )
          : ihmDocList.filter(
            (e) => e.hazmatPresentName == dropdown_hazmatpresent
          );
        isFilterAdded = true;
      }
    }

    /* <summary>
     date: 19/04/2023
     Name: SYJ
     description: Filter the List to Display in the Grid Depending on the search text
     <summary> */
    if (searchedText == "") {
    } else {
      let value = searchedText.toString().toLowerCase();
      filterList = filterList.filter(
        (t) =>
          (t.clientName != null &&
            t.clientName.toString().toLowerCase().includes(value)) ||
          (t.documentTypeName != null &&
            t.documentTypeName.toString().toLowerCase().includes(value)) ||
          (t.customeDocumentName != null &&
            t.customeDocumentName.toString().toLowerCase().includes(value)) ||
          (t.hazmatPresentName != null &&
            t.hazmatPresentName.toString().toLowerCase().includes(value)) ||
          (t.lineName != null &&
            t.lineName.toString().toLowerCase().includes(value)) ||
          (t.productDescription != null &&
            t.productDescription.toString().toLowerCase().includes(value)) ||
          (t.regionName != null &&
            t.regionName.toString().toLowerCase().includes(value)) ||
          (t.shipName != null &&
            t.shipName.toString().toLowerCase().includes(value)) ||
          (t.supplierName != null &&
            t.supplierName.toString().toLowerCase().includes(value)) ||
          (t.dateCreatedOn != null &&
            t.dateCreatedOn.toString().toLowerCase().includes(value)) ||
          (t.createdByName != null &&
            t.createdByName.toString().toLowerCase().includes(value)) ||
          (t.issuedBy != null &&
            t.issuedBy.toString().toLowerCase().includes(value)) ||
          (t.flagStateName != null &&
            t.flagStateName.toString().toLowerCase().includes(value))
      );
    }

    this.setState({
      mappedIHMDocument: filterList,
    });
  };

  onViewFileDownload = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess
      })
    } catch (e) {
    }
  };

  /* <summary>
     date: 19/04/2023
     Name: SYJ
     description: On content Search
     <summary> */
  onSearch = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        searchedText: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  /* <summary>
     date: 13/02/2023
     Name: HV
     description: clearing filter
     <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_clientName: "0",
        dropdown_shipName: "0",
        dropdown_region: "0",
        dropdown_supplier: "0",
        dropdown_documentname: "0",
        dropdown_documenttype: "0",
        dropdown_linename: "0",
        dropdown_hazmatpresent: "0",
        searchedText: "",
        documentNameList: this.state.defaultDocumentList
      },
      () => {
        this.filterGridView();
      }
    );
  };

  onBackClick = () => {
    var loc = GetLocalStorageDataWithoutParse("location");
    AddLocalStorageData("location", "");
    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    }
    else this.props.navigate("/Dashboard");
  };


  render() {
    const {
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      mappedIHMDocument,
      colDefs,
      clientNameList,
      dropdown_clientName,
      shipNameList,
      dropdown_shipName,
      regionList,
      dropdown_region,
      supplierList,
      dropdown_supplier,
      documentTypeList,
      dropdown_documenttype,
      dropdown_hazmatpresent,
      showUploadModal,
      documentNameList,
      dropdown_documentname,
      lineNameList,
      dropdown_linename,
      flagStateList,
      trackingToolYearList,
      showViewModal,
      filePathToView,
      lineNameOBJList,
      defaultShipNameList,
      defaultRegionList,
      showConfirmationPopup,
      accessList,
      trackingToolList
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"IHMDatabaseRegistry"} />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="manage-supplier">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    {/* <a href="Dashboard"> */}
                    <img src="images/back.png" alt="Back"
                      onClick={this.onBackClick} />
                    {/* </a> */}
                    <h5>IHM Database & Registry</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <Searchbar
                    placeholder={"Client Name, Ship Name, Region etc..."}
                    onChange={this.onSearch}
                    value={this.state.searchedText}
                  />
                </div>
              </div>
              {/* <!-- Mobile View --> */}
              <div className="training-mobile">
                <div className="accordion-filter-dropdown">
                  <div id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="supplier-filter">
                            <div className="row">
                              <div className="col-lg-12 filter-flex-container wrap">
                                <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"clientName"}>
                                  <Dropdown
                                    dropdownLabel="Client Name:"
                                    name="clientName"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_clientName}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {clientNameList == null
                                      ? null
                                      : clientNameList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_clientName > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"clientName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {clientNameList != null && clientNameList.filter(x => x.value == dropdown_clientName)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"shipName"}>
                                  <Dropdown
                                    dropdownLabel="Ship Name:"
                                    name="shipName"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_shipName}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {shipNameList == null
                                      ? null
                                      : shipNameList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_shipName > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"shipName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"regionName"}>
                                  <Dropdown
                                    dropdownLabel="Region:"
                                    name="region"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_region}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {regionList == null
                                      ? null
                                      : regionList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_region > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"regionName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"supplierName"}>
                                  <Dropdown
                                    dropdownLabel="Supplier Name:"
                                    name="supplier"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_supplier}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {supplierList == null
                                      ? null
                                      : supplierList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_supplier > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"supplierName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {supplierList != null && supplierList.filter(x => x.value == dropdown_supplier)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>
                                <div className="filter-box filter-flex-item">
                                  <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"docName"}>
                                    <Dropdown
                                      dropdownLabel="Document Name:"
                                      name="documentname"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_documentname}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {documentNameList == null
                                        ? null
                                        : documentNameList.map((item, key) => (
                                          <option key={key} value={item.documentName}>
                                            {item.documentName}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_documentname != undefined ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"docName"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {supplierList != null && dropdown_documentname}
                                      </UncontrolledTooltip>) : null}
                                  </div>
                                </div>
                                <div className="filter-box filter-flex-item">
                                  <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"docType"}>
                                    <Dropdown
                                      dropdownLabel="Document Type:"
                                      name="documenttype"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_documenttype}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {documentTypeList == null
                                        ? null
                                        : documentTypeList.filter(x => x.label != "Other").map((item, key) => (
                                          <option
                                            key={key}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_documenttype > 0 ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"docType"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                                      </UncontrolledTooltip>) : null}
                                  </div>
                                </div>

                                <div className="filter-box filter-flex-item">
                                  <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"lineNo1"}>
                                    <Dropdown
                                      dropdownLabel="Item Name:"
                                      name="linename"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_linename}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {lineNameList == null
                                        ? null
                                        : lineNameList.map((item, key) => (
                                          <option key={key} value={item}>
                                            {item}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_linename != undefined && dropdown_linename != 0 ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"lineNo1"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {dropdown_linename}
                                      </UncontrolledTooltip>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="form-group form-label dropdown-form admin-client-form filter-flex-item">
                                  <Dropdown
                                    dropdownLabel="Hazmat Present:"
                                    name="hazmatpresent"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_hazmatpresent}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <option key={1} value={"Yes"}>
                                      {"No"}
                                    </option>
                                    <option key={2} value={"No"}>
                                      {"No"}
                                    </option>
                                    ;
                                  </Dropdown>
                                </div>
                                <div
                                  className="col-12 col-md-4 col-lg-3 "
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-blue-bg"
                                    onClick={this.clearAllFilter}
                                    style={{ marginTop: 15 }}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="filter-btn training-add-button"
                    style={{ marginTop: 0 }}
                  >
                    <button
                      type="button"
                      disabled={
                        accessList != null && accessList.ihmdatabaseAccess == UserAction.READ
                          ? true
                          : false
                      }
                      id="upld-btn-training"
                      className="btn btn-blue-bg "
                      data-bs-toggle="modal"
                      data-bs-target="#IHMDBRegistryUpload-modal"
                      onClick={this.showDocumentUploadModal}
                    >
                      Upload Document
                    </button>
                    &nbsp;{" "}
                    {mappedIHMDocument?.length > 0 ? (
                      <span>
                        <img
                          title="Download"
                          id="training-admin-dwnld-icon"
                          src="./images/admin-tracking-download.png"
                          alt="download-document"
                          onClick={() => {
                            this.downloadIHMDocData("");
                          }}
                          className="cursor-class"
                        />
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <!-- DESKTOP --> */}
              <div className="training-desktop">
                <div className="desktop-class">
                  <div className="supplier-filter">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"clientName1"}>
                          <Dropdown
                            dropdownLabel="Client Name:"
                            name="clientName"
                            onChange={this.onFilterSelected}
                            selected={dropdown_clientName}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {clientNameList == null
                              ? null
                              : clientNameList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_clientName > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"clientName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {clientNameList != null && clientNameList.filter(x => x.value == dropdown_clientName)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"shipName1"}>
                          <Dropdown
                            dropdownLabel="Ship Name:"
                            name="shipName"
                            onChange={this.onFilterSelected}
                            selected={dropdown_shipName}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {shipNameList == null
                              ? null
                              : shipNameList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_shipName > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"shipName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"regionName1"}>
                          <Dropdown
                            dropdownLabel="Region:"
                            name="region"
                            onChange={this.onFilterSelected}
                            selected={dropdown_region}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {regionList == null
                              ? null
                              : regionList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>

                          {dropdown_region > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"regionName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"supplierName1"}>
                          <Dropdown
                            dropdownLabel="Supplier Name:"
                            name="supplier"
                            onChange={this.onFilterSelected}
                            selected={dropdown_supplier}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {supplierList == null
                              ? null
                              : supplierList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_supplier > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"supplierName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {supplierList != null && supplierList.filter(x => x.value == dropdown_supplier)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"docName1"}>
                          <Dropdown
                            dropdownLabel="Document Name:"
                            name="documentname"
                            onChange={this.onFilterSelected}
                            selected={dropdown_documentname}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {documentNameList == null
                              ? null
                              : documentNameList.map((item, key) => (
                                <option key={key} value={item.documentName}>
                                  {item.documentName}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_documentname != "0" ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"docName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {documentNameList != null && dropdown_documentname}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"docType1"}>
                          <Dropdown
                            dropdownLabel="Document Type:"
                            name="documenttype"
                            onChange={this.onFilterSelected}
                            selected={dropdown_documenttype}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {documentTypeList == null
                              ? null
                              : documentTypeList.filter(x => x.label != "Other").map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_documenttype > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"docType1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"lineNo1"}>
                          <Dropdown
                            dropdownLabel="Item Name:"
                            name="linename"
                            onChange={this.onFilterSelected}
                            selected={dropdown_linename}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {lineNameList == null
                              ? null
                              : lineNameList.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_linename != 0 && dropdown_linename != undefined ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"lineNo1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {lineNameList != null && lineNameList.filter(x => x == dropdown_linename)}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item">
                          <Dropdown
                            dropdownLabel="Hazmat Present:"
                            name="hazmatpresent"
                            onChange={this.onFilterSelected}
                            selected={dropdown_hazmatpresent}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            <option key={1} value={"Yes"}>
                              {"Yes"}
                            </option>
                            <option key={2} value={"No"}>
                              {"No"}
                            </option>
                            ;
                          </Dropdown>
                        </div>
                      </div>
                      <div
                        className="col-12 col-md-4 col-lg-3 "
                        style={{ alignSelf: "center" }}
                      >
                        <button
                          type="button"
                          className="btn btn-blue-bg"
                          onClick={this.clearAllFilter}
                          style={{ marginTop: 15 }}
                        >
                          Reset
                        </button>
                      </div>
                      <div
                        className="col-12 training-add-button"
                        style={{ marginTop: 0 }}
                      >
                        <button
                          type="button"
                          id="upld-btn-training"
                          className="btn btn-blue-bg "
                          disabled={
                            accessList != null
                              ? accessList.ihmdatabaseAccess == UserAction.READ
                                ? true
                                : false
                              : false
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#IHMDBRegistryUpload-modal"
                          onClick={this.showDocumentUploadModal}
                        >
                          Upload Document
                        </button>{" "}
                        &nbsp;{" "}
                        {mappedIHMDocument?.length > 0 ? (
                          <span>
                            <img
                              title="Multi Download"
                              id="training-admin-dwnld-icon"
                              src="./images/admin-tracking-download.png"
                              alt=""
                              onClick={() => {
                                this.downloadIHMDocData("");
                              }}
                              className="cursor-class"
                            />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--   grid Code for manage supplier --> */}
              <div className="training-grid">
                <div className="row">
                  <div className="col-md-12">
                    <AGGrid
                      rowData={mappedIHMDocument}
                      colDefs={this.gridColumns()}
                      onGridReady={this.onGridReady}
                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                      rowSelection="multiple"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {showUploadModal && (
          <IHMDatabaseRegistryModal
            onCloseClick={this.onCloseClick}
            flagStateList={flagStateList}
            clientList={clientNameList}
            shipList={defaultShipNameList}
            documentTypeList={documentTypeList}
            supplierList={supplierList}
            lineNameList={lineNameList}
            trackingToolList={trackingToolList}
            onUploadClick={this.onUploadClick}
            trackingToolYearList={trackingToolYearList}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
          />
        )}
        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View IHM Database Registry File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          ></ViewModal>
        )}

        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.deleteIHMDocData}
          />
        )}

        <Footer />
      </>
    );
  }
}
export default withRouter(ManageIHMDatabaseRegistry);
