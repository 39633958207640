import React from "react";
//import CKEditor from "react-ckeditor-component";
//import RichTextEditor from "../../components/RichTextEditor";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { toolbarOptions } from "../../helpers/enums";
import { ConvertToHtml, sanitizeHTML, ConvertFromHTML } from "../../helpers";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


// const createEditorState = (htmlContent) => {
//     const blocksFromHTML = convertFromHTML(htmlContent);
//     const contentState = ContentState.createFromBlockArray(
//         blocksFromHTML.contentBlocks,
//         blocksFromHTML.entityMap
//     );
//     return EditorState.createWithContent(contentState);
// };

const ViewHistoryModal = ({
    ToData,
    CCData,
    BCCData,
    SubjectData,
    MailBody,
    Attachment,
}) => {
    const editorState = ConvertFromHTML(MailBody);
    const ckEditorRemoveButtons = "ImageButton,Button,Undo,SelectAll,Spell Checker,Checkbox,Redo,Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Form,Radio,TextField,Textarea,Select,HiddenField,About,CopyFormatting,RemoveFormat,Language,Flash,Iframe";

    return (
        <section className="communications">
            <div className="modal fade" id="view-history" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog custom-modal-dialog-width modal-dialog-centered">
                    <div className="modal-content custom-modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Email Preview</h5>
                            <a href="" data-bs-dismiss="modal"><img className="close-img-icon" src="images/modal-close.png" width="25px" /></a>
                        </div>
                        <div className="modal-body editorClass">
                            <div className="row">
                                <div className="col-12 col-md-2  email-template-bottomspace" style={{ marginBottom: "8px" }} >
                                    <label className="label-class">To:</label>
                                </div>
                                <div className="col-md-10 col-12 email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <input className="modal-field-width" style={{ border: 0 }} type="text" id="to" value={ToData} name="to" readOnly />
                                    <br />
                                </div>
                                <div className="col-12 col-md-2  email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <label className="label-class">CC:</label>
                                </div>
                                <div className="col-md-10 col-12 email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <input className="modal-field-width" style={{ border: 0 }} type="text" id="cc" value={CCData} name="cc" readOnly />
                                    <br />
                                </div>
                                <div className="col-12 col-md-2  email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <label className="label-class">BCC:</label>
                                </div>
                                <div className="col-md-10 col-12 email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <input className="modal-field-width" style={{ border: 0 }} type="text" id="bcc" value={BCCData} name="bcc" readOnly />
                                    <br />
                                </div>
                                <div className="col-12 col-md-2 email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <label className="label-class">Subject:</label>
                                </div>
                                <div className="col-md-10 col-12  email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <input className="modal-field-width" style={{ border: 0 }} type="text" id="subject" value={SubjectData} name="subj" readOnly />
                                    <br />
                                </div>
                                <div className="col-md-2 col-12  email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <label className="label-class">Message:</label>
                                </div>
                                <div className="col-12 col-md-10  email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <Editor
                                        editorState={editorState}
                                        editorStyle={{ height: 350, border: '1px solid #ced4da', paddingLeft: '10px' }}
                                        wrapperClassName="demo-wrapper "
                                        editorClassName="editer-content editor-height"
                                        toolbarClassName="toolbar-class candrag"
                                        toolbar={toolbarOptions}
                                    />

                                    <br />
                                </div>
                                <div className="col-md-2 col-12  email-template-bottomspace" style={{ marginBottom: "8px" }}>
                                    <label className="label-class">Attachment:</label>
                                </div>
                                <div style={{ marginLeft: "90px" }}>
                                    {
                                        Attachment.map((item, index) => {
                                            return (
                                                <div className="col-md-6">
                                                    <label htmlFor="actual-btn" className="md-sdoc-attach-btn">
                                                        {item}
                                                    </label>
                                                </div>



                                            )
                                        })
                                    }
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ViewHistoryModal;