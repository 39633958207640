import React, { Component } from 'react'

export default class StatusRenderer extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        let classPath = this.props.path === 1 ? "pending-width pending-status" : "";
        return (
            <div className='manage-client-head'>
                <span className={classPath}></span>
            </div>
        )
    }
}
