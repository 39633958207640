import React, { Component } from "react";
import AGGrid from "../../components/AGGrid";
import modalCloseIcon from "../../assets/images/modal-close-blue.png";

export default class ZoomImportPO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomView: this.props.zoomView,
    };
  }
  componentDidMount() {}

  // onGridReady = (params) => {
  //     this.gridApi = params.api;
  //     this.gridColumnApi = params.columnApi;
  //     //this.props.onGridReady();
  //     this.selectHazmatItems();

  // };

  componentDidUpdate = (prevProps) => {
    if (prevProps.zoomView !== this.props.zoomView) {
      this.selectHazmatItems();
      this.setState({ zoomView: this.props.zoomView });
    }
  };

  selectHazmatItems = () => {
    if (this.gridApi != undefined)
      this.gridApi.forEachNode(function (node) {
        node.setSelected(node.data.isSuspectedHazmat === true);
      });
  };

  render() {
    var {
      rowData,
      colDefs,
      frameworkComponents,
      onSelectionChanged,
      closeModal,
    } = this.props;

    var { zoomView } = this.state;
    return (
      // <section>
      <>
        <div
          className="modal-backdrop fade show"
          style={{ display: zoomView ? "block" : "none" }}
        ></div>
        <div className="modal" style={{ display: zoomView ? "block" : "none" }}>
          <section>
            <div
              className="zoom-tracking-tool tracking-tool-grid"
              style={{ display: zoomView ? "block" : "none" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="top-container">
                      <span>
                        {/* <button type="button" onClick={() => closeModal("zoomView", false)} className="close-btn modal-close-btn" > <img style={{ zIndex: 2 }} src={modalCloseIcon}
                                                    alt="modal-close" /></button> */}
                        <img
                          style={{ zIndex: 2, cursor: "pointer" }}
                          src={modalCloseIcon}
                          alt="modal-close"
                          onClick={() => closeModal("zoomView", false)}
                        />
                      </span>
                    </div>

                    <div className="table-responsive">
                      <AGGrid
                        rowData={rowData}
                        colDefs={colDefs}
                        rowSelection="multiple"
                        pagination="50"
                        frameworkComponents={frameworkComponents}
                        rowMultiSelectWithClick={true}
                        onGridReady={this.onGridReady}
                        onSelectionChanged={onSelectionChanged}
                        height="550px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
