import React, { Component } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { changePageTitle, withRouter, isEmailValid, GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../helpers";
import { models, Report, Embed, service, Page } from "powerbi-client";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { StatisticsService } from "../../services/statistics.service";
import * as globalFunctions from "../../helpers/utilities";


class StatisticsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statisticsReport: [],
      powerBIReportID: GetLocalStorageDataWithoutParse("powerBIReportID"),
      tokenData: [],
      embeddedToken: GetLocalStorageDataWithoutParse("embeddedToken"),
      accessToken: "",
      embeddedUrl: GetLocalStorageDataWithoutParse("embeddedUrl"),
      reportName: GetLocalStorageDataWithoutParse("powerBIReportName"),
      userId: 0,
      userTypeId: 0,
      userData: GetLocalStorageDataWithParse("loggedInUser"),
      listReportData: [],
    };
    this.report = null; // to store the loaded report's object to perform operations like print, full screen etc..
  }

  componentDidMount() {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          userId: userData.id,
          userTypeId: userData.typeId,
        },
        () => {
          this.loadReport();
        }
      );
    }
  }

  loadReport = async () => {
    const { reportName } = this.state;
    const embeddedTokenData = await StatisticsService.LoadStatisticsReports(
      this.state.userId,
      this.state.userTypeId,
      reportName
    );
    if (embeddedTokenData.data != null && embeddedTokenData.data != "") {
      this.setState({
        listReportData: embeddedTokenData.data.map(item => item.userFilterId)
      });
      //this.setState({ embeddedToken: embeddedTokenData.data.token });
      //AddLocalStorageData("embeddedToken", embeddedTokenData.data.token);
    }
  }

  goback = () => {
    this.props.navigate("/ManageStatistics");
  };

  render() {
    const { listReportData, embeddedUrl, powerBIReportID, embeddedToken, userData, reportName } =
      this.state;
    const myFilter = [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: reportName,
          column: 'userId.1'
        },
        operator: "In",
        values: [userData.id],

        filterType: models.FilterType.Basic
      },
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: reportName,
          column: 'userRoleId'
        },
        operator: "In",
        values: [userData.typeId],

        filterType: models.FilterType.Basic
      }];

    const myFilterOnResult = [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: reportName,
          column: 'userFilterId'
        },
        operator: "In",
        values: listReportData,

        filterType: models.FilterType.Basic
      }];

    const myFilterUserId = [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: reportName,
          column: 'userFilterId'
        },
        operator: "In",
        values: [userData.id],

        filterType: models.FilterType.Basic
      }];

    const myClientFilter = [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: reportName,
          column: 'clientFilterId'
        },
        operator: "In",
        values: [userData.id],

        filterType: models.FilterType.Basic
      }];

    const myShipFilter = [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: reportName,
          column: 'shipFilterId'
        },
        operator: "In",
        values: [userData.id],

        filterType: models.FilterType.Basic
      }];

    return (
      <>
        <Header componentFor={"StatisticsReport"} />
        <main className="page-height">
          <section className="communications">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                    onClick={this.goback}
                  />
                  <span className="title-font">Manage Statistics</span>
                </div>
              </div>


              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: powerBIReportID,
                  embedUrl: embeddedUrl,
                  accessToken: embeddedToken,
                  tokenType: models.TokenType.Embed,
                  filters: ((userData.typeId == 1) ? [] : (userData.typeId == 4 || userData.typeId == 7) ? myClientFilter : (userData.typeId == 5) ? myShipFilter :
                      ((userData.typeId == 2 || userData.typeId == 3) && (reportName == 'Number of IHM Executives IU' || reportName == 'Number of updated IHM Profiles IU' || reportName == 'Total number of user accounts IU')) ? myFilterOnResult :
                          ((userData.typeId == 2 || userData.typeId == 3) && reportName != 'Number of IHM Executives IU' && reportName != 'Number of updated IHM Profiles IU' && reportName != 'Total number of user accounts IU') ? myFilterUserId : []
                  ),
                  settings: {
                    panes: {
                      filters: {
                        expanded: true,
                        visible: false,
                      },
                    },
                    background: models.BackgroundType.Transparent,
                    navContentPaneEnabled: false,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        window.report.getPages();
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                  ])
                }
                cssClassName={"report-style-class"}
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
            </div>
          </section>
        </main>
        <br />
        <br />
        <Footer />
      </>
    );
  }
}

export default withRouter(StatisticsReport);
