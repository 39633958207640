import React, { Component } from 'react'

export default function Dropdown(props) {
    return (
        <>
            {props.dropdownLabel != "" && (
                <label className="label-class label-mobile label-dropdown">{props.dropdownLabel}</label>
            )}
            <select name={`dropdown_${props.name}`}
                id="ddlDropdown"
                disabled={props.disabled ?? false}
                className="form-select custom-dropdown-class"
                onChange={(ev) => props.onChange(ev)}
                aria-label="dropdown-select"
                value={props.selected}
                //style={props.style}
                style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "pre",
                    overflow: "hidden",
                  }}
                >
                <option key={"0"} value={"0"} style={{display:props.hideselect?'none':""}}>{props.hasALL ? "All":"Select"}</option>
                {props.children}
            </select>
        </>

    )
}
