import React, { useState } from 'react'
import { helperText } from '../helpers'
import { isStringEmpty } from '../helpers/string-helper'

const ErrorView = (props) => {
    return (
        <div className={props.errorFor == "emptyDropdown" || props.errorFor == "errorPassword" ? "invalid-dropdown-icon" : "invalid-message-icon"}>
            <img src="images\icon material-error.png" alt="invalid" />
        </div>
    )
}

export default function UserCredentials(props) {

    return (
        <>
            <h1>Login</h1>

            {/* Username */}
            <h4 className={props.disabled ? "mb-1" : "mb-1 required-field"}>Username:</h4>
            <div className={!isStringEmpty(props.errorUsername) ? "invalid-position" : ""}>
                <input maxLength={100} autoFocus={false} autoComplete={"off"} type="text" className="form-control" name={props.name} id="id_username" value={props.username} onChange={props.onTextChange} disabled={props.disabled} />
                {!isStringEmpty(props.errorUsername) && (
                    <ErrorView />
                )}
            </div>

            {/* Password */}
            <h4 className={props.disabled || props.userId == 0 ? "mb-1 mt-3" : "mb-1 mt-3 required-field"}>Password:</h4>
            <div className="admin-supplier-change-password">
                <div className={!isStringEmpty(props.errorPassword) ? "invalid-position" : ""}>
                    <input autoFocus={false} autoComplete={"off"} type={props.showPassword ? "text" : "password"} className="form-control" placeholder="*********" value={props.password} name={props.passwordName} id="id_password" onChange={props.onTextChange} minLength={8} disabled={props.disabled || props.userId == 0 ? true : false} />
                    <i className={props.showPassword ? "fa fa-eye eye-icon cursor-class" : "fa eye-icon fa-eye-slash cursor-class"} id="togglePassword" onClick={props.togglePassword} title={props.showPassword ? "Hide" : "Show"}></i>
                    {!props.disabled && <img src="images/info.png" alt="info" className="info-Password" data-bs-toggle="tooltip" data-bs-placement="right" title="Password should have a minimum of 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces." />}
                    {!isStringEmpty(props.errorPassword) && (
                        <ErrorView errorFor="errorPassword" />
                    )}
                    <span>(Auto generated by system)</span>
                </div>
            </div>

            {/* Button to Change/Request Password */}
            <div className="admin-supplier-profile-login-button">
                <button type="button" className="btn" onClick={props.onPasswordButtonClick} disabled={props.disabledButton}>{props.label}</button>
            </div>
        </>

    )
}