import React, { Component } from "react";
import Loader from "../../components/Loader";
import { SupplierService } from "../../services/supplier.service";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import { changePageTitle, withRouter, isValidFile } from "../../helpers";
import Toast from "../../components/Toast";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Logout from "../../helpers/Logout";
import DatePicker from "../../components/DatePickerComponent";
import { helperText } from "../../helpers/helperText";
import { UserLevel, UserRole, SITERIGHTS } from "../../helpers/enums";
import * as globalFunctions from "../../helpers/utilities";

var currentDate = new Date();
const validFileFormats = [".pdf, .png, .jpg, .jpeg, .PNG,  .PDF"];
const validFileFormatsRedable = [".pdf, .docx, .PDF"];
const validFileFormatsReadable =
  "application/pdf,image/png, image/jpeg, image/jpg";
const validFileFormatsDeclaration =
  "application/pdf, application/PDF, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

class SupplierDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastMessage: null,
      toastType: "",
      toastVisible: false,
      errorMessages: {},
      showErrorMessage: false,
      supplierId: 0,
      clientDataList: [],
      shipDataList: [],
      poNumberList: [],
      lineNumberDescriptionList: [],
      tableABList: [],
      documentTypeId: 0,
      sdocIssueDate: "",
      mdIssueDate: "",
      mdNumber: "",
      sdocNumber: "",
      supplierName: "",
      division: "",
      markerName: "",
      productName: "",
      hazmatStatus: "",
      objectOfDeclaration: "",
      Remarks: "",
      fileInputKey: "",
      uploadMDSDocClicked: false,
      dropdown_client: [],
      dropdown_ship: [],
      dropdown_poNumberList: [],
      dropdown_lineItemDescription: [],
      DeclarationOfConformity: false,
      DeclarationOfAsbestosFree: false,
      DeclarationOfMSDS: false,
      Miscellaneous: false,
      DeclarationList: [
        {
          DateOfDeclaration: convertDate(new Date()),
          ItemsDeclaredFor: "",
          ExpiryDate: "",
          SupplierName: "",
          Division: "",
          Remarks: "",
          index: Math.random(),
          isErrorpresent: false,
          isFileErrorpresent: false,
        },
      ],

      listDeclarationData: [],
      DateOfDeclaration: convertDate(new Date()),
      mdsdocId: this.props.mdsdocId || 0,
      isSupplierLoggedIn: false,
      isAdminLoggedIn: false,
      dateRangeError: false,
      TFile: [],
      userTypeId: 0,
      showNotSelectedEror: false,
      MDSDocDateDisabledtrue: false,
      ShowErrorMessageOnMDSDocSelected: false,
      TrackingToolId: 0,
      declarationCheckBoxNotSelected: false,
      showHidePoNumberRequiredAstric: false,
    };
    this.fileInput = React.createRef();
    this.DeclarationOfConformity = React.createRef();
    this.DeclarationOfAsbestosFree = React.createRef();
    this.DeclarationOfMSDS = React.createRef();
    this.Miscellaneous = React.createRef();
  }

  componentDidMount = async () => {
    try {
      var userData =
        globalFunctions.GetLocalStorageDataWithParse("loggedInUser");
      if (userData != null) {
        this.setState(
          {
            supplierId: this.props.supplierId,
            supplierName: this.props.supplierName,
            isSupplierLoggedIn: userData.typeId == UserRole.SUPPLIER,
            isAdminLoggedIn:
              userData.typeId == UserRole.ADMIN ||
              userData.typeId == UserRole.INTERNALUSER ||
              userData.typeId == UserRole.ACCOUNTS,
            userTypeId: userData.typeId,
            mdIssueDate: currentDate,
            sdocIssueDate: currentDate,
            MDSDocDateDisabledtrue:
              userData.typeId == UserRole.SUPPLIER ? true : false,
            isLoading: true,
            TrackingToolId: this.props.TrackingToolId,
          },
          async () => {
            await this.GetSupplierDocDropdownData(this.state.supplierId);
          }
        );
      }
    } catch (error) {}
  };

  GetSupplierDocDropdownData = async (supplierId) => {
    this.setState({ isLoading: true });
    const response = await SupplierService.GetSupplierDocDropdownData(
      supplierId
    );

    if (response != null && response != undefined && response.status == 200) {
      var { supplierDocumentDropdownList, lstSupplierModel } = response.data;

      this.setState(
        {
          clientDataList: supplierDocumentDropdownList.clientList,
          shipDataList: supplierDocumentDropdownList.shipList,
          poNumberList: supplierDocumentDropdownList.poNumberList,
          lineNumberDescriptionList:
            supplierDocumentDropdownList.lineNumberDescriptionList,
          tableABList: supplierDocumentDropdownList.tableABCombinedList,
          supplierList: lstSupplierModel,

          originalClientList: supplierDocumentDropdownList.clientList,
          originalShipList: supplierDocumentDropdownList.shipList,
          originalPoNumberList: supplierDocumentDropdownList.poNumberList,
          originalLineNumberDescList:
            supplierDocumentDropdownList.lineNumberDescriptionList,
          division:
            lstSupplierModel.length > 0 ? lstSupplierModel[0].division : "",
          supplierName:
            lstSupplierModel.length > 0 ? lstSupplierModel[0].supplierName : "",
        },
        async () => {
          await this.BindModalDropdownData();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        isLoading: false,
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        poNumberList: [],
        clientDataList: [],
        shipDataList: [],
        tableABList: [],
        lineNumberDescriptionList: [],
        supplierList: [],
      });
    }
  };

  /* <summary>
	   date: 30/03/2023
	   Name: HV
	   description: for binding seelcted dropdown values
	   <summary> */

  BindModalDropdownData = async () => {
    const { selectedPendingItemsList } = this.props;

    const {
      clientDataList,
      shipDataList,
      poNumberList,
      lineNumberDescriptionList,
    } = this.state;
    if (selectedPendingItemsList != null) {
      this.setState({
        dropdown_client: clientDataList.filter(
          (x) => x.value == selectedPendingItemsList?.clientId 
        ),
        dropdown_ship: shipDataList.filter(
          (x) => x.value == selectedPendingItemsList?.shipId
        ),
        dropdown_poNumberList: poNumberList.filter(
          (x) =>
            x.label == selectedPendingItemsList?.poNumber &&
            x.value == selectedPendingItemsList?.trackingToolId
        ),
        dropdown_lineItemDescription: lineNumberDescriptionList.filter(
          (x) =>
            x.poNumber == selectedPendingItemsList?.poNumber &&
            x.value == selectedPendingItemsList?.trackingToolId
        ),
        poNumberList: poNumberList.filter(
          (x) =>
            x.shipId == selectedPendingItemsList?.shipId &&
            x.parentValue == selectedPendingItemsList?.clientId
        ),
        shipDataList: shipDataList.filter(
          (x) => x.parentValue == selectedPendingItemsList?.clientId
        ),
        lineNumberDescriptionList: lineNumberDescriptionList.filter(
          (x) =>
            x.poNumber == selectedPendingItemsList?.poNumber &&
            x.value == selectedPendingItemsList?.trackingToolId
        ),
      });
    }
    this.setState({ isLoading: false });
  };

  onChangeDocumentType = async (e) => {
    var documentTypeId = e.target.value;
    this.setState(
      {
        documentTypeId: parseInt(documentTypeId, 10),
        showErrorMessage: false,
        ShowErrorMessageOnMDSDocSelected: false,
        declarationCheckBoxNotSelected: false,
        listDeclarationData: [],
      },
      async () => {
        const { errorMessages } = this.state;

        if (this.state.documentTypeId == 1 || this.state.documentTypeId == 2) {
          this.setState({ showNotSelectedEror: false });
        }

        if (this.state.documentTypeId == 2 || this.state.documentTypeId == 0) {
          errorMessages["PoNumberNotSelectedError"] = "";
          this.setState({
            uploadMDSDocClicked: false,
            errorMessages: errorMessages,
            showHidePoNumberRequiredAstric: false,
          });
        }
      }
    );
  };

  onUploadMDSDocClicked = () => {
    const { errorMessages, uploadMDSDocClicked } = this.state;

    if (!uploadMDSDocClicked) {
      errorMessages["errorMdNumber"] = "";
      errorMessages["errorSdocNumber"] = "";
      errorMessages["errorMDIssueDate"] = "";
      errorMessages["errorUploadMDSDocEmptyFile"] = "";
      errorMessages["PoNumberNotSelectedError"] = "";
    }

    this.setState(
      {
        uploadMDSDocClicked: true,
        errorMessages: errorMessages,
        showHidePoNumberRequiredAstric: true,
      },
      async () => {
        if (this.state.uploadMDSDocClicked) {
          this.setState({
            ShowErrorMessageOnMDSDocSelected: false,
          });
        }
      }
    );
  };

  onFillDigitalMDSDocClicked = () => {
    this.setState({
      uploadMDSDocClicked: false,
      ShowErrorMessageOnMDSDocSelected: false,
      showHidePoNumberRequiredAstric: false,
    });
    const {
      mdsdocId,
      supplierList,
      dropdown_poNumberList,
      dropdown_lineItemDescription,
      TrackingToolId,
      poNumberList,
    } = this.state;

    globalFunctions.AddLocalStorageData("trackingToolId", TrackingToolId);
    globalFunctions.AddLocalStorageData("mdsdocId", mdsdocId);
    globalFunctions.AddLocalStorageData(
      "supplierList",
      JSON.stringify(supplierList)
    );
    globalFunctions.AddLocalStorageData(
      "poNumberList",
      JSON.stringify(dropdown_poNumberList)
    );
    globalFunctions.AddLocalStorageData(
      "lineItemDescription",
      JSON.stringify(dropdown_lineItemDescription)
    );

    globalFunctions.AddLocalStorageData("FilledMdsdoc", "true");

    globalFunctions.AddLocalStorageData("location", "SupplierDocument");

    this.navigateTo(`/MDSDoC`);
  };

  navigateTo(path) {
    this.props.navigate(path);
  }

  onDateChanged = (id, e) => {
    const { errorMessages } = this.state;

    if (id == "sdocIssueDate") {
      errorMessages["errorSDocIssueDate"] = "";
      this.setState({ sdocIssueDate: e, errorMessages: errorMessages });
    }

    if (id == "mdIssueDate") {
      errorMessages["errorMDIssueDate"] = "";
      this.setState({ mdIssueDate: e, errorMessages: errorMessages });
    }
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  onSaveClick = async () => {
    try {
      const { onSaveMDSDOCDeclarationData } = this.props;
      const {
        sdocIssueDate,
        mdIssueDate,
        mdNumber,
        sdocNumber,
        division,
        markerName,
        productName,
        objectOfDeclaration,
        Remarks,
        supplierId,
        dropdown_poNumberList,
        dropdown_client,
        dropdown_ship,
        dropdown_lineItemDescription,
        supplierName,
        documentTypeId,
        uploadMDSDocClicked,
        TFile,
        listDeclarationData,
        isSupplierLoggedIn,
        isAdminLoggedIn,
        userTypeId,
        TrackingToolId,
        mdsdocId,
        originalPoNumberList,
      } = this.state;

      if (!this.validateFields()) {
        this.setState({ isLoading: true });
        let trackingToolList = [];
        if (dropdown_client.length > 0) {
          trackingToolList = originalPoNumberList.filter((e) =>
            dropdown_client.map((e2) => e2.value).includes(e.parentValue)
          );

          if (dropdown_poNumberList.length > 0) {
            const matchingIds = new Set(
              dropdown_poNumberList.map((item) => item.label)
            );
            const matchingShipIds = new Set(
              dropdown_ship.map((item) => item.value)
            );

            trackingToolList = trackingToolList
              .filter(
                (item) =>
                  !matchingIds.has(item.label) &&
                  !matchingShipIds.has(item.value)
              )
              .concat(dropdown_poNumberList);
          } else {
            const matchingShipIds = new Set(
              dropdown_ship.map((item) => item.value)
            );
            trackingToolList = trackingToolList
              .filter((item) => !matchingShipIds.has(item.value))
              .concat(dropdown_poNumberList);
          }
        }

        if (documentTypeId == 1) {
          var sdocDate = sdocIssueDate == "" ? "" : convertDate(sdocIssueDate);
          var mdDate = mdIssueDate == "" ? "" : convertDate(mdIssueDate);

          let mdsdocData = new FormData();
          mdsdocData.append("sdocIssueDate", sdocDate);
          mdsdocData.append("mdIssueDate", mdDate);
          mdsdocData.append("mdNumber", mdNumber);
          mdsdocData.append("sdocNumber", sdocNumber);
          mdsdocData.append("division", division);
          mdsdocData.append("markerName", markerName);
          mdsdocData.append("productName", productName);
          mdsdocData.append("objectOfDeclaration", objectOfDeclaration);
          mdsdocData.append("Remarks", Remarks);
          mdsdocData.append("supplierName", supplierName);
          mdsdocData.append("SupplierId", supplierId);
          mdsdocData.append("Client", JSON.stringify(dropdown_client));
          mdsdocData.append("Ship", JSON.stringify(dropdown_ship));
          var firstClientId =
            dropdown_client.length != 0 ? dropdown_client[0].value : 0;
          mdsdocData.append("firstClientId", firstClientId);
          var firstShipId =
            dropdown_client.length != 0 ? dropdown_client[0].value : 0;
          mdsdocData.append("firstShipId", firstShipId);
          mdsdocData.append(
            "poNumberList",
            JSON.stringify(dropdown_poNumberList)
          );
          mdsdocData.append(
            "lineItemDescription",
            JSON.stringify(dropdown_lineItemDescription)
          );
          mdsdocData.append(
            "DocumentType",
            documentTypeId == 1 ? "MD/SDoC" : "Declaration"
          );
          mdsdocData.append("IsUploaded", true);
          mdsdocData.append("TFile", TFile);
          mdsdocData.append("IsSupplierLoggedIn", isSupplierLoggedIn);
          mdsdocData.append("IsAdminLoggedIn", isAdminLoggedIn);
          mdsdocData.append("UserTypeId", userTypeId);
          mdsdocData.append("TrackingToolId", TrackingToolId);
          mdsdocData.append("IsMdsdocUploaded", true);
          mdsdocData.append(
            "MdsdocId",
            mdsdocId == 0 || mdsdocId == null ? "" : mdsdocId
          );
          mdsdocData.append(
            "TrackingToolList",
            JSON.stringify(trackingToolList)
          );

          onSaveMDSDOCDeclarationData(mdsdocData);
        } else if (documentTypeId == 2) {
          let mdsdocData = new FormData();

          var imageData = [];

          if (listDeclarationData.length > 0) {
            var imageData = listDeclarationData.map((e) => ({
              Name: e.name,
              files: e.TFile,
            }));
          }

          for (let i = 0; i < imageData.length; i++) {
            mdsdocData.append(`${imageData[i].Name}`, imageData[i].files);
          }

          mdsdocData.append("supplierName", supplierName);
          mdsdocData.append("SupplierId", supplierId);
          mdsdocData.append("Client", JSON.stringify(dropdown_client));
          mdsdocData.append("Ship", JSON.stringify(dropdown_ship));
          var firstClientId =
            dropdown_client.length != 0 ? dropdown_client[0].value : 0;
          mdsdocData.append("firstClientId", firstClientId);
          mdsdocData.append(
            "poNumberList",
            JSON.stringify(dropdown_poNumberList)
          );
          mdsdocData.append(
            "lineItemDescription",
            JSON.stringify(dropdown_lineItemDescription)
          );
          mdsdocData.append(
            "DocumentType",
            documentTypeId == 1 ? "MD/SDoC" : "Declaration"
          );
          mdsdocData.append("IsUploaded", false);
          mdsdocData.append("IsSupplierLoggedIn", isSupplierLoggedIn);
          mdsdocData.append("IsAdminLoggedIn", isAdminLoggedIn);
          mdsdocData.append("UserTypeId", userTypeId);
          mdsdocData.append("TrackingToolId", TrackingToolId);

          var DeclarationData = Array.prototype.slice.call(listDeclarationData);
          mdsdocData.append("DeclarationData", JSON.stringify(DeclarationData));
          mdsdocData.append(
            "MdsdocId",
            mdsdocId == 0 || mdsdocId == null ? "" : mdsdocId
          );
          mdsdocData.append(
            "TrackingToolList",
            JSON.stringify(trackingToolList)
          );

          onSaveMDSDOCDeclarationData(mdsdocData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  validateFields() {
    const {
      uploadMDSDocClicked,
      sdocIssueDate,
      mdIssueDate,
      mdNumber,
      sdocNumber,
      errorMessages,
      TFile,
      dropdown_poNumberList,
      documentTypeId,
      listDeclarationData,
      isSupplierLoggedIn,
      DeclarationOfConformity,
      DeclarationOfAsbestosFree,
      DeclarationOfMSDS,
      Miscellaneous,
    } = this.state;

    let errors = errorMessages;

    if (documentTypeId == 0) {
      this.setState({
        errorMessages,
        showErrorMessage: false,
        showNotSelectedEror: true,
      });
      return true;
    } else if (documentTypeId == 1) {
      if (isSupplierLoggedIn && !uploadMDSDocClicked) {
        this.setState({
          errorMessages,
          showErrorMessage: false,
          showNotSelectedEror: false,
          ShowErrorMessageOnMDSDocSelected: true,
        });
        return true;
      } else if (uploadMDSDocClicked || documentTypeId == 1) {
        errors["PoNumberNotSelectedError"] =
          dropdown_poNumberList.length == 0 && isSupplierLoggedIn ? "true" : "";
        // if(dropdown_poNumberList.length == 0 && isSupplierLoggedIn)
        // {
        // 	//this.setState({ PoNumberNotSelectedError: true })
        // 	check = true;
        // }
        // else{
        // 	//this.setState({ PoNumberNotSelectedError: false })
        // 	check = false;
        // }

        var sdocDateEmptyCheck =
          sdocIssueDate == "" ? "" : convertDate(sdocIssueDate);
        var mdDateEmptyCheck =
          mdIssueDate == "" ? "" : convertDate(mdIssueDate);

        errors["errorMdNumber"] = isStringEmpty(mdNumber) ? "true" : "";
        errors["errorSdocNumber"] = isStringEmpty(sdocNumber) ? "true" : "";
        errors["errorMDIssueDate"] =
          mdDateEmptyCheck == null ||
          mdDateEmptyCheck == undefined ||
          mdDateEmptyCheck == ""
            ? "true"
            : "";
        errors["errorSDocIssueDate"] =
          sdocDateEmptyCheck == null ||
          sdocDateEmptyCheck == undefined ||
          sdocDateEmptyCheck == ""
            ? "true"
            : "";

        errors["errorUploadMDSDocEmptyFile"] =
          TFile != null && TFile.length == 0 ? "true" : "";

        if (
          errors["PoNumberNotSelectedError"] != "" ||
          errors["errorMdNumber"] != "" ||
          errors["errorSdocNumber"] != "" ||
          errors["errorMDIssueDate"] != "" ||
          errors["errorSDocIssueDate"] != "" ||
          errors["errorUploadMDSDocEmptyFile"] != ""
        ) {
          this.setState({
            errorMessages,
            showErrorMessage: true,
          });
          return true;
        }
        // else if (check) {
        // 	return true
        // }

        this.setState({
          errorMessages,
          showErrorMessage: false,
        });
        return false;
      }
    } else if (documentTypeId == 2) {
      var declarationCheck = false;
      if (
        DeclarationOfConformity == false &&
        DeclarationOfAsbestosFree == false &&
        DeclarationOfMSDS == false &&
        Miscellaneous == false
      ) {
        declarationCheck = true;

        this.setState({
          declarationCheckBoxNotSelected: true,
        });
      } else {
        this.setState({
          declarationCheckBoxNotSelected: false,
        });

        declarationCheck = false;
      }

      var declarationData = listDeclarationData.map((x) => {
        if (x.fileName == "" || x.fileName == null || x.ExpiryDate == "") {
          (x.isFileErrorpresent =
            x.fileName == "" || x.fileName == null ? true : false),
            (x.isErrorpresent = x.ExpiryDate == "" ? true : false);

          return { ...x };
        } else {
          (x.isFileErrorpresent = false), (x.isErrorpresent = false);
        }
        return x;
      });

      var dateValidationCheck = listDeclarationData.map((x) => {
        if (x.DateOfDeclaration != "" && x.ExpiryDate) {
          var decDate = convertDate(x.DateOfDeclaration);
          var expDate = convertDate(x.ExpiryDate);
          if (new Date(decDate).getTime() >= new Date(expDate).getTime()) {
            x.dateRangeError = true;
            return { ...x };
          } else {
            x.dateRangeError = false;
          }
        }
        return x;
      });

      var isErrorPresent = declarationData.filter(
        (t) =>
          t.isErrorpresent?.toString().toLowerCase().includes(true) ||
          t.isFileErrorpresent?.toString().toLowerCase().includes(true)
      );
      var dateRange = dateValidationCheck.filter((t) =>
        t.dateRangeError?.toString().toLowerCase().includes(true)
      );

      if (declarationCheck) {
        return true;
      } else if (isErrorPresent.length > 0) {
        this.setState({
          errorMessages,
          showErrorMessage: true,
          listDeclarationData: declarationData,
        });
        return true;
      } else if (dateRange.length > 0) {
        this.setState({
          errorMessages,
          showErrorMessage: false,
          listDeclarationData: dateValidationCheck,
        });
        return true;
      }

      this.setState({
        errorMessages,
        showErrorMessage: false,
        listDeclarationData: declarationData,
      });
      return false;
    }
  }

  onFileUpload = async (e) => {
    const { name, value } = e.target;
    if (value != "") {
      let files = e.target.files[0];
      const { showErrorMessage, errorMessages } = this.state;
      let errors = errorMessages;

      if (!isValidFile(value, validFileFormats)) {
        errors["errorUploadMDSDocEmptyFile"] = "true";
        this.setState(
          {
            [name]: null,
            fileName: "",
            showErrorMessage: true,
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.wrongSupplierFileError,
          },
          () => {
            if (showErrorMessage) this.validateFields();
          }
        );
        return;
      }

      this.setState(
        {
          [name]: files,
          fileName: files.name,
          filePath: value,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  onClientDropdownChange = (option) => {
    var dropdown_client = [];

    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_client.push(item);
        });
    }
    this.setState({ dropdown_client }, () => {
      this.filterShipDropdownBasisClient();
    });
  };

  onShipDropdownChange = (option) => {
    var dropdown_ship = [];
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_ship.push(item);
        });
    }

    this.setState({ dropdown_ship }, () => {
      this.filterPONumberDropdownBasisShip();
    });
  };

  onPONumberChange = (option) => {
    const { showErrorMessage } = this.state;

    var dropdown_poNumberList = [];
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_poNumberList.push(item);
        });
    }

    this.setState({ dropdown_poNumberList }, () => {
      this.filterLineDescDropdownBasisPoNumber();
      if (showErrorMessage) {
        this.validateFields();
      }
    });
  };

  onDescriptionChange = (option) => {
    var dropdown_lineItemDescription = [];
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_lineItemDescription.push(item);
        });
    }

    this.setState({ dropdown_lineItemDescription });
  };

  filterShipDropdownBasisClient = () => {
    var { dropdown_client, dropdown_ship } = this.state;
    var dropdownShipList =
      this.state.originalShipList == undefined
        ? []
        : this.state.originalShipList;

    if (dropdown_client.length == 0) {
      this.setState(
        {
          shipDataList: [],
          dropdown_ship: [],
        },
        () => {
          this.filterPONumberDropdownBasisShip();
        }
      );
    } else if (dropdownShipList.length > 0) {
      dropdownShipList = dropdownShipList.filter((e) =>
        dropdown_client.map((e2) => e2.value).includes(e.parentValue)
      );

      if (dropdown_ship != undefined)
        dropdown_ship = dropdown_ship.filter((e) =>
          dropdown_client.map((e2) => e2.value).includes(e.parentValue)
        );

      this.setState(
        {
          shipDataList: dropdownShipList,
          dropdown_ship: dropdown_ship,
        },
        () => {
          this.filterPONumberDropdownBasisShip();
        }
      );
    }
  };

  filterPONumberDropdownBasisShip = () => {
    var { dropdown_ship, dropdown_poNumberList, dropdown_client } = this.state;
    var dropdownPONumberList =
      this.state.originalPoNumberList == undefined
        ? []
        : this.state.originalPoNumberList;

    if (dropdownPONumberList.length > 0) {
      if (dropdown_client.length > 0) {
        dropdownPONumberList = dropdownPONumberList.filter((e) =>
          dropdown_client.map((e2) => e2.value).includes(e.parentValue)
        );

        if (dropdown_poNumberList != undefined)
          dropdown_poNumberList = dropdown_poNumberList.filter((e) =>
            dropdown_client.map((e2) => e2.value).includes(e.parentValue)
          );
      }

      if (dropdown_ship.length > 0) {
        dropdownPONumberList = dropdownPONumberList.filter((e) =>
          dropdown_ship.map((e2) => e2.value).includes(e.shipId)
        );

        if (dropdown_poNumberList != undefined)
          dropdown_poNumberList = dropdown_poNumberList.filter((e) =>
            dropdown_ship.map((e2) => e2.value).includes(e.shipId)
          );
      }

      this.setState(
        {
          poNumberList: dropdownPONumberList,
          dropdown_poNumberList: dropdown_poNumberList,
        },
        () => {
          this.filterLineDescDropdownBasisPoNumber();
        }
      );
    } else {
      this.setState(
        {
          poNumberList: dropdownPONumberList,
          dropdown_poNumberList: [],
        },
        () => {
          this.filterLineDescDropdownBasisPoNumber();
        }
      );
    }
  };

  filterLineDescDropdownBasisPoNumber = () => {
    var { dropdown_poNumberList, dropdown_lineItemDescription } = this.state;
    var dropdownLineDescList =
      this.state.originalLineNumberDescList == undefined
        ? []
        : this.state.originalLineNumberDescList;

    if (dropdown_poNumberList.length == 0) {
      this.setState({
        lineNumberDescriptionList: dropdownLineDescList,
        dropdown_lineItemDescription: [],
      });
    } else if (dropdownLineDescList.length > 0) {
      dropdownLineDescList = dropdownLineDescList.filter((e) =>
        dropdown_poNumberList.map((e2) => e2.label).includes(e.poNumber)
      );

      if (dropdown_lineItemDescription != undefined)
        dropdown_lineItemDescription = dropdown_lineItemDescription.filter(
          (e) =>
            dropdown_poNumberList.map((e2) => e2.label).includes(e.poNumber)
        );
      this.setState({
        lineNumberDescriptionList: dropdownLineDescList,
        dropdown_lineItemDescription: dropdown_lineItemDescription,
      });
    }
  };

  checkBoxClick = (e) => {
    const { name, value, checked } = e.target;

    let nextCurrentDate = new Date();
    nextCurrentDate.setDate(new Date().getDate() + 1);
    nextCurrentDate.toLocaleDateString();

    const {
      listDeclarationData,
      DeclarationList,
      supplierName,
      division,
      DeclarationOfConformity,
      DeclarationOfAsbestosFree,
      DeclarationOfMSDS,
      Miscellaneous,
    } = this.state;
    var rowIndex = listDeclarationData.findIndex((t) => t.name == name);
    var obj = [...DeclarationList];
    if (checked) {
      this.setState((prevState) => ({
        listDeclarationData: [
          ...prevState.listDeclarationData,
          {
            obj,
            name: name,
            DeclarationName: name,
            DateOfDeclaration: convertDate(new Date()),
            SupplierName: supplierName,
            Division: division,
            ExpiryDate: "",
            isErrorpresent: false,
            isFileErrorpresent: false,
            fileName: "",
            IsMisc:
              name.trim().toLowerCase() == "Miscellaneous".trim().toLowerCase()
                ? true
                : false,
            IsDOC:
              name.trim().toLowerCase() ==
              "DeclarationOfConformity".trim().toLowerCase()
                ? true
                : false,
            IsDOAsb:
              name.trim().toLowerCase() ==
              "DeclarationOfAsbestosFree".trim().toLowerCase()
                ? true
                : false,
            IsDOMsds:
              name.trim().toLowerCase() ==
              "DeclarationOfMSDS".trim().toLowerCase()
                ? true
                : false,
            nextCurrentDate: nextCurrentDate,
          },
        ],
        [name]: checked,
        declarationCheckBoxNotSelected: false,
      }));
    } else {
      if (rowIndex > -1) {
        listDeclarationData.splice(rowIndex, 1);
        this.setState({
          listDeclarationData: listDeclarationData,
          [name]: checked,
        });
      }
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  onTextDeclartionChange = (e, itemName) => {
    var { listDeclarationData } = this.state;
    const { name, value } = e.target;

    var rowIndex = listDeclarationData.findIndex((t) => t.name == itemName);
    if (rowIndex > -1) {
      listDeclarationData[rowIndex][name] = value;
      this.setState({ listDeclarationData }, () => {});
    }
  };

  onDeclarationDateChange = (e, itemName, dateName) => {
    const { listDeclarationData } = this.state;
    let checkDate = false;
    var rowIndex = listDeclarationData.findIndex((t) => t.name == itemName);
    if (rowIndex > -1) {
      if (dateName == "ExpiryDate") {
        listDeclarationData[rowIndex]["ExpiryDate"] = e;

        var declarationDate =
          listDeclarationData[rowIndex]["DateOfDeclaration"];
        var dDate = declarationDate == "" ? "" : declarationDate;
        var eDate = convertDate(e);

        if (dDate != "") {
          if (new Date(dDate).getTime() >= new Date(eDate).getTime()) {
            checkDate = true;
          }
        }

        if (e != "") {
          listDeclarationData[rowIndex]["isErrorpresent"] = false;
        } else {
          listDeclarationData[rowIndex]["isErrorpresent"] = true;
        }

        this.setState({ listDeclarationData }, () => {});

        this.setState({
          //errorMessages: errors,
          dateRangeError: checkDate,
        });
      }
    }
  };

  onAttachmentChange = async (e, itemName) => {
    let files = e.target.files[0];
    const { listDeclarationData, showErrorMessage, errorMessages } = this.state;
    const { name, value } = e.target;

    let errors = errorMessages;

    var rowIndex = listDeclarationData.findIndex((t) => t.name == itemName);
    if (value != "") {
      if (!isValidFile(value, validFileFormatsRedable)) {
        errors["errorEmptyFile"] = "true";

        this.setState(
          {
            [name]: null,
            fileName: "",
            showErrorMessage: true,
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.wrongFileSupMDSDoCError,
          },
          () => {
            if (showErrorMessage) this.validateFields();
          }
        );
        return;
      }

      if (rowIndex > -1) {
        listDeclarationData[rowIndex][name] = files;

        listDeclarationData[rowIndex]["fileName"] = files.name;
        this.setState(
          {
            listDeclarationData,
          },
          () => {
            if (showErrorMessage) this.validateFields();
          }
        );
      }
    }
  };

  //item declared autocomplete code
  onAddLineItemDescriptionTextChange = async (e, itemName) => {
    const { name, value } = e.target;
    const { listDeclarationData, tableABList } = this.state;

    var selectedLineItem = tableABList.filter(
      (x) =>
        x.label != null &&
        x.label?.trim().toLowerCase() == value?.trim().toLowerCase()
    );

    var rowIndex = listDeclarationData.findIndex((t) => t.name == itemName);
    if (rowIndex > -1) {
      listDeclarationData[rowIndex][name] = value;
      this.setState({ listDeclarationData }, () => {});
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      showErrorMessage,
      clientDataList,
      shipDataList,
      poNumberList,
      lineNumberDescriptionList,
      tableABList,
      documentTypeId,
      uploadMDSDocClicked,
      onFillDigitalMDSDocClicked,
      sdocIssueDate,
      mdIssueDate,
      mdNumber,
      sdocNumber,
      supplierName,
      division,
      markerName,
      productName,
      hazmatStatus,
      objectOfDeclaration,
      Remarks,
      fileInputKey,
      errorMessages,
      dropdown_client,
      dropdown_ship,
      dropdown_poNumberList,
      dropdown_lineItemDescription,
      DeclarationOfConformity,
      DeclarationOfAsbestosFree,
      DeclarationOfMSDS,
      Miscellaneous,
      listDeclarationData,
      DeclarationList,
      DateOfDeclaration,
      fileName,
      isSupplierLoggedIn,
      dateRangeError,
      showNotSelectedEror,
      MDSDocDateDisabledtrue,
      ShowErrorMessageOnMDSDocSelected,
      declarationCheckBoxNotSelected,
      showHidePoNumberRequiredAstric,
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        <div className="supplierdetaildoc" style={{ display: "block" }}>
          <div
            className="modal-backdrop fade show"
            style={{ display: "block" }}
          ></div>
          <div
            className="modal"
            id="SupplierDocumentupload-modal"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-box">
              <div className="modal-content ">
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Add Document</h4>
                  <button type="button" data-bs-dismiss="modal">
                    <img
                      src="images/modal-close.png"
                      alt="modal-close"
                      style={{ pointerEvents: "all" }}
                      onClick={() => this.props.onDocumentModalCloseClick()}
                    />
                  </button>
                </div>

                {/* <!-- Modal body --> */}
                <div className="modal-body">
                  {globalFunctions.HasSiteRights(
                    SITERIGHTS.HideClientShipPOLineNumber
                  ) && (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="doc-form-group">
                          <label htmlFor="">Client Name:</label>
                          <MultiSelectDropdown
                            selectedOptions={
                              dropdown_client == undefined
                                ? []
                                : dropdown_client
                            }
                            options={
                              clientDataList == undefined ? [] : clientDataList
                            }
                            onChange={this.onClientDropdownChange}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="doc-form-group">
                          <label htmlFor="">Ship Name:</label>
                          <MultiSelectDropdown
                            selectedOptions={
                              dropdown_ship == undefined ? [] : dropdown_ship
                            }
                            options={
                              shipDataList == undefined ? [] : shipDataList
                            }
                            onChange={this.onShipDropdownChange}
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="doc-form-group">
                          <label
                            htmlFor=""
                            className={
                              showHidePoNumberRequiredAstric
                                ? "required-field"
                                : ""
                            }
                          >
                            PO Number:
                          </label>

                          <div
                            className={
                              !isStringEmpty(
                                errorMessages.PoNumberNotSelectedError
                              )
                                ? "invalid-position invalid-position-multi-dropdown"
                                : ""
                            }
                          >
                            <MultiSelectDropdown
                              selectedOptions={
                                dropdown_poNumberList == undefined
                                  ? []
                                  : dropdown_poNumberList
                              }
                              options={
                                poNumberList == undefined ? [] : poNumberList
                              }
                              onChange={this.onPONumberChange}
                              isMulti={true}
                            />

                            {!isStringEmpty(
                              errorMessages.PoNumberNotSelectedError
                            ) && (
                              <div className="invalid-dropdown-icon">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="doc-form-group">
                          <label htmlFor="">Description:</label>
                          <MultiSelectDropdown
                            descriptionClassName="supplierMultiSelectDropDown"
                            selectedOptions={
                              dropdown_lineItemDescription == undefined
                                ? []
                                : dropdown_lineItemDescription
                            }
                            options={
                              lineNumberDescriptionList == undefined
                                ? []
                                : lineNumberDescriptionList
                            }
                            onChange={this.onDescriptionChange}
                            isMulti={true}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className={
                          showNotSelectedEror
                            ? "doc-form-group invalid-position"
                            : " doc-form-group"
                        }
                      >
                        <label htmlFor="" className="required-field">
                          Document Type:
                        </label>
                        <select
                          className="form-select"
                          aria-label="form-select example"
                          onChange={(e) => this.onChangeDocumentType(e)}
                          value={documentTypeId}
                        >
                          <option key={0} value="0">
                            Select
                          </option>
                          <option key={1} value="1">
                            MD/SDoC
                          </option>
                          <option key={2} value="2">
                            Declaration
                          </option>
                        </select>
                        {showNotSelectedEror && (
                          <div className="invalid-dropdown-icon-Suppliermodal">
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>

                    {globalFunctions.HasSiteRights(
                      SITERIGHTS.ShowUploadButtonTrackingTool
                    ) &&
                      documentTypeId == 1 && (
                        <>
                          <div className="col-md-4">
                            <div className="doc-btn-group">
                              <button
                                type="button"
                                className="btn btn-background-color"
                                onClick={this.onFillDigitalMDSDocClicked}
                              >
                                Fill Digital MD/SDoC
                              </button>
                              <span>Or</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="doc-btn-group">
                              <button
                                type="file"
                                className="btn btn-background-color"
                                onClick={this.onUploadMDSDocClicked}
                              >
                                Upload MD/SDoC
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                  </div>

                  {/*  */}

                  {(uploadMDSDocClicked && documentTypeId == 1) ||
                  (documentTypeId == 1 && !isSupplierLoggedIn) ? (
                    <div className="doc-gray-box">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="" className="required-field">
                              MD Number:
                            </label>
                            <div
                              className={
                                !isStringEmpty(errorMessages.errorMdNumber)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                className="form-control"
                                type="text"
                                name="mdNumber"
                                value={mdNumber}
                                onChange={this.onTextChange}
                              />

                              {!isStringEmpty(errorMessages.errorMdNumber) && (
                                <ErrorView errorFor="emptyText" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="" className="required-field">
                              SDoC Number:
                            </label>

                            <div
                              className={
                                !isStringEmpty(errorMessages.errorSdocNumber)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                className="form-control"
                                type="text"
                                name="sdocNumber"
                                value={sdocNumber}
                                onChange={this.onTextChange}
                              />
                              {!isStringEmpty(
                                errorMessages.errorSdocNumber
                              ) && <ErrorView errorFor="emptyText" />}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">MD Issue Date:</label>
                            <div
                              className={
                                !isStringEmpty(errorMessages.errorMDIssueDate)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <DatePicker
                                min="0"
                                selectedDate={mdIssueDate}
                                onDateChange={(e) =>
                                  this.onDateChanged("mdIssueDate", e)
                                }
                                isNotRoot={true}
                                disabled={MDSDocDateDisabledtrue}
                              >
                                {" "}
                              </DatePicker>
                              {!isStringEmpty(
                                errorMessages.errorMDIssueDate
                              ) && <ErrorView errorFor="emptyText" />}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">SDoC Issue Date:</label>
                            <div
                              className={
                                !isStringEmpty(errorMessages.errorSDocIssueDate)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <DatePicker
                                min="0"
                                selectedDate={sdocIssueDate}
                                onDateChange={(e) =>
                                  this.onDateChanged("sdocIssueDate", e)
                                }
                                isNotRoot={true}
                                disabled={MDSDocDateDisabledtrue}
                              >
                                {" "}
                              </DatePicker>

                              {!isStringEmpty(
                                errorMessages.errorSDocIssueDate
                              ) && <ErrorView errorFor="emptyText" />}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">Supplier Name:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="supplierName"
                              value={supplierName}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">Division:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="division"
                              value={division}
                              onChange={this.onTextChange}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">Maker Name:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="markerName"
                              value={markerName}
                              onChange={this.onTextChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">Product Name:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="productName"
                              value={productName}
                              onChange={this.onTextChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">Objects of Declaration:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="objectOfDeclaration"
                              value={objectOfDeclaration}
                              onChange={this.onTextChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="doc-form-group">
                            <label htmlFor="">Remarks:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="Remarks"
                              value={Remarks}
                              onChange={this.onTextChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="choosefile-btn choosefile-group">
                            <div
                              className="file-upload-modal"
                              onClick={() => this.fileInput.current.click()}
                            >
                              <label
                                className={
                                  "attach-btn attach-btn-responsive chs-btn " +
                                  (!isStringEmpty(
                                    errorMessages.errorUploadMDSDocEmptyFile
                                  )
                                    ? "invalid-position-border"
                                    : "")
                                }
                                htmlFor="actual-btn"
                              >
                                <img
                                  className="upload-img"
                                  src="images/Icon awesome-upload.png"
                                  width="18px"
                                />
                                <span>Choose File</span>
                              </label>
                            </div>
                            {!isStringEmpty(
                              errorMessages.errorUploadMDSDocEmptyFile
                            ) && (
                              <img
                                src="images\icon material-error.png"
                                alt="invalid"
                                className="invalid-position-img"
                                style={{ width: "16px" }}
                              />
                            )}
                            <label className="required-field">
                              &nbsp; (Please select a PDF or Image file)
                            </label>
                            <input
                              ref={this.fileInput}
                              type="file"
                              accept={validFileFormatsReadable}
                              id="fileInput"
                              name="TFile"
                              onChange={this.onFileUpload}
                              key={fileInputKey || ""}
                              style={{ display: "none", visibility: "hidden" }}
                            />
                            <label
                              className="label-breakword"
                              style={{
                                overflowWrap: "anywhere",
                                margin: "10px",
                              }}
                            >
                              {" "}
                              {fileName}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*  */}

                  {/*  */}
                  {documentTypeId == 2 ? (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="declaration-checkbox-group">
                            <label className="required-field">
                              Document Type
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="doc-declaration-checkbox">
                            <input
                              type="checkbox"
                              name="DeclarationOfConformity"
                              style={{ cursor: "pointer" }}
                              id="inlineCheckbox1"
                              value={DeclarationOfConformity}
                              onChange={(e) => this.checkBoxClick(e)}
                            />
                            <label
                              htmlFor="check1"
                              style={{ paddingLeft: "4px" }}
                            >
                              {" "}
                              Declaration of Conformity
                            </label>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="doc-declaration-checkbox">
                            <input
                              type="checkbox"
                              name="DeclarationOfAsbestosFree"
                              style={{ cursor: "pointer" }}
                              id="inlineCheckbox2"
                              value={DeclarationOfAsbestosFree}
                              onChange={(e) => this.checkBoxClick(e)}
                            />
                            <label
                              htmlFor="check2"
                              style={{ paddingLeft: "4px" }}
                            >
                              {" "}
                              Declaration of Asbestos Free
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="doc-declaration-checkbox">
                            <input
                              type="checkbox"
                              name="DeclarationOfMSDS"
                              style={{ cursor: "pointer" }}
                              id="inlineCheckbox3"
                              value={DeclarationOfMSDS}
                              onChange={(e) => this.checkBoxClick(e)}
                            />
                            <label
                              htmlFor="check3"
                              style={{ paddingLeft: "4px" }}
                            >
                              {" "}
                              Declaration of MSDS
                            </label>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="doc-declaration-checkbox">
                            <input
                              type="checkbox"
                              name="Miscellaneous"
                              style={{ cursor: "pointer" }}
                              id="inlineCheckbox4"
                              value={Miscellaneous}
                              onChange={(e) => this.checkBoxClick(e)}
                            />
                            <label
                              htmlFor="check4"
                              style={{ paddingLeft: "4px" }}
                            >
                              {" "}
                              Miscellaneous
                            </label>
                          </div>
                        </div>
                      </div>

                      {listDeclarationData != undefined &&
                      listDeclarationData.length > 0
                        ? listDeclarationData.map((item, index) => {
                            return (
                              <div className="doc-gray-box" key={index}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <label>
                                      {item.name == "DeclarationOfConformity"
                                        ? "Declaration of Conformity"
                                        : item.name ==
                                          "DeclarationOfAsbestosFree"
                                        ? "Declaration of Asbestos Free"
                                        : item.name == "DeclarationOfMSDS"
                                        ? "Declaration of MSDS"
                                        : "Miscellaneous"}
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="doc-form-group">
                                      <label htmlFor="">
                                        Date of Declaration:
                                      </label>
                                      <input
                                        type="name"
                                        className="form-control input-validation"
                                        id="DeclarationDate"
                                        name="DeclarationDate"
                                        value={DateOfDeclaration}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="doc-form-group">
                                      <label htmlFor="">
                                        Items Declared For:
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        id="ItemsDeclaredFor"
                                        name="ItemsDeclaredFor"
                                        value={item.ItemsDeclaredFor}
                                        list="addLineItemMdsdoc-options"
                                        onChange={(e) =>
                                          this.onAddLineItemDescriptionTextChange(
                                            e,
                                            item.name
                                          )
                                        }
                                      />
                                      <datalist id="addLineItemMdsdoc-options">
                                        {tableABList == null
                                          ? null
                                          : tableABList.map(
                                              (item, key, index) => {
                                                return (
                                                  <option
                                                    value={item.label}
                                                  ></option>
                                                );
                                              }
                                            )}
                                      </datalist>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="doc-form-group">
                                      <div
                                        className={
                                          item.isErrorpresent ||
                                          item.dateRangeError
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <label
                                          htmlFor=""
                                          className="required-field"
                                        >
                                          Expiry Date:
                                        </label>
                                        <DatePicker
                                          isNotRoot={true}
                                          min="0"
                                          selectedDate={item.ExpiryDate}
                                          onDateChange={(e) =>
                                            this.onDeclarationDateChange(
                                              e,
                                              item.name,
                                              "ExpiryDate"
                                            )
                                          }
                                          minDate={item.nextCurrentDate}
                                        >
                                          {" "}
                                        </DatePicker>
                                        {(item.isErrorpresent ||
                                          item.dateRangeError) && (
                                          <div
                                            className="invalid-dropdown-icon"
                                            style={{
                                              right: "13px",
                                              top: "28px",
                                            }}
                                          >
                                            <img
                                              src="images\icon material-error.png"
                                              alt="invalid"
                                            ></img>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="doc-form-group">
                                      <label htmlFor="">Supplier Name:</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="supplierName"
                                        value={supplierName}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="doc-form-group">
                                      <label htmlFor="">Division:</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="Division"
                                        value={item.Division}
                                        onChange={(e) =>
                                          this.onTextDeclartionChange(
                                            e,
                                            item.name
                                          )
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="doc-form-group">
                                      <label htmlFor="">Remarks:</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="Remarks"
                                        value={item.Remarks}
                                        onChange={(e) =>
                                          this.onTextDeclartionChange(
                                            e,
                                            item.name
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div
                                      className="choosefile-btn choosefile-group"
                                      onClick={
                                        item.name == "DeclarationOfConformity"
                                          ? () =>
                                              this.DeclarationOfConformity.current.click()
                                          : item.name ==
                                            "DeclarationOfAsbestosFree"
                                          ? () =>
                                              this.DeclarationOfAsbestosFree.current.click()
                                          : item.name == "DeclarationOfMSDS"
                                          ? () =>
                                              this.DeclarationOfMSDS.current.click()
                                          : () =>
                                              this.Miscellaneous.current.click()
                                      }
                                    >
                                      <label
                                        htmlFor="actual-btn"
                                        className={
                                          "attach-btn attach-btn-responsive chs-btn " +
                                          (item.isFileErrorpresent
                                            ? "invalid-position-border"
                                            : "")
                                        }
                                      >
                                        <img
                                          className="upload-img"
                                          src="images/Icon awesome-upload.png"
                                          width="18px"
                                        />
                                        <span>Choose File</span>
                                      </label>
                                      {item.isFileErrorpresent && (
                                        <img
                                          src="images\icon material-error.png"
                                          alt="invalid"
                                          className="invalid-position-img"
                                          style={{ width: "16px" }}
                                        />
                                      )}
                                      <label className="required-field">
                                        &nbsp; (Please select a PDF or Document
                                        file)
                                      </label>
                                      <input
                                        ref={
                                          item.name == "DeclarationOfConformity"
                                            ? this.DeclarationOfConformity
                                            : item.name ==
                                              "DeclarationOfAsbestosFree"
                                            ? this.DeclarationOfAsbestosFree
                                            : item.name == "DeclarationOfMSDS"
                                            ? this.DeclarationOfMSDS
                                            : this.Miscellaneous
                                        }
                                        type="file"
                                        accept={validFileFormatsDeclaration}
                                        id="file"
                                        name="TFile"
                                        onChange={(e) =>
                                          this.onAttachmentChange(e, item.name)
                                        }
                                        key={fileInputKey || ""}
                                        style={{ display: "none" }}
                                      />
                                      <label
                                        style={{
                                          overflowWrap: "anywhere",
                                          margin: "10px",
                                        }}
                                      >
                                        &nbsp; {item.fileName}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}

                      {/*  */}
                    </div>
                  ) : null}
                  {/*  */}

                  {declarationCheckBoxNotSelected && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Please Select atleast one checkbox.</p>
                    </div>
                  )}

                  {/* {PoNumberNotSelectedError && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Please Select atleast one PO number to upload MD/SDoc.</p>
                    </div>
                  )} */}

                  {dateRangeError && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Expiry Date should be greater than Issue Date.</p>
                    </div>
                  )}
                  {showErrorMessage && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Please fill in all the mandatory fields.</p>
                    </div>
                  )}

                  {showNotSelectedEror && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Please select the document type.</p>
                    </div>
                  )}

                  {ShowErrorMessageOnMDSDocSelected && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>
                        Please choose digital MD/SDoc or upload a MD/SDoc to
                        proceed.
                      </p>
                    </div>
                  )}

                  <div className="upload-btn">
                    <button
                      type="button"
                      className="btn btn-background-color document-detail-upload-btn"
                      onClick={this.onSaveClick}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SupplierDocumentModal);
