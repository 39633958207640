import React from "react";

const ConfirmationModal = ({
  onModalCloseClick,
  deleteIHMDocData,
  modalText,
}) => {
  return (
    <div className="deletedoc" style={{ display: "block" }}>
      <div
        className="modal-backdrop fade show"
        style={{ display: "block" }}
      ></div>
      <div className="modal" id="deleteDoc-modal" style={{ display: "block" }}>
        <div className="modal-dialog modal-l modal-dialog-centered modal-box">
          <div className="modal-content box">
            <div className="modal-body">
              <div className="block1" style={{ whiteSpace: "pre-line" }}>
                Are you sure you want to {modalText ? modalText : "delete?"}
              </div>
              <div className=" text-center yes-no-btn">
                <div>
                  {" "}
                  <button
                    type="button"
                    className="btn btn-background-color yes-btn "
                    onClick={deleteIHMDocData}
                  >
                    Yes
                  </button>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                <div>
                  <button
                    type="button"
                    className="btn btn-background-color  yes-btn"
                    onClick={onModalCloseClick}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
