export const ArchiveAction = {
    USER_LOGIN: 100,
    PASSWORD_RESET_EMAIL_SENT: 101,
    PASSWORD_RESETTED: 102,

    TRACKING_TOOL_IMPORTED_API: 103,
    TRACKING_TOOL_IMPORTED_EXCEL: 104,
    //AUTOEMAIL_TO_REQUEST_FOR_MDSDOC_TRIGERRED :105,
    MANUALEMAIL_TO_REQUEST_FOR_MDSDOC_SENT: 106,
    MDSDOC_RESENT_FOR_CORRECTION: 107,

    CONTACT_US_FORM_SENT: 108,
    NEW_USER_CREATED: 109,
    NEW_SHIP_CREATED: 110,
    NEW_CLIENT_CREATED: 111,
    NEW_SUPPLIER_CREATED: 112,

    USER_DELETED: 113,
    SHIP_SET_INACTIVE: 114,
    CLIENT_SET_INACTIVE: 115,
    SUPPLIER_SET_INACTIVE: 116,
    SUPPLIER_SET_ONHOLD_BY_SELF: 117,

    CHANGE_REQUEST_SENT: 118,
    CHANGE_REQUEST_ACCEPTED: 119,
    CHANGE_REQUEST_REJECTED: 120,

    SUPPLIER_REGISTRATION_ACCEPTED: 121,
    SUPPLIER_REGISTRATION_REJECTED: 122,

    DOCUMENT_SENT_FOR_APPROVAL: 123,
    DOCUMENT_SENT_FOR_APPROVAL_APPROVED: 124,
    DOCUMENT_SENT_FOR_APPROVAL_REJECTED: 125,
    DOCUMENT_SENT_FOR_APPROVAL_RESENT_FOR_CORRECTIONS: 126,

    SDOC_REGULATIONS_UPDATED_BY_ADMIN: 127,
    AUTOEMAIL_FOR_PENDING_MDSDOC_TRIGGERED: 128,
    AUTOEMAIL_FOR_IHMPART1_TRIGGERED: 129,
    AUTOEMAIL_FOR_SOCEU_TRIGGERED: 130,
    AUTOEMAIL_FOR_SOCHK_TRIGGERED: 131,
    NO_OF_AUTO_EMAIL_REMINDERS_FOR_MDSDOC_UPDATED: 132,

    REPORT_OR_DOCUMENT_DOWNLOADED: 133,
    REPORT_OR_DOCUMENT_UPLOADED: 134,
    SUPPLIER_SET_ONHOLD_BY_ADMIN: 135,
    TRACKING_TOOL_DOWNLOADED: 136,
    ADDED_SUSPECTED_ITEMS_INTO_TRACKING_TOOL_AFTER_IMPORT: 137,
    EMAIL_SENT_TO_MULTIPLE_SUPPLIERS_FROM_TRACKING_TOOL: 138,
    COMMUNICATION_EMAIL_SENT: 139,
};

export const BROWSER_TYPE_ID = {
    CHROME: 1,
    FIREFOX: 2,
    SAFARI: 3,
    OPERA: 4,
    EDGE: 5,
};

export const REGION_ID = {
    Global: 0,
    OSSEM: 1,
    SouthEU: 2,
    WestEU: 3,
    NorthEastAsia: 4,
    China: 5,
};

export const CLIENT_STATUS_ID = {
    ACTIVE: 1,
    INACTIVE: 2,
};

export const Email_Default_ID = {
    MDSDoC: 1,
    QuarterlyEmail: 2,
    AnnualReportEmail: 3,
    SOCExpiryEmail: 4,
    CustomEmail: 5,
    ReminderEmail: 6,
};

export const YesNoOptions = {
    YES: 1,
    NO: 2,
};

export const YesNoOptionsText = {
    YES: "Yes",
    NO: "No",
};

export const RequestStatus = {
    PENDING: "Pending",
    COMPLETED: "Completed",
    INPROGRESS: "In Progress",
    ONHOLD: "On Hold",
};

export const SettingsTab = {
    ACTIVITY_LOG: 1,
    EMAIL_SETTINGS: 2,
    SD_REGULATIONS: 3,
    REPORTS_SETTINGS: 4,
    LOGIN_SETTINGS: 5,
};

export const ErrorViewFor = {
    INVALID_DROPDOWN_ICON: 1,
    INVALID_MESSAGE_ICON: 2,
    DEFAULT_INVALID_DROPDOWN_ICON: 3,
};

export const SupplierStatus = {
    ACTIVE: "1",
    INACTIVE: "2",
    ONHOLD: "3",
};

export const TitlePageNames = {
    MAINTITLE: "IHM & Ship Recycling Services",
    CLIENTDETAILS: "Client Details",
    USERDETAILS: "User Details",
    SHIPDETAILS: "Ship Details",
    CLIENTUSERDETAILS: "Client User Details",
    TRACKINGTOOLDETAILS: "Tracking Tool Details",
    SUPPLIERDETAILS: "Supplier Profile Details",
    CLIENTROLESANDPERMISSION: "Client Roles And Permissions",
    ROLESANDPERMISSION: "Roles And Permissions",
    IMPORTPO: "PO Items Data",
    IHMDATABASEREGISTRY: "IHM Database & Registry",
    USERPROFILEDETAILS: "User Profile Details",
    TRAINING: "Training",
    REPORTS: "Reports",
    SUPPLIERDOCUMENTS: "Documents",
    SUPPLIERVIEWITEMS: "Supplier View Items",
    SUPPLIERMDSDOCITEMS: "Supplier MDSDoC Items",
    IMPORTPOAddHazmat: "Suspected Hazmat List",

}

export const UserAction = {

    READ: 1,
    READWRITE: 2,
    DENY: 3
};

export const UserLevel = {
    LEVEL_1: 1,
    LEVEL_2: 2,
    LEVEL_3: 3,
    CUSTOM: 4
};

export const UserRole = {

    ADMIN: 1,
    INTERNALUSER: 2,
    ACCOUNTS: 3,
    CLIENT: 4,
    SHIPCREW: 5,
    SUPPLIER: 6,
    CLIENTUSER: 7
};


export const AddNewValue = {
    AddNew: 0,
}

export const SITERIGHTS = {
    ResetPassword: 1,
    ChangeRequest: 2,
    ActionRequired: 3,
    CommunicationOtherLink: 4,
    IHMDatabaseRegistryOtherLink: 5,
    MiscellaneousOperationsOtherLink: 6,
    ReportsOtherLink: 7,
    StatisticsOtherLink: 8,
    DocumentsOtherLink: 9,
    Settings: 10,
    DashboardWithSubMenus: 11,
    HideClientShipPOLineNumber: 12,
    ShowUploadButtonTrackingTool: 13
};


export const DOCUMENT_STATUS = {
    PENDING: 1,
    COMPLETED: 2,
    RESENT_FOR_CORRECTION: 3,
};

export const REPORT_DOC_TYPE = {
    READY_REPORT: 1,
    GENERATE_REPORT: 2
};
export const SUPPLIER_SUMMARY = {

    NUMBER_OF_PO_ITEMS: 1,
    PENDING_MD_SDoC: 2,
    NUMBER_OF_SUSPECTED_HAZMAT: 3,
    NUMBER_OF_CONFIRMED_HAZMAT: 4,
    PENDING_MD_SDOC_WITH_CTI: 5,
};

export const DocumentTypeName = {
    IHMMaintenanceReadyReckonerReport: "IHM Maintenance Ready Reckoner Report (Monthly)",
    ClientSpecificDocumentTrackingReport: "Client Specific Document Tracking Report",
    ClientOverviewReport: "Client Overview Report",
    SOCExpiryReport: "SOC Expiry Report",
    Nonresponsivesupplier: "Non responsive supplier",
    AnnualMaintenanceReport: "IHM Annual Maintenance Report",

};

export const ReportName = {
    IHMMaintenanceReadyReckonerReport: "MONTHLY RECKONER REPORT",
    DocumentStatus: "IHMM DOCUMENTS STATUS",
    ClientOverviewReport: "Client Overview Report",
    SOCReport: "SOC",
    Nonresponsivesupplier: "NON RESPONSIVE SUPPLIERS LIST",
};

export const ReportsDescription = {
    IHMMaintenanceReadyReckonerReportDescription: "",
    DocumentStatusDescription: "DOCUMENTS RECEIVED & AVAILABLE AT CTI ARCHIVE",
    ClientOverviewReportDescription: "INDIVIDUAL CLIENT SPECIFIC DETAILS",
    SOCReportDescription: "IHMM STATEMENT OF COMPLIANCE DETAILS",
    NonresponsivesupplierDescription: "NRS REPORT",
};
export const DOCUMENT_TYPE = {
    IHM_Investigation_Report: 14,
    IHM_Part_1: 9,
    IHM_Part_1_Updated: 12,
    IHM_Asbestos_Removal_Report: 36,
};

export const KEY = {
    RECAPTCHA_SITE_KEY: "6LfGDGMqAAAAAEFbcKmYsycmIEL__y9mDTvCjLIQ",
}

export const Module = {
    TrackingTool: "TrackingTool",
    Ship: "Ship",
    Client: "Client",
    IHMRegistry: "IHMRegistry",
    Miscellaneous: "Miscellaneous",
    Training: "Training"
}

export const toolbarOptions = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
      'fontFamily',
    ],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
      className: 'custom-class',
    },
    fontSize: {
      options: [12, 14, 16, 18, 24, 30, 36, 48],
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      inDropdown: false,
      options: ['left', 'center', 'right', 'justify'],
    },
  
    link: {
      inDropdown: false,
    },
    fontFamily: {
      inDropdown: true,
      options: ['Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Verdana', 'Calibri', 'Cambria', 'Georgia',
        'Palatino', 'Garamond', 'Book Antiqua', 'Arial Black', 'Comic Sans MS', 'Impact', 'Lucida Sans Unicode',],
      className: 'custom-class',
    },
    // embedded: {
    //   defaultSize: {
    //     height: 'auto',
    //     width: 'auto',
    //   },
    // },
    // emoji: {
    //   // eslint-disable-next-line
    //   icon: () => <span>😀</span>,
    // },
    // image: {
    //   icon: () => <FontAwesomeIcon icon={faImage} />,
    //   className: undefined,
    //   component: undefined,
    //   popupClassName: undefined,
    //   urlEnabled: true,
    //   uploadEnabled: true,
    //   alignmentEnabled: true,
    //   uploadCallback: undefined,
    //   previewImage: false,
    //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    //   alt: { present: false, mandatory: false },
    //   defaultSize: {
    //     height: 'auto',
    //     width: 'auto',
    //   },
    // },
    // remove: {
    //   icon: () => <FontAwesomeIcon icon={faEraser} />,
    // },
    // history: {
    //   icon: () => <FontAwesomeIcon icon={faHistory} />,
    //   inDropdown: false,
    //   className: undefined,
    //   component: undefined,
    //   dropdownClassName: undefined,
    //   options: ['undo', 'redo'],
    // },
  };