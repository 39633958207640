import React, { Component } from "react";
import { components } from "react-select";
import Select from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some((val) => val.value === "0")) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const defaultDropdownStyles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: state.isDisabled ? "hsl(0,0%,90%)" : "white",
        width: '200px',
    }),

    placeholder: (defaultStyles, state) => {
        return {
            ...defaultStyles,
            color: "black",
            marginLeft: "6px",
        };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            whiteSpace: "pre",
            ":hover": {
                backgroundColor: "white",
            },
            color: "black",
            backgroundColor: "white",
            height: "28px",
            
        };
    },
    menuList: (base) => ({
        ...base,
        height: '120px',
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        padding: "0 6px",
    }),

    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "32px",
    }),

    dropdownIndicator: (base) => ({
        ...base,
        color: "#212529", // Custom colour

    }),
};

export default class MultiSelectDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [...this.props.options],
            selectedOptions: this.props.selectedOptions,
        };
    }

    componentDidUpdate = (prevProps) => {
        //code to update state when props change
        if (prevProps.options !== this.props.options) {
            var options = [...this.props.options];
            this.setState({ options });
        }
        if (prevProps.selectedOptions !== this.props.selectedOptions) {
            if (
                this.props.selectedOptions.length !== 0 &&
                this.props.selectedOptions.length === this.state.options.length
            )
                this.setState({ selectedOptions: [...this.props.options] });
            else this.setState({ selectedOptions: this.props.selectedOptions });
        }
    };

    onChange = (value, event) => {
        var { selectedOptions } = this.state;
        if (
            event.action === "select-option" &&
            event.option != undefined &&
            event.option.value === "0"
        ) {
            selectedOptions = this.state.options;
        } else if (
            (event.action === "deselect-option" &&
                event.option != undefined &&
                event.option.value === "0") ||
            (event.action === "remove-value" && event.removedValue.value == "0")
        ) {
            selectedOptions = [];
        } else if (event.action === "deselect-option") {
            selectedOptions = value.filter((o) => o.value !== "0");
        } else if (value.length === this.state.options.length) {
            selectedOptions = this.state.options;
        } else {
            selectedOptions = value;
        }

        this.setState({ selectedOptions: selectedOptions }, () => {
            this.props.onChange(this.state.selectedOptions);
        });
    };
    render() {
        const { options, selectedOptions } = this.state;
        return (
            <>
                {this.props.options != undefined &&
                    <Select
                        className={this.props.descriptionClassName ? this.props.descriptionClassName : ""}
                        name={`dropdown_${this.props.name}`}
                        options={options}
                        components={{
                            Option,
                            ValueContainer,
                        }}
                        placeholder="Select"
                        isSearchable={true}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={this.onChange}
                        allowSelectAll={true}
                        isDisabled={this.props.disabled ? true : false}
                        value={selectedOptions}
                        styles={defaultDropdownStyles}
                        classNamePrefix="react-select"
                    />}
            </>
        );
    }
}
