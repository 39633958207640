export function isStringEmpty(text) {
  const result = typeof text === "string" ? text.trim() : "";
  return !text || result.length === 0;
}

export function convertDate(str) {
  var date = new Date(str),
    mnth = date.toLocaleString("default", { month: "long" }).slice(0, 3),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}

/**
 * Siddharth: converts datetime object to HH:MM AM/PM format
 * @param {*} str
 * @returns
 */
export function convertTime(str) {
  var time = new Date(str);
  return time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export function sortAlphabetically(listToSort, propertyToCompare) {
  listToSort.sort((a, b) => {
    if (!a[propertyToCompare] || isStringEmpty(a[propertyToCompare])) return 1;

    if (!b[propertyToCompare] || isStringEmpty(b[propertyToCompare])) return -1;

    const string1 = a[propertyToCompare].toUpperCase();
    const string2 = b[propertyToCompare].toUpperCase();

    if (string1 < string2) return -1;

    if (string1 > string2) return 1;
    else return 0;
  });

  return listToSort;
}

export function sortByOrder(listToSort, propertyToCompare) {
  listToSort.sort((a, b) => {
    return a[propertyToCompare] - b[propertyToCompare];
  });
  return listToSort;
}

export function getFileName(path) {
  return path.substring(path.lastIndexOf("/") + 1);
}

const emailRegexString =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

export function isValidEmail(emailText) {
  return emailRegexString.test(emailText);
}

export function alphaNumComparator(valueA, valueB) {
  const regex = /([0-9]+)/g;
  if (valueA == null) {
    valueA = "0";
  }
  if (valueB == null) {
    valueB = "0";
  }
  const aMatch = valueA.match(regex);
  const bMatch = valueB.match(regex);

  if (aMatch && bMatch) {
    const aNum = parseInt(aMatch[0], 10);
    const bNum = parseInt(bMatch[0], 10);

    if (aNum !== bNum) {
      return aNum - bNum;
    }
  }
  return valueA
    .trim()
    .toLowerCase()
    .localeCompare(valueB.trim().toLowerCase());
}

// --1
export function customSortLineItemNoComparator(valueA, valueB) {
  const regex = /[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (valueA != null && valueB != null) {
    const aMatch = valueA.match(regex);
    const bMatch = valueB.match(regex);

    if (aMatch != null && bMatch != null) {
      if (aMatch && bMatch) {
        const aNum = parseInt(aMatch[0], 10);
        const bNum = parseInt(bMatch[0], 10);

        if (aNum !== bNum && !isNaN(aNum) && !isNaN(bNum)) {
          return aNum - bNum;
        }
      }
    }

    return valueA
      .trim()
      .toLowerCase()
      .localeCompare(valueB.trim().toLowerCase());
  }
}

export function customSortDocumentStatusComparator(
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted
) {
  // Get the values of the columns you want to compare
  const columnToSortByA = nodeA.data.statusText;
  const columnToSortByB = nodeB.data.statusText;
  // Compare the values and return the sorting result
  if (columnToSortByA > columnToSortByB) {
    return 1;
  } else if (columnToSortByA < columnToSortByB) {
    return -1;
  } else {
    return 0;
  }
}

export function customSortTableAComparator(
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted
) {
  // Get the values of the columns you want to compare
  const columnToSortByA = nodeA.data.tableAHazmatItemsText;
  const columnToSortByB = nodeB.data.tableAHazmatItemsText;
  // Compare the values and return the sorting result
  if (columnToSortByA > columnToSortByB) {
    return 1;
  } else if (columnToSortByA < columnToSortByB) {
    return -1;
  } else {
    return 0;
  }
}

export function customSortTableBComparator(
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted
) {
  // Get the values of the columns you want to compare
  const columnToSortByA = nodeA.data.tableBHazmatItemsText;
  const columnToSortByB = nodeB.data.tableBHazmatItemsText;
  // Compare the values and return the sorting result
  if (columnToSortByA > columnToSortByB) {
    return 1;
  } else if (columnToSortByA < columnToSortByB) {
    return -1;
  } else {
    return 0;
  }
}

export function customSortRemarkComparator(
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted
) {
  // Get the values of the columns you want to compare
  const columnToSortByA = nodeA.data.polineItemStatusToDisplay;
  const columnToSortByB = nodeB.data.polineItemStatusToDisplay;
  // Compare the values and return the sorting result
  if (columnToSortByA > columnToSortByB) {
    return 1;
  } else if (columnToSortByA < columnToSortByB) {
    return -1;
  } else {
    return 0;
  }
}

export function convertPOMinMaxDate(str, year, minusDate) {
  var date = new Date(str);
  let subtractedDate = date.getDate() - minusDate;
  let mnth = date.toLocaleString("default", { month: "short" }).slice(0, 3);
  let day = ("0" + subtractedDate).slice(-2);
  day = (day == "00") ? "01" : day;
  return [day, mnth, year].join("-");
}