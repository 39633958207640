import React, { Component } from "react";
import DatePicker from "../components/DatePickerComponent";
import Dropdown from "../components/Dropdown";
import { isValidFile } from "../helpers";
import { convertDate, isStringEmpty } from "../helpers/string-helper";
import { UncontrolledTooltip } from "reactstrap";

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" &&
        (
          <div style={{ right: "45px" }} className={props.errorFor == "" ? "invalid-dropdown-icon" : "invalid-message-icon"}>
            <img src="images\icon material-error.png" alt="invalid" />
          </div>
        )}
    </>
  );
};

export default class MoveDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInputKey: "",
      filePath: "",
      fileName: "",
      errorMessages: {},
      dropdown_ship: 0,
      dropdown_documentType: 0,
      clientName: this.props.clientName || "",
      remarks: "",
      issueDate: "",
      expiryDate: "",
      showErrorMessage: false,
      errors: {},
      emptyFile: false,
      uploadedFile: "",
      invalidFileFormt: false,
      TFile: [],
      docFileName: [],
      nextIssueDate: "",
      previousExpiryDate: ""
    };
    this.fileInput = React.createRef();
  }

  onDropdownChange = (e) => {
    const { showErrorMessage } = this.state;
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  validateFields() {
    const {
      dropdown_documentType,
      errorMessages,
    } = this.state;

    let errors = errorMessages;

    errors["errorDocumentType"] = dropdown_documentType == 0 ? "true" : "";

    if (errors["errorDocumentType"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: true,
      });
      return true;
    } else {
      errors["errorDocumentType"] = "";
      this.setState({
        invalidFileFormt: false,
        errorMessages,
        showErrorMessage: false,
      });
      return false;
    }
  }


  onSaveClick = () => {
    if (!this.validateFields()) {
      const {
        dropdown_ship,
        dropdown_documentType,
      } = this.state;

      const data = {
        dropdown_ship: dropdown_ship,
        dropdown_documentType: dropdown_documentType,
      };
      this.props.onMoveClicked(data);
    }
  };


  render() {
    const {
      fileName,
      errorMessages,
      fileInputKey,
      clientName,
      issueDate,
      expiryDate,
      remarks,
      showErrorMessage,
      emptyFile,
      invalidFileFormt,
      dropdown_ship,
      dropdown_documentType,
      nextIssueDate,
      previousExpiryDate
    } = this.state;

    const { onModalCloseClick, shipList, moveDocumentTypeModalList } =
      this.props;

    return (
      <div
        className="Training_Evalution client-modal-document"
        style={{ display: "block" }}
      >
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Link Document</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={onModalCloseClick}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="PO-list-topLabel">

                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorShip)
                            ? "invalid-position"
                            : ""
                        }                        
                        id={"shipName"}
                      >
                        <Dropdown
                          dropdownLabel="Ship:"
                          name="ship"
                          onChange={this.onDropdownChange}
                          selected={dropdown_ship}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {shipList == null
                            ? null
                            : shipList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_ship > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"shipName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="right"
                            style={{ width: "350px" }}
                          >
                            {shipList != null && shipList.filter(x => x.value == dropdown_ship)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.errorShip) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorDocumentType)
                            ? "invalid-position"
                            : ""
                        }
                        id={"docType"}
                      >
                        <label className={"required-field"}>Document Type:</label>
                        <Dropdown
                          name="documentType"
                          onChange={this.onDropdownChange}
                          selected={dropdown_documentType}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {moveDocumentTypeModalList == null
                            ? null
                            : moveDocumentTypeModalList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>                        
                        {dropdown_documentType > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"docType"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="right"
                            style={{ width: "350px" }}
                          >
                            {moveDocumentTypeModalList != null && moveDocumentTypeModalList.filter(x => x.value == dropdown_documentType)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.errorDocumentType) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="communications admin-ship-settings-save-btn button text-center">
                  <button
                    type="button"
                    className="btn btn-background-color view-history-btn upload-btn btn-padding"
                    onClick={this.onSaveClick}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
