import webserviceWrapper from "../helpers/webserviceWrapper";

export const ClientService = {
    GetManageClientData,
    UpdateClientProfileData,
    GenerateNewPassword,
    SaveClientProfileData,
    UpdateClientStatus,
    GetClientAndDefaultAccessPermissions,
    CheckIfEmailExists,
    GetDefaultPermissions,
    SaveDefaultPermissions,
    GetClientDropdownData,
    GetClientData,
    CheckIfClientCodeExist,
    AddClientDocData,
    GetClientDocumentData,
    DeleteClientDocData,
    DownloadClientDocument,
    GetClientHistory,
    GetManageClientUserData,
    GenerateNewClientUserPassword,
    CheckIfEmailExistsOfClientUser,
    GetClientUserData,
    SaveClientUserProfileData,
    GetClientUserShipData,
    GetManageClientChangeRequestData,
    GetClientUserAndDefaultAccessPermissions,
    UpdateClientChangeRequestData,
    SaveClientChangeRequestData,
    AddClientDocumentData,
    SaveClientUserChangeRequestData,
    MoveBulkDocumentData,
    GetClientProfileDocumentData
};

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get data for manage client screen
    <summary> */
function GetManageClientData(userId) {
    return webserviceWrapper.get(`/Client/GetManageClientData?userId=${userId}`);
}


function UpdateClientProfileData(data) {
    return webserviceWrapper.post(`/Client/UpdateClientProfileData`, data);
}

function GenerateNewPassword(clientId, roleid, username) {
    return webserviceWrapper.get(`/Client/GenerateNewPassword?clientId=${clientId}&roleId=${roleid}&userName=${username}`);
}

function SaveClientProfileData(data) {
    return webserviceWrapper.postWithHeader(`/Client/SaveClientProfileData`,
        data,
        { "Content-Type": "multipart/form-data" },
    );
}

function UpdateClientStatus(data) {
    return webserviceWrapper.post(`/Client/UpdateClientStatus`, data);
}

function CheckIfEmailExists(primaryEmailAddress) {
    return webserviceWrapper.get(`/Client/CheckIfEmailExists?primaryEmailAddress=${primaryEmailAddress}`);
}

function GetClientAndDefaultAccessPermissions(ClientId) {
    return webserviceWrapper.get(`/Client/GetClientAndDefaultAccessPermissions?clientId=${ClientId}`);
}

function GetClientUserAndDefaultAccessPermissions(ClientId, ClientUserId, roleId) {
    return webserviceWrapper.get(`/Client/GetClientUserAndDefaultAccessPermissions?clientId=${ClientId}&clientUserId=${ClientUserId}&roleId=${roleId}`);
}

function GetDefaultPermissions() {
    return webserviceWrapper.get(`/Client/GetDefaultPermissions`);
}

function SaveDefaultPermissions(data) {
    return webserviceWrapper.post(
        `/Client/SaveDefaultPermissions`,
        data,
        60000
    );
}

function GetClientDropdownData(clientId, clientUserId) {
    return webserviceWrapper.get(`/Client/GetClientDropdownData?clientId=${clientId}&clientUserId=${clientUserId}`);
}

function GetClientData(clientId) {
    return webserviceWrapper.get(`/Client/GetClientData?clientId=${clientId}`);
}

function CheckIfClientCodeExist(clientCode) {
    return webserviceWrapper.get(`/Client/CheckIfClientCodeExist?clientCode=${clientCode}`);
}

function AddClientDocData(userData) {
    return webserviceWrapper.postWithHeader(`/Client/AddClientDocData`,
        userData,
        { "Content-Type": "multipart/form-data" },
        600000
    );
}

function GetClientProfileDocumentData(clientId, clientUserId) {
    return webserviceWrapper.get(`/Client/GetClientProfileDocumentData?clientId=${clientId}&clientUserId=${clientUserId}`);
}

function GetClientDocumentData(clientId, clientUserId) {
    return webserviceWrapper.get(`/Client/GetClientDocumentData?clientId=${clientId}&clientUserId=${clientUserId}`);
}
function DeleteClientDocData(clientDocId, clientId) {
    return webserviceWrapper.get(`/Client/DeleteClientDocData?clientDocId=${clientDocId}&clientId=${clientId}`);
}

function DownloadClientDocument(clientDoc) {
    return webserviceWrapper.post(
        `/Client/DownloadClientDocument`,
        clientDoc,
        600000
    );
}

function GetClientHistory(clientId) {
    return webserviceWrapper.get(`/Client/GetClientHistory?clientId=${clientId}`);
}


function GetManageClientUserData(clientId) {
    return webserviceWrapper.get(`/Client/GetManageClientUsersData?clientId=${clientId}`);
}


function GenerateNewClientUserPassword(clientId, roleid, username) {
    return webserviceWrapper.get(`/Client/GenerateNewClientPassword?clientId=${clientId}&roleid=${roleid}&username=${username}`);
}


function CheckIfEmailExistsOfClientUser(email) {
    return webserviceWrapper.get(`/Client/CheckIfEmailExistsOfClientUser?emailaddress=${email}`);
}

function GetClientUserData(clientId, ClientUserId) {
    return webserviceWrapper.get(`/Client/GetManageClientUser?clientId=${clientId}&clientUserId=${ClientUserId}`);
}

function SaveClientUserProfileData(data) {
    return webserviceWrapper.postWithHeader(`/Client/SaveClientUserProfileData`,
        data,
        { "Content-Type": "multipart/form-data", },
        600000);
}

function GetClientUserShipData(clientId) {
    return webserviceWrapper.get(`/Client/GetClientuserShipListDataFromClient?clientId=${clientId}`);
}

function GetManageClientChangeRequestData(userID, userRoleID) {

    return webserviceWrapper.get(`/Client/GetManageClientChangeRequestData?userID=${userID}&userRoleID=${userRoleID}`);
}

function UpdateClientChangeRequestData(data) {
    return webserviceWrapper.post(`/Client/UpdateClientChangeRequestData`, data);
}

function SaveClientChangeRequestData(clientData) {
    return webserviceWrapper.postWithHeader(`/Client/SaveClientChangeRequestData`,
        clientData, { "Content-Type": "multipart/form-data" },
        600000
    );
}

function AddClientDocumentData(userData) {
    return webserviceWrapper.postWithHeader(`/Client/AddClientDocumentData`,
        userData,
        { "Content-Type": "multipart/form-data" },
        600000
    );
}

function SaveClientUserChangeRequestData(clientUserData) {
    return webserviceWrapper.postWithHeader(`/Client/SaveClientUserChangeRequestData`, clientUserData, {"Content-Type": "multipart/form-data",}, 600000);
}

function MoveBulkDocumentData(documentData) {
    return webserviceWrapper.postWithHeader(`/Client/MoveBulkDocumentData`,
        documentData,
        { "Content-Type": "multipart/form-data" },
        600000
        );
}
