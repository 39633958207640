import webserviceWrapper from "../helpers/webserviceWrapper";

export const CommonService = {
    CheckIfUserNameExist,
    SendEmailToUser,
    CheckIfEmailExists,
    CheckIfUserIsAdmin,
    CheckIfUserNotAdmin,
    AddNewDocumentType,
    CheckIfDocumentTypeExist
};

/* <summary>
date: 13/02/2023
Name: HV
description: service api call to check if user name already exist
<summary> */
async function CheckIfUserNameExist(data) {

    try {
        return await webserviceWrapper.post(`/Common/CheckIfUserNameExist`, data);
    }
    catch (err) {

    }
}

async function CheckIfEmailExists(data) {

    try {
        return await webserviceWrapper.post(`/Common/CheckIfEmailExists`, data);
    }
    catch (err) {
    }
}

async function CheckIfUserIsAdmin(emailId) {
    try {
        return await webserviceWrapper.get(`/Common/CheckIfUserIsAdmin?userEmail=${emailId}`);
    }
    catch (err) {
    }
}

/* <summary>
date: 13/04/2023
Name: DN
description: service api call to check if email address already registered
<summary> */
function SendEmailToUser(userID, userTypeId) {
    return webserviceWrapper.get(`/Common/SendEmailToUser?userID=${userID}&userTypeId=${userTypeId}`);
}

async function CheckIfUserNotAdmin(emailId) {
    try {
        return await webserviceWrapper.get(`/Common/CheckIfUserNotAdmin?userEmail=${emailId}`);
    }
    catch (err) {
    }
}

/* <summary>
date: 09/08/2023
Name: HV
description: common method for adding new document type if other option selected
<summary> */
function AddNewDocumentType(docData) {
    return webserviceWrapper.post(`/Common/AddNewDocumentType`, docData);
}

/* <summary>
date: 09/08/2023
Name: HV
description: common method for checking if document type exist
<summary> */
function CheckIfDocumentTypeExist(docData) {
    return webserviceWrapper.post(`/Common/CheckIfDocumentTypeExist`, docData);
}