import React, { Component } from "react";
import { changePageTitle, withRouter, isEmailValid, AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse } from "../../helpers";
import Header from "../../components/Header.jsx";
import { ClientService } from "../../services/client.service";
import { Module, UserAction } from "../../helpers/enums";
import {
  generateRandomString,
  helperText,
  isValidFile,
  isPasswordValid,
} from "../../helpers";
import Footer from "../../components/Footer.jsx";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import RolesPermissionModal from "./ClientRolesPermissionModal";
import Dropdown from "../../components/Dropdown";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import DatePicker from "../../components/DatePickerComponent";
import AGGrid from "../../components/AGGrid";
import historyIcon from "../../assets/images/admin-history.png";
import deleteIcon from "../../assets/images/delete.png";
import moveIcon from "../../assets/images/move.png";
import editIcon from "../../assets/images/profile-feather-edit.png";
import downloadIcon from "../../assets/images/admin-download.png";
import addProfileIcon from "../../assets/images/plus.png";
import eyeIcon from "../../assets/images/admin-eye.png";
import {
  isStringEmpty,
  convertDate,
  isValidEmail,
} from "../../helpers/string-helper";
import UploadClientDocumentModal from "./UploadClientDocumentModal";
import Logout from "../../helpers/Logout";
import ViewModal from "../../components/ViewModal";
import UserCredentials from "../../components/UserCredentials";
import ViewDocumentHistoryModal from "../../components/ViewDocumentHistoryModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import DirtyModal from "../../components/DirtyModal";
import { CommonService } from "../../services/common.service";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import ClientChangeRequestModal from "../ClientShipPortal/ClientChangeRequestModal";
import ProceedModal from "../../components/ProceedModal";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { UserService } from "../../services/user.service";
import MoveDocumentModal from "../../components/MoveDocumentModal";
import ClientRolesNPermissionModal from "../../components/ClientRolesNPermissionModal";
import DeleteModal from "../../components/DeleteModel";
import { UncontrolledTooltip } from "reactstrap";

const NUMBER_REGEX = /^[0-9()+-\s]+$/;
const NUMBER_REGEX_POSTCODE = /^[0-9\s]+$/;
const validFileFormats = ["png, jpg, jpeg"];
//const fileUrl = `${process.env.REACT_FILE_SERVER_URL}/ProfileImages/`;
//const defaultAllItem = { label: "All", value: "0" };
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";

class AddEditClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      errorMessages: {},
      userId: 0,
      serviceCategoryList: [],
      dropdown_maritecBDMList: [],
      ihmmEmailValue: "",
      alternateEmailValue: "",
      secondaryEmailDetails: [],
      clientRoleList: [],
      dropdown_region: 0,
      dropdown_alternateIhmmInCharge: [],
      shipList: [],
      clientPicList: [],
      ihmmInChargeList: [],
      alternateIhmmInChargeList: [],
      //maritecBDMList: [],
      maritecBDM: "",
      regionList: [],
      countryList: [],
      secondaryEmailAddress: "",
      primaryEmailAddress: "",
      showErrorMessage: false,
      mappedDocuments: [],
      clientId: parseInt(GetLocalStorageDataWithoutParse("selectedClient"), 10) || 0,
      clientUserName: "",
      clientPassword: "",
      profilePictureUrl: "",
      profilePicture: "",
      companyName: "",
      companyContact: "",
      clientName: "",
      clientEmail: "",
      clientContact: "",
      clientPincode: "",
      clientCity: "",
      clientAddress: "",
      clientContractualBriefTerms: "",
      dateOfContract: "",
      dateOfContractExpiry: "",
      ihmmInCharge: [],
      alternateihmmInCharge: [],
      fileInputKey: generateRandomString(),
      showPassword: false,
      createdOn: "",
      showUploadModal: false,
      documentTypeList: [],
      moveDocumentTypeModalList: [],
      dropdown_inheritedClientRoleId: 0,
      dropdown_clientRole: 0,
      dropdown_country: 0,
      dropdown_ihmmInCharge: 0,
      statusId: 0,
      shipName: "",
      allClientFiles: [],
      selectedClientDocListIds: [],
      filePathToView: "",
      showViewModal: false,
      filesBasedDocumentType: [],
      showHistoryModal: false,
      clientCodeExistError: false,
      documentColumns: this.gridDocumentColumns,
      clientRolesPermissions: [],
      customClientRolesPermissions: [],
      notificationFrequencyList: [],
      dropdown_poFrequency: 2,
      showConfirmationPopup: false,
      selectdDocDeleteId: 0,
      selectdClientDeletId: 0,
      usernameExistError: false,
      showChangePasswordConfirmationModal: false,
      isClient: false,
      showClientRequestModal: false,
      requestType: "",
      showProceedModal: false,
      selectedServiceCategoryIds: "",
      defaultClientPicList: [],
      clientPICEmail: "",
      selected_clientPicList: [],
      selectedServiceCategoryList: [],
      showErrorMessageEmail: false,
      profilePictureFileMediaId: 0,
      isActive: false,
      showErrorMessageMaritecEmail: false,
      saveButtonClick: false,

      showMoveToDocumentPopup: false,
      selectedFileMediaId: 0,
      accessList: [],
      userRolePermissionRead: false,

      viewRolesPermissionVisible: false,
      addNewUserPermission: false,
      isDirty: false,
      userTypeId: 0,
      dateOfRenewContract: "",
      dateOfRenewContractExpiry: "",
      showRenwaldatechange: false,
      savedRenewalContract: false,
      defaultIhmmInChargeList: [],
      defaultAlternateIhmmInChargeList: [],
      emailExistsError: false,
      showDirtyPopup: false,
      path: "",
      shortEmployeeNameError: false,
      createdOnDate: "",
      isProfileImgDeleted: false,
      isDataEditable: true,
      isContractExpiry: false,
    };
  }

  /* <summary>
  date: 29/12/2022
  Name: AN
  description: Get data to bind on the Add Edit client screen
  <summary> */
  componentDidMount() {
    try {
      //HV - 20/02/2023 - changing browser title name
      changePageTitle(TitlePageNames.CLIENTDETAILS);
      // window.addEventListener("beforeunload", this.handlePrompt);
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      if (userData.typeId == UserRole.CLIENT) {
        this.setState({
          clientid: userData.id,
        })
      }

      if (userData != null) {
        this.setState(
          {
            userId: userData.id,
            isClient: userData.typeId == UserRole.CLIENT,
            userTypeId: userData.typeId,
            isLoading: true,
            accessList: GetLocalStorageDataWithParse("userRolePermission"),
          },
          async () => {

            try {
              await this.getDropdownData().then(() => {
                if (this.state.clientId == 0) this.onChangePasswordClick(null);
                //this.getSystemGeneratedPasswordForNewUser();
              });
            } catch (error) { }
          }
        );
      }
    } catch (error) {
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("beforeunload", this.handlePrompt);
  }

  // componentDidUpdate = () => {
  //   const { isDirty } = this.state;
  //   if (isDirty) {
  //     window.onbeforeunload = () => true
  //   } else {
  //     window.onbeforeunload = undefined
  //   }
  // }

  // handlePrompt(event) {
  //   const { isDirty } = this.state;
  //   if (isDirty) {
  //     event.preventDefault();
  //     event.returnValue = "Are you sure you want to leave without saving";
  //   } 
  // }

  getDropdownData = async () => {
    this.setState({
      isLoading: true,
      userRolePermissionRead:
        this.state.accessList != null &&
        this.state.accessList.clientAndShipAccess == UserAction.READ,
      addNewUserPermission: !(
        this.state.accessList != null &&
        (this.state.accessList.addClientAccess == UserAction.READ ||
          this.state.accessList.addClientAccess == UserAction.DENY)
      ),
    });
    const response = await ClientService.GetClientDropdownData(
      this.state.clientId,
      0
    );

    if (response != null && response != undefined && response.status == 200) {
      // var maritecBDMList = response.data.maritecBDMList;
      // maritecBDMList.unshift(defaultAllItem);

      var alternateIhmmInChargeList = response.data.alternateIhmmInChargeList;
      var ihmmInChargeList = response.data.ihmmInChargeList

      this.setState(
        {
          serviceCategoryList: response.data.serviceCategoryList,
          clientRoleList: response.data.clientRoleList,
          defaultClientPicList: response.data.clientPicList,
          ihmmInChargeList: ihmmInChargeList,
          defaultIhmmInChargeList: ihmmInChargeList,
          alternateIhmmInChargeList: alternateIhmmInChargeList,
          defaultAlternateIhmmInChargeList: alternateIhmmInChargeList,
          //maritecBDMList: response.data.maritecBDMList,
          regionList: response.data.regionList,
          countryList: response.data.countryList,
          documentTypeList: response.data.documentTypeList,
          moveDocumentTypeModalList: response.data.moveDocumentTypeModalList,
          notificationFrequencyList: response.data.notificationFrequencyList,
          //isLoading: false
        },
        async () => {
          this.getClientAndDefaultAccessPermissions(false);

          if (
            parseInt(GetLocalStorageDataWithoutParse("selectedClient"), 10) == 0 &&
            !this.state.saveButtonClick
          ) {
            this.BindAddClientProfileData();
          } else {
            if (this.state.saveButtonClick)
              await this.getClientData(this.state.clientId);
            else

              var data = GetLocalStorageDataWithParse("selectedClient");
            if (data != null) {
              var id = data?.clientId > 0 ? data?.clientId : data > 0 ? data : 0;
              await this.getClientData(id);
            }

            this.setState({ saveButtonClick: false });
          }
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
  };

  showDeleteDocModal = (id, clientid) => {
    this.setState({
      showConfirmationPopup: true,
      selectdDocDeleteId: id,
      selectdClientDeletId: clientid,
    });
  };

  showMoveToDocumentModal = (data) => {
    this.setState({
      showMoveToDocumentPopup: true,
      selectedFileMediaId: data.fileMediaId,
    });
  };

  /* <summary>
  date: 28/2/2023
  Name: DP
  description: passed file media id in place of clintbridgeid
  <summary> */

  gridColumns = (isClient, isActive) => {
    return [
      {
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        unSortIcon: false,
      },
      {
        headerName: "Document Name",
        field: "documentName",
        tooltipField: "documentName",
        flex: 1,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Document Type",
        field: "documentTypeName",
        tooltipField: "documentTypeName",
        width: 340,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Revised Document",
        //field: "revised",
        cellRenderer: (params) =>
          params.data.documentTypeName.includes("-") == true ? '-' : params.data.revised,
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) =>
          params.data.documentTypeName.includes("-") == true && (this.state.accessList != null ?
            this.state.accessList.clientAndShipAccess == UserAction.READWRITE : true) ? (
            <img
              className="delete-cursor-class cursor-class"
              src={moveIcon}
              width="20px"
              title="Move"
              data-bs-toggle="modal"
              data-bs-target="#move-icon"
              onClick={() => this.showMoveToDocumentModal(params.data)}
            />
          ) : null,
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="eye-cursor-class cursor-class"
            src={eyeIcon}
            width="20px"
            title="View"
            data-bs-toggle="modal"
            data-bs-target="#eye-icon"
            onClick={() => this.viewClientDocData(params.data.fileMediaId)}
          />
        ),
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="download-cursor-class cursor-class"
            src={downloadIcon}
            style={{ cursor: "pointer" }}
            width="20px"
            data-bs-toggle="modal"
            title="Download"
            data-bs-target="#download-icon"
            onClick={() =>
              this.downloadClientDocData(
                params.data.fileMediaId,
                params.data.clientId
              )
            }
          />
        ),
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => ((params.data.documentTypeId != null) &&
          (this.state.allClientFiles.length != 0 ? !(this.state.allClientFiles.filter((x) => x.documentTypeId == params.data.documentTypeId).length === 1) : true) ?
          <img
            className="history-cursor-class"
            src={historyIcon}
            width="20px"
            data-bs-toggle="modal"
            title="History"
            data-bs-target="#history-icon"
            onClick={() => this.viewDocumentHistory(params.data.documentTypeId)}
          />
          :
          <></>
        ),
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        hide: isClient || !isActive || this.state.userRolePermissionRead,
        cellRenderer: (params) => (
          <img
            className="delete-cursor-class cursor-class"
            src={deleteIcon}
            width="15px"
            title="Delete"
            data-bs-toggle="modal"
            data-bs-target="#delete-icon"
            onClick={() =>
              this.showDeleteDocModal(
                params.data.fileMediaId,
                params.data.clientId
              )
            }
            style={{ marginLeft: "-5px" }}
          />
        ),
      },
    ];
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
date: 28/2/2023
Name: DP
description: passed file media id in place of clintbridgeid
<summary> */
  gridDocumentColumns = [
    {
      headerName: "Serial Number",
      //   fieldName: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      tooltipField: "serialNumber",
      unSortIcon: false,
      sortable: false,
      filter: false,
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
    },
    {
      headerName: "Document Name",
      field: "documentName",
      tooltipField: "documentName",
      width: 290,
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Date of Creation",
      field: "createdOn",
      tooltipField: "createdOn",
      cellRenderer: (params) => convertDate(params.data.createdOn),
      width: 200,
      wrapText: true,
    },
    {
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={eyeIcon}
          onClick={() => this.viewClientDocData(params.data.fileMediaId)}
          width="20px"
        />
      ),
    },
    {
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="download-cursor-class"
          src={downloadIcon}
          style={{ cursor: "pointer", width: "28px", marginLeft: "-5px" }}
          data-bs-toggle="modal"
          data-bs-target="#download-icon"
          onClick={() =>
            this.downloadClientDocData(
              params.data.fileMediaId,
              params.data.clientId
            )
          }
        />
      ),
    },
  ];

  /* <summary>
  date: 29/12/2022
  Name: AN
  description: On add new client, pre fill creation date, password, set status as Active  
  <summary> */
  BindAddClientProfileData = async () => {
    this.setState({
      createdOn: convertDate(new Date()),
      statusId: 1,
    });
    //this.onChangePasswordClick(true);
  };

  /* <summary>
  date: 17/01/2023
  Name: HV
  description: getting client data based on client id when edit click
  <summary> */
  getClientData = async (clientId) => {
    try {
      this.setState({
        isLoading: true,
      });

      const response = await ClientService.GetClientData(clientId);

      if (
        response.data != null &&
        response.data != undefined &&
        response.status == 200
      ) {
        var clientData = response.data[0];

        let selectedServiceCategoryList = clientData.serviceCategoryList.filter(
          (x) => x.isChecked == true
        );
        let finalClientPic = [];
        const { defaultClientPicList, defaultAlternateIhmmInChargeList, defaultIhmmInChargeList } = this.state;

        if (selectedServiceCategoryList.length > 0) {
          selectedServiceCategoryList.map((x) => {
            return defaultClientPicList.map((y) => {
              if (x.value == y.parentValue) {
                return finalClientPic.push(y);
              }
            });
          });
        }

        finalClientPic = Array.from(
          new Map(finalClientPic.map((o) => [o.value, o])).values()
        );
        var serviceCategory = selectedServiceCategoryList.map((x) => {
          return x.value;
        });
        const { errorMessages } = this.state;
        let errors = errorMessages;
        errors["secondaryEmailValidation"] = "";
        errors["secondaryMaritecEmailValidation"] = "";
        errors["secondaryPrimaryAddressEmailValidation"] = "";
        var selectClientPIC = [];
        let clientPicEmails = "";
        if (clientData.listClientPIC.length > 0) {
          // Use a Set to store unique selectClientPIC values
          const uniqueSelectClientPIC = new Set();

          clientData.listClientPIC.forEach((y) => {
            finalClientPic.forEach((x) => {
              if (x.value === y.value) {
                uniqueSelectClientPIC.add(x);
              }
            });
          });

          // Convert the Set back to an array
          selectClientPIC = Array.from(uniqueSelectClientPIC);

          if (clientData.clientPICEmail.length > 0) {
            // Use map to extract emails and then join them
            clientPicEmails = selectClientPIC.map(x => x.email).join(", ");
          }
        }


        let filteredAlternateIhmmInChargeList = defaultAlternateIhmmInChargeList;
        let filteredIhmmInChargeList = defaultIhmmInChargeList;

        if (clientData.ihmminchargeId != 0 && clientData.ihmminchargeId != null) {
          filteredAlternateIhmmInChargeList = filteredAlternateIhmmInChargeList.filter(x => x.value != clientData.ihmminchargeId);
        }

        if (clientData.listClientAlternateIHMMbridges.length > 0) {
          var selectedUsers = clientData.listClientAlternateIHMMbridges;
          selectedUsers
            .filter((t) => t.value != "0")
            .forEach((item) => {
              filteredIhmmInChargeList = filteredIhmmInChargeList.filter(x => x.value != item.value)
            });
        }

        this.setState({
          clientId: clientData.clientId,
          clientUserName: clientData.username,
          clientPassword: clientData.password,
          profilePictureUrl: clientData.clientProfilePic,
          profilePicture: clientData.clientProfilePic,
          clientName: clientData.clientName,
          clientCode: clientData.clientCode,
          maritecBDM: clientData.maritecBDM,
          serviceCategoryList: clientData.serviceCategoryList,
          clientContact: clientData.contactNumber,
          dropdown_clientRole: clientData.clientRoleId,
          primaryEmailAddress: clientData.primaryEmailAddress,
          secondaryEmailDetails:
            clientData.secondaryEmailAddress != ""
              ? clientData.secondaryEmailAddress.split(",")
              : [],
          errorMessages: errors,
          clientAddress: clientData.address,
          clientCity: clientData.city,
          clientPincode: clientData.postalCode,
          dropdown_country: clientData.country,
          statusId: clientData.isActive == true ? 1 : 0,
          isActive: clientData.isActive,
          createdOn: clientData.createdOnValue,
          createdOnDate: clientData.createdOn,
          clientContractualBriefTerms: clientData.briefTerms,
          dropdown_region: clientData.regionId,
          dateOfContract: new Date(clientData.contractDate),
          dateOfContractExpiry: new Date(clientData.contractExpiryDate),
          dropdown_ihmmInCharge: clientData.ihmminchargeId,
          ihmmEmailValue: clientData.ihmminchargeEmail,
          dropdown_alternateIhmmInCharge: clientData.listClientAlternateIHMMbridges,
          alternateEmailValue: clientData.alternateihmminchargeEmail,
          ihmmInChargeList: filteredIhmmInChargeList,
          alternateIhmmInChargeList: filteredAlternateIhmmInChargeList,
          //dropdown_maritecBDMList: clientData.listClientBdmbridges,
          shipName: clientData.shipNames,
          shipList: clientData.clientShipList,
          dropdown_poFrequency: clientData.pofrequencyId,
          clientPICEmail: clientPicEmails,
          selected_clientPicList: selectClientPIC,
          clientPicList: finalClientPic,
          selectedServiceCategoryList: selectedServiceCategoryList,
          selectedServiceCategoryIds: serviceCategory.toString(),
          profilePictureFileMediaId: clientData.profilePictureFileMediaId,
          dateOfRenewContract: clientData.renewContractDate != null && clientData.renewContractDate != "" ? new Date(clientData.renewContractDate) : "",
          savedRenewalContract: clientData.renewContractDate != null && clientData.renewContractDate != "",
          dateOfRenewContractExpiry: clientData.renewExpiryDate != null && clientData.renewExpiryDate != "" ? new Date(clientData.renewExpiryDate) : "",
          showRenwaldatechange: (clientData.renewContractDate != null && clientData.renewContractDate != "") && (clientData.renewExpiryDate != null && clientData.renewExpiryDate != ""),
          isLoading: false,
          isContractExpiry: new Date(clientData.contractExpiryDate) <= new Date() ? true : false,
        },
          () => {
            this.getClientDocumentData(false, this.state.clientId);
          }
        );

      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) { }
  };

  /* <summary>
  date: 14/1/2023
  Name: AN
  description: Get Default permissions of Client
  <summary> */
  getClientAndDefaultAccessPermissions = async (showModal) => {
    const response = await ClientService.GetClientAndDefaultAccessPermissions(
      this.state.clientId
    );

    if (
      response != null &&
      response != undefined &&
      response.data != null &&
      response.status == 200
    ) {

      let custRoles = response.data.customClientRolesPermission;

      this.setState({
        defaultRolesPermissions: response.data.defaultClientRolesPermissions,
        clientRolesPermissions: response.data.customClientRolesPermission,
        dropdown_inheritedClientRoleId:
          response.data.customClientRolesPermission == null
            ? 0
            : response.data.customClientRolesPermission.inheritedClientLevelId,
        customClientRolesPermissions: custRoles != null && custRoles.customClientRolesPermissionId != 0 ? response.data.customClientRolesPermission : [],
        rolesPermissionVisible: showModal
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
  };

  ApplyCustomPermissions = (
    accessPermissions,
    dropdown_inheritedClientRoleId
  ) => {
    const { showErrorMessage } = this.state;

    this.setState(
      {
        customClientRolesPermissions: accessPermissions,
        clientRolesPermissions: accessPermissions,
        dropdown_inheritedClientRoleId: dropdown_inheritedClientRoleId,
      },
      () => {
        if (showErrorMessage) {
          this.validate();
        }

        this.closeUserPermissionsModal();
      }
    );
  };

  /* <summary>
  date: 18/1/2023
  Name: HV
  description: on change password click, handled showing system generated password
  <summary> */

  onChangePasswordClick = async (setUsername) => {
    try {
      const { clientId } = this.state;
      var param = setUsername;
      this.setState({ isLoading: true });
      // if (clientId == 0) {
      //   this.setState({
      //     toastVisible: true,
      //     toastType: helperText.global.toastError,
      //     toastMessage: helperText.UserProfile.saveUserDataToChangePassword,
      //     showChangePasswordConfirmationModal: false,
      //   });
      // }
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      var roleid = 0;
      var username = "";
      if (userData != null) {
        roleid = userData.typeId;
        username = userData.name;
      }
      setUsername = false;
      var response = await ClientService.GenerateNewPassword(
        clientId,
        roleid,
        username
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200 && response.data != null) {
          if (setUsername) {
            this.setState({ clientUserName: "" });
          }
          this.setState({
            clientPassword: response.data,
            toastVisible: param != null ? true : false,
            toastType: helperText.global.toastSuccess,
            toastMessage: helperText.Client.clientUpdated,
          });
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        showChangePasswordConfirmationModal: false,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  // getSystemGeneratedPasswordForNewUser = async () => {
  //   const response = await UserService.GetSystemGeneratedPassword(
  //     this.state.clientId
  //   );
  //   if (response != null && response != undefined && response.status == 200) {
  //     this.setState({ clientPassword: response.data.result, isLoading: false });
  //   } else if (response.status == 401) {
  //     Logout(this.props.navigate);
  //   } else {
  //     this.setState({
  //       isLoading: false,
  //       toastVisible: true,
  //       toastMessage: helperText.global.somethingWentWrong,
  //       toastType: helperText.global.toastError,
  //     });
  //   }
  // };

  /* <summary>
date: 18/1/2023
Name: HV
description: validating all mandatory fields
<summary> */

  validate = async () => {
    try {
      const {
        clientUserName,
        clientName,
        clientCode,
        clientContact,
        dropdown_clientRole,
        primaryEmailAddress,
        dropdown_country,
        dropdown_region,
        dateOfContract,
        dateOfContractExpiry,
        dropdown_ihmmInCharge,
        errorMessages,
        clientPassword,
        selectedServiceCategoryList,
        customClientRolesPermissions,
        dateOfRenewContract,
        dateOfRenewContractExpiry,
        showRenwaldatechange
      } = this.state;

      let errors = errorMessages;
      var userName = "";
      userName = clientName;
      var primaryEmailHasmaritec = await this.isEmailHasMaritec(
        primaryEmailAddress
      );

      errors["userNameValidation"] = isStringEmpty(clientUserName) ? "true" : "";
      errors["passwordValidation"] =
        !isPasswordValid(clientPassword) ||
          isStringEmpty(clientPassword) ||
          clientPassword.length < 8
          ? "true"
          : "";
      errors["clientNameValidation"] = isStringEmpty(clientName) || userName.trim().length < 3 ? "true" : "";
      errors["clientCodeValidation"] = isStringEmpty(clientCode) ? "true" : "";
      errors["clientContact"] = isStringEmpty(clientContact) ? "true" : "";
      errors["serviceCategoryValidation"] =
        selectedServiceCategoryList.length == 0 ||
          selectedServiceCategoryList == null
          ? "true"
          : "";
      errors["clientRoleValidation"] =
        dropdown_clientRole == undefined ||
          dropdown_clientRole == 0 ||
          (dropdown_clientRole == 5 && customClientRolesPermissions.length == 0)
          ? "true"
          : "";
      errors["primaryEmailAddressValidation"] = isStringEmpty(
        primaryEmailAddress
      )
        ? "true"
        : "";
      errors["primaryEmailMaritecValidation"] =
        primaryEmailHasmaritec == true ? "true" : "";
      /*     errors["clientPicValidation"] =
        clientId == 0 ? "" : selected_clientPicList == 0 ? "true" : ""; */
      errors["clientCountryValidation"] =
        dropdown_country == undefined || dropdown_country == 0 ? "true" : "";
      errors["regionValidation"] =
        dropdown_region == undefined || dropdown_region == 0 ? "true" : "";

      var contractDate =
        dateOfContract == "" || dateOfContract == null
          ? ""
          : convertDate(dateOfContract);
      var contractExpiryDate =
        dateOfContractExpiry == "" || dateOfContractExpiry == null
          ? ""
          : convertDate(dateOfContractExpiry);

      errors["dateOfContractValidation"] =
        contractDate == "" || contractDate == undefined ? "true" : "";
      errors["dateOfContractExpiryValidation"] =
        contractExpiryDate == "" || contractExpiryDate == undefined
          ? "true"
          : "";


      errors["dateOfRenewContractValidation"] =
        showRenwaldatechange && (dateOfRenewContractExpiry != "" && dateOfRenewContractExpiry != null) && (dateOfRenewContract == "" || dateOfRenewContract == undefined) ? "true" : "";
      errors["dateOfRenewContractExpiryValidation"] =
        showRenwaldatechange && (dateOfRenewContract != "" && dateOfRenewContract != undefined) && (dateOfRenewContractExpiry == "" || dateOfRenewContractExpiry == undefined)
          ? "true"
          : "";
      errors["clientCodeExistValidation"] = this.state.clientCodeExistError
        ? "true"
        : "";
      // errors["ihmmInChargeValidation"] =
      //   dropdown_ihmmInCharge == undefined || dropdown_ihmmInCharge == 0
      //     ? "true"
      //     : "";

      //Commented For future use
      //errors["clientAddressValidation"] = isStringEmpty(clientAddress) ? "true" : "";
      //errors["clientContractualBriefTermsValidation"] = isStringEmpty(clientContractualBriefTerms) ? "true" : "";
      // errors["maritecBDMListValidation"] = dropdown_maritecBDMList.length == 0 ? "true" : "";
      //errors["clientCityValidation"] = isStringEmpty(clientCity) ? "true" : "";
      // errors["clientPincodeValidation"] = isStringEmpty(clientPincode) ? "true" : "";
      // errors["ihmmpicEmailAddressValidation"] = isStringEmpty(ihmmEmailValue) ? "true" : "";
      //errors["alternateIhmmInChargeValidation"] = dropdown_alternateIhmmInCharge == undefined || dropdown_alternateIhmmInCharge == 0 ? "true" : "";
      // errors["alternateihmmPicEmailAddressValidation"] = alternateEmailValue == undefined || alternateEmailValue == 0 ? "true" : "";

      if (
        errors["userNameValidation"] != "" ||
        errors["passwordValidation"] != "" ||
        // errors["clientNameValidation"] != "" ||
        errors["clientCodeValidation"] != "" ||
        errors["clientContact"] != "" ||
        errors["serviceCategoryValidation"] != "" ||
        errors["clientRoleValidation"] != "" ||
        errors["primaryEmailAddressValidation"] != "" ||
        errors["clientCountryValidation"] != "" ||
        errors["regionValidation"] != "" ||
        errors["dateOfContractValidation"] != "" ||
        errors["dateOfContractExpiryValidation"] != "" ||
        errors["dateOfRenewContractValidation"] != "" ||
        errors["dateOfRenewContractExpiryValidation"] != ""
        //  || errors["ihmmInChargeValidation"] != ""
      ) {
        this.setState({
          errorMessages,
          showErrorMessage: true,
          showErrorMessageEmail: false,
          shortEmployeeNameError: false,
        });
        return true;
      } else if (errors["clientCodeExistValidation"] != "") {
        errors["clientCodeExistValidation"] = "true";
        this.setState({
          errorMessages,
          showErrorMessage: false,
          showErrorMessageEmail: false,
          showErrorMessageMaritecEmail: false,
          shortEmployeeNameError: false,
        });
        return true;
      } else if (!isEmailValid(primaryEmailAddress)) {
        errors["primaryEmailAddressValidation"] = !isEmailValid(
          primaryEmailAddress
        )
          ? "true"
          : "";
        this.setState({
          errorMessages,
          showErrorMessage: false,
          showErrorMessageEmail: true,
          showErrorMessageMaritecEmail: false,
          shortEmployeeNameError: false,
        });
        return true;
      } else if (primaryEmailHasmaritec == true) {
        errors["primaryEmailMaritecValidation"] =
          primaryEmailHasmaritec == true ? "true" : "";
        this.setState({
          errorMessages,
          showErrorMessage: false,
          showErrorMessageEmail: false,
          showErrorMessageMaritecEmail: true,
        });
        return true;
      } else if (
        !isStringEmpty(errorMessages.secondaryEmailValidation) ||
        !isStringEmpty(errorMessages.secondaryMaritecEmailValidation)
        // ||
        // !isStringEmpty(errorMessages.secondaryPrimaryAddressEmailValidation)
      ) {
        return true;
      }

      if (errors["clientNameValidation"] != "") {
        this.setState({
          errorMessages,
          showErrorMessage: false,
          shortEmployeeNameError: true,
          showErrorMessageEmail: false,
        });
        return true;
      }

      this.setState({
        errorMessages: errors,
        isLoading: false,
        showErrorMessage: false,
        usernameExistError: false,
        shortEmployeeNameError: false,
        emailExistsError: false,
        showErrorMessageEmail: false,
        showErrorMessageMaritecEmail: false,
      });
      return false;
    } catch (e) {
    }
  };
  isEmailPrimaryEmailAddress = (emailAddress) => {
    return emailAddress === this.state.primaryEmailAddress;
  };
  isEmailHasMaritec = (emailAddress) => {
    return emailAddress
      .toString()
      .trim()
      .toLowerCase()
      .endsWith("@maritec.com.sg");
  };
  renewalDateClick = () => {
    this.setState({ showRenwaldatechange: true });
  }

  onSendEmail = async () => {
    const { clientId } = this.state;
    if (clientId != 0) {
      this.setState({ isLoading: true });
      var response = await CommonService.SendEmailToUser(
        clientId,
        UserRole.CLIENT
      );
      if (response != null && response != undefined && response.status == 200) {
        var emailSent = response.data;

        if (emailSent == true) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.emailSent,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          });
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            isLoading: false,
          });
        }
      }
    }
  };

  /* <summary>
date: 18/1/2023
Name: HV
description: saving data in db
<summary> */

  onSaveClick = async () => {
    try {

      var value = await this.validate();
      const { errorMessages } = this.state;

      if (!value && isStringEmpty(errorMessages.secondaryPrimaryAddressEmailValidation)) {

        this.setState({ isLoading: true });
        const {
          clientId,
          clientUserName,
          clientPassword,
          customClientRolesPermissions,
          secondaryEmailDetails,
          clientName,
          clientCode,
          clientContact,
          dropdown_clientRole,
          dropdown_inheritedClientRoleId,
          clientPincode,
          clientCity,
          dateOfContract,
          dateOfContractExpiry,
          ihmmEmailValue,
          primaryEmailAddress,
          clientAddress,
          clientContractualBriefTerms,
          dropdown_maritecBDMList,
          dropdown_alternateIhmmInCharge,
          dropdown_country,
          dropdown_region,
          dropdown_ship,
          selected_clientPicList,
          dropdown_ihmmInCharge,
          alternateEmailValue,
          createdOn,
          documentFile,
          statusId,
          userId,
          dropdown_poFrequency,
          errorMessages,
          selectedServiceCategoryIds,
          clientPICEmail,
          isClient,
          maritecBDM,
          profilePictureFileMediaId,
          ihmmInChargeList,
          createdOnDate,
          isProfileImgDeleted,
        } = this.state;

        var commaSeparated = secondaryEmailDetails.toString();
        let errors = errorMessages;

        var data = {
          emailAddress: primaryEmailAddress,
          tableName: "Clients",
          userId: clientId,
        };
        var response = await CommonService.CheckIfEmailExists(data);

        if (
          response != null &&
          response != undefined &&
          response.status == 200
        ) {
          var emailExists = response.data;
          if (emailExists == true) {
            errors["primaryEmailAddressValidation"] = "true";
            this.setState({
              toastVisible: true,
              toastMessage: helperText.UserProfile.emailAlreadyExists,
              toastType: helperText.global.toastError,
              emailExistsError: emailExists,
              errorMessages: errors,
              isLoading: false,
            });
            return;
          }
        }

        var data = {
          userName: clientUserName,
          tableName: "Clients",
          userId: clientId,
        };
        var responeData = await CommonService.CheckIfUserNameExist(data);

        if (
          responeData != null &&
          responeData != undefined &&
          responeData.status == 200
        ) {
          var usernameExist = responeData.data;
          if (usernameExist == true) {
            errors["userNameValidation"] = "true";
            this.setState({
              toastVisible: true,
              toastMessage: helperText.global.usernameAlreadyExists,
              usernameExistError: usernameExist,
              toastType: helperText.global.toastError,
              errorMessages: errors,
              isLoading: false,
            });
            return;
          }
        }

        let assignedIhmmInchargeList = [];

        if (dropdown_ihmmInCharge != 0) {
          var ihmmInCharge = ihmmInChargeList.filter(x => x.value == parseInt(dropdown_ihmmInCharge, 10));
          if (ihmmInCharge.length > 0)
            assignedIhmmInchargeList.push(ihmmInCharge[0]);
        }

        if (dropdown_alternateIhmmInCharge.length > 0) {
          dropdown_alternateIhmmInCharge.forEach(x => {
            assignedIhmmInchargeList.push(x)
          })
        }

        let clientData = new FormData();

        clientData.append("userId", userId);
        clientData.append("ClientId", clientId);
        clientData.append("Username", clientUserName);
        clientData.append("clientPassword", clientPassword);
        clientData.append("ClientName", clientName);
        clientData.append("ClientCode", clientCode);
        clientData.append("serviceCategory", selectedServiceCategoryIds);
        clientData.append("clientRole", dropdown_clientRole);
        clientData.append("ClientContact", clientContact);
        clientData.append("ship", dropdown_ship);
        clientData.append(
          "clientPICList",
          JSON.stringify(selected_clientPicList)
        );
        clientData.append("clientPICEmail", clientPICEmail);
        clientData.append("dateOfRenewContract", this.state.dateOfRenewContract != "" && this.state.dateOfRenewContract != null ? convertDate(this.state.dateOfRenewContract) : "");
        clientData.append("dateOfRenewContractExpiry", this.state.dateOfRenewContractExpiry != "" && this.state.dateOfRenewContractExpiry != null ? convertDate(this.state.dateOfRenewContractExpiry) : "");
        clientData.append("primaryEmailAddress", primaryEmailAddress);
        clientData.append("secondaryEmailAddress", commaSeparated);
        clientData.append("clientAddress", clientAddress);
        clientData.append("clientCity", clientCity);
        clientData.append("clientPincode", clientPincode);
        clientData.append("clientCountry", dropdown_country);

        clientData.append(
          "clientContractualBriefTerms",
          clientContractualBriefTerms
        );
        clientData.append("region", dropdown_region);
        clientData.append("dateOfContract", convertDate(dateOfContract));
        clientData.append(
          "dateOfContractExpiry",
          convertDate(dateOfContractExpiry)
        );
        clientData.append("createdOn", createdOnDate);
        clientData.append("ihmmInCharge", dropdown_ihmmInCharge);

        clientData.append("ihmmpicEmailAddress", ihmmEmailValue);
        clientData.append(
          "alternateIhmmincharge",
          JSON.stringify(dropdown_alternateIhmmInCharge)
        );
        clientData.append("alternateihmmPicEmailAddress", alternateEmailValue);
        clientData.append("assignedIhmmInchargeList", assignedIhmmInchargeList.length == 0 ? [] : JSON.stringify(assignedIhmmInchargeList));

        clientData.append(
          "CustomClientPermission",
          customClientRolesPermissions.length == 0
            ? null
            : JSON.stringify(customClientRolesPermissions)
        );
        clientData.append(
          "InheritedClientRoleId",
          dropdown_inheritedClientRoleId == undefined
            ? ""
            : dropdown_inheritedClientRoleId
        );
        clientData.append("IsActive", statusId == 1 ? true : false);
        clientData.append("poFrequency", dropdown_poFrequency);

        clientData.append("MaritecBDM", maritecBDM);
        let file = documentFile;
        if (file != undefined && file != "" && !isProfileImgDeleted) {
          clientData.append("ProfilePicAttached", file);
        }

        clientData.append(
          "ProfilePictureFileMediaId", isProfileImgDeleted ? 0 :
          profilePictureFileMediaId
        );
        var userData = GetLocalStorageDataWithParse("loggedInUser");

        clientData.append("loggedInUserID", userData.id);
        if (userData != null) {
          clientData.append("roleid", userData.typeId);
        } else {
          clientData.append("roleid", 0);
        }

        if (isProfileImgDeleted) {
          this.deleteClientProfileImage(this.state.clientId, UserRole.CLIENT, this.state.userId);
          this.setState({
            isProfileImgDeleted: false,
          });
        }

        var response = await ClientService.SaveClientProfileData(clientData);

        if (response.data == null) {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
            isLoading: false,
          });
        } else if (response.status == 200) {
          this.setState(
            {
              saveButtonClick: true,
              clientId: response.data,
              savedRenewalContract: (this.state.dateOfRenewContract != "" && this.state.dateOfRenewContract != null),
              isLoading: false,
            },
            async () => {
              AddLocalStorageData("isDirty", false);
              await this.getDropdownData();
              setTimeout(() => {
                this.setState({
                  toastVisible: true,
                  toastType: helperText.global.toastSuccess,
                  toastMessage:
                    clientId === 0
                      ? helperText.Client.clientAdded
                      : helperText.Client.clientUpdated,
                  isDirty: false,
                });
              }, 2000);
            }
          );

        } else {

          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            isLoading: false,
          });
        }
      }
    } catch (e) {
    }
  };

  /* <summary>
 date: 17/01/2023
 Name: HV
 description: Validation for client code
 <summary> */

  onClientCodeChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, isDirty: true }, () => {
      AddLocalStorageData("isDirty", true);
      if (this.state.showErrorMessage) this.validate();
    });

    this.CheckIfClientCodeExist(value);
  };

  /* <summary>
   date: 17/01/2023
   Name: HV
   description: Method to check if client code exist
   <summary> */

  CheckIfClientCodeExist = async (clientCode) => {
    let errors = this.state.errorMessages;

    const response = await ClientService.CheckIfClientCodeExist(clientCode);

    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["clientCodeExistValidation"] = "true";
        this.setState({ clientCodeExistError: true });
      } else {
        errors["clientCodeExistValidation"] = "";
        this.setState({ clientCodeExistError: false });
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };

  /* <summary>
 date: 27/01/2023
 Name: HV
 description: phone number validation for accepting only number, minu, plus and brackets
 <summary> */

  onPhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    let errors = this.state.errorMessages;
    if (value.trim() == "") {
      errors["contactValidation"] = "true";
      this.setState({
        [name]: value,
      });
    }

    if (NUMBER_REGEX.test(value)) {
      this.setState({
        [name]: value,
      });
      errors["contactValidation"] = "";
    } else {
      errors["contactValidation"] = "true";
    }
    this.setState(
      {
        errorMessages: errors,
        isDirty: true
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (showErrorMessage) this.validate();
      }
    );
  };

  /* <summary>
 date: 14/02/2023
 Name: HV
 description: on username change
 <summary> */

  onUsernameChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage, usernameExistError } = this.state;
    this.setState({ [name]: value, isDirty: true }, () => {
      AddLocalStorageData("isDirty", true);
      if (showErrorMessage || usernameExistError) {
        this.validate();
      }
    });
  };

  /* <summary>
     date: 25/12/2022
     Name: AN
     description: Sets the input field values
     <summary> */

  onTextChange = (e) => {
    const { name, value } = e.target;
    const {
      showErrorMessage,
      showErrorMessageEmail,
      showErrorMessageMaritecEmail,
      emailExistsError,
      shortEmployeeNameError
    } = this.state;
    let errors = this.state.errorMessages;
    // if (name == "hkcCount" || name == "euCount") {
    //   // Ensure the value is between 0 and 50
    //   const newValue = Math.min(Math.max(Number(value), 0), 50);

    //   if (value.length == 0)
    //     this.setState({ [name]: "", isDirty: true });
    //   else if (value.length > 2)
    //     this.setState({ [name]: value.substring(0, 2), isDirty: true });
    //   else if (Number.parseInt(value, 10) > 50) {
    //     this.setState({ [name]: "50", isDirty: true });
    //   }
    //   else if (Number.parseInt(value, 10) < 0) {
    //     this.setState({ [name]: "0", isDirty: true });
    //   } else {
    //     this.setState({ [name]: newValue, isDirty: true });
    //   }
    //   return;
    // }

    if (value.trim() == "" && name == "clientPincode") {
      this.setState({ [name]: value, isDirty: true }, () => {
        if (
          showErrorMessage ||
          showErrorMessageEmail ||
          showErrorMessageMaritecEmail ||
          emailExistsError
        )
          this.validate();
      });
    }
    if (
      name != "clientPincode" ||
      (name == "clientPincode" && NUMBER_REGEX_POSTCODE.test(value))
    ) {
      this.setState({ [name]: value, isDirty: true }, () => {

        if (name == "primaryEmailAddress") {
          this.validatePrimaryEmailAddress(value);
        }

        AddLocalStorageData("isDirty", true);
        if (
          showErrorMessage ||
          showErrorMessageEmail ||
          showErrorMessageMaritecEmail ||
          emailExistsError ||
          shortEmployeeNameError
        )
          this.validate();
      });
    }
  };

  validatePrimaryEmailAddress = (emailId) => {

    const { secondaryEmailDetails, errorMessages } = this.state;
    if (secondaryEmailDetails.length > 0) {
      let errors = errorMessages;

      let emailExists = secondaryEmailDetails.filter(x => x == emailId);
      errors["secondaryPrimaryAddressEmailValidation"] = emailExists.length > 0 ?
        helperText.Communication.secondaryPrimaryEmailValidation : "";

      this.setState({ errorMessages: errors });

      return emailExists.length > 0;
    }

  }

  /* <summary>
     date: 27/12/2022
     Name: AN
     description: Show hide password on eye icon click
     <summary> */
  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  /* <summary>
 date: 27/12/2022
 Name: AN
 description: filtering Dropdown Data
 <summary> */
  onDropDownSelected = (e) => {
    var { name, value } = e.target;
    const { showErrorMessage } = this.state;
    this.setState(
      {
        [name]: value,
        isDirty: true
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (showErrorMessage) this.validate();
      }
    );
  };

  /* <summary>
  date: 6/1/2023
  Name: AN
  description: filtering IHMM Dropdown Data
  <summary> */
  onFilterIhmmSelected = (e) => {
    var { name, value } = e.target;
    const { showErrorMessage, defaultAlternateIhmmInChargeList } = this.state;
    let filteredAlternateIhmmList = defaultAlternateIhmmInChargeList;
    if (value != 0) {
      var ihmmEmailValue = this.state.ihmmInChargeList.filter((p) => p.value == value)[0].email;
      filteredAlternateIhmmList = filteredAlternateIhmmList.filter(x => x.value != value);
    }

    this.setState({
      [name]: value,
      ihmmEmailValue: ihmmEmailValue,
      alternateIhmmInChargeList: filteredAlternateIhmmList,
      isDirty: true
    },
      () => {
        AddLocalStorageData("isDirty", true);
        if (showErrorMessage) this.validate();
      }
    );
  };

  /* <summary>
  date: 6/1/2023
  Name: AN
  description: filtering Alternate IHMM Dropdown Data
  <summary> */
  onFilterAlternateIhmmSelected = (option) => {

    const { defaultIhmmInChargeList, alternateIhmmInChargeList } = this.state;

    var dropdown_alternateIhmm = [];
    let filteredIhmmInChargeList = defaultIhmmInChargeList;
    var alternateIhmmEmail = "";

    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_alternateIhmm.push(item);
          let emailValue = alternateIhmmInChargeList.filter((p) => p.value == item.value)[0]?.email;

          alternateIhmmEmail = alternateIhmmEmail == "" ? emailValue : alternateIhmmEmail + ", " + emailValue;
          filteredIhmmInChargeList = filteredIhmmInChargeList.filter(x => x.value != item.value)
        });
    }
    this.setState({
      dropdown_alternateIhmmInCharge: dropdown_alternateIhmm,
      alternateEmailValue: alternateIhmmEmail,
      ihmmInChargeList: filteredIhmmInChargeList,
      isDirty: true
    },
      () => {
        AddLocalStorageData("isDirty", true);
        if (this.state.showErrorMessage) this.validate();
      }
    );
  };

  /* <summary>
  date: 14/1/2023
  Name: AN
  description: Closing Client permission Modal
  <summary> */
  closeUserPermissionsModal = () => {
    this.setState({
      rolesPermissionVisible: false,
      viewRolesPermissionVisible: false,
    });
  };

  goback = (path) => {
    if (this.state.isDirty) {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (loc == "/ActionRequired") {
        this.setState({ path: "/ActionRequired" });
      } else if (loc == "/ActivityLog") {
        this.setState({ path: "/ActivityLog" });
      } else {
        this.setState({ path: path });
      }
      this.setState({ showDirtyPopup: true });
    }
    else {
      var loc = GetLocalStorageDataWithoutParse("location");

      if (loc == "/ActionRequired") {
        this.props.navigate("/ActionRequired");
      } else if (loc == "/ManageChangeRequest") {
        this.props.navigate("/ManageChangeRequest");
      } else if (loc == "/ActivityLog") {
        this.props.navigate("/ActivityLog");
      } else {
        this.props.navigate(path);
      }
    }
    AddLocalStorageData("location", null);
  };

  goToRolesPermission = () => {
    const { dropdown_clientRole } = this.state;

    if (dropdown_clientRole == 5)
      this.setState({ rolesPermissionVisible: true, isDataEditable: false })
    else
      this.setState({ viewRolesPermissionVisible: true });

  };

  /* <summary>
  date: 6/1/2023
  Name: AN
  description: On Changing Profile Picture
  <summary> */
  onProfilePicChange = async (e) => {

    if (!isValidFile(e.target.value, validFileFormats)) {
      if (this.state.filePath == "") {
        this.setState({
          fileInputKey: generateRandomString(),
          documentFile: null,
          errorFile: true,
          isDirty: true,
        });
        AddLocalStorageData("isDirty", true);
      }

      //HV - 06/06/2023 - if after attaching correct file, wrong file is attached then retain the older file but show error message
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileTypeError,
      })
      return;
    }

    let files = e.target.files[0];

    this.setState({
      errorFile: false,
      documentFile: files,
      profilePicture: URL.createObjectURL(files),
      isDirty: true,
      isProfileImgDeleted: false
      //fileName: files.name,
      //filePath: e.target.value,
    });
    AddLocalStorageData("isDirty", true);
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
    date: 6/1/2023
    Name: AN
    description:  Sets date component values
    <summary> */
  //HV - 20/02/2023 - on changing date to remove error icon

  onDateChanged = (id, e) => {
    const { errorMessages } = this.state;
    let errors = errorMessages;
    if (id == "dateOfContract") {
      var contractDate = e;
      errors["dateOfContractValidation"] =
        contractDate == "" || contractDate == undefined ? "true" : "";
      this.setState({ dateOfContract: e, errorMessages: errors, isDirty: true }, () => {
        if (this.state.showErrorMessage) this.validate();
      });
    } else if (id == "dateOfContractExpiry") {
      var contractExpiryDate = e;
      errors["dateOfContractExpiryValidation"] =
        contractExpiryDate == "" || contractExpiryDate == undefined
          ? "true"
          : "";
      this.setState({ dateOfContractExpiry: e, errorMessages: errors, isDirty: true }, () => {
        if (this.state.showErrorMessage) this.validate();
      });
    }
    else if (id == "dateOfRenewContract") {
      var renewcontractDate = e;
      errors["dateOfRenewContractValidation"] =
        renewcontractDate == "" || renewcontractDate == undefined
          ? "true"
          : "";
      this.setState({ dateOfRenewContract: e, errorMessages: errors, isDirty: true }, () => {
        if (this.state.showErrorMessage) this.validate();
      });
    }
    else if (id == "dateOfRenewContractExpiry") {
      var renewcontractExpiryDate = e;
      errors["dateOfRenewContractExpiryValidation"] =
        renewcontractExpiryDate == "" || renewcontractExpiryDate == undefined
          ? "true"
          : "";
      this.setState({ dateOfRenewContractExpiry: e, errorMessages: errors, isDirty: true }, () => {
        if (this.state.showErrorMessage) this.validate();
      });
    }
    this.setState({
      errorMessages: errors,
      isDirty: true
    });
    AddLocalStorageData("isDirty", true);
  };

  closeUploadModal = () => {
    this.setState({ showUploadModal: false });
  };

  /* <summary>
date: 19/01/2023
Name: HV
description: upload modal of document section
<summary> */

  onUploadClick = async (docData) => {
    const { userId, clientId } = this.state;

    this.setState({ isLoading: true });
    let clientDocumentData = new FormData();
    clientDocumentData.append("DocumentTypeId", docData.dropdown_documentType);
    clientDocumentData.append("Remark", docData.remarks);
    var dateIssue =
      docData.issueDate == "" ? "" : convertDate(docData.issueDate);
    clientDocumentData.append("IssueDate", dateIssue);
    var dateExpiry =
      docData.expiryDate == "" ? "" : convertDate(docData.expiryDate);

    clientDocumentData.append(
      "ExpiryDate",
      dateExpiry == "" ? "" : convertDate(dateExpiry)
    );
    clientDocumentData.append("loggedInUserID", userId);
    clientDocumentData.append("clientId", clientId);
    clientDocumentData.append("shipId", docData.dropdown_ship);
    clientDocumentData.append("userTypeId", this.state.userTypeId);
    let file = docData.TFile[0];
    if (file != "" && file.size !== null && file.size !== undefined) {
      clientDocumentData.append("ClientDocumentAttached", file);
      var fileName = file.name.substring(0, file.name.lastIndexOf("."));
      clientDocumentData.append("fileName", fileName);
    }

    var responseSaved = await ClientService.AddClientDocData(clientDocumentData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.getClientDocumentData(true, clientId);
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  /* <summary>
date: 19/01/2023
Name: HV
description: getting client documents from db
<summary> */

  getClientDocumentData = async (closeModal, clientId) => {
      const response = await ClientService.GetClientProfileDocumentData(clientId, 0);
    if (response != null && response != undefined && response.status == 200) {

      this.setState(
        {
          mappedDocuments: response.data.clientDocumentModel,
          allClientFiles: response.data.allClientDocumentList,
          toastVisible: closeModal ? true : false,
          toastMessage: closeModal
            ? helperText.global.documnetUploadedSuccess
            : "",
          toastType: closeModal ? helperText.global.toastSuccess : "",
          isLoading: false,
          showMoveToDocumentPopup: false,
        },
        () => {
          if (closeModal) this.closeUploadModal();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onViewHistoryModalClose = () => {
    this.setState({ showHistoryModal: false });
  };

  /* <summary>
date: 20/01/2023
Name: HV
description: viewing history of documents
<summary> */

  viewDocumentHistory = (id) => {
    const { allClientFiles } = this.state;
    var listDocument = allClientFiles.filter((x) => x.documentTypeId == id).sort(function (a, b) {
     return new Date(b.OriginalCreatedOn) - new Date(a.OriginalCreatedOn);
    });
    listDocument.shift();
    listDocument.forEach((x, index) => {
      x.serialNumber = index + 1;
    });
    this.setState({ filesBasedDocumentType: listDocument }, () => {
      this.setState({ showHistoryModal: true });
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
date: 19/01/2023
Name: HV
description: check box selection method
<summary> */
  /* <summary>
  date: 28/2/2023
  Name: DP
  description: passed file media id in place of clintbridgeid
  <summary> */

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    let listSelectedBridgeId = [];

    if (selectedRows.length > 0) {
      selectedRows.forEach((element) => {
        listSelectedBridgeId.push(element.fileMediaId);
      });
    }
    this.setState({ selectedClientDocListIds: listSelectedBridgeId });
  };

  /* <summary>
date: 19/01/2023
Name: HV
description: viewing document
<summary> */
  /* <summary>
date: 28/2/2023
Name: DP
description: passed file media id in place of clintbridgeid and even list changed
<summary> */
  viewClientDocData = (id) => {
    var filterList = [];

    const { allClientFiles } = this.state;
    filterList = allClientFiles.filter(
      (x) => x.fileMediaId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
      showChangePasswordConfirmationModal: false,
      showMoveToDocumentPopup: false,
    });
  };
  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState({
      showDirtyPopup: false,
      isDirty: false
    }, () => {
      AddLocalStorageData("isDirty", false);
      if (this.state.path != "")
        this.props.navigate(this.state.path);
    });
  };

  /* <summary>
date: 19/01/2023
Name: HV
description: deleting client documents
<summary> */

  deleteClientDocData = async (id, clientId) => {
    try {
      const { selectdDocDeleteId, selectdClientDeletId } = this.state;
      this.setState({
        isLoading: true,
      });
      var response = await ClientService.DeleteClientDocData(
        selectdDocDeleteId,
        selectdClientDeletId
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      } else if (response != null) {
        this.setState(
          {
            mappedDocuments: response.data.clientDocumentModel,
            allClientFiles: response.data.allClientDocumentList,
            toastVisible: true,
            toastMessage: helperText.global.deletedDocument,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };

  /* <summary>
date: 20/01/2023
Name: HV
description: downloading client documents
<summary> */
  /* <summary>
  date: 28/2/2023
  Name: DP
  description: passed file media id in place of clintbridgeid
  <summary> */

  downloadClientDocData = async (fileMediaId, clientId) => {
    try {
      setTimeout(async () => {
        if (this.state.selectedClientDocListIds.length > 0) {
          this.setState({
            isLoading: true,
          });
          let tFileIDlist = [];
          if (fileMediaId == 0) {
            this.state.selectedClientDocListIds.forEach((element) => {
              var tId = element;
              tFileIDlist.push(tId);
            });
          } else {
            tFileIDlist.push(fileMediaId);
          }

          let clientDoc = {
            clientDocId: tFileIDlist,
            clientId: clientId
          }
          const response = await ClientService.DownloadClientDocument(clientDoc);

          if (response.status == 200) {
            response.data.forEach((element) => {
              var fname = "";
              var lastItem = element.split("/").pop();
              fname = lastItem;
              const link = document.createElement("a");
              link.setAttribute("href", element);
              link.setAttribute("download", element);

              var ext = element.substring(element.lastIndexOf(".") + 1);
              if (ext == "pdf" || ext == "PDF") {
                link.setAttribute("target", "_blank");
              } else {
                link.setAttribute("target", "");
              }
              document.body.appendChild(link);
              link.download = fname;
              link.click();
            });
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastSuccess,
              toastMessage: helperText.global.downloadSuccess,
              isLoading: false,
            });
          } else {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.global.somethingWentWrong,
              isLoading: false,
            });
          }
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.noDocumentsSelected,
            isLoading: false,
          });
        }
      }, 500);
    } catch (error) {
    }
  };

  onViewFileDownload = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess
      })
    } catch (e) {
    }
  };

  onRequestNewPasswordClick = () => {
    this.setState({
      showClientRequestModal: true,
      requestType: "Change Password",
    });
  };

  onCloseChangeRequestModalClick = () => {
    this.setState({ showClientRequestModal: false, requestType: "" });
  };

  onShowProceedModalClick = () => {
    this.setState({ showProceedModal: true });
  };

  /* <summary>
  date: 01/03/2023
  Name: HV
  description: proceed modal close.
  <summary> */

  onProceeModalCloseClick = () => {
    this.setState({
      showProceedModal: false,
    });
  };

  /* <summary>
date: 01/03/2023
Name: HV
description: on change request click
<summary> */

  SaveClientChangeRequestData = async () => {
    const { userId, clientId, requestType } = this.state;

    this.setState({ isLoading: true, showProceedModal: false });
    let clientChangeRequestData = new FormData();
    clientChangeRequestData.append("RequestType", requestType);
    clientChangeRequestData.append("EditedBy", userId);
    clientChangeRequestData.append("ClientId", clientId);
    clientChangeRequestData.append("ModuleName", "Client");
    clientChangeRequestData.append("NavigateTo", "Client Details");

    var responseSaved = await ClientService.SaveClientChangeRequestData(
      clientChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.Client.changeRequestSent,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        },
        () => {
          this.onCloseChangeRequestModalClick();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  /* <summary>
 date: 03/03/2023
 Name: HV
 description: on change request clickcheckbox clicked, populate client pic list
 <summary> */

  onCheckBoxSeelcted = (data) => {
    let { defaultClientPicList, selected_clientPicList, clientPICEmail } =
      this.state;
    let selectedList = data.filter((x) => x.isChecked == true);
    var commaSeparated = selectedList.map((x) => {
      return x.value;
    });
    let selectedServiceCategoryIds = commaSeparated.toString();
    let errors = this.state.errorMessages;

    let finalClientPic = [];
    let clientPicEmails = "";

    errors["serviceCategoryValidation"] = "";

    if (selectedList.length > 0) {
      selectedList.map((x) => {
        return defaultClientPicList.map((y) => {
          if (x.value == y.parentValue) {
            return finalClientPic.push(y);
          }
        });
      });
    }

    var selectClientPIC = [];
    if (selected_clientPicList.length > 0) {
      finalClientPic.forEach((x) => {
        selected_clientPicList.forEach((y) => {
          if (x.value == y.value) {
            selectClientPIC.push(y);
          }
        });
      });

      selectClientPIC = Array.from(
        new Map(selectClientPIC.map((o) => [o.value, o])).values()
      );

      if (clientPICEmail.length > 0) {
        var commaSeparated = selectClientPIC.map((x) => {
          return x.email;
        });
        clientPicEmails = commaSeparated.join(", ");
      }
    }

    finalClientPic = Array.from(
      new Map(finalClientPic.map((o) => [o.value, o])).values()
    );
    this.setState(
      {
        serviceCategoryList: data,
        selectedServiceCategoryIds: selectedServiceCategoryIds,
        errors: errors,
        clientPicList: finalClientPic,
        selected_clientPicList: selectClientPIC,
        clientPICEmail: clientPicEmails,
        selectedServiceCategoryList: selectedList,
        isDirty: true,
      },
      () => {
        if (this.state.showErrorMessage) this.validate();
      }
    );
  };

  /* <summary>
date: 4/03/2023
Name: HV
description: on Click gets the Client PIC multiselected dropdown data.
<summary> */

  onClientPICDropdownChange = (option) => {
    const { errorMessages } = this.state;

    var selectClientPIC = [];
    let clientPicEmails = "";
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          selectClientPIC.push(item);
        });

      var commaSeparated = selectClientPIC.map((x) => {
        return x.email;
      });
      clientPicEmails = commaSeparated.join(", ");
    }

    let errors = errorMessages;
    errors["clientPicValidation"] = "";

    this.setState({
      selected_clientPicList: selectClientPIC,
      clientPICEmail: clientPicEmails,
      errorMessages: errors,
      isDirty: true
    });
  };

  /* <summary>
date: 04/03/2023
Name: HV
description: navigating to manage client user sccreen
<summary> */
  onAddClientUserClicked = (clientId, clientName, isActive) => {
    const { addNewUserPermission } = this.state;
    if (
      (!isActive && clientId != 0) ||
      this.state.userRolePermissionRead ||
      addNewUserPermission
    ) {
      let clientData = {
        clientId: clientId,
        clientName: clientName,
      };
      AddLocalStorageData("selectedClient", JSON.stringify(clientData));
      AddLocalStorageData("location", "/AddEditClient");
      AddLocalStorageData("selectedClientUser", "0");
      AddLocalStorageData("selectedClientID", clientId);

      this.props.navigate("/AddEditClientUser");
      //this.props.navigate("/ManageClientUsers");
    }
  };

  /* <summary>
 date: 04/03/2023
 Name: HV
 description: email change
 <summary> */

  onEmailChange = (emailId) => {
    this.setState({ secondaryEmailDetails: emailId, isDirty: true }, () => {
      if (
        !isStringEmpty(this.state.errorMessages.secondaryEmailValidation) ||
        !isStringEmpty(this.state.errorMessages.secondaryMaritecEmailValidation) ||
        !isStringEmpty(this.state.errorMessages.secondaryPrimaryAddressEmailValidation)
      )
        this.validateEmail(emailId);
      else
        this.validatePrimaryEmailAddress(this.state.primaryEmailAddress);
    });
  };
  onkeychange = (e) => {
    if (e.keyCode === 8 && this.state.emailId === undefined) {
      if (
        !isStringEmpty(this.state.errorMessages.secondaryEmailValidation) ||
        !isStringEmpty(this.state.errorMessages.secondaryMaritecEmailValidation) ||
        !isStringEmpty(this.state.errorMessages.secondaryPrimaryAddressEmailValidation)
      )
        this.validateEmail("");
    }
  };

  onSaveNewTypeClick = async (typeName) => {

    this.setState({
      isLoading: true,
    });

    let docData = {
      TypeName: typeName,
      Module: Module.Client
    }

    var responseSaved = await CommonService.AddNewDocumentType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data);
      const response = await ClientService.GetClientDropdownData(
        this.state.clientId,
        0
      );

      if (response != null && response != undefined && response.status == 200) {

        this.setState(
          {
            documentTypeList: response.data.documentTypeList,
            isLoading: false,
          })
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };

  /* <summary>
 date: 04/03/2023
 Name: HV
 description: validating email
 <summary> */

  validateEmail = async (email) => {
    try {

      const { errorMessages, primaryEmailAddress } = this.state;
      let errors = errorMessages;
      let validEmail = false;
      var secondaryEmailHasMaritec =
        email.length != 0 ? this.isEmailHasMaritec(email) : false;
      var secondaryPrimaryAddress =
        email.length != 0 ? this.validatePrimaryEmailAddress(primaryEmailAddress) : false;
      if (secondaryEmailHasMaritec) {
        errors["secondaryMaritecEmailValidation"] =
          helperText.Communication.maritecEmailValidation;
        validEmail = false;
        errors["secondaryEmailValidation"] = "";
        errors["secondaryPrimaryAddressEmailValidation"] = "";
      } else if (secondaryPrimaryAddress) {
        errors["secondaryPrimaryAddressEmailValidation"] =
          helperText.Communication.secondaryPrimaryEmailValidation;
        validEmail = false;
        errors["secondaryEmailValidation"] = "";
        errors["secondaryMaritecEmailValidation"] = "";
      }
      else {
        if (email.length != 0) {
          if (isEmail(email.trim())) {
            errors["secondaryEmailValidation"] = "";
            errors["secondaryMaritecEmailValidation"] = "";
            errors["secondaryPrimaryAddressEmailValidation"] = "";
            validEmail = true;
          } else {
            errors["secondaryEmailValidation"] =
              helperText.Communication.invalidEmailValidation;
            errors["secondaryMaritecEmailValidation"] = "";
            errors["secondaryPrimaryAddressEmailValidation"] = "";
            validEmail = false;
          }
        } else {
          errors["secondaryEmailValidation"] = "";
          errors["secondaryMaritecEmailValidation"] = "";
          errors["secondaryPrimaryAddressEmailValidation"] = "";
          validEmail = true;
        }
      }

      this.setState({ errorMessages: errors });
      return validEmail;
    } catch (e) {
    }
  };

  showChangePasswordConfirmationModal = () => {
    this.setState({
      showChangePasswordConfirmationModal: true,
    });
  };

  onMoveClicked = async (docData) => {
    const { userId, clientId, selectedFileMediaId } = this.state;

    this.setState({ isLoading: true });
    let clientDocumentData = new FormData();
    clientDocumentData.append("documentTypeId", docData.dropdown_documentType);
    clientDocumentData.append("documentFileMediaId", selectedFileMediaId);
    clientDocumentData.append("loggedInUserID", userId);
    clientDocumentData.append("clientId", clientId);
    clientDocumentData.append("shipId", docData.dropdown_ship);

    var responseSaved = await ClientService.MoveBulkDocumentData(
      clientDocumentData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.getClientDocumentData(true, clientId);
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };


  /* <summary>
   date: 20/06/2023
   Name: SYJ
   description: Delete profile image
   <summary> */
  onDeleteProfilePic = () => {
    const { isActive, clientId, userRolePermissionRead } = this.state;

    if (!((!isActive && clientId != 0) || userRolePermissionRead)) {
      this.setState({
        showDeleteProfileModal: true,
      });
    }
  };

  onDeleteProfileModalYesClick = () => {
    this.setState({
      isDirty: true,
      profilePicture: null,
      isProfileImgDeleted: true,
      showDeleteProfileModal: false,
      toastVisible: true,
      toastMessage: helperText.global.deletedProfile,
      toastType: helperText.global.toastSuccess,
    })
  };

  onDeleteProfileModalCloseClick = () => {
    this.setState({
      showDeleteProfileModal: false,
      isProfileImgDeleted: false,
    });
  };

  deleteClientProfileImage = async (userId, userTypeId, loggedUserId) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteProfileModal: false,
      });
      var response = await UserService.DeleteProfileImage(userId, userTypeId, loggedUserId);
      if (response != undefined) {
        await this.getDropdownData();

        if (userTypeId == UserRole.CLIENT && this.state.userId != 1 && this.state.userId != 2 && this.state.userId != 3 && this.state.userId != 7
          && this.state.userId != 6 && this.state.userId != 5) {
          AddLocalStorageData("loggedInUserPic", null);
        }

        this.setState(
          {
            profilePicture: null,
            fileInputKey: generateRandomString(),
            documentFile: null,
            // toastVisible: true,
            // toastMessage: helperText.global.deletedProfile,
            // toastType: helperText.global.toastSuccess,
          });
      } else {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };


  render() {
    const {
      mappedDocuments,
      isLoading,
      toastVisible,
      toastMessage,
      showPassword,
      serviceCategoryList,
      shipName,
      secondaryEmailDetails,
      clientRoleList,
      clientRolesPermissions,
      clientPicList,
      ihmmInChargeList,
      alternateIhmmInChargeList,
      dropdown_alternateIhmmInChargeList,
      //maritecBDMList,
      //dropdown_maritecBDMList,
      regionList,
      countryList,
      colDefs,
      dropdown_country,
      fileInputKey,
      defaultRolesPermissions,

      clientUserName,
      clientPassword,
      profilePicture,
      rolesPermissionVisible,
      viewRolesPermissionVisible,
      toastType,

      clientName,
      clientCode,
      clientContact,
      selected_clientPicList,

      clientPincode,
      clientCity,
      primaryEmailAddress,
      clientAddress,
      clientContractualBriefTerms,
      createdOn,

      showErrorMessage,
      errorMessages,
      showUploadModal,
      dropdown_inheritedClientRoleId,
      dropdown_clientRole,
      dropdown_region,
      dropdown_ihmmInCharge,
      dropdown_alternateIhmmInCharge,
      showViewModal,
      filePathToView,
      filesBasedDocumentType,
      showHistoryModal,
      clientCodeExistError,
      clientId,
      documentColumns,
      dropdown_poFrequency,
      notificationFrequencyList,
      showConfirmationPopup,
      showChangePasswordConfirmationModal,
      isClient,
      showClientRequestModal,
      showProceedModal,
      clientPICEmail,
      showErrorMessageEmail,
      isActive,
      showErrorMessageMaritecEmail,
      dateOfContract,
      dateOfContractExpiry,

      showMoveToDocumentPopup,

      shipList,
      moveDocumentTypeModalList,
      documentTypeList,
      maritecBDM,
      addNewUserPermission,
      isDirty,
      shortEmployeeNameError,
      showDeleteProfileModal,
      isDataEditable,
      isContractExpiry,
    } = this.state;

    var contractexpirydate = new Date(dateOfContractExpiry)
    contractexpirydate.setDate(contractexpirydate.getDate() + 1)
    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {rolesPermissionVisible && (
          <RolesPermissionModal
            showModal={rolesPermissionVisible}
            clientRoleList={this.state.clientRoleList}
            clientRolesPermissions={clientRolesPermissions}
            defaultRolesPermissions={defaultRolesPermissions || []}
            dropdown_inheritedClientLevelID={dropdown_inheritedClientRoleId}
            ApplyCustomPermissions={this.ApplyCustomPermissions}
            closeModal={this.closeUserPermissionsModal}
            roleId={dropdown_clientRole}
            isDataEditable={isDataEditable}
          />
        )}

        {viewRolesPermissionVisible && (
          <ClientRolesNPermissionModal
            showModal={viewRolesPermissionVisible}
            closeModal={this.closeUserPermissionsModal}
          />
        )}

        {showViewModal && (
          <ViewModal
            showViewModal={showViewModal}
            modalheader="View Client File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showClientRequestModal && (
          <ClientChangeRequestModal
            onCloseClick={this.onCloseChangeRequestModalClick}
            modalTitle={"Request admin to setup new password"}
            clientEmailId={primaryEmailAddress}
            onChangeRequestClick={this.onShowProceedModalClick}
          />
        )}

        {showProceedModal && (
          <ProceedModal
            showProceedModal={showProceedModal}
            onNoClick={this.onProceeModalCloseClick}
            onYesClick={this.SaveClientChangeRequestData}
          />
        )}

        {showDeleteProfileModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteProfileModalCloseClick}
            onYesClick={this.onDeleteProfileModalYesClick}
            onNoClick={this.onDeleteProfileModalCloseClick}
            modalheader="Delete Profile Image"
          ></DeleteModal>
        )}

        <main className="page-height">
          <section className="communications manage-ship">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                    onClick={() =>
                      this.goback(
                        isClient ? "/ClientDashboard" : "/ManageClient"
                      )
                    }
                  />
                  <span className="title-font">
                    {" "}
                    {isClient
                      ? "Profile Details"
                      : clientId == 0
                        ? "Add Client"
                        : "Edit Client"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  {/* block-1 */}
                  <div className="admin-profile-detail-img edit-client-profile-img">
                    <div className="admin-profile-detail-bg-img">
                      {profilePicture == "" || profilePicture == undefined ? (
                        <img
                          src={"images/add-photo.png"}
                          alt="img-profile"
                          className="admin-profile"
                          style={{ objectFit: "contain" }}
                        />
                      ) : (
                        <img
                          src={profilePicture}
                          alt="img-profile"
                          className="admin-profile"
                          style={{ objectFit: "contain" }}
                        />
                      )}

                      {profilePicture != "" && profilePicture != undefined && profilePicture != null ? (
                        <div className="admin-profile-icon">
                          <label style={{ left: "66%", top: "53%" }}>
                            <img
                              title={"Delete"}
                              src={deleteIcon}
                              alt="profile-delete"
                              className="img-fluid cursor-class test-Pankaj"
                              style={{ width: "15px", height: "20px", opacity: (!isActive && clientId != 0) || this.state.userRolePermissionRead ? "0.6" : "1" }}
                              onClick={() => this.onDeleteProfilePic()}
                              disabled={(!isActive && clientId != 0) || this.state.userRolePermissionRead}
                            />
                          </label>
                        </div>) : null}

                      <div className="admin-profile-icon">
                        <label htmlFor="myfile">
                          <img
                            title={
                              profilePicture == "" ||
                                profilePicture == undefined
                                ? "Add"
                                : "Edit"
                            }
                            src={
                              profilePicture == "" ||
                                profilePicture == undefined
                                ? addProfileIcon
                                : editIcon
                            }
                            style={{
                              opacity:
                                (!isActive && clientId != 0) ||
                                  this.state.userRolePermissionRead
                                  ? "0.6"
                                  : "1",
                            }}
                            alt="profile-edit "
                            className="img-fluid cursor-class"
                          />
                        </label>

                        <input
                          type="file"
                          id="myfile"
                          accept={validFileFormatsReadable}
                          name="documentProfileFile"
                          onChange={this.onProfilePicChange}
                          key={fileInputKey || ""}
                          style={{ display: "none", visibility: "none" }}
                          disabled={
                            (!isActive && clientId != 0) ||
                            this.state.userRolePermissionRead
                          }
                        />
                        <div id="display-image"></div>
                      </div>
                    </div>
                    <div style={{ minHeight: 38 }}>
                      <h2>{clientName}</h2>
                    </div>
                  </div>

                  <div className="admin-profile-login-detail admin-supplier-login-detail">
                    <UserCredentials
                      userId={clientId}
                      username={clientUserName}
                      password={clientPassword}
                      name="clientUserName"
                      passwordName="clientPassword"
                      onPasswordButtonClick={
                        isClient
                          ? this.onRequestNewPasswordClick
                          : this.showChangePasswordConfirmationModal
                      }
                      showPassword={showPassword}
                      togglePassword={this.togglePassword}
                      label={
                        isClient ? "Request New Password" : "Change Password"
                      }
                      onTextChange={this.onUsernameChange}
                      errorPassword={errorMessages.passwordValidation}
                      errorUsername={errorMessages.userNameValidation}
                      disabled={
                        isClient ||
                        (!isActive && clientId != 0) ||
                        this.state.userRolePermissionRead
                      }
                      disabledButton={clientId == 0 || this.state.userRolePermissionRead}
                    />
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="admin-editclient-box">
                    <div className="accordion" id="accordionExample">
                      {/* button - 1 */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientdetails"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Client Details
                          </button>
                        </h2>

                        <div
                          id="clientdetails"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div style={{ alignSelf: "center" }} className="col-lg-3 align-self-class"
                                      >
                                        <h5
                                          className={
                                            (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Client Name:
                                        </h5>
                                      </div>

                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientNameValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="clientName"
                                            name="clientName"
                                            value={clientName}
                                            onChange={this.onTextChange}
                                            maxLength={100}
                                            disabled={
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                          />

                                          {!isStringEmpty(
                                            errorMessages.clientNameValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5
                                          className={
                                            this.state.clientId > 0 ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Client Code:
                                        </h5>
                                      </div>
                                      <div className="col-lg-6">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientCodeValidation
                                            ) ||
                                              !isStringEmpty(
                                                errorMessages.clientCodeExistValidation
                                              )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="clientCode"
                                            name="clientCode"
                                            value={clientCode != undefined ? clientCode : ""}
                                            onChange={this.onClientCodeChange}
                                            disabled={
                                              this.state.clientId > 0 ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            maxLength={10}
                                          />

                                          {(!isStringEmpty(
                                            errorMessages.clientCodeValidation
                                          ) ||
                                            !isStringEmpty(
                                              errorMessages.clientCodeExistValidation
                                            )) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5
                                          className={
                                            (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Phone Number:
                                        </h5>
                                      </div>
                                      <div className="col-lg-6">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientContact
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            autoFocus={false}
                                            autoComplete={"off"}
                                            type="text"
                                            placeholder=""
                                            className="form-control"
                                            id="clientContact"
                                            name="clientContact"
                                            value={clientContact}
                                            onChange={this.onPhoneNumberChange}
                                            maxLength={20}
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                            disabled={
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                          />

                                          {!isStringEmpty(
                                            errorMessages.clientContact
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5
                                          className={
                                            (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Client Email:
                                        </h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.primaryEmailAddressValidation
                                            ) ||
                                              !isStringEmpty(
                                                errorMessages.primaryEmailMaritecValidation
                                              )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            autoFocus={false}
                                            autoComplete={"off"}
                                            type="email"
                                            className="form-control"
                                            name="primaryEmailAddress"
                                            id="email"
                                            value={primaryEmailAddress}
                                            onChange={this.onTextChange}
                                            maxLength={100}
                                            disabled={
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                          />

                                          {(!isStringEmpty(
                                            errorMessages.primaryEmailAddressValidation
                                          ) ||
                                            !isStringEmpty(
                                              errorMessages.primaryEmailMaritecValidation
                                            )) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="ship-detail-pb" style={{ paddingTop: "5px" }}>
                                    <div className="row">
                                      <div className="col-lg-3 align-self-class">
                                        <h5
                                          className={
                                            isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Service Category:
                                        </h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.serviceCategoryValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                          style={{ alignSelf: "center" }}
                                        >
                                          <MultiSelectCheckbox
                                            options={serviceCategoryList}
                                            onChange={this.onCheckBoxSeelcted}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                          />
                                          {!isStringEmpty(
                                            errorMessages.serviceCategoryValidation
                                          ) && (
                                              <div className="invalid-position-multi-checkbox">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-md-3 align-self-class">
                                        <h5
                                          className={
                                            isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Client Role:
                                        </h5>
                                      </div>
                                      <div className="col-xl-3 col-md-4">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientRoleValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                          id={"role"}
                                        >
                                          <Dropdown
                                            dropdownLabel=""
                                            name="clientRole"
                                            onChange={this.onDropDownSelected}
                                            selected={dropdown_clientRole}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {clientRoleList == null
                                              ? null
                                              : clientRoleList.map(
                                                (item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item.value}
                                                  >
                                                    {item.label}
                                                  </option>
                                                )
                                              )}
                                            ;
                                          </Dropdown>
                                          {dropdown_clientRole > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"role"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {clientRoleList != null && clientRoleList.filter(x => x.value == dropdown_clientRole)[0]?.label}
                                            </UncontrolledTooltip>) : null}
                                          {!isStringEmpty(
                                            errorMessages.clientRoleValidation
                                          ) && (
                                              <div className="invalid-dropdown-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      {dropdown_clientRole == 5 && !this.state.userRolePermissionRead && !isClient ? (
                                        <div className="col-xl-6 col-md-5"
                                          style={{ alignSelf: "center" }}>
                                          <button
                                            className="btn btn-block mobile-client-roles-btn"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#clientrolespermission"
                                            onClick={() => this.getClientAndDefaultAccessPermissions(true)
                                            }
                                          >
                                            Set Roles & Permissions
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="col-xl-6 col-md-5"
                                          style={{ alignSelf: "center" }}>
                                          <a
                                            onClick={() =>
                                              this.goToRolesPermission()
                                            }
                                          >
                                            View Roles and Permissions
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {clientId == 0 ||
                                  (isActive && clientId != 0) ? null : (
                                  <div className="col-md-12">
                                    <div className="ship-detail-pb">
                                      <div className="row">
                                        <div
                                          style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                          <h5>Ship:</h5>
                                        </div>
                                        <div className="col-lg-9">
                                          <textarea
                                            className="textarea-address"
                                            rows="2"
                                            name="shipName"
                                            id="shipName"
                                            value={shipName}
                                            readOnly
                                            disabled
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {clientId == 0 ? null : (
                                  <div className="col-md-12">
                                    <div className="ship-detail-pb">
                                      <div className="row">
                                        <div
                                          style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                          <h5>Client PIC:</h5>
                                        </div>
                                        <div className="col-lg-6">
                                          <div
                                            className={
                                              !isStringEmpty(
                                                errorMessages.clientPicValidation
                                              )
                                                ? "invalid-position invalid-position-multi-dropdown"
                                                : ""
                                            }
                                          >
                                            <MultiSelectDropdown
                                              selectedOptions={
                                                selected_clientPicList ==
                                                  undefined
                                                  ? []
                                                  : selected_clientPicList
                                              }
                                              options={
                                                clientPicList == undefined
                                                  ? []
                                                  : clientPicList
                                              }
                                              onChange={
                                                this.onClientPICDropdownChange
                                              }
                                              isMulti={true}
                                              disabled={
                                                isClient ||
                                                (!isActive && clientId != 0) ||
                                                this.state
                                                  .userRolePermissionRead
                                              }
                                            />

                                            {!isStringEmpty(
                                              errorMessages.clientPicValidation
                                            ) && (
                                                <div className="invalid-dropdown-icon">
                                                  <img
                                                    src="images\icon material-error.png"
                                                    alt="invalid"
                                                  ></img>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                        <div
                                          className="col-lg-3"
                                          style={{ alignSelf: "center" }}
                                        >
                                          <a
                                            className="view-permision-link"
                                            data-bs-toggle="modal"
                                            style={{
                                              display:
                                                clientId == 0 ? "none" : "",
                                              cursor: "pointer",
                                              alignSelf: "center"
                                            }}
                                            onClick={() =>
                                              this.onAddClientUserClicked(
                                                clientId,
                                                clientName,
                                                isActive
                                              )
                                            }
                                            disabled={
                                              (!isActive && clientId != 0) ||
                                              this.state
                                                .userRolePermissionRead ||
                                              !addNewUserPermission
                                            }
                                          >
                                            <label
                                              style={{
                                                cursor: "pointer",
                                                opacity:
                                                  (!isActive &&
                                                    clientId != 0) ||
                                                    this.state
                                                      .userRolePermissionRead ||
                                                    !addNewUserPermission
                                                    ? "0.6"
                                                    : "1",
                                              }}
                                            >
                                              Add Client User
                                            </label>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {clientId == 0 ? null : (
                                  <div className="col-md-12">
                                    <div className="ship-detail-pb">
                                      <div className="row">
                                        <div
                                          style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                          <h5>Client PIC Email:</h5>
                                        </div>
                                        <div className="col-lg-9">
                                          <textarea
                                            className="textarea-address"
                                            rows="1"
                                            name="clientPICEmail"
                                            id="clientPICEmail"
                                            value={clientPICEmail}
                                            maxLength={100}
                                            disabled
                                            readOnly
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>Secondary Email Address:</h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.secondaryEmailValidation
                                            ) ||
                                              !isStringEmpty(
                                                errorMessages.secondaryMaritecEmailValidation
                                              ) ||
                                              !isStringEmpty(
                                                errorMessages.secondaryPrimaryAddressEmailValidation
                                              )
                                              ? "invalid-position-multi-email"
                                              : ""
                                          }
                                          disabled={!isActive && clientId != 0}
                                        >
                                          <ReactMultiEmail
                                            placeholder=""
                                            emails={secondaryEmailDetails}
                                            onChange={(_emails) =>
                                              this.onEmailChange(_emails)
                                            }
                                            validateEmail={(email) =>
                                              this.validateEmail(email)
                                            }
                                            // enable={
                                            //   !this.state.userRolePermissionRead
                                            // }
                                            onKeyUp={this.onkeychange}
                                            style={{
                                              resize: "none",
                                              fontSize: "14px",
                                            }}
                                            getLabel={(
                                              email,
                                              index,
                                              removeEmail = index()
                                            ) => {
                                              return (
                                                <div data-tag key={index}>
                                                  {email}
                                                  <span
                                                    data-tag-handle
                                                    onClick={() =>
                                                      removeEmail(index)
                                                    }
                                                  >
                                                    ×
                                                  </span>
                                                </div>
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>Address:</h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientAddressValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <textarea
                                            className="textarea-address"
                                            rows="3"
                                            name="clientAddress"
                                            id="clientAddress"
                                            autoComplete="new-password"
                                            value={clientAddress}
                                            onChange={this.onTextChange}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            maxLength={250}
                                          ></textarea>
                                          {!isStringEmpty(
                                            errorMessages.clientAddressValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5>City:</h5>
                                      </div>
                                      <div className="col-lg-6">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientCityValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="clientCity"
                                            name="clientCity"
                                            autoComplete="new-password"
                                            value={clientCity}
                                            onChange={this.onTextChange}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            maxLength={50}
                                          />

                                          {!isStringEmpty(
                                            errorMessages.clientCityValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5>Postal / Zip Code:</h5>
                                      </div>
                                      <div className="col-lg-6">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientPincodeValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            autoFocus={false}
                                            type="text"
                                            placeholder=""
                                            className="form-control"
                                            id="clientPincode"
                                            name="clientPincode"
                                            autoComplete="new-password"
                                            value={clientPincode}
                                            onChange={this.onTextChange}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            maxLength={10}
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          />

                                          {!isStringEmpty(
                                            errorMessages.clientPincodeValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5
                                          className={
                                            isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Country:
                                        </h5>
                                      </div>
                                      <div className="col-lg-6">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientCountryValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                          id={"sc"}
                                        >
                                          <Dropdown
                                            dropdownLabel=""
                                            name="country"
                                            onChange={this.onDropDownSelected}
                                            selected={dropdown_country}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {countryList == null
                                              ? null
                                              : countryList.map((item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              ))}
                                            ;
                                          </Dropdown>
                                          {dropdown_country > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"sc"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {countryList != null && countryList.filter(x => x.value == dropdown_country)[0]?.label}
                                            </UncontrolledTooltip>) : null}

                                          {!isStringEmpty(
                                            errorMessages.clientCountryValidation
                                          ) && (
                                              <div className="invalid-dropdown-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* button - 2 */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#ihmmdetails"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            IHMM Details
                          </button>
                        </h2>

                        <div
                          id="ihmmdetails"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>Contractual Brief Terms:</h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientContractualBriefTermsValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            name="clientContractualBriefTerms"
                                            className="form-control"
                                            id="clientContractualBriefTerms"
                                            autoComplete="new-password"
                                            value={clientContractualBriefTerms}
                                            onChange={this.onTextChange}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            maxLength={150}
                                          />
                                          {!isStringEmpty(
                                            errorMessages.clientContractualBriefTermsValidation
                                          ) && (
                                              <div className="invalid-dropdown-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>Maritec BDM:</h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="maritecBDM"
                                          autoComplete="new-password"
                                          value={maritecBDM}
                                          onChange={this.onTextChange}
                                          disabled={
                                            isClient ||
                                            (!isActive && clientId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                          maxLength={150}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-xl-6 col-lg-4 align-self-class">
                                        <h5
                                          className={
                                            isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          CTI Region:
                                        </h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.regionValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                          id={"CTIRegion"}
                                        >
                                          <Dropdown
                                            dropdownLabel=""
                                            name="region"
                                            onChange={this.onDropDownSelected}
                                            selected={dropdown_region}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {regionList == null
                                              ? null
                                              : regionList.map((item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              ))}
                                            ;
                                          </Dropdown>
                                          {dropdown_region > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"CTIRegion"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                                            </UncontrolledTooltip>) : null}

                                          {!isStringEmpty(
                                            errorMessages.regionValidation
                                          ) && (
                                              <div className="invalid-dropdown-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-xl-6 col-lg-4 align-self-class">
                                        <h5>Date of Creation:</h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="dateOfCreation"
                                          value={createdOn}
                                          disabled
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-xl-6 col-lg-4 align-self-class">
                                        <h5
                                          className={
                                            isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Date of Contract:
                                        </h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.dateOfContractValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <DatePicker
                                            selectedDate={dateOfContract}
                                            name="dateOfContract"
                                            id="dateOfContract"
                                            onDateChange={(e) =>
                                              this.onDateChanged(
                                                "dateOfContract",
                                                e
                                              )
                                            }
                                            className="form-control"
                                            title="dateOfContract"
                                            placeholder=""
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              || (clientId != 0 && isContractExpiry)
                                            }
                                            maxDate={dateOfContractExpiry}
                                          >
                                            {" "}
                                          </DatePicker>

                                          {!isStringEmpty(
                                            errorMessages.dateOfContractValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-xl-6 col-lg-4 align-self-class">
                                        <h5
                                          className={
                                            isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Date of Contract Expiry:
                                        </h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <div>
                                          <div
                                            className={
                                              !isStringEmpty(
                                                errorMessages.dateOfContractExpiryValidation
                                              )
                                                ? "invalid-position addextraemail expiry-box"
                                                : "addextraemail expiry-box"
                                            }
                                          >

                                            <DatePicker
                                              selectedDate={dateOfContractExpiry}
                                              name="dateOfContractExpiry"
                                              id="dateOfContractExpiry"
                                              onDateChange={(e) =>
                                                this.onDateChanged(
                                                  "dateOfContractExpiry",
                                                  e
                                                )
                                              }
                                              className="form-control"
                                              disabled={
                                                isClient ||
                                                (!isActive && clientId != 0) ||
                                                this.state.userRolePermissionRead
                                                || (clientId != 0 && isContractExpiry)
                                              }
                                              minDate={dateOfContract}
                                            >
                                              {" "}
                                            </DatePicker>

                                            {!isStringEmpty(
                                              errorMessages.dateOfContractExpiryValidation
                                            ) && (
                                                <div className="invalid-message-icon">
                                                  <img
                                                    src="images\icon material-error.png"
                                                    alt="invalid"
                                                  ></img>
                                                </div>
                                              )}
                                            <img style={{ display: (clientId != 0 && !isClient && ((!isActive && clientId != 0) || this.state.userRolePermissionRead || ((this.state.dateOfRenewContract != null && this.state.dateOfRenewContract != "") && (this.state.dateOfRenewContractExpiry != null && this.state.dateOfRenewContractExpiry != "") ? false : new Date(dateOfContractExpiry) < new Date() ? true : false))) ? "" : 'none', marginLeft: "8px" }} src="images/add.png" alt="plus-icon" onClick={() => this.renewalDateClick()} disabled={(isClient || (!isActive && clientId != 0) || this.state.userRolePermissionRead)} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12" style={{ display: this.state.showRenwaldatechange ? "" : 'none' }}>
                                  <div className="renew-contract-fieldset" >
                                    <fieldset className="p-2 admin-ship-fieldset" >
                                      <legend className="float-none w-auto p-2 renew-contract-legend">Renew Contract</legend>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="ship-detail-pb">
                                            <div className="row">
                                              <div
                                                style={{ alignSelf: "center" }} className="col-xl-6 col-lg-4 align-self-class">
                                                <h5>Date of Renewal:</h5>
                                              </div>
                                              <div
                                                className={
                                                  !isStringEmpty(
                                                    errorMessages.dateOfRenewContractValidation
                                                  )
                                                    ? "col-xl-6 col-lg-4 align-self-class invalid-position"
                                                    : "col-xl-6 col-lg-4 align-self-class"
                                                }
                                              >
                                                <DatePicker
                                                  selectedDate={this.state.dateOfRenewContract}
                                                  name="dateOfRenewContract"
                                                  id="dateOfRenewContract"
                                                  onDateChange={(e) =>
                                                    this.onDateChanged(
                                                      "dateOfRenewContract",
                                                      e
                                                    )
                                                  }
                                                  className="form-control"
                                                  title="dateOfRenewContract"
                                                  placeholder=""
                                                  disabled={
                                                    isClient ||
                                                    (!isActive && clientId != 0) ||
                                                    this.state.userRolePermissionRead || this.state.savedRenewalContract
                                                  }
                                                  minDate={contractexpirydate}
                                                  maxDate={this.state.dateOfRenewContractExpiry}
                                                >
                                                  {" "}
                                                </DatePicker>

                                                {!isStringEmpty(
                                                  errorMessages.dateOfRenewContractValidation
                                                ) && (
                                                    <div className="invalid-message-icon">
                                                      <img
                                                        src="images\icon material-error.png"
                                                        alt="invalid"
                                                      ></img>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="ship-detail-pb">
                                            <div className="row">
                                              <div
                                                style={{ alignSelf: "center" }} className="col-xl-6 col-lg-4 align-self-class">
                                                <h5>Date of Renewal Expiry:</h5>
                                              </div>
                                              <div
                                                className={
                                                  !isStringEmpty(
                                                    errorMessages.dateOfRenewContractExpiryValidation
                                                  )
                                                    ? "col-xl-6 col-lg-4 align-self-class invalid-position"
                                                    : "col-xl-6 col-lg-4 align-self-class"
                                                }
                                              >
                                                <DatePicker
                                                  selectedDate={this.state.dateOfRenewContractExpiry}
                                                  name="dateOfRenewContractExpiry"
                                                  id="dateOfRenewContractExpiry"
                                                  onDateChange={(e) =>
                                                    this.onDateChanged(
                                                      "dateOfRenewContractExpiry",
                                                      e
                                                    )
                                                  }
                                                  className="form-control"
                                                  title="dateOfRenewContractExpiry"
                                                  placeholder=""
                                                  disabled={
                                                    isClient ||
                                                    (!isActive && clientId != 0) ||
                                                    this.state.userRolePermissionRead
                                                  }
                                                  minDate={this.state.dateOfRenewContract}
                                                >
                                                  {" "}
                                                </DatePicker>

                                                {!isStringEmpty(
                                                  errorMessages.dateOfRenewContractExpiryValidation
                                                ) && (
                                                    <div className="invalid-message-icon">
                                                      <img
                                                        src="images\icon material-error.png"
                                                        alt="invalid"
                                                      ></img>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5>
                                          IHMM In Charge:
                                        </h5>
                                      </div>
                                      <div className="col-lg-6">
                                        <div id={"IhmmIncharge"} >
                                          <Dropdown
                                            dropdownLabel=""
                                            name="ihmmInCharge"
                                            onChange={this.onFilterIhmmSelected}
                                            selected={dropdown_ihmmInCharge}
                                            disabled={
                                              isClient ||
                                              (!isActive && clientId != 0) ||
                                              this.state.userRolePermissionRead
                                            }
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {ihmmInChargeList == null
                                              ? null
                                              : ihmmInChargeList.map(
                                                (item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item.value}
                                                  >
                                                    {item.label}
                                                  </option>
                                                )
                                              )}
                                            ;
                                          </Dropdown>

                                          {dropdown_ihmmInCharge > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"IhmmIncharge"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {ihmmInChargeList != null && ihmmInChargeList.filter(x => x.value == dropdown_ihmmInCharge)[0]?.label}
                                            </UncontrolledTooltip>) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>IHMM PIC Email Address:</h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.ihmmpicEmailAddressValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="ihmmpicEmailAddress"
                                            name="ihmmpicEmailAddress"
                                            autoComplete="new-password"
                                            value={this.state.ihmmEmailValue}
                                            onChange={this.onTextChange}
                                            disabled
                                            maxLength={100}
                                          />
                                          {!isStringEmpty(
                                            errorMessages.ihmmpicEmailAddressValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>Alternate IHMM In Charge:</h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <MultiSelectDropdown
                                          selectedOptions={
                                            dropdown_alternateIhmmInCharge ==
                                              undefined
                                              ? []
                                              : dropdown_alternateIhmmInCharge
                                          }
                                          options={
                                            alternateIhmmInChargeList ==
                                              undefined
                                              ? []
                                              : alternateIhmmInChargeList
                                          }
                                          onChange={this.onFilterAlternateIhmmSelected}
                                          isMulti={true}
                                          disabled={
                                            isClient ||
                                            (!isActive && clientId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-3 align-self-class">
                                        <h5>
                                          Alternate IHMM PIC Email Address:
                                        </h5>
                                      </div>
                                      <div className="col-lg-9">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.alternateihmmPicEmailAddressValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="alternateihmmPicEmailAddress"
                                            id="alternateihmmPicEmailAddress"
                                            value={
                                              this.state.alternateEmailValue
                                            }
                                            onChange={this.onTextChange}
                                            disabled
                                          />
                                          {!isStringEmpty(
                                            errorMessages.alternateihmmPicEmailAddressValidation
                                          ) && (
                                              <div className="invalid-message-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5>PO Frequency:</h5>
                                      </div>
                                      <div className="col-lg-6" id={"PoFreq"}>
                                        <Dropdown
                                          dropdownLabel=""
                                          name="poFrequency"
                                          onChange={this.onDropDownSelected}
                                          selected={dropdown_poFrequency}
                                          disabled={
                                            isClient ||
                                            (!isActive && clientId != 0) ||
                                            this.state.userRolePermissionRead
                                          }
                                          style={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "pre",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {notificationFrequencyList == null
                                            ? null
                                            : notificationFrequencyList.map(
                                              (item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              )
                                            )}
                                          ;
                                        </Dropdown>
                                        {dropdown_poFrequency > 0 ? (
                                          <UncontrolledTooltip
                                            autohide={false}
                                            target={"PoFreq"}
                                            aria-haspopup="true"
                                            role="example"
                                            effect="solid"
                                            placement="bottom"
                                            style={{ width: "350px" }}
                                          >
                                            {notificationFrequencyList != null && notificationFrequencyList.filter(x => x.value == dropdown_poFrequency)[0]?.label}
                                          </UncontrolledTooltip>) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                </div>

                                {/* <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5>SOC EU Expiry Reminder Count:</h5>
                                      </div>
                                      <div className="col-lg-6" id={"euCount"}>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="euCount"
                                          max={50} 
                                          maxLength={2}
                                          value={euCount}
                                          onChange={this.onTextChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div
                                        style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                        <h5>SOC HKC Expiry Reminder Count:</h5>
                                      </div>
                                      <div className="col-lg-6" id={"hkcCount"}>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="hkcCount"
                                          max={50} 
                                          maxLength={2}
                                          value={hkcCount}
                                          onChange={this.onTextChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                {/* <div className="col-md-12">
                                  <div style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                    Please enter a number from 1 to 50
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {clientId > 0 && (
                  <div className="col-12">
                    <div className="client-ship-table">
                      <div className="admin-ship-document">
                        {clientId > 0 && (
                          <div className="container">
                            {isActive && !this.state.userRolePermissionRead && !isClient && (
                              <div className="tooltip">
                                <img
                                  src="images/add-document.png"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editclientupload-modal"
                                  alt="add-document"
                                  className="add-document"
                                  style={{ pointerEvents: "all", width: "28px", height: "28px" }}
                                  onClick={() =>
                                    this.setState({ showUploadModal: true })
                                  }
                                  title="Add Document"
                                />
                              </div>
                            )}

                            <img
                              src="images/ship-download-document.png"
                              alt="download-document"
                              className="download-document"
                              title="Multi Download"
                              style={{ width: "28px", height: "28px" }}
                              onClick={() =>
                                this.downloadClientDocData(0, clientId)
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="admin-ship-document-table">
                        <div className="table-responsive">
                          <AGGrid
                            rowData={mappedDocuments}
                            colDefs={this.gridColumns(isClient, isActive)}
                            pagination="10"
                            onSelectionChanged={(e) =>
                              this.onSelectionChanged(e)
                            }
                            rowSelection="multiple"
                            onGridReady={this.onGridReady}
                            onSortChanged={(e) => this.onSortChanged(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="admin-ship-settings-save-btn">
                {showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images\icon material-error.png"
                      alt="invalid"
                    ></img>
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                {showErrorMessageEmail && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please enter valid email address.</p>
                  </div>
                )}
                {shortEmployeeNameError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>
                      Client Name must be atleast 3 characters long.
                    </p>
                  </div>
                )}
                {showErrorMessageMaritecEmail && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please enter email address other than @maritec.com.sg</p>
                  </div>
                )}
                {(clientCodeExistError ||
                  !isStringEmpty(errorMessages.clientCodeExistValidation)) && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Client Code already exists.</p>
                    </div>
                  )}

                {(!isStringEmpty(errorMessages.secondaryEmailValidation) ||
                  !isStringEmpty(
                    errorMessages.secondaryMaritecEmailValidation
                  ) || !isStringEmpty(errorMessages.secondaryPrimaryAddressEmailValidation)) && (
                    <div>
                      <div className="invalid-dropdown-icon">
                        <img
                          src="images\icon material-error.png"
                          alt="invalid"
                        ></img>
                      </div>
                      <div className="invalid-message-icon-box">
                        <img
                          src="images/icon material-error.png"
                          alt="invalid-icon"
                        />
                        <p>
                          {" "}
                          {!isStringEmpty(errorMessages.secondaryEmailValidation)
                            ? errorMessages.secondaryEmailValidation :
                            !isStringEmpty(errorMessages.secondaryPrimaryAddressEmailValidation) ?
                              errorMessages.secondaryPrimaryAddressEmailValidation
                              : errorMessages.secondaryMaritecEmailValidation}
                        </p>
                      </div>
                    </div>
                  )}
                <button
                  type="button"
                  className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn"
                  onClick={() => this.onSaveClick()}
                  disabled={
                    (!isActive && clientId != 0) ||
                    this.state.userRolePermissionRead
                  }
                >
                  Save
                </button>

                {clientId > 0 && !isClient ? (
                  <button
                    type="button"
                    className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive add-user-btn manage-users-btn"
                    onClick={this.onSendEmail}
                    disabled={!isActive || this.state.userRolePermissionRead}
                  >
                    Send Email
                  </button>
                ) : null}
              </div>
            </div>
          </section>
        </main>
        {showUploadModal && (
          <UploadClientDocumentModal
            closeUploadModal={this.closeUploadModal}
            shipList={shipList}
            clientId={clientId}
            clientName={clientName}
            documentTypeList={documentTypeList}
            onUploadClick={this.onUploadClick}
            showBulkUpload={false}
            isClientUser={isClient}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
          />
        )}
        {showHistoryModal && (
          <ViewDocumentHistoryModal
            onModalCloseClick={this.onViewHistoryModalClose}
            filesBasedDocumentType={filesBasedDocumentType}
            colDefs={documentColumns}
          />
        )}
        <br></br>
        <br></br>
        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.deleteClientDocData}
          />
        )}
        {this.state.showDirtyPopup && (
          <DirtyModal
            onModalCloseClick={this.onDirtyModalCloseClick}
            onProceedClick={this.onProceedClick}
          />
        )}

        {showChangePasswordConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onChangePasswordClick}
            modalText={"change password? An email will be sent to the user with a new password after clicking the Yes button."}
          />
        )}

        {showMoveToDocumentPopup && (
          <MoveDocumentModal
            shipList={shipList}
            moveDocumentTypeModalList={moveDocumentTypeModalList}
            onModalCloseClick={this.onModalCloseClick}
            onMoveClicked={this.onMoveClicked}
          />
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddEditClient);
