import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { AddLocalStorageData, GetLocalStorageDataWithoutParse, changePageTitle, withRouter, GetLocalStorageDataWithParse } from "../../helpers";
import Logout from "../../helpers/Logout";
import { dashboardservice } from "../../services/dashboard.service";
import StatusRenderer from "../../components/StatusRenderer";
import Footer from "../../components/Footer";
import Dropdown from "../../components/Dropdown";
import { SUPPLIER_SUMMARY, TitlePageNames } from "../../helpers/enums";
import AGGrid from "../../components/AGGrid";
import downloadIcon from "../../assets/images/admin-download.png";
import downloadIconpdf from "../../assets/images/download_pdf.png";
import editIcon from "../../assets/images/profile-feather-edit.png";
import eyeIcon from "../../assets/images/admin-eye.png";
import { helperText } from "../../helpers/helperText";
import SupplierDocumentModal from "../SupplierPortal/SupplierDocumentModal";
import { SupplierService } from "../../services/supplier.service";
import { UncontrolledTooltip } from "reactstrap";

class SupplierPendingCompletedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      supplierName: "",
      userId: 0,
      TrackingToolId: 0,
      selectedPendingItemsList: [],
      allPendingCompletedData: [],
      frameworkComponents: {
        tagRenderer: StatusRenderer,
      },
      filePathToView: "",
      clientNameList: [],
      dropdown_clientName: 0,
      shipNameList: [],
      dropdown_shipName: 0,
      defaultPendingCompletedData: [],
      viewFrom: GetLocalStorageDataWithoutParse("viewFrom"),
      showDocumentUploadModal: false,
    };
  }

  componentDidMount = () => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      changePageTitle(TitlePageNames.SUPPLIERMDSDOCITEMS);
      if (userData != null) {
        this.setState(
          { userId: userData.id, supplierName: userData.name },
          () => {
            AddLocalStorageData("loggedInUserName", userData.name);
            var listToDisplay = JSON.parse(
              GetLocalStorageDataWithoutParse("PendingCompletedList")
            );

            this.setState({
              defaultPendingCompletedData: listToDisplay,
              allPendingCompletedData: listToDisplay,
              viewFrom: GetLocalStorageDataWithoutParse("viewFrom"),
            });
            this.GetSupplierSummaryGridInfo(userData.id);
          }
        );
      }
    }
  };

  GetSupplierSummaryGridInfo = async (supplierId) => {
    this.setState({ isLoading: true });
    var response = await dashboardservice.GetSupplierSummaryGridInfo(
      supplierId,
      0
    );
    if (response.status == 200 && response.data != null) {
      var client = response.data.dropdownClientList;
      var ship = response.data.dropdownShipList;
      this.setState({
        clientNameList: client,
        shipNameList: ship,
        isLoading: false,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
      this.setState({
        isLoading: false,
        clientNameList: [],
        shipNameList: [],
      });
    }
    this.setState({
      isLoading: false,
    });
  };

  navigateTo = (path) => {
    this.props.navigate(path);
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  gridColumns = () => {
    return [
      {
        headerName: "Serial Number",
        // field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "PO Number",
        field: "poNumber",
      },
      {
        headerName: "Description",
        width: 300,
        field: "description",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Client",
        field: "clientName",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Ship",
        field: "shipName",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Date of Creation",
        field: "createdOn",
      },
      {
        headerName: "MD/SDoC Status",
        field: "mDSDoCStatus",
        unSortIcon: false,
        sortable: false,
        filter: false,
        cellRenderer: (params) => (
          <div>
            {GetLocalStorageDataWithoutParse("viewFrom") == "Pending"
              ? "Pending"
              : GetLocalStorageDataWithoutParse("viewFrom") == "Completed"
                ? "Completed"
                : ""}
          </div>
        ),
      },
      {
        headerName: "Add/Edit",
        field: "",
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 150,
        cellRenderer: (params) => (
          <a
            onClick={() => params.data.isViewDoc == true ? this.onViewCompletedDocumentClicked(params.data) : this.onAddEditDocumentClicked(params.data)}
            className="link-page cursor-class"
          >
            {params.data.isEditDoc == true
              ? "Edit Document"
              : params.data.isViewDoc == true
                ? "View Document"
                : "Add Document"}
          </a>
        ),
        cellStyle: {textAlign:"center" , paddingInline: "0"}
      },
    ];
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  filterGridView = () => {
    const { dropdown_clientName, dropdown_shipName } = this.state;

    var filterList = [];
    var DocList = this.state.defaultPendingCompletedData;

    if (dropdown_clientName == 0 && dropdown_shipName == 0)
      filterList = this.state.defaultPendingCompletedData;
    else {
      if (dropdown_clientName != "" && dropdown_clientName != 0)
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.clientId == parseInt(dropdown_clientName, 10)
            )
            : DocList.filter(
              (x) => x.clientId == parseInt(dropdown_clientName, 10)
            );

      if (dropdown_shipName != "" && dropdown_shipName != "0")
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.shipId == parseInt(dropdown_shipName, 10)
            )
            : DocList.filter((x) => x.shipId == dropdown_shipName);
    }
    this.setState({
      allPendingCompletedData: filterList,
    });
  };

  clearAllFilter = () => {
    this.setState(
      {
        dropdown_clientName: "0",
        dropdown_shipName: "0",
      },
      () => {
        this.filterGridView();
      }
    );
  };

  goBack = () => {
    AddLocalStorageData("PendingCompletedList", null);
    this.navigateTo("/SupplierDashboard");
  };

  onDocumentModalCloseClick = () => {
    this.setState({ showDocumentUploadModal: false });
  };

  onSaveMDSDOCDeclarationData = async (mdsdocData) => {
    this.setState({ showDocumentUploadModal: false, isLoading: true });

    const { selectedPendingItemsList, userId } = this.state;
    mdsdocData.append("ClientId", selectedPendingItemsList?.clientId);
    mdsdocData.append("ShipId", selectedPendingItemsList?.shipId);
      mdsdocData.append("loggedInUserID", userId);
      mdsdocData.append("fromPage", "Supplier");

    var responseSaved = await SupplierService.SaveUploadMDSDocData(mdsdocData);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        isLoading: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.TrackingTool.documnetUploadedSuccess,
      }, () => { this.GetSupplierSummaryGridInfo(this.state.userId); });
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onViewCompletedDocumentClicked = async (obj) => {
    this.navigateTo("/SupplierDocument");
  };

  onAddEditDocumentClicked = async (obj) => {
    this.setState({
      showDocumentUploadModal: true,
      selectedPendingItemsList: obj,
    });
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      allPendingCompletedData,
      clientNameList,
      dropdown_clientName,
      showDocumentUploadModal,
      shipNameList,
      dropdown_shipName,
      viewFrom,
      supplierName,
      userId,
      TrackingToolId,
      selectedPendingItemsList,
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showDocumentUploadModal && (
          <SupplierDocumentModal
            onDocumentModalCloseClick={this.onDocumentModalCloseClick}
            supplierName={supplierName}
            supplierId={userId}
            TrackingToolId={TrackingToolId}
            onSaveMDSDOCDeclarationData={this.onSaveMDSDOCDeclarationData}
            selectedPendingItemsList={selectedPendingItemsList}
          />
        )}
        <main className="page-height">
          <section className="communications po-items-list">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    alt="Back"
                    onClick={() => this.goBack()}
                  />
                  <span className="title-font">
                    {viewFrom == "Pending"
                      ? "Pending MD/SDoC"
                      : "Completed MD/SDoC"}
                  </span>
                </div>
              </div>
              <div className="desktop-class">
                <div className="row mt-row">
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"clientName"}>
                      <Dropdown
                        dropdownLabel="Client Name:"
                        name="clientName"
                        onChange={this.onFilterSelected}
                        selected={dropdown_clientName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {clientNameList == null
                          ? null
                          : clientNameList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_clientName > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"clientName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {clientNameList != null && clientNameList.filter(x => x.value == dropdown_clientName)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"shipName"}>
                      <Dropdown
                        dropdownLabel="Ship Name:"
                        name="shipName"
                        onChange={this.onFilterSelected}
                        selected={dropdown_shipName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {shipNameList == null
                          ? null
                          : shipNameList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_shipName > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"shipName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 "
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      type="button"
                      className="btn btn-blue-bg"
                      onClick={this.clearAllFilter}
                      style={{ marginTop: "27px" }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      rowData={allPendingCompletedData}
                      colDefs={this.gridColumns()}
                      rowSelection="single"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <br />
        <br />
        <Footer />
      </>
    );
  }
}

export default withRouter(SupplierPendingCompletedItems);
