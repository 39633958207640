import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { helperText } from "../../helpers/helperText";
import ViewModal from "../../components/ViewModal";
import { convertDate } from "../../helpers/string-helper";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { GetLocalStorageDataWithParse, changePageTitle, withRouter, AddLocalStorageData } from "../../helpers";
import Logout from "../../helpers/Logout";
import admindownload from "../../assets/images/admin-download.png";
import admineye from "../../assets/images/admin-eye.png";
import { Module, UserAction, UserRole } from "../../helpers/enums";
import { ClientService } from "../../services/client.service";
import UploadClientDocumentModal from "../Client/UploadClientDocumentModal";
import { ReportService } from "../../services/report.service";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";

class ClientDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedIHMDocument: [],
      defaultIHMDoc: [],
      colDefs: this.gridColumns,
      shipNameList: [],
      defaultShipNameList: [],
      dropdown_shipName: 0,
      documentTypeList: [],
      dropdown_documenttype: 0,
      showUploadModal: false,
      clientId: 0,
      showViewModal: false,
      filePathToView: "",
      selectedDocListIds: [],
      saveData: [],
      showConfirmationPopup: false,
      selectdDocDeleteId: 0,
      accessList: [],
      documentFile: [],
      isClientUser: false,
      clientUserId: 0,
      userRolePermissionRead: false,
      searchedText: "",
      isClient: false,
    };
  }

  componentDidMount() {
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      let isClientUser = userData.typeId == UserRole.CLIENTUSER;
      this.setState(
        {
          clientName: isClientUser ? "" : userData.name,
          clientId: isClientUser ? userData.clientId : userData.id,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          isClientUser: isClientUser,
          isClient: userData.typeId == UserRole.CLIENT,
          clientUserId: isClientUser ? userData.id : 0,
        },
        () => {
          this.getClientDocumentsData(
            this.state.clientId,
            this.state.clientUserId
          );
        }
      );
    }
  }

  onSaveNewTypeClick = async (typeName, selected_ship) => {

    this.setState({
      isLoading: true,
    });

    const { clientId, clientUserId } = this.state;
    let docData = {
      TypeName: typeName,
      Module: (selected_ship == "" || selected_ship == "0") ? Module.Client : Module.Ship,
    }
    var responseSaved = await CommonService.AddNewDocumentType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data)
      const response = await ClientService.GetClientDocumentData(clientId, clientUserId);

      if (response.data != null && response.data != undefined) {
        this.setState({
          documentTypeList: response.data.documentTypeAllDataList ?? [],
          isLoading: false,
        });
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
   date: 6/3/2023
   Name: AN
   description: to get data of all client documents 
   <summary> */
  getClientDocumentsData = async (clientId, clientUserId) => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.documentsAccess == UserAction.READ,
      });

      const response = await ClientService.GetClientDocumentData(
        clientId,
        clientUserId
      );

      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        }

        let documenttype = response.data.documentTypeAllDataList;

        let defaultshipNames = response.data.shipAllDataList;

        const { isClientUser, clientName } = this.state;

        this.setState({
          mappedIHMDocument: response.data.allClientDocumentList,
          defaultIHMDoc: response.data.allClientDocumentList,
          documentTypeList: documenttype ?? [],
          shipNameList: defaultshipNames ?? [],
          defaultShipNameList: defaultshipNames ?? [],
          //dropdown_shipName: 0,
          clientName: isClientUser ? response.data.clientName : clientName,
        },
          () => {
            this.filterGridView();
          });
      } else {
        this.setState({
          shipNameList: [],
          mappedIHMDocument: [],
          defaultIHMDoc: [],
          documentTypeList: [],
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          defaultShipNameList: [],
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:closes upload document modal
       <summary> */
  onCloseClick = () => {
    this.setState({ showUploadModal: false });
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:show upload document modal 
   <summary> */
  showDocumentUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  showDeleteDocModal = (id) => {
    this.setState({ showConfirmationPopup: true, selectdDocDeleteId: id });
  };

  gridColumns = [
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      unSortIcon: false,
    },
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      width: 150,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Document Name",
      field: "documentName",
      tooltipField: "documentName",
      flex: 1,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Document Type",
      field: "documentTypeName",
      tooltipField: "documentTypeName",
      width: 190,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Issue Date",
      field: "issueDate",
      tooltipField: "issueDate",
      width: 180,
    },
    {
      headerName: "Expiry Date",
      field: "expiryDate",
      tooltipField: "expiryDate",
      width: 180,
    },
    /*     {
      headerName: "Status",
      field: "status",
      tooltipField: "status",
      width: 190,
    }, */
    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admindownload}
          onClick={() => this.downloadIHMDocData(params.data.fileMediaId)}
          width="15px"
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admineye}
          onClick={() => this.viewIHMDocData(params.data.fileMediaId)}
          style={{ width: "20px" }}
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
  ];

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:view ihm documents
   <summary> */
  viewIHMDocData = (id) => {
    var filterList = [];
    filterList = this.state.mappedIHMDocument.filter(
      (x) => x.fileMediaId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:download ihm documents
   <summary> */
  downloadIHMDocData = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });
      let tFileIDlist = [];
      if (id == 0) {
        this.state.selectedDocListIds.forEach((element) => {
          var tId = element;
          tFileIDlist.push(tId);
        });
      } else {
        tFileIDlist.push(id);
      }
      if (tFileIDlist.length == 0) {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.noDocumentsSelected,
        });
        return;
      }

      let clientDoc = {
        clientDocId: tFileIDlist,
        clientId: this.state.clientId
      }

      const response = await ClientService.DownloadClientDocument(clientDoc);
      if (response.status == 200) {
        response.data.forEach((element) => {
          var fname = "";
          var lastItem = element.split("/").pop();
          fname = lastItem;

          const link = document.createElement("a");
          link.setAttribute("href", element);
          link.setAttribute("download", element);

          var ext = element.substring(element.lastIndexOf(".") + 1);
          if (ext == "pdf" || ext == "PDF") {
            link.setAttribute("target", "_blank");
          } else {
            link.setAttribute("target", "");
          }
          link.download = fname;
          document.body.appendChild(link);

          link.click();
        });

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:saves ihm document data
   <summary> */
  onUploadClick = async (docData) => {
    const { clientId, clientUserId, isClientUser } = this.state;

    this.setState({ isLoading: true });
    let clientDocumentData = new FormData();
    clientDocumentData.append("DocumentTypeId", docData.dropdown_documentType);
    clientDocumentData.append("Remark", docData.remarks);

    var dateIssue =
      docData.issueDate == "" ? "" : convertDate(docData.issueDate);
    clientDocumentData.append("IssueDate", dateIssue);

    var dateExpiry =
      docData.expiryDate == "" ? "" : convertDate(docData.expiryDate);
    clientDocumentData.append("ExpiryDate", dateExpiry);
    clientDocumentData.append(
      "loggedInUserID",
      isClientUser ? clientUserId : clientId
    );
    clientDocumentData.append("clientId", clientId);
    clientDocumentData.append("shipId", docData.dropdown_ship);
    clientDocumentData.append("BulkDocument", docData.bulkUpload);

    let files = docData.documentFile;
    var ins = files.length;

    for (var x = 0; x < ins; x++) {
      if (files[x].size !== null && files[x].size !== undefined) {
        clientDocumentData.append("ClientDocumentAttached", files[x]);
      }
    }

    var responseSaved = await ClientService.AddClientDocumentData(clientDocumentData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          showUploadModal: false,
          toastVisible: true,
          toastMessage: helperText.global.documnetUploadedSuccess,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        },
        () => {
          if (isClientUser)
            this.SaveClientUserChangeRequestData(
              docData,
              ins,
              files[0].name.substring(0, files[0].name.lastIndexOf("."))
            );
          else
            this.SaveClientChangeRequestData(
              docData,
              ins
            );
        }
      );

      this.getClientDocumentsData(clientId, clientUserId);
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = [];

      selectedRows.forEach((element) => {
        listSelectedTTId.push(element.fileMediaId);
      });
      this.setState({ selectedDocListIds: listSelectedTTId });
    } else {
      this.setState({ selectedDocListIds: [] });
    }
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
    });
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;


      this.setState(
        {
          [name]: value,
        },
        () => {
          this.filterGridView();
        }
      );
  };

  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:filters grid based on selected filter
   <summary> */
  filterGridView = () => {
    const {
      dropdown_shipName,
      dropdown_documenttype,
      dropdown_status,
      searchedText,
    } = this.state;

    var filterList = [];
    var ihmDocList = this.state.defaultIHMDoc;
    filterList = this.state.defaultIHMDoc;
    if (
      dropdown_shipName == 0 &&
      dropdown_documenttype == 0 &&
      dropdown_status == 0
    ) {
      filterList = this.state.defaultIHMDoc;
    } else {
      var filterApplied = false;
      if (dropdown_shipName != "" && dropdown_shipName != "0") {
        filterList =
          filterList.length > 0
            ? filterList
              .filter((x) => x.shipName)
              .filter((e) => e.shipId == dropdown_shipName)
            : ihmDocList
              .filter((x) => x.shipName)
              .filter((e) => e.shipId == dropdown_shipName);
        filterApplied = true;
      }

      if (dropdown_documenttype != "" && dropdown_documenttype != "0") {
        filterList = filterApplied
          ? filterList.filter((e) => e.documentTypeId == dropdown_documenttype)
          : ihmDocList.filter((e) => e.documentTypeId == dropdown_documenttype);
      }
    }

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();
      filterList = filterList.filter(
        (t) =>
          (t.shipName && t.shipName.toString().toLowerCase().includes(value)) ||
          t.documentName.toString().toLowerCase().includes(value) ||
          t.documentTypeName.toString().toLowerCase().includes(value) ||
          t.issueDate.toString().toLowerCase().includes(value) ||
          t.expiryDate.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedIHMDocument: filterList,
    });
  };

  onViewFileDownload = (filePath) => {
    var lastItem = filePath.split("/").pop();
    var fname = lastItem;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", filePath);
    document.body.appendChild(link);
    link.download = fname;
    link.click();
  };

  /* <summary>
     date: 13/02/2023
     Name: HV
     description: clearing filter
     <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_shipName: "0",
        dropdown_documenttype: "0",
        dropdown_status: "0",
        searchedText: "",
      },
      () => {
        this.filterGridView();
      }
    );
  };

  //A.N. - 06/03/2023 - handled for ship name filter select
  onShipNameSelect = (e) => {
    var { name, value } = e.target;

    this.setState({ [name]: value });

    var filterList = this.state.mappedIHMDocument;
    var supplierList = this.state.defaultIHMDoc;

    if (value != "" && value != "0")
      filterList =
        filterList.length > 0
          ? filterList
            .filter((x) => x.shipName)
            .filter((e) => e.shipId == value)
          : supplierList
            .filter((x) => x.shipName)
            .filter((e) => e.shipId == value);

    this.setState({
      mappedIHMDocument: value == "0" ? supplierList : filterList,
    });
  };

  onDocumentTypeSelect = (e) => {
    var { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    var filterList = this.state.mappedIHMDocument;
    var supplierList = this.state.defaultIHMDoc;

    if (value != "" && value != "0")
      filterList =
        filterList.length > 0
          ? filterList.filter((e) => e.documentTypeId == value)
          : supplierList.filter((e) => e.documentTypeId == value);

    this.setState({
      mappedIHMDocument: value == "0" ? supplierList : filterList,
    });
  };

  closeUploadModal = () => {
    this.setState({ showUploadModal: false });
  };

  SaveClientChangeRequestData = async (
    docData,
    noOfDocuments
  ) => {
    const { clientId, requestType } = this.state;
    var docfilename = "";
    if (!docData.bulkUpload) {
      let filedocumentdata = new FormData();
      filedocumentdata.append("ClientID", parseInt(clientId, 10));
      filedocumentdata.append(
        "DocumentTypeID",
        parseInt(docData.dropdown_documentType, 10)
      );
      filedocumentdata.append("SectionTypeID", 0);
      filedocumentdata.append("ShipID", docData.dropdown_ship);

      filedocumentdata.append(
        "IssueDate",
        docData.issueDate == "" ? "" : convertDate(docData.issueDate)
      );
      filedocumentdata.append(
        "ExpiryDate",
        docData.expiryDate == "" ? "" : convertDate(docData.expiryDate)
      );
      filedocumentdata.append("EditableProjectNo", "");
      filedocumentdata.append("PONo", "");
      filedocumentdata.append("IssuedBy", "");
      filedocumentdata.append("ClassificationId", 0);
      filedocumentdata.append("SupplierID", 0);
      filedocumentdata.append("ItemName", "");
      filedocumentdata.append("HazmatPresent", false);
      var reponsefilename = await ReportService.GetFileName(filedocumentdata);

      if (reponsefilename.status == 200 && reponsefilename.data != null) {
        docfilename = reponsefilename.data.result;
      }
    }
    let clientChangeRequestData = new FormData();
    clientChangeRequestData.append("ClientDocument", true);
    clientChangeRequestData.append("BulkDocument", docData.bulkUpload);
    clientChangeRequestData.append(
      "RequestType",
      docData.bulkUpload ? noOfDocuments : docfilename
    );
    
    clientChangeRequestData.append("ShipId", docData.dropdown_ship);
    clientChangeRequestData.append("EditedBy", clientId);
    clientChangeRequestData.append("ClientId", clientId);
    clientChangeRequestData.append("ModuleName", "Client");
    clientChangeRequestData.append("NavigateTo", "Client Details");
    clientChangeRequestData.append(
      "DocumentTypeID",
      docData.dropdown_documentType
    );

    var responseSaved = await ClientService.SaveClientChangeRequestData(
      clientChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          isLoading: false,
        },
        () => {
          this.onCloseChangeRequestModalClick();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  onCloseChangeRequestModalClick = () => {
    this.setState({ showClientRequestModal: false, requestType: "" });
  };

  onSearch = (e) => {
    var inputValue = e.target.value;
    this.setState(
      {
        searchedText: inputValue,
      },
      () => {
        this.filterGridView();
      }
    );

    //var shipList = this.state.defaultIHMDoc;
    // if (inputValue.trim() !== "") {
    //   var value = inputValue.trim().toString().toLowerCase();

    //   shipList = shipList.filter(
    //     (t) =>
    //       (t.shipName && t.shipName.toString().toLowerCase().includes(value)) ||
    //       t.documentName.toString().toLowerCase().includes(value) ||
    //       t.documentTypeName.toString().toLowerCase().includes(value)
    //   );
    // }

    // this.setState({
    //   mappedIHMDocument: shipList,
    // });
  };

  /* <summary>
  date: 23/03/2023
  Name: HV
  description: saving data for documnet upload
  <summary> */

  SaveClientUserChangeRequestData = async (
    docData,
    noOfDocuments,
    documentName
  ) => {
    const { clientUserId, clientId } = this.state;

    this.setState({ isLoading: true, showProceedModal: false });
    let clientUserChangeRequestData = new FormData();
    clientUserChangeRequestData.append("ClientDocument", true);
    clientUserChangeRequestData.append("BulkDocument", docData.bulkUpload);
    clientUserChangeRequestData.append(
      "RequestType",
      docData.bulkUpload ? noOfDocuments : documentName
    );
    clientUserChangeRequestData.append("EditedBy", clientUserId);
    clientUserChangeRequestData.append("ClientId", clientId);
    clientUserChangeRequestData.append("ClientUserId", clientUserId);
    clientUserChangeRequestData.append("ModuleName", "Client");
    clientUserChangeRequestData.append("NavigateTo", "Client Details");

    var responseSaved = await ClientService.SaveClientUserChangeRequestData(
      clientUserChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.onCloseChangeRequestModalClick();
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      mappedIHMDocument,
      colDefs,
      shipNameList,
      dropdown_shipName,
      documentTypeList,
      dropdown_documenttype,
      showViewModal,
      filePathToView,
      accessList,
      showUploadModal,
      isClient
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ClientDocuments"} />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        <main className="page-height">
          <section className="manage-supplier">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    <a onClick={() => this.props.navigate("/ClientDashboard")}>
                      <img src="images/back.png" alt="Back" />
                    </a>
                    <h5>Documents</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <Searchbar
                    placeholder={"Document Type, Document Name, etc..."}
                    onChange={this.onSearch}
                  />
                </div>
              </div>

              {/* <!-- Mobile View --> */}
              <div className="training-mobile">
                <div className="accordion-filter-dropdown">
                  <div id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="supplier-filter">
                            <div className="row">
                              <div className="col-lg-12 filter-flex-container wrap">
                                <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"shipName"}>
                                  <Dropdown
                                    dropdownLabel="Ship Name:"
                                    name="shipName"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_shipName}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {shipNameList == null
                                      ? null
                                      : shipNameList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </Dropdown>
                                  {dropdown_shipName > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"shipName"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                </div>

                                <div className="filter-box filter-flex-item">
                                  <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"doctype"}>
                                    <Dropdown
                                      dropdownLabel="Document Type:"
                                      name="documenttype"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_documenttype}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {documentTypeList == null
                                        ? null
                                        : documentTypeList.map((item, key) => (
                                          <option
                                            key={key}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_documenttype > 0 ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"doctype"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                                      </UncontrolledTooltip>) : null}
                                  </div>
                                </div>

                                {/* <div className="form-group form-label dropdown-form admin-client-form filter-flex-item">
                                  <Dropdown
                                    dropdownLabel="Status:"
                                    name="status"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_status}
                                  >
                                    <option key={1} value={"Active"}>
                                      {"Active"}
                                    </option>
                                    <option key={2} value={"Inactive"}>
                                      {"Inactive"}
                                    </option>
                                    ;
                                  </Dropdown>
                                </div> */}
                                <div
                                  className="col-12 col-md-4 col-lg-3 "
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-blue-bg"
                                    onClick={this.clearAllFilter}
                                    style={{ marginTop: 15 }}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="filter-btn training-add-button"
                    style={{ marginTop: 0 }}
                  >
                    <button
                      type="button"
                      disabled={this.state.userRolePermissionRead}
                      id="upld-btn-training"
                      className="btn btn-blue-bg "
                      data-bs-toggle="modal"
                      data-bs-target="#IHMDBRegistryUpload-modal"
                      onClick={this.showDocumentUploadModal}
                    >
                      Upload Document
                    </button>
                    &nbsp;{" "}
                    {mappedIHMDocument.length > 0 ? (
                      <span>
                        <img
                          id="training-admin-dwnld-icon"
                          src="./images/admin-tracking-download.png"
                          alt="download-document"
                          onClick={() => {
                            this.downloadIHMDocData(0);
                          }}
                        />
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* <!-- DESKTOP --> */}
              <div className="training-desktop">
                <div className="desktop-class">
                  <div className="row">
                    <div className="col-lg-12 filter-flex-container wrap">
                      <div className="filter-box filter-flex-item form-label">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"shipName1"}>
                          <Dropdown
                            dropdownLabel="Ship Name:"
                            name="shipName"
                            onChange={this.onFilterSelected}
                            selected={dropdown_shipName}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {shipNameList == null
                              ? null
                              : shipNameList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>

                          {dropdown_shipName > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"shipName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>

                      <div className="filter-box filter-flex-item form-label">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"docType1"}>
                          <Dropdown
                            dropdownLabel="Document Type:"
                            name="documenttype"
                            onChange={this.onFilterSelected}
                            selected={dropdown_documenttype}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {documentTypeList == null
                              ? null
                              : documentTypeList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_documenttype > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"docType1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>

                      {/* <div className="col-lg-3 col-md-4">
                        <div className="form-group form-label dropdown-form admin-client-form filter-flex-item">
                          <Dropdown
                            dropdownLabel="Status:"
                            name="status"
                            onChange={this.onFilterSelected}
                            selected={dropdown_status}
                          >
                            <option key={1} value={"Active"}>
                              {"Active"}
                            </option>
                            <option key={2} value={"InActive"}>
                              {"InActive"}
                            </option>
                            ;
                          </Dropdown>
                        </div>
                      </div> */}
                      <div
                        className="filter-box clear-all-btn"
                        style={{ marginBottom: "8px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-blue-bg"
                          onClick={this.clearAllFilter}
                          style={{ marginTop: 15 }}
                        >
                          Reset
                        </button>
                      </div>
                      <div
                        className="col-12 training-add-button"
                        style={{ marginTop: 0 }}
                      >
                        <button
                          type="button"
                          id="upld-btn-training"
                          className="btn btn-blue-bg "
                          disabled={this.state.userRolePermissionRead}
                          data-bs-toggle="modal"
                          data-bs-target="#IHMDBRegistryUpload-modal"
                          onClick={this.showDocumentUploadModal}
                        >
                          Upload Document
                        </button>{" "}
                        &nbsp;{" "}
                        {mappedIHMDocument.length > 0 ? (
                          <span>
                            <img
                              id="training-admin-dwnld-icon"
                              src="./images/admin-tracking-download.png"
                              alt=""
                              onClick={() => {
                                this.downloadIHMDocData(0);
                              }}
                            />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--grid Code for manage supplier --> */}
              <div className="training-grid">
                <div className="row">
                  <div className="col-md-12">
                    <AGGrid
                      rowData={mappedIHMDocument}
                      colDefs={colDefs}
                      onGridReady={this.onGridReady}
                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                      rowSelection="multiple"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        {showUploadModal && (
          <UploadClientDocumentModal
            showBulkUpload={true}
            closeUploadModal={this.closeUploadModal}
            shipList={this.state.shipNameList}
            clientId={this.state.clientId}
            isClient={isClient}
            clientName={this.state.clientName}
            documentTypeList={this.state.documentTypeList}
            onUploadClick={this.onUploadClick}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
          />
        )}

        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Document File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
          ></ViewModal>
        )}

        <Footer />
      </>
    );
  }
}
export default withRouter(ClientDocuments);
