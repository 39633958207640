import React from "react";
import fileUploadIcon from "../assets/images/tt-file-upload.png";

const HelpModal = ({ helpContentModal, isPOInstructions, onOkClick }) => {
  return (
    <section>
      <div className="supplier_on_hold">
        <div
          className="modal-backdrop fade show"
          style={{ display: helpContentModal ? "block" : "none" }}
        ></div>
        <div
          className="modal"
          id="view-document-Modal"
          style={{
            display: helpContentModal ? "block" : "none",
            zIndex: "999999",
          }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-box modal-md poinfo-modal-dialog">
            <div
              className="modal-content modal-border"
              style={{ width: "800px" }}
            >
              <div className="row content" style={{ width: "800px" }}>
                {isPOInstructions ? (
                  <div
                    className="table-responsive"
                    style={{ textAlign: "left" }}
                  >
                    <span>
                      <b>1) What information must be entered to upload a PO?</b>
                    </span>
                    <br />
                    <ul>
                      <li>
                        You can upload the PO Excel file by filling in the
                        required fields, which are marked by an asterisk (*).
                      </li>
                    </ul>
                    <span>
                      <b>2) What format should the PO Date be entered in?</b>
                    </span>
                    <br />
                    <ul>
                      <li>
                        Enter the PO Date in the format dd-MM-yyyy E.g.,
                        14-03-2022.
                      </li>
                    </ul>
                    <span>
                      <b>
                        3) Is it mandatory to enter supplier fields when
                        uploading PO?{" "}
                      </b>
                    </span>
                    <br />
                    <ul>
                      <li>
                        No, it is not mandatory to add Supplier data when
                        uploading PO file. However, the PO will not be linked to
                        the Supplier and linking to supplier will have to be
                        done by manually updating the Supplier information on
                        the tracking tool against each PO line item.
                      </li>
                    </ul>
                    <span>
                      <b>
                        4) What information must be provided to link PO to
                        corresponding Supplier profile?
                      </b>
                    </span>
                    <br />
                    <ul>
                      <li>
                        Enter the supplier's name, email address, and branch
                        information exactly as present in the supplier profile
                        to link it. Spelling mistakes will create a new supplier
                        profile.
                      </li>
                      <li>
                        If the supplier does not exist in the existing supplier
                        database, enter the supplier's name, email address, and
                        branch information when uploading PO to have the
                        supplier’s profile automatically created. If Branch is
                        not known, enter it as 0 as leaving it as blank will not
                        create the supplier profile, and supplier will have to
                        be manually created and linked to PO line items in this
                        instance.
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div
                    className="table-responsive"
                    style={{ textAlign: "left" }}
                  >
                    {/* <label for="exampleDataList" className="form-label">
                    1) How to save the Manual data in Tracking Tool? Please
                    press Enter on the keyboard after manually entering the data
                    to save it.
                    <br />
                    2) How to upload a PO for the manual entered data? To upload
                    a PO list, select a line item by selecting the checkbox.
                    Click the upload icon. Two options will be displayed. Upload
                    MD/SDOC and upload the PO List. Click "Upload PO List."
                    Select the file type (Excel/PDF) and attach the file by
                    clicking "Choose File." Click "Upload" to upload the file.
                   </label> */}
                    <span>
                      <b>1) How to save add/edit data in Tracking Tool?</b>
                    </span>
                    <br />
                    <ul>
                      <li>
                        Please press Enter on the keyboard after manually
                        entering the data to save it.
                      </li>
                    </ul>
                    <span>
                      <b>2) How to upload a PO for the manual entered data?</b>
                    </span>
                    <ul>
                      <li>
                        To upload a PO list, select a line item by selecting the
                        checkbox.
                      </li>
                      <li>
                        Click the{" "}
                        <img
                          height={20}
                          width={20}
                          src={fileUploadIcon}
                          title="Upload multiple MD/SDoC"
                          data-bs-placement="bottom"
                        />{" "}
                        icon. Two options will be displayed. Upload MD/SDOC and
                        upload the PO List.
                      </li>
                      <li>Click "Upload PO List."</li>
                      <li>
                        Select the file type (Excel/PDF) and attach the file by
                        clicking "Choose File".
                      </li>
                      <li>
                        Click "Upload" to upload the file. Once the file is
                        uploaded PO date will be displayed automatically.
                      </li>
                    </ul>
                    <span>
                      <b>3) How to enable Send Email link?</b>
                    </span>
                    <br />
                    <ul>
                      <li>
                        To enable the Send Email link, enter PO Number, Supplier
                        Branch Name, Supplier Email and Description.
                      </li>
                    </ul>
                  </div>
                )}

                <br />
                <div className="filter-box filter-margin-top">
                  <button
                    type="button"
                    className="btn btn-blue-bg "
                    onClick={onOkClick}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HelpModal;
