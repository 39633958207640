import React, { Component } from "react";

export default function MobileDropdown(props) {
  return (
    <>
      <label className="label-class label-mobile">{props.dropdownLabel}</label>
      <select
        name={`dropdown_${props.name}`}
        id="ddlDropdown"
        disabled={props.disabled ?? false}
        className="form-select custom-dropdown-class history-from-drpdown"
        onChange={(ev) => props.onChange(ev)}
        aria-label="dropdownMobile"
      >
        <option key={"0"} value={"0"}>
          Select
        </option>
        {props.children}
      </select>
    </>
  );
}
