import React, { Component } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import AGGrid from "../../components/AGGrid";
import viewIcon from "../../assets/images/admin-eye.png";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import Toast from "../../components/Toast";
import { UserAction, UserRole } from "../../helpers/enums";
import { StatisticsService } from "../../services/statistics.service";
import * as globalFunctions from "../../helpers/utilities";


let switchChecked = true;

class ManageStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedStatistics: [],
      defaultStatistics: [],
      colDefs: this.gridColumns,
      clientPendingChangeRequestCount: 0,
      accessList: [],
      gridApi: null,
      userRolePermissionRead: false,
      userId: 0,
      userTypeId: 0,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");

    if (userData != null) {
      this.setState(
        {
          userId: userData.id,
          userTypeId: userData.typeId,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        () => {
          this.getToken();
        }
      );
    }
  }

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
  };

  onSortChanged(e) {
    e.api.refreshCells();
  }

  gridColumns = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Report Name",
      field: "reportName",
      tooltipField: "reportName",
      wrapText: true,
      width: 960,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 50,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={params.data.powerBireportId != null ? viewIcon : null}
          width="20px"
          title={params.data.powerBireportId != null ? "View" : ""}
          data-bs-toggle="modal"
          data-bs-target="#edit-icon"
          onClick={(e) => {
            this.onReportViewClicked(params.data.reportName);
          }}
        />
      ),
    },
  ];

  getToken = async () => {
    const response = await StatisticsService.GetStatisticsReportToken(this.state.userId, this.state.userTypeId);
    this.setState({ accessToken: response.data }, () => {
      this.getManageStatisticsData();
    });
  };

  getManageStatisticsData = async () => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.clientAndShipAccess == UserAction.READ,
      });
      const response = await StatisticsService.GetStatisticsDropdownData(this.state.userTypeId);

      if (response.status == 200) {
        this.setState(
          {
            mappedStatistics: response.data,
            defaultStatistics: response.data,
            isLoading: false,
          },
          () => {
            this.setState({});
          }
        );
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onReportViewClicked = async (reportName) => {
    const { mappedStatistics, accessToken } = this.state;
    var reportData = mappedStatistics.filter(r => r.reportName == reportName);
    if (reportData != null) {
      this.setState({
        powerBIReportID: reportData[0].powerBireportId,
        powerBIGroupId: reportData[0].powerBigroupId,
      });
      AddLocalStorageData(
        "powerBIReportID",
        reportData[0].powerBireportId
      );
      AddLocalStorageData(
        "powerBIReportName",
        reportName
      );

      const reportDetails = await StatisticsService.GetReport(
        reportData[0].powerBireportId,
        reportData[0].powerBigroupId,
        this.state.userId,
        this.state.userTypeId
      );
      if (reportDetails.data != null) {
        this.setState({ embeddedUrl: reportDetails.data });
        AddLocalStorageData("embeddedUrl", reportDetails.data);
        const embeddedTokenData = await StatisticsService.GetEmbeddedTokenData(
          reportData[0].powerBireportId,
          reportData[0].powerBigroupId,
          this.state.userId,
          this.state.userTypeId
        );
        if (embeddedTokenData.data != null) {
          this.setState({ embeddedToken: embeddedTokenData.data });
          AddLocalStorageData("embeddedToken", embeddedTokenData.data);
        }
      }
    }
    this.props.navigate("/StatisticsReport");
  };

  goback = () => {
    const { userTypeId } = this.state;

    if (userTypeId == UserRole.CLIENT || userTypeId == UserRole.CLIENTUSER)
      this.props.navigate("/ClientDashboard");
    else if (userTypeId == UserRole.SHIPCREW) this.props.navigate("/ShipCrewDashboard");
    else this.props.navigate("/Dashboard");
  };

  onSearch = (e) => {
    var inputValue = e.target.value;
    var statisticsList = this.state.defaultStatistics;

    if (inputValue.trim() !== "") {
      var value = inputValue.trim().toString().toLowerCase();
      statisticsList = statisticsList.filter((t) =>
        t.reportName?.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedStatistics: statisticsList,
    });
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  render() {
    const {
      mappedStatistics,
      colDefs,
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ManageStatistics"} />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        <main className="page-height">
          <section className="communications">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                    onClick={this.goback}
                  />
                  <span className="title-font">Manage Statistics</span>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <div className="searchBar">
                    <Searchbar
                      placeholder={"Report Name"}
                      onChange={this.onSearch}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      onGridReady={this.onGridReady}
                      rowData={mappedStatistics}
                      colDefs={colDefs}
                      rowSelection="single"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <br />
        <br />
        <Footer />
      </>
    );
  }
}

export default withRouter(ManageStatistics);
