import webserviceWrapper from "../helpers/webserviceWrapper";

export const IHMDatabaseRegistryService = {
    GetIHMDatabaseRegistryData,
    AddIHMDatabaseRegistryData,
    DownloadIHMDoc,
    DeleteIHMDocData
};

function GetIHMDatabaseRegistryData(userID, userTypeId) {
    return webserviceWrapper.get(`/IHMDatabaseRegistry/GetIHMDatabaseRegistryData?userID=${userID}&userTypeId=${userTypeId}`);
}

function AddIHMDatabaseRegistryData(data) {
    return webserviceWrapper.postWithHeader(`/IHMDatabaseRegistry/AddIHMDatabaseRegistryData`, data, { "Content-Type": "multipart/form-data", }, 600000);
}

function DownloadIHMDoc(tFileIDlist) {
    return webserviceWrapper.post(
        `/IHMDatabaseRegistry/DownloadIHMDoc`,
        tFileIDlist,
        600000
    );
}

function DeleteIHMDocData(fileMediaId, userTypeId, loggedInUserId) {
    return webserviceWrapper.get(`/IHMDatabaseRegistry/DeleteIHMDocData?fileMediaId=${fileMediaId}&userTypeId=${userTypeId}&loggedInUserId=${loggedInUserId}`);
}