import React, { Component } from 'react'

export default function YesNoDropdown(props) {
    return (
        <>
            {props.dropdownLabel != "" && (
                <label className="label-class label-mobile label-dropdown">{props.dropdownLabel}</label>
            )}
            <select name={`dropdown_${props.name}`}
                id="ddlDropdown"
                disabled={props.disabled ?? false}
                className="form-select custom-dropdown-class"
                onChange={(ev) => props.onChange(ev)}
                aria-label="dropdown-select"
                value={props.selected}
                >
                {/* <option key={0} value={0}>Select</option> */}
                <option key={1} value={1}>Yes</option>
                <option key={2} value={2}>No</option>
                {props.children}
            </select>
        </>

    )
}
