import React, { Component } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { UserService } from "../../services/user.service";
import { isStringEmpty } from "../../helpers/string-helper";
import Dropdown from "../../components/Dropdown";
import { UserAction } from "../../helpers/enums";
import {
  generateRandomString,
  helperText,
  isValidFile,
  isEmailValid,
  isPasswordValid,
  changePageTitle,
  AddLocalStorageData,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
} from "../../helpers";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import UserCredentials from "../../components/UserCredentials";
import { withRouter } from "../../helpers";
import editIcon from "../../assets/images/profile-feather-edit.png";
import Toast from "../../components/Toast";
import RolesPermissionModal from "./RolesPermissionModal";
import Logout from "../../helpers/Logout";
import { CommonService } from "../../services/common.service";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import ConfirmationModal from "../../components/ConfirmationModal";
import addProfileIcon from "../../assets/images/plus.png";
import UserRolesNPermissionModal from "../../components/UserRolesNPermissionModal";
import DirtyModal from "../../components/DirtyModal";
import deleteIcon from "../../assets/images/delete icon.png";
import DeleteModal from "../../components/DeleteModel";
import { UncontrolledTooltip } from "reactstrap";

const validFileFormats = ["png, jpg, jpeg"];
const defaultAllItem = { label: "All", value: "0" };
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";
const NUMBER_REGEX = /^[0-9()+-\s]+$/;

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastResponseType: "",
      toastMessage: "",
      userId: 0,
      dropdownList: {},
      errorMessages: {},
      showErrorMessage: false,
      shortEmployeeNameError: false,
      showErrorMessageEmail: false,
      showPassword: false,
      loggedInUserID: 0,
      profilePicture: "",
      dropdownList_shipList_Filtered: [],
      disableSave: false,
      password: "",
      usernameExistError: false,
      emailExistError: false,
      contactNo: "",
      originalUsername: "",
      showConfirmationPopup: false,
      address: "",
      profilePictureFileMediaId: 0,
      typeId: "",
      viewRolesPermissionVisible: false,
      accessList: [],
      userRolePermissionRead: false,
      customUserRolesPermissions: [],
      rolesPermissionId: 0,
      dropdown_inheritedUserLevelId: 0,
      defaultAssignedClientList: [],
      removedAssignedClientList: [],
      showDirtyPopup: false,
      path: "",
      isDataEditable: true,
      isCustomRoleUpdated: false,
      showDeleteProfileModal: false,
      isProfileImgDelete: false,
      prevClientList: [],
      prevShipList: [],
      removedShips: [],
      newAddedClients: [],
      defaultAssignedShipList: []
    };
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.USERDETAILS);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      var selectedUser = parseInt(GetLocalStorageDataWithoutParse("selectedUser"), 10);
      this.setState(
        {
          typeId: userData.typeId,
          userId: selectedUser,
          loggedInUserID: userData.id,
          isLoading: true,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        async () => {
          const { userId } = this.state;
          try {
            await this.getDropdownData()
              .then(async () => {
                if (userId == 0) {
                  this.getSystemGeneratedPasswordForNewUser();
                } else {
                  this.loadUserData(userId, userData.typeId);
                }
              })
              .then(() => {
                this.getUserAndDefaultAccessPermissions(false);
              })
              .finally(() => {
                setTimeout(() => {
                  this.setState({ isLoading: false });
                }, 4000);
              });
          } catch (error) { }
        }
      );
    }
  }

  getDropdownData = async () => {
    this.setState({
      userRolePermissionRead:
        this.state.accessList != null &&
        this.state.accessList.manageUserAccess == UserAction.READ,
    });
    const response = await UserService.GetDropdownData(
      this.state.loggedInUserID
    );
    if (response != null && response != undefined && response.status == 200) {
      var clientList = response.data.clientList;
      if (clientList.length > 0)
        clientList.unshift(defaultAllItem);

      var shipList = response.data.shipList;
      shipList.unshift(defaultAllItem);

      this.setState({
        dropdownList: response.data,
        dropdownList_shipList_Filtered: shipList,
        clientList: clientList,
        shipList: shipList,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
      });
    }
  };

  loadUserData = async (userId, typeId) => {
    var response = await UserService.GetUserProfileData(userId, typeId);
    if (response != null && response != undefined && response.status == 200) {
      let assignedClient = response.data.clientList;
      let totalClientList = this.state.clientList.filter((x) => x.value != "0");

      if (assignedClient.length > 0 && totalClientList.length == assignedClient.length) {
        assignedClient.unshift(defaultAllItem);
      }

      this.setState(
        {
          employeeName: response.data.name,
          contactNo: response.data.phoneNumber,
          emailAddress: response.data.emailAddress,
          address: response.data.address,
          dropdown_region: response.data.regionId,
          dropdown_userRole: response.data.userRoleId,
          dropdown_serviceCategory: response.data.serviceCategoryId,
          dropdown_userLevel: response.data.levelId,
          dropdown_ship: response.data.shipList,
          dropdown_client: assignedClient,
          prevClientList: assignedClient,
          prevShipList: response.data.shipList,
          defaultAssignedClientList: response.data.clientList,
          defaultAssignedShipList: response.data.shipList,
          profilePicture: response.data.userPic,
          designation: response.data.designation,
          username: response.data.userName,
          originalUsername: response.data.userName ?? "",
          password: response.data.password,
          profilePictureFileMediaId: response.data.profilePictureFileMediaId,
          isLoading: false,
        },
        () => {
          AddLocalStorageData("isDirty", false);
          this.filterShipDropdownBasisClient();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
      });
    }
  };

  getSystemGeneratedPasswordForNewUser = async () => {
    const response = await UserService.GetSystemGeneratedPassword(
      this.state.userId
    );
    if (response != null && response != undefined && response.status == 200) {
      this.setState({ password: response.data.result });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
      });
    }
  };

  getUserAndDefaultAccessPermissions = async (showModal) => {
    const response = await UserService.GetUserAndDefaultAccessPermissions(
      this.state.userId
    );
    if (
      response != null &&
      response != undefined &&
      response.data != null &&
      response.status == 200
    ) {
      const { customUserRolesPermissions, dropdown_inheritedUserLevelId } =
        this.state;
      let custRoles =
        (customUserRolesPermissions == null ||
          customUserRolesPermissions.length == 0)
          ? response.data.rolesPermissions
          : customUserRolesPermissions;
      let rolesPermissionId = 0;

      if (custRoles != null && custRoles.rolePermissionId != 0) {
        if (custRoles.clientAndShipAccess == 0) {
          rolesPermissionId = custRoles.rolePermissionId;
        }
      }
      this.setState({
        defaultRolesPermissions: response.data.defaultRolesPermissions,
        userRolesPermissions: custRoles,
        dropdown_inheritedUserLevelId:
          dropdown_inheritedUserLevelId != 0 &&
            dropdown_inheritedUserLevelId != null
            ? dropdown_inheritedUserLevelId
            : custRoles == null
              ? 0
              : custRoles.inheritedLevelId,
        customUserRolesPermissions:
          custRoles != null &&
            custRoles.rolePermissionId != 0 &&
            custRoles.clientAndShipAccess != 0
            ? custRoles
            : null,
        rolesPermissionVisible: showModal,
        rolesPermissionId: rolesPermissionId,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
      });
    }
  };

  onDropdownSelect = (e) => {
    var { name, value } = e.target;
    const { showErrorMessage } = this.state;
    this.setState(
      {
        [name]: value,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  onRemoveCapsule = (value, stateListName) => {
    var updateState = this.state[stateListName];
    this.setState(
      { [stateListName]: updateState.filter((t) => t.value != value) },
      () => {
        if (stateListName == "dropdown_client") {
          this.filterShipDropdownBasisClient();
        }
      }
    );
  };

  onClientDropdownChange = (option) => {
    var dropdown_client = [];
    const { defaultAssignedClientList } = this.state;
    var removedAssignedClients = defaultAssignedClientList;

    if (option.length > 0) {
      option
        // .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_client.push(item);
          removedAssignedClients = removedAssignedClients.filter(
            (x) => x.value != item.value
          );
        });
    }
    this.setState(
      { dropdown_client, removedAssignedClientList: removedAssignedClients },
      () => {
        AddLocalStorageData("isDirty", true);
        this.filterShipDropdownBasisClient();
      }
    );
  };

  filterShipDropdownBasisClient = () => {
    var { dropdown_client, dropdown_ship } = this.state;
    let assignedShip = dropdown_ship == undefined ? [] : dropdown_ship;

    var dropdownShipList =
      this.state.shipList == undefined ? [] : this.state.shipList;
    if (dropdownShipList.length > 0) {
      dropdownShipList = dropdownShipList.filter((e) =>
        dropdown_client.map((e2) => e2.value).includes(e.parentValue)
      );
      if (dropdownShipList.length > 0) {
        dropdownShipList.unshift(defaultAllItem);
      }

      if (dropdown_ship != undefined) {
        assignedShip = dropdown_ship.filter((e) =>
          dropdown_client.map((e2) => e2.value).includes(e.parentValue)
        );
      }

      let totalShipList = dropdownShipList.filter((x) => x.value != "0");

      if (totalShipList.length == assignedShip.length && assignedShip.length > 0) {
        assignedShip.unshift(defaultAllItem);
      }


      this.setState({
        dropdownList_shipList_Filtered: dropdownShipList,
        dropdown_ship: assignedShip,
      });
    }
  };

  onShipDropdownChange = (option) => {
    const { defaultAssignedShipList } = this.state;
    var removedShipLst = defaultAssignedShipList;
    var dropdown_ship = [];
    if (option.length > 0) {
      option
        // .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_ship.push(item);
          removedShipLst = removedShipLst.filter(
            (x) => x.value != item.value
          );
        });
    }

    AddLocalStorageData("isDirty", true);
    this.setState({ dropdown_ship, removedShips: removedShipLst });
  };
  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState(
      {
        showDirtyPopup: false,
      },
      () => {
        AddLocalStorageData("isDirty", false);
        if (this.state.path != "") this.props.navigate(this.state.path);
      }
    );
  };
  onSendEmail = async () => {
    const { userId } = this.state;

    if (userId != 0) {
      this.setState({ isLoading: true });
      var response = await CommonService.SendEmailToUser(
        userId,
        UserRole.INTERNALUSER
      );
      if (response != null && response != undefined && response.status == 200) {
        var emailSent = response.data;
        if (emailSent == true) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.emailSent,
            toastResponseType: helperText.global.toastSuccess,
            isLoading: false,
          });
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastResponseType: helperText.global.toastError,
            isLoading: false,
          });
        }
      }
    }
  };

  onSave = async () => {
    const {
      userId,
      employeeName,
      contactNo,
      emailAddress,
      address,
      designation,
      username,
      password,
      dropdown_region,
      dropdown_userRole,
      dropdown_serviceCategory,
      dropdown_userLevel,
      dropdown_client,
      dropdown_ship,
      documentFile,
      loggedInUserID,
      userRolesPermissions,
      dropdown_inheritedUserLevelId,
      errorMessages,
      originalUsername,
      profilePictureFileMediaId,
      typeId,
      customUserRolesPermissions,
      removedAssignedClientList,
      isCustomRoleUpdated,
      isProfileImgDelete,
      prevClientList,
      prevShipList,
      removedShips
    } = this.state;

    var hasErrors = this.validateFields();

    if (!hasErrors) {
      this.setState({ isLoading: true, disableSave: true });
      let errors = errorMessages;

      var data = {
        emailAddress: emailAddress,
        tableName: "Users",
        userId: userId,
      };
      var response = await CommonService.CheckIfEmailExists(data);
      if (response != null && response != undefined && response.status == 200) {
        var emailExists = response.data;
        if (emailExists == true) {
          errors["errorEmailAddress"] = "true";
          this.setState({
            toastVisible: true,
            toastMessage: helperText.UserProfile.emailAlreadyExists,
            toastResponseType: helperText.global.toastError,
            errorMessages: errors,
            emailExistError: emailExists,
            isLoading: false,
            disableSave: false,
          });
          return;
        }
      }

      if (
        originalUsername.trim().toLowerCase() != username.trim().toLowerCase()
      ) {
        var data = {
          userName: username,
          tableName: "Users",
          userId: userId,
        };
        var responeData = await CommonService.CheckIfUserNameExist(data);
        if (
          responeData != null &&
          responeData != undefined &&
          responeData.status == 200
        ) {
          var usernameExist = responeData.data;
          if (usernameExist == true) {
            errors["errorUsername"] = "true";
            this.setState({
              toastVisible: true,
              toastMessage: helperText.global.usernameAlreadyExists,
              toastResponseType: helperText.global.toastError,
              errorMessages: errors,
              usernameExistError: usernameExist,
              isLoading: false,
              disableSave: false,
            });
            return;
          }
        }
      }

      let userData = new FormData();

      let assignedClient = dropdown_client == undefined ? [] : dropdown_client;

      let newClients = [];

      newClients = JSON.stringify(dropdown_client);

      if (assignedClient.length > 0) {
        assignedClient = dropdown_client.filter((t) => t.value != "0");
      }

      let assignedShip = dropdown_ship == undefined ? [] : dropdown_ship;

      if (assignedShip.length > 0) {
        assignedShip = assignedShip.filter((t) => t.value != "0");
      }

      //SYJ -08/09/2023- Changes Notification on ship/Client Selected/Removed.
      let prevAssignedClient = prevClientList;
      if (prevAssignedClient.length > 0) {
        prevAssignedClient = prevAssignedClient.filter((prevClients) => prevClients.value != "0")
      }

      let prevAssignedShip = prevShipList;
      if (prevAssignedShip.length > 0) {
        prevAssignedShip = prevAssignedShip.filter((prevShips) => prevShips.value != "0")
      }


      const previousClients = new Map(prevAssignedClient.map((item) => [item.value, item.label]));
      const previousShips = new Map(prevAssignedShip.map((item) => [item.value, item.label]));

      // Find items in currentData that are not present in previousData
      const newClientsSelected = assignedClient.filter(
        (currentItem) => !previousClients.has(currentItem.value)
      ); 
      
      // Find items in currentData that are not present in previousData
      const newShipsSelected = assignedShip.filter(
        (currentItem) => !previousShips.has(currentItem.value)
      ); 

      this.setState({ newAddedClients: newClientsSelected });


      userData.append("UserId", userId);
      userData.append("EmployeeName", employeeName.trim());
      userData.append("ContactNo", contactNo.trim());
      userData.append("EmailAddress", emailAddress.trim());
      userData.append("Address", address.trim());
      userData.append("Designation", designation.trim());
      userData.append("Username", username.trim());
      userData.append("Password", password.trim());
      userData.append("UserRole", dropdown_userRole);
      userData.append("ServiceCategory", dropdown_serviceCategory);
      userData.append("UserLevel", dropdown_userLevel);
      userData.append("Region", dropdown_region);
      userData.append("Client", JSON.stringify(assignedClient));
      userData.append("Ship", JSON.stringify(assignedShip));
      userData.append("LoggedInUserID", loggedInUserID);
      userData.append("CustomUserPermission", customUserRolesPermissions != null && customUserRolesPermissions.length != 0 ? JSON.stringify(customUserRolesPermissions) : null);
      userData.append("InheritedUserLevelId", dropdown_inheritedUserLevelId != "" && dropdown_inheritedUserLevelId != null ? parseInt(dropdown_inheritedUserLevelId, 10) : 0);
      userData.append("ProfilePictureFileMediaId", isProfileImgDelete ? 0 : profilePictureFileMediaId);
      userData.append(
        "RemovedAssignedClientList",
        removedAssignedClientList.length > 0
          ? JSON.stringify(removedAssignedClientList)
          : null
      );
      userData.append("isCustomRoleUpdated", isCustomRoleUpdated);
      userData.append("NewClientsSelected", JSON.stringify(newClientsSelected));
      userData.append("NewShipsSelected", JSON.stringify(newShipsSelected));
      userData.append("ShipsRemoved", JSON.stringify(removedShips));


      let file = documentFile;
      if (
        file != undefined &&
        file != "" &&
        !isProfileImgDelete
      ) {
        userData.append("ProfilePicAttached", file);
      }

      if (isProfileImgDelete) {
        this.deleteProfileImage(this.state.userId, this.state.typeId, this.state.loggedInUserID);
        this.setState({
          isProfileImgDelete: false,
        });
      }

      var response = await UserService.AddEditInternalUser(userData);
      if (response != null && response != undefined && response.data != null) {
        this.setState(
          {
            userId: response.data,
          },
          async () => {
            AddLocalStorageData("isDirty", false);
            this.getDropdownData()
              .then(() => {
                this.loadUserData(response.data, this.state.typeId);
              })
              .then(() => {
                this.getUserAndDefaultAccessPermissions();
              })
              .finally(() => {
                this.setState({ isLoading: false });
              });
            setTimeout(() => {
              this.setState({
                toastVisible: true,
                toastMessage:
                  userId == 0
                    ? helperText.UserProfile.userAdded
                    : helperText.UserProfile.userUpdated,
                toastResponseType: "success",
                disableSave: false,
              });
            }, 1500);
          }
        );

      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: "fail",
          isLoading: false,
          disableSave: false,
        });
      }
    }
  };

  validateFields() {
    const {
      employeeName,
      contactNo,
      emailAddress,
      designation,
      errorMessages,
      username,
      password,
      dropdown_region,
      dropdown_userRole,
      dropdown_serviceCategory,
      dropdown_userLevel,
      customUserRolesPermissions,
    } = this.state;
    let errors = errorMessages;

    var empName = "";
    empName = employeeName;

    errors["errorEmployeeName"] =
      isStringEmpty(employeeName) || empName.trim().length < 3 ? "true" : "";
    errors["errorContactNo"] = isStringEmpty(contactNo) ? "true" : "";
    errors["errorEmailAddress"] = isStringEmpty(emailAddress) ? "true" : "";
    errors["errorDesignation"] = isStringEmpty(designation) ? "true" : "";
    errors["errorUsername"] = isStringEmpty(username) ? "true" : "";
    errors["errorPassword"] =
      isStringEmpty(password) ||
        password.length < 8 ||
        !isPasswordValid(password)
        ? "true"
        : "";
    errors["errorRegion"] =
      dropdown_region == undefined || dropdown_region == 0 ? "true" : "";
    errors["errorUserRole"] =
      dropdown_userRole == undefined || dropdown_userRole == 0 ? "true" : "";
    errors["errorServiceCategory"] =
      dropdown_serviceCategory == undefined || dropdown_serviceCategory == 0
        ? "true"
        : "";
    errors["errorUserLevel"] =
      dropdown_userLevel == undefined ||
        dropdown_userLevel == 0 ||
        (dropdown_userLevel == 4 && customUserRolesPermissions == null)
        ? "true"
        : "";

    if (
      errors["errorContactNo"] != "" ||
      errors["errorEmailAddress"] != "" ||
      errors["errorDesignation"] != "" ||
      errors["errorUsername"] != "" ||
      errors["errorPassword"] != "" ||
      errors["errorRegion"] != "" ||
      errors["errorUserRole"] != "" ||
      errors["errorServiceCategory"] != "" ||
      errors["errorUserLevel"] != ""
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: true,
        shortEmployeeNameError: false,
        showErrorMessageEmail: false,
      });
      return true;
    } else if (!isEmailValid(emailAddress)) {
      errors["errorEmailAddress"] = !isEmailValid(emailAddress) ? "true" : "";
      this.setState({
        errorMessages,
        showErrorMessageEmail: true,
        shortEmployeeNameError: false,
        showErrorMessage: false,
      });
      return true;
    }

    if (errors["errorEmployeeName"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: false,
        shortEmployeeNameError: true,
        showErrorMessageEmail: false,
      });
      return true;
    }

    this.setState({
      errorMessages,
      showErrorMessage: false,
      shortEmployeeNameError: false,
      usernameExistError: false,
      emailExistError: false,
      showErrorMessageEmail: false,
    });
    return false;
  }

  ApplyCustomPermissions = (
    accessPermissions,
    dropdown_inheritedUserLevelId
  ) => {
    const { showErrorMessage, rolesPermissionId } = this.state;

    if (rolesPermissionId != 0) {
      accessPermissions.rolePermissionId = rolesPermissionId;
    }

    this.setState(
      {
        userRolesPermissions: accessPermissions,
        isCustomRoleUpdated: true,
        dropdown_inheritedUserLevelId:
          dropdown_inheritedUserLevelId == undefined
            ? 0
            : dropdown_inheritedUserLevelId,
        customUserRolesPermissions: accessPermissions,
      },
      () => {
        if (showErrorMessage) {
          this.validateFields();
        }
      }
    );

    this.setState({
      rolesPermissionVisible: false,
    });
  };

  closeUserPermissionsModal = () => {
    this.setState({
      rolesPermissionVisible: false,
      viewRolesPermissionVisible: false,
      isCustomRoleUpdated: false,
    });
  };

  onChangePassword = async () => {
    try {
      const { userId } = this.state;
      if (userId == 0) {
        this.setState({
          toastVisible: true,
          toastResponseType: "fail",
          toastMessage: helperText.UserProfile.saveUserDataToChangePassword,
          showConfirmationPopup: false,
        });
        return;
      }
      this.setState({
        isLoading: true,
      });

      var response = await UserService.GenerateNewPassword(userId);
      if (response != null && response != undefined && response.status == 200) {
        this.setState({
          password: response.data,
          toastVisible: true,
          toastResponseType: helperText.global.toastSuccess,
          toastMessage: helperText.Client.clientUpdated,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastResponseType: "fail",
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };

  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onProfilePicChange = async (e) => {
    if (!isValidFile(e.target.value, validFileFormats)) {
      if (this.state.filePath == "") {
        this.setState({
          fileInputKey: generateRandomString,
          documentFile: null,
          errorFile: true,
        });
        AddLocalStorageData("isDirty", true);
      }

      //HV - 06/06/2023 - if after attaching correct file, wrong file is attached then retain the older file but show error message
      this.setState({
        toastVisible: true,
        toastResponseType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileTypeError,
      });

      return;
    }

    let files = e.target.files[0];
    AddLocalStorageData("isDirty", true);
    this.setState({
      errorFile: false,
      documentFile: files,
      profilePicture: URL.createObjectURL(files),
      fileName: files.name,
      filePath: e.target.value,
      isProfileImgDelete: false
    });
  };

  /* <summary>
 date: 14/02/2023
 Name: HV
 description: on username change
 <summary> */

  onUsernameChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage, usernameExistError } = this.state;
    this.setState({ [name]: value }, () => {
      AddLocalStorageData("isDirty", true);
      if (showErrorMessage || usernameExistError) {
        this.validateFields();
      }
    });
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    const {
      showErrorMessage,
      showErrorMessageEmail,
      shortEmployeeNameError,
      emailExistError,
    } = this.state;
    this.setState(
      {
        [name]: value,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (
          showErrorMessage ||
          showErrorMessageEmail ||
          shortEmployeeNameError ||
          emailExistError
        )
          this.validateFields();
      }
    );
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  goback = () => {
    var isDirty = GetLocalStorageDataWithoutParse("isDirty");
    var loc = GetLocalStorageDataWithoutParse("location");
    let path = "";

    if (loc == "/ActivityLog") {
      path = "/ActivityLog";
    }
    else if (loc == "/ActionRequired") {
      path = "/ActionRequired";
    }
    else
      path = "/ManageUsers";

    if (isDirty === "true") {
      this.setState({ showDirtyPopup: true, path: path });
    }
    else {
      this.props.navigate(path);
    }
    // else if (isDirty === "true")
    //   this.setState({ showDirtyPopup: true, path: "/ManageUsers" });
    // else this.props.navigate("/ManageUsers");
  };

  goToRolesPermission = () => {
    const { dropdown_userLevel } = this.state;

    if (dropdown_userLevel == 4)
      this.setState({ rolesPermissionVisible: true, isDataEditable: false });
    else this.setState({ viewRolesPermissionVisible: true });
    // AddLocalStorageData("location", "/AddUser");
    // this.props.navigate("/ManageRolesAndPermission");
  };

  onPhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;

    if (value.trim() == "") {
      this.setState({
        [name]: value,
      });
      AddLocalStorageData("isDirty", true);
    }

    if (NUMBER_REGEX.test(value)) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          AddLocalStorageData("isDirty", true);
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
    });
  };

  showConfirmationPopup = () => {
    this.setState({
      showConfirmationPopup: true,
    });
  };

  /* <summary>
   date: 20/06/2023
   Name: SYJ
   description: Delete profile image
   <summary> */
  onDeleteProfilePic = () => {
    if (!this.state.userRolePermissionRead) {
      this.setState({
        showDeleteProfileModal: true,
      });
    }
  };

  onDeleteProfileModalYesClick = () => {
    AddLocalStorageData("isDirty", true);
    this.setState({
      profilePicture: null,
      isProfileImgDelete: true,
      showDeleteProfileModal: false,
      toastVisible: true,
      toastMessage: helperText.global.deletedProfile,
      toastResponseType: helperText.global.toastSuccess,
    });
  };

  onDeleteProfileModalCloseClick = () => {
    this.setState({
      isProfileImgDelete: false,
      showDeleteProfileModal: false,
    });
  };

  deleteProfileImage = async (userId, userTypeId, loggedUserId) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteProfileModal: false,
      });
      var response = await UserService.DeleteProfileImage(userId, userTypeId, loggedUserId);
      if (response != undefined) {
        AddLocalStorageData("loggedInUserPic", null);
        this.getDropdownData()
          .then(() => {
            this.loadUserData(userId, userTypeId);
          })
          .then(() => {
            this.getUserAndDefaultAccessPermissions();
          })
          .finally(() => {
            this.setState({
              isLoading: false,
              profilePicture: null,
              documentFile: null,
              fileInputKey: generateRandomString(),
            });
          });
      } else {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };

  render() {
    const {
      isLoading,
      rolesPermissionVisible,
      defaultRolesPermissions,
      userRolesPermissions,
      toastVisible,
      toastResponseType,
      toastMessage,
      userId,
      employeeName,
      contactNo,
      emailAddress,
      address,
      dropdown_userLevel,
      designation,
      dropdown_userRole,
      dropdown_serviceCategory,
      profilePicture,
      fileInputKey,
      dropdownList,
      username,
      password,
      errorMessages,
      showPassword,
      dropdown_client,
      dropdown_ship,
      dropdown_region,
      dropdownList_shipList_Filtered,
      clientList,
      showErrorMessage,
      disableSave,
      showErrorMessageEmail,
      showConfirmationPopup,
      shortEmployeeNameError,
      viewRolesPermissionVisible,
      isDataEditable,
      showDeleteProfileModal
    } = this.state;

    const DropdownControl = (props) => {
      return (
        <div>
          <Dropdown
            dropdownLabel={props.dropdownLabel}
            name={props.name}
            selected={props.selected}
            onChange={props.onChange}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "pre",
              overflow: "hidden",
            }}
            disabled={props.disabled}
          >
            {props.list == null
              ? null
              : props.list.map((obj, key) => (
                <option key={key} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            ;
          </Dropdown>
          {/* {props.selected != "" ? ( id={"ddlbl"}
            <UncontrolledTooltip
              autohide={false}
              target={"ddlbl"}
              aria-haspopup="true"
              role="example"
              effect="solid"
              placement="bottom"
              style={{ width: "350px" }}
            >
              {props.list != null && props.list.filter(x => x.value == props.selected)[0]?.label}
            </UncontrolledTooltip>) : null} */}
        </div>
      );
    };

    const ErrorView = (props) => {
      return (
        <>
          {props.errorFor != "" && (
            <div
              className={
                props.errorFor == "emptyDropdown"
                  ? "invalid-dropdown-icon"
                  : "invalid-message-icon"
              }
            >
              <img src="images\icon material-error.png" alt="invalid" />
            </div>
          )}
        </>
      );
    };

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {rolesPermissionVisible && (
          <RolesPermissionModal
            showModal={rolesPermissionVisible}
            userLevelList={dropdownList.userLevel}
            userRolesPermissions={userRolesPermissions}
            defaultRolesPermissions={defaultRolesPermissions}
            ApplyCustomPermissions={this.ApplyCustomPermissions}
            closeModal={this.closeUserPermissionsModal}
            isDataEditable={isDataEditable}
          />
        )}
        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onChangePassword}
            modalText={"change password? An email will be sent to the user with a new password after clicking the Yes button."}
          />
        )}

        {viewRolesPermissionVisible && (
          <UserRolesNPermissionModal
            showModal={viewRolesPermissionVisible}
            closeModal={this.closeUserPermissionsModal}
          />
        )}

        {showDeleteProfileModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteProfileModalCloseClick}
            onYesClick={this.onDeleteProfileModalYesClick}
            onNoClick={this.onDeleteProfileModalCloseClick}
            modalheader="Delete Profile Image"
          ></DeleteModal>
        )}

        <main className="page-height">
          <section className="communications manage-users">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 heading-bottom-space">
                  <div className="manage-users-title">
                    <img
                      className="back-btn-responsive"
                      src="images/back button image.png"
                      height="22px"
                      style={{ pointerEvents: "all" }}
                      onClick={this.goback}
                    />
                    <span className="title-font">
                      {userId == 0
                        ? "Add Internal User Details"
                        : "Edit Internal User Details"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="add-internal-class">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="admin-profile-detail-img edit-user-profile-img">
                      <div className="admin-profile-detail-bg-img">
                        {profilePicture == "" || profilePicture == undefined ? (
                          <img
                            src={"images/blankProfileImage.png"}
                            //alt="img-profile"
                            className="admin-profile"
                            style={{ objectFit: "contain" }} //SYJ - Fit image to the Contain Fix for https://jiraapp.edotteam.com/browse/IHMM-114
                          />
                        ) : (
                          <img
                            src={profilePicture}
                            alt="img-profile"
                            className="admin-profile"
                            style={{ objectFit: "contain" }}
                          />
                        )}

                        {profilePicture != "" && profilePicture != undefined && profilePicture != null ? (
                          <div className="admin-profile-icon">
                            <label style={{ left: "66%", top: "53%" }}>
                              <img
                                title={"Delete"}
                                src={deleteIcon}
                                alt="profile-delete"
                                className="img-fluid cursor-class test-Pankaj"
                                style={{ width: "15px", height: "20px", opacity: this.state.userRolePermissionRead ? "0.6" : "1", }}
                                onClick={this.onDeleteProfilePic}
                                disabled={this.state.userRolePermissionRead}
                              />
                            </label>
                          </div>) : null}
                        <div className="admin-profile-icon">
                          <label htmlFor="myfile">
                            <img
                              title={
                                profilePicture == "" ||
                                  profilePicture == undefined
                                  ? "Add"
                                  : "Edit"
                              }
                              src={
                                profilePicture == "" ||
                                  profilePicture == undefined
                                  ? addProfileIcon
                                  : editIcon
                              }
                              alt="profile-edit "
                              className="img-fluid cursor-class"
                              style={{
                                opacity: this.state.userRolePermissionRead
                                  ? "0.6"
                                  : "1",
                              }}
                            />
                          </label>
                          <input
                            type="file"
                            accept={validFileFormatsReadable}
                            id="myfile"
                            name="myfile"
                            onChange={this.onProfilePicChange}
                            key={fileInputKey || ""}
                            style={{ display: "none", visibility: "none" }}
                            disabled={this.state.userRolePermissionRead}
                          />
                        </div>
                      </div>
                      <div style={{ minHeight: 38 }}>
                        <h2>{employeeName} </h2>
                      </div>
                    </div>
                    <div className="admin-profile-login-detail admin-supplier-login-detail">
                      <UserCredentials
                        userId={userId}
                        username={username}
                        password={password}
                        name="username"
                        passwordName="password"
                        onPasswordButtonClick={this.showConfirmationPopup}
                        showPassword={showPassword}
                        togglePassword={this.togglePassword}
                        label={"Change Password"}
                        onTextChange={this.onUsernameChange}
                        errorPassword={errorMessages.errorPassword}
                        errorUsername={errorMessages.errorUsername}
                        disabled={this.state.userRolePermissionRead}
                        disabledButton={userId == 0 || this.state.userRolePermissionRead}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="editinternaldetails">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-6"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Employee Name:
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.errorEmployeeName
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    autoFocus={false}
                                    autoComplete="new-password"
                                    className="form-control"
                                    type="text"
                                    id="Employee Name"
                                    name="employeeName"
                                    value={employeeName}
                                    onChange={this.onTextChange}
                                    maxLength={100}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.errorEmployeeName
                                  ) && <ErrorView errorFor="emptyText" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-6"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Contact Number:
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    !isStringEmpty(errorMessages.errorContactNo)
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    autoFocus={false}
                                    autoComplete="new-password"
                                    className="form-control"
                                    type="text"
                                    id="contactNo"
                                    name="contactNo"
                                    placeholder=""
                                    value={contactNo}
                                    maxLength={20}
                                    onChange={this.onPhoneNumberChange}
                                    disabled={this.state.userRolePermissionRead}
                                  //onChange={this.onTextChange}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.errorContactNo
                                  ) && <ErrorView errorFor="emptyText" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Email Address:
                                </h5>
                              </div>
                              <div className="col-md-9">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.errorEmailAddress
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    autoFocus={false}
                                    autoComplete="new-password"
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    name="emailAddress"
                                    value={emailAddress}
                                    onChange={this.onTextChange}
                                    maxLength={100}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.errorEmailAddress
                                  ) && <ErrorView errorFor="emptyText" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5 className={"label-class"}>Address:</h5>
                              </div>
                              <div className="col-md-9">
                                <textarea
                                  className="multi-line-text text-area-class add-user-fields"
                                  rows="3"
                                  name="address"
                                  autoComplete="new-password"
                                  onChange={this.onTextChange}
                                  value={address}
                                  maxLength={250}
                                  disabled={this.state.userRolePermissionRead}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-6"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Region:
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    !isStringEmpty(errorMessages.errorRegion)
                                      ? "invalid-position"
                                      : ""
                                  }
                                  id={"region"}
                                >
                                  <DropdownControl
                                    dropdownLabel=""
                                    selected={dropdown_region}
                                    list={dropdownList.regionList}
                                    name="region"
                                    onChange={this.onDropdownSelect}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {dropdown_region > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"region"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {dropdownList.regionList != null && dropdownList.regionList.filter(x => x.value == dropdown_region)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                  {!isStringEmpty(
                                    errorMessages.errorRegion
                                  ) && <ErrorView errorFor="emptyDropdown" />}
                                  {/* <select className="form-select custom-dropdown-class history-from-drpdown add-user-fields" aria-label="Default select example">
																	<option selected></option>
																	<option value="1">Level 1</option>
																	<option value="2">Level 2</option>
																	<option value="3">Level 3</option>
																</select> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-6"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  User Role:
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    !isStringEmpty(errorMessages.errorUserRole)
                                      ? "invalid-position"
                                      : ""
                                  }
                                  id={"role"}
                                >
                                  <DropdownControl
                                    dropdownLabel=""
                                    selected={dropdown_userRole}
                                    list={dropdownList.userRole}
                                    name="userRole"
                                    onChange={this.onDropdownSelect}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {dropdown_userRole > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"role"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {dropdownList.userRole != null && dropdownList.userRole.filter(x => x.value == dropdown_userRole)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                  {!isStringEmpty(
                                    errorMessages.errorUserRole
                                  ) && <ErrorView errorFor="emptyDropdown" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-6"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Designation:
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.errorDesignation
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    autoFocus={false}
                                    autoComplete="new-password"
                                    className="form-control"
                                    type="text"
                                    id="designation"
                                    name="designation"
                                    value={designation}
                                    onChange={this.onTextChange}
                                    maxLength={100}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.errorDesignation
                                  ) && <ErrorView errorFor="emptyText" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-6"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Service Category:
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.errorServiceCategory
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                  id={"sc"}
                                >
                                  <DropdownControl
                                    dropdownLabel=""
                                    selected={dropdown_serviceCategory}
                                    list={dropdownList.serviceCategories}
                                    name="serviceCategory"
                                    onChange={this.onDropdownSelect}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {dropdown_serviceCategory > 0 ? (
                                    <UncontrolledTooltip
                                      autohide={false}
                                      target={"sc"}
                                      aria-haspopup="true"
                                      role="example"
                                      effect="solid"
                                      placement="bottom"
                                      style={{ width: "350px" }}
                                    >
                                      {dropdownList.serviceCategories != null && dropdownList.serviceCategories.filter(x => x.value == dropdown_serviceCategory)[0]?.label}
                                    </UncontrolledTooltip>) : null}
                                  {!isStringEmpty(
                                    errorMessages.errorServiceCategory
                                  ) && <ErrorView errorFor="emptyDropdown" />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6"></div>
                        <div className="col-md-12">
                          <div className="pb-3">
                            <div className="ship-detail-pb">
                              <div className="row">
                                <div className="col-md-3 align-self-class"
                                  style={{ alignSelf: "center" }}>
                                  <h5
                                    className={
                                      this.state.userRolePermissionRead
                                        ? "label-class"
                                        : "label-class required-field"
                                    }
                                  >
                                    Select Level:
                                  </h5>
                                </div>
                                <div className="col-xl-3 col-md-4">
                                  <div
                                    className={
                                      !isStringEmpty(
                                        errorMessages.errorUserLevel
                                      )
                                        ? "invalid-position"
                                        : ""
                                    }
                                    id={"level"}
                                  >
                                    <DropdownControl
                                      dropdownLabel=""
                                      selected={dropdown_userLevel}
                                      name="userLevel"
                                      list={dropdownList.userLevel}
                                      onChange={this.onDropdownSelect}
                                      disabled={
                                        this.state.userRolePermissionRead
                                      }
                                    />

                                    {dropdown_userLevel > 0 ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"level"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {dropdownList.userLevel != null && dropdownList.userLevel.filter(x => x.value == dropdown_userLevel)[0]?.label}
                                      </UncontrolledTooltip>) : null}

                                    {!isStringEmpty(
                                      errorMessages.errorUserLevel
                                    ) && <ErrorView errorFor="emptyDropdown" />}
                                  </div>
                                </div>
                                {dropdown_userLevel == 4 &&
                                  !this.state.userRolePermissionRead ? (
                                  <div className="col-xl-6 col-md-5"
                                    style={{ alignSelf: "center" }}>
                                    <button
                                      type="button"
                                      className="btn roles-permissions-btn"
                                      disabled={disableSave}
                                      onClick={() =>
                                        this.getUserAndDefaultAccessPermissions(
                                          true
                                        )
                                      }
                                    >
                                      Set Roles &amp; Permissions
                                    </button>
                                  </div>
                                ) : (
                                  <div className="col-xl-6 col-md-5"
                                    style={{ alignSelf: "center" }}>
                                    <a
                                      onClick={() => this.goToRolesPermission()}
                                    >
                                      View Roles and Permissions
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5 className={"label-class"}>
                                  Assign Client:
                                </h5>
                              </div>

                              <div className="col-md-9">
                                <MultiSelectDropdown
                                  selectedOptions={
                                    dropdown_client == undefined
                                      ? []
                                      : dropdown_client
                                  }
                                  options={
                                    clientList == undefined ? [] : clientList
                                  }
                                  onChange={this.onClientDropdownChange}
                                  isMulti={true}
                                  disabled={this.state.userRolePermissionRead}
                                />
                              </div>
                              <div className="col-md-3"></div>
                              {/* <div className="col-12 col-md-9">
																<div className="display-btn-class">
																	{dropdown_client != undefined && (
																		dropdown_client.map((item) => {
																			return (
																				<span className="assign-client-display">
																					{item.label}
																					<img className="close-img" src="images/close-icon.png" width="21px" style={{ pointerEvents: "all" }} onClick={() => this.onRemoveCapsule(item.value, "dropdown_client")} />
																				</span>
																			)

																		})

																	)}
																</div>
															</div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5 className={"label-class"}>Assign Ship:</h5>
                              </div>
                              <div className="col-md-9">
                                <MultiSelectDropdown
                                  selectedOptions={
                                    dropdown_ship == undefined
                                      ? []
                                      : dropdown_ship
                                  }
                                  options={
                                    dropdownList_shipList_Filtered == undefined
                                      ? []
                                      : dropdownList_shipList_Filtered
                                  }
                                  onChange={this.onShipDropdownChange}
                                  isMulti={true}
                                  disabled={this.state.userRolePermissionRead}
                                />
                              </div>
                              <div className="col-md-3"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 otherprofile-fixed">
                          {showErrorMessage && (
                            <div className="invalid-message-icon-box">
                              <img
                                src="images/icon material-error.png"
                                alt="invalid-icon"
                              />
                              <p>Please fill in all the mandatory fields.</p>
                            </div>
                          )}
                          {showErrorMessageEmail && (
                            <div className="invalid-message-icon-box">
                              <img
                                src="images/icon material-error.png"
                                alt="invalid-icon"
                              />
                              <p>Please enter valid email address.</p>
                            </div>
                          )}
                          {shortEmployeeNameError && (
                            <div className="invalid-message-icon-box">
                              <img
                                src="images/icon material-error.png"
                                alt="invalid-icon"
                              />
                              <p>
                                Employee Name must be atleast 3 characters long.
                              </p>
                            </div>
                          )}
                          <div className="admin-ship-settings-save-btn text-center save-btn-row">
                            <button
                              type="button"
                              className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn"
                              onClick={this.onSave}
                              disabled={
                                disableSave || this.state.userRolePermissionRead
                              }
                            >
                              Save
                            </button>
                            {userId > 0 ? (
                              <button
                                type="button"
                                className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive add-user-btn manage-users-btn"
                                onClick={this.onSendEmail}
                                disabled={
                                  disableSave ||
                                  this.state.userRolePermissionRead
                                }
                              >
                                Send Email
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.showDirtyPopup && (
                <DirtyModal
                  onModalCloseClick={this.onDirtyModalCloseClick}
                  onProceedClick={this.onProceedClick}
                />
              )}
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
export default withRouter(AddUser);
