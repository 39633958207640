import React, { Component } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Switch from "react-switch";
import { ClientService } from "../../services/client.service";
import { GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, helperText } from "../../helpers";
import Toast from "../../components/Toast";
import { withRouter } from "../../helpers";
import { TitlePageNames, UserRole } from "../../helpers/enums";

const defaultArray = {
  shipParticularsAccess: 0,
  trackingToolAccess: 0,
  reportsAccess: 0,
  statisticsAccess: 0,
  trainingAccess: 0,
  addClientAccess: 0,
  documentsAccess: 0,
  clientRoleId: 0,
};

class ManageRolesAndPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      accessPermissionsFleetAdmin: [],
      accessPermissionsFleetManager: [],
      accessPermissionsSuperintendent: [],
      accessPermissionsOthers: [],
      loggedInUserID: 0,
      toastVisible: false,
      toastResponseType: "",
      isClient: false,
      validateError: false,
    };
  }

  /* <summary>
    date: 14/1/2023
    Name: AN
    description: Get data to bind on the manage client roles and permission screen
    <summary> */
  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.CLIENTROLESANDPERMISSION);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.getDefaultAccessPermissions();
    }
  }

  /* <summary>
    date: 14/1/2023
    Name: AN
    description: Get Default permissions of Client
    <summary> */
  // HV - 25/01/2023 - fixed default array passing issue
  getDefaultAccessPermissions = async () => {
    var clientData = GetLocalStorageDataWithParse("loggedInUser");
    this.setState({
      isLoading: false,
      loggedInUserID: clientData.id,
      isClient: clientData.typeId == UserRole.CLIENT,
    });
    const response = await ClientService.GetDefaultPermissions();
    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data.length > 0) {
        this.setState({
          accessPermissionsFleetAdmin: response.data[0],
          accessPermissionsFleetManager: response.data[1],
          accessPermissionsSuperintendent: response.data[2],
          accessPermissionsOthers: response.data[3],
          isLoading: false,
        });
      } else {
        this.setState({
          accessPermissionsFleetAdmin: { ...defaultArray, clientRoleId: 1 },
          accessPermissionsFleetManager: { ...defaultArray, clientRoleId: 2 },
          accessPermissionsSuperintendent: { ...defaultArray, clientRoleId: 3 },
          accessPermissionsOthers: { ...defaultArray, clientRoleId: 4 },
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  /* <summary>
    date: 14/1/2023
    Name: AN
    description: On click switch button it changes the access permission status of the client  
    <summary> */
  onSwitchToggle = (checked, e, id) => {
    var switchOperation = id.split("_")[0];
    var switchId = id.split("_")[1];
    var levelId = id.split("_")[2];
    var {
      accessPermissionsFleetAdmin,
      accessPermissionsFleetManager,
      accessPermissionsSuperintendent,
      accessPermissionsOthers,
    } = this.state;
    switchId = switchId.replace(levelId, "");
    if (levelId.includes("FleetAdmin")) {
      accessPermissionsFleetAdmin[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsFleetAdmin,
      });
    } else if (levelId.includes("FleetManager")) {
      accessPermissionsFleetManager[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsFleetManager,
      });
    } else if (levelId.includes("Superintendent")) {
      accessPermissionsSuperintendent[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsSuperintendent,
      });
    } else if (levelId.includes("Others")) {
      accessPermissionsOthers[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsOthers,
      });
    }
    this.isValidate(2);
  };


  isValidate = (param) => {
    var {
      accessPermissionsFleetAdmin,
      accessPermissionsFleetManager,
      accessPermissionsSuperintendent,
      accessPermissionsOthers,
    } = this.state;

    var hasErrors = false;

    if (
      accessPermissionsFleetAdmin["shipParticularsAccess"] == 0 || accessPermissionsFleetAdmin["shipParticularsAccess"] == null ||
      accessPermissionsFleetAdmin["trackingToolAccess"] == 0 || accessPermissionsFleetAdmin["trackingToolAccess"] == null ||
      accessPermissionsFleetAdmin["reportsAccess"] == 0 || accessPermissionsFleetAdmin["reportsAccess"] == null ||
      accessPermissionsFleetAdmin["statisticsAccess"] == 0 || accessPermissionsFleetAdmin["statisticsAccess"] == null ||
      accessPermissionsFleetAdmin["trainingAccess"] == 0 || accessPermissionsFleetAdmin["trainingAccess"] == null ||
      accessPermissionsFleetAdmin["addClientAccess"] == 0 || accessPermissionsFleetAdmin["addClientAccess"] == null ||
      accessPermissionsFleetAdmin["documentsAccess"] == 0 || accessPermissionsFleetAdmin["documentsAccess"] == null ||
      accessPermissionsFleetManager["shipParticularsAccess"] == 0 || accessPermissionsFleetManager["shipParticularsAccess"] == null ||
      accessPermissionsFleetManager["trackingToolAccess"] == 0 || accessPermissionsFleetManager["trackingToolAccess"] == null ||
      accessPermissionsFleetManager["reportsAccess"] == 0 || accessPermissionsFleetManager["reportsAccess"] == null ||
      accessPermissionsFleetManager["statisticsAccess"] == 0 || accessPermissionsFleetManager["statisticsAccess"] == null ||
      accessPermissionsFleetManager["trainingAccess"] == 0 || accessPermissionsFleetManager["trainingAccess"] == null ||
      accessPermissionsFleetManager["addClientAccess"] == 0 || accessPermissionsFleetManager["addClientAccess"] == null ||
      accessPermissionsFleetManager["documentsAccess"] == 0 || accessPermissionsFleetManager["documentsAccess"] == null ||
      accessPermissionsSuperintendent["shipParticularsAccess"] == 0 || accessPermissionsSuperintendent["shipParticularsAccess"] == null ||
      accessPermissionsSuperintendent["trackingToolAccess"] == 0 || accessPermissionsSuperintendent["trackingToolAccess"] == null ||
      accessPermissionsSuperintendent["reportsAccess"] == 0 || accessPermissionsSuperintendent["reportsAccess"] == null ||
      accessPermissionsSuperintendent["statisticsAccess"] == 0 || accessPermissionsSuperintendent["statisticsAccess"] == null ||
      accessPermissionsSuperintendent["trainingAccess"] == 0 || accessPermissionsSuperintendent["trainingAccess"] == null ||
      accessPermissionsSuperintendent["addClientAccess"] == 0 || accessPermissionsSuperintendent["addClientAccess"] == null ||
      accessPermissionsSuperintendent["documentsAccess"] == 0 || accessPermissionsSuperintendent["documentsAccess"] == null ||
      accessPermissionsOthers["shipParticularsAccess"] == 0 || accessPermissionsOthers["shipParticularsAccess"] == null ||
      accessPermissionsOthers["trackingToolAccess"] == 0 || accessPermissionsOthers["trackingToolAccess"] == null ||
      accessPermissionsOthers["reportsAccess"] == 0 || accessPermissionsOthers["reportsAccess"] == null ||
      accessPermissionsOthers["statisticsAccess"] == 0 || accessPermissionsOthers["statisticsAccess"] == null ||
      accessPermissionsOthers["trainingAccess"] == 0 || accessPermissionsOthers["trainingAccess"] == null ||
      accessPermissionsOthers["addClientAccess"] == 0 || accessPermissionsOthers["addClientAccess"] == null ||
      accessPermissionsOthers["documentsAccess"] == 0 || accessPermissionsOthers["documentsAccess"] == null
    ) {
      hasErrors = true;
    } else hasErrors = false;

    if (param == 1 || !hasErrors)
      this.setState({ validateError: hasErrors });

    return hasErrors;
  }

  /* <summary>
    date: 14/1/2023
    Name: AN
    description: Saves the access permission status of the client  
    HV - 19/01/2023 - some field was matching with db entries 
    <summary> */
  onSave = async () => {
    var {
      accessPermissionsFleetAdmin,
      accessPermissionsFleetManager,
      accessPermissionsSuperintendent,
      accessPermissionsOthers,
      userId,
      loggedInUserID,
      isClient
    } = this.state;

    var hasErrors = this.isValidate(1);
    if (!hasErrors) {
      this.setState({ isLoading: false });
      var accessPermissions = [];
      accessPermissions.push(accessPermissionsFleetAdmin);
      accessPermissions.push(accessPermissionsFleetManager);
      accessPermissions.push(accessPermissionsSuperintendent);
      accessPermissions.push(accessPermissionsOthers);
      var data = {
        accessPermissions: accessPermissions,
        loggedInUserId: loggedInUserID,
        isClient: isClient,
      };
      var response = await ClientService.SaveDefaultPermissions(data);
      if (response != null && response != undefined && response.status == 200) {
        this.setState(
          {
            toastVisible: true,
            toastMessage: helperText.global.saveSuccess,
            toastResponseType: "success",
            isLoading: false,
          },
          () => {
            // setTimeout(() => {
            //   this.goback();
            // }, 1000);
          }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: "fail",
          isLoading: false,
        });
      }
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    }
    else if (loc == "/AddEditClientUser") {
      this.props.navigate("/AddEditClientUser");
    } else if (loc == "/AddEditClient") {
      this.props.navigate("/AddEditClient");
    } else {
      this.state.isClient
        ? this.props.navigate("/ManageClientUsers")
        : this.props.navigate("/ManageClient");
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastResponseType,
      toastMessage,
      accessPermissionsFleetAdmin,
      accessPermissionsFleetManager,
      accessPermissionsSuperintendent,
      accessPermissionsOthers,
      validateError,
    } = this.state;

    const SwitchControl = (props) => {
      return (
        <td className="center">
          <Switch
            id={props.id}
            disabled={this.state.isClient || props.disabled}
            className="switch"
            height={20}
            width={40}
            checked={props.Ischecked}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={"#C90000"}
            onChange={(checked, e, id) => this.onSwitchToggle(checked, e, id)}
          />
        </td>
      );
    };
    const SectionRow = (props) => {
      return (
        <tr style={{ backgroundColor: props.permissionAccess == 0 || props.permissionAccess == null ? "#ff3b3b" : "" }}>
          <SwitchControl
            id={`read_${props.id}`}
            Ischecked={props.permissionAccess == 1}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />{""}
          {/*Read */}
          <SwitchControl
            id={`readWrite_${props.id}`}
            Ischecked={props.permissionAccess == 2}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
            disabled={props.disabled}
          />
          {/*Read and Write */}
          <SwitchControl
            id={`deny_${props.id}`}
            Ischecked={props.permissionAccess == 3}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Deny */}
        </tr>
      );
    };

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="communications role-permissions-class client-roles-permissions-class">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 heading-bottom-space">
                  <img
                    className="back-btn-responsive"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all" }}
                    onClick={this.goback}
                  />
                  <span className="title-font">{this.state.isClient ? "" : "Client "}Roles and Permissions</span>
                </div>
              </div>
              <div className="row  levels-table">
                <div className="col-12 col-md-12 col-lg-12 table-left-padding table-pl-responsive">
                  <div className="level-boxes client-level-boxes">
                    <div className="level-boxes-spacing mobile-level-box">
                      <h5 className="level-heading"> Levels:</h5>
                      <table className="table table-bordered manage-users-table roles-permissions-tbl client-roles-permissions-tbl">
                        <thead className="level-table-header-color">
                          <tr>
                            <th className="table-one-text">Roles</th>
                          </tr>
                        </thead>
                        <tbody className="table-one-body">
                          <tr>
                            <td className="roles-table-heading">Ship Particulars</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Tracking Tool</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Reports</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Statistics</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Training</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Add Client User</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Documents</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="table-responsive d-flex">
                      <div className="level-boxes-spacing mobile-level-box1">
                        <h5 className="level-heading">Fleet Admin:</h5>
                        <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                          <thead className="level-table-header-color">
                            <tr>
                              <th className="table-header-text">Read</th>
                              <th className="table-header-text client-roles-header-text">
                                <div className="tooltip p-0">R & W
                                  <span className="tooltiptext">Read & Write</span>
                                </div>
                              </th>
                              <th className="table-header-text">Deny</th>
                            </tr>
                          </thead>
                          <tbody className="table-one-body">
                            <SectionRow
                              id="shipParticularsAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.shipParticularsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trackingToolAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.trackingToolAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="reportsAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.reportsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="statisticsAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.statisticsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trainingAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.trainingAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="addClientAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.addClientAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                            <SectionRow
                              id="documentsAccess_FleetAdmin"
                              permissionAccess={
                                accessPermissionsFleetAdmin.documentsAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                          </tbody>
                        </table>
                      </div>
                      <div className="level-boxes-spacing">
                        <h5 className="level-heading">Fleet Manager:</h5>
                        <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                          <thead className="level-table-header-color">
                            <tr>
                              <th className="table-header-text">Read</th>
                              <th className="table-header-text client-roles-header-text">
                                <div className="tooltip p-0">R & W
                                  <span className="tooltiptext">Read & Write</span>
                                </div>
                              </th>
                              <th className="table-header-text">Deny</th>
                            </tr>
                          </thead>
                          <tbody className="table-one-body">
                            <SectionRow
                              id="shipParticularsAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.shipParticularsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trackingToolAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.trackingToolAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="reportsAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.reportsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="statisticsAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.statisticsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trainingAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.trainingAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="addClientAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.addClientAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                            <SectionRow
                              id="documentsAccess_FleetManager"
                              permissionAccess={
                                accessPermissionsFleetManager.documentsAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                          </tbody>
                        </table>
                      </div>
                      <div className="level-boxes-spacing">
                        <h5 className="level-heading">Superintendent:</h5>
                        <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                          <thead className="level-table-header-color">
                            <tr>
                              <th className="table-header-text">Read</th>
                              <th className="table-header-text client-roles-header-text">
                                <div className="tooltip p-0">R & W
                                  <span className="tooltiptext">Read & Write</span>
                                </div>
                              </th>
                              <th className="table-header-text">Deny</th>
                            </tr>
                          </thead>
                          <tbody className="table-one-body">
                            <SectionRow
                              id="shipParticularsAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.shipParticularsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trackingToolAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.trackingToolAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="reportsAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.reportsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="statisticsAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.statisticsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trainingAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.trainingAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="addClientAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.addClientAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                            <SectionRow
                              id="documentsAccess_Superintendent"
                              permissionAccess={
                                accessPermissionsSuperintendent.documentsAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                          </tbody>
                        </table>
                      </div>
                      <div className="level-boxes-spacing">
                        <h5 className="level-heading">Others:</h5>
                        <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                          <thead className="level-table-header-color">
                            <tr>
                              <th className="table-header-text">Read</th>
                              <th className="table-header-text client-roles-header-text">
                                <div className="tooltip p-0">R & W
                                  <span className="tooltiptext">Read & Write</span>
                                </div>
                              </th>
                              <th className="table-header-text">Deny</th>
                            </tr>
                          </thead>
                          <tbody className="table-one-body">
                            <SectionRow
                              id="shipParticularsAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.shipParticularsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trackingToolAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.trackingToolAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="reportsAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.reportsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="statisticsAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.statisticsAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="trainingAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.trainingAccess
                              }
                              precedingPermission={""}
                              disabled={true}
                            />
                            <SectionRow
                              id="addClientAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.addClientAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                            <SectionRow
                              id="documentsAccess_Others"
                              permissionAccess={
                                accessPermissionsOthers.documentsAccess
                              }
                              precedingPermission={""}
                              disabled={false}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {validateError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                <div className="admin-ship-settings-save-btn text-center">
                  {this.state.isClient ? null : (
                    <button
                      type="button"
                      className="btn btn-background-color save-btn"
                      onClick={this.onSave}
                    >Save</button>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
export default withRouter(ManageRolesAndPermission);
