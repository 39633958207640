import React, { Component } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { LoginService } from "../../services/login.service";
import { changePageTitle, helperText } from "../../helpers";
import DOMPurify from "dompurify";

var scrollValue = 0;
class TermsAndCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      data: [],
      activeId: 0,
    };
  }

  componentDidMount() {
    changePageTitle("");
    this.getTermsAndConditionData();
  }

  getTermsAndConditionData = async () => {
    try {
      this.setState({ isLoading: true }, async () => {
        const response = await LoginService.GetTermsAndConditionData();
        if (response.status === 200) {
          this.setState({ data: response.data.result, isLoading: false });
        } else {
          this.setState({
            isLoading: false,
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* <summary>
    date: 12-12-2022
    Name: VD
    description: This is used to close the Toast once it has been opened
    <summary>*/
  closeToast = () => {
    this.setState({
      toastVisible: false,
    });
  };

  /* <summary>
date: 23-2-2023
Name: VK
description: scroll
<summary>*/
  createScrollHandler = async (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    this.setState({
      activeId: id,
    });
  };

  render() {
    const { isLoading, toastVisible, toastType, toastMessage, activeId } =
      this.state;
    const { data } = this.state;

    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header componentFor="TermsAndCondition" />
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div className="admin-main-body contact-us-body-header" id="parentDiv">
          <div className="terms-condition">
            <div className="container-fluid">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="container">
              <h3>
                Terms and Conditions
                <hr />
              </h3>
            </div>
          </div>
          <section className="privacy-policy">
            <article className="timeline-pdpa">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <nav className="timeline__nav timeline-top-space">
                      <ul>
                        {Array.isArray(data) &&
                          data.map((item) => {
                            return (
                              <li
                                className={`scroll-bar ${
                                  activeId ==
                                  "terms-condition-" + item.termsNconditionsId
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.createScrollHandler(
                                    "terms-condition-" + item.termsNconditionsId
                                  )
                                }
                                key={item.termsNconditionsId}
                              >
                                <span>{item.headerName}</span>
                              </li>
                            );
                          })}
                      </ul>
                    </nav>
                  </div>
                  <div className="col-md-8">
                    <section className="timeline__section">
                      <div className="wrapper">
                        {Array.isArray(data) &&
                          data.map((item) => {
                            return (
                              <div
                                id={
                                  "terms-condition-" + item.termsNconditionsId
                                }
                                key={item.termsNconditionsId}
                                
                              >
                                <h4 className="milestone">{item.headerName}</h4>
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text)}}/>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TermsAndCondition;
