import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import { dashboardservice } from "../../services/dashboard.service";
import { UserAction, UserRole } from "../../helpers/enums";
import Footer from "../../components/Footer";
import DropdownWithoutSelectOption from "../../components/DropdownWithoutSelectOption";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";

const defaultAllItem = { label: "All", value: 0 };
class ClientDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toastVisible: false,
            isLoading: false,
            toastType: "",
            toastMessage: "",
            accessList: [],
            shipList: [],
            dropdown_ship: [],
            totalClientShips: 0,
            totalClientPOItems: 0,
            totalClientUsers: 0,
            userId: 0,
            clientName: "",
            isClient: false,
            isClientUser: false,
            clientId: 0,
            dropdown_shipValue: 0,
        };
    }

    componentDidMount = () => {

        var userData = GetLocalStorageDataWithParse("loggedInUser");
        //HV - 20/02/2023 - changing browser title name 
        changePageTitle("");
        //HV - 17/02/2023 - handled browser back button
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
        if (userData != null) {
            this.setState({ userId: userData.id, clientName: userData.name, isClient: userData.typeId == UserRole.CLIENT, isClientUser: userData.typeId == UserRole.CLIENTUSER }, () => {
                AddLocalStorageData("loggedInUserName", userData.name)
                this.getClientRolePermissions(userData);
            })
        }

    };

    /* <summary>
    date: 27/02/2023
    Name: HV
    description: This is used to get all the count on dashboard page
    <summary>*/
    GetClientDashboardInfo = async (userData) => {

        this.setState({ isLoading: true });
        //A.N. - 06/03/2023 - handled for client login
        let clientData = {
            clientId: parseInt(userData.id, 10),
            clientName: userData.name
        }
        AddLocalStorageData("selectedClient", JSON.stringify(clientData));

        var response = await dashboardservice.GetClientDashboardInfo(userData.id, parseInt(this.state.dropdown_shipValue, 10));
        if (response.status == 200 && response.data != null) {

            let clientDashboardData = response.data;

            var appList = clientDashboardData.shipList;
            var checkIfAllValueExists = appList.filter(x => x.label == "All").length > 0;
            if (!checkIfAllValueExists) {
                appList.unshift(defaultAllItem);
            }
            this.setState({
                totalClientShips: clientDashboardData.totalClientShips,
                totalClientUsers: clientDashboardData.totalClientUsers,
                totalClientPOItems: clientDashboardData.totalClientPOItems,
                shipList: appList,
                dropdown_ship: this.state.dropdown_ship.length == 0 ? defaultAllItem : this.state.dropdown_ship,
                dropdown_shipValue: this.state.dropdown_ship.length == 0 ? (clientDashboardData.clientDefaultShipId == null ? 0 : clientDashboardData?.clientDefaultShipId) : this.state.dropdown_shipValue,
                isLoading: false,
            }, () => {
                AddLocalStorageData("selectClienDashboardShip", parseInt(this.state.dropdown_shipValue, 10));
            });
        } else if (response.status == 401) {
            Logout(this.props.navigate);
            this.setState({ isLoading: false });
        }
    };

    /* <summary>
     date: 15/03/2023
     Name: HV
     description: This is used to get all the count on dashboard page for client user
     <summary>*/
    GetClientUserDashboardInfo = async (clientUserId, clientId) => {

        this.setState({ isLoading: true });
        var response = await dashboardservice.GetClientUserDashboardInfo(clientUserId, clientId, parseInt(this.state.dropdown_shipValue, 10));
        if (response.status == 200 && response.data != null) {
            let clientDashboardData = response.data;

            let clientData = {
                clientId: parseInt(clientId, 10),
                clientName: clientDashboardData?.clientName
            }
            AddLocalStorageData("selectedClient", JSON.stringify(clientData));

            this.setState({
                totalClientShips: clientDashboardData?.totalClientShips,
                totalClientUsers: clientDashboardData?.totalClientUsers,
                totalClientPOItems: clientDashboardData?.totalClientPOItems,
                shipList: clientDashboardData.shipList,
                dropdown_ship: this.state.dropdown_ship.length == 0 ? defaultAllItem : this.state.dropdown_ship,
                dropdown_shipValue: this.state.dropdown_ship.length == 0 ? (clientDashboardData.clientDefaultShipId == null ? 0 : clientDashboardData?.clientDefaultShipId) : this.state.dropdown_shipValue,
                clientId: clientId,
                isLoading: false
            }, () => {
                AddLocalStorageData("selectClienDashboardShip", parseInt(this.state.dropdown_shipValue, 10));
            });
        } else if (response.status == 401) {
            Logout(this.props.navigate);
            this.setState({ isLoading: false });
        }
    };

    /* <summary>
      date: 28-02-2023
      Name: HV
      description: This is used to the roles and permission for the logged in client
      <summary>*/
    getClientRolePermissions = async (userData) => {
        this.setState({ isLoading: true })

        const { isClient, isClientUser } = this.state;

        let clientId = isClient ? userData.id : userData.clientId;
        let clientUserId = isClientUser ? userData.id : 0;

        var response = await dashboardservice.GetClientPermissionDashBoard(clientId, clientUserId)
        if (response.status == 200 && response.data != null) {
            this.setState({
                accessList: response.data.clientRoleId == 5 ? response.data.listCustomClientRolePermissions : response.data.listDefaultClientRolePermission,
            }, () => {
                //A.N. - 06/03/2023 - handled for manage ships
                AddLocalStorageData("userRolePermission", JSON.stringify(this.state.accessList));

                if (this.state.isClient) {
                    this.GetClientDashboardInfo(userData);
                }
                else {
                    this.GetClientUserDashboardInfo(clientUserId, clientId);
                }
            });
        } else if (response.status == 401) {
            Logout(this.props.navigate);
            this.setState({ isLoading: false });
        }
    }

    closeToast = () => {
        this.setState({
            toastVisible: false,
        });
    };

    navigateTo = (path) => {
        this.props.navigate(path);
    };
    onDropdownChangeApplicableTo = (option) => {

        if (option != null) {
            this.setState(
                {
                    dropdown_ship: option, dropdown_shipValue: option.value
                }, () => {
                    const { userId, clientId, dropdown_ship } = this.state;
                    AddLocalStorageData("selectClienDashboardShip", parseInt(option.value, 10));

                    if (this.state.isClient)
                        this.GetClientDashboardInfo(GetLocalStorageDataWithParse("loggedInUser"));
                    else
                        this.GetClientUserDashboardInfo(userId, clientId)
                });

        }
    };
    /* <summary>
        date: 27/02/2023
        Name: HV
        description: on ship changed, showing selected ship PO count
        <summary>*/

    onShipSelected = (e) => {
        var { name, value } = e.target;
        const { userId, clientId, dropdown_ship } = this.state;
        this.setState(
            {
                [name]: value,
            }, () => {
                AddLocalStorageData("selectClienDashboardShip", parseInt(dropdown_ship, 10));
                if (this.state.isClient)
                    this.GetClientDashboardInfo(GetLocalStorageDataWithParse("loggedInUser"));
                else
                    this.GetClientUserDashboardInfo(userId, clientId)
            });
    };

    //AN - 06/03/2023 - handled for scenario where client has no ships assigned and to navigate to manage ships
    navigateToSection = (moduleName) => {
        if (this.state.shipList.length == 0) {
            this.setState({
                toastVisible: true,
                toastType: helperText.global.toastError,
                toastMessage: helperText.Client.noShipsPresent,
            })
        }
        else {
            if (moduleName == "ManageShips") {
                AddLocalStorageData("selectedShip", this.state.dropdown_shipValue);
                this.navigateTo("/ManageShips");
            }
            else {
                this.navigateTo("/TrackingTool");
            }
        }
    };

    //A.N. - 09/03/2023 - navigate to client documents
    onClickDocuments = () => {
        AddLocalStorageData("selectedShip", this.state.dropdown_shipValue);
        this.navigateTo("/ClientDocuments");
    };

    navigateToStatistics = () => {
        this.props.navigate("/ManageStatistics");
    };

    render() {
        const {
            isLoading,
            toastVisible,
            toastType,
            toastMessage,
            accessList,
            dropdown_ship,
            shipList,
            totalClientPOItems,
            totalClientShips,
            totalClientUsers,
            isClientUser
        } = this.state;
        return (
            <div>
                {isLoading && <Loader></Loader>}
                <Header componentFor={"ClientDashboard"} />
                {toastVisible && (
                    <Toast
                        toastVisible={toastVisible}
                        type={toastType}
                        message={toastMessage}
                        closeToast={this.closeToast}
                    />
                )}

                <main className="page-height dashboard-bg">
                    <section className="admin-dashboard">
                        <div className="container">
                            <div className="row quick-link">
                                <div className="col-12 col-md-5 col-xl-4 heading-bottom-space">
                                    <div className="row">
                                        <div className="col-5 col-lg-4">
                                            <label className="label-class">Select Ship:</label>
                                        </div>
                                        <div className="col-7 col-lg-8">
                                            {/* <DropdownWithoutSelectOption
                                                dropdownLabel=""
                                                name="ship"
                                                onChange={this.onShipSelected}
                                                selected={dropdown_ship}
                                                style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                                            >
                                                {shipList == null
                                                    ? null
                                                    : shipList.map((item, key) => (
                                                        <option
                                                            key={key}
                                                            value={item.value}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                ;
                                            </DropdownWithoutSelectOption> */}
                                            <MultiSelectDropdown
                                                selectedOptions={dropdown_ship == undefined ? [] : dropdown_ship}
                                                options={shipList == null ? [] : shipList}
                                                onChange={this.onDropdownChangeApplicableTo}
                                                isMulti={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <fieldset className="dashboard-fieldset-client">
                                    <legend className="dashboard-legend">Quick Access</legend>
                                    <div className="row">
                                        <div
                                            className="col-12 col-sm-6 col-lg-4"
                                            onClick={() => accessList != null && accessList.shipParticularsAccess == UserAction.DENY ? "" : this.navigateToSection("ManageShips")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className={accessList != null && accessList.shipParticularsAccess == UserAction.DENY ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"}>
                                                <h3>View Ships</h3>
                                                <img src="images/clients.png" alt="client" />
                                                <div className="sub-data-quicklink-client">
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <div>Total Ships</div>
                                                            <div className="purple-color">{totalClientShips}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="col-sm-6 col-lg-4"
                                            onClick={() => accessList != null && accessList.trackingToolAccess == UserAction.DENY ? "" : this.navigateToSection("TrackingTool")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className={accessList != null && accessList.trackingToolAccess == UserAction.DENY ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"} >
                                                <h3>Tracking Tool</h3>
                                                <img src="images/tracking.png" className="tracking-eye-image" alt="tracking" />
                                                <div className="sub-data-quicklink-client">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div>Total PO Items</div>
                                                            <div className="purple-color">{totalClientPOItems}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4"
                                            onClick={() => accessList != null && accessList.reportsAccess == UserAction.DENY ? "" : this.navigateTo("/Reports")}
                                            style={{ cursor: "pointer" }}>
                                            <div className={accessList != null && accessList.reportsAccess == UserAction.DENY ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"}>
                                                <h3>Reports</h3>
                                                <img src="images/seo-report.png" className="ihm-database-img" alt="reports" />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4"
                                            onClick={() => (isClientUser || accessList != null && accessList.addClientAccess == UserAction.DENY) ? "" : this.navigateTo("/ManageClientUsers")}
                                        >
                                            <div
                                                className={(isClientUser || accessList != null && accessList.addClientAccess == UserAction.DENY) ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"}>
                                                <h3>Manage Users</h3>
                                                <img src="images/manage-user.png" className="manage-user-icon" alt="user" />
                                                <div className="sub-data-quicklink-client user-box">
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <div>Total Users</div>
                                                            <div className="purple-color">{totalClientUsers}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4"
                                            onClick={() => accessList != null && accessList.trainingAccess == UserAction.DENY ? "" : this.navigateTo("/TrainingDocument")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className={accessList != null && accessList.trainingAccess == UserAction.DENY ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"}>

                                                <h3>Training</h3>
                                                <img src="images/training.png" alt="Training" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4"
                                            onClick={() => accessList != null && accessList.documentsAccess == UserAction.DENY ? "" : this.onClickDocuments()}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className={accessList != null && accessList.documentsAccess == UserAction.DENY ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"}>
                                                <h3>Documents</h3>
                                                <img src="images/Documents.png" alt="Documents" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4"
                                            onClick={accessList != null && accessList.statisticsAccess == UserAction.DENY ? "" : this.navigateToStatistics}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className={accessList != null && accessList.statisticsAccess == UserAction.DENY ? "quick-link-box-client quick-link-disable" : "quick-link-box-client"}>
                                                <h3>Statistics</h3>
                                                <img src="images/statistics.png" alt="Statistics" />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            {/* <div>
                                <div className="row dashboard-box2 col-4"
                                onClick={accessList != null && accessList.statisticsAccess == UserAction.DENY ? "" : this.navigateToStatistics}
                                style={{ cursor: "pointer" }}
                                >
                                    <div className="col-6 p-0 statistics"
                                    >
                                        <h3>Statistics</h3>
                                        <img src="images/analytics.png" className="img-fluid" alt="Communications" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ClientDashboard);
