import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { config } from '../helpers/config';


const msalConfig = {
    auth: config.auth,
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

function MsalProviderWrapper(props) {

    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <MsalProvider instance={msalInstance}>
            {props.children}
        </MsalProvider>   
    );
}

export default MsalProviderWrapper;