import webserviceWrapper from "../helpers/webserviceWrapper";


export const ActionService = {
    GetAllActionRequiredData,
    SaveIsViewedByAdminStatus,
};


function GetAllActionRequiredData(UserId,pageval,usertypeId) {
     
    return webserviceWrapper.get(`/ActionRequired/GetAllActionRequiredData?userId=${UserId}&prevpageval=${pageval}&userTypeId=${usertypeId}`);
}

function SaveIsViewedByAdminStatus(data) {
    return webserviceWrapper.post( `/ActionRequired/SaveIsViewedByAdminStatus`,data);
}
