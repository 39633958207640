import React from "react";
import AGGrid from "../components/AGGrid";

const ViewHistoryModal = ({
    showViewHistoryModal,
    mappedHistory,
    colDefs,
    onCloseClick
}) => {
    return (
        <section>
            <div className="supplier_on_hold">
                <div className="modal-backdrop fade show" style={{ display: showViewHistoryModal ? 'block' : 'none' }}></div>
                <div className="modal" id="view-history-Modal" style={{ display: showViewHistoryModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-box modal-xl">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">View History</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" onClick={onCloseClick} />
                                </button>
                            </div>
                            <div className="row content">
                                <div className="table-responsive">
                                    <AGGrid
                                        rowData={mappedHistory}
                                        colDefs={colDefs}
                                        rowSelection="single"
                                        pagination="10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ViewHistoryModal;