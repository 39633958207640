import React, { Component } from "react";
import axios from "axios";
import * as html2pdf from "html2pdf.js";
import { UserService } from "../../services/user.service";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { helperText } from "../../helpers/helperText";
import Snackbar from "../../components/Snackbar";
import Loader from "../../components/Loader";
import YesNoDropdown from "../../components/YesNoDropdown";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import backIcon from "../../assets/images/back button image.png";
import printIcon from "../../assets/images/printer.png";
import shipdownloaddocumentIcon from "../../assets/images/ship-download-document.png";
import FileUploadIcon from "../../assets/images/FileUploadIcon.png";
import {
  generateRandomString,
  withRouter,
  isEmailValid,
  isValidFile,
  isPasswordValid,
  changePageTitle,
  AddLocalStorageData,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
} from "../../helpers";
import Toast from "../../components/Toast";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Dropdown from "../../components/Dropdown";
import DatePicker from "../../components/DatePickerComponent";
import { MdsdocService } from "../../services/mdsdoc.service";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import Logout from "../../helpers/Logout";
import LinkVerificationModal from "./LinkVerificationModal";

const NUMBER_REGEX = /^[0-9()\s]+$/;
const validFileFormats = ["png, jpg, jpeg, PNG"];
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";

class MDSDoC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      errorMessages: {},

      showErrorMessage: false,
      showInvalidEmailErrorMessage: false,
      userId: 0,

      ctiRegulationList: [],
      declarationList: [],
      remarkList: [],
      mdsdocLineItemList: [],
      supplierLineItemList: [],
      mdsdocId: 0,
      declaration: "",

      declarationDate: convertDate(new Date()),
      mdIdNo: "",
      remark: "",
      companyName: "",
      divisionName: "",
      contactPerson: "",
      contactNumber: "",
      faxNumber: "",
      emailAddress: "",
      productNumber: "",
      productDescription: "",
      amount: "",
      unitValue: "",

      asbMass: "",
      pbMass: "",
      ocMass: "",
      ohMass: "",
      oofhMass: "",
      octMass: "",
      otMass: "",
      ohcMass: "",
      ohbMass: "",
      ombMass: "",
      obcMass: "",
      oafMass: "",
      ccMass: "",
      hcMass: "",
      llMass: "",
      mmMass: "",
      pbbMass: "",
      pbdMass: "",
      pMass: "",
      rsMass: "",
      ccpMass: "",
      pfoMass: "",
      hbcddMass: "",

      asbThreshold: "",
      pbThreshold: "",
      ocThreshold: "",
      ohThreshold: "",
      oofhThreshold: "",
      octThreshold: "",
      otThreshold: "",
      ohcThreshold: "",
      ohbThreshold: "",
      ombThreshold: "",
      obcThreshold: "",
      oafThreshold: "",
      ccThreshold: "",
      hcThreshold: "",
      llThreshold: "",
      mmThreshold: "",
      pbbThreshold: "",
      pbdThreshold: "",
      pThreshold: "",
      rsThreshold: "",
      ccpThreshold: "",
      pfoThreshold: "",
      hbcddThreshold: "",

      dropdown_asbAboveThreshold: "2",
      dropdown_pbAboveThreshold: "2",
      dropdown_ocAboveThreshold: "2",
      dropdown_ohAboveThreshold: "2",
      dropdown_oofhAboveThreshold: "2",
      dropdown_octAboveThreshold: "2",
      dropdown_otAboveThreshold: "2",
      dropdown_ohcAboveThreshold: "2",
      dropdown_ohbAboveThreshold: "2",
      dropdown_ombAboveThreshold: "2",
      dropdown_obcAboveThreshold: "2",
      dropdown_oafAboveThreshold: "2",
      dropdown_ccAboveThreshold: "2",
      dropdown_hcAboveThreshold: "2",
      dropdown_llAboveThreshold: "2",
      dropdown_mmAboveThreshold: "2",
      dropdown_pbbAboveThreshold: "2",
      dropdown_pbdAboveThreshold: "2",
      dropdown_pAboveThreshold: "2",
      dropdown_rsAboveThreshold: "2",
      dropdown_ccpAboveThreshold: "2",
      dropdown_pfoAboveThreshold: "2",
      dropdown_hbcddAboveThreshold: "2",

      asbUnit: "",
      pbUnit: "",
      ocUnit: "",
      ohUnit: "",
      oofhUnit: "",
      octUnit: "",
      otUnit: "",
      ohcUnit: "",
      ohbUnit: "",
      ombUnit: "",
      obcUnit: "",
      oafUnit: "",
      ccUnit: "",
      hcUnit: "",
      llUnit: "",
      mmUnit: "",
      pbbUnit: "",
      pbdUnit: "",
      pUnit: "",
      rsUnit: "",
      ccpUnit: "",
      pfoUnit: "",
      hbcddUnit: "",

      asbInfo: "",
      pbInfo: "",
      ocInfo: "",
      ohInfo: "",
      oofhInfo: "",
      octInfo: "",
      otInfo: "",
      ohcInfo: "",
      ohbInfo: "",
      ombInfo: "",
      obcInfo: "",
      oafInfo: "",
      ccInfo: "",
      hcInfo: "",
      llInfo: "",
      mmInfo: "",
      pbbInfo: "",
      pbdInfo: "",
      pInfo: "",
      rsInfo: "",
      ccpInfo: "",
      pfoInfo: "",
      hbcddInfo: "",

      sdocNo: "",
      issuerName: "",
      issuerAddress: "",
      supplierIssuerAddress: "",
      additionalInfo: "",
      onBehalf: "",
      placeOfIssue: "",
      dateOfIssue: "",
      nameFunction: "",
      signature: "",
      companyStampUrl: "",

      fileInputKey: generateRandomString(),
      shipProfilePictureUrl: "",

      trackingToolId: 0,

      addLineItemDescription: "",
      addLineItemPONumber: "",
      addLineItemQuantity: "",
      addLineItemUnit: "",
      addLineItemEquipmentUsed: "",

      signatureCustom: "",
      companyStampCustom: "",
      isProfileSignature: false,
      isProfileStamp: false,

      isHTMLtoPDFDownload:
        this.props.isHTMLtoPDFDownload === true ? true : false,
      addLineItemTrackingToolId: 0,
      disableFields: false,
      companyStampChanged: false,
      companySignatureChanged: false,

      dropdown_poItem: "",
      supplierId: 0,
      queryParamSupplierID: 0,
      queryParamMDSDoCID: 0,
      showPasswordModal: false,
      otpEntered: "",
      emptyOtp: "",
      alreadyAccessedOtp: "",
      validationMessage: "",
      loggedInUserRoleId: 0,
    };
  }

  isInt = (value) => {
    return (
      !isNaN(value) &&
      parseInt(Number(value), 10) == value &&
      !isNaN(parseInt(value, 10))
    );
  };

  /* <summary>
    date: 25/11/2022
    Name: DN
    description: onLoad bind data of the supplier  
    <summary> */
  /* <summary>
   date: 20/03/2023
   Name: DP
   description: passed msdoc id , supplier list and PO item item list
   <summary>*/
  componentDidMount = async () => {
    try {
      changePageTitle(TitlePageNames.SUPPLIERDOCUMENTS);
      var mdsdocId = null;
      var trackingToolId = null;
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size != 0) {
        let supplierID = queryParams.get("supplierId");
        let mdsdocID = queryParams.get("mdsDocId");
        this.setState({
          queryParamSupplierID: supplierID,
          queryParamMDSDoCID: mdsdocID,
        });

        var response = await MdsdocService.CheckIfLinkIsAccessible(
          supplierID,
          mdsdocID
        );
        if (response.data != null || response.data != undefined) {
          mdsdocId = response.data.mdsdocId;
          trackingToolId = response.data.trackingToolId;

          this.setState({ supplierId: response.data.supplierId });
          //if guid is not null and true means it has already been used/
          if (
            response.data.isGuidAccessed != null &&
            response.data.isGuidAccessed === true
          ) {
            this.setState({
              showPasswordModal: true,
              alreadyAccessedOtp: true,
              isLoading: false,
            });
          } else {
            //show modal to enter password
            this.setState({
              showPasswordModal: true,
              isLoading: false,
            });
          }
        }
      }
      if (this.state.queryParamMDSDoCID != "") {
        await this.loadMDSDoCFromGenericLink(mdsdocId, trackingToolId);
      } else {
        mdsdocId = GetLocalStorageDataWithoutParse("mdsdocId");
        var loggedInUserData = GetLocalStorageDataWithParse("loggedInUser");

        this.setState({
          loggedInUserRoleId: loggedInUserData.typeId,
        });
        await this.loadMDSDoC(mdsdocId);
      }

      //SYJ - By default Hide the Product Name Values From UL list
      const dropdown = document.getElementById("addLineItemMdsdoc-options");
      dropdown.setAttribute("hidden", "true");
    } catch (error) { }
  };

  loadMDSDoCFromGenericLink = async (mdsdocId, trackingToolId) => {
    //Load Cti regulations
    var response = await MdsdocService.GetCTIRegulations();
    if (response != undefined || response.data != null) {
      this.setState({ ctiRegulationList: response.data });
    }
    if (parseInt(mdsdocId, 10) == 0) {
      var trackingToolId = parseInt(trackingToolId, 10);
      var responsePOData = await MdsdocService.GetPOAndLineItemData(
        this.state.supplierId
      );
      if (responsePOData.data != null) {
        var { supplierDocumentDropdownList } = responsePOData.data;
        AddLocalStorageData(
          "poNumberList",
          JSON.stringify(supplierDocumentDropdownList.poNumberList)
        );
        AddLocalStorageData(
          "lineItemDescription",
          JSON.stringify(supplierDocumentDropdownList.lineNumberDescriptionList)
        );
      }

      var responseSupplier = await MdsdocService.GetSupplierData(
        this.state.supplierId
      );
      if (responseSupplier.data != null) {
        var supplierData = responseSupplier.data;
        const signatureBlob = await this.convertImageToBlob(
          supplierData.digitalSignatureUrl
        );
        const companyStampBlob = await this.convertImageToBlob(
          supplierData.companyStampUrl
        );

        const signatureCustomBlob = await this.convertImageToBlob(
          supplierData.digitalSignatureUrl
        );
        const companyStampCustomBlob = await this.convertImageToBlob(
          supplierData.companyStampUrl
        );

        this.setState({
          companyName: supplierData.supplierName,
          divisionName: supplierData.division,
          supplierIssuerAddress: supplierData.issuerAddress,
          contactPerson: supplierData.issuerName,
          //issuerName: supplierData.issuerName,
          contactNumber: supplierData.supplierContactNo,
          faxNumber: supplierData.faxNumber,
          emailAddress: supplierData.emailAddress,
          supplierId: supplierData.supplierId,
          trackingToolId: trackingToolId,

          signature: signatureBlob,
          companyStampUrl: companyStampBlob,

          signatureCustom: signatureCustomBlob,
          companyStampCustom: companyStampCustomBlob,
          isProfileSignature: supplierData.isProfileSignature,
          isProfileStamp: supplierData.isProfileSignature,
        });
      }

      var poNumberList = GetLocalStorageDataWithoutParse("poNumberList");
      var lineItemDescription = GetLocalStorageDataWithoutParse(
        "lineItemDescription"
      );
      let lineItemData = new FormData();
      if (
        poNumberList.length > 0 ||
        lineItemDescription.length > 0 ||
        supplierData.supplierId != 0
      ) {
        lineItemData.append("poNumberList", poNumberList);
        lineItemData.append("lineItemDescription", lineItemDescription);
        lineItemData.append("SupplierId", supplierData.supplierId);
        lineItemData.append("trackingToolId", trackingToolId);
      }
      var response = await MdsdocService.GetLineItemData(lineItemData);
      if (response == undefined || response.data == null) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200 && response.data != null) {
          var lineItemList = response.data.mdsdocLineItemList.map((x) => {
            if (x.trackingToolId == 0) {
              x.id = Math.random();
            }
            return x;
          });

          this.setState({
            mdsdocLineItemList: lineItemList,
            supplierLineItemList: response.data.supplierLineItemList,
            issuerName: response.data.issuerName,
            issuerAddress: response.data.issuerAddress,
          });
        }
      }
    } else {
      this.setState(
        { mdsdocId: parseInt(mdsdocId, 10) },
        async () => await this.getMDSDoCData(parseInt(mdsdocId, 10))
      );
    }
  };

  loadMDSDoC = async (mdsdocId) => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      var disableFields = GetLocalStorageDataWithoutParse(
        "disableMdsdocFormFields"
      );
      this.setState({
        userId: userData != null ? userData.id : 0,
        disableFields: disableFields == "true" ? true : false,
      });

      const { params } = this.props;

      var loc = GetLocalStorageDataWithoutParse("FilledMdsdoc");
      //const mdsdocId = mdsdocId ? mdsdocId  : this.isInt(params?.id)  ? parseInt(params.id) : 0;
      if (parseInt(mdsdocId, 10) == 0 || loc == "true") {
        var trackingToolId = parseInt(
          GetLocalStorageDataWithoutParse("trackingToolId"),
          10
        );
        var supplierList = GetLocalStorageDataWithParse("supplierList");
        var poNumberList = GetLocalStorageDataWithoutParse("poNumberList");
        var lineItemDescription = GetLocalStorageDataWithoutParse(
          "lineItemDescription"
        );
        if (supplierList.length > 0) {
          var supplierData = supplierList[0];
          const signatureBlob = await this.convertImageToBlob(
            supplierData.digitalSignatureUrl
          );
          const companyStampBlob = await this.convertImageToBlob(
            supplierData.companyStampUrl
          );

          const signatureCustomBlob = await this.convertImageToBlob(
            supplierData.digitalSignatureUrl
          );
          const companyStampCustomBlob = await this.convertImageToBlob(
            supplierData.companyStampUrl
          );

          var response = await MdsdocService.GetCTIRegulations();
          if (response != undefined || response.data != null) {
            this.setState({ ctiRegulationList: response.data });
          }

          this.setState({
            companyName: supplierData.supplierName,
            divisionName: supplierData.division,
            supplierIssuerAddress: supplierData.issuerAddress,
            contactPerson: supplierData.issuerName,
            contactNumber: supplierData.supplierContactNo,
            faxNumber: supplierData.faxNumber,
            emailAddress: supplierData.emailAddress,
            supplierId: supplierData.supplierId,
            trackingToolId: trackingToolId,

            signature: signatureBlob,
            companyStampUrl: companyStampBlob,

            signatureCustom: signatureCustomBlob,
            companyStampCustom: companyStampCustomBlob,
            isProfileSignature: supplierData.isProfileSignature,
            isProfileStamp: supplierData.isProfileSignature,
          });
        }

        if (
          poNumberList.length > 0 ||
          lineItemDescription.length > 0 ||
          supplierData.supplierId != 0
        ) {
          let lineItemData = new FormData();
          lineItemData.append("poNumberList", poNumberList);
          lineItemData.append("lineItemDescription", lineItemDescription);
          lineItemData.append("SupplierId", supplierData.supplierId);
          lineItemData.append("trackingToolId", trackingToolId);

          var response = await MdsdocService.GetLineItemData(lineItemData);

          if (response == undefined || response.data == null) {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.global.somethingWentWrong,
            });
          } else if (response != null) {
            if (response.status == 401) {
              Logout(this.props.navigate);
            } else if (response.status == 200 && response.data != null) {
              var lineItemList = response.data.mdsdocLineItemList.map((x) => {
                if (x.trackingToolId == 0) {
                  x.id = Math.random();
                }
                return x;
              });
              this.setState({
                mdsdocLineItemList: lineItemList,
                supplierLineItemList: response.data.supplierLineItemList,
                issuerName: response.data.issuerName,
                issuerAddress: response.data.issuerAddress,
              });
            }
          }
        }
      } else {
        this.setState({ mdsdocId: parseInt(mdsdocId, 10) }, () =>
          this.getMDSDoCData(parseInt(mdsdocId, 10))
        );
      }
    }
  };

  componentWillUnmount() {
    AddLocalStorageData("FilledMdsdoc", "false");
  }

  getMDSDoCData = async (mdsdocId) => {
    try {
      var response = await MdsdocService.GetMdsdocData(mdsdocId);
      if (response == undefined || response.data == null) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200 && response.data != null) {
          const signatureBlob = await this.convertImageToBlob(
            response.data.signature
          );
          const companyStampBlob = await this.convertImageToBlob(
            response.data.companyStamp
          );

          const signatureCustomBlob = await this.convertImageToBlob(
            response.data.signatureCustom
          );
          const companyStampCustomBlob = await this.convertImageToBlob(
            response.data.companyStampCustom
          );

          const shipProfilePictureUrlBlob = await this.convertImageToBlob(
            response.data.shipProfilePictureUrl
          );

          var lineItemList = response.data.mdsdocLineItemList.map((x) => {
            if (x.trackingToolId == 0) {
              x.id = Math.random();
            }
            return x;
          });

          this.setState({
            ctiRegulationList: response.data.ctiRegulationsList,
            declarationList: response.data.objectDeclarationList,
            remarkList: response.data.mdsdocRemarkList,
            supplierId: response.data.supplierId,
            mdsdocLineItemList: lineItemList,
            supplierLineItemList: response.data.supplierLineItemList,

            mdsdocId: response.data.mdsdocId,
            sdocNo:
              response.data.sdoCidno === "null" ? "" : response.data.sdoCidno,
            issuerName:
              response.data.issuerName === "null"
                ? ""
                : response.data.issuerName,
            issuerAddress:
              response.data.issuerAddress === "null"
                ? ""
                : response.data.issuerAddress,
            additionalInfo:
              response.data.additionalInformation === "null"
                ? ""
                : response.data.additionalInformation,
            onBehalf:
              response.data.signedOnBehalf === "null"
                ? ""
                : response.data.signedOnBehalf,
            placeOfIssue:
              response.data.place === "null" ? "" : response.data.place,
            dateOfIssue:
              response.data.dateOfIssue == null ||
                response.data.dateOfIssue == undefined ||
                response.data.dateOfIssue == "" ||
                response.data.dateOfIssue == "null"
                ? null
                : new Date(response.data?.dateOfIssue),
            nameFunction:
              response.data.nameFunction === "null"
                ? ""
                : response.data.nameFunction,

            mdIdNo: response.data.mdidno,
            declarationDate:
              response.data?.mdsDocFormDeclarationDate === "null"
                ? ""
                : response.data?.mdsDocFormDeclarationDate,
            companyName:
              response.data.companyName === "null"
                ? ""
                : response.data.companyName,
            divisionName:
              response.data.divisionName === "null"
                ? ""
                : response.data.divisionName,
            contactPerson:
              response.data.contactPerson === "null"
                ? ""
                : response.data.contactPerson,
            supplierIssuerAddress:
              response.data.address === "null" ? "" : response.data.address,
            contactNumber:
              response.data.telephoneNumber === "null"
                ? ""
                : response.data.telephoneNumber,
            faxNumber:
              response.data.faxNumber === "null" ? "" : response.data.faxNumber,
            emailAddress:
              response.data.emailAddress === "null"
                ? ""
                : response.data.emailAddress,

            productDescription:
              response.data.description === "null"
                ? ""
                : response.data.description,
            productNumber:
              response.data.poNumber === "null" ? "" : response.data.poNumber,
            amount:
              response.data.quantity === "null" ? "" : response.data.quantity,
            unitValue: response.data.unit === "null" ? "" : response.data.unit,

            asbThreshold:
              response.data.aAsbestosThreshold === "null"
                ? ""
                : response.data.aAsbestosThreshold,
            pbThreshold:
              response.data.aPbthreshold === "null"
                ? ""
                : response.data.aPbthreshold,
            ocThreshold:
              response.data.aOcthreshold === "null"
                ? ""
                : response.data.aOcthreshold,
            ohThreshold:
              response.data.aOhthreshold === "null"
                ? ""
                : response.data.aOhthreshold,
            oofhThreshold:
              response.data.aOofhthreshold === "null"
                ? ""
                : response.data.aOofhthreshold,
            octThreshold:
              response.data.aOctthreshold === "null"
                ? ""
                : response.data.aOctthreshold,
            otThreshold:
              response.data.aOtthreshold === "null"
                ? ""
                : response.data.aOtthreshold,
            ohcThreshold:
              response.data.aOhcthreshold === "null"
                ? ""
                : response.data.aOhcthreshold,
            ohbThreshold:
              response.data.aOhbthreshold === "null"
                ? ""
                : response.data.aOhbthreshold,
            ombThreshold:
              response.data.aOmbthreshold === "null"
                ? ""
                : response.data.aOmbthreshold,
            obcThreshold:
              response.data.aObthreshold === "null"
                ? ""
                : response.data.aObthreshold,
            oafThreshold:
              response.data.aAfthreshold === "null"
                ? ""
                : response.data.aAfthreshold,
            ccThreshold:
              response.data.bCthreshold === "null"
                ? ""
                : response.data.bCthreshold,
            hcThreshold:
              response.data.bHthreshold === "null"
                ? ""
                : response.data.bHthreshold,
            llThreshold:
              response.data.bLthreshold === "null"
                ? ""
                : response.data.bLthreshold,
            mmThreshold:
              response.data.bMthreshold === "null"
                ? ""
                : response.data.bMthreshold,
            pbbThreshold:
              response.data.bPbbthreshold === "null"
                ? ""
                : response.data.bPbbthreshold,
            pbdThreshold:
              response.data.bPbdethreshold === "null"
                ? ""
                : response.data.bPbdethreshold,
            pThreshold:
              response.data.bCithreshold === "null"
                ? ""
                : response.data.bCithreshold,
            rsThreshold:
              response.data.bRthreshold === "null"
                ? ""
                : response.data.bRthreshold,
            ccpThreshold:
              response.data.bCscpthreshold === "null"
                ? ""
                : response.data.bCscpthreshold,
            pfoThreshold:
              response.data.bPhosthreshold === "null"
                ? ""
                : response.data.bPhosthreshold,
            hbcddThreshold:
              response.data.bHbcddthreshold === "null"
                ? ""
                : response.data.bHbcddthreshold,

            dropdown_asbAboveThreshold: response.data.aAsbestosAboveThreshold
              ? "1"
              : "2",
            dropdown_pbAboveThreshold: response.data.aPbaboveThreshold
              ? "1"
              : "2",
            dropdown_ocAboveThreshold: response.data.aOcaboveThreshold
              ? "1"
              : "2",
            dropdown_ohAboveThreshold: response.data.aOhaboveThreshold
              ? "1"
              : "2",
            dropdown_oofhAboveThreshold: response.data.aOofhaboveThreshold
              ? "1"
              : "2",
            dropdown_octAboveThreshold: response.data.aOctaboveThreshold
              ? "1"
              : "2",
            dropdown_otAboveThreshold: response.data.aOtaboveThreshold
              ? "1"
              : "2",
            dropdown_ohcAboveThreshold: response.data.aOhcaboveThreshold
              ? "1"
              : "2",
            dropdown_ohbAboveThreshold: response.data.aOhbaboveThreshold
              ? "1"
              : "2",
            dropdown_ombAboveThreshold: response.data.aOmbaboveThreshold
              ? "1"
              : "2",
            dropdown_obcAboveThreshold: response.data.aObaboveThreshold
              ? "1"
              : "2",
            dropdown_oafAboveThreshold: response.data.aAfaboveThreshold
              ? "1"
              : "2",
            dropdown_ccAboveThreshold: response.data.bCaboveThreshold
              ? "1"
              : "2",
            dropdown_hcAboveThreshold: response.data.bHaboveThreshold
              ? "1"
              : "2",
            dropdown_llAboveThreshold: response.data.bLaboveThreshold
              ? "1"
              : "2",
            dropdown_mmAboveThreshold: response.data.bMaboveThreshold
              ? "1"
              : "2",
            dropdown_pbbAboveThreshold: response.data.bPbbaboveThreshold
              ? "1"
              : "2",
            dropdown_pbdAboveThreshold: response.data.bPbdeaboveThreshold
              ? "1"
              : "2",
            dropdown_pAboveThreshold: response.data.bCiaboveThreshold
              ? "1"
              : "2",
            dropdown_rsAboveThreshold: response.data.bRaboveThreshold
              ? "1"
              : "2",
            dropdown_ccpAboveThreshold: response.data.bCscpaboveThreshold
              ? "1"
              : "2",
            dropdown_pfoAboveThreshold: response.data.bPhosaboveThreshold
              ? "1"
              : "2",
            dropdown_hbcddAboveThreshold: response.data.bHbcddaboveThreshold
              ? "1"
              : "2",

            asbMass: response.data.aAsbestosMass ?? "",
            pbMass: response.data.aPbmass ?? "",
            ocMass: response.data.aOcmass ?? "",
            ohMass: response.data.aOhmass ?? "",
            oofhMass: response.data.aOofhmass ?? "",
            octMass: response.data.aOctmass ?? "",
            otMass: response.data.aOtmass ?? "",
            ohcMass: response.data.aOhcmass ?? "",
            ohbMass: response.data.aOhbmass ?? "",
            ombMass: response.data.aOmbmass ?? "",
            obcMass: response.data.aObmass ?? "",
            oafMass: response.data.aAfmass ?? "",
            ccMass: response.data.bCmass ?? "",
            hcMass: response.data.bHmass ?? "",
            llMass: response.data.bLmass ?? "",
            mmMass: response.data.bMmass ?? "",
            pbbMass: response.data.bPbbmass ?? "",
            pbdMass: response.data.bPbdemass ?? "",
            pMass: response.data.bCimass ?? "",
            rsMass: response.data.bRmass ?? "",
            ccpMass: response.data.bCscpmass ?? "",
            pfoMass: response.data.bPhosmass ?? "",
            hbcddMass: response.data.bHbcddmass ?? "",

            asbUnit:
              response.data.aAsbestosUnit === "null"
                ? ""
                : response.data.aAsbestosUnit,
            pbUnit:
              response.data.aPbunit === "null" ? "" : response.data.aPbunit,
            ocUnit:
              response.data.aOcunit === "null" ? "" : response.data.aOcunit,
            ohUnit:
              response.data.aOhunit === "null" ? "" : response.data.aOhunit,
            oofhUnit:
              response.data.aOofhunit === "null" ? "" : response.data.aOofhunit,
            octUnit:
              response.data.aOctunit === "null" ? "" : response.data.aOctunit,
            otUnit:
              response.data.aOtunit === "null" ? "" : response.data.aOtunit,
            ohcUnit:
              response.data.aOhcunit === "null" ? "" : response.data.aOhcunit,
            ohbUnit:
              response.data.aOhbunit === "null" ? "" : response.data.aOhbunit,
            ombUnit:
              response.data.aOmbunit === "null" ? "" : response.data.aOmbunit,
            obcUnit:
              response.data.aObunit === "null" ? "" : response.data.aObunit,
            oafUnit:
              response.data.aAfunit === "null" ? "" : response.data.aAfunit,
            ccUnit: response.data.bCunit === "null" ? "" : response.data.bCunit,
            hcUnit: response.data.bHunit === "null" ? "" : response.data.bHunit,
            llUnit: response.data.bLunit === "null" ? "" : response.data.bLunit,
            mmUnit: response.data.bMunit === "null" ? "" : response.data.bMunit,
            pbbUnit:
              response.data.bPbbunit === "null" ? "" : response.data.bPbbunit,
            pbdUnit:
              response.data.bPbdeunit === "null" ? "" : response.data.bPbdeunit,
            pUnit:
              response.data.bCiunit === "null" ? "" : response.data.bCiunit,
            rsUnit: response.data.bRunit === "null" ? "" : response.data.bRunit,
            ccpUnit:
              response.data.bCscpunit === "null" ? "" : response.data.bCscpunit,
            pfoUnit:
              response.data.bPhosunit === "null" ? "" : response.data.bPhosunit,
            hbcddUnit:
              response.data.bHbcddunit === "null"
                ? ""
                : response.data.bHbcddunit,

            asbInfo:
              response.data.aAsbestosInfo === "null"
                ? ""
                : response.data.aAsbestosInfo,
            pbInfo:
              response.data.aPbinfo === "null" ? "" : response.data.aPbinfo,
            ocInfo:
              response.data.aOcinfo === "null" ? "" : response.data.aOcinfo,
            ohInfo:
              response.data.aOhinfo === "null" ? "" : response.data.aOhinfo,
            oofhInfo:
              response.data.aOofhinfo === "null" ? "" : response.data.aOofhinfo,
            octInfo:
              response.data.aOctinfo === "null" ? "" : response.data.aOctinfo,
            otInfo:
              response.data.aOtinfo === "null" ? "" : response.data.aOtinfo,
            ohcInfo:
              response.data.aOhcinfo === "null" ? "" : response.data.aOhcinfo,
            ohbInfo:
              response.data.aOhbinfo === "null" ? "" : response.data.aOhbinfo,
            ombInfo:
              response.data.aOmbinfo === "null" ? "" : response.data.aOmbinfo,
            obcInfo:
              response.data.aObinfo === "null" ? "" : response.data.aObinfo,
            oafInfo:
              response.data.aAfinfo === "null" ? "" : response.data.aAfinfo,
            ccInfo: response.data.bCinfo === "null" ? "" : response.data.bCinfo,
            hcInfo: response.data.bHinfo === "null" ? "" : response.data.bHinfo,
            llInfo: response.data.bLinfo === "null" ? "" : response.data.bLinfo,
            mmInfo: response.data.bMinfo === "null" ? "" : response.data.bMinfo,
            pbbInfo:
              response.data.bPbbinfo === "null" ? "" : response.data.bPbbinfo,
            pbdInfo:
              response.data.bPbdeinfo === "null" ? "" : response.data.bPbdeinfo,
            pInfo:
              response.data.bCiinfo === "null" ? "" : response.data.bCiinfo,
            rsInfo: response.data.bRinfo === "null" ? "" : response.data.bRinfo,
            ccpInfo:
              response.data.bCscpinfo === "null" ? "" : response.data.bCscpinfo,
            pfoInfo:
              response.data.bPhosinfo === "null" ? "" : response.data.bPhosinfo,
            hbcddInfo:
              response.data.bHbcddinfo === "null"
                ? ""
                : response.data.bHbcddinfo,

            signature: signatureBlob,
            companyStampUrl: companyStampBlob,
            shipProfilePictureUrl: shipProfilePictureUrlBlob,

            signatureCustom: signatureCustomBlob,
            companyStampCustom: companyStampCustomBlob,
            isProfileSignature: response.data.isProfileSignature,
            isProfileStamp: response.data.isProfileStamp,
            isLoading: false,
          });
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
    } catch (error) { }
  };

  //13-03-23 SP: HTML to PDF converter is unable to display image from url hence we need to convert it into blob
  // in local environment browser will throw CORS, please install 'Moesif CORS' extention. https://chrome.google.com/webstore/detail/moesif-origin-cors-change/digfbfaphojjndkpccljibejjbppifbc
  convertImageToBlob = async (url) => {
    let responseUrl = "";
    try {
      if (url != null) {
        if (url.startsWith("http") && url.includes("IHMM")) {
          await axios.get(url, { responseType: "blob" }).then((response) => {
            responseUrl = URL.createObjectURL(response.data);
          });
        } else {
          responseUrl = url;
        }
      } else {
        responseUrl = "";
      }
    } catch (error) {
      responseUrl = "";
    }
    return responseUrl;
  };

  onTextChange = (e) => {
    const { showErrorMessage } = this.state;
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (showErrorMessage) {
        this.validate();
      }
    });
  };

  onDateChanged = (id, e) => {
    const { showErrorMessage } = this.state;
    if (id == "dateOfDeclaration")
      this.setState({ declarationDate: e }, () => { });

    if ((id = "dateOfIssue"))
      this.setState({ dateOfIssue: e }, () => {
        if (showErrorMessage) {
          this.validate();
        }
      });
  };

  /* <summary>
     date: 30/03/2023
     Name: HV
     description: handled highlighting fields if error
     <summary> */

  validate = (isSubmitted) => {
    const {
      dateOfIssue,
      nameFunction,
      errorMessages,

      dropdown_asbAboveThreshold,
      asbMass,
      asbUnit,
      asbInfo,

      dropdown_pbAboveThreshold,
      pbMass,
      pbUnit,
      pbInfo,

      dropdown_ocAboveThreshold,
      ocUnit,
      ocInfo,
      ocMass,

      dropdown_ohAboveThreshold,
      ohUnit,
      ohMass,
      ohInfo,

      dropdown_oofhAboveThreshold,
      oofhUnit,
      oofhInfo,
      oofhMass,

      dropdown_octAboveThreshold,
      octMass,
      octUnit,
      octInfo,

      dropdown_otAboveThreshold,
      otMass,
      otUnit,
      otInfo,

      dropdown_ohcAboveThreshold,
      ohcMass,
      ohcUnit,
      ohcInfo,

      dropdown_ohbAboveThreshold,
      ohbUnit,
      ohbMass,
      ohbInfo,

      dropdown_ombAboveThreshold,
      ombMass,
      ombUnit,
      ombInfo,

      dropdown_obcAboveThreshold,
      obcUnit,
      obcInfo,
      obcMass,

      dropdown_oafAboveThreshold,
      oafMass,
      oafUnit,
      oafInfo,

      dropdown_ccAboveThreshold,
      ccMass,
      ccUnit,
      ccInfo,

      dropdown_hcAboveThreshold,
      hcMass,
      hcUnit,
      hcInfo,

      dropdown_llAboveThreshold,
      llMass,
      llUnit,
      llInfo,

      dropdown_mmAboveThreshold,
      mmMass,
      mmUnit,
      mmInfo,

      dropdown_pbbAboveThreshold,
      pbbMass,
      pbbUnit,
      pbbInfo,

      dropdown_pbdAboveThreshold,
      pbdMass,
      pbdUnit,
      pbdInfo,

      dropdown_pAboveThreshold,
      pMass,
      pUnit,
      pInfo,

      dropdown_rsAboveThreshold,
      rsMass,
      rsUnit,
      rsInfo,

      dropdown_ccpAboveThreshold,
      ccpMass,
      ccpUnit,
      ccpInfo,

      dropdown_pfoAboveThreshold,
      pfoMass,
      pfoUnit,
      pfoInfo,

      dropdown_hbcddAboveThreshold,
      hbcddMass,
      hbcddUnit,
      hbcddInfo,
      mdsdocLineItemList,
      mdIdNo,
      sdocNo,
    } = this.state;

    let errors = errorMessages;
    let isFormIncomplete = false;
    if (
      dropdown_hbcddAboveThreshold == "" ||
      dropdown_hbcddAboveThreshold == "0" ||
      dropdown_hbcddAboveThreshold == "1"
    ) {
      if (dropdown_hbcddAboveThreshold == "1") {
        errors["errorhbcddMass"] = isStringEmpty(hbcddMass.toString())
          ? "true"
          : "";
        errors["errorhbcddUnit"] = isStringEmpty(hbcddUnit) ? "true" : "";
        errors["errorhbcddInfo"] = isStringEmpty(hbcddInfo) ? "true" : "";

        if (
          isStringEmpty(hbcddMass.toString()) ||
          isStringEmpty(hbcddUnit) ||
          isStringEmpty(hbcddInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_pfoAboveThreshold == "" ||
      dropdown_pfoAboveThreshold == "0" ||
      dropdown_pfoAboveThreshold == "1"
    ) {
      if (dropdown_pfoAboveThreshold == "1") {
        errors["errorpfoMass"] = isStringEmpty(pfoMass.toString())
          ? "true"
          : "";
        errors["errorpfoUnit"] = isStringEmpty(pfoUnit) ? "true" : "";
        errors["errorpfoInfo"] = isStringEmpty(pfoInfo) ? "true" : "";

        if (
          isStringEmpty(pfoMass.toString()) ||
          isStringEmpty(pfoUnit) ||
          isStringEmpty(pfoInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ccpAboveThreshold == "" ||
      dropdown_ccpAboveThreshold == "0" ||
      dropdown_ccpAboveThreshold == "1"
    ) {
      if (dropdown_ccpAboveThreshold == "1") {
        errors["errorccpMass"] = isStringEmpty(ccpMass.toString())
          ? "true"
          : "";
        errors["errorccpUnit"] = isStringEmpty(ccpUnit) ? "true" : "";
        errors["errorccpInfo"] = isStringEmpty(ccpInfo) ? "true" : "";

        if (
          isStringEmpty(ccpMass.toString()) ||
          isStringEmpty(ccpUnit) ||
          isStringEmpty(ccpInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_rsAboveThreshold == "" ||
      dropdown_rsAboveThreshold == "0" ||
      dropdown_rsAboveThreshold == "1"
    ) {
      if (dropdown_rsAboveThreshold == "1") {
        errors["errorsMass"] = isStringEmpty(rsMass.toString()) ? "true" : "";
        errors["errorsUnit"] = isStringEmpty(rsUnit) ? "true" : "";
        errors["errorsInfo"] = isStringEmpty(rsInfo) ? "true" : "";

        if (
          isStringEmpty(rsMass.toString()) ||
          isStringEmpty(rsUnit) ||
          isStringEmpty(rsInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_pAboveThreshold == "" ||
      dropdown_pAboveThreshold == "0" ||
      dropdown_pAboveThreshold == "1"
    ) {
      if (dropdown_pAboveThreshold == "1") {
        errors["errorpMass"] = isStringEmpty(pMass.toString()) ? "true" : "";
        errors["errorpUnit"] = isStringEmpty(pUnit) ? "true" : "";
        errors["errorpInfo"] = isStringEmpty(pInfo) ? "true" : "";

        if (
          isStringEmpty(pMass.toString()) ||
          isStringEmpty(pUnit) ||
          isStringEmpty(pInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_pbdAboveThreshold == "" ||
      dropdown_pbdAboveThreshold == "0" ||
      dropdown_pbdAboveThreshold == "1"
    ) {
      if (dropdown_pbdAboveThreshold == "1") {
        errors["errorpbdMass"] = isStringEmpty(pbdMass.toString())
          ? "true"
          : "";
        errors["errorpbdUnit"] = isStringEmpty(pbdUnit) ? "true" : "";
        errors["errorpbdInfo"] = isStringEmpty(pbdInfo) ? "true" : "";

        if (
          isStringEmpty(pbdMass.toString()) ||
          isStringEmpty(pbdUnit) ||
          isStringEmpty(pbdInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_pbbAboveThreshold == "" ||
      dropdown_pbbAboveThreshold == "0" ||
      dropdown_pbbAboveThreshold == "1"
    ) {
      if (dropdown_pbbAboveThreshold == "1") {
        errors["errorpbbMass"] = isStringEmpty(pbbMass.toString())
          ? "true"
          : "";
        errors["errorpbbUnit"] = isStringEmpty(pbbUnit) ? "true" : "";
        errors["errorpbbInfo"] = isStringEmpty(pbbInfo) ? "true" : "";

        if (
          isStringEmpty(pbbMass.toString()) ||
          isStringEmpty(pbbUnit) ||
          isStringEmpty(pbbInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_mmAboveThreshold == "" ||
      dropdown_mmAboveThreshold == "0" ||
      dropdown_mmAboveThreshold == "1"
    ) {
      if (dropdown_mmAboveThreshold == "1") {
        errors["errormmMass"] = isStringEmpty(mmMass.toString()) ? "true" : "";
        errors["errormmUnit"] = isStringEmpty(mmUnit) ? "true" : "";
        errors["errormmInfo"] = isStringEmpty(mmInfo) ? "true" : "";

        if (
          isStringEmpty(mmMass.toString()) ||
          isStringEmpty(mmUnit) ||
          isStringEmpty(mmInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_llAboveThreshold == "" ||
      dropdown_llAboveThreshold == "0" ||
      dropdown_llAboveThreshold == "1"
    ) {
      if (dropdown_llAboveThreshold == "1") {
        errors["errorllMass"] = isStringEmpty(llMass.toString()) ? "true" : "";
        errors["errorllUnit"] = isStringEmpty(llUnit) ? "true" : "";
        errors["errorllInfo"] = isStringEmpty(llInfo) ? "true" : "";

        if (
          isStringEmpty(llMass.toString()) ||
          isStringEmpty(llUnit) ||
          isStringEmpty(llInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_hcAboveThreshold == "" ||
      dropdown_hcAboveThreshold == "0" ||
      dropdown_hcAboveThreshold == "1"
    ) {
      if (dropdown_hcAboveThreshold == "1") {
        errors["errorhcMass"] = isStringEmpty(hcMass.toString()) ? "true" : "";
        errors["errorhcUnit"] = isStringEmpty(hcUnit) ? "true" : "";
        errors["errorhcInfo"] = isStringEmpty(hcInfo) ? "true" : "";

        if (
          isStringEmpty(hcMass.toString()) ||
          isStringEmpty(hcUnit) ||
          isStringEmpty(hcInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ccAboveThreshold == "" ||
      dropdown_ccAboveThreshold == "0" ||
      dropdown_ccAboveThreshold == "1"
    ) {
      if (dropdown_ccAboveThreshold == "1") {
        errors["errorccMass"] = isStringEmpty(ccMass.toString()) ? "true" : "";
        errors["errorccUnit"] = isStringEmpty(ccUnit) ? "true" : "";
        errors["errorccInfo"] = isStringEmpty(ccInfo) ? "true" : "";

        if (
          isStringEmpty(ccMass.toString()) ||
          isStringEmpty(ccUnit) ||
          isStringEmpty(ccInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_oafAboveThreshold == "" ||
      dropdown_oafAboveThreshold == "0" ||
      dropdown_oafAboveThreshold == "1"
    ) {
      if (dropdown_oafAboveThreshold == "1") {
        errors["erroroafMass"] = isStringEmpty(oafMass.toString())
          ? "true"
          : "";
        errors["erroroafUnit"] = isStringEmpty(oafUnit) ? "true" : "";
        errors["erroroafInfo"] = isStringEmpty(oafInfo) ? "true" : "";

        if (
          isStringEmpty(oafMass.toString()) ||
          isStringEmpty(oafUnit) ||
          isStringEmpty(oafInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_obcAboveThreshold == "" ||
      dropdown_obcAboveThreshold == "0" ||
      dropdown_obcAboveThreshold == "1"
    ) {
      if (dropdown_obcAboveThreshold == "1") {
        errors["errorobcMass"] = isStringEmpty(obcMass.toString())
          ? "true"
          : "";
        errors["errorobcUnit"] = isStringEmpty(obcUnit) ? "true" : "";
        errors["errorobcInfo"] = isStringEmpty(obcInfo) ? "true" : "";

        if (
          isStringEmpty(obcUnit) ||
          isStringEmpty(obcInfo) ||
          isStringEmpty(obcMass.toString())
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ombAboveThreshold == "" ||
      dropdown_ombAboveThreshold == "0" ||
      dropdown_ombAboveThreshold == "1"
    ) {
      if (dropdown_ombAboveThreshold == "1") {
        errors["errorombMass"] = isStringEmpty(ombMass.toString())
          ? "true"
          : "";
        errors["errorombUnit"] = isStringEmpty(ombUnit) ? "true" : "";
        errors["errorombInfo"] = isStringEmpty(ombInfo) ? "true" : "";

        if (
          isStringEmpty(ombMass.toString()) ||
          isStringEmpty(ombUnit) ||
          isStringEmpty(ombInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ohbAboveThreshold == "" ||
      dropdown_ohbAboveThreshold == "0" ||
      dropdown_ohbAboveThreshold == "1"
    ) {
      if (dropdown_ohbAboveThreshold == "1") {
        errors["errorohbMass"] = isStringEmpty(ohbMass.toString())
          ? "true"
          : "";
        errors["errorohbUnit"] = isStringEmpty(ohbUnit) ? "true" : "";
        errors["errorohbInfo"] = isStringEmpty(ohbInfo) ? "true" : "";

        if (
          isStringEmpty(ohbUnit) ||
          isStringEmpty(ohbMass.toString()) ||
          isStringEmpty(ohbInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ohcAboveThreshold == "" ||
      dropdown_ohcAboveThreshold == "0" ||
      dropdown_ohcAboveThreshold == "1"
    ) {
      if (dropdown_ohcAboveThreshold == "1") {
        errors["errorohcMass"] = isStringEmpty(ohcMass.toString())
          ? "true"
          : "";
        errors["errorohcUnit"] = isStringEmpty(ohcUnit) ? "true" : "";
        errors["errorohcInfo"] = isStringEmpty(ohcInfo) ? "true" : "";

        if (
          isStringEmpty(ohcMass.toString()) ||
          isStringEmpty(ohcUnit) ||
          isStringEmpty(ohcInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_otAboveThreshold == "" ||
      dropdown_otAboveThreshold == "0" ||
      dropdown_otAboveThreshold == "1"
    ) {
      if (dropdown_otAboveThreshold == "1") {
        errors["errorotMass"] = isStringEmpty(otMass.toString()) ? "true" : "";
        errors["errorotUnit"] = isStringEmpty(otUnit) ? "true" : "";
        errors["errorotInfo"] = isStringEmpty(otInfo) ? "true" : "";

        if (
          isStringEmpty(otMass.toString()) ||
          isStringEmpty(otUnit) ||
          isStringEmpty(otInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_octAboveThreshold == "" ||
      dropdown_octAboveThreshold == "0" ||
      dropdown_octAboveThreshold == "1"
    ) {
      if (dropdown_octAboveThreshold == "1") {
        errors["erroroctMass"] = isStringEmpty(octMass.toString())
          ? "true"
          : "";
        errors["erroroctUnit"] = isStringEmpty(octUnit) ? "true" : "";
        errors["erroroctInfo"] = isStringEmpty(octInfo) ? "true" : "";

        if (
          isStringEmpty(octMass.toString()) ||
          isStringEmpty(octUnit) ||
          isStringEmpty(octInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_oofhAboveThreshold == "" ||
      dropdown_oofhAboveThreshold == "0" ||
      dropdown_oofhAboveThreshold == "1"
    ) {
      if (dropdown_oofhAboveThreshold == "1") {
        errors["erroroofhMass"] = isStringEmpty(oofhMass.toString())
          ? "true"
          : "";
        errors["erroroofhUnit"] = isStringEmpty(oofhUnit) ? "true" : "";
        errors["erroroofhInfo"] = isStringEmpty(oofhInfo) ? "true" : "";

        if (
          isStringEmpty(oofhMass.toString()) ||
          isStringEmpty(oofhUnit) ||
          isStringEmpty(oofhInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_asbAboveThreshold == "" ||
      dropdown_asbAboveThreshold == "0" ||
      dropdown_asbAboveThreshold == "1"
    ) {
      if (dropdown_asbAboveThreshold == "1") {
        errors["errorasbMass"] = isStringEmpty(asbMass.toString())
          ? "true"
          : "";
        errors["errorasbUnit"] = isStringEmpty(asbUnit) ? "true" : "";
        errors["errorasbInfo"] = isStringEmpty(asbInfo) ? "true" : "";

        if (
          isStringEmpty(asbMass.toString()) ||
          isStringEmpty(asbUnit) ||
          isStringEmpty(asbInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_pbAboveThreshold == "" ||
      dropdown_pbAboveThreshold == "0" ||
      dropdown_pbAboveThreshold == "1"
    ) {
      if (dropdown_pbAboveThreshold == "1") {
        errors["errorpbMass"] = isStringEmpty(pbMass.toString()) ? "true" : "";
        errors["errorpbUnit"] = isStringEmpty(pbUnit) ? "true" : "";
        errors["errorpbInfo"] = isStringEmpty(pbInfo) ? "true" : "";

        if (
          isStringEmpty(pbMass.toString()) ||
          isStringEmpty(pbUnit) ||
          isStringEmpty(pbInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ocAboveThreshold == "" ||
      dropdown_ocAboveThreshold == "0" ||
      dropdown_ocAboveThreshold == "1"
    ) {
      if (dropdown_ocAboveThreshold == "1") {
        errors["errorocMass"] = isStringEmpty(ocMass.toString()) ? "true" : "";
        errors["errorocUnit"] = isStringEmpty(ocUnit) ? "true" : "";
        errors["errorocInfo"] = isStringEmpty(ocInfo) ? "true" : "";

        if (
          isStringEmpty(ocMass.toString()) ||
          isStringEmpty(ocUnit) ||
          isStringEmpty(ocInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    if (
      dropdown_ohAboveThreshold == "" ||
      dropdown_ohAboveThreshold == "0" ||
      dropdown_ohAboveThreshold == "1"
    ) {
      if (dropdown_ohAboveThreshold == "1") {
        errors["errorohMass"] = isStringEmpty(ohMass.toString()) ? "true" : "";
        errors["errorohUnit"] = isStringEmpty(ohUnit) ? "true" : "";
        errors["errorohInfo"] = isStringEmpty(ohInfo) ? "true" : "";

        if (
          isStringEmpty(ohMass.toString()) ||
          isStringEmpty(ohUnit) ||
          isStringEmpty(ohInfo)
        ) {
          isFormIncomplete = true;
        }
      }
    }

    errors["dateOfIssueValidation"] =
      dateOfIssue == null || dateOfIssue == undefined || dateOfIssue == ""
        ? "true"
        : "";
    errors["nameFunctionValidation"] = isStringEmpty(nameFunction)
      ? "true"
      : "";
    errors["mdsdocLineItemValidation"] =
      mdsdocLineItemList.length < 1 ? "true" : "";
    errors["MDIDNumber"] =
      mdIdNo == null || mdIdNo == undefined || mdIdNo == "" ? "true" : "";
    errors["SDoCID"] =
      sdocNo == null || sdocNo == undefined || sdocNo == "" ? "true" : "";

    if (
      errors["dateOfIssueValidation"] != "" ||
      errors["nameFunctionValidation"] != "" ||
      errors["mdsdocLineItemValidation"] != "" ||
      isFormIncomplete ||
      errors["MDIDNumber"] != "" ||
      errors["SDoCID"] != ""
    ) {
      this.setState({ errorMessages, showErrorMessage: true });
      return true;
    }

    this.setState({
      errorMessages: errors,
      isLoading: false,
      showErrorMessage: false,
      usernameExistError: false,
    });
    return false;
  };

  onSaveClick = async (isSubmitted, isSaved) => {
    try {
      if (!this.validate(isSubmitted)) {
        this.setState({
          isLoading: true,
        });
        const {
          userId,
          mdsdocId,
          sdocNo,
          issuerName,
          issuerAddress,
          additionalInfo,
          onBehalf,
          placeOfIssue,
          dateOfIssue,
          nameFunction,
          signature,
          signatureCustom,
          companyStampUrl,
          companyStampCustom,

          declarationDate,
          mdIdNo,
          remark,
          companyName,
          divisionName,
          contactPerson,
          contactNumber,
          faxNumber,
          emailAddress,
          productDescription,
          productNumber,
          amount,
          unitValue,

          asbMass,
          pbMass,
          ocMass,
          ohMass,
          oofhMass,
          octMass,
          otMass,
          ohcMass,
          ohbMass,
          ombMass,
          obcMass,
          oafMass,
          ccMass,
          hcMass,
          llMass,
          mmMass,
          pbbMass,
          pbdMass,
          pMass,
          rsMass,
          ccpMass,
          pfoMass,
          hbcddMass,

          asbThreshold,
          pbThreshold,
          ocThreshold,
          ohThreshold,
          oofhThreshold,
          octThreshold,
          otThreshold,
          ohcThreshold,
          ohbThreshold,
          ombThreshold,
          obcThreshold,
          oafThreshold,
          ccThreshold,
          hcThreshold,
          llThreshold,
          mmThreshold,
          pbbThreshold,
          pbdThreshold,
          pThreshold,
          rsThreshold,
          ccpThreshold,
          pfoThreshold,
          hbcddThreshold,

          dropdown_asbAboveThreshold,
          dropdown_pbAboveThreshold,
          dropdown_ocAboveThreshold,
          dropdown_ohAboveThreshold,
          dropdown_oofhAboveThreshold,
          dropdown_octAboveThreshold,
          dropdown_otAboveThreshold,
          dropdown_ohcAboveThreshold,
          dropdown_ohbAboveThreshold,
          dropdown_ombAboveThreshold,
          dropdown_obcAboveThreshold,
          dropdown_oafAboveThreshold,
          dropdown_ccAboveThreshold,
          dropdown_hcAboveThreshold,
          dropdown_llAboveThreshold,
          dropdown_mmAboveThreshold,
          dropdown_pbbAboveThreshold,
          dropdown_pbdAboveThreshold,
          dropdown_pAboveThreshold,
          dropdown_rsAboveThreshold,
          dropdown_ccpAboveThreshold,
          dropdown_pfoAboveThreshold,
          dropdown_hbcddAboveThreshold,

          asbUnit,
          pbUnit,
          ocUnit,
          ohUnit,
          oofhUnit,
          octUnit,
          otUnit,
          ohcUnit,
          ohbUnit,
          ombUnit,
          obcUnit,
          oafUnit,
          ccUnit,
          hcUnit,
          llUnit,
          mmUnit,
          pbbUnit,
          pbdUnit,
          pUnit,
          rsUnit,
          ccpUnit,
          pfoUnit,
          hbcddUnit,

          asbInfo,
          pbInfo,
          ocInfo,
          ohInfo,
          oofhInfo,
          octInfo,
          otInfo,
          ohcInfo,
          ohbInfo,
          ombInfo,
          obcInfo,
          oafInfo,
          ccInfo,
          hcInfo,
          llInfo,
          mmInfo,
          pbbInfo,
          pbdInfo,
          pInfo,
          rsInfo,
          ccpInfo,
          pfoInfo,
          hbcddInfo,

          trackingToolId,

          documentSignFile,
          documentStampFile,
          isProfileSignature,
          isProfileStamp,
          supplierId,
          errorMessages,
          remarkList,
          mdsdocLineItemList,
          declarationList,
        } = this.state;

        let errors = errorMessages;
        var issueDate =
          dateOfIssue == null || dateOfIssue == undefined || dateOfIssue == ""
            ? ""
            : convertDate(dateOfIssue);
        var declareDate = declarationDate;

        let mdsdocData = new FormData();

        //trackingToolId
        mdsdocData.append("trackingToolId", trackingToolId);
        mdsdocData.append("SupplierId", supplierId);
        //SDoC
        mdsdocData.append("userId", userId);
        mdsdocData.append("mdsdocId", mdsdocId);
        mdsdocData.append("sdocNo", sdocNo);
        mdsdocData.append("additionalInfo", additionalInfo);
        mdsdocData.append("onBehalf", onBehalf);
        mdsdocData.append("placeOfIssue", placeOfIssue);
        mdsdocData.append("dateOfIssue", issueDate);
        mdsdocData.append("nameFunction", nameFunction);

        //MD
        mdsdocData.append("declarationDate", declareDate);
        mdsdocData.append("mdIdNo", mdIdNo);
        mdsdocData.append("asbMass", asbMass);
        mdsdocData.append("pbMass", pbMass);
        mdsdocData.append("ocMass", ocMass);
        mdsdocData.append("ohMass", ohMass);
        mdsdocData.append("oofhMass", oofhMass);
        mdsdocData.append("octMass", octMass);
        mdsdocData.append("otMass", otMass);
        mdsdocData.append("ohcMass", ohcMass);
        mdsdocData.append("ohbMass", ohbMass);
        mdsdocData.append("ombMass", ombMass);
        mdsdocData.append("obcMass", obcMass);
        mdsdocData.append("oafMass", oafMass);
        mdsdocData.append("ccMass", ccMass);
        mdsdocData.append("hcMass", hcMass);

        mdsdocData.append("llMass", llMass);
        mdsdocData.append("mmMass", mmMass);
        mdsdocData.append("pbbMass", pbbMass);
        mdsdocData.append("pbdMass", pbdMass);
        mdsdocData.append("pMass", pMass);
        mdsdocData.append("rsMass", rsMass);
        mdsdocData.append("ccpMass", ccpMass);
        mdsdocData.append("pfoMass", pfoMass);
        mdsdocData.append("hbcddMass", hbcddMass);
        mdsdocData.append("asbThreshold", asbThreshold);
        mdsdocData.append("pbThreshold", pbThreshold);
        mdsdocData.append("ocThreshold", ocThreshold);
        mdsdocData.append("ohThreshold", ohThreshold);
        mdsdocData.append("oofhThreshold", oofhThreshold);
        mdsdocData.append("octThreshold", octThreshold);
        mdsdocData.append("otThreshold", otThreshold);
        mdsdocData.append("ohcThreshold", ohcThreshold);
        mdsdocData.append("ohbThreshold", ohbThreshold);
        mdsdocData.append("ombThreshold", ombThreshold);
        mdsdocData.append("obcThreshold", obcThreshold);
        mdsdocData.append("oafThreshold", oafThreshold);
        mdsdocData.append("ccThreshold", ccThreshold);
        mdsdocData.append("hcThreshold", hcThreshold);
        mdsdocData.append("llThreshold", llThreshold);
        mdsdocData.append("mmThreshold", mmThreshold);
        mdsdocData.append("pbbThreshold", pbbThreshold);
        mdsdocData.append("pbdThreshold", pbdThreshold);
        mdsdocData.append("pThreshold", pThreshold);
        mdsdocData.append("rsThreshold", rsThreshold);
        mdsdocData.append("ccpThreshold", ccpThreshold);
        mdsdocData.append("pfoThreshold", pfoThreshold);
        mdsdocData.append("hbcddThreshold", hbcddThreshold);
        mdsdocData.append(
          "dropdown_asbAboveThreshold",
          dropdown_asbAboveThreshold
        );
        mdsdocData.append(
          "dropdown_pbAboveThreshold",
          dropdown_pbAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ocAboveThreshold",
          dropdown_ocAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ohAboveThreshold",
          dropdown_ohAboveThreshold
        );
        mdsdocData.append(
          "dropdown_oofhAboveThreshold",
          dropdown_oofhAboveThreshold
        );
        mdsdocData.append(
          "dropdown_octAboveThreshold",
          dropdown_octAboveThreshold
        );
        mdsdocData.append(
          "dropdown_otAboveThreshold",
          dropdown_otAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ohcAboveThreshold",
          dropdown_ohcAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ohbAboveThreshold",
          dropdown_ohbAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ombAboveThreshold",
          dropdown_ombAboveThreshold
        );
        mdsdocData.append(
          "dropdown_obcAboveThreshold",
          dropdown_obcAboveThreshold
        );
        mdsdocData.append(
          "dropdown_oafAboveThreshold",
          dropdown_oafAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ccAboveThreshold",
          dropdown_ccAboveThreshold
        );
        mdsdocData.append(
          "dropdown_hcAboveThreshold",
          dropdown_hcAboveThreshold
        );
        mdsdocData.append(
          "dropdown_llAboveThreshold",
          dropdown_llAboveThreshold
        );
        mdsdocData.append(
          "dropdown_mmAboveThreshold",
          dropdown_mmAboveThreshold
        );
        mdsdocData.append(
          "dropdown_pbbAboveThreshold",
          dropdown_pbbAboveThreshold
        );
        mdsdocData.append(
          "dropdown_pbdAboveThreshold",
          dropdown_pbdAboveThreshold
        );
        mdsdocData.append("dropdown_pAboveThreshold", dropdown_pAboveThreshold);
        mdsdocData.append(
          "dropdown_rsAboveThreshold",
          dropdown_rsAboveThreshold
        );
        mdsdocData.append(
          "dropdown_ccpAboveThreshold",
          dropdown_ccpAboveThreshold
        );
        mdsdocData.append(
          "dropdown_pfoAboveThreshold",
          dropdown_pfoAboveThreshold
        );
        mdsdocData.append(
          "dropdown_hbcddAboveThreshold",
          dropdown_hbcddAboveThreshold
        );
        mdsdocData.append("asbUnit", asbUnit);
        mdsdocData.append("pbUnit", pbUnit);
        mdsdocData.append("ocUnit", ocUnit);
        mdsdocData.append("ohUnit", ohUnit);
        mdsdocData.append("oofhUnit", oofhUnit);
        mdsdocData.append("octUnit", octUnit);
        mdsdocData.append("otUnit", otUnit);
        mdsdocData.append("ohcUnit", ohcUnit);
        mdsdocData.append("ohbUnit", ohbUnit);
        mdsdocData.append("ombUnit", ombUnit);
        mdsdocData.append("obcUnit", obcUnit);
        mdsdocData.append("oafUnit", oafUnit);
        mdsdocData.append("ccUnit", ccUnit);
        mdsdocData.append("hcUnit", hcUnit);
        mdsdocData.append("llUnit", llUnit);
        mdsdocData.append("mmUnit", mmUnit);
        mdsdocData.append("pbbUnit", pbbUnit);
        mdsdocData.append("pbdUnit", pbdUnit);
        mdsdocData.append("pUnit", pUnit);
        mdsdocData.append("rsUnit", rsUnit);
        mdsdocData.append("ccpUnit", ccpUnit);
        mdsdocData.append("pfoUnit", pfoUnit);
        mdsdocData.append("hbcddUnit", hbcddUnit);
        mdsdocData.append("asbInfo", asbInfo);
        mdsdocData.append("pbInfo", pbInfo);
        mdsdocData.append("ocInfo", ocInfo);
        mdsdocData.append("ohInfo", ohInfo);
        mdsdocData.append("oofhInfo", oofhInfo);
        mdsdocData.append("octInfo", octInfo);
        mdsdocData.append("otInfo", otInfo);
        mdsdocData.append("ohcInfo", ohcInfo);
        mdsdocData.append("ohbInfo", ohbInfo);
        mdsdocData.append("ombInfo", ombInfo);
        mdsdocData.append("obcInfo", obcInfo);
        mdsdocData.append("oafInfo", oafInfo);
        mdsdocData.append("ccInfo", ccInfo);
        mdsdocData.append("hcInfo", hcInfo);
        mdsdocData.append("llInfo", llInfo);
        mdsdocData.append("mmInfo", mmInfo);
        mdsdocData.append("pbbInfo", pbbInfo);
        mdsdocData.append("pbdInfo", pbdInfo);
        mdsdocData.append("pInfo", pInfo);
        mdsdocData.append("rsInfo", rsInfo);
        mdsdocData.append("ccpInfo", ccpInfo);
        mdsdocData.append("pfoInfo", pfoInfo);
        mdsdocData.append("hbcddInfo", hbcddInfo);

        mdsdocData.append("IsMdsdocFilled", true);

        mdsdocData.append("isSubmitted", isSubmitted ? true : false);
        mdsdocData.append("isSaved", isSaved ? true : false);
        mdsdocData.append("issuerName", issuerName);
        mdsdocData.append("issuerAddress", issuerAddress);

        if (
          documentSignFile != null &&
          documentSignFile != "" &&
          documentSignFile.size !== null &&
          documentSignFile.size !== undefined &&
          isProfileSignature === false
        ) {
          mdsdocData.append("documentSignFile", documentSignFile);
          mdsdocData.append("IsProfileSignature", false);
          mdsdocData.append(
            "companySignatureChanged",
            this.state.companySignatureChanged
          );
        }
        if (
          documentStampFile != null &&
          documentStampFile != "" &&
          documentStampFile.size !== null &&
          documentStampFile.size !== undefined &&
          isProfileStamp === false
        ) {
          mdsdocData.append("documentStampFile", documentStampFile);
          mdsdocData.append("IsProfileStamp", false);
          mdsdocData.append(
            "companyStampChanged",
            this.state.companyStampChanged
          );
        }
        if (isProfileSignature === true) {
          mdsdocData.append("documentSignFile", null);
          mdsdocData.append("IsProfileSignature", true);
        } else if (isProfileSignature === null) {
          mdsdocData.append("documentSignFile", null);
          mdsdocData.append("IsProfileSignature", null);
        }

        if (isProfileStamp === true) {
          mdsdocData.append("documentStampFile", null);
          mdsdocData.append("IsProfileStamp", true);
        } else if (isProfileStamp === null) {
          mdsdocData.append("documentStampFile", null);
          mdsdocData.append("IsProfileStamp", null);
        }

        mdsdocData.append("remarkList", JSON.stringify(remarkList));
        mdsdocData.append(
          "mdsdocLineItemList",
          JSON.stringify(mdsdocLineItemList)
        );
        mdsdocData.append(
          "objectDeclarationList",
          JSON.stringify(declarationList)
        );
        var response = await MdsdocService.SaveMdsdocData(mdsdocData);
        if (response == undefined) {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
          });
        } else if (response != null && response.data != null) {
          this.setState(
            {
              toastVisible: true,
              toastType: helperText.global.toastSuccess,
              toastMessage: isSubmitted
                ? helperText.Mdsdoc.mdsdocSubmittedSuccessfully
                : helperText.Mdsdoc.mdsdocSavedSuccessfully,
              mdsdocId: response.data,
            },
            () => {
              if (isSubmitted) {
                setTimeout(() => {
                  this.props.navigate("/SupplierDocument");
                }, 1300);
              }
            }
          );
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
          });
        }

        this.setState({
          isLoading: false,
        });
      } else {
      }
    } catch (error) { }
  };

  onDeclarationDelete = async (declarationId, id) => {
    try {
      const { declarationList, mdsdocId } = this.state;

      if (mdsdocId == 0) {
        var deleteData = declarationList.filter((x) => x.index != id);
        this.setState({ declarationList: deleteData });
      } else {
        var response = await MdsdocService.DeleteDeclarationMDSDoC(
          declarationId
        );
        if (response.data != null || response.data != undefined) {
          this.setState({
            declarationList: response.data,
            declaration: "",
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.objectDeclarationDeleteError,
          });
        }
      }
    } catch (error) { }
  };

  onDeclarationAdd = async () => {
    try {
      const { declaration, mdsdocId, declarationList } = this.state;

      if (mdsdocId == 0) {
        if (!isStringEmpty(declaration)) {
          var declarationData = {
            index: Math.random(),
            declarationID: 0,
            declarationText: declaration,
          };

          declarationList.push(declarationData);

          this.setState({
            declarationList: declarationList,
            declaration: "",
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.objectDeclarationAddingEmpty,
          });
        }
      } else {
        if (!isStringEmpty(declaration)) {
          var response = await MdsdocService.AddDeclarationMDSDoC(
            mdsdocId,
            declaration
          );
          if (response.data != null || response.data != undefined) {
            this.setState({
              declarationList: response.data,
              declaration: "",
            });
          } else {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.Mdsdoc.objectDeclarationAddingError,
            });
          }
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.objectDeclarationAddingEmpty,
          });
        }
      }
    } catch (error) { }
  };

  onRemarkDelete = async (remarkId, id) => {
    try {
      const { mdsdocId, remarkList } = this.state;

      if (mdsdocId == 0) {
        var deleteData = remarkList.filter((x) => x.index != id);
        this.setState({ remarkList: deleteData });
      } else {
        var response = await MdsdocService.DeleteRemarkMDSDoC(remarkId);
        if (response.data != null || response.data != undefined) {
          this.setState({
            remarkList: response.data,
            remark: "",
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.remarkDeleteError,
          });
        }
      }
    } catch (error) { }
  };

  onRemarkAdd = async () => {
    try {
      const { remark, mdsdocId, remarkList } = this.state;
      if (mdsdocId == 0) {
        if (!isStringEmpty(remark)) {
          var remarkData = {
            index: Math.random(),
            remarkText: remark,
            remarkID: 0,
          };

          remarkList.push(remarkData);

          this.setState({
            remarkList: remarkList,
            remark: "",
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.remarkAddingEmpty,
          });
        }
      } else {
        if (!isStringEmpty(remark)) {
          var response = await MdsdocService.AddRemarkMDSDoC(mdsdocId, remark);

          if (response.data != null || response.data != undefined) {
            this.setState({
              remarkList: response.data,
              remark: "",
            });
          } else {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.Mdsdoc.remarkAddingError,
            });
          }
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.remarkAddingEmpty,
          });
        }
      }
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;

    const { mdsdocLineItemList } = this.state;
    // SP: Do not allow user to select "Yes" in hazards if more than 1 line item present
    if (mdsdocLineItemList.length > 1) {
      if (
        (name == "dropdown_asbAboveThreshold" && value == "1") ||
        (name == "dropdown_pbAboveThreshold" && value == "1") ||
        (name == "dropdown_ocAboveThreshold" && value == "1") ||
        (name == "dropdown_ohAboveThreshold" && value == "1") ||
        (name == "dropdown_oofhAboveThreshold" && value == "1") ||
        (name == "dropdown_octAboveThreshold" && value == "1") ||
        (name == "dropdown_otAboveThreshold" && value == "1") ||
        (name == "dropdown_ohcAboveThreshold" && value == "1") ||
        (name == "dropdown_ohbAboveThreshold" && value == "1") ||
        (name == "dropdown_ombAboveThreshold" && value == "1") ||
        (name == "dropdown_obcAboveThreshold" && value == "1") ||
        (name == "dropdown_oafAboveThreshold" && value == "1") ||
        (name == "dropdown_ccAboveThreshold" && value == "1") ||
        (name == "dropdown_hcAboveThreshold" && value == "1") ||
        (name == "dropdown_llAboveThreshold" && value == "1") ||
        (name == "dropdown_mmAboveThreshold" && value == "1") ||
        (name == "dropdown_pbbAboveThreshold" && value == "1") ||
        (name == "dropdown_pbdAboveThreshold" && value == "1") ||
        (name == "dropdown_pAboveThreshold" && value == "1") ||
        (name == "dropdown_rsAboveThreshold" && value == "1") ||
        (name == "dropdown_ccpAboveThreshold" && value == "1") ||
        (name == "dropdown_pfoAboveThreshold" && value == "1") ||
        (name == "dropdown_hbcddAboveThreshold" && value == "1")
      ) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.Mdsdoc.moreThan1LineItemPresentError,
        });
        return;
      }
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.clearErrors(name);
      }
    );
  };

  /* <summary>
     date: 30/03/2023
     Name: HV
     description: clearing errors for yes no dropdown
     <summary> */

  clearErrors = (name) => {
    const { errorMessages } = this.state;

    let errors = errorMessages;

    if (name == "dropdown_hbcddAboveThreshold") {
      errors["errorhbcddMass"] = "";
      errors["errorhbcddUnit"] = "";
      errors["errorhbcddInfo"] = "";
    }

    if (name == "dropdown_pfoAboveThreshold") {
      errors["errorpfoMass"] = "";
      errors["errorpfoUnit"] = "";
      errors["errorpfoInfo"] = "";
    }

    if (name == "dropdown_ccpAboveThreshold") {
      errors["errorccpMass"] = "";
      errors["errorccpUnit"] = "";
      errors["errorccpInfo"] = "";
    }

    if (name == "dropdown_rsAboveThreshold") {
      errors["errorsMass"] = "";
      errors["errorsUnit"] = "";
      errors["errorsInfo"] = "";
    }
    if (name == "dropdown_pAboveThreshold") {
      errors["errorpMass"] = "";
      errors["errorpUnit"] = "";
      errors["errorpInfo"] = "";
    }

    if (name == "dropdown_pbdAboveThreshold") {
      errors["errorpbdMass"] = "";
      errors["errorpbdUnit"] = "";
      errors["errorpbdInfo"] = "";
    }

    if (name == "dropdown_pbbAboveThreshold") {
      errors["errorpbbMass"] = "";
      errors["errorpbbUnit"] = "";
      errors["errorpbbInfo"] = "";
    }

    if (name == "dropdown_mmAboveThreshold") {
      errors["errormmMass"] = "";
      errors["errormmUnit"] = "";
      errors["errormmInfo"] = "";
    }

    if (name == "dropdown_llAboveThreshold") {
      errors["errorllMass"] = "";
      errors["errorllUnit"] = "";
      errors["errorllInfo"] = "";
    }

    if (name == "dropdown_hcAboveThreshold") {
      errors["errorhcMass"] = "";
      errors["errorhcUnit"] = "";
      errors["errorhcInfo"] = "";
    }

    if (name == "dropdown_ccAboveThreshold") {
      errors["errorccMass"] = "";
      errors["errorccUnit"] = "";
      errors["errorccInfo"] = "";
    }

    if (name == "dropdown_oafAboveThreshold") {
      errors["erroroafMass"] = "";
      errors["erroroafUnit"] = "";
      errors["erroroafInfo"] = "";
    }
    if (name == "dropdown_obcAboveThreshold") {
      errors["errorobcMass"] = "";
      errors["errorobcUnit"] = "";
      errors["errorobcInfo"] = "";
    }

    if (name == "dropdown_ombAboveThreshold") {
      errors["errorombMass"] = "";
      errors["errorombUnit"] = "";
      errors["errorombInfo"] = "";
    }

    if (name == "dropdown_ohbAboveThreshold") {
      errors["errorohbMass"] = "";
      errors["errorohbUnit"] = "";
      errors["errorohbInfo"] = "";
    }

    if (name == "dropdown_ohcAboveThreshold") {
      errors["errorohcMass"] = "";
      errors["errorohcUnit"] = "";
      errors["errorohcInfo"] = "";
    }

    if (name == "dropdown_otAboveThreshold") {
      errors["errorotMass"] = "";
      errors["errorotUnit"] = "";
      errors["errorotInfo"] = "";
    }

    if (name == "dropdown_octAboveThreshold") {
      errors["erroroctMass"] = "";
      errors["erroroctUnit"] = "";
      errors["erroroctInfo"] = "";
    }

    if (name == "dropdown_oofhAboveThreshold") {
      errors["erroroofhMass"] = "";
      errors["erroroofhUnit"] = "";
      errors["erroroofhInfo"] = "";
    }

    if (name == "dropdown_asbAboveThreshold") {
      errors["errorasbMass"] = "";
      errors["errorasbUnit"] = "";
      errors["errorasbInfo"] = "";
    }

    if (name == "dropdown_pbAboveThreshold") {
      errors["errorpbMass"] = "";
      errors["errorpbUnit"] = "";
      errors["errorpbInfo"] = "";
    }

    if (name == "dropdown_ocAboveThreshold") {
      errors["errorocMass"] = "";
      errors["errorocUnit"] = "";
      errors["errorocInfo"] = "";
    }

    if (name == "dropdown_ohAboveThreshold") {
      errors["errorohMass"] = "";
      errors["errorohUnit"] = "";
      errors["errorohInfo"] = "";
    }

    this.setState({ errorMessages: errors });
  };

  onAttachmentChange = async (e) => {
    const { name, value } = e.target;
    if (!isValidFile(value, validFileFormats)) {
      // if (this.state.fileProfilePath == "") {
      this.setState({
        fileInputKey: generateRandomString(),
        [name]: null,
        errorFile: true,
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileTypeError,
      });
      // }
      return;
    }

    let files = e.target.files[0];

    this.setState({
      errorFile: false,
      [name]: files,
    });

    if (name === "documentProfileFile") {
      this.setState({
        fileProfileName: files.name,
        fileProfilePath: value,
        profilePictureUrl: URL.createObjectURL(files),
      });
    } else if (name === "documentSignFile") {
      this.setState({
        fileSignName: files.name,
        fileSignPath: value,
        // signature: URL.createObjectURL(files),
        signatureCustom: URL.createObjectURL(files),
        isProfileSignature: false,
        companySignatureChanged: true,
      });
    } else if (name === "documentStampFile") {
      this.setState({
        fileStampName: files.name,
        fileStampPath: value,
        // companyStampUrl: URL.createObjectURL(files),
        companyStampCustom: URL.createObjectURL(files),
        isProfileStamp: false,
        companyStampChanged: true,
      });
    }
  };

  /* <summary>
  date: 04-03-2023
  Name: SP
  description: Take print of MDSdoc page
  <summary>*/
  onPrintClicked = async (isDownload) => {
    let timeout = 0;
    this.setState(
      {
        isHTMLtoPDFDownload: true,
        isLoading: true,
      },
      async () => {
        timeout = setTimeout(async () => {
          try {
            var element = document.getElementById("printNewDesign");
            // var dateTimeLastEdited = response.data.cyberRiskAssessmentAnswer.editedOn;, compress: true, precision: 20, floatPrecision : "smart

            var opt = {
              margin: [15, 5, 15, 5], //top, left, bottom, right,portrait
              //margin: [25, 15, 25, 15],
              image: { type: "jpeg", quality: 0.3 },
              filename: "MDSDoC_" + this.state.mdsdocId + ".pdf",
              html2canvas: { dpi: 300, scale: 4, letterRendering: true },
              pagebreak: {
                avoid: ["tr", "input", "label", "span"],
                before: ".sdoc-form",
              },
              jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
            };

            var worker = html2pdf();

            if (isDownload) {
              worker.set(opt).from(element).toPdf().get("pdf").save();
            } else {
              worker
                .set(opt)
                .from(element)
                .toPdf()
                .get("pdf")
                .then(function (pdfObj) {
                  // pdfObj has your jsPDF object in it, use it as you please!
                  // For instance (untested):
                  pdfObj.autoPrint();
                  window.open(pdfObj.output("bloburl"), "_blank");
                });
            }
          } catch (e) {
          } finally {
            this.setState({
              isHTMLtoPDFDownload: false,
              isLoading: false,
            });
            if (timeout) {
              clearTimeout(timeout);
            }
          }
        }, 1000);
      }
    );
  };

  /* <summary>
date: 18/03/2023
Name: DP
description: delete when mdsdoc is 0 was not working
<summary>*/
  onMdsdocLineItemDelete = async (lineItemId, id) => {
    const { mdsdocId, mdsdocLineItemList } = this.state;
    let mdsdocAfterDeleteData = [];

    if (lineItemId != 0 && lineItemId != null) {
      mdsdocAfterDeleteData = mdsdocLineItemList.filter(
        (item) => item.trackingToolId !== lineItemId
      );
    } else {
      mdsdocAfterDeleteData = mdsdocLineItemList.filter((x) => x.id != id);
    }

    this.setState({
      mdsdocLineItemList: mdsdocAfterDeleteData,
      addLineItemTrackingToolId: 0,
      addLineItemPONumber: "",
      addLineItemQuantity: "",
      addLineItemUnit: "",
      addLineItemEquipmentUsed: "",
    });
  };

  checkForHazardousItem = () => {
    const {
      mdsdocLineItemList,
      dropdown_asbAboveThreshold,
      dropdown_pbAboveThreshold,
      dropdown_ocAboveThreshold,
      dropdown_ohAboveThreshold,
      dropdown_oofhAboveThreshold,
      dropdown_octAboveThreshold,
      dropdown_otAboveThreshold,
      dropdown_ohcAboveThreshold,
      dropdown_ohbAboveThreshold,
      dropdown_ombAboveThreshold,
      dropdown_obcAboveThreshold,
      dropdown_oafAboveThreshold,
      dropdown_ccAboveThreshold,
      dropdown_hcAboveThreshold,
      dropdown_llAboveThreshold,
      dropdown_mmAboveThreshold,
      dropdown_pbbAboveThreshold,
      dropdown_pbdAboveThreshold,
      dropdown_pAboveThreshold,
      dropdown_rsAboveThreshold,
      dropdown_ccpAboveThreshold,
      dropdown_pfoAboveThreshold,
      dropdown_hbcddAboveThreshold,
    } = this.state;

    if (mdsdocLineItemList.length > 0) {
      if (
        dropdown_asbAboveThreshold == "1" ||
        dropdown_pbAboveThreshold == "1" ||
        dropdown_ocAboveThreshold == "1" ||
        dropdown_ohAboveThreshold == "1" ||
        dropdown_oofhAboveThreshold == "1" ||
        dropdown_octAboveThreshold == "1" ||
        dropdown_otAboveThreshold == "1" ||
        dropdown_ohcAboveThreshold == "1" ||
        dropdown_ohbAboveThreshold == "1" ||
        dropdown_ombAboveThreshold == "1" ||
        dropdown_obcAboveThreshold == "1" ||
        dropdown_oafAboveThreshold == "1" ||
        dropdown_ccAboveThreshold == "1" ||
        dropdown_hcAboveThreshold == "1" ||
        dropdown_llAboveThreshold == "1" ||
        dropdown_mmAboveThreshold == "1" ||
        dropdown_pbbAboveThreshold == "1" ||
        dropdown_pbdAboveThreshold == "1" ||
        dropdown_pAboveThreshold == "1" ||
        dropdown_rsAboveThreshold == "1" ||
        dropdown_ccpAboveThreshold == "1" ||
        dropdown_pfoAboveThreshold == "1" ||
        dropdown_hbcddAboveThreshold == "1"
      ) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.Mdsdoc.hazardousPresentForLineItemError,
        });
        return true;
      } else return false;
    }
  };

  onMdsdocLineItemAdd = async (lineItemId) => {
    try {
      const {
        mdsdocLineItemList,
        mdsdocId,
        addLineItemDescription,
        addLineItemPONumber,
        addLineItemQuantity,
        addLineItemUnit,
        addLineItemEquipmentUsed,
      } = this.state;
      // SP: Do not allow user to add line item, if "Yes" in hazards already present in current line item
      if (!this.checkForHazardousItem()) {
        if (!isStringEmpty(addLineItemDescription)) {
          let addItem = {
            trackingToolId: 0,
            description: addLineItemDescription,
            ponumber: addLineItemPONumber,
            quantity: addLineItemQuantity,
            unit: addLineItemUnit,
            equipmentUsed: addLineItemEquipmentUsed,
            id: Math.random(),
          };

          mdsdocLineItemList.push(addItem);

          this.setState({
            mdsdocLineItemList: mdsdocLineItemList,
            addLineItemDescription: "",
            addLineItemTrackingToolId: 0,
            addLineItemPONumber: "",
            addLineItemQuantity: "",
            addLineItemUnit: "",
            addLineItemEquipmentUsed: "",
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.Mdsdoc.lineItemAddingEmpty,
          });
        }
      }
    } catch (error) { }
  };

  onInputClick = () => {
    const dropdown = document.getElementById("addLineItemMdsdoc-options");
    dropdown.toggleAttribute("hidden");
  };

  onAddLineItemDescriptionTextChange = (e) => {
    //const { name, value } = e.target;
    const selectedValue = e.target.getAttribute("data-value");
    const value = e.target.value;

    const dropdown = document.getElementById("addLineItemMdsdoc-options");
    dropdown.toggleAttribute("hidden");

    const { mdsdocLineItemList, supplierLineItemList, showErrorMessage } =
      this.state;
    if (!this.checkForHazardousItem()) {
      var desc = supplierLineItemList?.filter(
        (x) => x.trackingToolId == selectedValue
      )[0]?.description;
      this.setState(
        {
          addLineItemDescription: desc != null ? desc : value,
        },
        () => {
          // if (value?.trim() != "") {
          var selectedLineItem = supplierLineItemList.filter(
            (x) => x.trackingToolId == selectedValue
          );
          var isLineItemPresent = mdsdocLineItemList.findIndex(
            (x) => x.trackingToolId == selectedValue
          );

          if (isLineItemPresent != -1) {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.Mdsdoc.lineItemAlreadyAddedError,
            });
          }

          if (selectedLineItem.length > 0 && isLineItemPresent == -1) {
            var selectedMDSDoc = selectedLineItem;

            if (selectedMDSDoc.length > 0) {
              var mDSDocList = {
                trackingToolId: selectedMDSDoc[0].trackingToolId,
                description: selectedMDSDoc[0].description,
                ponumber: selectedMDSDoc[0].ponumber,
                quantity: selectedMDSDoc[0].quantity,
                unit: selectedMDSDoc[0].unit,
                equipmentUsed: selectedMDSDoc[0].equipmentUsed,
              };
              mdsdocLineItemList.push(mDSDocList);

              this.setState({
                mdsdocLineItemList: mdsdocLineItemList,
                addLineItemDescription: "",
                addLineItemTrackingToolId: 0,
                addLineItemPONumber: "",
                addLineItemQuantity: "",
                addLineItemUnit: "",
                addLineItemEquipmentUsed: "",
              });
            }
          }
          if (showErrorMessage) this.validate(false);
        }
      );
    }
  };

  onAddLineItemOptionClick = (event) => {
    const selectedValue = event.target.getAttribute("data-value");
    // Handle the selected value as needed
  };

  formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "-" || e.key == "E";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  navigateBack = () => {
    var loc = GetLocalStorageDataWithoutParse("location");
    if (this.state.disableFields) {
      AddLocalStorageData("disableMdsdocFormFields", false);
      if (loc == "SupplierViewItems") this.props.navigate("/SupplierViewItems");
      else if (loc == "AddEditTrackingTool")
        this.props.navigate("/AddEditTrackingTool");
      else if (loc == "IHMDatabaseRegistry")
        this.props.navigate("/ManageIHMDatabaseRegistry");
    } else {
      this.props.navigate("/SupplierDocument");
    }
  };

  onVerifyClicked = async () => {
    //If otp not entered show validation message
    if (isStringEmpty(this.state.otpEntered)) {
      this.setState({
        emptyOtp: "true",
        validationMessage: "Please fill in the mandatory fields.",
      });
    } else {
      const { queryParamMDSDoCID, queryParamSupplierID, otpEntered } =
        this.state;
      //Api call to chk if the password is valid for that mdsdoc
      let mdsdocItemData = new FormData();
      mdsdocItemData.append("supplierId", queryParamSupplierID);
      mdsdocItemData.append("mdsdocId", queryParamMDSDoCID);
      mdsdocItemData.append("otpValue", otpEntered);
      var response = await MdsdocService.VerifyMdsdocLinkPassword(
        mdsdocItemData
      );

      if (response.data != null || response.data != undefined) {
        if (response.data == true) {
          this.setState({
            showPasswordModal: false,
            alreadyAccessedOtp: "",
            emptyOtp: "",
            validationMessage: "",
            otpEntered: "",
          });
        } else {
          this.setState({
            showPasswordModal: true,
            alreadyAccessedOtp: "",
            emptyOtp: "true",
            validationMessage: "Please enter a valid OTP.",
          });
        }
      }
    }
  };

  downloadStampORSignature = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
      });
    } catch (e) { }
  };

  onOTPTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      alreadyAccessedOtp: "",
      emptyOtp: "",
      validationMessage: "",
      otpEntered: value,
    });
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,

      errorMessages,
      showErrorMessage,
      showInvalidEmailErrorMessage,

      declarationDate,
      mdIdNo,
      remark,
      companyName,
      divisionName,
      contactPerson,
      contactNumber,
      faxNumber,
      emailAddress,
      productDescription,
      productNumber,
      amount,
      unitValue,

      asbMass,
      pbMass,
      ocMass,
      ohMass,
      oofhMass,
      octMass,
      otMass,
      ohcMass,
      ohbMass,
      ombMass,
      obcMass,
      oafMass,
      ccMass,
      hcMass,
      llMass,
      mmMass,
      pbbMass,
      pbdMass,
      pMass,
      rsMass,
      ccpMass,
      pfoMass,
      hbcddMass,

      asbThreshold,
      pbThreshold,
      ocThreshold,
      ohThreshold,
      oofhThreshold,
      octThreshold,
      otThreshold,
      ohcThreshold,
      ohbThreshold,
      ombThreshold,
      obcThreshold,
      oafThreshold,
      ccThreshold,
      hcThreshold,
      llThreshold,
      mmThreshold,
      pbbThreshold,
      pbdThreshold,
      pThreshold,
      rsThreshold,
      ccpThreshold,
      pfoThreshold,
      hbcddThreshold,

      dropdown_asbAboveThreshold,
      dropdown_pbAboveThreshold,
      dropdown_ocAboveThreshold,
      dropdown_ohAboveThreshold,
      dropdown_oofhAboveThreshold,
      dropdown_octAboveThreshold,
      dropdown_otAboveThreshold,
      dropdown_ohcAboveThreshold,
      dropdown_ohbAboveThreshold,
      dropdown_ombAboveThreshold,
      dropdown_obcAboveThreshold,
      dropdown_oafAboveThreshold,
      dropdown_ccAboveThreshold,
      dropdown_hcAboveThreshold,
      dropdown_llAboveThreshold,
      dropdown_mmAboveThreshold,
      dropdown_pbbAboveThreshold,
      dropdown_pbdAboveThreshold,
      dropdown_pAboveThreshold,
      dropdown_rsAboveThreshold,
      dropdown_ccpAboveThreshold,
      dropdown_pfoAboveThreshold,
      dropdown_hbcddAboveThreshold,

      asbUnit,
      pbUnit,
      ocUnit,
      ohUnit,
      oofhUnit,
      octUnit,
      otUnit,
      ohcUnit,
      ohbUnit,
      ombUnit,
      obcUnit,
      oafUnit,
      ccUnit,
      hcUnit,
      llUnit,
      mmUnit,
      pbbUnit,
      pbdUnit,
      pUnit,
      rsUnit,
      ccpUnit,
      pfoUnit,
      hbcddUnit,

      asbInfo,
      pbInfo,
      ocInfo,
      ohInfo,
      oofhInfo,
      octInfo,
      otInfo,
      ohcInfo,
      ohbInfo,
      ombInfo,
      obcInfo,
      oafInfo,
      ccInfo,
      hcInfo,
      llInfo,
      mmInfo,
      pbbInfo,
      pbdInfo,
      pInfo,
      rsInfo,
      ccpInfo,
      pfoInfo,
      hbcddInfo,

      sdocNo,
      issuerName,
      issuerAddress,
      supplierIssuerAddress,
      additionalInfo,
      onBehalf,
      placeOfIssue,
      nameFunction,
      signature,
      companyStampUrl,

      ctiRegulationList,
      declarationList,
      remarkList,
      declaration,

      fileInputKey,
      shipProfilePictureUrl,
      isHTMLtoPDFDownload,
      mdsdocLineItemList,
      supplierLineItemList,
      addLineItemDescription,
      addLineItemTrackingToolId,
      addLineItemPONumber,
      addLineItemQuantity,
      addLineItemUnit,
      addLineItemEquipmentUsed,

      isProfileSignature,
      isProfileStamp,
      signatureCustom,
      companyStampCustom,
      disableFields,

      showPasswordModal,
      otpEntered,
      emptyOtp,
      alreadyAccessedOtp,
      queryParamMDSDoCID,
      validationMessage,
      dropdown_poItem,
      loggedInUserRoleId,
      dateOfIssue,
    } = this.state;
    return (
      <div>
        {isLoading && <Loader></Loader>}
        {isStringEmpty(queryParamMDSDoCID) && <Header />}

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {showPasswordModal && (
          <LinkVerificationModal
            showPasswordModal
            onVerifyClick={this.onVerifyClicked}
            onOTPChange={this.onOTPTextChange}
            otpValue={otpEntered}
            showValidationError={emptyOtp}
            showOtpUsedError={alreadyAccessedOtp}
            validationMessage={validationMessage}
          ></LinkVerificationModal>
        )}
        <main className="page-height">
          <section className="communications Viewmd/sdoc">
            <div className="container">
              <div id="print" style={{ backgroundColor: "white" }}>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 heading-bottom-space viewmdsdocflex">
                    <a
                      onClick={() => this.navigateBack()}
                      style={{
                        visibility: isHTMLtoPDFDownload ? "hidden" : "visible",
                      }}
                    >
                      {isStringEmpty(queryParamMDSDoCID) && (
                        <img
                          style={{ cursor: "pointer" }}
                          className="back-btn-responsive"
                          src={backIcon}
                          height="22px"
                        />
                      )}
                    </a>
                    <span className="title-font">Material Declaration</span>
                  </div>
                </div>

                <div className="mdoc-form">
                  <div className="material-dec">
                    {shipProfilePictureUrl == "" ? null : (
                      <img
                        src={shipProfilePictureUrl}
                        alt="mdprofile"
                        className="mdprofile"
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-md-12 divtable divtabledod">
                          <label>Date of Declaration</label>
                          <table>
                            <tbody>
                              <tr>
                                <td className="divtablebg">Date:</td>
                                <td width="70%">
                                  {/* <DatePicker
                                    selectedDate={declarationDate}
                                    name="dateOfDeclaration"
                                    id="dateOfDeclaration"
                                    onDateChange={(e) =>
                                      this.onDateChanged("dateOfDeclaration", e)
                                    }
                                    className="form-control"
                                    title="dateOfDeclaration"
                                    placeholder=""
                                  >
                                    {" "}
                                  </DatePicker> */}
                                  <input
                                    type="text"
                                    className="form-control"
                                    title="dateOfDeclaration"
                                    name="dateOfDeclaration"
                                    id="dateOfDeclaration"
                                    value={declarationDate}
                                    disabled
                                    readOnly
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-md-12 divtable">
                          <label className="required-field">MD ID Number</label>
                          <table>
                            <tbody>
                              <tr>
                                <td className="divtablebg">MD-ID-Number:</td>
                                <td width="70%">
                                  <div
                                    className={
                                      !isStringEmpty(errorMessages.MDIDNumber)
                                        ? "invalid-position"
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="mdIdNo"
                                      name="mdIdNo"
                                      value={mdIdNo}
                                      onChange={this.onTextChange}
                                      disabled={disableFields}
                                    />
                                    {!isStringEmpty(
                                      errorMessages.MDIDNumber
                                    ) && (
                                        <div className="invalid-message-icon">
                                          <img
                                            src="images\icon material-error.png"
                                            alt="invalid"
                                          ></img>
                                        </div>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 divtable divtableoi">
                          <label>Other Information </label>
                          <table>
                            <tbody>
                              {remarkList == null
                                ? null
                                : remarkList.map((item, key, index) => {
                                  return (
                                    <tr key={item.remarkID + 1}>
                                      <td className="divtablebg">
                                        Remark {key + 1}:
                                      </td>
                                      <td width="70%">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="remark"
                                          name="remark"
                                          value={item.remarkText}
                                        />
                                      </td>
                                      <td
                                        width="8%"
                                        className="divtablecenter"
                                      >
                                        {!isHTMLtoPDFDownload &&
                                          !disableFields ? (
                                          <img
                                            src="images/delete icon.png"
                                            alt="delete icon"
                                            height="20px"
                                            onClick={() =>
                                              this.onRemarkDelete(
                                                item.remarkID,
                                                item.index
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) : null}
                                      </td>
                                    </tr>
                                  );
                                })}

                              {!isHTMLtoPDFDownload ? (
                                <tr>
                                  <td className="divtablebg">
                                    Remark{" "}
                                    {remarkList == null
                                      ? null
                                      : remarkList.length + 1}{" "}
                                    :
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="remark"
                                      name="remark"
                                      value={remark}
                                      onChange={this.onTextChange}
                                      disabled={disableFields}
                                    />
                                  </td>
                                  <td className="divtablecenter">
                                    {!disableFields && (
                                      <img
                                        src="images/add.png"
                                        alt="add icon"
                                        height="20px"
                                        onClick={this.onRemarkAdd}
                                        style={{ cursor: "pointer" }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-md-12 divtable">
                          <label>Supplier (Respondent) Information </label>
                          <table>
                            <tbody>
                              <tr>
                                <td className="divtablebg">Company Name:</td>
                                <td width="70%">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    name="companyName"
                                    value={companyName}
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg">Division Name:</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="divisionName"
                                    name="divisionName"
                                    value={divisionName}
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg">Address:</td>
                                <td>
                                  <input
                                    style={{
                                      height: "80px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                    type="text"
                                    className="form-control"
                                    id="supplierIssuerAddress"
                                    name="supplierIssuerAddress"
                                    value={supplierIssuerAddress}
                                    disabled
                                    title={
                                      supplierIssuerAddress != ""
                                        ? supplierIssuerAddress
                                        : null
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg">Contact Person:</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="contactPerson"
                                    name="contactPerson"
                                    value={contactPerson}
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg">
                                  Telephone Number:
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={contactNumber}
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg">Fax Number:</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="faxNumber"
                                    name="faxNumber"
                                    value={faxNumber}
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg">E-mail Address:</td>
                                <td>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    name="emailAddress"
                                    value={emailAddress}
                                    disabled
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="divtablebg required-field">
                                  SDoC ID-Number:
                                </td>
                                <td>
                                  <div
                                    className={
                                      !isStringEmpty(errorMessages.SDoCID)
                                        ? "invalid-position"
                                        : ""
                                    }
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="sdocNo"
                                      name="sdocNo"
                                      value={sdocNo}
                                      onChange={this.onTextChange}
                                      disabled={disableFields}
                                    />

                                    {!isStringEmpty(errorMessages.SDoCID) && (
                                      <div className="invalid-message-icon">
                                        <img
                                          src="images\icon material-error.png"
                                          alt="invalid"
                                        ></img>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="productinfomd">
                    <div
                      className={
                        !isStringEmpty(errorMessages.mdsdocLineItemValidation)
                          ? "invalid-position"
                          : ""
                      }
                    >
                      <label className="required-field">
                        Product Information{" "}
                      </label>
                      <div className="table-responsive-xxl">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "150px" }}
                                className="productname"
                              >
                                Product Name
                              </th>
                              <th style={{ width: "150px" }}>Product Number</th>
                              <th className="deliveunit">
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        colSpan="2"
                                        className="center materinfopadding"
                                      >
                                        Delivered Unit
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="center">Amount</th>
                                      <th className="center unit materinfopadding">
                                        Unit
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                              <th className="center productinfo">
                                Product Information
                              </th>
                              {!isHTMLtoPDFDownload && !disableFields ? (
                                <th className="center">Action</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {mdsdocLineItemList == null
                              ? null
                              : mdsdocLineItemList.map((item, key, index) => {
                                return (
                                  <tr key={item.trackingToolId}>
                                    <td>
                                      <div>
                                        <input
                                          type="text"
                                          style={{
                                            width: "300px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "pre",
                                            overflow: "hidden",
                                          }}
                                          className="form-control"
                                          id="productDescription"
                                          name="productDescription"
                                          value={item.description}
                                          title={item.description}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        style={{
                                          width: "200px",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "pre",
                                          overflow: "hidden",
                                        }}
                                        className="form-control"
                                        id="productNumber"
                                        name="productNumber"
                                        value={item.ponumber}
                                        disabled
                                        title={
                                          item.ponumber != ""
                                            ? item.ponumber
                                            : null
                                        }
                                      />
                                    </td>
                                    <td>
                                      <table className="table-borderless">
                                        <tbody>
                                          <tr></tr>
                                          <tr>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="amount"
                                                name="amount"
                                                value={item.quantity}
                                                disabled
                                                style={{ width: "110px" }}
                                              />
                                            </td>
                                            <td className="deliveunitinfo materinfopadding">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="unitValue"
                                                name="unitValue"
                                                value={item.unit}
                                                disabled
                                                style={{ width: "115px" }}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <textarea
                                        className="form-control prodinfo"
                                        rows="1"
                                        disabled
                                        value={item.equipmentUsed}
                                      ></textarea>
                                    </td>
                                    <td className="divtablecenter">
                                      {!isHTMLtoPDFDownload &&
                                        !disableFields ? (
                                        <img
                                          src="images/delete icon.png"
                                          alt="delete icon"
                                          height="20px"
                                          onClick={() =>
                                            this.onMdsdocLineItemDelete(
                                              item.trackingToolId,
                                              item.id
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            {!isHTMLtoPDFDownload && !disableFields ? (
                              <tr>
                                <td>
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="addLineItemMdsdoc"
                                      name="addLineItemMdsdoc"
                                      list="addLineItemMdsdoc-options"
                                      value={addLineItemDescription}
                                      maxLength={100}
                                      //title={addLineItemDescription}
                                      //  disabled
                                      onChange={
                                        this.onAddLineItemDescriptionTextChange
                                      }
                                      autoComplete="off"
                                      disabled={disableFields}
                                      onClick={this.onInputClick}
                                      ref={(input) =>
                                        (this.inputElement = input)
                                      }
                                    />

                                    <div className="custom-dropdown">
                                      <ul id="addLineItemMdsdoc-options">
                                        {supplierLineItemList == null
                                          ? null
                                          : supplierLineItemList.map(
                                            (item, key, index) => (
                                              <li
                                                key={item.trackingToolId}
                                                data-value={
                                                  item.trackingToolId
                                                }
                                                onClick={
                                                  this
                                                    .onAddLineItemDescriptionTextChange
                                                }
                                              >
                                                {item.description}
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    </div>
                                    {/* <datalist id="addLineItemMdsdoc-options">
                                      {supplierLineItemList == null
                                        ? null
                                        : supplierLineItemList.map((item, key, index) => {
                                          return (
                                              <option
                                                wrap="true"
                                                key={item.trackingToolId}
                                                value={item.trackingToolId}
                                              >{item.description}</option>
                                          );
                                        }
                                        )}
                                    </datalist> */}
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="addLineItemPONumber"
                                    name="addLineItemPONumber"
                                    value={addLineItemPONumber}
                                    onChange={this.onTextChange}
                                    maxLength={50}
                                  //disabled
                                  />
                                </td>
                                <td>
                                  <table className="table-borderless">
                                    <tbody>
                                      <tr></tr>
                                      <tr>
                                        <td>
                                          <input
                                            type="number"
                                            min="0"
                                            max="999"
                                            className="form-control"
                                            id="addLineItemQuantity"
                                            name="addLineItemQuantity"
                                            value={addLineItemQuantity}
                                            //disabled
                                            onChange={this.onTextChange}
                                            style={{ width: "110px" }}
                                          />
                                        </td>
                                        <td className="deliveunitinfo materinfopadding">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="addLineItemUnit"
                                            name="addLineItemUnit"
                                            value={addLineItemUnit}
                                            //disabled
                                            onChange={this.onTextChange}
                                            maxLength={20}
                                            style={{ width: "115px" }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <textarea
                                    className="form-control prodinfo"
                                    rows="1"
                                    name="addLineItemEquipmentUsed"
                                    //disabled
                                    value={addLineItemEquipmentUsed}
                                    onChange={this.onTextChange}
                                    maxLength={100}
                                  ></textarea>
                                </td>
                                <td className="divtablecenter">
                                  <img
                                    src="images/add.png"
                                    alt="add icon"
                                    height="20px"
                                    onClick={() =>
                                      this.onMdsdocLineItemAdd(
                                        addLineItemTrackingToolId
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                      {!isStringEmpty(
                        errorMessages.mdsdocLineItemValidation
                      ) && (
                          <div className="invalid-message-icon">
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                            ></img>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="materialinfo">
                    <label> Material Information </label>
                    <h5>
                      <table className="table-borderless materialinfotable">
                        <tr>
                          <td className="materialinfotd" rowSpan="2">
                            This material information shows the amount of
                            hazardous contained in
                          </td>
                          <td></td>
                          <td className="tdinfo unit">Unit</td>
                        </tr>
                        <tr>
                          <td className="tdinfo">
                            <input
                              type="text"
                              className="form-control"
                              id="amount"
                              name="amount"
                              value={1}
                              disabled
                              style={{ width: "110px" }}
                            />
                          </td>
                          <td className="tdinfo">
                            <input
                              type="text"
                              className="form-control"
                              id="unitValue"
                              name="unitValue"
                              value={"piece"}
                              disabled
                              style={{ width: "115px" }}
                            />
                          </td>
                        </tr>
                      </table>
                    </h5>
                    <div className="table-responsive-xxl">
                      <table className="materialinfotable2 table-bordered">
                        <thead>
                          <tr>
                            <th className="center table">Table</th>
                            <th className="center">Material Name</th>
                            <th className="center">Threshold level</th>
                            <th className="center materinfopadding">
                              <table className="materialinfotable2">
                                <thead>
                                  <tr>
                                    <th className="center materialinfoborder">
                                      Present above threshold level
                                    </th>
                                  </tr>
                                  <tr>
                                    <th className="center">Yes/No</th>
                                  </tr>
                                </thead>
                              </table>
                            </th>
                            <th className="center materinfopadding">
                              <table className="materialinfotable2">
                                <thead>
                                  <tr>
                                    <th
                                      colSpan="2"
                                      className="materialinfoborder materialinfoborder center"
                                    >
                                      If yes, material mass
                                    </th>
                                  </tr>
                                  <tr className="materinfopadding">
                                    <th className="materialinfoborderright center">
                                      Mass
                                    </th>
                                    <th className="center">Unit</th>
                                  </tr>
                                </thead>
                              </table>
                            </th>
                            <th className="center">
                              If yes, information on where it is used
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td rowSpan="12" className="tableinfo center">
                              Table A**
                              <br />
                              <span>
                                (materials listed in appendix 1 of the
                                Convention)
                              </span>
                            </td>
                            <td>Asbestos</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="asbThreshold"
                                name="asbThreshold"
                                value={asbThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="asbAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_asbAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorasbMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="asbMass"
                                          name="asbMass"
                                          value={asbMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorasbMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorasbUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="asbUnit"
                                          name="asbUnit"
                                          value={asbUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorasbUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorasbInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="asbInfo"
                                  name="asbInfo"
                                  value={asbInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorasbInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Polychlorinated biphenyls</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="pbThreshold"
                                name="pbThreshold"
                                value={pbThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="pbAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_pbAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpbMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="pbMass"
                                          name="pbMass"
                                          value={pbMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpbMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpbUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="pbUnit"
                                          name="pbUnit"
                                          value={pbUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpbUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorpbInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pbInfo"
                                  name="pbInfo"
                                  value={pbInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorpbInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="9">
                              <table>
                                <tbody>
                                  <tr>
                                    <td rowSpan="9" className="borderright">
                                      Ozone depleting substance
                                    </td>
                                    <td>Chlorofluorocarbons</td>
                                  </tr>
                                  <tr>
                                    <td>Halons</td>
                                  </tr>
                                  <tr>
                                    <td>Othes fully halogenated</td>
                                  </tr>
                                  <tr>
                                    <td>Carbon tetrachloride</td>
                                  </tr>
                                  <tr>
                                    <td>1,1,1-Trichloroethane</td>
                                  </tr>
                                  <tr>
                                    <td>Hydrochlorofluorocarbons</td>
                                  </tr>
                                  <tr>
                                    <td>Hydrobromofluorocarbons</td>
                                  </tr>
                                  <tr>
                                    <td>Methyl bromide</td>
                                  </tr>
                                  <tr>
                                    <td>Bromochloromethane</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td rowSpan="9" className="center">
                              No threshold level
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ocAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ocAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorocMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ocMass"
                                          name="ocMass"
                                          value={ocMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorocMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorocUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ocUnit"
                                          name="ocUnit"
                                          value={ocUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorocUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorocInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ocInfo"
                                  name="ocInfo"
                                  value={ocInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorocInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ohAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ohAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorohMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ohMass"
                                          name="ohMass"
                                          value={ohMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorohMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorohUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ohUnit"
                                          name="ohUnit"
                                          value={ohUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorohUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorohInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ohInfo"
                                  name="ohInfo"
                                  value={ohInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorohInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="oofhAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_oofhAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.erroroofhMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="oofhMass"
                                          name="oofhMass"
                                          value={oofhMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.erroroofhMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.erroroofhUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="oofhUnit"
                                          name="oofhUnit"
                                          value={oofhUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.erroroofhUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.erroroofhInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="oofhInfo"
                                  name="oofhInfo"
                                  value={oofhInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(
                                  errorMessages.erroroofhInfo
                                ) && (
                                    <div className="invalid-message-icon">
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="octAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_octAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.erroroctMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="octMass"
                                          name="octMass"
                                          value={octMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.erroroctMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.erroroctUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="octUnit"
                                          name="octUnit"
                                          value={octUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.erroroctUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.erroroctInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="octInfo"
                                  name="octInfo"
                                  value={octInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.erroroctInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="otAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_otAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorotMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="otMass"
                                          name="otMass"
                                          value={otMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorotMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorotUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="otUnit"
                                          name="otUnit"
                                          value={otUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorotUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorotInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="otInfo"
                                  name="otInfo"
                                  value={otInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorotInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ohcAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ohcAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorohcMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ohcMass"
                                          name="ohcMass"
                                          value={ohcMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorohcMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorohcUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ohcUnit"
                                          name="ohcUnit"
                                          value={ohcUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorohcUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorohcUnit)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ohcInfo"
                                  name="ohcInfo"
                                  value={ohcInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorohcUnit) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ohbAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ohbAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorohbMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ohbMass"
                                          name="ohbMass"
                                          value={ohbMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorohbMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorohbUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ohbUnit"
                                          name="ohbUnit"
                                          value={ohbUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorohbUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorohbInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ohbInfo"
                                  name="ohbInfo"
                                  value={ohbInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorohbInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ombAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ombAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorombMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ombMass"
                                          name="ombMass"
                                          value={ombMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorombMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorombUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ombUnit"
                                          name="ombUnit"
                                          value={ombUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorombUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorombInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ombInfo"
                                  name="ombInfo"
                                  value={ombInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorombInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="obcAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_obcAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorobcMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="obcMass"
                                          name="obcMass"
                                          value={obcMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorobcMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorobcUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="obcUnit"
                                          name="obcUnit"
                                          value={obcUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorobcUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorobcInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="obcInfo"
                                  name="obcInfo"
                                  value={obcInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorobcInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Anti-fouling systems containing organotin
                              compounds as a biocide
                            </td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="oafThreshold"
                                name="oafThreshold"
                                value={oafThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="oafAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_oafAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.erroroafMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="oafMass"
                                          name="oafMass"
                                          value={oafMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.erroroafMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.erroroafUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="oafUnit"
                                          name="oafUnit"
                                          value={oafUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.erroroafUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.erroroafInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="oafInfo"
                                  name="oafInfo"
                                  value={oafInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.erroroafInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="9" className="tableinfo center">
                              Table B** <br />
                              <span>
                                (materials listed in appendix 2 of the
                                Convention)
                              </span>
                            </td>
                            <td>Cadmium and cadmium compounds</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="ccThreshold"
                                name="ccThreshold"
                                value={ccThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ccAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ccAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorccMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ccMass"
                                          name="ccMass"
                                          value={ccMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorccMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorccUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ccUnit"
                                          name="ccUnit"
                                          value={ccUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorccUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorccInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ccInfo"
                                  name="ccInfo"
                                  value={ccInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorccInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Hexavalent chromium and hexavalent chromium
                              compounds
                            </td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="hcThreshold"
                                name="hcThreshold"
                                value={hcThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="hcAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_hcAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorhcMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="hcMass"
                                          name="hcMass"
                                          value={hcMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorhcMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorhcUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="hcUnit"
                                          name="hcUnit"
                                          value={hcUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorhcUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorhcInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="hcInfo"
                                  name="hcInfo"
                                  value={hcInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorhcInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Lead and lead compounds</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="llThreshold"
                                name="llThreshold"
                                value={llThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="llAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_llAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorllMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="llMass"
                                          name="llMass"
                                          value={llMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorllMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorllUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="llUnit"
                                          name="llUnit"
                                          value={llUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorllUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorllInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="llInfo"
                                  name="llInfo"
                                  value={llInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorllInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Mercury and mercury compounds</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="mmThreshold"
                                name="mmThreshold"
                                value={mmThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="mmAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_mmAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errormmMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="mmMass"
                                          name="mmMass"
                                          value={mmMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errormmMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errormmUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="mmUnit"
                                          name="mmUnit"
                                          value={mmUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errormmUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errormmInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mmInfo"
                                  name="mmInfo"
                                  value={mmInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errormmInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Polybrominated biphenyl (PBBs)</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="pbbThreshold"
                                name="pbbThreshold"
                                value={pbbThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="pbbAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_pbbAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpbbMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="pbbMass"
                                          name="pbbMass"
                                          value={pbbMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpbbMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpbbUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="pbbUnit"
                                          name="pbbUnit"
                                          value={pbbUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpbbUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorpbbInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pbbInfo"
                                  name="pbbInfo"
                                  value={pbbInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorpbbInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Polybrominated dephenyl ethers (PBDEs)</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="pbdThreshold"
                                name="pbdThreshold"
                                value={pbdThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="pbdAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_pbdAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpbdMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="pbdMass"
                                          name="pbdMass"
                                          value={pbdMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpbdMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpbdUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="pbdUnit"
                                          name="pbdUnit"
                                          value={pbdUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpbdUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorpbdInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pbdInfo"
                                  name="pbdInfo"
                                  value={pbdInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorpbdInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Polychloronaphthalenes (Cl ;= 3)</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="pThreshold"
                                name="pThreshold"
                                value={pThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="pAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_pAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="pMass"
                                          name="pMass"
                                          value={pMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="pUnit"
                                          name="pUnit"
                                          value={pUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorpInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pInfo"
                                  name="pInfo"
                                  value={pInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorpInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Radioactive substances</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="rsThreshold"
                                name="rsThreshold"
                                value={rsThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="rsAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_rsAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorsMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="rsMass"
                                          name="rsMass"
                                          value={rsMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorsMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorsUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="rsUnit"
                                          name="rsUnit"
                                          value={rsUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorsUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorsInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="rsInfo"
                                  name="rsInfo"
                                  value={rsInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorsInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Certain shortchain chlorinated paraffins</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="ccpThreshold"
                                name="ccpThreshold"
                                value={ccpThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="ccpAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_ccpAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorccpMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="ccpMass"
                                          name="ccpMass"
                                          value={ccpMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorccpMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorccpUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ccpUnit"
                                          name="ccpUnit"
                                          value={ccpUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorccpUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorccpInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ccpInfo"
                                  name="ccpInfo"
                                  value={ccpInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorccpInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="2" className="tableinfo center">
                              Annex II*** <br />
                              <span>(Additional materials)</span>
                            </td>
                            <td>Perfluorooctane sulfonic acid (PFOS)</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="pfoThreshold"
                                name="pfoThreshold"
                                value={pfoThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="pfoAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_pfoAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpfoMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="pfoMass"
                                          name="pfoMass"
                                          value={pfoMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpfoMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorpfoUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="pfoUnit"
                                          name="pfoUnit"
                                          value={pfoUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorpfoUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorpfoInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pfoInfo"
                                  name="pfoInfo"
                                  value={pfoInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(errorMessages.errorpfoInfo) && (
                                  <div className="invalid-message-icon">
                                    <img
                                      src="images\icon material-error.png"
                                      alt="invalid"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Brominated Flame Retardant(HBCDD)</td>
                            <td className="center">
                              <input
                                type="text"
                                className="form-control"
                                id="hbcddThreshold"
                                name="hbcddThreshold"
                                value={hbcddThreshold}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </td>
                            <td>
                              <YesNoDropdown
                                dropdownLabel=""
                                name="hbcddAboveThreshold"
                                onChange={this.onFilterSelected}
                                selected={dropdown_hbcddAboveThreshold}
                                disabled={disableFields}
                              ></YesNoDropdown>
                            </td>
                            <td className="materinfopadding">
                              <table className="materialinfotable2">
                                <tbody>
                                  <tr></tr>
                                  <tr className="materinfopadding mass-unit">
                                    <td className="materialinfoborderright">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorhbcddMass
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="hbcddMass"
                                          name="hbcddMass"
                                          value={hbcddMass}
                                          onChange={this.onTextChange}
                                          onKeyDown={this.formatInput}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorhbcddMass
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorhbcddUnit
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="hbcddUnit"
                                          name="hbcddUnit"
                                          value={hbcddUnit}
                                          onChange={this.onTextChange}
                                          disabled={disableFields}
                                        />
                                        {!isStringEmpty(
                                          errorMessages.errorhbcddUnit
                                        ) && (
                                            <div className="invalid-message-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <div
                                className={
                                  !isStringEmpty(errorMessages.errorhbcddInfo)
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="hbcddInfo"
                                  name="hbcddInfo"
                                  value={hbcddInfo}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                />
                                {!isStringEmpty(
                                  errorMessages.errorhbcddInfo
                                ) && (
                                    <div className="invalid-message-icon">
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="mdoc-notice-form">
                    *Please refer to footnote 18 on the "Form of Material
                    Declaration" in the IMO Guidelines Resolution MEPC.269(68).
                    <br />
                    **Hong Kong International Convention for the Safe and
                    Environmentally Sound Recycling of Ships, 2009 (
                    SR/CONF/45).
                    <br />
                    ***Regulation EU Number 1257/2013 of the European Parliament
                    and of the Council of 20 November 2013 on Ship Recycling and
                    amending Regulation EC Number 1013/2006 and Directive
                    2009/16/EC.
                    <br />
                  </p>
                  <p className="mdoc-notice-form">
                    <span>Important Notice:</span> The content and
                    specifications of this form may not be changed or amended.
                    Any changes or amendments by others than the author of this
                    form constitute a breach of copyright law.
                  </p>
                </div>
                <div className="sdoc-form">
                  <h4 className="center">
                    Supplier's Declaration of Conformity for Material
                    Declaration Management
                  </h4>
                  <div className="sdoc-main-form">
                    <div className="material-dec">
                      {shipProfilePictureUrl == "" ? null : (
                        <img
                          src={shipProfilePictureUrl}
                          alt="mdprofile"
                          className="mdprofile"
                        />
                      )}
                    </div>
                    <div className="pb3">
                      <div className="row">
                        <div className="col-md-3">
                          <span>
                            1)<h5>SDoC Number:</h5>
                          </span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="sdocNo"
                            name="sdocNo"
                            value={sdocNo}
                            onChange={this.onTextChange}
                            disabled={disableFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pb3">
                      <div className="row">
                        <div className="col-md-3">
                          <span>
                            2)<h5>Issuer's Name:</h5>
                          </span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="issuerName"
                            name="issuerName"
                            value={issuerName}
                            onChange={this.onTextChange}
                            disabled={disableFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pb3">
                      <div className="row">
                        <div className="col-md-3">
                          <span>
                            <h5>Issuer's Address:</h5>
                          </span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="issuerAddress"
                            name="issuerAddress"
                            value={issuerAddress}
                            onChange={this.onTextChange}
                            disabled={disableFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pb3">
                      <div className="row">
                        <div className="col-md-3">
                          <span>
                            3)<h5>Object(s) of Declaration:</h5>
                          </span>
                        </div>
                        <div className="col-md-9 table-responsive">
                          <table>
                            {declarationList == null
                              ? null
                              : declarationList.map((item, key, index) => {
                                return (
                                  <tr key={item.declarationID}>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="declaration"
                                        name="declaration"
                                        value={item.declarationText}
                                      />
                                    </td>
                                    <td width="8%" className="center">
                                      {!isHTMLtoPDFDownload &&
                                        !disableFields ? (
                                        <img
                                          src="images/delete icon.png"
                                          alt="delete icon"
                                          height="30px"
                                          onClick={() =>
                                            this.onDeclarationDelete(
                                              item.declarationID,
                                              item.index
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                        {!isHTMLtoPDFDownload ? (
                          <div className="pb3">
                            <div className="row">
                              <div className="col-md-3"></div>
                              <div className="col-md-9 table-responsive">
                                <table>
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="declaration"
                                        name="declaration"
                                        value={declaration}
                                        onChange={this.onTextChange}
                                        disabled={disableFields}
                                      />
                                    </td>
                                    <td width="8%" className="center">
                                      {!disableFields && (
                                        <img
                                          src="images/add.png"
                                          alt="add icon"
                                          height="30px"
                                          onClick={() =>
                                            this.onDeclarationAdd()
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="pb3">
                      <h6>
                        4) The object(s) of the declaration described above
                        is/are in conformity with the following documents
                      </h6>
                    </div>
                    <div className="pb3">
                      <h5 className="textalign">
                        5) Applicable regulations or other stipulated
                        requirements and documents
                      </h5>
                    </div>

                    <div className="pb-3">
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th className="center doc-no">Document Number</th>
                              <th className="title">Title</th>
                              <th className="center doi">Date of Issue</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ctiRegulationList == null
                              ? null
                              : ctiRegulationList.map((item, key, index) => {
                                return (
                                  <tr key={item.documentNo}>
                                    <td className="center doc-no">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="declarationMore"
                                        name="declarationMore"
                                        value={item.documentNo}
                                        disabled
                                      />
                                    </td>
                                    <td className="title">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="declarationMore"
                                        name="declarationMore"
                                        value={item.title}
                                        disabled
                                      />
                                    </td>
                                    <td className="center doi">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="declarationMore"
                                        name="declarationMore"
                                        value={item.issueDate}
                                        disabled
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="pb3">
                      <div className="row">
                        <div className="col-md-3">
                          <span>
                            6)<h5>Additional Information:</h5>
                          </span>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            className="form-control"
                            rows="2"
                            name="additionalInfo"
                            id="additionalInfo"
                            value={additionalInfo}
                            onChange={this.onTextChange}
                            disabled={disableFields}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="pb3">
                      <div className="row">
                        <div className="col-md-3">
                          <span>
                            <h5>Signed for and on behalf of:</h5>
                          </span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            id="onBehalf"
                            name="onBehalf"
                            value={onBehalf}
                            onChange={this.onTextChange}
                            disabled={disableFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pt4">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="pb4">
                            <div className="displayrow">
                              <h5> </h5>
                              <input
                                type="text"
                                className="form-control"
                                id="placeOfIssue"
                                name="placeOfIssue"
                                value={placeOfIssue}
                                onChange={this.onTextChange}
                                disabled={disableFields}
                              />
                            </div>
                            <h5> Place of Issue</h5>
                          </div>
                          <div className="pb4">
                            <div className="displayrow">
                              <h5> </h5>
                              <div
                                className={
                                  !isStringEmpty(
                                    errorMessages.dateOfIssueValidation
                                  )
                                    ? "invalid-position"
                                    : ""
                                }
                              >
                                <DatePicker
                                  selectedDate={dateOfIssue}
                                  name="dateOfIssue"
                                  id="dateOfIssue"
                                  onDateChange={(e) =>
                                    this.onDateChanged("dateOfIssue", e)
                                  }
                                  className="form-control"
                                  title="dateOfIssue"
                                  placeholder=""
                                  disabled={disableFields}
                                >
                                  {" "}
                                </DatePicker>

                                {!isStringEmpty(
                                  errorMessages.dateOfIssueValidation
                                ) && (
                                    <div className="invalid-message-icon">
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <h5 className="required-field"> Date of Issue</h5>
                          </div>
                          <div className="pb3">
                            <div className="displayrow">
                              <h5>7) </h5>
                              <div
                                style={{ paddingLeft: "10px" }}
                                className={
                                  !isStringEmpty(
                                    errorMessages.nameFunctionValidation
                                  )
                                    ? "col-md-9 invalid-position"
                                    : "col-md-9"
                                }
                              >
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="nameFunction"
                                  name="nameFunction"
                                  value={nameFunction}
                                  onChange={this.onTextChange}
                                  disabled={disableFields}
                                  maxLength={50}
                                />

                                {!isStringEmpty(
                                  errorMessages.nameFunctionValidation
                                ) && (
                                    <div className="invalid-message-icon">
                                      <img
                                        src="images\icon material-error.png"
                                        alt="invalid"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <h5
                              className="required-field"
                              style={{ paddingLeft: "24px" }}
                            >
                              {" "}
                              Name, Function
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-6 divasinputmain">
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="divInputmdsdoc">
                                    {(signature != "" &&
                                      isProfileSignature === true) ||
                                      (signatureCustom != "" &&
                                        isProfileSignature === false) ? (
                                      <img
                                        src={
                                          signature != "" &&
                                            isProfileSignature === true
                                            ? signature
                                            : signatureCustom != "" &&
                                              isProfileSignature === false
                                              ? signatureCustom
                                              : null
                                        }
                                        alt="digital signature"
                                        style={{
                                          objectFit: "contain",
                                          height: "105px",
                                          width: "140px",
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                  {(signature != "" || signatureCustom != "") &&
                                    (loggedInUserRoleId === 1 ||
                                      loggedInUserRoleId === 2) && (
                                      <img
                                        src={shipdownloaddocumentIcon}
                                        alt="download signature"
                                        style={{
                                          height: "20px",
                                          cursor: "pointer",
                                          width: "20px",
                                          marginLeft: "4px",
                                        }}
                                        onClick={(e) =>
                                          this.downloadStampORSignature(
                                            signature != "" &&
                                              isProfileSignature === true
                                              ? signature
                                              : signatureCustom != "" &&
                                                isProfileSignature === false
                                                ? signatureCustom
                                                : null
                                          )
                                        }
                                      />
                                    )}
                                </div>
                                <h5 className="divasinputdata">Signature</h5>
                              </div>
                              {!isHTMLtoPDFDownload && !disableFields ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span className="download-dropdown">
                                    <a
                                      className="dropdown-toggle download-dropdown"
                                      href="#"
                                      id="downloadDropdown"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img
                                        src={FileUploadIcon}
                                        alt=""
                                        className=""
                                        height="45px"
                                      />
                                    </a>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="downloadDropdown"
                                    >
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.setState({
                                              isProfileSignature: true,
                                            })
                                          }
                                        >
                                          From Profile
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.refs.fileSignInput.click()
                                          }
                                        >
                                          From Device
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.setState({
                                              isProfileSignature: null,
                                            })
                                          }
                                        >
                                          Remove
                                        </a>
                                      </li>
                                    </ul>
                                  </span>
                                </div>
                              ) : null}
                              <input
                                ref="fileSignInput"
                                type="file"
                                accept={validFileFormatsReadable}
                                id="fileSignInput"
                                name="documentSignFile"
                                onChange={this.onAttachmentChange}
                                key={fileInputKey || ""}
                                style={{
                                  display: "none",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div className="col-md-6">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="divInputmdsdoc">
                                    {(companyStampUrl != "" &&
                                      isProfileStamp === true) ||
                                      (companyStampCustom != "" &&
                                        isProfileStamp === false) ? (
                                      <img
                                        src={
                                          companyStampUrl != "" &&
                                            isProfileStamp === true
                                            ? companyStampUrl
                                            : companyStampCustom != "" &&
                                              isProfileStamp === false
                                              ? companyStampCustom
                                              : null
                                        }
                                        alt="company stamp"
                                        style={{
                                          objectFit: "contain",
                                          height: "105px",
                                          width: "140px",
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                  {(companyStampUrl != "" ||
                                    companyStampCustom != "") &&
                                    (loggedInUserRoleId === 1 ||
                                      loggedInUserRoleId === 2) && (
                                      <img
                                        src={shipdownloaddocumentIcon}
                                        alt="download signature"
                                        style={{
                                          height: "20px",
                                          cursor: "pointer",
                                          width: "20px",
                                          marginLeft: "4px",
                                        }}
                                        onClick={(e) =>
                                          this.downloadStampORSignature(
                                            companyStampUrl != "" &&
                                              isProfileStamp === true
                                              ? companyStampUrl
                                              : companyStampCustom != "" &&
                                                isProfileStamp === false
                                                ? companyStampCustom
                                                : null
                                          )
                                        }
                                      />
                                    )}
                                </div>
                                <h5 className="divasinputdata">
                                  Stamp{" "}
                                  <span style={{ display: "inline" }}>
                                    {"(optional)"}
                                  </span>
                                </h5>
                              </div>
                              {!isHTMLtoPDFDownload && !disableFields ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span className="download-dropdown">
                                    <a
                                      className="dropdown-toggle download-dropdown"
                                      href="#"
                                      id="downloadDropdown"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img
                                        src={FileUploadIcon}
                                        alt=""
                                        className=""
                                        height="45px"
                                      />
                                    </a>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="downloadDropdown"
                                    >
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.setState({
                                              isProfileStamp: true,
                                            })
                                          }
                                        >
                                          From Profile
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.refs.fileStampInput.click()
                                          }
                                        >
                                          From Device
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.setState({
                                              isProfileStamp: null,
                                            })
                                          }
                                        >
                                          Remove
                                        </a>
                                      </li>
                                    </ul>
                                  </span>
                                </div>
                              ) : null}
                              <input
                                ref="fileStampInput"
                                type="file"
                                accept={validFileFormatsReadable}
                                id="fileStampInput"
                                name="documentStampFile"
                                onChange={this.onAttachmentChange}
                                key={fileInputKey || ""}
                                style={{
                                  display: "none",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isHTMLtoPDFDownload && (
                <div id="printNewDesign" style={{ backgroundColor: "white" }}>
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <h4 className="title-font center">
                        Material Declaration
                      </h4>
                    </div>
                  </div>

                  <div className="mdoc-form pdf-mdoc-form">
                    <div className="material-dec">
                      {shipProfilePictureUrl == "" ? null : (
                        <img
                          src={shipProfilePictureUrl}
                          alt="mdprofile"
                          className="mdprofile"
                        />
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-md-12 divtable divtabledod">
                            <label>Date of Declaration </label>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="divtablebg">Date:</td>
                                  <td width="62%">
                                    <div className="div-input form-control">
                                      {convertDate(declarationDate)}
                                    </div>
                                    {/* {declarationDate} */}
                                  </td>
                                  <td width="8%" />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-md-12 divtable">
                            <label>MD ID Number </label>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="divtablebg">MD-ID-Number:</td>
                                  <td width="62%">
                                    <div className="form-control mdidno div-input">
                                      {mdIdNo}
                                    </div>
                                  </td>
                                  <td width="8%" />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 divtable divtableoi">
                            <label>Other Information </label>
                            <table>
                              <tbody>
                                {remarkList.length == 0 ? (
                                  <tr>
                                    <td className="divtablebg">Remark 1:</td>
                                    <td width="62%">
                                      <div className="form-control other-info div-input">
                                        {""}
                                      </div>
                                    </td>
                                    <td
                                      width="8%"
                                      className="divtablecenter"
                                    ></td>
                                  </tr>
                                ) : (
                                  remarkList.map((item, key, index) => {
                                    return (
                                      <tr key={item.remarkID + 1}>
                                        <td className="divtablebg">
                                          Remark {key + 1}:
                                        </td>
                                        <td width="62%">
                                          <div className="form-control other-info">
                                            {item.remarkText}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-md-12 divtable">
                            <label>Supplier (Respondent) Information </label>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="divtablebg">Company Name:</td>
                                  <td width="70%">
                                    <div
                                      type="name"
                                      className="form-control companyname div-input"
                                    >
                                      {companyName}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">Division Name:</td>
                                  <td>
                                    <div
                                      type="name"
                                      className="form-control div-input"
                                    >
                                      {divisionName}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">Address:</td>
                                  <td>
                                    <div className="form-control div-input">
                                      {supplierIssuerAddress}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">
                                    Contact Person:
                                  </td>
                                  <td>
                                    <div className="form-control div-input">
                                      {contactPerson}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">
                                    Telephone Number:
                                  </td>
                                  <td>
                                    <div className="form-control div-input">
                                      {contactNumber}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">Fax Number:</td>
                                  <td>
                                    <div className="form-control div-input">
                                      {faxNumber}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">
                                    E-mail Address:
                                  </td>
                                  <td>
                                    <div className="form-control div-input">
                                      {emailAddress}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="divtablebg">SDoC ID-Number:</td>
                                  <td>
                                    <div className="form-control div-input">
                                      {sdocNo}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="productinfomd">
                      <label>Product Information </label>
                      <div className="table-responsive-xxl">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="productname">Product Name</th>
                              <th>Product Number</th>
                              <th className="deliveunit">
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        colSpan={2}
                                        className="center materinfopadding"
                                      >
                                        Delivered Unit
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="center">Amount</th>
                                      <th className="center unit materinfopadding">
                                        Unit
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                              <th className="center productinfo">
                                Product Information
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {mdsdocLineItemList.length == 0 ? (
                              <tr>
                                <td>
                                  <div className="tdasinput div-input">
                                    {""}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-control prodno">
                                    {""}
                                  </div>
                                </td>
                                <td>
                                  <table className="table-borderless">
                                    <tbody>
                                      <tr />
                                      <tr>
                                        <td>
                                          <div className="form-control amt-input">
                                            {""}
                                          </div>
                                        </td>
                                        <td className="deliveunitinfo materinfopadding">
                                          <div className="form-control div-input">
                                            {""}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  <div className="form-control div-input">
                                    {""}
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              mdsdocLineItemList.map((item, key, index) => {
                                return (
                                  <tr key={item.trackingToolId}>
                                    <td>
                                      <div className="tdasinput">
                                        {item.description}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-control prodno">
                                        {item.ponumber}
                                      </div>
                                    </td>
                                    <td>
                                      <table className="table-borderless">
                                        <tbody>
                                          <tr />
                                          <tr>
                                            <td>
                                              <div className="form-control amt-input">
                                                {item.quantity}
                                              </div>
                                            </td>
                                            <td className="deliveunitinfo materinfopadding">
                                              <div className="form-control div-input">
                                                {item.unit}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <div className="form-control div-input">
                                        {item.equipmentUsed}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="materialinfo" style={{ width: "100%" }}>
                      <label>Material Information</label>
                      <h5>
                        <table className="table-borderless materialinfotable">
                          <tr>
                            <td className="materialinfotd" rowSpan="2">
                              This material information shows the amount of
                              hazardous contained in
                            </td>
                            <td></td>
                            <td className="tdinfo unit">Unit</td>
                          </tr>
                          <tr>
                            <td className="tdinfo">
                              <input
                                type="text"
                                className="form-control"
                                id="amount"
                                name="amount"
                                value={1}
                                disabled
                                style={{ width: "110px" }}
                              />
                            </td>
                            <td className="tdinfo">
                              <input
                                type="text"
                                className="form-control"
                                id="unitValue"
                                name="unitValue"
                                value={"piece"}
                                disabled
                                style={{ width: "115px" }}
                              />
                            </td>
                          </tr>
                        </table>
                      </h5>
                      <div className="staticborder-new">
                        <table className="materialinfotable2 table-responsive-xxl">
                          <thead className="">
                            <tr className="testtr staticborder-new">
                              <th className="center table staticborder-new">
                                Table
                              </th>
                              <th className="center staticborder-new">
                                Material Name
                              </th>
                              <th className="center staticborder-new">
                                Threshold level
                              </th>
                              <th className="center materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <thead>
                                    <tr>
                                      <th className="center materialinfoborder">
                                        Present above threshold level
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="center ">Yes/No</th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                              <th className="center materinfopadding ">
                                <table className="materialinfotable2 staticborder-new">
                                  <thead>
                                    <tr>
                                      <th
                                        colSpan={2}
                                        className="materialinfoborder materialinfoborder center"
                                      >
                                        If yes, material mass
                                      </th>
                                    </tr>
                                    <tr className="materinfopadding">
                                      <th className="materialinfoborderright center">
                                        Mass
                                      </th>
                                      <th className="center">Unit</th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                              <th className="center th6 staticborder-new">
                                If yes, information on where it is used
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td rowSpan={13} className="tableinfo center">
                                {"Table A**"}
                                <br />
                                <span>
                                  {
                                    "(materials listed in appendix 1 of the Convention)"
                                  }
                                </span>
                              </td>
                              <td className="staticborder-new">Asbestos</td>
                              <td className="center staticborder-new">
                                <div>{asbThreshold}</div>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  <div>
                                    {dropdown_asbAboveThreshold == 1
                                      ? "Yes"
                                      : dropdown_asbAboveThreshold == 2
                                        ? "No"
                                        : ""}
                                  </div>
                                  {/* <div>No</div> */}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    <tr />
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {asbMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          <div value={1} className="">
                                            {asbUnit}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input staticborder-new">
                                  {asbInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Polychlorinated biphenyls
                              </td>
                              <td className="center">{pbThreshold}</td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  <div>
                                    {dropdown_pbAboveThreshold == 1
                                      ? "Yes"
                                      : dropdown_pbAboveThreshold == 2
                                        ? "No"
                                        : ""}
                                  </div>
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {pbMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          <div className="" value={1}>
                                            {pbUnit}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input staticborder-new">
                                  {pbInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan={10} className="staticborder-new">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td
                                        rowSpan={10}
                                        className="borderright"
                                        style={{ width: "5%" }}
                                      >
                                        <span>Ozone depleting substance</span>
                                      </td>
                                      <td className="">Chlorofluorocarbons</td>
                                    </tr>
                                    <tr>
                                      <td className="">Halons</td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Othes fully halogenated
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Carbon tetrachloride</td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        1,1,1-Trichloroethane
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Hydrochlorofluorocarbons
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Hydrobromofluorocarbons
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Methyl bromide</td>
                                    </tr>
                                    <tr>
                                      <td className="">Bromochloromethane</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                rowSpan={10}
                                className="center staticborder-new"
                              >
                                <span>No threshold level</span>
                              </td>
                              <td>
                                <div className="form-control div-input staticborder-new">
                                  {dropdown_ocAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit staticborder-new">
                                      <td className="materialinfoborderright staticborder-new">
                                        <div className="form-control div-input staticborder-new">
                                          {ocMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input staticborder-new">
                                          {ocUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <div className="form-control div-input staticborder-new">
                                  {ocInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  <div>
                                    {dropdown_ohAboveThreshold == 1
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright ">
                                        <div className="form-control div-input">
                                          {ohMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {ohUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input staticborder-new">
                                  {ohInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_oofhAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit ">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input ">
                                          {oofhMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {oofhUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {oofhInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_octAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {octMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {octUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {octInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_otAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {otMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {otUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {otInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_ohcAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {ohcMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {ohcUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {ohcInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_ohbAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {ohbMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {ohbUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {ohbInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_ombAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {ombMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {ombUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {ombInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_obcAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {obcMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {obcUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {obcInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Anti-fouling systems containing organotin
                                compounds as a biocide
                              </td>
                              <td className="center staticborder-new">
                                {oafThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_oafAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {oafMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {oafUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {oafInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                rowSpan={9}
                                className="tableinfo center staticborder-new"
                              >
                                Table B** <br />
                                <span>
                                  (materials listed in appendix 2 of the
                                  Convention)
                                </span>
                              </td>
                              <td className="staticborder-new">
                                Cadmium and cadmium compounds
                              </td>
                              <td className="center staticborder-new">
                                {ccThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  <div>
                                    {dropdown_ccAboveThreshold == 1
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {ccMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {ccUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {ccInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Hexavalent chromium and hexavalent chromium
                                compounds
                              </td>
                              <td className="center staticborder-new">
                                {hcThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_hcAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {hcMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {hcUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {hcInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Lead and lead compounds
                              </td>
                              <td className="center staticborder-new">
                                <div>{llThreshold}</div>
                              </td>
                              <td>
                                <div className="form-control div-input">
                                  {dropdown_llAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {llMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {llUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {llInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Mercury and mercury compounds
                              </td>
                              <td className="center staticborder-new">
                                {mmThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_mmAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {mmMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {mmUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {mmInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Polybrominated biphenyl (PBBs)
                              </td>
                              <td className="center staticborder-new">
                                {pbbThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_pbbAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {pbbMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {pbbUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {pbbInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Polybrominated dephenyl ethers (PBDEs)
                              </td>
                              <td className="center staticborder-new">
                                <div>{pbdThreshold}</div>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_pbdAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {pbdMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {pbdUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {pbdInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Polychloronaphthalenes (Cl = 3)
                              </td>
                              <td className="center staticborder-new">
                                <div>{pThreshold}</div>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  <div>
                                    {dropdown_pAboveThreshold == 1
                                      ? "Yes"
                                      : "No"}
                                  </div>
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {pMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {pUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {pInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Radioactive substances
                              </td>
                              <td className="center staticborder-new">
                                {rsThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_rsAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {rsMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {rsUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {rsInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Certain shortchain chlorinated paraffins
                              </td>
                              <td className="center staticborder-new">
                                {ccpThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_ccpAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {ccpMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {ccpUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {ccpInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                rowSpan={3}
                                className="tableinfo center staticborder-new"
                              >
                                Annex II*** <br />
                                <span>(Additional materials)</span>
                              </td>
                              <td className="staticborder-new">
                                Perfluorooctane sulfonic acid (PFOS)
                              </td>
                              <td className="center staticborder-new">
                                {pfoThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_pfoAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {pfoMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {pfoUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {pfoInfo}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="staticborder-new">
                                Brominated Flame Retardant(HBCDD)
                              </td>
                              <td className="center staticborder-new">
                                {hbcddThreshold}
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {dropdown_hbcddAboveThreshold == 1
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </td>
                              <td className="materinfopadding staticborder-new">
                                <table className="materialinfotable2">
                                  <tbody>
                                    {/* <tr /> */}
                                    <tr className="materinfopadding mass-unit">
                                      <td className="materialinfoborderright">
                                        <div className="form-control div-input">
                                          {hbcddMass}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control div-input">
                                          {hbcddUnit}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="staticborder-new">
                                <div className="form-control div-input">
                                  {hbcddInfo}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p className="mdoc-notice-form">
                      *Please refer to footnote 18 on the "Form of Material
                      Declaration" in the IMO Guidelines Resolution
                      MEPC.269(68).
                      <br />
                      **Hong Kong International Convention for the Safe and
                      Environmentally Sound Recycling of Ships, 2009 (
                      SR/CONF/45).
                      <br />
                      ***Regulation EU Number 1257/2013 of the European Parliament
                      and of the Council of 20 November 2013 on Ship Recycling
                      and amending Regulation EC Number 1013/2006 and Directive
                      2009/16/EC.
                      <br />
                    </p>
                    <p className="mdoc-notice-form">
                      <span>Important Notice:</span> The content and
                      specifications of this form may not be changed or amended.
                      Any changes or amendments by others than the author of
                      this form constitute a breach of copyright law.
                    </p>
                  </div>
                  <div className="sdoc-form">
                    <h4 className="center">
                      Supplier's Declaration of Conformity for Material
                      Declaration Management
                    </h4>
                    <div className="sdoc-main-form">
                      {shipProfilePictureUrl == "" ? null : (
                        <div className="material-dec d-flex">
                          <div className="divasinput">
                            <img
                              src={shipProfilePictureUrl}
                              alt="mdprofile"
                              className="mdprofile"
                            />
                          </div>
                        </div>
                      )}
                      <div className="pb3">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              1)<h5>SDoC Number:</h5>
                            </span>
                          </div>
                          <div className="col-md-9 table-responsive">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="form-control div-input">
                                      {sdocNo}
                                    </div>
                                  </td>
                                  <td width="8%" />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="pb3">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              2)<h5>Issuer's Name:</h5>
                            </span>
                          </div>
                          <div className="col-md-9 table-responsive">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="form-control div-input">
                                      {issuerName}
                                    </div>
                                  </td>
                                  <td width="8%" />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="pb3">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              <h5>Issuer's Address:</h5>
                            </span>
                          </div>
                          <div className="col-md-9 table-responsive">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="form-control div-input">
                                      {issuerAddress}
                                    </div>
                                  </td>
                                  <td width="8%" />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="pb3">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              3)<h5>Object(s) of Declaration:</h5>
                            </span>
                          </div>
                          <div className="col-md-9 table-responsive">
                            <table>
                              <tbody>
                                {declarationList.length == 0 ? (
                                  <tr>
                                    <td>
                                      <div className="form-control div-input"></div>
                                    </td>
                                    <td width="8%" />
                                  </tr>
                                ) : (
                                  declarationList.map((item, key, index) => {
                                    return (
                                      <tr key={item.declarationID}>
                                        <td>
                                          <div className="form-control">
                                            {item.declarationText}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="pb3">
                        <h6>
                          4)&nbsp;&nbsp;&nbsp;The object(s) of the declaration
                          described above is/are in conformity with the
                          following documents
                        </h6>
                      </div>
                      <div className="pb3">
                        <h5 className="textalign">
                          5)&nbsp;&nbsp;Applicable regulations or other
                          stipulated requirements and documents
                        </h5>
                      </div>
                      <div className="pb3">
                        <div className="table-responsive">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th className="center doc-no">
                                          Document Number
                                        </th>
                                        <th className="title center">Title</th>
                                        <th className="center doi">
                                          Date of Issue
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ctiRegulationList.length == 0
                                        ? null
                                        : ctiRegulationList.map(
                                          (item, key, index) => {
                                            return (
                                              <tr key={item.documentNo}>
                                                <td className="center doc-no">
                                                  <div className="form-control">
                                                    {item.documentNo}
                                                  </div>
                                                </td>
                                                <td className="title">
                                                  <div className="form-control">
                                                    {item.title}
                                                  </div>
                                                </td>
                                                <td className="center doi">
                                                  <div className="form-control">
                                                    {convertDate(
                                                      item.issueDate
                                                    )}
                                                  </div>
                                                  {/* {item.issueDate} */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="pb3">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              6)<h5>Additional Information:</h5>
                            </span>
                          </div>
                          <div className="col-md-9">
                            <div className="form-control div-input">
                              {additionalInfo}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pb3">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              &nbsp;&nbsp;&nbsp;{" "}
                              <h5>Signed for and on behalf of:</h5>
                            </span>
                          </div>
                          <div className="col-md-9">
                            <div className="form-control div-input">
                              {onBehalf}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="pb4">
                              <div className="displayrow">
                                <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h5>
                                <div className="form-control div-input">
                                  {placeOfIssue}
                                </div>
                              </div>
                              <h5>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Place of Issue
                              </h5>
                            </div>
                            <div className="pb4">
                              <div className="displayrow">
                                <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h5>
                                <div className="form-control div-input">
                                  {dateOfIssue == null ||
                                    dateOfIssue == undefined ||
                                    dateOfIssue == ""
                                    ? null
                                    : convertDate(dateOfIssue)}
                                </div>
                              </div>
                              <h5>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date of Issue
                              </h5>
                            </div>
                            <div className="pb3">
                              <div className="displayrow">
                                <h5>7)&nbsp;&nbsp; </h5>
                                <div className="form-control div-input">
                                  {nameFunction}
                                </div>
                              </div>
                              <h5>&nbsp;&nbsp;&nbsp;&nbsp; Name, Function</h5>
                            </div>
                          </div>
                          <div className="col-md-6 divasinputmain">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="divasinput">
                                  {(signature != "" &&
                                    isProfileSignature === true) ||
                                    (signatureCustom != "" &&
                                      isProfileSignature === false) ? (
                                    <img
                                      src={
                                        signature != "" &&
                                          isProfileSignature === true
                                          ? signature
                                          : signatureCustom != "" &&
                                            isProfileSignature === false
                                            ? signatureCustom
                                            : null
                                      }
                                      alt="digital signature"
                                      style={{
                                        objectFit: "contain",
                                        height: "105px",
                                        width: "140px",
                                      }}
                                    />
                                  ) : null}
                                </div>
                                <h5 className="divasinputdata">Signature</h5>
                              </div>
                              <div className="col-md-6">
                                <div className="divasinput">
                                  {(companyStampUrl != "" &&
                                    isProfileStamp === true) ||
                                    (companyStampCustom != "" &&
                                      isProfileStamp === false) ? (
                                    <img
                                      src={
                                        companyStampUrl != "" &&
                                          isProfileStamp === true
                                          ? companyStampUrl
                                          : companyStampCustom != "" &&
                                            isProfileStamp === false
                                            ? companyStampCustom
                                            : null
                                      }
                                      alt="company stamp"
                                      style={{
                                        objectFit: "contain",
                                        height: "105px",
                                        width: "140px",
                                      }}
                                    />
                                  ) : null}
                                </div>
                                <h5 className="divasinputdata">
                                  Stamp{" "}
                                  <span style={{ display: "inline" }}>
                                    {"(optional)"}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="admin-ship-settings-save-btn"
                style={{
                  marginBottom: !disableFields ? "25" : "50px",
                  marginTop: !disableFields ? "15" : "0px",
                }}
              >
                {showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images\icon material-error.png"
                      alt="invalid"
                    ></img>
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                {!disableFields && isStringEmpty(queryParamMDSDoCID) && (
                  <button
                    type="button"
                    className="btn btn-block"
                    onClick={() => this.onSaveClick(false, true)}
                  >
                    Save
                  </button>
                )}
                {!disableFields && (
                  <button
                    type="button"
                    className="btn btn-block"
                    onClick={() => this.onSaveClick(true, false)}
                  >
                    Submit
                  </button>
                )}

                <div style={{ display: "inline", float: "right" }}>
                  <span
                    className="download-mdsdoc"
                    onClick={() => this.onPrintClicked(true)}
                    style={{ cursor: "pointer", marginRight: "15px" }}
                  >
                    <img
                      src={shipdownloaddocumentIcon}
                      alt="download"
                      height="35px"
                    />
                  </span>
                  <span
                    className="download-mdsdoc"
                    onClick={() => this.onPrintClicked(false)}
                    style={{ cursor: "pointer", marginRight: "18px" }}
                  >
                    <img src={printIcon} alt="download" height="35px" />
                  </span>
                </div>
              </div>
              {/* ) : null} */}
            </div>
          </section>
        </main>
        <br />
        {isStringEmpty(queryParamMDSDoCID) && <Footer />}
      </div>
    );
  }
}

export default withRouter(MDSDoC);
