import React, { Component } from "react";
import DatePicker from "../../components/DatePickerComponent";
import Dropdown from "../../components/Dropdown";
import { isValidFile, helperText, GetLocalStorageDataWithoutParse } from "../../helpers";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import Toast from "../../components/Toast";
import { ClientService } from "../../services/client.service";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";
import { Module } from "../../helpers/enums";

const validFileFormats = [".pdf, .xls, .xlsx, .docx,.PDF"];
const validFileFormatsReadable =
  "application/pdf,application/PDF,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-client-modal"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

export default class UploadClientDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInputKey: "",
      filePath: "",
      fileName: "",
      errorMessages: {},
      dropdown_ship: 0,
      dropdown_documentType: 0,
      clientName: this.props.clientName || "",
      remarks: "",
      issueDate: "",
      expiryDate: "",
      showErrorMessage: false,
      errors: {},
      emptyFile: false,
      uploadedFile: "",
      invalidFileFormt: false,
      bulkUpload: false,
      TFile: [],
      docFileName: [],
      showBulkUpload: this.props.showBulkUpload || false,
      nextIssueDate: "",
      previousExpiryDate: "",
      fileSizeExceed: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      invalidFiles: [],
      showTypeNameModal: false,
      typeName: "",
      invalidFileSize: false,
      isClient: this.props.isClient,
      showShipMandatory: false,
    };
    this.fileInput = React.createRef();

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  onDropdownChange = (e) => {

    const { showErrorMessage } = this.state;
    const { name, value, module } = e.target;

    if (name == "dropdown_documentType") {
      var othersSelected = this.props.documentTypeList.filter(x => x.value == value)[0].label;
      var selectedModule = this.props.documentTypeList.filter(x => x.value == value)[0].module;
      if (selectedModule == 'Ship')
        this.setState({ showShipMandatory: true });
      else
        this.setState({ showShipMandatory: false });

      this.setState({ showTypeNameModal: othersSelected === 'Other' });
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  /* <summary>
       date: 19/01/2022
       Name: HV
       description: input field on change method
       <summary> */

  onTextChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    let errors = this.state.errorMessages;

    this.setState({ [name]: value }, () => {
      if (showErrorMessage) this.validateFields();
    });
  };
  onCloseTypeClick = () => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    errors["errorTypeName"] = "";
    errorMessages["errorDocumentType"] = "";
    errorMessages["errorShip"] = "";
    this.setState({ dropdown_documentType: 0, showTypeNameModal: false, typeName: "", errorMessages: errors, });
  };
  ontypeNameChange = (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;

    this.setState({ typeName: e.target.value, }, () => {
      if (!isStringEmpty(errorMessages.errorTypeName)) {
        errors["errorTypeName"] = e.target.value.trim() === "" ? "true" : "";
        this.setState({
          errorMessages: errors,
        })
      }
      else if (!isStringEmpty(errorMessages.DocTypeExist)) {
        this.CheckIfDocTypeExist(e.target.value.trim());
      }
    });
  }
  ontypeNameSaveClicked = async (e) => {
    var { errorMessages, dropdown_ship } = this.state;
    let errors = errorMessages;
    if (this.state.typeName.trim() != "") {

      await this.CheckIfDocTypeExist(this.state.typeName.trim());

      if (isStringEmpty(errorMessages.DocTypeExist)) {
        errors["errorTypeName"] = "";
        errorMessages["errorDocumentType"] = "";
        errorMessages["errorShip"] = "";

        if (dropdown_ship != "" || dropdown_ship != "0")
          this.setState({ showShipMandatory: true });
        else
          this.setState({ showShipMandatory: false });
  
        await this.props.onSaveNewTypeClick(this.state.typeName.trim(), dropdown_ship);
        this.setState({ showTypeNameModal: false, dropdown_documentType: GetLocalStorageDataWithoutParse('selecteddoctype') != null ? GetLocalStorageDataWithoutParse('selecteddoctype') : 0, typeName: "", errorMessages: errors, })
      }
    }
    else {
      errors["errorTypeName"] = "true";
      this.setState({
        errorMessages: errors,
      });
    }
  }

  CheckIfDocTypeExist = async (typeName) => {

    let errors = this.state.errorMessages;

    let docData = {
      TypeName: typeName,
      Module: Module.Client
    }

    const response = await CommonService.CheckIfDocumentTypeExist(docData);

    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["DocTypeExist"] = "true";
      } else {
        errors["DocTypeExist"] = "";
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };
  /* <summary>
    date: 19/01/2022
    Name: HV
    description: date change method
    <summary> */
  //HV - 24/03/2023 - handled expiry date should be greater than issue date

  onDateChanged = (id, e) => {
    const { errorMessages } = this.state;

    let errors = errorMessages;
    if (id == "issueDate") {
      var nextIssueDate = new Date(e);
      nextIssueDate.setDate(e.getDate() + 1);
      nextIssueDate.toLocaleDateString();
      errors["errorIssueDate"] = "";
      this.setState({ issueDate: e, nextIssueDate: nextIssueDate }, () => { });
    }

    if (id == "expiryDate") {
      var previousExpiryDate = new Date(e);
      previousExpiryDate.setDate(e.getDate() - 1);
      previousExpiryDate.toLocaleDateString();
      errors["errorExpiryDate"] = "";
      this.setState({ expiryDate: e, previousExpiryDate: previousExpiryDate }, () => { });
    }

    this.setState({
      errorMessages: errors,
    });
  };

  /* <summary>
    date: 19/01/2022
    Name: HV
    description: validating field
    <summary> */

  validateFields() {
    const {
      dropdown_documentType,
      issueDate,
      expiryDate,
      remarks,
      errorMessages,
      TFile,
      clientName,
      documentFile,
      bulkUpload,
      dropdown_ship,
      showShipMandatory,
    } = this.state;

      let errors = errorMessages;

    if (!bulkUpload) {
      var iDate = issueDate == "" ? "" : convertDate(issueDate);
      var eDate = expiryDate == "" ? "" : convertDate(expiryDate);
      errors["errorDocumentType"] = dropdown_documentType == 0 ? "true" : "";
      errors["errorShip"] = showShipMandatory && dropdown_ship == 0 ? "true" : "";
      errors["errorremarks"] = isStringEmpty(remarks) ? "true" : "";
      errors["errorclientName"] = isStringEmpty(clientName) ? "true" : "";
      errors["errorIssueDate"] =
        iDate == null || iDate == undefined || iDate == "" ? "true" : "";
      // errors["errorExpiryDate"] =
      //  eDate == null || eDate == undefined || eDate == "" ? "true" : "";
      errors["errorTFile"] =
        TFile == null || TFile == undefined || TFile.length == 0 ? "true" : "";

      if (
        errors["errorShip"] != "" ||
        errors["errorDocumentType"] != "" ||
        errors["errorremarks"] != "" ||
        errors["errorIssueDate"] != "" ||
        //errors["errorExpiryDate"] != "" ||
        errors["errorclientName"] != "" ||
        errors["errorTFile"] != ""
      ) {
        this.setState({
          errorMessages,
          showErrorMessage: true,
        });
        return true;
      } else {
        errors["errorTFile"] = "";
        this.setState({
          invalidFileFormt: false,
          errorMessages,
          showErrorMessage: false,
        });
        return false;
      }
    } else {
      errors["errorTFile"] =
        TFile.length == 0 || TFile == null || TFile == undefined ? "true" : "";

      if (errors["errorTFile"] != "") {
        this.setState({
          errorMessages,
          showErrorMessage: true,
        });
        return true;
      } else {
        errors["errorTFile"] = "";
        this.setState({
          invalidFileFormt: false,
          errorMessages,
          showErrorMessage: false,
        });
        return false;
      }
    }
  }

  /* <summary>
    date: 15/03/2022
    Name: A.N.
    description: on file change handle method (bulk)
    <summary> */
  onAttachmentChange = async (e) => {
    const { name, value } = e.target;
    const { showErrorMessage, errorMessages, bulkUpload, isClient } = this.state;
    let errors = errorMessages;

    //SYJ - 29/12/2023 - Single file Upload

    errors["errorTFile"] = "";

    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const uploadedFile = [];
    var invalidFile = [];
    let totalFileSize = 0;
    chosenFiles.some((x) => {
      if (value != "" && !isValidFile(x.name, validFileFormats)) {

        errors["errorTFile"] = "true";

        this.setState(
          {
            [name]: null,
            TFile: [],
            docFileName: [],
            errorFile: true,
            documentFile: [],
            showErrorMessage: true,
            invalidFileFormt: true,
            fileSizeExceed: false,
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.wrongFileError,
          },
          () => {
            if (showErrorMessage) this.validateFields();
          }
        );
        return;
      }

      totalFileSize = x.size + totalFileSize;
      const bytesToMegaBytes = bytes => bytes / (1024 ** 2);
      if (bytesToMegaBytes(totalFileSize) > 200 && bulkUpload) {
        invalidFile.push(x.name)
        this.fileInput.current.value = '';
        this.setState({
          fileSizeExceed: true,
          invalidFileSize: false,
          invalidFiles: invalidFile
        });
        totalFileSize = totalFileSize - x.size;
      }
      else if (bytesToMegaBytes(totalFileSize) > 200 && !bulkUpload && isClient) {
        invalidFile.push(x.name)
        this.fileInput.current.value = '';
        this.setState({
          fileInputKey: "",
          invalidFileSize: true, fileName: "", TFile: null
        });
        x.value = '';
        totalFileSize = totalFileSize - x.size;
      }
      else {
        uploadedFile.push(x);
        this.setState(
          {
            TFile: uploadedFile,
            errorFile: false,
            documentFile: uploadedFile,
            fileName: e.target.files[0].name,
            docFileName: e.target.files[0].name,
            filePath: e.target.value,
            invalidFileFormt: false,
            fileSizeExceed: false,
            invalidFiles: [],
            invalidFileSize: false
          },
          () => {
            if (showErrorMessage) this.validateFields();
          }
        );
      }
    });
  };

  /* <summary>
   date: 19/01/2022
   Name: HV
   description: method for validating data on upload click
   <summary> */

  onUploadClick = () => {
    if (!this.validateFields()) {
      const {
        clientName,
        dropdown_ship,
        dropdown_documentType,
        issueDate,
        expiryDate,
        remarks,
        TFile,
        documentFile,
        bulkUpload,
      } = this.state;

      const data = {
        clientName: bulkUpload ? null : clientName,
        dropdown_ship: bulkUpload ? 0 : dropdown_ship,
        dropdown_documentType: bulkUpload ? "" : dropdown_documentType,
        issueDate: bulkUpload ? "" : issueDate,
        expiryDate: bulkUpload ? "" : expiryDate,
        remarks: bulkUpload ? "" : remarks,
        TFile: TFile,
        documentFile: documentFile || TFile,
        bulkUpload: bulkUpload,
      };
      this.props.onUploadClick(data);
    }
  };

  handleCheckboxChange = (event) => {
    this.fileInput.current.value = '';
    this.setState({
      bulkUpload: event.target.checked,
      remarks: "",
      issueDate: "",
      expiryDate: "",
      shipList: [],
      documentTypeList: [],
      dropdown_ship: "0",
      dropdown_documentType: "0",
      errorMessages: {},
      error: {},
      showErrorMessage: false,
      documentFile: [],
      TFile: [],
      invalidFiles: [],
      fileName: "",
      invalidFileSize: false
    });
  };

  onClearSingleFileCapsule = (name) => {
    var array = this.state.TFile.filter((x) => x.name != name);
    this.setState({ TFile: array });
  };


  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };


  render() {
    const {
      fileName,
      errorMessages,
      fileInputKey,
      clientName,
      issueDate,
      expiryDate,
      remarks,
      showErrorMessage,
      emptyFile,
      invalidFileFormt,
      showBulkUpload,
      dropdown_ship,
      dropdown_documentType,
      nextIssueDate,
      previousExpiryDate,
      toastVisible,
      toastType,
      toastMessage,
      invalidFileSize,
      isClient,
      showShipMandatory
    } = this.state;
    const { closeUploadModal, shipList, documentTypeList, clientId, selecteddoctype } =
      this.props;

    return (
      <div
        className="Training_Evalution client-modal-document"
        style={{ display: "block" }}
      >
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload Document Client</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={closeUploadModal}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="PO-list-topLabel">
                  {showBulkUpload && (
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="checkbox"
                          checked={this.state.bulkUpload}
                          onChange={this.handleCheckboxChange}
                        />
                        <label className="label-class label-mobile label-dropdown">
                          Bulk Upload
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorDocumentName)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <label className="label-class label-mobile label-dropdown">
                          Client:
                        </label>

                        <input
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                          autoFocus={false}
                          autoComplete={"off"}
                          className="form-control"
                          type="text"
                          id="clientName"
                          name="clientName"
                          value={clientName}
                          title={clientName}
                          onChange={this.onTextChange}
                          disabled
                        />
                        {!isStringEmpty(errorMessages.errorclientName) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">

                      <div className="issue-date">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorShip)
                              ? "invalid-position"
                              : ""
                          }
                          id={"shipName"}
                        >
                          <label className={!showShipMandatory ? "" : "required-field"}>Ship:</label>
                          <Dropdown
                            //dropdownLabel="Ship:"
                            name="ship"
                            onChange={this.onDropdownChange}
                            selected={dropdown_ship}
                            disabled={clientId == 0 || this.state.bulkUpload}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {shipList == null
                              ? null
                              : shipList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_ship > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"shipName"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {shipList != null && shipList.filter(x => x.value == dropdown_ship)[0]?.label}
                            </UncontrolledTooltip>) : null}
                          {!isStringEmpty(errorMessages.errorShip) && (
                            <ErrorView errorFor="emptyDropdown" />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorDocumentType)
                            ? "invalid-position"
                            : ""
                        }
                        id={"docType"}
                      >
                        <label className={this.state.bulkUpload ? "" : "required-field"}>Document Type:</label>
                        <Dropdown
                          //  dropdownLabel="Document Type:"
                          name="documentType"
                          onChange={this.onDropdownChange}
                          selected={dropdown_documentType}
                          disabled={this.state.bulkUpload}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {documentTypeList == null
                            ? null
                            : documentTypeList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_documentType > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"docType"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documentType)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.errorDocumentType) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="issue-date">
                        <label htmlFor="issuedate" className={this.state.bulkUpload ? "" : "required-field"}>Issue Date:</label>

                        <div
                          className={
                            !isStringEmpty(errorMessages.errorIssueDate)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <DatePicker
                            min="0"
                            selectedDate={issueDate}
                            onDateChange={(e) =>
                              this.onDateChanged("issueDate", e)
                            }
                            isNotRoot={true}
                            disabled={this.state.bulkUpload}
                            maxDate={previousExpiryDate}
                          >
                            {" "}
                          </DatePicker>
                          {!isStringEmpty(errorMessages.errorIssueDate) && (
                            <ErrorView errorFor="emptyText" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="expiry-date">
                        <label htmlFor="expirydate" >Expiry Date:</label>
                        <div
                        // className={
                        //   !isStringEmpty(errorMessages.errorExpiryDate)
                        //     ? "invalid-position"
                        //     : ""
                        // }
                        >
                          <DatePicker
                            min="0"
                            selectedDate={expiryDate}
                            onDateChange={(e) =>
                              this.onDateChanged("expiryDate", e)
                            }
                            isNotRoot={true}
                            disabled={this.state.bulkUpload}
                            minDate={nextIssueDate}
                          >
                            {" "}
                          </DatePicker>
                          {/* {!isStringEmpty(errorMessages.errorExpiryDate) && (
                            <ErrorView errorFor="emptyText" />
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="approved-by">
                        <label htmlFor="client" className={this.state.bulkUpload ? "" : "required-field"}>Remarks:</label>
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorremarks)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="remarks"
                            name="remarks"
                            value={remarks}
                            maxLength={250}
                            onChange={this.onTextChange}
                            disabled={this.state.bulkUpload}
                          />

                          {!isStringEmpty(errorMessages.errorremarks) && (
                            <ErrorView errorFor="emptyText" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div style={{ paddingTop: '35px' }}>

                        <div className="choosefile-btn">
                          <div
                            className="file-upload-modal"
                            style={{ width: "155px" }}
                            onClick={() => {
                              this.fileInput.current.click();
                              this.fileInput.current.value = '';
                            }}
                          >
                            <label
                              className={
                                "attach-btn attach-btn-responsive chs-btn " +
                                (!isStringEmpty(errorMessages.errorTFile)
                                  ? "invalid-position-border"
                                  : "")
                              }
                              style={{ marginTop: '5px' }}
                            >
                              <img
                                className="upload-img"
                                src="images/Icon awesome-upload.png"
                                width="18px"
                              />
                              &nbsp;Choose File
                            </label>

                            {!isStringEmpty(errorMessages.errorTFile) && (
                              <img
                                src="images\icon material-error.png"
                                alt="invalid"
                                className="invalid-position-img"
                                style={{ width: "16px" }}
                              />
                            )}
                          </div>
                          <label className="required-field">&nbsp; (Please select a PDF, Excel or Word file)</label>

                          <input
                            ref={this.fileInput}
                            type="file"
                            accept={validFileFormatsReadable}
                            id="file"
                            name="TFile"
                            onChange={this.onAttachmentChange}
                            key={fileInputKey || ""}
                            style={{ display: "none" }}
                            multiple={this.state.bulkUpload}
                          />
                        </div>
                        {this.state.bulkUpload ?
                          <div style={{ paddingBottom: '10px' }}>
                            <span style={{ fontSize: '13px', fontWeight: "bold", color: 'black' }}>Maximum 200 MB files can be uploaded.</span>
                          </div> :
                          isClient && !this.state.bulkUpload ? <div style={{ paddingBottom: '10px' }}>
                            <span style={{ fontSize: '13px', fontWeight: "bold", color: 'black' }}>Maximum 200 MB files can be uploaded.</span>
                          </div> : null}
                      </div>
                      {this.state.bulkUpload ? <ul
                        style={{
                          listStyleType: "none",
                          display: "flex",
                          flexWrap: "wrap",
                          paddingLeft: 0,
                        }}
                      >
                        {this.state.TFile.map((file, index) => (
                          <li key={index}>
                            <div
                              style={{
                                fontWeight: "bold",
                                marginRight: "6px",
                                borderRadius: "3px",
                                padding: ".5833em .833em",
                                backgroundColor: "#e3e1e1",
                                fontSize: ".8rem",
                                border: "none",
                                margin: "8px 8px 8px 0px",
                              }}
                            >
                              <span className="label-breakword" style={{ marginRight: 5 }}>
                                {" "}
                                {file.name}
                              </span>
                              <button
                                type="button"
                                onClick={() =>
                                  this.onClearSingleFileCapsule(file.name)
                                }
                                style={{ marginLeft: 4, border: "none" }}
                              >
                                X
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul> : null}

                      {!this.state.bulkUpload ? <label className="label-breakword">{this.state.fileName}</label> : null}
                    </div>
                  </div>
                </div>

                {emptyFile && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please upload a file.</p>
                  </div>
                )}
                {/* {invalidFileFormt && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please upload a PDF, Excel, or Word file.</p>
                  </div>
                )} */}

                {!invalidFileFormt && showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                {this.state.invalidFiles.length > 0 && <div className="invalid-message-icon-box">
                  <p>The file limit exceeded 200MB. Please upload the attached file and then try again to upload the remaining file.</p>
                </div>}
                {invalidFileSize && isClient && <div className="invalid-message-icon-box">
                  <p>Please select a file upto 200MB.</p>
                </div>}
                <br />
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-background-color upload-btn"
                    onClick={this.onUploadClick}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showTypeNameModal && (
            <div className="supplier_on_hold">
              <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
              <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Add Type</h4>
                      <button type="button" data-bs-dismiss="modal">
                        <img
                          src="images/modal-close.png"
                          alt="modal-close"
                          onClick={this.onCloseTypeClick}
                        />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row content">
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                          <div className={
                            !isStringEmpty(errorMessages.errorTypeName) || !isStringEmpty(errorMessages.DocTypeExist)
                              ? "invalid-position"
                              : ""
                          }>
                            <label htmlFor="todate">Type Name:</label>
                            <br />
                            <input
                              type="typeName"
                              className="form-control"
                              id="typeName"
                              name="typeName"
                              placeholder="Enter Type Name"
                              value={this.state.typeName}
                              onChange={this.ontypeNameChange}
                              maxLength={50}
                            />
                            {(!isStringEmpty(errorMessages.errorTypeName)) || (!isStringEmpty(errorMessages.DocTypeExist)) && (
                              <div className="misc-invalid-message-icon ">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.errorTypeName) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.DocTypeExist) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Document type already exists.</p>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-background-color save-btn"
                          onClick={this.ontypeNameSaveClicked}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
