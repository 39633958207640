import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import DatePicker from "../../components/DatePickerComponent";
import { helperText } from "../../helpers/helperText";
import { SupplierService } from "../../services/supplier.service";
import editIcon from "../../assets/images/Icon feather-edit.png";
import SupplierOnHold from "../Suppliers/SupplierOnHold.jsx";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import viewIcon from "../../assets/images/admin-eye.png";
import { UserAction } from "../../helpers/enums";
import { SupplierStatus } from "../../helpers/enums";
import ConfirmationModal from "../../components/ConfirmationModal";
import { UncontrolledTooltip } from "reactstrap";

var currentDate = new Date();

class ManageSuppliers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedSuppliers: [],
      defaultSuppliers: [],
      dropdownList: [],
      isLoading: false,
      toastMessage: null,
      toastType: "",
      toastVisible: false,
      showOnHoldModal: false,
      selectedSupplierId: null,
      onHoldFromDate: "",
      onHoldToDate: "",
      userId: 0,
      supplierCompanyList: [],
      supplierStatusList: [],
      dropdown_companyName: "",
      dropdown_status: "",
      filter_fromDate: "",
      filter_toDate: "",
      supplierId: 0,
      nextFromDate: "",
      previousToDate: currentDate,
      isSupplier: false,
      typeId: 0,
      errorMessages: {},
      showHideStatusModal: false,
      gridApi: null,
      accessList: [],
      userRolePermissionRead: false,
      searchedText: ""
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  /* <summary>
   date: 23/11/2022
   Name: DN
   description: Get data to bind on the manage supplier screen
   <summary> */
  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");

    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          typeId: userData.typeId,
          isSupplier: userData.typeId == UserRole.SUPPLIER,
          userId: userData.id,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        () => {
          this.getManageSupplierData();
        }
      );
    }
  }

  // componentDidUpdate() {
  //   if (this.state.gridApi) {
  //     this.state.gridApi.paginationGoToPage(0);
  //   }
  // }

  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
   date: 23/11/2022
   Name: DN
   description: Bind AG grid columns
   <summary> */
  /* <summary>
   date: 4/3/2023
   Name: DN
   description: removed field which was not needed
   <summary> */
  gridColumns = () => {
    return [
      {
        headerName: "Serial Number",
        // field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "Supplier Name",
        field: "supplierName",
        tooltipField: "supplierName",
        width: 170,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Supplier Contact Number",
        field: "supplierContactNo",
        tooltipField: "supplierContactNo",
        width: 230,
      },
      {
        headerName: "Supplier Email Address",
        field: "emailAddress",
        tooltipField: "emailAddress",
        width: 230,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Company Name",
        field: "companyName",
        tooltipField: "companyName",
        width: 190,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Division/Branch Name",
        field: "division",
        tooltipField: "companyBranchName",
        width: 230,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Fax Number",
        field: "faxNumber",
        tooltipField: "faxNumber",
        width: 190,
      },
      {
        headerName: "Issuer Name",
        field: "issuerName",
        tooltipField: "issuerName",
        width: 200,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Issuer Address",
        field: "issuerAddress",
        tooltipField: "issuerAddress",
        width: 200,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Date of Creation",
        field: "createdOn",
        tooltipField: "createdOn",
        width: 200,
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            disabled={this.state.userRolePermissionRead}
            className="cursor-class"
            src={
              params.data.statusName == "Active" &&
                !this.state.userRolePermissionRead
                ? editIcon
                : viewIcon
            }
            width="20px"
            title="Edit"
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() => { this.onAddEditSupplierClicked(params.data.supplierId); }}
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        filter: "agMultiColumnFilter",
        width: "150px",
        sortable: false,
        editable: !this.state.userRolePermissionRead,
        headerName: "Status",
        field: "statusName",
        tooltipField: "statusName",
        cellRenderer: (params) => {
          return (
            <>
              {this.state.userRolePermissionRead ?
                <label>
                  {params.data.statusId == 1 ? 'Active' : params.data.statusId == 2 ? "Inactive" : params.data.statusId == 2 ? "On Hold" : ""}
                </label>
                :
                <select
                  className="form-select status-alignment "
                  aria-label="Default select example"
                  data-bs-toggle="modal"
                  data-bs-target="#supplieronhold-modal"
                  disabled={this.state.userRolePermissionRead}
                  onChange={(e) =>
                    params.data.statusId != "3"
                      ? this.onStatusModalOpenClick(params.data.supplierId, e, "NoHold")
                      : this.onStatusModalOpenClick(
                        params.data.supplierId,
                        e,
                        "FromHold"
                      )
                  }
                  value={params.data.statusId}
                >
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                  <option value="3" className="onhold-link">
                    On Hold
                  </option>
                </select>
              }

            </>
          );
        },
        cellStyle: { textAlign: "center" }
      },
    ];
  };

  /* <summary>
   date: 19/04/2023
   Name: SYJ
   description: On status OnHold change Show Confirmation Modal
   <summary> */
  onStatusModalOpenClick = (id, e) => {
    this.setState({
      showHideStatusModal: true,
      supplierId: id,
      statusId: e.target.value,
    });
  };

  /* <summary>
   date: 24/11/2022
   Name: DN
   description: On status dropdown change save the new assigned status and bind the grid again. If Onhold clicked set hold time for supplier
   <summary> */
  onRowStatusChanged = async (id, e, type) => {
    var supplierId = id;
    var statusId = type == "NoHold" ? e.target.value : e; //SYJ - added type param to check if status is OnHold or Not
    if (parseInt(statusId, 10) == parseInt(SupplierStatus.ONHOLD, 10)) {
      this.setState({
        showHideStatusModal: false,
        onHoldFromDate: "",
        onHoldToDate: "",
        comments: "",
        showOnHoldModal: true,
        selectedSupplierId: supplierId,
        errorMessages: {},
      });
    } else {
      this.setState({ isLoading: true });
      //save the status of the supplier in db
      const response = await SupplierService.SetSupplierStatus(
        supplierId,
        statusId,
        this.state.userId,
        this.state.typeId
      );
      if (response.data != null && response.data != undefined) {
        let uniqueCompanyNameList = Array.from([
          ...new Set(
            response.data
              .map((l) => l.companyName) // Extract company names
              .filter((name) => name !== "") // Filter out empty strings
          ),
        ]);
        let uniqueStatusList = Array.from([
          ...new Set(response.data.map((l) => l.statusName)),
        ]);
        this.setState({
          mappedSuppliers: response.data,
          defaultSuppliers: response.data,
          showHideStatusModal: false,
          supplierCompanyList: uniqueCompanyNameList.sort((a, b) =>
            a.localeCompare(b)
          ),
          supplierStatusList: uniqueStatusList,
        }, () => {
          this.clearAllFilter();
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.setState({ isLoading: false });
    }
  };

  /* <summary>
   date: 23/11/2022
   Name: DN
   description: Get all suppliers to bind in the Ag grid, fill in the dropdown used for filtering
   <summary> */
  getManageSupplierData = async () => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.supplierAccess == UserAction.READ,
      });
      const { supplierId } = this.state;

      const response = await SupplierService.GetManageSupplierData(
        supplierId,
        this.state.userId
      );
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        }
        let uniqueCompanyNameList = Array.from([
          ...new Set(
            response.data
              .map((l) => l.companyName) // Extract company names
              .filter((name) => name !== "") // Filter out empty strings
          ),
        ]);
        let uniqueStatusList = Array.from([
          ...new Set(response.data.map((l) => l.statusName)),
        ]);

        this.setState({
          mappedSuppliers: response.data,
          defaultSuppliers: response.data,
          supplierCompanyList: uniqueCompanyNameList.sort((a, b) =>
            a.localeCompare(b)
          ),
          supplierStatusList: uniqueStatusList,
        });
      } else {
        this.setState({
          mappedSuppliers: [],
          defaultSuppliers: [],
          supplierCompanyList: [],
          supplierStatusList: [],
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
   date: 24/11/2022
   Name: DN
   description: On date selected/changed from the date picker
   <summary> */
  //HV - 24/03/2023 - handled from date should be greater than to date
  onDateChanged = (id, e) => {
    if (id == "fromDate") {
      var nextFromDate = new Date(e);
      nextFromDate.setDate(e.getDate());
      nextFromDate.toLocaleDateString();
      this.setState({ filter_fromDate: e, nextFromDate: nextFromDate }, () => {
        this.filterGridView();
      });
    } else {
      var previousToDate = new Date(e);
      previousToDate.setDate(e.getDate() - 1);
      previousToDate.toLocaleDateString();
      this.setState(
        { filter_toDate: e, previousToDate: previousToDate },
        () => {
          this.filterGridView();
        }
      );
    }
  };

  /* <summary>
   date: 24/11/2022
   Name: DN
   description: On filter dropdown selected
   <summary> */
  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterGridView()
    );
  };

  /* <summary>
   date: 24/11/2022
   Name: DN
   description: Filter grid data using the date and dropdowns available in the ui
   <summary> */
  filterGridView = () => {
    const {
      dropdown_companyName,
      dropdown_status,
      filter_fromDate,
      filter_toDate,
      searchedText
    } = this.state;
    var filteradded = false;
    var filterList = [];
    var supplierList = this.state.defaultSuppliers;

    if (
      dropdown_companyName == "" &&
      dropdown_status == "" &&
      filter_fromDate == "" &&
      filter_toDate == ""
    ) {
      filterList = this.state.defaultSuppliers;
    }
    //SYJ - 16/01/2023 - When Select Option is Selected from the Dropdown
    if (dropdown_status == "0" && dropdown_companyName == "0") {
      filterList = this.state.defaultSuppliers;
    } else if (dropdown_status == "0" && dropdown_companyName != "") {
      filterList = filteradded
        ? filterList.filter((e) => e.companyName.includes(dropdown_companyName))
        : supplierList.filter((e) =>
          e.companyName.includes(dropdown_companyName)
        );
      filteradded = true;
    } else if (dropdown_status == "" && dropdown_companyName == "0") {
      filterList = this.state.defaultSuppliers;
    } else if (dropdown_status == "0" && dropdown_companyName == "") {
      filterList = this.state.defaultSuppliers;
    } else if (dropdown_status != "" && dropdown_companyName == "0") {
      filterList = filteradded
        ? filterList.filter((e) => e.statusName.includes(dropdown_status))
        : supplierList.filter((e) => e.statusName.includes(dropdown_status));
      filteradded = true;
    }
    if (dropdown_companyName != "" && dropdown_companyName != 0) {
      filterList = filteradded
        ? filterList.filter((e) => e.companyName.includes(dropdown_companyName))
        : supplierList.filter((e) =>
          e.companyName.includes(dropdown_companyName)
        );
      filteradded = true;
    }
    if (dropdown_status != "" && dropdown_status != 0) {
      filterList = filteradded
        ? filterList.filter((e) => e.statusName.includes(dropdown_status))
        : supplierList.filter((e) => e.statusName.includes(dropdown_status));
      filteradded = true;
    }
    if (filter_fromDate != "" && filter_toDate != "") {
      let fromDate = convertDate(filter_fromDate);
      let toDate = convertDate(filter_toDate);

      filterList = filteradded
        ? filterList.filter((e) => {
          let date = new Date(e.createdOn);
          date.setHours(0, 0, 0, 0);
          return date >= filter_fromDate && date <= filter_toDate;
        })
        : supplierList.filter((e) => {
          let date = new Date(e.createdOn);
          date.setHours(0, 0, 0, 0);
          return date >= filter_fromDate && date <= filter_toDate;
        });
    }

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.supplierName?.toString().toLowerCase().includes(value) ||
          t.supplierAddress?.toString().toLowerCase().includes(value) ||
          t.supplierContactNo?.toString().toLowerCase().includes(value) ||
          t.emailAddress?.toString().toLowerCase().includes(value) ||
          t.companyName?.toString().toLowerCase().includes(value) ||
          t.companyBranchName?.toString().toLowerCase().includes(value) ||
          t.companyContactNo?.toString().toLowerCase().includes(value) ||
          t.issuerName?.toString().toLowerCase().includes(value) ||
          t.issuerAddress?.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedSuppliers: filterList,
    });
  };

  /* <summary>
    date: 23/11/2022
    Name: DN
    description: Filter data from grid using search bar
    <summary> */
  onSearch = (e) => {
    var inputValue = e.target.value;
    this.setState({
      searchedText: inputValue,
    }, () => {
      this.filterGridView();
    });
  };

  /* <summary>
    date: 24/11/2022
    Name: DN
    description: When supplier is put on hold save the date and time of the onhold period
    <summary> */
  async onHoldSaveClicked(supplierId) {
    const {
      onHoldFromDate,
      onHoldToDate,
      comments,
      userId,
      isSupplier,
      typeId,
    } = this.state;

    this.setState({ isLoading: true });
    if (!this.isValidateOnHoldModal()) {
      let supplierData = new FormData();
      supplierData.append("IsSupplier", isSupplier);
      supplierData.append("SupplierId", supplierId);
      supplierData.append("FromDate", convertDate(onHoldFromDate));
      supplierData.append("ToDate", convertDate(onHoldToDate));
      supplierData.append("Comment", comments);
      supplierData.append("EditedBy", userId);
      supplierData.append("typeId", typeId);

      var response = await SupplierService.SetSupplierOnHold(supplierData);

      if (response == undefined || response == null) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        this.setState({
          mappedSuppliers: response.data,
          defaultSuppliers: response.data,
          showOnHoldModal: false,
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.Supplier.setOnHold,
          supplierId: 0,
        }, () => {
          this.clearAllFilter();
          this.getManageSupplierData();
        });
      }
    }
    this.setState({
      isLoading: false,
    });
  }

  isValidateOnHoldModal = () => {
    const { onHoldFromDate, onHoldToDate, errorMessages } = this.state;

    let hasErrors = false;
    let errors = errorMessages;

    errors["onHoldFromDateValidation"] = onHoldFromDate == "" ? "true" : "";
    errors["onHoldToDateValidation"] = onHoldToDate == "" ? "true" : "";

    if (
      errors["onHoldFromDateValidation"] != "" ||
      errors["onHoldToDateValidation"] != ""
    ) {
      hasErrors = true;
      this.setState({ showOnHoldErrorMessage: true, isLoading: false });
    } else {
      hasErrors = false;
      this.setState({ showOnHoldErrorMessage: false });
    }

    this.setState({ errorMessages: errors });
    return hasErrors;
  };

  /* <summary>
    date: 24/11/2022
    Name: DN
    description: From Date selected from on hold modal to be saved in supplierOnHold table
    <summary> */
  onHoldFromDateChanged = (e) => {
    this.setState(
      {
        onHoldFromDate: e,
        onHoldToDate: "",
      },
      () => {
        this.isValidateOnHoldModal();
      }
    );
  };

  /* <summary>
    date: 24/11/2022
    Name: DN
    description: To Date selected from on hold modal to be saved in supplierOnHold table
    <summary> */
  onHoldToDateChanged = (e) => {
    this.setState(
      {
        onHoldToDate: e,
      },
      () => {
        this.isValidateOnHoldModal();
      }
    );
  };

  /* <summary>
    date: 24/11/2022
    Name: DN
    description: OnAddSupplier button click or Edit icon click from grid, navigate to the new page and pass the data of supplier 
    <summary> */
  onAddEditSupplierClicked = (supplierId) => {
    if (supplierId === 0) AddLocalStorageData("selectedSupplier", "");
    else {
      let supplierData = this.state.defaultSuppliers.filter(
        (s) => s.supplierId === supplierId
      );

      AddLocalStorageData("selectedSupplier", JSON.stringify(supplierData[0]));
    }
    this.props.navigate("/AddEditSupplier");
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
 date: 13/02/2023
 Name: HV
 description: clearing filter
 <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_companyName: "0",
        dropdown_status: "0",
        filter_fromDate: "",
        filter_toDate: "",
        nextFromDate: "",
        previousToDate: currentDate,
        searchedText: ""
      },
      () => {
        this.filterGridView();
      }
    );
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    } else {
      this.props.navigate("/Dashboard");
    }
  };

  toggleModal = () => {
    this.setState({
      showOnHoldModal: false,
      showOnHoldErrorMessage: false,
      onHoldFromDate: "",
      onHoldToDate: "",
    });
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onStatusModalYesClick = () => {
    this.onRowStatusChanged(
      this.state.supplierId,
      this.state.statusId,
      "FromHold"
    );
  };

  onStatusModalCloseClick = () => {
    this.setState({
      showHideStatusModal: false,
    });
  };

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
  };

  render() {
    const {
      mappedSuppliers,
      supplierCompanyList,
      supplierStatusList,
      filter_fromDate,
      filter_toDate,
      comments,
      isLoading,
      onHoldFromDate,
      onHoldToDate,
      showOnHoldModal,
      toastVisible,
      toastType,
      toastMessage,
      selectedSupplierId,
      dropdown_status,
      dropdown_companyName,
      accessList,
      previousToDate,
      nextFromDate,
      showOnHoldErrorMessage,
      errorMessages,
      showHideStatusModal,
      userRolePermissionRead,
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ManageSupplier"} />
        <SupplierOnHold
          onCloseClick={this.toggleModal}
          showModal={showOnHoldModal}
          onSaveClicked={() => this.onHoldSaveClicked(selectedSupplierId)}
          fromDate={onHoldFromDate}
          toDate={onHoldToDate}
          fromDateChanged={(e) => this.onHoldFromDateChanged(e)}
          toDateChanged={(e) => this.onHoldToDateChanged(e)}
          comments={comments}
          onCommentsChange={(e) => this.onTextChange(e)}
          showOnHoldErrorMessage={showOnHoldErrorMessage}
          errorMessages={errorMessages}
        />
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showHideStatusModal && (
          <ConfirmationModal
            onModalCloseClick={this.onStatusModalCloseClick}
            deleteIHMDocData={this.onStatusModalYesClick}
            modalText={"change the Status?"}
          />
        )}
        <main className="page-height">
          <section className="manage-supplier">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    {/* <a href="Dashboard"> */}
                    <img
                      src="images/back.png"
                      alt="Back"
                      onClick={this.goback}
                    />
                    {/* </a> */}
                    <h5>Manage Suppliers</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <Searchbar
                    placeholder={"Supplier Name, Supplier Company, etc..."}
                    onChange={this.onSearch}
                  />
                </div>
              </div>

              <div className="supplier-filter">
                <div className="row">
                  <div className="col-lg-12 filter-flex-container wrap">
                    <div className="filter-box filter-flex-item form-label" id={"companyName"}>
                      <Dropdown
                        dropdownLabel="Company Name:"
                        name="companyName"
                        onChange={this.onFilterSelected}
                        selected={dropdown_companyName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {supplierCompanyList == null
                          ? null
                          : supplierCompanyList.map((item, key) => (
                            <option key={key} value={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>

                      {dropdown_companyName != "" ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"companyName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {supplierCompanyList != null && supplierCompanyList.filter(x => x.value == dropdown_companyName)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                    <div className="filter-date-from">
                      <label htmlFor="exampleDataList" className="form-label">
                        Date of Creation:
                      </label>
                      <br />
                      <span className="from-to-box">
                        <span className="from-date">
                          <label>From:</label>
                          <DatePicker
                            selectedDate={filter_fromDate}
                            onDateChange={(e) =>
                              this.onDateChanged("fromDate", e)
                            }
                            maxDate={previousToDate}
                          >
                            {" "}
                          </DatePicker>
                        </span>
                        <span className="to-date">
                          <label>To:</label>
                          <DatePicker
                            selectedDate={filter_toDate}
                            onDateChange={(e) =>
                              this.onDateChanged("toDate", e)
                            }
                            minDate={nextFromDate}
                            maxDate={currentDate}
                          >
                            {" "}
                          </DatePicker>
                        </span>
                      </span>
                    </div>
                    <div className="filter-box filter-flex-item form-label" id={"status"}>
                      <Dropdown
                        dropdownLabel="Status:"
                        name="status"
                        onChange={this.onFilterSelected}
                        selected={dropdown_status}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {supplierStatusList == null
                          ? null
                          : supplierStatusList.map((item, key) => (
                            <option key={key} value={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_status != "" && dropdown_status != undefined && dropdown_status != null && dropdown_status != 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"status"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {supplierStatusList != null && dropdown_status}
                        </UncontrolledTooltip>) : null}
                    </div>
                    <div className="filter-box clear-all-btn">
                      <button
                        type="button"
                        className="btn btn-blue-bg"
                        onClick={this.clearAllFilter}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="filter-btn manage-add-button">
                      <button
                        type="button"
                        className="btn btn-blue-bg "
                        disabled={userRolePermissionRead ? true : false}
                        onClick={() => this.onAddEditSupplierClicked(0)}
                      >
                        <img src="images/admin-plus-icon.png" alt="plus-icon" />
                        Add Supplier
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="manage-supplier-grid">
                <div className="row">
                  <div className="col-md-12">
                    <AGGrid
                      onGridReady={this.onGridReady}
                      rowData={mappedSuppliers}
                      colDefs={this.gridColumns()}
                      rowSelection="single"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </>
    );
  }
}

export default withRouter(ManageSuppliers);
