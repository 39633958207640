import React, { Component } from 'react'
import { isStringEmpty } from '../helpers/string-helper';

export default class ForgotPasswordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { onCloseClick, modalTitle, onChange, errorMessages, userEmailId, onRequestNewPasswordClick } = this.props;

        return (

            <div className="Training_Evalution client-modal-document" style={{ display: "block" }}>
                <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
                <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{modalTitle}</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: "all" }} onClick={onCloseClick} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ padding: '15px' }}>
                                    <div className="row">
                                        <div className="col-lg-3 forgot-pass-label" style={{ paddingRight: 0 }}>
                                            <label className="label-class label-mobile">Email Address:</label>
                                        </div>
                                        <div className="col-lg-7 col-10" style={{ padding: 0 }}>
                                         
                                                <input
                                                    autoFocus={false}
                                                    autoComplete={"off"}
                                                    className="form-control"
                                                    type="text"
                                                    id="userEmailId"
                                                    name="userEmailId"
                                                    value={userEmailId}
                                                    onChange={onChange}
                                                />
                                                {!isStringEmpty(errorMessages.errorUserEmailId) && (
                                                        <div className="invalid-login-email-message">
                                                            {errorMessages.errorUserEmailId}
                                                        </div>
                                                )}
                                        </div>
                                        <div className="col-lg-1 col-2" style={{paddingTop: '7px' }}>
                                            <img src="images/info.png" alt="info" className="info-modal" data-bs-toggle="tooltip" data-bs-placement="right" title="Email will be sent to Admin for changing password." />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center" style={{ padding: '20px', cursor: 'pointer' }}>
                                    <button type="button" className="btn btn-background-color upload-btn" onClick={onRequestNewPasswordClick} >Request New Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
