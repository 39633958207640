import React from "react";
import { isStringEmpty } from "../../helpers/string-helper";

const LinkVerificationModal = ({
    showPasswordModal,
    onVerifyClick,
    onOTPChange,
    otpValue,
    showValidationError,
    showOtpUsedError,
    validationMessage,
}) => {
    return (
        <section>
            <div className="supplier_on_hold">
                <div
                    className="modal-backdrop fade show"
                    style={{ display: showPasswordModal ? "block" : "none", zIndex: "998" }}
                ></div>
                <div
                    className="modal"
                    id="uploadPOlist"
                    tabIndex="-1"
                    style={{ display: showPasswordModal ? "block" : "none", zIndex: "999" }}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Verify OTP</h4>
                                {/* <button type="button" data-bs-dismiss="modal">
                                    <img
                                        src="images/modal-close.png"
                                        alt="modal-close"
                                        onClick={onVerifyModalClose}
                                    />
                                </button> */}
                            </div>
                            <div className="modal-body">
                                {(showOtpUsedError === "") &&
                                    (<div className="top-container">
                                        <div className={
                                            (showValidationError != "" || showOtpUsedError != "")
                                                ? "invalid-position"
                                                : ""
                                        }>
                                            <label className={"required-field"}>Enter the OTP received in email:</label>
                                            <br />
                                            <input
                                                type="typeName"
                                                className="form-control"
                                                id="typeName"
                                                name="typeName"
                                                placeholder="Enter OTP"
                                                value={otpValue}
                                                onChange={onOTPChange}
                                                maxLength={50}
                                            />
                                            {(showValidationError != "" || showOtpUsedError != "") && (
                                                <div className="misc-invalid-message-icon ">
                                                    <img
                                                        src="images\icon material-error.png"
                                                        alt="invalid"
                                                    ></img>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    )}

                                <div
                                    className="invalid-message-icon-box"
                                    style={{ display: showValidationError != "" ? "" : "none" }}
                                >
                                    <img src="images\icon material-error.png" alt="invalid"></img>
                                    <p>{validationMessage}</p>
                                </div>
                                <div
                                    className="invalid-message-icon-box"
                                    style={{ display: showOtpUsedError != "" ? "" : "none" }}
                                >
                                    <img src="images\icon material-error.png" alt="invalid"></img>
                                    <p>The MD/SDoC link has been already accessed.</p>
                                </div>
                                {(showOtpUsedError === "") && (
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-background-color save-btn"
                                            onClick={onVerifyClick}
                                        >
                                            Verify
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LinkVerificationModal;
