import React from "react";
import {
    isStringEmpty,
} from "../helpers/string-helper";

const AutoCreatingSupplier = ({
    onModalCloseClick,
    errorMessages,
    yesClicked,
    isYesClicked,
    onChange,
    onSaveClick,
    supplierName,
}) => {
    return (
        <div className="deletedoc" style={{ display: "block" }}>
            <div
                className="modal-backdrop fade show"
                style={{ display: "block" }}
            ></div>
            <div className="modal" id="deleteDoc-modal" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box">
                    <div className="modal-content box">
                        <div className="modal-body">
                            <div className="block1" style={{ whiteSpace: "pre-line" }}>
                                The supplier does not exist. Would you like to create a new supplier?
                            </div>
                            <div className=" text-center yes-no-btn">
                                <div>
                                    {" "}
                                    <button
                                        type="button"
                                        className="btn btn-background-color yes-btn "
                                        onClick={yesClicked}
                                    >
                                        Yes
                                    </button>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-background-color  yes-btn"
                                        onClick={onModalCloseClick}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                            {isYesClicked ? (
                                <div>
                                    <br /><br />
                                    <div>
                                        <div className="block1" style={{ whiteSpace: "pre-line" }}>
                                            <label className="label-class label-mobile">Please enter the supplier name:</label>
                                        </div>
                                        <div className="col-lg-12 col-10" style={{ padding: 0 }}>
                                            <input
                                                autoFocus={false}
                                                autoComplete={"off"}
                                                className="form-control"
                                                type="text"
                                                id="supplierName"
                                                name="supplierName"
                                                value={supplierName}
                                                onChange={onChange}
                                                maxLength={100}
                                            />
                                            {!isStringEmpty(errorMessages) && (
                                                <div className="invalid-login-email-message">
                                                    {errorMessages}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center" style={{ padding: '20px', cursor: 'pointer' }}>
                                        <button type="button" className="btn btn-background-color upload-btn" onClick={onSaveClick}>Save</button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutoCreatingSupplier;
