import webserviceWrapper from "../helpers/webserviceWrapper";

export const TestService = {
    SendEmail,
    SaveAttachment,
    GetAttachmentClicked,
    FetchEmails,
};

function SendEmail() {
    return webserviceWrapper.post(
        `/Test/SendEmail`, null,
        400000
    );
}

function SaveAttachment(blobFormatData) {
    return webserviceWrapper.post(
        `/Test/SaveAttachment`, blobFormatData,
        100000
    );
}

function GetAttachmentClicked() {
    return webserviceWrapper.get(`/Test/GetAttachmentClicked`);
}

function FetchEmails(userData) {
    return webserviceWrapper.post(
        `/Test/FetchEmails`, userData, 3600000000
    );
}

