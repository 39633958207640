import React, { useState, useEffect } from 'react';
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ClientService } from "../../services/client.service";
import Toast from '../../components/Toast';
import AGGrid from '../../components/AGGrid';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import Logout from '../../helpers/Logout';
import { convertDate } from '../../helpers/string-helper';
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, customComparator, helperText } from '../../helpers';
import InputFieldEditor from '../../components/InputFieldEditor';
import { UncontrolledTooltip } from "reactstrap";

var currentDate = new Date();

/* <summary>
date: 27/01/2023
Name: HV
description: manage change request page functionality,inline editing, displaying and updating data
<summary> */

export default function ManageChangeRequest() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [toastData, setToastData] = useState({ toastVisible: false, toastType: "", toastMessage: "" });
    const [changeRequestData, setChangeRequestData] = useState([]);
    const [requestStausList, setRequestStausList] = useState([]);
    const [loggedInUserId, setloggedInUserId] = useState(0);
    const [loggedInUserRoleId, setloggedInUserRoleId] = useState(0);

    useEffect(() => {
        try {
            changePageTitle("");
            var userData = GetLocalStorageDataWithParse("loggedInUser");

            setloggedInUserId(userData.id);
            setloggedInUserRoleId(userData.typeId);
            getManageClientChangeRequestData(userData);
            return () => { }
        }
        catch (error) {
        }
    }, [])

    const getManageClientChangeRequestData = async (userData) => {
        try {
            setIsLoading(true);

            const response = await ClientService.GetManageClientChangeRequestData(userData.id, userData.typeId);

            if (response.status == 200 && response.data != null) {
                setChangeRequestData(response.data.clientChangeRequestList);
                setRequestStausList(response.data.requestStatusList);
                setIsLoading(false);
            } else if (response.status == 401) {
                Logout(navigate);
            }
            else {
                setIsLoading(false);
            }
        } catch (error) {
        }

    }

    const closeToast = () => {
        setToastData({ ...toastData, toastVisible: false });
    };

    const goback = () => {
        navigate("/ManageClient");
    };

    const onDropDownSelected = (e, data) => {
        var { name, value } = e.target;
        if (value > 0) {
            var statusName = requestStausList.filter(x => x.value == value)[0].label;

            const newsRows = changeRequestData.map(row => {
                if (row.clientChangeRequestId == data.clientChangeRequestId) {

                    row.statusId = value;
                    row.status = statusName;
                    row.statusName = statusName;
                    row.editedOn = convertDate(currentDate)
                    return {
                        ...row
                    };
                }
                return row;
            });

            setChangeRequestData(newsRows);
            data.status = statusName;
            data.statusId = value;
            data.statusName = statusName;

            UpdateClientChangeRequestData(newsRows.filter(x => x.clientChangeRequestId == data.clientChangeRequestId), true)
        }
    };

    const UpdateClientChangeRequestData = async (data, statusChanged) => {

        setIsLoading(true);
        var changeRequestdData = {
            ClientChangeRequestId: data[0].clientChangeRequestId,
            ClientId: data[0].clientId,
            StatusId: data[0].statusId,
            Comments: data[0].comments,
            EditedBy: loggedInUserId,
            NavigateTo: data[0].navigateTo
        };

        let response = await ClientService.UpdateClientChangeRequestData(changeRequestdData);
        if (response.status == 200 && response.status != "") {
            var userData = GetLocalStorageDataWithParse("loggedInUser");

            const response = await ClientService.GetManageClientChangeRequestData(userData.id, userData.typeId);
            if (response.status == 200 && response.data != null) {
                setChangeRequestData(response.data.clientChangeRequestList);
            } else if (response.status == 401) {
                Logout(navigate);
            }
            if (statusChanged) {
                setToastData({ toastVisible: true, toastType: helperText.global.toastSuccess, toastMessage: helperText.Client.statusUpdated })
                setTimeout(() => {
                    setIsLoading(false);
                }, 3000);

            }
            else {
                setToastData({ toastVisible: true, toastType: helperText.global.toastSuccess, toastMessage: helperText.Client.changeRequestUpdated })
                setTimeout(() => {
                    setIsLoading(false);
                }, 3000);
            }
        } else if (response.status == 401) {
            Logout(navigate);
            setIsLoading(false);
        }
    }
    const onSortChanged = (e) => {

        e.api.refreshCells();
    }

    const gridColumns = [
        {
            headerName: "Serial Number",
            // field: "serialNumber",
            valueGetter: "node.rowIndex + 1",
            width: 90,
            cellStyle: {
                wordwrap: "break-word",
                whitespace: "nowrap",
            },
            unSortIcon: false,
            sortable: false,
            filter: false,
        },
        {
            headerName: "Client Name",
            field: "clientName",
            tooltipField: "clientName",
            width: 150,
            wrapText: true,
            cellStyle: {
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                overflow: "hidden",
            },
            cellRendererFramework: (params) => {
                return (
                    <a
                        onClick={() => onClientNameClicked(params.data.clientId)}
                        style={{
                            cursor: "pointer",
                            width: "105px",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                        }}
                    >
                        {params.data.clientName}{" "}
                    </a>
                );
            },
        },
        {
            headerName: "Client PIC",
            field: "clientPicName",
            tooltipField: "clientPicName",
            cellStyle: {
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                overflow: "hidden",
            },
        },
        {
            headerName: "Client Code",
            field: "clientCode",
            tooltipField: "clientCode",
            comparator: customComparator,
        },

        {
            headerName: "Change Requested",
            width: 250,
            field: "changeRequested",
            tooltipField: "changeRequested",
            wrapText: true,
            cellStyle: {
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                overflow: "hidden",
            },
        },
        {
            headerName: "Status",
            field: "status",
            tooltipField: "status",
            sortable: false,
            cellRenderer: (params) => (
                <div style={{ marginTop: '-3px' }} id={"statuses"}>
                    <Dropdown
                        dropdownLabel=""
                        name="status"
                        onChange={(e) => onDropDownSelected(e, params.data)}
                        selected={params.data.statusId}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden', fontSize: '14px' }}
                    >
                        {requestStausList == null
                            ? null
                            : requestStausList.map((item) => (
                                <option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                            ))}
                    </Dropdown>
                    {params.data.statusId > 0 ? (
                        <UncontrolledTooltip
                            autohide={false}
                            target={"statuses"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                        >
                            {requestStausList != null && requestStausList.filter(x => x.value == params.data.statusId)[0]?.label}
                        </UncontrolledTooltip>) : null}
                </div>
            )
        },
        {
            headerName: "Date Requested",
            field: "createdOn",
            tooltipField: "createdOn",
            cellRenderer: (params) => convertDate(params.data.createdOn),
        },
        {
            headerName: "Date Updated",
            field: "editedOn",
            tooltipField: "editedOn",
            cellRenderer: (params) => convertDate(params.data.editedOn),
        },
        {
            headerName: "Comments",
            field: "comments",
            tooltipField: "comments",
            sortable: false,
            //cellStyle: { 'backgroundColor': 'transparent', 'border': 'none' },
            cellRenderer: (params) => (
                <InputFieldEditor
                    onclear={onclear}
                    onEnterKeyPress={onEnterKeyPress}
                    params={params} />
            ),
            cellStyle: {
                textOverflow: "ellipsis",
                whiteSpace: "pre",
                overflow: "hidden",
                backgroundColor: "transparent",
                border: "none"
            },
        },
    ];

    const onclear = (rowIndex) => {

        var rowid = changeRequestData[rowIndex].clientChangeRequestId;
        const newsRows = changeRequestData.map(row => {
            if (row.clientChangeRequestId == rowid) {
                return {
                    ...row,
                    comments: "",
                    editedOn: convertDate(currentDate)
                };
            }
            return row;
        });
        setChangeRequestData([...newsRows]);

        UpdateClientChangeRequestData(newsRows.filter(x => x.clientChangeRequestId == rowid), false)
    };
    const onEnterKeyPress = (commentValue, rowindex) => {

        var rowid = changeRequestData[rowindex].clientChangeRequestId;
        const newsRows = changeRequestData.map(row => {
            if (row.clientChangeRequestId == rowid) {
                row.comments = commentValue;
                row.editedOn = convertDate(currentDate)
                return {
                    ...row
                };
            }
            return row;
        });
        setChangeRequestData(newsRows)

        UpdateClientChangeRequestData(newsRows.filter(x => x.clientChangeRequestId == rowid), false)
    };

    const onClientNameClicked = (clientId) => {
        if (clientId != 0) {
            AddLocalStorageData("selectedClient", clientId);
            navigate("/AddEditClient");
            AddLocalStorageData("location", "/ManageChangeRequest");
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <Header />

            {toastData.toastVisible && (
                <Toast
                    toastVisible={toastData.toastVisible}
                    type={toastData.toastType}
                    message={toastData.toastMessage}
                    closeToast={closeToast}
                />
            )}

            <main className="page-height">
                <section className="communications">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                                <img
                                    className="back-btn-responsive manage-client-back-button"
                                    src="images/back button image.png"
                                    height="22px"
                                    style={{ pointerEvents: "all", cursor: "pointer" }}
                                    onClick={goback}
                                />
                                <span className="title-font">Manage Change Requests</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 table-left-padding table-pl-responsive">
                                <div className="table-responsive">
                                    <AGGrid
                                        rowData={changeRequestData}
                                        colDefs={gridColumns}
                                        rowSelection="single"
                                        pagination="10"
                                        //  onCellKeyPress={onCellKeyPress}
                                        onSortChanged={(e) => onSortChanged(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
