import React from 'react';
import AGGrid from "./AGGrid";

const ViewDocumnetHistoryModal = ({
    onModalCloseClick,
    filesBasedDocumentType,
    colDefs,
}) => {
     
    return (
        <div className="shipdetailupload" style={{ display: "block" }}>
            <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
            <div className="modal Admin-Ship-ViewHistory" id="ViewHistory" style={{ display: "block" }}>

                <div className="modal-dialog modal-md modal-dialog-centered" style={{ maxWidth: "750px" }} >
                    <div className="modal-content">
                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title">View History</h4>
                            <button type="button" data-bs-dismiss="modal">
                                <img src="images/modal-close.png" alt="modal-close" onClick={onModalCloseClick} />
                            </button>
                        </div>
                        {/* <!-- Modal body --> */}
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <AGGrid
                                        rowData={filesBasedDocumentType}
                                        colDefs={colDefs}
                                        rowSelection="single"
                                        pagination="10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 export default ViewDocumnetHistoryModal;
