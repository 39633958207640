import React, { Component } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import {
  GetLocalStorageDataWithParse, helperText, isEmailValid,
  ConvertToHtml,
  sanitizeHTML,
  ConvertFromHTML
} from "../../helpers";
import { isStringEmpty } from "../../helpers/string-helper";
import { contactUsService } from "../../services/contactus.service";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { REGION_ID, KEY, toolbarOptions } from "../../helpers/enums";
import ReCAPTCHA from "react-google-recaptcha";

var scrollValue = 0;
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      errorMessages: {},
      contactName: "",
      email: "",
      contactNumber: "",
      companyName: "",
      subject: "",
      message: "",
      userId: 0,
      contactUsDetailsList: [],
      regionZeroList: [],
      regionOneList: [],
      regionTwoList: [],
      regionThreeList: [],
      regionFourList: [],
      regionFiveList: [],
      showBottomArrow: true,
      emailBody: EditorState.createEmpty(),
      showErrorMessage: false,
      showInvalidEmailErrorMessage: false,
      recaptchaValue: "",
      errorCaptcha: false,
      selectCaptcha: false,
      isDevUrl: window.location.href.includes('dev') ? true : false,
    };
    this.parentref = React.createRef();
    this.recaptchaRef = React.createRef();
    this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);
  }

  /* <summary>
  date: 12-12-2022
  Name: VD
  description: This is the first function that is being called when the page loads
  <summary>*/
  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState({ userId: userData?.id });
    }
    this.getAllContactDetails();

  }

  handleRecaptchaChange(value) {
    this.setState({ recaptchaValue: value, errorCaptcha: "" });
  }

  resetCaptcha = () => {
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset(); // Call the reset() method on the ReCAPTCHA component
      this.setState({ selectCaptcha: false, recaptchaValue: "" });
    }
  };

  /* <summary>
  date: 12-12-2022
  Name: VD
  description: This is used to close the Toast once it has been opened
  <summary>*/
  closeToast = () => {
    this.setState({
      toastVisible: false,
    });
  };

  /* <summary>
  date: 12-12-2022
  Name: VD
  description: This is called when the used moves out from this page
  <summary>*/
  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  /* <summary>
  date: 12-12-2022
  Name: VD
  description: This is used to check if the page has reached bottom or not
  <summary>*/
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  /* <summary>
  date: 12-12-2022
  Name: VD
  description: This is used to track the scrolling
  <summary>*/
  trackScrolling = () => {
    let wrappedElement = document.getElementById("parentDiv");
    if (this.state.userId != 0) {
      wrappedElement = document.getElementById("regionFiveDivId");
    }
    if (this.isBottom(wrappedElement)) {
      this.setState({ showBottomArrow: false });
      //document.removeEventListener("scroll", this.trackScrolling);
    } else {
      this.setState({ showBottomArrow: true });
    }
  };

  /* <summary>
  date: 08-12-2022
  Name: VD
  description: This is used to get all the details of the contacts
  <summary>*/
  getAllContactDetails = async () => {
    try {
      this.setState({ isLoading: true });
      var response = await contactUsService.getAllContactUsDetailsList();
      if (response.status == 200) {
        this.setState({ contactUsDetailsList: response.data }, async () => {
          this.filterContactstoBind(response.data);
        });
      }
      this.setState({ isLoading: false });
    } catch (error) { }
  };

  /* <summary>
  date: 08-12-2022
  Name: VD
  description: This is used to filter the contacts based on the region id
  <summary>*/
  filterContactstoBind = (data) => {
    try {
      this.setState({
        regionZeroList: data.filter((x) => x.regionID == REGION_ID.Global),
        regionOneList: data.filter((x) => x.regionID == REGION_ID.OSSEM),
        regionTwoList: data.filter((x) => x.regionID == REGION_ID.SouthEU),
        regionThreeList: data.filter((x) => x.regionID == REGION_ID.WestEU),
        regionFourList: data.filter((x) => x.regionID == REGION_ID.NorthEastAsia),
        regionFiveList: data.filter((x) => x.regionID == REGION_ID.China),
      });
    } catch (error) { }
  };

  /* <summary>
  date: 08-12-2022
  Name: VD
  description: This is used to scroll the page based
  <summary>*/
  scrollDownImage = () => {
    setTimeout(() => {
      let parent = document.getElementById("parentDiv");
      if (parent) {
        var val = parent.scrollTop;
        if (window.scrollY == 0) {
          scrollValue = 0;
        }
        scrollValue = scrollValue + 800;
        window.scrollTo(scrollValue, scrollValue);
      }
    }, 500);
  };

  /* <summary>
  date: 08-12-2022
  Name: VD
  description: This is a common function that is called on all type of text change
  <summary>*/
  onTextChange = (e) => {
    //SYJ -05/01/2023- Validate the field on change.
    const { name, value } = e.target;
    const { errorMessages, showErrorMessage } = this.state;

    let errors = errorMessages;
    switch (name) {
      case 'contactName':
        errors['toName'] = '';
        break;
      case 'email':
        errors['toEmail'] = '';
        break;
      case 'contactNumber':
        errors['toNumber'] = '';
        break;
      case 'subject':
        errors['toSubject'] = '';
        break;
      default:
        break;
    }

    this.setState({ [name]: value, errorMessages: errors }, () => {
      if (showErrorMessage)
        this.validate()
    });
  };

  /* <summary>
  date: 08-12-2022
  Name: VD
  description: This is used to submi the contact details 
  <summary>*/
  submitDetailsClick = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      if (!this.validate()) {
        const {
          contactName,
          email,
          contactNumber,
          companyName,
          subject,
          emailBody
        } = this.state;
        let tempUserId = 0;
        var userData = GetLocalStorageDataWithParse("loggedInUser");
        if (userData != null) {
          this.setState({ userId: userData.id });
          tempUserId = userData.id;
        }

        let emailBodyToSave = sanitizeHTML(ConvertToHtml(emailBody));
        var contactUsData = {
          userId: tempUserId,
          contactName: contactName,
          email: email,
          contactNumber: contactNumber,
          companyName: companyName,
          subject: subject,
          message: emailBodyToSave,
        };

        var response = await contactUsService.saveContactInformation(
          contactUsData
        );
        if (response.status == 200) {
          this.resetCaptcha();
          if (response.data == true) {
            this.setState({
              toastVisible: true,
              toastMessage: helperText.ContactUs.saveSuccess,
              toastType: helperText.global.toastSuccess,
              errorMessages: {},
              contactName: "",
              email: "",
              contactNumber: "",
              companyName: "",
              subject: "",
              message: "",
              emailBody: EditorState.createEmpty(),
              //userId: 0, SYJ commented to fix => https://jiraapp.edotteam.com/browse/IHMM-1211
              showErrorMessage: false,
              showInvalidEmailErrorMessage: false,
            });
          } else {
            this.setState({
              toastVisible: true,
              toastMessage: helperText.ContactUs.saveError,
              toastType: helperText.global.toastError,
            });
          }
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  validate = () => {
    const {
      contactName,
      email,
      contactNumber,
      subject,
      errorMessages,
      emailBody,
      recaptchaValue,
      isDevUrl
    } = this.state;
    const defaultErrors = {
      toName: "",
      toEmail: "",
      toNumber: "",
      toSubject: "",
      toMessage: ""
    };

    let hasErrors = false;
    let hasInvalidEmailErrors = false;
    let errors = { ...defaultErrors, ...errorMessages };

    const getErrorMessage = (value, fieldName, errorMessage) => {
      if (isStringEmpty(value)) {
        hasErrors = true;
        return errorMessage;
      }
      else {
        return "";
      }
    };

    errors["toName"] = getErrorMessage(contactName, "toName", helperText.ContactUs.emptyName);
    errors["toEmail"] = getErrorMessage(email, "toEmail", helperText.ContactUs.emptyEmail);

    if (!isStringEmpty(email) && !isEmailValid(email)) {
      hasInvalidEmailErrors = true;
      //hasErrors = true;
      errors["toEmail"] = helperText.ContactUs.wrongEmail;
    }

    const contentState = emailBody.getCurrentContent();
    const plainText = contentState.getPlainText().trim();
    errors["toNumber"] = getErrorMessage(contactNumber, "toNumber", helperText.ContactUs.emptyContactNumber);
    errors["toSubject"] = getErrorMessage(subject, "toSubject", helperText.ContactUs.emptySubject);
    errors["toMessage"] = getErrorMessage(plainText, "toMessage", helperText.ContactUs.emptyMessage);
    if (isStringEmpty(recaptchaValue) && !isDevUrl) {
      this.setState({
        errorCaptcha: helperText.loginPageText.errorEmptyCaptcha,
      });
    } else {
      this.setState({
        errorCaptcha: "",
      });
    }

    this.setState({
      errorMessages: errors,
      showErrorMessage: hasErrors,
      showInvalidEmailErrorMessage: hasInvalidEmailErrors
    });

    return hasErrors;
  };

  HandleTextChange = (evt) => {
    const { showErrorMessage } = this.state;
    //var newContent = evt.editor.getData();
    var newContent = evt;
    this.setState({
      emailBody: newContent,
    }, () => {
      if (showErrorMessage) this.validate();
    }
    );
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      contactName,
      email,
      contactNumber,
      companyName,
      subject,
      errorMessages,
      regionZeroList,
      regionOneList,
      regionTwoList,
      regionThreeList,
      regionFourList,
      regionFiveList,
      userId,
      showBottomArrow,
      emailBody,
      showErrorMessage,
      showInvalidEmailErrorMessage,
      errorCaptcha,
      isDevUrl
    } = this.state;
    const ckEditorRemoveButtons = "ImageButton,Button,Undo,SelectAll,Spell Checker,Checkbox,Redo,Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Form,Radio,TextField,Textarea,Select,HiddenField,About,CopyFormatting,RemoveFormat,Language,Flash,Iframe";

    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header componentFor="ContactUs" />
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="admin-main-body contact-us-body-header"
          id="parentDiv"
          ref={this.parentref}
        >
          <div className="contact-us">
            <div className="container-fluid">
              <h1>Contact Us</h1>
            </div>
            <div className="container">
              <h3>
                Contact Us
                <hr />
              </h3>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contactus-form">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="contactus-details">
                        <h3>GET IN TOUCH</h3>
                        <p>
                          If you have any questions or comments please don't
                          hesitate to contact us
                        </p>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={
                                !isStringEmpty(errorMessages.toName)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                type="name"
                                className="form-control"
                                name="contactName"
                                placeholder="Name"
                                required
                                value={contactName}
                                onChange={this.onTextChange}
                              />
                              {!isStringEmpty(errorMessages.toName) && (
                                <div className="invalid-message-icon">
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={
                                !isStringEmpty(errorMessages.toEmail)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                required
                                value={email}
                                onChange={this.onTextChange}
                              />
                              {!isStringEmpty(errorMessages.toEmail) && (
                                <div className="invalid-message-icon">
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={
                                !isStringEmpty(errorMessages.toNumber)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                type="number"
                                className="form-control"
                                name="contactNumber"
                                placeholder="Contact Number"
                                required
                                value={contactNumber}
                                onChange={this.onTextChange}
                                min="0"
                              />
                              {!isStringEmpty(errorMessages.toNumber) && (
                                <div className="invalid-message-icon">
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="name"
                              className="form-control"
                              name="companyName"
                              placeholder="Company Name"
                              required
                              value={companyName}
                              onChange={this.onTextChange}
                            />
                          </div>
                          <div className="col-md-12">
                            <div
                              className={
                                !isStringEmpty(errorMessages.toSubject)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <input
                                type="name"
                                className="form-control"
                                name="subject"
                                placeholder="Subject"
                                required
                                value={subject}
                                onChange={this.onTextChange}
                              />
                              {!isStringEmpty(errorMessages.toSubject) && (
                                <div className="invalid-message-icon">
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className={
                                !isStringEmpty(errorMessages.toMessage)
                                  ? "invalid-position"
                                  : ""
                              }
                            >
                              <Editor
                                editorState={emailBody}
                                placeholder="Enter Comments"
                                editorStyle={{ height: 200, border: '1px solid #ced4da', paddingLeft: '10px' }}
                                wrapperClassName="demo-wrapper "
                                editorClassName="editer-content editor-height"
                                toolbarClassName="toolbar-class candrag"
                                onEditorStateChange={(e) => {
                                  this.HandleTextChange(e);
                                }}
                                handlePastedText={(val) => {
                                  const textLength =
                                    emailBody
                                      .getCurrentContent()
                                      .getPlainText().length;
                                  return (
                                    val.length + textLength
                                  );
                                }}
                                toolbar={toolbarOptions}
                              />

                              {!isStringEmpty(errorMessages.toMessage) && (
                                <div className="invalid-message-icon" style={{ top: '82px' }}>
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            id="captchaBackground"
                            className={!isStringEmpty(errorMessages.toMessage)
                              ? "captch-checkbox-validation" : "invalid-position"}
                          >
                            {!isDevUrl ?
                              (<div style={{ display: "flex", justifyContent: "center", marginTop: "10px", alignItems: "center" }}>
                                <ReCAPTCHA
                                  sitekey={KEY.RECAPTCHA_SITE_KEY}
                                  onChange={this.handleRecaptchaChange}
                                  ref={this.recaptchaRef}
                                />
                              </div>) : null}
                            <span id="output"></span>
                            {!isStringEmpty(errorCaptcha) && (
                              <div className="invalid-message">
                                <i
                                  className="fa fa-exclamation-circle"
                                  aria-hidden="true"
                                ></i>
                                <div className="invalid-message-icon-box" style={{ textAlign: "center" }}>
                                  <p>{errorCaptcha}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="contactus-formbtn">
                          {/* {showErrorMessage ? ( */}
                          <div className="invalid-message-icon-box" style={{ display: showErrorMessage ? '' : 'none' }}>
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                            ></img>
                            <p>Please fill in all the mandatory fields.</p>
                          </div>
                          {/* ) : null} */}

                          {showInvalidEmailErrorMessage ? (
                            <div className="invalid-message-icon-box">
                              <img
                                src="images\icon material-error.png"
                                alt="invalidEmail"
                              ></img>
                              <p>Please enter a valid Email.</p>
                            </div>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-block Contact_Us-btn"
                            onClick={this.submitDetailsClick}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      {showBottomArrow ? (
                        <a
                          className="contactscrolltobottom"
                        >
                          <img
                            src="images/contactus-scrolldown.gif"
                            alt="scroll-down"
                            id="myBtn"
                            onClick={this.scrollDownImage}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-5">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1064.1452319063835!2d103.75199949892463!3d1.3115166262645974!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8f5fc76e77a6254b!2sMaritec%20Pte%20Ltd!5e0!3m2!1sen!2sin!4v1667885445991!5m2!1sen!2sin"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="contactus-map"
                      />
                      <div className="contactus-map-address">
                        <h5>Address</h5>
                        <div className="location-flex">
                          <img
                            src="images/contact-us-map-location.png"
                            alt="location"
                            className="map-arrow"
                          />
                          <p>
                            192 Pandan Loop, #05-27 Pantech Business Hub,
                            Singapore 128381
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row contactus-address-top"
              id="contactusaddressdetail"
            >
              <h4 className="Contactus-header">IHMM</h4>
              <div className="col-xl-4 col-md-6 mb-2">
                {regionZeroList.map((x) => (
                  <div className="contactus-address" key={x.contactUsDetailsID}>
                    <h6>{x.contactName}</h6>
                    <div className="contactus-flex-phone">
                      <img
                        src="images/phone.png"
                        alt="phone"
                        className="phone"
                      />
                      <a>{x.contactNumber}</a>
                    </div>
                    <div className="contactus-flex">
                      <img
                        src="images/email.png"
                        alt="email"
                        className="email-address"
                      />
                      <a>{x.contactEmail}</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {userId != 0 ? (
              <div className="row">
                <h4 className="Contactus-header">
                  Region 1 - Oceania, South Asia, Southeast Asia, and Middle
                  East
                </h4>
                {regionOneList.map((x) => (
                  <div
                    className="col-xl-4 col-md-6 mb-2"
                    key={x.contactUsDetailsID}
                  >
                    <div className="contactus-address">
                      <h6>{x.contactName}</h6>
                      <div className="contactus-flex">
                        <img
                          src="images/email.png"
                          alt="email"
                          className="email-address"
                        />
                        <a>{x.contactEmail}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            {userId != 0 ? (
              <div className="row">
                <h4 className="Contactus-header">Region 2 - South EU</h4>
                {regionTwoList.map((x) => (
                  <div
                    className="col-xl-4 col-md-6 mb-2"
                    key={x.contactUsDetailsID}
                  >
                    <div className="contactus-address">
                      <h6>{x.contactName}</h6>
                      <div className="contactus-flex">
                        <img
                          src="images/email.png"
                          alt="email"
                          className="email-address"
                        />
                        <a>{x.contactEmail}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            {userId != 0 ? (
              <div className="row">
                <h4 className="Contactus-header">Region 3 - West EU</h4>
                {regionThreeList.map((x) => (
                  <div
                    className="col-xl-4 col-md-6 mb-2"
                    key={x.contactUsDetailsID}
                  >
                    <div className="contactus-address">
                      <h6>{x.contactName}</h6>
                      <div className="contactus-flex">
                        <img
                          src="images/email.png"
                          alt="email"
                          className="email-address"
                        />
                        <a>{x.contactEmail}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            {userId != 0 ? (
              <div className="row">
                <h4 className="Contactus-header">Region 4 - North East Asia</h4>
                {regionFourList.map((x) => (
                  <div
                    className="col-xl-4 col-md-6 mb-2"
                    key={x.contactUsDetailsID}
                  >
                    <div className="contactus-address">
                      <h6>{x.contactName}</h6>
                      <div className="contactus-flex">
                        <img
                          src="images/email.png"
                          alt="email"
                          className="email-address"
                        />
                        <a>{x.contactEmail}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            {userId != 0 ? (
              <div className="row" id="regionFiveDivId">
                <h4 className="Contactus-header">Region 5 - China</h4>
                {regionFiveList.map((x) => (
                  <div
                    className="col-xl-4 col-md-6 mb-2"
                    key={x.contactUsDetailsID}
                  >
                    <div className="contactus-address">
                      <h6>{x.contactName}</h6>
                      <div className="contactus-flex">
                        <img
                          src="images/email.png"
                          alt="email"
                          className="email-address"
                        />
                        <a>{x.contactEmail}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactUs;
