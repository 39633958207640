import React, { Component, Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import 'ag-grid-community/styles//ag-theme-balham.css';
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { TestService } from '../../services/test.service';
import BlankProfile from '../../assets/images/BlankProfile.png';
import Loader from '../../components/Loader';
import { AddLocalStorageData, withRouter } from '../../helpers';
import AttachmentPreview from "../DemoTest/AttachmentPreview.jsx"
import Searchbar from '../../components/Searchbar';
import DOMPurify from "dompurify";

class OutlookTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            list: [],
            defaultList: [],
            listCcEmail: [],
            showPreviewModal: false,
            srcPreviewUrl: '',
            docFilter: '',
            senderFilter: '',
        }
    }

    componentDidMount = () => {
        this.GetEmailData(false);
    }

    GetEmailData = async (sendEmail) => {
        this.setState({ isLoading: true });

        const response = await TestService.SendEmail();
        if (response.data != null && response.data != undefined) {
            this.setState({
                list: response.data,
                defaultList: response.data,
                isLoading: false
            });
        }

        this.setState({
            isLoading: false,
        });
    }

    onLinkDownloadClick = async (contentType, content, name, senderEmailId) => {
        var path = `data:${contentType};base64,${content}`;
       // var senderEmail = senderEmailId.split('<')[1].split('>')[0];
        // const blobImage = window.URL.createObjectURL(new Blob([path]));
        // var imageblob = this.fetchImage(blobImage);
        //var emailbodySplit = emailBody.split('Supplier ID:')

        const filenew = new File([path], 'MDSDoC_PO-AVE770_1.pdf', {
            type: contentType,
        });

        let supplierData = new FormData();
        supplierData.append('SenderEmailId', senderEmailId);
        supplierData.append('BlobAttachment', path);
        supplierData.append('AttachmentName', name);

        const response = await TestService.SaveAttachment(supplierData);
        if (response.data != null) {
            AddLocalStorageData("selectedSupplier", JSON.stringify(response.data));
        }
        this.props.navigate('/TestTrackingToolScreen');
    }

    fetchImage = async url => {
        const response = await fetch(url, { mode: 'no-cors', });
        const blob = await response.blob();
        const base64 = await this.convertBase64(blob);
        return base64;
    }

    convertBase64 = async file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    onLinkClick = () => {
        this.props.navigate('/ManageSuppliers');
    }

    onPreviewClick = (contentType, content) => {
        var path = `data:${contentType};base64,${content}`;

        this.setState({
            showPreviewModal: true,
            srcPreviewUrl: path
        });
    }

    onSearch = (e) => {
        var inputValue = e.target.value;
        var trackingList = this.state.defaultList;

        if (inputValue.trim() !== "") {
            var value = inputValue.trim().toString().toLowerCase();

            trackingList = trackingList.filter(
                t => t.senderName.toString().toLowerCase().includes(value) ||
                    t.ticketEmailBody.toString().toLowerCase().includes(value) ||
                    t.senderEmailId.toString().toLowerCase().includes(value) ||
                    t.ticketEmailSubject.toString().toLowerCase().includes(value));
        }

        this.setState({
            list: trackingList,
        });
    }

    onFilterSelected = (e) => {
        var { name, value } = e.target;
        this.setState({
            [name]: value.toString().toLowerCase(),
        }, () => this.filterGridView());
    }

    filterGridView = () => {
        const {
            docFilter,
            senderFilter,
        } = this.state;

        var filterList = [];
        var trackingToolList = this.state.defaultList;

        if (docFilter == "" && senderFilter == "")
            filterList = this.state.defaultTrackingTool;
        else {
            if (docFilter != "")
                filterList = filterList.length > 0 ? filterList.filter(e => e.ticketEmailSubject.toString().toLowerCase().includes(docFilter)) : trackingToolList.filter(e => e.ticketEmailSubject.toString().toLowerCase().includes(docFilter));

            if (senderFilter != "")
                filterList = filterList.length > 0 ? filterList.filter(e => e.senderName.toString().toLowerCase().includes(senderFilter)) : trackingToolList.filter(e => e.senderName.toString().toLowerCase().includes(senderFilter));
        }
        this.setState({
            list: filterList,
        });
    }

    render() {
        return (
            <div>
                {this.state.isLoading && <Loader></Loader>}
                <Header />
                <AttachmentPreview
                    showModal={this.state.showPreviewModal}
                    srcUrl={this.state.srcPreviewUrl}
                />
                <div className="clearfix">
                    <div
                        style={{ height: '580px', overflowY: 'scroll', margin: 50 }}
                    >
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-8 ">
                                <div className="admin-profile-detail-back">
                                    <a href="#"><img src="images/back.png" alt="Back" /></a>
                                    <h5>Communication Received Tab - Demo</h5>
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-7 col-lg-4">
                                <div className="searchBar">
                                    <Searchbar placeholder={"Sender Name, Subject, Email Body, etc..."} onChange={this.onSearch} />
                                </div>
                            </div> */}
                        </div>
                        <div className="desktop-class">
                            <div className="row">
                                <div className="col-lg-12 filter-flex-container wrap">
                                    <div className="filter-box filter-flex-item" >
                                        <label htmlFor="exampleDataList" className="form-label">Email Type:</label>
                                        <select className="form-select" aria-label="Default select example" name="docFilter" onChange={this.onFilterSelected}>
                                            <option selected value="">Select</option>
                                            <option value="MDSDoC">MD/SDoC</option>
                                            <option value="Quarterly">Quarterly Email</option>
                                            <option value="Annual">Annual Report Email</option>
                                            <option value="SOC">SOC Expiry Email</option>
                                            <option value="Password Changed">Password Changed</option>
                                        </select>
                                    </div>


                                    <div className="filter-box filter-flex-item">
                                        <label htmlFor="exampleDataList" className="form-label">Sender:</label>
                                        <select className="form-select" aria-label="Default select example" name="senderFilter" onChange={this.onFilterSelected}>
                                            <option selected value="">Select</option>
                                            <option value="Deepika">Deepika</option>
                                            <option value="Shreya">Shreya</option>
                                            <option value="Suresh">Suresh</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <br />
                        <br />
                        {this.state.list.map((x, i) => {
                            return (
                                <div key={i} className="container ticket-entry" style={{ margin: 20, border: 1, borderStyle: 'solid' }}>
                                    <div className="row">
                                        <div className="col-1 col-md-1">
                                            <img src={BlankProfile} alt="Avatar" className="right avatar-img-className sender-avatar-img-responsive" width="70px" />
                                        </div>
                                        <div className="col-11 col-md-11 left-column-chat">
                                            <div className="ticket-message-box arrow-left">
                                                <p style={{ backgroundColor: "lightgrey", height: 40, padding: 10 }}><b>{x.senderName}</b></p>
                                                {x.listCCEmail.length > 0 ?
                                                    <p style={{ marginBottom: "0px" }}><b>CC:</b> {x.listCCEmail.toString()}</p> : null}
                                            </div>
                                            <div className="reply-box">
                                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.ticketEmailBody) }}></span>
                                            </div>
                                            {
                                                x.attachments.length > 0 ?
                                                    (
                                                        <>
                                                            {
                                                                <div className="reply-box">
                                                                    <b>Attachments:</b>  <br />
                                                                    {
                                                                        x.attachments.map((a, i) => (
                                                                            <>
                                                                                <div style={{ display: 'inline-block', marginRight: 10 }}>
                                                                                    <iframe src={`data:${a.contentType};base64,${a.content}`} type={`${a.contentType}`} height="150px" width="200px"></iframe>
                                                                                    <br />
                                                                                    <a href="" target="_blank" data-bs-toggle="modal" data-bs-target="#manage-template" onClick={() => this.onPreviewClick(a.contentType, a.content)}>Preview</a>
                                                                                    <br />
                                                                                    <a href={`data:${a.contentType};base64,${a.content}`} download={a.name} target="_blank">Download</a>
                                                                                    <br />
                                                                                    {/* <a download={a.name} href="" target="_blank" onClick={this.onLinkClick}>Link</a> */}
                                                                                    {/* <br /> */}
                                                                                    <a target="_blank" onClick={() => this.onLinkDownloadClick(a.contentType, a.content, a.name, x.senderEmailId)}>Link Download</a>
                                                                                </div>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default withRouter(OutlookTest)