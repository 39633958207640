import React, { Component } from "react";
import Switch from "react-switch";
import Loader from "../components/Loader";
import { UserRole } from "../helpers/enums";
import { ClientService } from "../services/client.service";
import { GetLocalStorageDataWithParse, helperText, withRouter } from "../helpers";

export default class ClientRolesNPermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: this.props.clientId,
      isLoading: false,
      accessPermissionsFleetAdmin: [],
      accessPermissionsFleetManager: [],
      accessPermissionsSuperintendent: [],
      accessPermissionsOthers: [],
    };
  }

  componentDidMount() {
    this.getDefaultAccessPermissions();
  }

  getDefaultAccessPermissions = async () => {
    var clientData = GetLocalStorageDataWithParse("loggedInUser");
    this.setState({
      isLoading: false,
      loggedInUserID: clientData.id,
      isClient: clientData.typeId == UserRole.CLIENT,
    });
    const response = await ClientService.GetDefaultPermissions();
    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data.length > 0) {
        this.setState({
          accessPermissionsFleetAdmin: response.data[0],
          accessPermissionsFleetManager: response.data[1],
          accessPermissionsSuperintendent: response.data[2],
          accessPermissionsOthers: response.data[3],
          isLoading: false,
        });
      } else {
        this.setState({
          accessPermissionsFleetAdmin: { ...defaultArray, clientRoleId: 1 },
          accessPermissionsFleetManager: { ...defaultArray, clientRoleId: 2 },
          accessPermissionsSuperintendent: { ...defaultArray, clientRoleId: 3 },
          accessPermissionsOthers: { ...defaultArray, clientRoleId: 4 },
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  render() {
    const { showModal, closeModal } = this.props;
    const {
      isLoading,
      accessPermissionsFleetAdmin,
      accessPermissionsFleetManager,
      accessPermissionsSuperintendent,
      accessPermissionsOthers,
    } = this.state;
    const SwitchControl = (props) => {
      return (
        <td className="center">
          <Switch
            id={props.id}
            className="switch"
            height={20}
            width={40}
            disabled={true}
            checked={props.IsChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={"#C90000"}
          />
        </td>
      );
    };
    const SectionRow = (props) => {
      return (
        <tr
          style={{
            backgroundColor: props.permissionAccess == 0 ? "#ff3b3b" : "",
          }}
        >
          <SwitchControl
            id={`read_${props.id}`}
            IsChecked={props.permissionAccess == 1}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />{" "}
          {/*Read */}
          <SwitchControl
            id={`readWrite_${props.id}`}
            IsChecked={props.permissionAccess == 2}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Read and Write */}
          <SwitchControl
            id={`deny_${props.id}`}
            IsChecked={props.permissionAccess == 3}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Deny */}
        </tr>
      );
    };

    return (
      <section className="communications role-permissions-class">
        {isLoading && <Loader></Loader>}
        <div
          className="modal-backdrop fade show"
          style={{ display: showModal ? "block" : "none" }}
        ></div>
        <div
          className="modal"
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog custom-modal-dialog-width modal-dialog-centered modal-custom-css">
            <div className="modal-content custom-modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">
                  {"View Roles & Permissions"}
                </h5>
                <img
                  className="close-img-icon"
                  src="images/modal-close.png"
                  width="25px"
                  style={{ pointerEvents: "all" }}
                  onClick={closeModal}
                />
              </div>
              <div className="modal-body">
                <div className="row  levels-table">
                  <div className="col-12 col-md-12 col-lg-12 table-left-padding table-pl-responsive">
                    <div className="level-boxes client-level-boxes">
                      <div className="level-boxes-spacing mobile-level-box">
                        <h5 className="level-heading"> Levels: </h5>
                        <table className="table table-bordered manage-users-table roles-permissions-tbl client-roles-permissions-tbl">
                          <thead className="level-table-header-color">
                            <tr>
                              <th className="table-one-text">Roles</th>
                            </tr>
                          </thead>
                          <tbody className="table-one-body">
                            <tr>
                              <td className="roles-table-heading">
                                Ship Particulars
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Tracking Tool
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">Reports</td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Statistics
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">Training</td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Add Client User
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">Documents</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="table-responsive d-flex">
                        <div className="level-boxes-spacing mobile-level-box1">
                          <h5 className="level-heading">Fleet Admin:</h5>
                          <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body">
                              <SectionRow
                                id="shipParticularsAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.shipParticularsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trackingToolAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.trackingToolAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="reportsAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.reportsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="statisticsAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.statisticsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trainingAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.trainingAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="addClientAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.addClientAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="documentsAccess_FleetAdmin"
                                permissionAccess={
                                  accessPermissionsFleetAdmin.documentsAccess
                                }
                                precedingPermission={""}
                              />
                            </tbody>
                          </table>
                        </div>

                        <div className="level-boxes-spacing">
                          <h5 className="level-heading">Fleet Manager:</h5>
                          <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body">
                              <SectionRow
                                id="shipParticularsAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.shipParticularsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trackingToolAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.trackingToolAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="reportsAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.reportsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="statisticsAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.statisticsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trainingAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.trainingAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="addClientAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.addClientAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="documentsAccess_FleetManager"
                                permissionAccess={
                                  accessPermissionsFleetManager.documentsAccess
                                }
                                precedingPermission={""}
                              />
                            </tbody>
                          </table>
                        </div>

                        <div className="level-boxes-spacing">
                          <h5 className="level-heading">Superintendent:</h5>
                          <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body">
                              <SectionRow
                                id="shipParticularsAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.shipParticularsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trackingToolAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.trackingToolAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="reportsAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.reportsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="statisticsAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.statisticsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trainingAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.trainingAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="addClientAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.addClientAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="documentsAccess_Superintendent"
                                permissionAccess={
                                  accessPermissionsSuperintendent.documentsAccess
                                }
                                precedingPermission={""}
                              />
                            </tbody>
                          </table>
                        </div>

                        <div className="level-boxes-spacing">
                          <h5 className="level-heading">Others:</h5>
                          <table className="table manage-users-table roles-permissions-table client-roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body">
                              <SectionRow
                                id="shipParticularsAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.shipParticularsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trackingToolAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.trackingToolAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="reportsAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.reportsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="statisticsAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.statisticsAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trainingAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.trainingAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="addClientAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.addClientAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="documentsAccess_Others"
                                permissionAccess={
                                  accessPermissionsOthers.documentsAccess
                                }
                                precedingPermission={""}
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
