import React from 'react';

const DirtyModal = ({ onModalCloseClick, onProceedClick }) => {
    return (
        <div className="deletedoc" style={{ display: "block" }}>
            <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
            <div className="modal" id="deleteDoc-modal" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box">
                    <div className="modal-content box">
                        <div className="modal-body">
                            <div className="block1">
                                Do you want to exit without saving changes?
                            </div>
                            <div className=" text-center yes-no-btn">
                                <div> <button type="button" className="btn btn-background-color yes-btn " onClick={onProceedClick}>Yes</button></div>
                                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                <div><button type="button" className="btn btn-background-color  yes-btn" onClick={onModalCloseClick}>No</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DirtyModal;