import React, { Component, Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import 'ag-grid-community/styles//ag-theme-balham.css';
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import { TestService } from '../../services/test.service';
import BlankProfile from '../../assets/images/BlankProfile.png';
import Loader from '../../components/Loader';
import { withRouter } from '../../helpers';
import AttachmentPreview from "../DemoTest/AttachmentPreview.jsx"
import Searchbar from '../../components/Searchbar';

class TestTrackingToolScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            path: "",
            dwnldPath: "",
            fileName: "",
        }
    }

    componentDidMount = async () => {
        const response = await TestService.GetAttachmentClicked();
        if (response.data != null && response.data != undefined) {
            this.setState({
                path: "https://localhost:7053/IHMMDevFiles/OutlookAttachments/" + response.data.fileName,
                dwnldPath: response.data.path,
                fileName: response.data.fileName,
            });
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="clearfix">
                    <div
                        style={{ height: '580px', overflowY: 'scroll', margin: 50 }}
                    >
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-8 ">
                                <div className="admin-profile-detail-back">
                                    <a href="#"><img src="images/back.png" alt="Back" /></a>
                                    <h5>Edit Tracking Tool Dummy</h5>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <h2>To demo MDSDoC document saved in PO line item on click of any attachment in email</h2>
                        <div className="container ticket-entry" style={{ margin: 20, border: 1, borderStyle: 'solid' }}>
                            <div className="row">
                                <div className="col-11 col-md-11 left-column-chat">
                                    <label htmlFor="exampleDataList" className="form-label">PO Number: PO-AVE770</label>
                                    <br />
                                    <label htmlFor="exampleDataList" className="form-label">{this.state.fileName}</label>
                                    <br />
                                    <a href={this.state.path} download={this.state.fileName} target="_blank">View/Download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default withRouter(TestTrackingToolScreen)