import React from "react";

const ProceedModal = ({
    showProceedModal,
    onNoClick,
    onYesClick,
    modalFor,
}) => {
    return (
        <section>
            <div className="deletedoc">
                <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
                <div className="modal" id="deleteDoc-modal" style={{ display: showProceedModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-l modal-dialog-centered modal-box">
                        <div className="modal-content box" style={{ marginTop: modalFor == "Change Request" || modalFor == "Help & Support" ? 0 : '170px' }}>
                            <div className="modal-body">
                                <div className="block1">
                                    Are you sure you want to proceed?
                                </div>
                                <div className=" text-center yes-no-btn">
                                    <div > <button type="button" className="btn btn-background-color yes-btn " onClick={onYesClick}>Yes</button></div>
                                    &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                    <div ><button type="button" className="btn btn-background-color  yes-btn" onClick={onNoClick}>No</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProceedModal;