import React, { useEffect, useState } from "react";

const Snackbar = ({ message, toastType, showToast, CloseToast, position }) => {
  const [showSnackBar, setshowSnackBar] = useState(showToast);
  useEffect(() => {
    if (showToast == true) {
      setTimeout(() => {
        setshowSnackBar(false);
        CloseToast();
      }, 3000);
    }
    setshowSnackBar(showToast);
  }, [showToast == true]);
  return (
    <div
      id="snackbar"
      className={showSnackBar ? "show" : ""}
      style={{
        zIndex: 20000,
        backgroundColor:  "rgb(244 67 54)" ,
        top: position == undefined ? "initial" : "95%",
      }}
    >
      {message}
    </div>
  );
};

export default Snackbar;
