import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerComponent({ selectedDate, onDateChange, isNotRoot,readOnly, disabled, minDate, maxDate }) {
  return (
    <DatePicker
      dateFormat="dd-MMM-yyyy"
      portalId={isNotRoot ? "" : "root"}
      selected={selectedDate}
      onChange={(ev) => onDateChange(ev)}
      className="form-control"
      popperClassName="ag-custom-component-popup"
      scrollableYearDropdown={true}
      showYearDropdown
      showMonthDropdown
      readOnly={readOnly}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      yearDropdownItemNumber={50}//SYJ - 20/04/2023 - To Increase the Years Min and Max in Year dropDown
    />
  );
}
