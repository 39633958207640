import React from 'react'
import { config } from "../helpers/config";

export default function LoginHeader() {
    return (
        <div className="login-header">
            <div className="navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" target={"_blank"} href={config.maritecSiteUrl}>About Us</a>
                    </li>
                    {/* <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                Services
                                            </a>
                                        </li> */}
                    <li className="nav-item">
                        <a className="nav-link" href="/ContactUs">
                            Contact Us
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    )
}
