import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { isStringEmpty } from "../../helpers/string-helper";
import ErrorMessageBox from "../../components/ErrorMessageBox";
import ErrorElementView from "../../components/ErrorElementView";
import { ErrorViewFor } from "../../helpers/enums";
import { helperText, isValidFile, GetLocalStorageDataWithoutParse } from "../../helpers";
import Toast from "../../components/Toast";
import { MiscellaneousOperationsService } from "../../services/miscellaneousoperations.service";
import Logout from "../../helpers/Logout";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";
import { Module } from "../../helpers/enums";

const validFileFormatsReadable =
  "application/pdf,application/PDF,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
const validFileFormatsExt = [".pdf, .xlsx, .xls,.PDF"];

export default class UploadMiscellaneousFileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileInputKey: "",
      filePath: "",
      validateError: false,
      fileName: "",
      errorMessages: {},
      dropdown_client: 0,
      dropdown_ship: 0,
      dropdown_documentType: 0,
      dropdownList: this.props.dropdownList,
      defaultShipList: this.props.shipList,
      shipList: [],
      input_documentName: "",
      input_Remark: "",
      TFile: null,
      invalidFileFormat: false,
      showTypeNameModal: false,
      typeName: "",
      toastVisible: false,
      toastType: "",
      toastMessage: ""
    };
  }

  onUploadButtonClick = () => {
    var {
      dropdown_client,
      dropdown_ship,
      dropdown_documentType,
      TFile,
      input_Remark,
      input_documentName,
    } = this.state;
    const { onUploadClick } = this.props;
    var hasErrors = this.validateFields();

    if (hasErrors) return;

    const data = {
      miscellaneousOperationsID: this.state.MiscellaneousOperationsID,
      dropdown_client: dropdown_client,
      dropdown_ship: dropdown_ship,
      dropdown_documentType: dropdown_documentType,
      TFile: TFile,
      remark: input_Remark.trim(),
      documentName: input_documentName.trim(),
    };
    onUploadClick(data);
  };

  validateFields() {
    const { dropdown_client, dropdown_documentType, TFile, errorMessages } = this.state;

    var hasErrors = false;
    let errors = errorMessages;

    errors["errorClient"] =
      dropdown_client == undefined || dropdown_client == 0 ? "true" : "";
    errors["errorDocumentType"] =
      dropdown_documentType == undefined || dropdown_documentType == 0
        ? "true"
        : "";
    errors["errorTFile"] = TFile == null ? "true" : "";
    if (
      errors["errorClient"] != "" ||
      errors["errorDocumentType"] != "" ||
      errors["errorTFile"] != ""
    ) {
      hasErrors = true;
    }

    this.setState({
      validateError: hasErrors,
      errorMessages: errors,
    });

    return hasErrors;
  }

  onFileUpload = (e) => {
    const { name, value } = e.target;
    const {  errorMessages, validateError } = this.state;
    let errors = errorMessages;

    if (value != "" && !isValidFile(value, validFileFormatsExt)) {
      errors["errorTFile"] = "true";
      this.setState({
        [name]: null,
        fileName: "",
        invalidFileFormat: true,
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileError,
      },
        () => {
          if (validateError) this.validateFields();
        });
      return;
    }

    let files = e.target.files[0];
    errors["errorTFile"] = "";
    this.setState({
      [name]: files,
      fileName: files != null ? files.name : "",
      filePath: value,
      errorMessages: { ...errors },
      invalidFileFormat: false,
    },
      () => {
        if (validateError) this.validateFields();
      });
  };

  //HV - 24/02/2023 - population ship list based on selected client
  onDropdownChangeClient = (e) => {
    const { name, value } = e.target;
    var { errorMessages, defaultShipList, validateError } = this.state;

    // errorMessages["errorClient"] =
    //   value == "0" ? errorMessages["errorClient"] : "";
    this.setState((prevState) => ({
      dropdown_client: value,
      errorMessages: errorMessages,
      shipList: defaultShipList.filter(
        (e) => e.parentValue === parseInt(value, 10)
      )
    }), () => {
        if(validateError){
          this.validateFields();
        }
    });
  };

  onDropdownChangeShip = (e) => {
    const { name, value } = e.target;
    var { errorMessages, validateError } = this.state;

    this.setState({ dropdown_ship: value, errorMessages }, () => {
      if(validateError){
        this.validateFields();
      }
    })
  };

  onDropdownChangeDocumentType = (e) => {

    const { name, value } = e.target;
    var { errorMessages, validateError } = this.state;
    // errorMessages["errorDocumentType"] =
    //   value == "0" ? errorMessages["errorDocumentType"] : "";
    if (name == "dropdown_documentType") {
      var othersSelected = this.props.dropdownList.documentTypeList.filter(x => x.value == value)[0].label;
      this.setState({ showTypeNameModal: othersSelected === 'Others' });
    }
    this.setState({ dropdown_documentType: value, errorMessages }, () => {
      if(validateError){
        this.validateFields();
      }
    });
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    const { validateError } = this.state;

    this.setState({
      [name]: value,
    }, () => {
      if(validateError){
        this.validateFields();
      }
    });
  };

  onCloseTypeClick = () => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    errors["errorTypeName"] = "";
    errorMessages["errorDocumentType"] = "";
    this.setState({ dropdown_documentType: 0, showTypeNameModal: false, typeName: "", errorMessages: errors, });
  };
  ontypeNameChange = (e) => {

    var { errorMessages } =
      this.state;
    let errors = errorMessages;

    this.setState({ typeName: e.target.value, }, () => {
      if (!isStringEmpty(errorMessages.errorTypeName)) {
        errors["errorTypeName"] = e.target.value.trim() === "" ? "true" : "";
        this.setState({
          errorMessages: errors,
        })
      }
      else if (!isStringEmpty(errorMessages.DocTypeExist)) {
        this.CheckIfDocTypeExist(e.target.value.trim());
      }
    });
  }
  ontypeNameSaveClicked = async (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    if (this.state.typeName.trim() != "") {

      await this.CheckIfDocTypeExist(this.state.typeName.trim());

      if (isStringEmpty(errorMessages.DocTypeExist)) {
        errors["errorTypeName"] = "";
        errorMessages["errorDocumentType"] = "";
        await this.props.onSaveNewTypeClick(this.state.typeName.trim());

        this.setState({ showTypeNameModal: false, dropdown_documentType: GetLocalStorageDataWithoutParse('selecteddoctype') != null ? GetLocalStorageDataWithoutParse('selecteddoctype') : 0, typeName: "", errorMessages: errors, })
      }
    }
    else {
      errors["errorTypeName"] = "true";
      this.setState({
        errorMessages: errors,
      });
    }
  }
  CheckIfDocTypeExist = async (typeName) => {
    let errors = this.state.errorMessages;

    var docData = {
      TypeName: typeName,
      Module: Module.Miscellaneous
    }

    const response = await CommonService.CheckIfDocumentTypeExist(docData);

    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["DocTypeExist"] = "true";
      } else {
        errors["DocTypeExist"] = "";
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };

  render() {
    const { showModal, onCloseClick, onUploadClick, dropdownList, selecteddoctype } = this.props;

    const {
      fileInputKey,
      validateError,
      fileName,
      errorMessages,
      input_Remark,
      input_documentName,
      shipList,
      dropdown_ship,
      invalidFileFormat,
      dropdown_documentType,
      toastVisible,
      toastMessage,
      toastType,
      dropdown_client
    } = this.state;

    return (
      <div className="Finance-Account" style={{ display: "block" }}>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div className="modal" id="uploadFin-Ac" style={{ display: "block" }}>
          <div className="modal-dialog modal-l modal-dialog-centered modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload Document</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick("showUploadModal", false)}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="Finance-Account-modalbody">
                  <div className="PO-list-topLabel">
                    <div className="row">
                      <div className="col-12 col-lg-6 client">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorClient)
                              ? "invalid-position"
                              : ""
                          }
                          id={"clientName"}
                        >
                          <label className="required-field">Client:</label>
                          <Dropdown
                            //dropdownLabel="Client:"
                            name="client"
                            onChange={this.onDropdownChangeClient}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {dropdownList.clientList == null
                              ? null
                              : dropdownList.clientList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>

                          {dropdown_client > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"clientName"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {dropdownList.clientList != null && dropdownList.clientList.filter(x => x.value == dropdown_client)[0]?.label}
                            </UncontrolledTooltip>) : null}
                          {!isStringEmpty(errorMessages.errorClient) && (
                            <ErrorElementView
                              errorFor={
                                ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 ship">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorShip)
                              ? "invalid-position"
                              : ""
                          }
                          id={"shipName"}
                        >
                          <Dropdown
                            dropdownLabel="Ship:"
                            name="ship"
                            onChange={this.onDropdownChangeShip}
                            selected={dropdown_ship}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {shipList == null
                              ? null
                              : shipList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_ship > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"shipName"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {shipList != null && shipList.filter(x => x.value == dropdown_ship)[0]?.label}
                            </UncontrolledTooltip>) : null}
                          {!isStringEmpty(errorMessages.errorShip) && (
                            <ErrorElementView
                              errorFor={
                                ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorDocumentType)
                              ? "invalid-position"
                              : ""
                          }
                          id={"docType"}
                        >
                          <label className="required-field">Type:</label>
                          <Dropdown
                            //dropdownLabel="Type:"
                            name="documentType"
                            onChange={this.onDropdownChangeDocumentType}
                            selected={dropdown_documentType}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {dropdownList.documentTypeList == null
                              ? null
                              : dropdownList.documentTypeList.map(
                                (obj, key) => (
                                  <option key={key} value={obj.value}>
                                    {obj.label}
                                  </option>
                                )
                              )}
                            ;
                          </Dropdown>
                          {dropdown_documentType > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"docType"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {dropdownList.documentTypeList != null && dropdownList.documentTypeList.filter(x => x.value == dropdown_documentType)[0]?.label}
                            </UncontrolledTooltip>) : null}
                          {!isStringEmpty(errorMessages.errorDocumentType) && (
                            <ErrorElementView
                              errorFor={
                                ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorDocumentName)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <label className="label-class label-mobile label-dropdown">
                            Document Name:
                          </label>

                          <input
                            autoFocus={false}
                            autoComplete={"off"}
                            className="form-control"
                            type="text"
                            id="documentName"
                            name="input_documentName"
                            value={input_documentName}
                            onChange={this.onTextChange}
                          />
                          {!isStringEmpty(errorMessages.errorDocumentName) && (
                            <ErrorElementView
                              errorFor={
                                ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorRemark)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <label className="label-class label-mobile label-dropdown">
                            Remark:
                          </label>

                          <input
                            autoFocus={false}
                            autoComplete={"off"}
                            className="form-control"
                            type="text"
                            id="documentName"
                            name="input_Remark"
                            value={input_Remark}
                            onChange={this.onTextChange}
                          />
                          {!isStringEmpty(errorMessages.errorRemark) && (
                            <ErrorElementView
                              errorFor={
                                ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                                            <div className="col-12 col-sm-6  col-lg-8"> */}
                    <div className="choosefile-btn">
                      <label
                        htmlFor="myfile"
                        className={
                          "attach-btn attach-btn-responsive chs-btn " +
                          (!isStringEmpty(errorMessages.errorTFile)
                            ? "invalid-position-border"
                            : "")
                        }
                      >
                        <img
                          className="upload-img"
                          src="images/Icon awesome-upload.png"
                          width="18px"
                        />
                        &nbsp;Choose File
                      </label>
                      {!isStringEmpty(errorMessages.errorTFile) && (
                        <img
                          src="images\icon material-error.png"
                          alt="invalid"
                          className="invalid-position-img"
                        />
                      )}
                      <label className="required-field">&nbsp; (Please select a PDF or Excel file)</label>
                      {/*  &nbsp; (Please select a PDF or Excel file) */}
                      <input
                        type="file"
                        accept={validFileFormatsReadable}
                        id="myfile"
                        name="TFile"
                        onChange={this.onFileUpload}
                        key={fileInputKey || ""}
                        style={{ display: "none", visibility: "none" }}
                      />
                      <label className="label-breakword" style={{ overflowWrap: "anywhere", margin: "10px" }}>
                        {" "}
                        {fileName}
                      </label>
                    </div>
                  </div>
                </div>
                {!invalidFileFormat && validateError && <ErrorMessageBox />}
                {/* {invalidFileFormat && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please select a valid file.</p>
                  </div>
                )} */}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-background-color upload-btn"
                    onClick={this.onUploadButtonClick}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showTypeNameModal && (
            <div className="supplier_on_hold">
              <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
              <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Add Type</h4>
                      <button type="button" data-bs-dismiss="modal">
                        <img
                          src="images/modal-close.png"
                          alt="modal-close"
                          onClick={this.onCloseTypeClick}
                        />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row content">
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                          <div className={
                            !isStringEmpty(errorMessages.errorTypeName) || !isStringEmpty(errorMessages.DocTypeExist)
                              ? "invalid-position"
                              : ""
                          }>
                            <label htmlFor="todate">Type Name:</label>
                            <br />
                            <input
                              type="typeName"
                              className="form-control"
                              id="typeName"
                              name="typeName"
                              placeholder="Enter Type Name"
                              value={this.state.typeName}
                              onChange={this.ontypeNameChange}
                              maxLength={50}
                            />
                            {(!isStringEmpty(errorMessages.errorTypeName)) || (!isStringEmpty(errorMessages.DocTypeExist)) && (
                              <div className="misc-invalid-message-icon ">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.errorTypeName) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.DocTypeExist) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Document type already exists.</p>
                      </div>
                      <div className="admin-ship-settings-save-btn text-center">
                        <button
                          type="button"
                          className="btn btn-background-color save-btn"
                          onClick={this.ontypeNameSaveClicked}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
