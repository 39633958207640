import React, { Component } from 'react'
import Dropdown from '../../components/Dropdown';
import { UserRole } from '../../helpers/enums';
import { isStringEmpty } from '../../helpers/string-helper';
import { UncontrolledTooltip } from "reactstrap";
import { GetLocalStorageDataWithParse } from '../../helpers';

export default class ClientChangeRequestModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            moduleList: this.props.moduleList || [],
            isClientUser: false,
        };
    }

    componentDidMount() {
        var userData = GetLocalStorageDataWithParse("loggedInUser");

        const { moduleList } = this.state;
        if (userData != null) {
            let isClientUser = userData.typeId == UserRole.CLIENTUSER;
            this.setState(
                {
                    moduleList: isClientUser ? moduleList.filter(x => x.showClient == false) : moduleList.filter(x => x.showClient == true),
                    isClientUser: isClientUser
                });
        }
    }

    render() {
        const { onCloseClick, modalTitle, userEmailId, onChangeRequestClick, clientEmailId, description, onChange, errorMessages, showErrorMessage, dropdown_moduleName } = this.props;

        const { moduleList, isClientUser } = this.state;

        return (

            <div className="Training_Evalution client-modal-document" style={{ display: "block" }}>
                <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
                <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{modalTitle}</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: "all" }} onClick={onCloseClick} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ padding: '15px' }}>
                                    <div className="row">
                                        {modalTitle == "Change Request" || modalTitle == "Help & Support" ?
                                            <div>
                                                <div className="col-lg-4">
                                                    <label className="label-class label-mobile"><strong>{modalTitle} For:</strong></label>
                                                </div>
                                                <div className="col-lg-5 col-md-12 col-sm-12 data">
                                                    <div
                                                        className={
                                                            !isStringEmpty(errorMessages.errorModuleName)
                                                                ? "invalid-position"
                                                                : ""
                                                        }
                                                        id={"module"}
                                                    >
                                                        <Dropdown
                                                            dropdownLabel=""
                                                            name="moduleName"
                                                            onChange={onChange}
                                                            style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                                                            selected={dropdown_moduleName}
                                                        >
                                                            {moduleList == null
                                                                ? null
                                                                : moduleList.map((obj, key) => (
                                                                    <option key={key} value={obj.id}>
                                                                        {obj.moduleName}
                                                                    </option>
                                                                ))}
                                                            ;
                                                        </Dropdown>
                                                        {dropdown_moduleName > 0 ? (
                                                            <UncontrolledTooltip
                                                                autohide={false}
                                                                target={"module"}
                                                                aria-haspopup="true"
                                                                role="example"
                                                                effect="solid"
                                                                placement="right"
                                                                style={{ width: "350px" }}
                                                            >
                                                                {moduleList != null && moduleList.filter(x => x.id == dropdown_moduleName)[0]?.moduleName}
                                                            </UncontrolledTooltip>) : null}
                                                        {!isStringEmpty(errorMessages.errorModuleName)
                                                            && <div className="invalid-dropdown-icon">
                                                                <img
                                                                    src="images\icon material-error.png"
                                                                    alt="invalid"
                                                                ></img>
                                                            </div>}
                                                    </div>
                                                </div>
                                                {isClientUser && dropdown_moduleName == 9 &&
                                                    <>
                                                        <div className="col-lg-3 forgot-pass-label" style={{ paddingRight: 0 }}>
                                                            <label className="label-class label-mobile"><strong>Email Address:</strong></label>
                                                        </div>
                                                        <div className="col-12 data" style={{ padding: 0 }}>
                                                            <div
                                                                className={
                                                                    !isStringEmpty(errorMessages.errorUserEmailId)
                                                                        ? "invalid-position"
                                                                        : ""
                                                                }
                                                            >
                                                                <input
                                                                    autoFocus={false}
                                                                    autoComplete={"off"}
                                                                    className="form-control"
                                                                    type="text"
                                                                    id="userEmailId"
                                                                    name="userEmailId"
                                                                    value={userEmailId}
                                                                    onChange={onChange}
                                                                    maxLength={100}
                                                                />
                                                                {!isStringEmpty(errorMessages.errorUserEmailId) && (
                                                                    <div className="invalid-message-icon">
                                                                        <img
                                                                            src="images\icon material-error.png"
                                                                            alt="invalid"
                                                                        ></img>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>}

                                                <div className="col-lg-3 forgot-pass-label" style={{ paddingRight: 0 }}>
                                                    <label className="label-class label-mobile"><strong>Description:</strong></label>
                                                </div>
                                                <div className="col-12">
                                                    <div className={errorMessages.errordescription ? "invalid-position" : ""}>
                                                        <textarea
                                                            className="textarea-address"
                                                            rows={4}
                                                            name="description"
                                                            id="description"
                                                            value={description}
                                                            onChange={onChange}
                                                            style={{ width: '100%', borderRadius: '6px', padding: '5px' }}
                                                            placeholder=" Please provide details on what you are requesting to change.
                                                            For eg: Profile Details, other info."
                                                        ></textarea>
                                                        {errorMessages.errordescription
                                                            && <div className="invalid-message-icon">
                                                                <img
                                                                    src="images\icon material-error.png"
                                                                    alt="invalid"
                                                                ></img>
                                                            </div>}
                                                    </div>


                                                    <p><strong>Note:</strong> A change request will be sent to admin for approval.</p>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="col-lg-3" style={{ paddingRight: 0 }}>
                                                    <label className="label-class label-mobile">Email Address:</label>
                                                </div>
                                                <div className="col-lg-7 col-10" style={{ padding: 0 }}>
                                                    <input
                                                        autoFocus={false}
                                                        autoComplete={"off"}
                                                        className="form-control"
                                                        type="text"
                                                        id="clientEmailId"
                                                        name="clientEmailId"
                                                        value={clientEmailId}
                                                        disabled={true}
                                                        readOnly
                                                    />

                                                </div>
                                                <div className="col-lg-1 col-2" style={{ alignSelf: 'center' }}>
                                                    <img src="images/info.png" alt="info" className="info-modal" data-bs-toggle="tooltip" data-bs-placement="right" title="Email will be sent to Admin for changing password." />
                                                </div>
                                            </>}
                                    </div>
                                </div>
                                {showErrorMessage && (
                                    <div className="invalid-message-icon-box">
                                        <img
                                            src="images/icon material-error.png"
                                            alt="invalid-icon"
                                        />
                                        <p>Please fill in all the mandatory fields.</p>
                                    </div>
                                )}
                                <div className="text-center" style={{ padding: '20px' }}>
                                    <button type="button" className="btn btn-background-color upload-btn" onClick={onChangeRequestClick}>{modalTitle == "Change Request" || modalTitle == "Help & Support" ? "Send" : "Request New Password"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
