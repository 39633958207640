import React, { Component } from "react";
import Switch from "react-switch";
import Dropdown from "../../components/Dropdown";
import { UncontrolledTooltip } from "reactstrap";

export default class RolesPermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessPermissions: [],
      userId: this.props.userId,
      dropdown_inheritedUserLevelId:
        this.props.userRolesPermissions == null
          ? 0
          : this.props.userRolesPermissions.inheritedLevelId,
      isDataEditable: this.props.isDataEditable == undefined ? true : this.props.isDataEditable,
    };
  }

  componentDidMount() {
    this.setState({
      accessPermissions: this.props.userRolesPermissions,
    });
  }

  onDropdownSelect = (e) => {
    var { name, value } = e.target;

    const defaultArray = {
      addClientAccess: null,
      clientRole: null,
      clientRoleId: null,
      createdBy: 1,
      createdOn: null,
      customClientRolesPermissions: [],
      defaultClientRolePermissionId: 0,
      documentsAccess: null,
      editedBy: 1,
      editedOn: null,
      reportsAccess: null,
      shipParticularsAccess: null,
      statisticsAccess: null,
      trackingToolAccess: null,
      trainingAccess: null,
    };

    this.setState({
      [name]: value,
      validateError: false
    },
      () => {
        var permissions = value == "0"
          ? defaultArray
          : this.props.defaultRolesPermissions.filter(
            (t) => t.userLevelId == this.state[name]
          )[0];
        this.setState({
          accessPermissions: permissions == undefined ? [] : permissions,
        });
      }
    );
  };

  onSwitchToggle = (checked, e, id) => {
    var accessPermissionsUpdate = this.state.accessPermissions;

    var switchId = id.split("_")[1];
    var switchOperation = id.split("_")[0];
    accessPermissionsUpdate[switchId] =
      checked && switchOperation == "read"
        ? 1
        : checked && switchOperation == "readWrite"
          ? 2
          : checked && switchOperation == "deny"
            ? 3
            : 0;
    this.setState({
      accessPermissions: accessPermissionsUpdate,
    });
    this.isValid();
  };

  isValid = () => {
    var accessPermissions = this.state.accessPermissions;
    var hasErrors = false;
    if (
      accessPermissions == null ||
      accessPermissions["clientAndShipAccess"] == 0 ||
      accessPermissions["trackingToolAccess"] == 0 ||
      accessPermissions["communicationAccess"] == 0 ||
      accessPermissions["reportAccess"] == 0 ||
      accessPermissions["statisticAccess"] == 0 ||
      accessPermissions["supplierAccess"] == 0 ||
      accessPermissions["trainingAccess"] == 0 ||
      accessPermissions["manageUserAccess"] == 0 ||
      accessPermissions["ihmdatabaseAccess"] == 0 ||
      accessPermissions["financeAndAccountAccess"] == 0 ||
      accessPermissions["clientAndShipAccess"] == null ||
      accessPermissions["trackingToolAccess"] == null ||
      accessPermissions["communicationAccess"] == null ||
      accessPermissions["reportAccess"] == null ||
      accessPermissions["statisticAccess"] == null ||
      accessPermissions["supplierAccess"] == null ||
      accessPermissions["trainingAccess"] == null ||
      accessPermissions["manageUserAccess"] == null ||
      accessPermissions["ihmdatabaseAccess"] == null ||
      accessPermissions["financeAndAccountAccess"] == null
    ) {
      hasErrors = true;
    } else hasErrors = false;
    this.setState({ validateError: hasErrors });
    return hasErrors;
  };

  onSave = async () => {
    var hasErrors = this.isValid();

    if (!hasErrors) {
      const { accessPermissions, dropdown_inheritedUserLevelId } = this.state;
      this.props.ApplyCustomPermissions(
        accessPermissions,
        dropdown_inheritedUserLevelId
      );
    }
  };

  render() {
    const { showModal, userLevelList, closeModal } = this.props;
    const { accessPermissions, validateError, dropdown_inheritedUserLevelId, isDataEditable } =
      this.state;
    const SwitchControl = (props) => {
      return (
        <td className="center">
          <Switch
            id={props.id}
            className="switch"
            height={20}
            width={40}
            checked={props.IsChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={"#C90000"}
            onChange={(checked, e, id) => this.onSwitchToggle(checked, e, id)}
            disabled={!isDataEditable}
          />
        </td>
      );
    };
    const SectionRow = (props) => {
      return (
        <tr
          style={{
            backgroundColor: props.permissionAccess == 0 || props.permissionAccess == null ? "#ff3b3b" : "",
          }}
        >
          <td
            className="roles-table-heading left"
            style={{ backgroundColor: "#fff" }}
          >
            {props.label}
          </td>
          <SwitchControl
            id={`read_${props.id}`}
            IsChecked={props.permissionAccess == 1}
          />{" "}
          {/*Read */}
          <SwitchControl
            id={`readWrite_${props.id}`}
            IsChecked={props.permissionAccess == 2}
          />
          {/*Read and Write */}
          <SwitchControl
            id={`deny_${props.id}`}
            IsChecked={props.permissionAccess == 3}
          />
          {/*Deny */}
        </tr>
      );
    };

    return (
      <>
        <div
          className="modal-backdrop fade show"
          style={{ display: showModal ? "block" : "none" }}
        ></div>
        <div
          className="modal"
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog custom-modal-dialog-width modal-dialog-centered modal-custom-css">
            <div className="modal-content custom-modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">
                  {(!isDataEditable ? "View" : "Set") + " Roles & Permissions"}
                </h5>
                <img
                  className="close-img-icon"
                  src="images/modal-close.png"
                  width="25px"
                  style={{ pointerEvents: "all" }}
                  onClick={closeModal}
                />
              </div>
              <div className="modal-body">
                <div className="row modal-inherit-left" style={{ display: isDataEditable ? '' : 'none' }}>
                  <div className="col-12 col-md-12 col-lg-3">
                    <label htmlFor="test" className="inherit-lbl-class">
                      Inherit from:
                    </label>
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="form-group" id={"userLevel"}>
                      {/* <select className="form-select ship-name-dropdown inherit-frm-dropdown" aria-label="Default select example">
                           
                        </select> */}
                      <Dropdown
                        dropdownLabel={""}
                        name={"inheritedUserLevelId"}
                        selected={dropdown_inheritedUserLevelId}
                        onChange={this.onDropdownSelect}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {userLevelList == null
                          ? null
                          : userLevelList
                            .filter((t) => t.value != 4)
                            .map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                        ;
                      </Dropdown>
                      {dropdown_inheritedUserLevelId != "" ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"userLevel"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {userLevelList != null && userLevelList.filter(x => x.value == dropdown_inheritedUserLevelId)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="table-responsive" style={{padding:"10px"}}>
                      <table className="table manage-users-table roles-permissions-modal-table">
                        <thead className="level-table-header-color">
                          <tr>
                            <th className="table-header-modal-text left">
                              Roles
                            </th>
                            <th className="table-header-modal-text">Read</th>
                            <th className="table-header-text client-roles-header-text">
                              <div className="tooltip p-0">
                                R & W
                                <span className="tooltiptext">
                                  Read & Write
                                </span>
                              </div>
                            </th>
                            <th className="table-header-modal-text">Deny</th>
                          </tr>
                        </thead>
                        <tbody className="table-one-body roles-permissions-tbl-body">
                          <SectionRow
                            label={"Client and Ship Particulars"}
                            id="clientAndShipAccess"
                            permissionAccess={
                              accessPermissions.clientAndShipAccess
                            }
                          />
                          <SectionRow
                            label={"Tracking Tool"}
                            id="trackingToolAccess"
                            permissionAccess={
                              accessPermissions.trackingToolAccess
                            }
                          />
                          <SectionRow
                            label={"Communication"}
                            id="communicationAccess"
                            permissionAccess={
                              accessPermissions.communicationAccess
                            }
                          />
                          <SectionRow
                            label={"Reports"}
                            id="reportAccess"
                            permissionAccess={accessPermissions.reportAccess}
                          />
                          <SectionRow
                            label={"Statistics"}
                            id="statisticAccess"
                            permissionAccess={accessPermissions.statisticAccess}
                          />
                          <SectionRow
                            label={"Supplier"}
                            id="supplierAccess"
                            permissionAccess={accessPermissions.supplierAccess}
                          />
                          <SectionRow
                            label={"Training"}
                            id="trainingAccess"
                            permissionAccess={accessPermissions.trainingAccess}
                          />
                          <SectionRow
                            label={"Manage User"}
                            id="manageUserAccess"
                            permissionAccess={
                              accessPermissions.manageUserAccess
                            }
                          />
                          <SectionRow
                            label={"IHM Database & Registry"}
                            id="ihmdatabaseAccess"
                            permissionAccess={
                              accessPermissions.ihmdatabaseAccess
                            }
                          />
                          <SectionRow
                            label={"Miscellaneous Operation"}
                            id="financeAndAccountAccess"
                            permissionAccess={
                              accessPermissions.financeAndAccountAccess
                            }
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    {validateError && (
                      <div className="invalid-message-icon-box">
                        <img
                          src="images/icon material-error.png"
                          alt="invalid-icon"
                        />
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                    )}
                    <div className="admin-ship-settings-save-btn text-center" style={{ display: !isDataEditable ? 'none' : '' }}>
                      <button
                        type="button"
                        className="btn btn-background-color btn-save"
                        onClick={this.onSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
