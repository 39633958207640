import React, { Component } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Switch from "react-switch";
import { UserService } from "../../services/user.service";
import { changePageTitle, helperText, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse } from "../../helpers";
import Toast from "../../components/Toast";
import { withRouter } from "../../helpers";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import { Link, useNavigate } from 'react-router-dom';

const defaultArray = {
  clientAndShipAccess: 0,
  trackingToolAccess: 0,
  communicationAccess: 0,
  reportAccess: 0,
  statisticAccess: 0,
  supplierAccess: 0,
  trainingAccess: 0,
  manageUserAccess: 0,
  ihmdatabaseAccess: 0,
  financeAndAccountAccess: 0,
};

class ManageRolesAndPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastVisible: false,
      accessPermissionsLevel1: [],
      accessPermissionsLevel2: [],
      accessPermissionsLevel3: [],
      loggedInUserID: 0,
      toastResponseType: "",
      isInternalUser: false,
      validateError: false,
    };
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.ROLESANDPERMISSION);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          isLoading: true,
          loggedInUserID: userData.id,
          isInternalUser: userData.typeId == UserRole.INTERNALUSER,
        },
        () => {
          this.getDefaultAccessPermissions();
        }
      );
    }
  }

  getDefaultAccessPermissions = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await UserService.GetDefaultPermissions();
    if (response != null && response != undefined && response.status == 200) {
      if (response.data.length > 0) {
        this.setState({
          accessPermissionsLevel1: response.data[0],
          accessPermissionsLevel2: response.data[1],
          accessPermissionsLevel3: response.data[2],
          isLoading: false,
        });
      } else {
        this.setState({
          accessPermissionsLevel1: { ...defaultArray },
          accessPermissionsLevel2: { ...defaultArray },
          accessPermissionsLevel3: { ...defaultArray },
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  onSwitchToggle = (checked, e, id) => {
    var switchOperation = id.split("_")[0];
    var switchId = id.split("_")[1];
    var levelId = id.split("_")[2];

    var {
      accessPermissionsLevel1,
      accessPermissionsLevel2,
      accessPermissionsLevel3,
    } = this.state;
    switchId = switchId.replace(levelId, "");
    if (levelId.includes("Level1")) {
      accessPermissionsLevel1[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsLevel1,
      });
    } else if (levelId.includes("Level2")) {
      accessPermissionsLevel2[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsLevel2,
      });
    } else if (levelId.includes("Level3")) {
      accessPermissionsLevel3[switchId] =
        checked && switchOperation == "read"
          ? 1
          : checked && switchOperation == "readWrite"
            ? 2
            : checked && switchOperation == "deny"
              ? 3
              : 0;
      this.setState({
        accessPermissionsLevel3,
      });
    }
    this.isValidate(2);
  };

  isValidate = (param) => {
    var {
      accessPermissionsLevel1,
      accessPermissionsLevel2,
      accessPermissionsLevel3,
      userId,
      loggedInUserID,
    } = this.state;
    var hasErrors = false;
    if (
      accessPermissionsLevel1["clientAndShipAccess"] == 0 || accessPermissionsLevel1["clientAndShipAccess"] == null ||
      accessPermissionsLevel1["trackingToolAccess"] == 0 || accessPermissionsLevel1["trackingToolAccess"] == null ||
      accessPermissionsLevel1["communicationAccess"] == 0 || accessPermissionsLevel1["communicationAccess"] == null ||
      accessPermissionsLevel1["reportAccess"] == 0 || accessPermissionsLevel1["reportAccess"] == null ||
      accessPermissionsLevel1["statisticAccess"] == 0 || accessPermissionsLevel1["statisticAccess"] == null ||
      accessPermissionsLevel1["supplierAccess"] == 0 || accessPermissionsLevel1["supplierAccess"] == null ||
      accessPermissionsLevel1["trainingAccess"] == 0 || accessPermissionsLevel1["trainingAccess"] == null ||
      accessPermissionsLevel1["manageUserAccess"] == 0 || accessPermissionsLevel1["manageUserAccess"] == null ||
      accessPermissionsLevel1["ihmdatabaseAccess"] == 0 || accessPermissionsLevel1["ihmdatabaseAccess"] == null ||
      accessPermissionsLevel1["financeAndAccountAccess"] == 0 || accessPermissionsLevel1["financeAndAccountAccess"] == null ||
      accessPermissionsLevel2["clientAndShipAccess"] == 0 || accessPermissionsLevel2["clientAndShipAccess"] == null ||
      accessPermissionsLevel2["trackingToolAccess"] == 0 || accessPermissionsLevel2["trackingToolAccess"] == null ||
      accessPermissionsLevel2["communicationAccess"] == 0 || accessPermissionsLevel2["communicationAccess"] == null ||
      accessPermissionsLevel2["reportAccess"] == 0 || accessPermissionsLevel2["reportAccess"] == null ||
      accessPermissionsLevel2["statisticAccess"] == 0 || accessPermissionsLevel2["statisticAccess"] == null ||
      accessPermissionsLevel2["supplierAccess"] == 0 || accessPermissionsLevel2["supplierAccess"] == null ||
      accessPermissionsLevel2["trainingAccess"] == 0 || accessPermissionsLevel2["trainingAccess"] == null ||
      accessPermissionsLevel2["manageUserAccess"] == 0 || accessPermissionsLevel2["manageUserAccess"] == null ||
      accessPermissionsLevel2["ihmdatabaseAccess"] == 0 || accessPermissionsLevel2["ihmdatabaseAccess"] == null ||
      accessPermissionsLevel2["financeAndAccountAccess"] == 0 || accessPermissionsLevel2["financeAndAccountAccess"] == null ||
      accessPermissionsLevel3["clientAndShipAccess"] == 0 || accessPermissionsLevel3["clientAndShipAccess"] == null ||
      accessPermissionsLevel3["trackingToolAccess"] == 0 || accessPermissionsLevel3["trackingToolAccess"] == null ||
      accessPermissionsLevel3["communicationAccess"] == 0 || accessPermissionsLevel3["communicationAccess"] == null ||
      accessPermissionsLevel3["reportAccess"] == 0 || accessPermissionsLevel3["reportAccess"] == null ||
      accessPermissionsLevel3["statisticAccess"] == 0 || accessPermissionsLevel3["statisticAccess"] == null ||
      accessPermissionsLevel3["supplierAccess"] == 0 || accessPermissionsLevel3["supplierAccess"] == null ||
      accessPermissionsLevel3["trainingAccess"] == 0 || accessPermissionsLevel3["trainingAccess"] == null ||
      accessPermissionsLevel3["manageUserAccess"] == 0 || accessPermissionsLevel3["manageUserAccess"] == null ||
      accessPermissionsLevel3["ihmdatabaseAccess"] == 0 || accessPermissionsLevel3["ihmdatabaseAccess"] == null ||
      accessPermissionsLevel3["financeAndAccountAccess"] == 0 || accessPermissionsLevel3["financeAndAccountAccess"] == null
    ) {
      hasErrors = true;
    } else hasErrors = false;

    if (param == 1 || !hasErrors)
      this.setState({ validateError: hasErrors });

    return hasErrors;
  }

  onSave = async () => {
    var {
      accessPermissionsLevel1,
      accessPermissionsLevel2,
      accessPermissionsLevel3,
      userId,
      loggedInUserID,
    } = this.state;

    var hasErrors = this.isValidate(1);
    if (!hasErrors) {
      this.setState({ isLoading: true });
      var accessPermissions = [];
      accessPermissionsLevel1.userLevelId = 1;
      accessPermissionsLevel2.userLevelId = 2;
      accessPermissionsLevel3.userLevelId = 3;

      accessPermissions.push(accessPermissionsLevel1);
      accessPermissions.push(accessPermissionsLevel2);
      accessPermissions.push(accessPermissionsLevel3);

      var data = {
        accessPermissions: accessPermissions,
        loggedInUserId: loggedInUserID,
      };

      var response = await UserService.SaveDefaultPermissions(data);
      if (response != null && response != undefined && response.status == 200) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.saveSuccess,
          toastResponseType: "success",
          isLoading: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: "fail",
          isLoading: false,
        });
      }
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    }
    else if (loc == "/AddUser") {
      this.props.navigate("/AddUser");
    }
    else {
      this.props.navigate("/ManageUsers");
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastResponseType,
      isInternalUser,
      toastMessage,
      accessPermissionsLevel1,
      accessPermissionsLevel2,
      accessPermissionsLevel3,
      validateError,
    } = this.state;

    const SwitchControl = (props) => {
      return (
        <td className="center">
          <Switch
            id={props.id}
            className="switch"
            height={20}
            width={40}
            disabled={isInternalUser}
            checked={props.IsChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={"#C90000"}
            onChange={(checked, e, id) => this.onSwitchToggle(checked, e, id)}
          />
        </td>
      );
    };
    const SectionRow = (props) => {
      return (
        <tr
          style={{
            backgroundColor: props.permissionAccess == 0 || props.permissionAccess == null ? "#ff3b3b" : "",
          }}
        >
          <SwitchControl
            id={`read_${props.id}`}
            IsChecked={props.permissionAccess == 1}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />{" "}
          {/*Read */}
          <SwitchControl
            id={`readWrite_${props.id}`}
            IsChecked={props.permissionAccess == 2}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Read and Write */}
          <SwitchControl
            id={`deny_${props.id}`}
            IsChecked={props.permissionAccess == 3}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Deny */}
        </tr>
      );
    };

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="communications role-permissions-class">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 heading-bottom-space">
                  <img
                    className="back-btn-responsive"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all" }}
                    onClick={this.goback}
                  />
                  <span className="title-font">Manage Users Roles & Permissions</span>
                </div>
              </div>
              <div className="row  levels-table">
                <div className="col-12 col-md-12 col-lg-12 table-left-padding table-pl-responsive">
                  <div className="level-boxes level-boxes-responsive">
                    <div className="level-boxes-spacing">
                      <h5 className="level-heading"> Levels: </h5>
                      <table className="table table-bordered manage-users-table roles-permissions-tbl">
                        <thead className="level-table-header-color">
                          <tr>
                            <th className="table-one-text">Roles</th>
                          </tr>
                        </thead>
                        <tbody className="table-one-body">
                          <tr>
                            <td className="roles-table-heading">
                              Client and Ship Particulars
                            </td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">
                              Tracking Tool
                            </td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">
                              Communication
                            </td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Reports</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Statistics</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Supplier</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Training</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">Manage User</td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">
                              IHM Database & Registry
                            </td>
                          </tr>
                          <tr>
                            <td className="roles-table-heading">
                              Miscellaneous Operation
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="level-boxes-spacing">
                      <h5 className="level-heading"> Level 1: </h5>
                      <table className="table manage-users-table roles-permissions-table">
                        <thead className="level-table-header-color">
                          <tr>
                            <th className="table-header-text">Read</th>
                            <th className="table-header-text client-roles-header-text">
                              <div className="tooltip p-0">
                                R & W
                                <span className="tooltiptext">
                                  Read & Write
                                </span>
                              </div>
                            </th>
                            <th className="table-header-text">Deny</th>
                          </tr>
                        </thead>
                        <tbody className="table-one-body" disabled={true}>
                          <SectionRow
                            id="clientAndShipAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.clientAndShipAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="trackingToolAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.trackingToolAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="communicationAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.communicationAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="reportAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.reportAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="statisticAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.statisticAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="supplierAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.supplierAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="trainingAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.trainingAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="manageUserAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.manageUserAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="ihmdatabaseAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.ihmdatabaseAccess
                            }
                            precedingPermission={""}
                          />
                          <SectionRow
                            id="financeAndAccountAccess_Level1"
                            permissionAccess={
                              accessPermissionsLevel1.financeAndAccountAccess
                            }
                            precedingPermission={""}
                          />
                        </tbody>
                      </table>
                    </div>
                    <div className="level-boxes-spacing">
                      <h5 className="level-heading"> Level 2: </h5>
                      <table className="table manage-users-table roles-permissions-table">
                        <thead className="level-table-header-color">
                          <tr>
                            <th className="table-header-text">Read</th>
                            <th className="table-header-text client-roles-header-text">
                              <div className="tooltip p-0">
                                R & W
                                <span className="tooltiptext">
                                  Read & Write
                                </span>
                              </div>
                            </th>
                            <th className="table-header-text">Deny</th>
                          </tr>
                        </thead>
                        <tbody className="table-one-body">
                          <SectionRow
                            id="clientAndShipAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.clientAndShipAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.clientAndShipAccess
                            }
                          />
                          <SectionRow
                            id="trackingToolAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.trackingToolAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.trackingToolAccess
                            }
                          />
                          <SectionRow
                            id="communicationAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.communicationAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.communicationAccess
                            }
                          />
                          <SectionRow
                            id="reportAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.reportAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.reportAccess
                            }
                          />
                          <SectionRow
                            id="statisticAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.statisticAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.statisticAccess
                            }
                          />
                          <SectionRow
                            id="supplierAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.supplierAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.supplierAccess
                            }
                          />
                          <SectionRow
                            id="trainingAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.trainingAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.trainingAccess
                            }
                          />
                          <SectionRow
                            id="manageUserAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.manageUserAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.manageUserAccess
                            }
                          />
                          <SectionRow
                            id="ihmdatabaseAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.ihmdatabaseAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.ihmdatabaseAccess
                            }
                          />
                          <SectionRow
                            id="financeAndAccountAccess_Level2"
                            permissionAccess={
                              accessPermissionsLevel2.financeAndAccountAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel1.financeAndAccountAccess
                            }
                          />
                        </tbody>
                      </table>
                    </div>
                    <div className="level-boxes-spacing">
                      <h5 className="level-heading"> Level 3: </h5>
                      <table className="table manage-users-table roles-permissions-table">
                        <thead className="level-table-header-color">
                          <tr>
                            <th className="table-header-text">Read</th>
                            <th className="table-header-text client-roles-header-text">
                              <div className="tooltip p-0">
                                R & W
                                <span className="tooltiptext">
                                  Read & Write
                                </span>
                              </div>
                            </th>
                            <th className="table-header-text">Deny</th>
                          </tr>
                        </thead>
                        <tbody className="table-one-body">
                          <SectionRow
                            id="clientAndShipAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.clientAndShipAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.clientAndShipAccess
                            }
                          />
                          <SectionRow
                            id="trackingToolAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.trackingToolAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.trackingToolAccess
                            }
                          />
                          <SectionRow
                            id="communicationAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.communicationAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.communicationAccess
                            }
                          />
                          <SectionRow
                            id="reportAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.reportAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.reportAccess
                            }
                          />
                          <SectionRow
                            id="statisticAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.statisticAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.statisticAccess
                            }
                          />
                          <SectionRow
                            id="supplierAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.supplierAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.supplierAccess
                            }
                          />
                          <SectionRow
                            id="trainingAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.trainingAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.trainingAccess
                            }
                          />
                          <SectionRow
                            id="manageUserAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.manageUserAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.manageUserAccess
                            }
                          />
                          <SectionRow
                            id="ihmdatabaseAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.ihmdatabaseAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.ihmdatabaseAccess
                            }
                          />
                          <SectionRow
                            id="financeAndAccountAccess_Level3"
                            permissionAccess={
                              accessPermissionsLevel3.financeAndAccountAccess
                            }
                            precedingPermission={
                              accessPermissionsLevel2.financeAndAccountAccess
                            }
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {validateError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                <div className="admin-ship-settings-save-btn text-center">
                  {isInternalUser ? null : (
                    <button
                      type="button"
                      className="btn btn-background-color save-btn"
                      onClick={this.onSave}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
export default withRouter(ManageRolesAndPermission);
