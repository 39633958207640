import React from 'react';
import { useState, useEffect, useRef } from "react";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';

import { convertDate, convertTime } from '../../helpers/string-helper';
import ViewAttachmentModal from "./ViewAttachmentModal";
import { CommunicationService } from "../../services/communication.service";
import { UserService } from "../../services/user.service";
import Toast from "../../components/Toast";
import DatePicker from "../../components/DatePickerComponent";
import { helperText } from "../../helpers/helperText";
import { config } from "../../helpers/config";
import Logout from "../../helpers/Logout";
import DOMPurify from "dompurify";


import { TestService } from '../../services/test.service';
import Loader from '../../components/Loader';
import LinkDocumentModal from './LinkDocumentModal';
import { SupplierService } from '../../services/supplier.service';

var currentDate = new Date();

// files which we need to load using google viewer instead of direct url.
const googleViewerExt = [".docx", ".xlsx"];

const msalConfig = {
    auth: config.auth,
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const loginRequest = {
    scopes: ["User.Read", "Mail.ReadBasic", "Mail.Read", "Mail.ReadWrite"]
};

const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me/messages?$expand=attachments&$top=10&$search="Subject:IHMM"' //e.g. https://graph.microsoft.com/v1.0/me
};

/**
 * SP 1st Feb 2023: functional component with Ms graph api hooks
 * @param {*} props 
 * @returns 
 */
function RecievedSection(props) {
    // const msalInstance = new PublicClientApplication(msalConfig);

    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");


    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastVisible(true);
        setToastType("fail");
    }

    const closeErrorToast = () => {
        setToastMessage("");
        setToastVisible(false);
        setToastType("");
    }

    return (
        <div>
            {toastVisible && (
                <Toast
                    toastVisible={toastVisible}
                    type={toastType}
                    message={toastMessage}
                    closeToast={closeErrorToast}
                />
            )}
            <ProfileContent userID={props.userID} emailTypeDataList={props.emailTypeDataList} setIsLoading={props.setIsLoading} showErrorToast={showErrorToast} />
        </div>
    );
}

export default RecievedSection;



const ProfileContent = (props) => {
    const { instance, accounts } = useMsal();
    const [emailData, setEmailData] = useState(null);
    const isAuthenticated = useIsAuthenticated();
    const [startIndex, setStartIndex] = useState(0);
    const [oldEmailCount, setOldEmailCount] = useState(0);
    const [visibleLoadMore, setVisibleLoadMore] = useState(false);

    useEffect(() => {
        handleFetchEmails(startIndex);
        return () => { };
    }, []);

    // child
    const isFirstRender = useRef(true); // used in useEffect to avoid calling onFilterChange on first render.

    const [emailListFiltered, setEmailListFiltered] = useState([]);
    const [showViewModal, setShowViewModal] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [fileName, setFileName] = useState("");
    const [useGoolgeViewer, setUseGoolgeViewer] = useState(false);
    const [toastAttachmentVisible, setAttachmentToastVisible] = useState(false);

    const [server, setServer] = useState('');
    const [port, setPort] = useState(993);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [visibleEmails, setVisibleEmails] = useState(10);
    const [emailLists, setemailList] = useState([]);

    const emailList = Array.isArray(emails) ? emails : [];

    // dropdown
    const [emailSenderlList, setEmailSenderList] = useState([]);


    const [errorMsgEmailList, setEmailErrorMsg] = useState("");


    const [loggedUserID, setLoggedID] = useState(props.userID);
    const [loggedUserName, setLoggedUsername] = useState("");
    const [loggedUserPassword, setLoggedUserPassword] = useState("");

    const [toastVisible, setToastVisible] = useState(false);
    const [toastType, setToastType] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    // filter
    const [searchText, setSearchText] = useState("");
    const [filterFromDate, setFilterFromDate] = useState("");
    const [filterToDate, setFilterToDate] = useState("");
    const [filterSender, setFilterSender] = useState("");
    const [filterEmailType, setFilterEmailType] = useState("");
    const [nextFromDate, setNextFromDate] = useState("");
    const [previousToDate, setPreviousToDate] = useState(currentDate);
    const [showLinkdocumentModal, setShowLinkdocumentModal] = useState(false);
    const [documentTypeId, setDocumentTypeId] = useState(0);
    const [linkAttachment, setLinkAttachment] = useState(null);

    // pagination
    const [nextLink, setNextLink] = useState("");


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false // toggle flag after first render/mounting
            return;
        }

        onFilterChange();

    }, [searchText, filterEmailType, filterSender, filterFromDate, filterToDate, loggedUserID, loggedUserName, loggedUserPassword]);

    const onViewFileClicked = async (att, id) => {
        try {
            props.setIsLoading(true);
            const dataobject = `data:${att.contentType};base64,${att.contentBytes}`
            const pdfstr = await fetch(dataobject);
            const blobFromFetch = await pdfstr.blob();
            const file = new File([blobFromFetch], att.fileName, { type: att.contentType });

            let payload = new FormData();
            payload.append("ID", id);
            payload.append("EmailAttachment", file);

            const response = await CommunicationService.UploadEmailAttachmentForViewing(payload);
            if (response.status == 200 && response.data) {

                setFilePath(response.data.path);
                setFileName(att.fileName);

                //if (googleViewerExt.includes(response.data.extension)) {
                setUseGoolgeViewer(true);
                //}
                // else {
                //     setUseGoolgeViewer(false);
                // }

                setShowViewModal(true);
            }
            else {
                props.showErrorToast(helperText.global.somethingWentWrong);
            }
        }
        catch (e) {
            props.showErrorToast(e);
        }
        props.setIsLoading(false);
    }

    const onFilterChange = () => {
        var filterList = emails;

        if (searchText == "" && filterEmailType == "" && filterSender == "" && filterFromDate == "" && filterToDate == "") {
            filterList = emailLists;
        }
        else {
            if (filterEmailType != "") {
                filterList = emailLists;
                filterList = filterList.filter((email) => email.subject.toString().toLowerCase().includes(filterEmailType?.toString().toLowerCase()));
            }

            if (filterSender != "") {
                filterList = emailLists;
                filterList = filterList.filter((email) => email.from == filterSender);
            }

            if (searchText != "") {
                filterList = emailLists;
                const value = searchText.toString().toLowerCase();
                filterList = filterList.filter(
                    (email) =>
                        email.from.toString().toLowerCase().includes(value) ||
                        email.subject?.toString().toLowerCase().includes(value) ||
                        email.body.toString().toLowerCase().includes(value)
                );
            }


            if (filterFromDate != "" && filterToDate != "") {
                filterList = emailLists;
                filterList = filterList.filter((e) => {
                    var date = new Date(e.emailDate);
                    date.setHours(0, 0, 0, 0);
                    return date >= filterFromDate && date <= filterToDate;
                })
            }

            if (filterEmailType != "" && filterSender != "") {
                filterList = emailLists;
                filterList = filterList.filter((email) => email.subject.toString().toLowerCase().includes(filterEmailType?.toString().toLowerCase()) && email.from == filterSender);
            }

            if (filterEmailType != "" && filterSender != "" && searchText != "") {
                filterList = emailLists;
                const value = searchText.toString().toLowerCase();
                filterList = filterList.filter((email) => email.subject.toString().toLowerCase().includes(filterEmailType?.toString().toLowerCase()) && email.from == filterSender && (email.from.toString().toLowerCase().includes(value) ||
                    email.subject?.toString().toLowerCase().includes(value) || email.body.toString().toLowerCase().includes(value)));
            }

            if (filterEmailType != "" && filterSender != "" && filterFromDate != "" && filterToDate) {
                filterList = emailLists;
                filterList = filterList.filter((email) => {
                    var date = new Date(email.emailDate);
                    date.setHours(0, 0, 0, 0);
                    return email.subject.toString().toLowerCase().includes(filterEmailType?.toString().toLowerCase()) && email.from == filterSender && date >= filterFromDate && date <= filterToDate
                });
            }

            if (filterSender != "" && filterFromDate != "" && filterToDate) {
                filterList = emailLists;
                filterList = filterList.filter((email) => {
                    var date = new Date(email.emailDate);
                    date.setHours(0, 0, 0, 0);
                    return email.from == filterSender && date >= filterFromDate && date <= filterToDate
                });
            }
        }

        setEmails(filterList);
    }

    const clearAllFilter = () => {
        setSearchText("");
        setFilterFromDate("");
        setFilterToDate("");
        setFilterSender("");
        setFilterEmailType("");
        setNextFromDate("");
        setPreviousToDate(currentDate)
    }

    const onLoadMoreEmailsClick = () => {
        setStartIndex(prevIndex => prevIndex + 5);
        handleFetchEmails(startIndex + 5);
    }

    const onLoadNewEmailsClick = () => {
        setStartIndex(0);
        handleFetchEmails(0);
    }

    const onSearchTextChange = (e) => {
        var { name, value } = e.target;
        setSearchText(value);
    }

    const onDropdownChange = (e) => {
        var { name, value } = e.target;

        if (name == "FilterEmailType") {
            setFilterEmailType(value);
        }
        else if (name == "FilterSender") {
            setFilterSender(value);
        }
    }

    const onDateChanged = (id, e) => {
        if (id == "filterFromDate") {
            var nextFromDate = new Date(e);
            nextFromDate.setDate(e.getDate());
            nextFromDate.toLocaleDateString();

            setFilterFromDate(e);
            setNextFromDate(nextFromDate);
        }
        else if (id == "filterToDate") {
            var previousToDate = new Date(e);
            previousToDate.setDate(e.getDate());
            previousToDate.toLocaleDateString();

            setFilterToDate(e);
            setPreviousToDate(previousToDate);
        }
    }

    const handleLoadMore = () => {
        setVisibleEmails(prevVisibleEmails => prevVisibleEmails + 10);
    };

    const handleFetchEmails = async (newStartIndex) => {
        props.setIsLoading(true);
        let userData = {
            userId: props.userID,
            startIndex: newStartIndex,
            previousEmailCount: oldEmailCount
        }
        if (newStartIndex == 0) {
            setEmails([]);
            setemailList([]);
            setEmailSenderList([]);
        }
        try {
            const response = await TestService.FetchEmails(userData);

            if (response.data.length > 0) {
                setVisibleLoadMore(true);
                //Fill the email count only once
                if (oldEmailCount == 0)
                    setOldEmailCount(response.data[0].previousEmailCount);

                // Merge new emails with existing ones
                setEmails((prevEmails) => [...prevEmails, ...response.data]);
                setemailList((prevEmails) => [...prevEmails, ...response.data]);

                let rawEmailList = response.data;
                let senderList = [...emailSenderlList];

                for (let message of rawEmailList) {
                    if (!senderList.includes(message.from)) {
                        senderList.push(message.from);
                        setLoggedUsername(message.loggedInUserEmail);
                    }
                }
                setEmailSenderList(senderList);
            } else {
                setVisibleLoadMore(false);
                setEmailErrorMsg("No more emails to load.");
                props.showErrorToast(helperText.global.clientEmailFetchError);
            }
        } catch (error) {
            setVisibleLoadMore(true);
            setEmailErrorMsg("Failed to fetch emails.");
            props.showErrorToast(helperText.global.clientEmailFetchError);
        } finally {
            props.setIsLoading(false);
        }
    };


    const onViewFileDownload = (filePath) => {
        try {
            var lastItem = filePath.split("/").pop();
            var fname = lastItem;
            const link = document.createElement("a");
            link.href = filePath;
            link.setAttribute("download", filePath);
            document.body.appendChild(link);
            link.download = fname;
            link.click();
            setAttachmentToastVisible(true);
            setToastType(helperText.global.toastSuccess);
            setToastMessage(helperText.global.downloadSuccess);
        } catch (e) {
        }
    };

    const closeToast = () => {
        try {
            setToastMessage("");
            setAttachmentToastVisible(false);
            setToastVisible(false);
            setToastType("");
        } catch (e) {
        }
    };

    const onSaveMDSDOCDeclarationData = async (mdsdocData) => {
        props.setIsLoading(true);
        setShowLinkdocumentModal(false)

        mdsdocData.append("fromPage", "Communication");

        var responseSaved = await SupplierService.SaveUploadMDSDocData(mdsdocData);
        if (
            responseSaved != null &&
            responseSaved != undefined &&
            responseSaved.status == 200
        ) {
            props.setIsLoading(false);
            setToastType(helperText.global.toastSuccess);
            setToastMessage(helperText.TrackingTool.documentLinked);
            setToastVisible(true)

        } else if (responseSaved.status == 401) {
            Logout(this.props.navigate);
        } else {
            props.setIsLoading(false);
            setToastType(helperText.global.toastError);
            setToastMessage(helperText.global.somethingWentWrong);
            setToastVisible(true);
        }
    };

    const onModalCloseClick = () => {
        setShowLinkdocumentModal(false);
    };

    const onLinkClicked = async (attachment) => {

        const dataobject = `data:${attachment.contentType};base64,${attachment.contentBytes}`
        const pdfstr = await fetch(dataobject);
        const blobFromFetch = await pdfstr.blob();

        const file = new File([blobFromFetch], attachment.fileName, { type: attachment.contentType });

        setLinkAttachment(file);
        setDocumentTypeId(attachment?.documentTypeId)
        setShowLinkdocumentModal(true);
    }

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <SignOutButton showErrorToast={props.showErrorToast} />{accounts[0].name} */}
                </div>
                <h5 style={{ marginTop: 5, marginLeft: 20 }} className="card-title">Logged in as {loggedUserName} </h5>
            </div>


            <div style={{ padding: 20 }}>
                {showLinkdocumentModal &&
                    <LinkDocumentModal
                        onSaveMDSDOCDeclarationData={onSaveMDSDOCDeclarationData}
                        onModalCloseClick={onModalCloseClick}
                        documentTypeId={documentTypeId}
                        file={linkAttachment}
                        setIsLoading={props.setIsLoading}
                    />
                }
                {emails && (
                    <>

                        <div id="received-communications" className="tabcontent">
                            <div className="row">
                                <div className="col-12 col-md-7 col-lg-8" />
                                <div className="col-12 col-md-5 col-lg-4">
                                    <div className="searchBar">
                                        <input id="searchQueryInput" type="text" name="searchQueryInput" placeholder="Sender, Subject, etc."
                                            onChange={onSearchTextChange}
                                            value={searchText} className="search-placeholder-text" />
                                        <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                                            <img src="images/magnifying-glass.png" width="27px" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-filter-dropdown communications-history-form">
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button type="button" className="accordion-button collapsed filter-toggle-btn" data-bs-toggle="collapse" data-bs-target="#collapsetwo">Filter By</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body filter-dropdwn-body">
                                                {/* <div className="form-group">
                                                    <label className="label-class">Type of Email:</label>
                                                    <select value={filterEmailType} onChange={(e) => onDropdownChange(e)} name="FilterEmailType" className="form-select custom-dropdown-class" aria-label="Default select example">
                                                        <option value={''}>All</option>
                                                        {
                                                            props.emailTypeDataList.map(s => <option key={s} value={s}>{s}</option>)
                                                        }
                                                    </select>
                                                </div> */}
                                                <div className="form-group">
                                                    <label className="label-class">Sender:</label>
                                                    <select value={filterSender} onChange={(e) => onDropdownChange(e)} name="FilterSender" className="form-select custom-dropdown-class" aria-label="Default select example">
                                                        <option value={''}>All</option>
                                                        {
                                                            emailSenderlList.map(s => <option key={s} value={s}>{s}</option>)
                                                        }
                                                    </select>
                                                </div>
                                                <div className="filter-date-from">
                                                    <label htmlFor="exampleDataList" className="form-label">Date of Creation:</label><br />
                                                    <span className="from-to-box">
                                                        <span className="from-date"><label>From:</label>
                                                            <input type="date" className="form-control" />
                                                            <DatePicker
                                                                selectedDate={filterFromDate}
                                                                onDateChange={(e) => onDateChanged("filterFromDate", e)}
                                                                maxDate={previousToDate}
                                                            >
                                                                {" "}
                                                            </DatePicker>
                                                        </span>
                                                        <span className="to-date"><label>To:</label>
                                                            <DatePicker
                                                                selectedDate={filterToDate}
                                                                onDateChange={(e) => onDateChanged("filterToDate", e)}
                                                                minDate={nextFromDate}
                                                                maxDate={currentDate}
                                                            >
                                                                {" "}
                                                            </DatePicker>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-blue-bg "
                                                        onClick={clearAllFilter}
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-12 col-md-6 col-lg-3">
                                    <div className="desktop-class">
                                        <div className="form-group">
                                            <label className="label-class">Type of Email:</label>
                                            <select value={filterEmailType} onChange={(e) => onDropdownChange(e)} name="FilterEmailType" className="form-select custom-dropdown-class" aria-label="Default select example">
                                                <option value={''}>All</option>
                                                {
                                                    props.emailTypeDataList.map(s => <option key={s} value={s}>{s}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="desktop-class">
                                        <div className="form-group">
                                            <label className="label-class">Sender:</label>
                                            <select value={filterSender} onChange={(e) => onDropdownChange(e)} name="FilterSender" className="form-select custom-dropdown-class" aria-label="Default select example">
                                                <option value={''}>All</option>
                                                {
                                                    emailSenderlList.map(s => <option key={s} value={s}>{s}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-9">
                                    <div className="desktop-class">
                                        <div>
                                            <label htmlFor="exampleDataList" className="label-class">Date:</label>
                                            <span className="from-to-box">
                                                <span className="from-date"><label>From:</label>
                                                    <DatePicker
                                                        selectedDate={filterFromDate}
                                                        onDateChange={(e) => onDateChanged("filterFromDate", e)}
                                                        maxDate={previousToDate}
                                                    >
                                                        {" "}
                                                    </DatePicker>
                                                </span>
                                                <span className="to-date"><label>To:</label>
                                                    <DatePicker
                                                        selectedDate={filterToDate}
                                                        onDateChange={(e) => onDateChanged("filterToDate", e)}
                                                        minDate={nextFromDate}
                                                        maxDate={currentDate}
                                                    >
                                                        {" "}
                                                    </DatePicker>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="desktop-class">
                                        <button
                                            type="button"
                                            className="btn btn-blue-bg "
                                            onClick={clearAllFilter}
                                            style={{ marginTop: 15 }}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 icon-alignment">
                                    <img
                                        className="download-document"
                                        src="images/refreshIcon.png"
                                        width="30px"
                                        style={{ pointerEvents: "all", cursor: "pointer" }}
                                        onClick={onLoadNewEmailsClick}
                                        title="Refresh to load new emails."
                                    />
                                </div>

                                <div style={{ maxHeight: '800px', overflowY: 'auto' }}>
                                    {emails.length > 0 ? (<div className="accordion Communication-recieved" id="accordionExample">
                                        {
                                            emails.map((email, index) => (
                                                    <div key={email.id} className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#ACC${index}`} aria-expanded="true" aria-controls={`ACC${index}`}>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-md-1 communication-profile">
                                                                            <img src="images/user-2.png" alt="user" width="50px" />
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <h5 style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.from) }}></h5>
                                                                            <p style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.subject) }}></p>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <span className="accordion-time">{convertTime(email.receivedDate)} - {convertDate(email.receivedDate)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`ACC${index}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="container">
                                                                    <div style={{ maxWidth: '100%', wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.body) }} />
                                                                    {
                                                                        email.attachments.length > 0 ? (
                                                                            <>
                                                                                <h6 className="attachment-class">Attachments:</h6>
                                                                                <div className="row">
                                                                                    {
                                                                                        email.attachments
                                                                                            .filter(att => att.isAttachment)
                                                                                            .map((att) =>
                                                                                                <div key={att.id} className="col-lg-2 col-md-3 attachment-details">
                                                                                                    <img src="images/supplier-document.png" />
                                                                                                    <br /><a data-bs-toggle="modal" data-bs-target="#attachment-preview" onClick={() => { onViewFileClicked(att, email.id) }}>Preview</a>
                                                                                                    <br /><a href={`data:${att.contentType};base64,${att.contentBytes}`} download={att.fileName} rel="noreferrer" target="_blank">{att.fileName}</a>
                                                                                                    {att.showLink && (<div><a onClick={() => { onLinkClicked(att) }}>Link</a> </div>)}
                                                                                                </div>
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                            </>) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                        }
                                    </div>) : (<p style={{ textAlign: 'center' }} >No emails found</p>)}
                                </div>

                            </div>
                            <div className="row">
                                {
                                    visibleLoadMore && (<button style={{ margin: 'auto', width: 180 }} type="button" className="btn btn-blue-bg" onClick={() => onLoadMoreEmailsClick()}>
                                       Load More Emails
                                    </button>)
                                }
                            </div>
                        </div>
                        {showViewModal && (
                            <ViewAttachmentModal
                                showViewModal
                                modalheader="View Attachment"
                                filePath={filePath}
                                onCloseClick={() => { setShowViewModal(false); }}
                                onDownloadClick={() => onViewFileDownload(filePath)}
                                fileName={fileName}
                                useGoolgeViewer={useGoolgeViewer}
                                toastVisible={toastAttachmentVisible}
                                type={toastType}
                                message={toastMessage}
                                closeToast={closeToast}
                            ></ViewAttachmentModal>
                        )}

                        {toastVisible && (
                            <Toast
                                toastVisible
                                type={toastType}
                                message={toastMessage}
                                closeToast={closeToast}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};