import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { GetLocalStorageDataWithParse, changePageTitle, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import { dashboardservice } from "../../services/dashboard.service";
import { UserAction, UserLevel, UserRole } from "../../helpers/enums";
import Footer from "../../components/Footer";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      totalClients: 0,
      activeClient: 0,
      inActiveClients: 0,
      totalUsers: 0,
      totalSuppliers: 0,
      accessList: []
    };
  }

  /* <summary>
  date: 16-12-2022
  Name: VD
  description: First function called on page load
  <summary>*/
  componentDidMount = async () => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    //HV - 20/02/2023 - changing browser title name 
    changePageTitle("");
    //HV - 17/02/2023 - handled browser back button
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    if (userData != null) {
      this.getUserRolePermissions(userData);
    }
  };


  /* <summary>
  date: 20-2-2023
  Name:NGG
  description: This is used to the roles and permission for the logged in user
  <summary>*/
  getUserRolePermissions = async (userData) => {
    this.setState({ isLoading: true });
    var response = await dashboardservice.GetUserPermissionDashBoard(userData.id)
    if (response.status == 200) {
      var DefaultRolePermissionList = response.data.lstDefaultUserRolePermission != null ? response.data.lstDefaultUserRolePermission[0] : null
      var RolePermissionList = response.data.lstUserRolePermissions != null ? response.data.lstUserRolePermissions[0] : null

      this.setState({
        accessList: response.data.userRoleId != UserRole.ADMIN ? userData.levelId == UserLevel.CUSTOM ? RolePermissionList : DefaultRolePermissionList : null
      }, () => {
        this.GetAllDashboardinfo(userData);
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  }


  /* <summary>
  date: 16-12-2022
  Name: VD
  description: This is used to get all the count on dashboard page
  <summary>*/
  GetAllDashboardinfo = async (userData) => {

    var response = await dashboardservice.GetAllDashboardinfo(userData.id);
    if (response.status == 200) {
      this.setState({
        totalClients: response.data.totalClients,
        activeClient: response.data.activeClients,
        inActiveClients: response.data.inactiveClients,
        totalUsers: response.data.totalUsers,
        totalSuppliers: response.data.totalSuppliers,
        isLoading: false
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
    this.setState({ isLoading: false });
  };

  /* <summary>
  date: 16-12-2022
  Name: VD
  description: This is used to close the Toast once it has been opened
  <summary>*/
  closeToast = () => {
    this.setState({
      toastVisible: false,
    });
  };

  navigateToManageClient = () => {
    this.props.navigate("/ManageClient");
  };
  navigateToManageUser = () => {
    this.props.navigate("/ManageUsers");
  };

  navigateToTraining = () => {
    this.props.navigate("/Training");
  };

  navigateToMiscellaneousOperations = () => {
    this.props.navigate("/MiscellaneousOperations");
  };

  navigateToManageSupplier = () => {
    this.props.navigate("/ManageSuppliers");
  };

  navigateToTrackingTool = () => {
    this.props.navigate("/TrackingTool");
  };

  navigateToCommunication = () => {
    this.props.navigate("/Communication");
  };

  navigateToReports = () => {
    this.props.navigate("/Reports");
  };

  navigateToStatistics = () => {
    this.props.navigate("/ManageStatistics");
  };

  navigateToIHMDatabaseRegistry = () => {

    this.props.navigate("/ManageIHMDatabaseRegistry");
  }

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      totalClients,
      activeClient,
      inActiveClients,
      totalUsers,
      totalSuppliers,
      accessList
    } = this.state;
    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"Dashboard"} />
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height dashboard-bg">
          <section className="admin-dashboard">
            <div className="container">
              <div className="row quick-link">
                <fieldset className="dashboard-fieldset">
                  <legend className="dashboard-legend">Quick Access</legend>
                  <div className="row">
                    <div
                      className="col-12 col-sm-12 col-lg-5"
                      onClick={accessList != null ? accessList.clientAndShipAccess == UserAction.DENY ? "" : this.navigateToManageClient : this.navigateToManageClient}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={accessList != null ? accessList.clientAndShipAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                        <h3>Manage Clients and Ships</h3>
                        <img src="images/clients.png" alt="client" />
                        <div className="sub-data-quicklink">
                          <div className="row">
                            <div className="col-5 col-sm-4 col-lg-5">
                              <div>Total Clients</div>
                              <div className="semi-bold">
                                <span>Active Clients:</span>{" "}
                                <span className="orange-color">
                                  {activeClient}
                                </span>
                              </div>
                            </div>
                            <div className="col-5 col-sm-4 col-lg-5">
                              <div className="orange-color">
                                <i
                                  className="fa fa-line-chart"
                                  aria-hidden="true"
                                >
                                  {" "}
                                </i>{" "}
                                {totalClients}
                              </div>
                              <div className="semi-bold">
                                Inactive Clients:{" "}
                                <span className="orange-color">
                                  {inActiveClients}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-7">
                      <div className="row">
                        <div
                          className="col-sm-4 col-lg-4"
                          onClick={accessList != null ? accessList.trackingToolAccess == UserAction.DENY ? "" : this.navigateToTrackingTool : this.navigateToTrackingTool}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.trackingToolAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"} >
                            <h3>Tracking Tool</h3>
                            <img
                              src="images/tracking.png"
                              alt="Communications"
                            />
                          </div>
                        </div>
                        <div
                          className="col-sm-4  col-lg-4"
                          onClick={accessList != null ? accessList.communicationAccess == UserAction.DENY ? "" : this.navigateToCommunication : this.navigateToCommunication}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.communicationAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Communications</h3>
                            <img
                              src="images/Communications.png"
                              alt="Reports"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-lg-4"
                          onClick={accessList != null ? accessList.reportAccess == UserAction.DENY ? "" : this.navigateToReports : this.navigateToReports}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.reportAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Reports</h3>
                            <img
                              src="images/seo-report.png"
                              className="ihm-database-img"
                              alt="IHM Database & Registry"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="row">
                        <div
                          className="col-sm-6 col-lg-6"
                          onClick={accessList != null ? accessList.manageUserAccess == UserAction.DENY ? "" : this.navigateToManageUser : this.navigateToManageUser}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.manageUserAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Manage Users</h3>
                            <img
                              src="images/manage-user.png"
                              className="manage-user-icon"
                              alt="user"
                            />
                            <div className="sub-data-quicklink user-box">
                              <div className="row">
                                <div className="col-12 col-md-8">
                                  <div>Total Users</div>
                                  <div className="orange-color">
                                    <i
                                      className="fa fa-line-chart"
                                      aria-hidden="true"
                                    >
                                      {" "}
                                    </i>{" "}
                                    {totalUsers}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-sm-6 col-lg-6"
                          onClick={accessList != null ? accessList.supplierAccess == UserAction.DENY ? "" : this.navigateToManageSupplier : this.navigateToManageSupplier}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.supplierAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Manage Suppliers</h3>
                            <img
                              src="images/supplier.png"
                              alt="Supplier"
                            />
                            <div className="sub-data-quicklink user-box">
                              <div className="row">
                                <div className="col-12 col-md-8">
                                  <div>Total Suppliers</div>
                                  <div className="orange-color">
                                    <i
                                      className="fa fa-line-chart"
                                      aria-hidden="true"
                                    >
                                      {" "}
                                    </i>{" "}
                                    {totalSuppliers}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col-sm-4 col-lg-4"


                          onClick={accessList != null ? accessList.ihmdatabaseAccess == UserAction.DENY ? "" : this.navigateToIHMDatabaseRegistry : this.navigateToIHMDatabaseRegistry}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.ihmdatabaseAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>IHM Database &amp; Registry</h3>
                            <img
                              src="images/database.png"
                              alt="Database"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-lg-4"
                          onClick={accessList != null ? accessList.trainingAccess == UserAction.DENY ? "" : this.navigateToTraining : this.navigateToTraining}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.trainingAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Training</h3>
                            <img
                              src="images/training.png"
                              alt="Training"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-lg-4"
                          onClick={accessList != null ? accessList.financeAndAccountAccess == UserAction.DENY ? "" : this.navigateToMiscellaneousOperations : this.navigateToMiscellaneousOperations}
                          style={{ cursor: "pointer" }}>
                          <div className={accessList != null ? accessList.financeAndAccountAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Miscellaneous Operations</h3>
                            <img
                              src="images/bookkeeping.png"
                              alt="Miscellaneous Operations"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-sm-5 col-lg-5"
                          onClick={accessList != null ? accessList.statisticAccess == UserAction.DENY ? "" : this.navigateToStatistics : this.navigateToStatistics}
                          style={{ cursor: "pointer" }}
                        >
                          <div className={accessList != null ? accessList.statisticAccess == UserAction.DENY ? "quick-link-box quick-link-disable" : "quick-link-box" : "quick-link-box"}>
                            <h3>Statistics</h3>
                            <img
                              src="images/statistics.png"
                              alt="Database"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              {/* <div>
                <div
                  className="row dashboard-box2"
                  onClick={this.navigateToStatistics}
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-12 col-sm-3  col-lg-3">
                    <div className="statistics">
                      <h3>Statistics</h3>
                      <img
                        src="images/pie-chart.png"
                        className="img-fluid"
                        alt="Communications"
                      />
                      <div className="chart-data">
                        <div className="blue-chart"> </div> Hazmat Items
                      </div>
                      <div className="chart-data">
                        <div className="yellow-chart"> </div> Non Hazmat Items
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-9 col-lg-9 statistics-display">
                    <div className="row">
                      <div className="col-sm-6 col-lg-6">
                        <img
                          src="images/dummy-statistic.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-sm-6 col-lg-6">
                        <img
                          src="images/dummy-statistic-2.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Dashboard);
