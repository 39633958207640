import webserviceWrapper from "../helpers/webserviceWrapper";


export const UserService = {
    GetUserProfileData,
    UpdateUserProfileData,
    GenerateNewPassword,
    GetSystemGeneratedPassword,
    GetManageUsersData,
    GetDropdownData,
    AddEditInternalUser,
    GetUserAndDefaultAccessPermissions,
   // CheckIfEmailExists,
    GetDefaultPermissions,
    SaveDefaultPermissions,
    SaveUserRolesPermissions,
    GetUserRolesPermissions,
    DeleteProfileImage,
};

function GetUserProfileData(UserId, userTypeId) {
    return webserviceWrapper.get(`/User/GetUserProfileData?userId=${UserId}&userTypeId=${userTypeId}`);
}

function UpdateUserProfileData(profileData) {
    return webserviceWrapper.postWithHeader(
        `/User/UpdateUserProfileData`,
        profileData,
        {"Content-Type": "multipart/form-data",},
        600000
    );
}

function GenerateNewPassword(UserId) {
    return webserviceWrapper.get(`/User/GenerateNewPassword?userId=${UserId}`);
}

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get data for manage users screen
    <summary> */
function GetManageUsersData(loginUserId)
{
    return webserviceWrapper.get(`/User/GetManageUsersData?loginUserId=${loginUserId}`);
}

/* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to get data for dropdowns
    <summary> */
    function GetDropdownData(userID)
    {
        return webserviceWrapper.get(`/User/GetDropdownData?userID=${userID}`);
    }

    /* <summary>
    date: 22/11/2022
    Name: AS
    description: service api call to create/edit  internal user
    <summary> */
    function AddEditInternalUser(userData) {
        return webserviceWrapper.postWithHeader(
            `/User/AddEditInternalUser`,
            userData,
            {"Content-Type": "multipart/form-data",},
            600000
        );
    }

     /* <summary>
    date: 25/11/2022
    Name: AS
    description: service api call to fetch new system generated password to autofill password entry while creating new user
    <summary> */
    function GetSystemGeneratedPassword(UserId) {
        return webserviceWrapper.get(`/Common/GetSystemGeneratedPassword?userId=${UserId}`);
    }


   /* <summary>
    date: 02/12/2022
    Name: AS
    description: service api call to fetch user permissions and default level permissions
    <summary> */
    function GetUserAndDefaultAccessPermissions(UserId) {
        return webserviceWrapper.get(`/User/GetUserAndDefaultAccessPermissions?userId=${UserId}`);
    }

    
   /* <summary>
    date: 23/01/2023
    Name: SYJ
    description: service api call to fetch user permissions and default level permissions
    <summary> */
    function GetUserRolesPermissions(UserId) {
        return webserviceWrapper.get(`/User/GetUserRolesPermissionsData?userId=${UserId}`);
    }


     /* <summary>
    date: 02/12/2022
    Name: AS
    description: service api call to check if email address already registered
    <summary> */
    // function CheckIfEmailExists(data) {
    //     return webserviceWrapper.post(`/User/CheckIfEmailExists`, data);
    // }


    /* <summary>
    date: 09/12/2022
    Name: AS
    description: service api call to get default permissions for user levels
    <summary> */
    function GetDefaultPermissions()
    {
        return webserviceWrapper.get(`/User/GetDefaultPermissions`);
    }

     /* <summary>
    date: 09/12/2022
    Name: AS
    description: service api call to save default permissions for user levels
    <summary> */
    function SaveDefaultPermissions(data) {
        return webserviceWrapper.post(
            `/User/SaveDefaultPermissions`,
            data,
            60000
        );
    }
    
     /* <summary>
    date: 09/12/2022
    Name: AS
    description: service api call to save default permissions for user levels
    <summary> */
    function SaveUserRolesPermissions(data) {
        return webserviceWrapper.post(
            `/User/ManageRolesPermission`,
            data,
            60000
        );
    }

    function DeleteProfileImage(userId, userTypeId, loggedUserId) {
        return webserviceWrapper.get(`/Common/DeleteProfileImage?userId=${userId}&userTypeId=${userTypeId}&loggedUserId=${loggedUserId}`);
    }

   
