import React, { Component } from 'react';
import { render } from 'react-dom';
import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import getData from '../../assets/data';

class ChartExample extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                autoSize: true,
                title: {
                    text: 'Total PO Line Items',
                    fontSize: 18,
                },
                subtitle: {
                    text: 'Displays the total number of PO Line Items Checked Per Year',
                },
                series: [
                    {
                        type: 'column',
                        xKey: 'field',
                        yKey: 'PO',
                        fill: '#0084e7',
                        strokeWidth: 0,
                        shadow: {
                            xOffset: 3,
                        },
                        label: {
                            enabled: true,
                            color: '#eeeeee',
                            formatter: ({ value }) => formatNumber(value),
                        },
                        tooltip: {
                            renderer: ({ yValue, xValue }) => {
                                return { title: xValue, content: formatNumber(yValue) };
                            },
                        },
                    },
                ],
                axes: [
                    {
                        type: 'category',
                        position: 'bottom',
                        title: {
                            text: 'Year',
                        },
                    },
                    {
                        type: 'number',
                        position: 'left',
                        title: {
                            text: 'Total PO Line Items',
                        },
                        label: {
                            formatter: ({ value }) => formatNumber(value),
                        },
                    },
                ],
                legend: {
                    enabled: false,
                },
            },
        };
    }

    componentDidMount() {
        var dataSet = getData();
        this.setState({
            options: {
                data: dataSet[0].Year
            }
        });
    }

    onChange = (e) => {
        const { value } = e.target;
    };

    render() {
        return (
        <div className="container">
            <div className="col-12 col-md-4 col-lg-3 dropdown-column">
                <div className="form-group">
                    <label className="label-class">Filter By: </label>
                    <select className="form-select custom-dropdown-class" defaultValue={4} style={{width:200, height:35}} onChange={this.onChange} aria-label="Default select example">
                        <option value="0">Select</option>
                        <option value="1">Client</option>
                        <option value="2">Ship</option>
                        <option value="3">IHMM Handler</option>
                        <option value="4">Year</option>
                    </select>
                </div>
            </div>
             <div style={{ width: '700px' }}> <AgChartsReact options={this.state.options} /></div>
        </div>)
    }
}

function formatNumber(value) {
    //value /= 10;
    return `${Math.floor(value)}`;
}

export default ChartExample;