import webserviceWrapper from "../helpers/webserviceWrapper";


export const CommunicationService = {
    GetAllCommunicationFilters,
    AddCommunication,
    GetAllEmailDefaultTemplates,
    AddEditEmailTemplate,
    GetCommunicationHistory,
    DeleteCommunicationData,
    UploadEmailAttachmentForViewing
};

function GetAllCommunicationFilters(userID, userTypeId) {
    return webserviceWrapper.get(`/Communication/GetAllCommunicationFilters?userID=${userID}&userTypeId=${userTypeId}`);
}

function AddCommunication(communicationData) {
    return webserviceWrapper.postWithHeader(
        `/Communication/AddCommunication`,
        communicationData,
        { "Content-Type": "multipart/form-data" },
        600000
    );
}

function GetAllEmailDefaultTemplates() {
    return webserviceWrapper.get(`/EmailDefault/GetAllEmailDefaultTemplates`);
}

function AddEditEmailTemplate(emailData) {
    return webserviceWrapper.post(
        `/EmailDefault/AddEditEmailTemplate`,
        emailData,
        60000
    );
}

function GetCommunicationHistory(emailType, userID, userTypeId) {
    return webserviceWrapper.get(`/Communication/GetCommunicationHistory?emailType=${emailType}&userID=${userID}&userTypeId=${userTypeId}`);
}

function DeleteCommunicationData(communicationID, userID) {
    return webserviceWrapper.get(`/Communication/DeleteCommunicationData?communicationID=${communicationID}&userID=${userID}`);
}

function UploadEmailAttachmentForViewing(communicationData) {
    return webserviceWrapper.postWithHeader(
        `/Communication/UploadEmailAttachmentForViewing`,
        communicationData,
        { "Content-Type": "multipart/form-data", },
        600000
    );
}
