import webserviceWrapper from "../helpers/webserviceWrapper";

export const SupplierService = {
  GetManageSupplierData,
  SetSupplierStatus,
  SetSupplierOnHold,
  SaveSupplierProfileData,
  GenerateNewPassword,
  GetSupplierClientData,
  GetSupplierData,
  DeleteSupplierCompanyData,
  GetSupplierDocumentData,
  SaveSupplierChangeRequestData,
  GetSupplierDocDropdownData,
  SaveUploadMDSDocData,
  DownloadSupplierTemplate,
};

function GetManageSupplierData(supplierId, userID) {
  return webserviceWrapper.get(
    `/Supplier/GetManageSupplierData?supplierId=${supplierId}&userID=${userID}`
  );
}

function SetSupplierStatus(supplierId, statusId, userID, typeId) {
  return webserviceWrapper.get(
    `/Supplier/SetSupplierStatus?supplierId=${supplierId}&statusId=${statusId}&userID=${userID}&typeId=${typeId}`
  );
}

function SetSupplierOnHold(supplierData) {
  return webserviceWrapper.postWithHeader(
    `/Supplier/SetSupplierOnHold`,
    supplierData,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function SaveSupplierProfileData(supplierData) {
  return webserviceWrapper.postWithHeader(
    `/Supplier/SaveSupplierProfileData`,
    supplierData,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function GenerateNewPassword(supplierId, roleid, username) {
  return webserviceWrapper.get(
    `/Supplier/GenerateNewPassword?supplierId=${supplierId}&roleId=${roleid}&userName=${username}`
  );
}

function GetSupplierClientData(userID, supplierId, userTypeId) {
  return webserviceWrapper.get(
    `/Supplier/GetSupplierClientData?userID=${userID}&supplierId=${supplierId}&userTypeId=${userTypeId}`
  );
}

function GetSupplierData(supplierId) {
  return webserviceWrapper.get(
    `/Supplier/GetSupplierData?supplierId=${supplierId}`
  );
}

function DeleteSupplierCompanyData(suppCompId) {
  return webserviceWrapper.get(
    `/Supplier/DeleteSupplierCompanyData?suppCompId=${suppCompId}`
  );
}

function GetSupplierDocumentData(supplierId) {
  return webserviceWrapper.get(
    `/Supplier/GetSupplierDocumentData?supplierId=${supplierId}`
  );
}

function SaveSupplierChangeRequestData(supplierData) {
  return webserviceWrapper.postWithHeader(
    `/Supplier/SaveSupplierChangeRequestData`,
    supplierData,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function GetSupplierDocDropdownData(supplierId) {
  return webserviceWrapper.get(
    `/Supplier/GetSupplierDocDropdownData?supplierId=${supplierId}`
  );
}

function SaveUploadMDSDocData(supplierData) {
  return webserviceWrapper.postWithHeader(
    `/Supplier/SaveUploadMDSDocData`,
    supplierData,
    { "Content-Type": "multipart/form-data" },
    600000
  );
}

function DownloadSupplierTemplate() {
  return webserviceWrapper.get(`/Supplier/DownloadSupplierTemplate`);
}
