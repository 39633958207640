import React, { Component } from 'react'
import { isStringEmpty } from '../helpers/string-helper';
import { LoginService } from '../services/login.service';
import Logout from '../helpers/Logout';
import { GetLocalStorageDataWithParse, helperText } from '../helpers';
import Loader from './Loader';

const ErrorView = (props) => {
    return (
        <>
            {props.errorFor != "" &&
                (
                    <div style={{ right: "45px" }} className={props.errorFor == "" ? "invalid-dropdown-icon" : "invalid-message-icon"}>
                        <img src="images\icon material-error.png" alt="invalid" />
                    </div>
                )}
        </>
    );
};

export default class ResetPasswordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            userTypeId: 0,
            newPassword: "",
            confirmPassword: "",
            showNewPassword: false,
            showConfirmPassword: false,
            errorMessages: {},
            validateError: false,
            passwordNotMatching: false,
            invalidPasswordError: false,
            isLoading: false,
        };
    }

    componentDidMount = () => {
        var userData = GetLocalStorageDataWithParse("loggedInUser");
        if (userData != null) {
            this.setState({
                userId: userData.id,
                userTypeId: userData.typeId,
            });
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.handleValidation();
        });
    };

    validatePassword = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?])(?!.*\s).{8,}$/;
        return passwordRegex.test(password);
    };

    handleResetPassword = async () => {
        const { newPassword, errorMessages, userId, userTypeId } = this.state;

        var hasErrors = this.handleValidation();
        if (hasErrors) return;
        this.setState({ isLoading: true });

        var resetPassData = {
            userId: userId,
            userTypeId: userTypeId,
            newPassword: newPassword,
          };

        var responseSaved = await LoginService.ResetPasswordService(resetPassData);
        if (
            responseSaved != null &&
            responseSaved != undefined &&
            responseSaved.status == 200
        ) {
            this.setState({
                isLoading: false,
            }, () => {
                    this.props.onCloseClick(true);
                });
        } else if (responseSaved.status == 401) {
            Logout(this.props.navigate);
        } else {
            this.setState({
                toastVisible: true,
                toastMessage: helperText.global.somethingWentWrong,
                toastType: "fail",
                isLoading: false,
            },
                () => {
                    this.props.onCloseClick(false);
                });
        }
    }

    handleValidation = () => {

        const { errorMessages, newPassword, confirmPassword, userId, userTypeId } = this.state;
        var hasErrors = false;
        let errors = errorMessages

        errors['errorNewPassword'] = isStringEmpty(newPassword) ? "true" : "";
        errors['errorInvalidNewPassword'] = !this.validatePassword(newPassword) ? "true" : "";
        errors['errorConfirmPassword'] = isStringEmpty(confirmPassword) ? "true" : "";
        errors['errorInvalidConfirmPassword'] = !this.validatePassword(confirmPassword) ? "true" : "";
        errors['errorPasswordNotMatching'] = newPassword.trim() !== confirmPassword.trim() ? "true" : "";

        if (errors['errorNewPassword'] != "" || errors['errorConfirmPassword'] != "") {
            hasErrors = true;
            this.setState({
                validateError: hasErrors,
                passwordNotMatching: false,
                invalidPasswordError: false,
                errorMessages: errors,
            })
        }
        else if (errors['errorPasswordNotMatching'] != "") {
            hasErrors = true;
            this.setState({
                passwordNotMatching: hasErrors,
                validateError: false,
                errorMessages: errors,
                invalidPasswordError: false,
            })
        }
        else if (errors['errorInvalidNewPassword'] != "" || errors['errorInvalidConfirmPassword'] != "") {
            hasErrors = true;
            this.setState({
                passwordNotMatching: false,
                validateError: false,
                errorMessages: errors,
                invalidPasswordError: hasErrors,
            })
        }
        else {
            this.setState({
                validateError: false,
                passwordNotMatching: false,
                errorMessages: errors,
                invalidPasswordError: false,
            })
        }
        return hasErrors;
    }

    toggleNewPassword = () => {
        this.setState((prevState) => ({ showNewPassword: !prevState.showNewPassword }));
    };

    toggleConfirmPassword = () => {
        this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
    };

    render() {
        const {
            newPassword,
            confirmPassword,
            showConfirmPassword,
            showNewPassword,
            errorMessages,
            validateError,
            passwordNotMatching,
            invalidPasswordError,
            isLoading,
        } = this.state;

        return (

            <div className="Training_Evalution client-modal-document" style={{ display: "block" }}>
                {isLoading && <Loader/>}
                <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
                <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Reset Password</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: "all" }} onClick={() => this.props.onCloseClick(false)} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row' style={{ padding: '15px' }}>
                                    <div className='col-lg-2'>

                                    </div>
                                    <div className="col-lg-8 col-12" style={{ padding: 0 }}>
                                        <div className='row'>
                                            <label className="mb-1 mt-3">New Password:</label>
                                            <div className={!isStringEmpty(errorMessages.errorNewPassword) ? "admin-supplier-change-password invalid-position" : "admin-supplier-change-password"}>
                                                <input
                                                    autoFocus={false}
                                                    autoComplete={"off"}
                                                    type={showNewPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Enter New Password"
                                                    value={newPassword}
                                                    name={"newPassword"}
                                                    id="id_password"
                                                    onChange={this.handleInputChange}
                                                    minLength={8}
                                                />
                                                <i
                                                    className={showNewPassword ? "fa fa-eye eye-icon cursor-class" : "fa eye-icon fa-eye-slash cursor-class"}
                                                    id="togglePassword"
                                                    onClick={this.toggleNewPassword}
                                                    title={showNewPassword ? "Hide" : "Show"}
                                                    style={{ right: "16px", left: "unset" }}>
                                                </i>

                                                {!isStringEmpty(errorMessages.errorNewPassword) && (
                                                    <ErrorView errorFor="x" />
                                                )}
                                            </div>
                                            <br />
                                            <label className="mb-1 mt-3">Confirm New Password:</label>
                                            <div className={!isStringEmpty(errorMessages.errorConfirmPassword) ? "admin-supplier-change-password invalid-position" : "admin-supplier-change-password"}>
                                                <input
                                                    autoFocus={false}
                                                    autoComplete={"off"}
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Confirm New Password"
                                                    value={confirmPassword}
                                                    name={"confirmPassword"}
                                                    id="id_password"
                                                    onChange={this.handleInputChange}
                                                    minLength={8}
                                                />
                                                <i
                                                    className={showConfirmPassword ? "fa fa-eye eye-icon cursor-class" : "fa eye-icon fa-eye-slash cursor-class"}
                                                    id="togglePassword"
                                                    onClick={this.toggleConfirmPassword}
                                                    title={showConfirmPassword ? "Hide" : "Show"}
                                                    style={{ right: "16px", left: "unset" }}>
                                                </i>

                                                {!isStringEmpty(errorMessages.errorConfirmPassword) && (
                                                    <ErrorView errorFor="x" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {invalidPasswordError && (
                                    <div className="invalid-message-icon-box">
                                        <img
                                            src="images/icon material-error.png"
                                            alt="invalid-icon"
                                        />
                                        <p>Password should have a minimum of 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.</p>
                                    </div>
                                )}

                                {passwordNotMatching && (
                                    <div className="invalid-message-icon-box">
                                        <img
                                            src="images/icon material-error.png"
                                            alt="invalid-icon"
                                        />
                                        <p>Passwords do not match.</p>
                                    </div>
                                )}

                                {validateError && (
                                    <div className="invalid-message-icon-box">
                                        <img
                                            src="images/icon material-error.png"
                                            alt="invalid-icon"
                                        />
                                        <p>Please fill in all the mandatory fields.</p>
                                    </div>
                                )}
                                <div className="text-center" style={{ padding: '20px', cursor: 'pointer' }}>
                                    <button className="btn btn-background-color upload-btn" onClick={this.handleResetPassword}>Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
