import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Footer from "../../components/Footer";
import { TrainingService } from "../../services/training.service";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import Logout from "../../helpers/Logout";

class TrainingDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedTrainingDocs: [],
      defaultTrainingDocs: [],
      isLoading: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      isSupplier: false,
      isShipCrew: false,
    };
  }

  /* <summary>
    date: 27/02/2023
    Name: AN
    description: This is used to get all the data on training page
    <summary>*/
  componentDidMount() {
    var userData = GetLocalStorageDataWithParse("loggedInUser");

    changePageTitle(TitlePageNames.TRAINING);

    if (userData != null) {
      this.setState({
        isSupplier: userData.typeId == UserRole.SUPPLIER,
        isShipCrew: userData.typeId == UserRole.SHIPCREW,
      });
      this.getClientTrainingData(userData.id, userData.typeId);
    }
  }

  /* <summary>
    date: 27/02/2023
    Name: AN
    description: This is used to get all the data on training page
    <summary>*/
  getClientTrainingData = (clientId, typeId) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const response = await TrainingService.GetClientTrainingData(
          clientId,
          typeId
        );

        if (
          response != null &&
          response != undefined &&
          response.status == 200
        ) {
          const groupedDocuments = {};
          const documents = response.data.listClientTrainingDocumentsData;

          documents.forEach((document) => {
            if (!groupedDocuments[document.trainingDocumentType]) {
              groupedDocuments[document.trainingDocumentType] = [];
            }
            groupedDocuments[document.trainingDocumentType].push(document);
          });

          this.setState({
            defaultTrainingDocs: groupedDocuments,
            isLoading: false,
          });
        } else if (response.status == 401) {
          Logout(this.props.navigate);
        } else {
          this.setState({
            isLoading: false,
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
          });
        }
      }
    );
  };

  /* <summary>
    date: 27/02/2023
    Name: AN
    description: This is used to download file
    <summary>*/
  onFileDownload = (filePath) => {
    var lastItem = filePath.split("/").pop();
    var fname = lastItem;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", filePath);
    var ext = filePath.substring(filePath.lastIndexOf(".") + 1);
    if (ext == "pdf" || ext == "PDF") {
      link.setAttribute("target", "_blank");
    } else {
      link.setAttribute("target", "");
    }
    document.body.appendChild(link);
    link.download = fname;
    link.click();

    setTimeout(() => {
    this.setState({ 
      toastVisible: true, 
      toastMessage: helperText.global.downloadSuccess, 
      toastType: helperText.global.toastSuccess
    })
      
    }, 1000);

  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  onBackClick = () => {
    const { isShipCrew, isSupplier } = this.state;
    let path = "";

    var loc = GetLocalStorageDataWithoutParse("location");
    if (loc == "/ActivityLog")
      path = "/ActivityLog";
    else if (isShipCrew)
      path = "/ShipCrewDashboard";
    else if (isSupplier)
      path = "/SupplierDashboard";
    else
      path = "/ClientDashboard";

    AddLocalStorageData("location", null);
    this.props.navigate(path);
  }

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      defaultTrainingDocs,
      isSupplier,
      isShipCrew,
    } = this.state;

    return (
      <>
        {isLoading && <Loader />}

        <Header componentFor={"TrainingDocument"} />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        <div className="admin-main-body contact-us-body-header training-parent-class">
          <div className="training-banner" style={{ zIndex: "unset" }}>
            <div className="container-fluid">
              <div className="internal-page-banner">
                {/* <h1>Training</h1> */}
                <div className="container training-banner-box">
                  <a
                    onClick={() => this.onBackClick()}
                  >
                    <img
                      className="back-btn-responsive"
                      src="images/white_back_button.png"
                      height="22px"
                      style={{ cursor: "pointer" }}
                    />
                  </a>{" "}
                  <h3>
                    Training
                    <hr />
                  </h3>
                </div>
              </div>
            </div>
          </div>
          {Object.keys(defaultTrainingDocs).length > 0 ? (
            <div className="container">
              {Object.keys(defaultTrainingDocs).map((type, index) => (
                <div key={index}>
                  <h4 className="mt-3 training-reference">{type}:</h4>
                  <div className="d-flex training-flexrow">
                    {defaultTrainingDocs[type].map((document, id) => (
                      <div key={id} className="training-flexbox">
                        <div className="d-flex">
                          <img
                            src="images/supplier-document.png"
                            alt="document"
                            className="document-training"
                          />
                          <span className="training-doc"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                            data-tooltip={document.trainingDocumentName}
                          >{document.trainingDocumentName}</span>
                        </div>

                        <img
                          style={{ cursor: "pointer" }}
                          src="images/admin-download.png"
                          alt="download"
                          className="download-training"
                          onClick={() =>
                            this.onFileDownload(document.trainingDocumentPath)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (!this.state.isLoading &&
            <div
              style={{
                textAlign: "center",
                marginBottom: "15px",
                marginTop: "15px",
                paddingTop: "25px",
                fontFamily: "Arial,sans-serif",
                fontSize: "1.5rem"
              }}
            >
              No data to display.
            </div>
          )}
        </div>

        <Footer />
      </>
    );
  }
}

export default withRouter(TrainingDocument);
