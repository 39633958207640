import React from "react";

const ErrorMessageBox = () => {
  return (
    <div className="invalid-message-icon-box">
      <img src="images/icon material-error.png" alt="invalid-icon" />
      <p>Please fill in all the mandatory fields.</p>
    </div>
  );
};

export default ErrorMessageBox;
