import React from "react";

const AlertModal = ({
    emailSentModal,
    onOkClick,
    msgToDisplay,
    viewLink,
}) => {
    return (
        <section>
            <div className="supplier_on_hold">
                <div className="modal-backdrop fade show" style={{ display: emailSentModal ? 'block' : 'none' }}></div>
                <div className="modal" id="view-document-Modal" style={{ display: emailSentModal ? 'block' : 'none', zIndex: "999999" }} tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-box modal-md">
                        <div className="modal-content modal-border">
                            <div className="row content">
                                <div className="table-responsive">
                                    <label htmlFor="exampleDataList" className="form-label">
                                        {msgToDisplay}
                                    </label>
                                    <br />
                                    <a className="cursor-class" onClick={viewLink}> View Email </a>
                                </div>

                                <br />
                                <div className="filter-box filter-margin-top">
                                    <button type="button" className="btn btn-blue-bg " onClick={onOkClick}>
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AlertModal;