import React, { Component } from "react";
import ViewPOHistory from "./ViewPOHistory";

export default class ImportPOHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHistoryModal: false,
      gridData: [],
      poFileName: "",
      fileProperty: "",
      importedPOFileId: 0,
    };
  }
  toggleModal = (name, value, item) => {
    this.setState({ [name]: value });
    if (value == true) {
      this.setState({
        gridData:
          item.importedPOFileModel.fileProperty == "unreadable"
            ? item.gridListUnreadable
            : item.gridListReadable,
        poFileName: item.poFileName,
        fileProperty: item.importedPOFileModel.fileProperty,
        importedPOFileId: item.importedPOFileModel.poimportedFileId,
      });
    }
  };

  render() {
    var {
      importHistory,
      colDefs,
      colDefsUnreadable,
      onDownloadPOReadableFilesClick,
    } = this.props;
    var {
      showHistoryModal,
      gridData,
      poFileName,
      fileProperty,
      importedPOFileId,
    } = this.state;
    return (
      <div className="po-item-list-history">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#shipdetails"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                History
              </button>
            </h2>
            {importHistory.length === 0 && (
              <div
                id="shipdetails"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body">
                  <label>No records to display.</label>
                </div>
              </div>
            )}

            {importHistory.length > 0 && (
              <div
                id="shipdetails"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {importHistory.map((item, i) => {
                    return (
                      <div key={i} className="history-details">
                        <div className="row">
                          <div className="col-3 col-sm-2 col-md-1 col-lg-1">
                            {/* <div className="po-history-width excel-status"></div> */}
                            {/* <div className="po-history-width api-status"></div> */}
                            <div
                              className={
                                item.importedPOFileModel.fileType == "excel" || item.importedPOFileModel.fileType == "xlsx" || item.importedPOFileModel.fileType == "xls"
                                  ? "po-history-width excel-status"
                                  : item.importedPOFileModel.fileType ==
                                      "pdf" ||
                                    item.importedPOFileModel.fileType == "PDF"
                                  ? "po-history-width pdf-status"
                                  : "po-history-width api-status"
                              }
                            ></div>
                          </div>
                          <div className="col-9 col-sm-10 col-md-11 col-lg-11">
                            <h5>
                              Created on {item.importedPOFileModel.createdOn}
                            </h5>
                            <p>
                              {item.importedPOFileModel.totalSuspectedItem}{" "}
                              suspected hazmat items found out of{" "}
                              {item.importedPOFileModel.totalPoitem} inserted
                              records.
                            </p>
                            <span
                              style={{
                                color: "#0000EE",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.toggleModal("showHistoryModal", true, item)
                              }
                            >
                              View list
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {showHistoryModal && (
              <ViewPOHistory
                closeModal={this.toggleModal}
                rowData={gridData}
                colDefs={colDefs}
                colDefsUnreadable={colDefsUnreadable}
                poFileName={poFileName}
                fileProperty={fileProperty}
                importedPOFileId={importedPOFileId}
                onDownloadPOReadableFilesClick={onDownloadPOReadableFilesClick}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
