import React, { Component } from "react";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, withRouter } from "../../helpers";
import Header from "../../components/Header.jsx";
import { ClientService } from "../../services/client.service";
import { UserService } from "../../services/user.service";
import { UserAction } from "../../helpers/enums";
import DirtyModal from "../../components/DirtyModal";
import {
  generateRandomString,
  helperText,
  isValidFile,
  isPasswordValid,
  isEmailValid,
} from "../../helpers";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import Footer from "../../components/Footer.jsx";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import RolesPermissionModal from "./ClientRolesPermissionModal";
import Dropdown from "../../components/Dropdown";
import UserCredentials from "../../components/UserCredentials";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import Logout from "../../helpers/Logout";
import editIcon from "../../assets/images/profile-feather-edit.png";
import { parse } from "bowser";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import { CommonService } from "../../services/common.service";
import { TitlePageNames, UserRole } from "../../helpers/enums";
import ClientChangeRequestModal from "../ClientShipPortal/ClientChangeRequestModal";
import ProceedModal from "../../components/ProceedModal";
import addProfileIcon from "../../assets/images/plus.png";
import ClientRolesNPermissionModal from "../../components/ClientRolesNPermissionModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import deleteIcon from "../../assets/images/delete.png";
import DeleteModal from "../../components/DeleteModel";
import { UncontrolledTooltip } from "reactstrap";

const validFileFormats = ["png, jpg, jpeg"];
const NUMBER_REGEX = /^[0-9()+-\s]+$/;
const defaultAllItem = { label: "All", value: "0" };
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";

class AddEditClientUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastVisible: false,
      isLoading: false,
      toastType: "",
      toastMessage: "",
      errorMessages: {},
      clientRoleList: [],
      clientServiceCategoryList: [],
      clientRolesPermissions: [],
      customClientRolesPermissions: [],
      userId: 0,
      clientUserId:
        parseInt(GetLocalStorageDataWithoutParse("selectedClientUserId"), 10) || 0,
      clientId: parseInt(GetLocalStorageDataWithoutParse("selectedClientID"), 10) || 0,
      clientUserName: "",
      clientUserPassword: "",
      clientUserEmail: "",
      clientUserPIC: "",
      clientUserProfilePic: "",
      clientUserContactNo: "",
      clientUseRoleId: 0,
      dropdown_inheritedClientUserRoleId: 0,
      showErrorMessage: false,
      showEmailValidationErrorMessage: false,
      fileInputKey: this.generateRandomString(),
      showPassword: false,
      isEditable: false,
      profilePictureUrl: "",
      dropdownList_shipList_Filtered: [],
      dropdown_ship: [],
      usernameExistError: false,
      emailExistError: false,
      defaultClientRolePermission: [],
      roleId: 0,
      isClient: false,
      isClientUser: false,
      showChangePasswordModal: false,
      showProceedModal: false,
      dropdown_clientRole: 0,
      profilePictureFileMediaId: 0,
      typeId: "",
      accessList: [],
      saveButtonClick: false,
      viewRolesPermissionVisible: false,
      userRolePermissionRead: false,
      showChangePasswordConfirmationModal: false,
      showDirtyPopup: false,
      path: "",
      customClientRolesPermissionId: 0,
      isCustomRoleUpdated: false,
      shortEmployeeNameError: false,
      createdOn: "",
      createdBy: "",
      showDeleteProfileModal: false,
      isProfileImgDeleted: false,
      isDataEditable: true,
    };
  }
  onDropdownChangeServiceCategory = (option) => {
    const { showErrorMessage } = this.state;
    var dropdown_ServiceCategorylist = [];
    if (option.length > 0) {
      option.forEach((item) => {
        dropdown_ServiceCategorylist.push(item);
      });
    }
    this.setState(
      { dropdown_clientServiceCategory: dropdown_ServiceCategorylist },
      () => {
        if (showErrorMessage) this.validate();
      }
    );
  };
  /* <summary>
    date: 24/01/2023
    Name: SYJ
    description: Get data to bind on the Add Edit client screen
    <summary> */
  componentDidMount = async () => {
    try {
      //HV - 20/02/2023 - changing browser title name
      changePageTitle(TitlePageNames.CLIENTUSERDETAILS);
      var userData = GetLocalStorageDataWithParse("loggedInUser");

      if (userData != null) {
        const { clientId } = this.state;

        var data = GetLocalStorageDataWithParse("selectedClient");

        this.setState({
          userId: userData.id,
          clientId: clientId == 0 ? data?.clientId : clientId,
          isClient: userData.typeId == UserRole.CLIENT,
          isClientUser: userData.typeId == UserRole.CLIENTUSER,
          typeId: userData.typeId,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
          async () => {

            await this.getDropdownData();

            await this.getClientUserShipDetails();

            let isAddClientUser =
              GetLocalStorageDataWithoutParse("selectedClientUser") == 0 ? true : false;
            this.setState({
              isAddClientUser: GetLocalStorageDataWithoutParse("selectedClientUser") == 0,
            });

            if (!isAddClientUser) {
              this.loadUserData();
            } else {
              this.BindAddClientUserProfileData();
            }
          }
        );
      }
    } catch (error) { }
  };
  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState({
      showDirtyPopup: false,
    }, () => {
      if (this.state.path != "")
        AddLocalStorageData("isDirty", false);
      this.props.navigate(this.state.path);
    });
  };

  onCheckBoxSeelcted = (data) => {
    let selectedList = data.filter((x) => x.isChecked == true);
    var commaSeparated = selectedList.map((x) => {
      return x.value;
    });
    let selectedServiceCategoryIds = commaSeparated.toString();
    let errors = this.state.errorMessages;

    let finalClientPic = [];
    let clientPicEmails = "";

    errors["serviceCategoryValidation"] = "";

    this.setState(
      {
        clientServiceCategoryList: data,
        dropdown_clientServiceCategory: selectedServiceCategoryIds,
        errors: errors,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (this.state.showErrorMessage) this.validate();
      }
    );
  };
  getDropdownData = async () => {
    this.setState({
      isLoading: true,
      userRolePermissionRead:
        this.state.accessList != null &&
        this.state.accessList.clientAndShipAccess == UserAction.READ || this.state.accessList != null &&
        this.state.accessList.addClientAccess == UserAction.READ,
    });

    const response = await ClientService.GetClientDropdownData(
      this.state.clientId,
      this.state.clientUserId
    );

    if (response != null && response != undefined && response.status == 200) {
      this.setState(
        {
          clientRoleList: response.data.clientRoleList,
          clientServiceCategoryList: response.data.serviceCategoryList,
        },
        () => {
          this.getClientAndDefaultAccessPermissions(this.state.dropdown_clientRole, false);
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
  };

  getClientUserShipDetails = async () => {

    const { clientId } = this.state;

    var clientid = clientId == 0 ? parseInt(GetLocalStorageDataWithoutParse("selectedClientID"), 10) : clientId;
    const response = await ClientService.GetClientUserShipData(clientid);
    if (response != null && response != undefined && response.status == 200) {
      var shipList = response.data.clientUserList;

      if (shipList != null && shipList != undefined && shipList.length > 0) {
        shipList.unshift(defaultAllItem);
        this.setState({
          dropdownList_shipList_Filtered: shipList,
        });
      }
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
  };

  /* <summary>
 date: 26/01/2023
 Name: SYJ
 description: Get Default permissions of Client
 <summary> */
  getClientAndDefaultAccessPermissions = async (clientRole, showModal) => {

    let roleId = clientRole == null ? 0 : clientRole;

    const { clientId, clientUserId } = this.state;

    const response =
      await ClientService.GetClientUserAndDefaultAccessPermissions(clientId, clientUserId, parseInt(roleId, 10));

    if (
      response != null &&
      response != undefined &&
      response.data != null &&
      response.status == 200
    ) {
      const { customClientRolesPermissions, dropdown_inheritedClientUserRoleId } = this.state;

      let custRoles = customClientRolesPermissions.length == 0 ? response.data.customClientRolesPermission : customClientRolesPermissions;
      let customClientRolesPermissionId = 0;

      if (custRoles != null && custRoles.customClientRolesPermissionId != 0) {
        if (custRoles.shipParticularsAccess != null) {
          customClientRolesPermissionId = custRoles.customClientRolesPermissionId;
        }
      }

      this.setState({
        defaultRolesPermissions: response.data.defaultClientRolesPermissions,
        clientRolesPermissions: custRoles,
        defaultClientRolePermission: response.data.defaultClientRolesPermission,
        dropdown_inheritedClientUserRoleId: dropdown_inheritedClientUserRoleId != 0 && dropdown_inheritedClientUserRoleId != null ? dropdown_inheritedClientUserRoleId :
          custRoles == null ? 0 : custRoles.inheritedClientLevelId,
        customClientRolesPermissions: custRoles != null && custRoles.customClientRolesPermissionId != 0 && custRoles.shipParticularsAccess != 0 ? custRoles : [],
        rolesPermissionVisible: showModal,
        customClientRolesPermissionId: customClientRolesPermissionId,
        isLoading: false,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
  };

  ApplyCustomPermissions = (
    accessPermissions,
    dropdown_inheritedClientUserRoleId
  ) => {
    const { showErrorMessage, customClientRolesPermissionId } = this.state;

    if (customClientRolesPermissionId != 0) {
      accessPermissions.customClientRolesPermissionId = customClientRolesPermissionId;
    }

    this.setState({
      clientRolesPermissions: accessPermissions,
      customClientRolesPermissions: accessPermissions,
      isCustomRoleUpdated: true,
      dropdown_inheritedClientUserRoleId: dropdown_inheritedClientUserRoleId,
    }, () => {
      if (showErrorMessage) {
        this.validate();
      }

      this.setState({
        rolesPermissionVisible: false
      });
    });

  };

  closeUserPermissionsModal = () => {
    this.setState({
      rolesPermissionVisible: false,
      viewRolesPermissionVisible: false,
      isCustomRoleUpdated: false
    });
  };

  onSendEmail = async () => {
    const { clientUserId } = this.state;
    if (clientUserId != 0) {
      this.setState({ isLoading: true });
      var response = await CommonService.SendEmailToUser(
        clientUserId,
        UserRole.CLIENTUSER
      );
      if (response != null && response != undefined && response.status == 200) {
        var emailSent = response.data;

        if (emailSent == true) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.emailSent,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          });
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            isLoading: false,
          });
        }
      }
    }
  };

  onSaveClick = async () => {
    const {
      clientId,
      clientUserId,
      clientUserName,
      clientUserPassword,
      clientUserEmail,
      clientUserPIC,
      clientUserContactNo,
      dropdown_clientRole,
      dropdown_clientServiceCategory,
      dropdown_inheritedClientUserRoleId,
      createdOn,
      documentFile,
      customClientRolesPermissions,
      userId,
      dropdown_ship,
      errorMessages,
      profilePictureFileMediaId,
      typeId,
      isCustomRoleUpdated,
      createdBy,
      isProfileImgDeleted
    } = this.state;

    var hasErrors = this.validate();

    if (!hasErrors) {
      this.setState({ isLoading: true });
      let errors = errorMessages;

      var data = {
        emailAddress: clientUserEmail,
        tableName: "ClientUsers",
        userId: clientUserId,
      };
      var response = await CommonService.CheckIfEmailExists(data);
      if (response != null && response != undefined && response.status == 200) {
        var emailExists = response.data;
        if (emailExists == true) {
          errors["clientUserEmailValidation"] = "true";
          this.setState({
            toastVisible: true,
            toastMessage: helperText.ClientUser.EmailRegistered,
            toastType: helperText.global.toastError,
            errorMessages: errors,
            emailExistError: emailExists,
            isLoading: false
          });
          return;
        }
      }

      var data = {
        userName: clientUserName,
        tableName: "ClientUsers",
        userId: clientUserId,
      };
      var responeData = await CommonService.CheckIfUserNameExist(data);
      if (
        responeData != null &&
        responeData != undefined &&
        responeData.status == 200
      ) {
        var usernameExist = responeData.data;
        if (usernameExist == true) {
          errors["clientUserUserNameValidation"] = "true";
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.usernameAlreadyExists,
            toastType: helperText.global.toastError,
            errorMessages: errors,
            usernameExistError: usernameExist,
            isLoading: false,
          });
          return;
        }
      }

      let assignedShip = dropdown_ship == undefined ? [] : dropdown_ship;

      if (assignedShip.length > 0) {
        assignedShip = assignedShip.filter((t) => t.value != "0");
      }


      let clientUserData = new FormData();

      clientUserData.append("userId", userId);
      clientUserData.append("ClientId", clientId);
      clientUserData.append("ClientUserId", clientUserId);
      clientUserData.append("ClientUserUsername", clientUserName);
      clientUserData.append("clientUserPassword", clientUserPassword);
      clientUserData.append("PICName", clientUserPIC);
      clientUserData.append("clientRole", dropdown_clientRole);
      clientUserData.append(
        "clientServiceCategory",
        dropdown_clientServiceCategory
      );
      clientUserData.append("ClientUserContact", clientUserContactNo);
      clientUserData.append("ClientUserEmailAddress", clientUserEmail);
      clientUserData.append("createdOn", createdOn);
      clientUserData.append("createdBy", createdBy);
      clientUserData.append(
        "CustomClientUserPermission",
        customClientRolesPermissions.length == 0
          ? null
          : JSON.stringify(customClientRolesPermissions)
      );
      clientUserData.append(
        "InheritedClientUserRoleId",
        dropdown_inheritedClientUserRoleId == undefined
          ? ""
          : dropdown_inheritedClientUserRoleId
      );
      clientUserData.append("Ship", JSON.stringify(assignedShip));
      let file = documentFile;
      if (file != undefined && file != "" && !isProfileImgDeleted) {
        clientUserData.append("ProfilePicAttached", file);
      }
      clientUserData.append("isClient", this.state.isClient);
      clientUserData.append(
        "ProfilePictureFileMediaId", isProfileImgDeleted ? 0 :
        profilePictureFileMediaId
      );
      clientUserData.append("typeId", typeId);
      clientUserData.append("IsCustomRoleUpdated", isCustomRoleUpdated);


      if (isProfileImgDeleted) {
        this.deleteClientUserProfileImage(this.state.clientUserId, UserRole.CLIENTUSER, this.state.userId);
        this.setState({
          isProfileImgDeleted: false,
        })
      }

      var response = await ClientService.SaveClientUserProfileData(
        clientUserData
      );

      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null && response.status == 200) {
        AddLocalStorageData("isDirty", false);
        this.setState(
          {
            saveButtonClick: true,
            clientId: response.data.clientId,
            clientUserId: response.data.clientUserId,
          },
          async () => {
            await this.getDropdownData();
            await this.getClientUserShipDetails();
            await this.loadUserData();
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastSuccess,
              toastMessage:
                clientUserId == 0
                  ? helperText.ClientUser.clientUserAdded
                  : helperText.ClientUser.clientUserUpdated,
              isLoading: false,
            });
          }
        );

      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    }
  };

  BindAddClientUserProfileData = async () => {
    this.onChangePasswordClick(true);
  };

  loadUserData = async () => {

    const { clientId, clientUserId } = this.state;

    var response = await ClientService.GetClientUserData(
      clientId,
      clientUserId
    );
    if (response != null && response != undefined && response.status == 200) {

      let assignedShip = response.data.shipList;
      let totalShipList = this.state.dropdownList_shipList_Filtered.filter((x) => x.value != "0");

      if (assignedShip.length > 0 && totalShipList.length == assignedShip.length) {
        assignedShip.unshift(defaultAllItem);
      }

      this.setState(
        {
          clientUserId: response.data.clientUserId,
          clientId: response.data.clientId,
          clientUserName: response.data.userName,
          clientUserPassword: response.data.password,
          profilePictureUrl: response.data.clientProfilePictureUrl,
          clientUserEmail: response.data.emailAddress,
          clientUserContactNo: response.data.contactNumber,
          dropdown_clientRole: response.data.roleId,
          roleId: response.data.roleId,
          dropdown_clientServiceCategory: response.data.serviceCategoryID,
          shipNames: response.data.shipNames,
          clientUserPIC: response.data.picname,
          dropdown_ship: response.data.shipList,
          profilePictureFileMediaId: response.data.profilePictureFileMediaId,
          createdOn: response.data.createdOn,
          createdBy: response.data.createdBy,
          isLoading: false,
        },
        async () => {
          AddLocalStorageData("isDirty", false)
          await this.getClientAndDefaultAccessPermissions(
            this.state.dropdown_clientRole, false
          );
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
      });
    }
  };

  /* <summary>
 date: 24/01/2023
 Name: SYJ
 description: on change password click, handled showing system generated password
 <summary> */

  onChangePasswordClick = async () => {
    try {
      var setUsername = false;
      this.setState({ isLoading: true });

      var userData = GetLocalStorageDataWithParse("loggedInUser");
      var roleid = 0;
      var username = "";
      if (userData != null) {
        roleid = userData.typeId;
        username = userData.name;
      }
      var response = await ClientService.GenerateNewClientUserPassword(
        this.state.clientUserId,
        roleid,
        username
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200 && response.data != null) {
          if (setUsername) {
            this.setState({ clientUserName: "" });
          }
          this.setState({
            clientUserPassword: response.data.password,
            isLoading: false,
            showChangePasswordConfirmationModal: false,
          });
        }
      } else {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
    } catch (error) { }
  };

  onDropDownSelected = (e) => {
    var { name, value } = e.target;
    const { showErrorMessage } = this.state;
    this.setState(
      {
        [name]: value,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (showErrorMessage) this.validate();
      }
    );
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  isValidFile = (value) => {
    if (!value) return false;

    //regex to get file extension
    var re = /(?:\.([^.]+))?$/;
    //second item in the array will be the extension
    let ext = re.exec(value)[1];
    return validFileFormats[0].includes(ext);
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  onAttachmentChange = async (e) => {
    if (!isValidFile(e.target.value, validFileFormats)) {
      if (this.state.filePath == "") {
        this.setState({
          fileInputKey: generateRandomString,
          documentFile: null,
          errorFile: true,
        });
        AddLocalStorageData("isDirty", true);
      }

      //HV - 06/06/2023 - if after attaching correct file, wrong file is attached then retain the older file but show error message
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileTypeError,
      })

      return;
    }
    AddLocalStorageData("isDirty", true);
    let files = e.target.files[0];

    this.setState({
      errorFile: false,
      documentFile: files,
      profilePictureUrl: URL.createObjectURL(files),
      isProfileImgDeleted: false
    });
  };

  /* <summary>
date: 14/02/2023
Name: HV
description: on username change
<summary> */

  onUsernameChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage, usernameExistError } = this.state;

    this.setState({ [name]: value }, () => {
      AddLocalStorageData("isDirty", true);
      if (showErrorMessage || usernameExistError) {
        this.validate();
      }
    });
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage, showEmailValidationErrorMessage, emailExistError, shortEmployeeNameError } = this.state;
    let errors = this.state.errorMessages;
    this.setState(
      {
        [name]: value,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (name === "clientUserContactNo") {
          if (this.validatePhoneNumber(e)) {
            this.setState(
              {
                [name]: value,
              },
              () => {
                if (showErrorMessage || showEmailValidationErrorMessage) {
                  this.validate();
                }
              }
            );
          } else {
            this.setState(
              {
                [name]: "",
              },
              () => {
                if (showErrorMessage || showEmailValidationErrorMessage || emailExistError) {
                  this.validate();
                }
              }
            );
          }
        }
        if (showErrorMessage || showEmailValidationErrorMessage || emailExistError || shortEmployeeNameError) {
          this.validate();
        }
      }
    );
  };

  showChangePasswordConfirmationModal = () => {
    this.setState({
      showChangePasswordConfirmationModal: true,
    });
  };

  /* <summary>
   date: 24/01/2023
   Name: SYJ
   description: Allow only numbers, spaces and Brackets
   <summary> */
  validatePhoneNumber = (e) => {
    const { name, value } = e.target;

    if (NUMBER_REGEX.test(value)) {
      return true;
    } else {
      this.setState({
        [name]: "",
      });
      e.preventDefault();
    }
  };

  validate = () => {
    const {
      clientUserName,
      errorMessages,
      clientUserEmail,
      clientUserPassword,
      clientUserContactNo,
      clientUserPIC,
      dropdown_clientRole,
      customClientRolesPermissions,
      dropdown_clientServiceCategory,
    } = this.state;

    let errors = errorMessages;
    var userName = "";
    userName = clientUserPIC;

    errors["clientUserEmptyEmailValidation"] = isStringEmpty(clientUserEmail)
      ? "true"
      : "";
    errors["clientUserEmailValidation"] = !isEmailValid(clientUserEmail.trim())
      ? "true"
      : "";
    errors["clientUserUserNameValidation"] = isStringEmpty(clientUserName)
      ? "true"
      : "";
    errors["clientUsePasswordValidation"] =
      !isPasswordValid(clientUserPassword) ||
        isStringEmpty(clientUserPassword) ||
        clientUserPassword.length < 8
        ? "true"
        : "";
    errors["clientUserContactNumberValidation"] = isStringEmpty(
      clientUserContactNo
    )
      ? "true"
      : "";
    errors["clientUserPICValidation"] = isStringEmpty(clientUserPIC) || userName.trim().length < 3 ? "true" : "";

    errors["clientUserDropDownServiceCategoryValidation"] =
      dropdown_clientServiceCategory == "" ||
        dropdown_clientServiceCategory == undefined ||
        dropdown_clientServiceCategory == 0
        ? "true"
        : "";
    errors["clientUserDropDownRoleValidation"] =
      dropdown_clientRole == "" ||
        dropdown_clientRole == undefined ||
        dropdown_clientRole == 0 ||
        (dropdown_clientRole == 5 && customClientRolesPermissions.length == 0)
        ? "true"
        : "";

    if (
      errors["clientUserUserNameValidation"] != "" ||
      errors["clientUsePasswordValidation"] != "" ||
      errors["clientUserContactNumberValidation"] != "" ||
      // errors["clientUserPICValidation"] != "" ||
      errors["clientUserDropDownServiceCategoryValidation"] != "" ||
      errors["clientUserDropDownRoleValidation"] != "" ||
      errors["clientUserEmptyEmailValidation"] != ""
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: true,
        showEmailValidationErrorMessage:
          errors["clientUserEmptyEmailValidation"] == "" &&
            errors["clientUserEmailValidation"] != ""
            ? true
            : false,
      });

      return true;
    }
    if (errors["clientUserEmailValidation"] != "") {
      this.setState({
        errorMessages,
        showEmailValidationErrorMessage: true,
        shortEmployeeNameError: false,
        showErrorMessage: false,
      });
      return true;
    }

    if (errors["clientUserPICValidation"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: false,
        shortEmployeeNameError: true,
        showErrorMessageEmail: false,
      });
      return true;
    }

    this.setState({
      errorMessages: errors,
      isLoading: false,
      showEmailValidationErrorMessage: false,
      shortEmployeeNameError: false,
      showErrorMessage: false,
      usernameExistError: false,
      emailExistError: false
    });

    return false;
  };

  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  goback = () => {
    var isDirty = GetLocalStorageDataWithoutParse("isDirty");
    var loc = GetLocalStorageDataWithoutParse("location");
    if (isDirty === "true")
      this.setState({ showDirtyPopup: true, path: this.state.isClientUser ? "/ClientDashboard" : "/ManageClientUsers" });
    else {
      if (loc == "/ActivityLog") {
        this.props.navigate("/ActivityLog");
      }
      if (loc == "/AddEditClient") {
        this.props.navigate("/AddEditClient");
      }
      else {
        this.props.navigate(this.state.isClientUser ? "/ClientDashboard" : "/ManageClientUsers");
      }
    }
    //this.props.navigate(this.state.isClientUser ? "/ClientDashboard" : "/ManageClientUsers");
  };

  goToRolesPermission = () => {
    const { dropdown_clientRole } = this.state;

    if (dropdown_clientRole == 5)
      this.setState({ rolesPermissionVisible: true, isDataEditable: false })
    else
      this.setState({ viewRolesPermissionVisible: true });
    // AddLocalStorageData('location', '/AddEditClientUser');
    // this.props.navigate("/ManageClientRolesAndPermission");
  };

  onShipDropdownChange = (option) => {
    var dropdown_ship = [];

    if (option.length > 0) {
      option
        // .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_ship.push(item);
        });
    }
    AddLocalStorageData("isDirty", true);
    this.setState({ dropdown_ship });
  };

  /* <summary>
  date: 15/03/2023
  Name: HV
  description: on request new password clicked
  <summary> */

  onRequestNewPasswordClick = () => {
    this.setState({
      showChangePasswordModal: true,
      requestType: "Change Password",
    });
  };

  onCloseChangeRequestModalClick = () => {
    this.setState({ showChangePasswordModal: false, requestType: "" });
  };

  onShowProceedModalClick = () => {
    this.setState({ showProceedModal: true });
  };

  onProceeModalCloseClick = () => {
    this.setState({
      showProceedModal: false,
    });
  };

  onModalCloseClick = () => {
    this.setState({
      showChangePasswordConfirmationModal: false,
      showProceedModal: false,
    });
  };

  /* <summary>
date: 01/03/2023
Name: HV
description: on change request click
<summary> */

  SaveClientUserChangeRequestData = async () => {
    const { userId, clientUserId, requestType } = this.state;

    this.setState({ isLoading: true, showProceedModal: false });
    let clientUserChangeRequestData = new FormData();
    clientUserChangeRequestData.append("RequestType", requestType);
    clientUserChangeRequestData.append("EditedBy", userId);
    clientUserChangeRequestData.append("ClientUserId", clientUserId);
    clientUserChangeRequestData.append("ModuleName", "Client");
    clientUserChangeRequestData.append("NavigateTo", "Client User Details");

    var responseSaved = await ClientService.SaveClientUserChangeRequestData(
      clientUserChangeRequestData
    );

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.Client.changeRequestSent,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        },
        () => {
          this.onCloseChangeRequestModalClick();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  /* <summary>
   date: 20/06/2023
   Name: SYJ
   description: Delete profile image
   <summary> */
  onDeleteProfilePic = () => {
    if (!this.state.userRolePermissionRead) {
      this.setState({
        showDeleteProfileModal: true,
      });
    }
  };

  onDeleteProfileModalYesClick = () => {
    AddLocalStorageData("isDirty", true);
    this.setState({
      profilePictureUrl: null,
      isProfileImgDeleted: true,
      showDeleteProfileModal: false,
      toastVisible: true,
      toastMessage: helperText.global.deletedProfile,
      toastType: helperText.global.toastSuccess,
    })
  };

  onDeleteProfileModalCloseClick = () => {
    this.setState({
      showDeleteProfileModal: false,
      isProfileImgDeleted: false,
    });
  };

  deleteClientUserProfileImage = async (userId, userTypeId, loggedUserId) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteProfileModal: false,
      });
      var response = await UserService.DeleteProfileImage(userId, userTypeId, loggedUserId);
      if (response != undefined) {
        await this.loadUserData();

        if (userTypeId == UserRole.CLIENTUSER && this.state.userId != 1 && this.state.userId != 2 && this.state.userId != 3 && this.state.userId != 4
          && this.state.userId != 6 && this.state.userId != 5) {
          AddLocalStorageData("loggedInUserPic", null);
        }

        this.setState(
          {
            profilePictureUrl: null,
            toastVisible: true,
            toastMessage: helperText.global.deletedProfile,
            toastType: helperText.global.toastSuccess,
            documentFile: null,
            fileInputKey: generateRandomString(),
          });
      } else {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };


  render() {
    const {
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      fileInputKey,
      isEditable,
      showPassword,
      profilePictureUrl,
      defaultRolesPermissions,
      rolesPermissionVisible,
      dropdown_inheritedClientUserRoleId,
      clientRolesPermissions,
      clientUserName,
      clientUserId,
      errorMessages,
      clientRoleList,
      clientServiceCategoryList,
      shipNames,
      showErrorMessage,
      clientUserEmail,
      showEmailValidationErrorMessage,
      clientUserPassword,
      clientUserContactNo,
      clientUserPIC,
      dropdown_clientRole,
      dropdown_clientServiceCategory,
      dropdownList_shipList_Filtered,
      dropdown_ship,
      defaultClientRolePermission,
      roleId,
      isClientUser,
      showProceedModal,
      showChangePasswordModal,
      isAddClientUser,
      viewRolesPermissionVisible,
      showChangePasswordConfirmationModal,
      shortEmployeeNameError,
      showDeleteProfileModal,
      isDataEditable
    } = this.state;
    return (
      <div>
        {isLoading && <Loader />}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showChangePasswordModal && (
          <ClientChangeRequestModal
            onCloseClick={this.onCloseChangeRequestModalClick}
            modalTitle={"Request admin to setup new password"}
            clientEmailId={clientUserEmail}
            onChangeRequestClick={this.onShowProceedModalClick}
          />
        )}

        {showChangePasswordConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onChangePasswordClick}
            modalText={"change password? An email will be sent to the user with a new password after clicking the Yes button."}
          />
        )}

        {showProceedModal && (
          <ProceedModal
            showProceedModal={showProceedModal}
            onNoClick={this.onProceeModalCloseClick}
            onYesClick={this.SaveClientUserChangeRequestData}
          />
        )}

        {rolesPermissionVisible && (
          <RolesPermissionModal
            roleId={dropdown_clientRole}
            showModal={rolesPermissionVisible}
            clientRoleList={this.state.clientRoleList}
            clientRolesPermissions={clientRolesPermissions}
            defaultRolesPermissions={defaultRolesPermissions || []}
            isDataEditable={isDataEditable}
            defaultClientRolePermission={defaultClientRolePermission}
            dropdown_inheritedClientLevelID={dropdown_inheritedClientUserRoleId}
            ApplyCustomPermissions={this.ApplyCustomPermissions}
            closeModal={this.closeUserPermissionsModal}
          />
        )}

        {viewRolesPermissionVisible && (
          <ClientRolesNPermissionModal
            showModal={viewRolesPermissionVisible}
            closeModal={this.closeUserPermissionsModal}
          />
        )}

        {showDeleteProfileModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteProfileModalCloseClick}
            onYesClick={this.onDeleteProfileModalYesClick}
            onNoClick={this.onDeleteProfileModalCloseClick}
            modalheader="Delete Profile Image"
          ></DeleteModal>
        )}

        <main className="page-height">
          <section className="communications manage-users editclientuser">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 heading-bottom-space">
                  <div className="manage-users-title">
                    <img
                      className="back-btn-responsive"
                      src="images/back button image.png"
                      height="22px"
                      style={{ pointerEvents: "all" }}
                      onClick={this.goback}
                    />
                    <span className="title-font">
                      {isClientUser
                        ? "Profile Details"
                        : clientUserId == 0 ||
                          clientUserId == undefined ||
                          clientUserId == null
                          ? "Add Client User"
                          : "Edit Client User"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="add-internal-class">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="admin-profile-detail-img edit-client-profile-img">
                      <div className="admin-profile-detail-bg-img">
                        {profilePictureUrl == "" ||
                          profilePictureUrl == undefined ? (
                          <img
                            src={"images/add-photo.png"}
                            className="admin-profile"
                            style={{ objectFit: "contain" }} //SYJ - Fit image to the Contain Fix for https://jiraapp.edotteam.com/browse/IHMM-114
                          />
                        ) : (
                          <img
                            src={profilePictureUrl}
                            className="admin-profile"
                            style={{ objectFit: "contain" }}
                          />
                        )}
                        {profilePictureUrl != "" && profilePictureUrl != undefined && profilePictureUrl != null ? (
                          <div className="admin-profile-icon">
                            <label style={{ left: "66%", top: "53%" }}>
                              <img
                                title={"Delete"}
                                src={deleteIcon}
                                alt="profile-delete"
                                className="img-fluid cursor-class test-Pankaj"
                                style={{ width: "15px", height: "20px", opacity: this.state.userRolePermissionRead ? "0.6" : "1" }}
                                onClick={this.onDeleteProfilePic}
                                disabled={this.state.userRolePermissionRead}
                              />
                            </label>
                          </div>) : null}

                        <div className="admin-profile-icon">
                          <label htmlFor="myfile">
                            <img
                              title={
                                profilePictureUrl == "" ||
                                  profilePictureUrl == undefined
                                  ? "Add"
                                  : "Edit"
                              }
                              src={
                                profilePictureUrl == "" ||
                                  profilePictureUrl == undefined
                                  ? addProfileIcon
                                  : editIcon
                              }
                              alt="profile-edit "
                              className="img-fluid cursor-class"
                              style={{
                                opacity: this.state.userRolePermissionRead
                                  ? "0.6"
                                  : "1",
                              }}
                            />
                          </label>
                          <input
                            type="file"
                            accept={validFileFormatsReadable}
                            id="myfile"
                            name="clientuserProfileFile"
                            onChange={this.onAttachmentChange}
                            key={fileInputKey || ""}
                            style={{ display: "none", visibility: "none" }}
                            disabled={this.state.userRolePermissionRead}
                          />
                        </div>
                      </div>
                      <div style={{ minHeight: 38 }}>
                        <h2>{clientUserPIC}</h2>
                      </div>
                    </div>
                    <div className="admin-profile-login-detail admin-client-user-details">
                      <UserCredentials
                        userId={clientUserId}
                        username={clientUserName}
                        password={clientUserPassword}
                        name="clientUserName"
                        passwordName="clientUserPassword"
                        onPasswordButtonClick={
                          isClientUser
                            ? this.onRequestNewPasswordClick
                            : this.showChangePasswordConfirmationModal //this.onChangePasswordClick
                        }
                        showPassword={showPassword}
                        togglePassword={this.togglePassword}
                        label={
                          isClientUser
                            ? "Request New Password"
                            : "Change Password"
                        }
                        onTextChange={this.onUsernameChange}
                        errorPassword={
                          errorMessages.clientUsePasswordValidation
                        }
                        errorUsername={
                          errorMessages.clientUserUserNameValidation
                        }
                        disabled={
                          isClientUser || this.state.userRolePermissionRead
                        }
                        disabledButton={clientUserId == 0 || this.state.userRolePermissionRead}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="editinternaldetails editclientuserdetails">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class "
                                      : "label-class required-field"
                                  }
                                >
                                  Client PIC:
                                </h5>
                              </div>
                              <div className="col-md-9">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.clientUserPICValidation
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="clientUserPIC"
                                    name="clientUserPIC"
                                    value={clientUserPIC}
                                    onChange={this.onTextChange}
                                    maxLength={100}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.clientUserPICValidation
                                  ) && (
                                      <div className="invalid-message-icon">
                                        <img
                                          src="images\icon material-error.png"
                                          alt="invalid"
                                        ></img>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    isClientUser ||
                                      this.state.userRolePermissionRead
                                      ? "label-class"
                                      : "label-class required-field"
                                  }
                                >
                                  Email Address:
                                </h5>
                              </div>
                              <div className="col-md-9">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.clientUserEmptyEmailValidation
                                    ) ||
                                      !isStringEmpty(
                                        errorMessages.clientUserEmailValidation
                                      )
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    className="form-control"
                                    type="mail"
                                    autoComplete="new-clientUserEmail"
                                    id="clientUserEmail"
                                    name="clientUserEmail"
                                    value={clientUserEmail}
                                    onChange={this.onTextChange}
                                    disabled={
                                      isClientUser ||
                                      this.state.userRolePermissionRead
                                    }
                                    maxLength={100}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.clientUserEmailValidation
                                  ) && (
                                      <div className="invalid-message-icon">
                                        <img
                                          src="images\icon material-error.png"
                                          alt="invalid"
                                        ></img>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5
                                  className={
                                    this.state.userRolePermissionRead
                                      ? "label-class "
                                      : "label-class required-field"
                                  }
                                >
                                  Contact Number:
                                </h5>
                              </div>
                              <div className="col-md-9">
                                <div
                                  className={
                                    !isStringEmpty(
                                      errorMessages.clientUserContactNumberValidation
                                    )
                                      ? "invalid-position"
                                      : ""
                                  }
                                >
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoFocus={false}
                                    autoComplete={"off"}
                                    id="clientUserContactNo"
                                    name="clientUserContactNo"
                                    value={clientUserContactNo}
                                    onChange={this.onTextChange}
                                    maxLength={20}
                                    disabled={this.state.userRolePermissionRead}
                                  />
                                  {!isStringEmpty(
                                    errorMessages.clientUserContactNumberValidation
                                  ) && (
                                      <div className="invalid-message-icon">
                                        <img
                                          src="images\icon material-error.png"
                                          alt="invalid"
                                        ></img>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="pb-3">
                            <div className="ship-detail-pb">
                              <div className="col-lg-12" style={{ paddingTop: "5px" }}>
                                <div className="row">
                                  <div className="col-md-3 align-self-class">
                                    <h5
                                      className={
                                        isClientUser ||
                                          this.state.userRolePermissionRead
                                          ? "label-class"
                                          : "label-class required-field"
                                      }
                                    >
                                      Service Category:
                                    </h5>
                                  </div>
                                  <div className="col-lg-9 col-md-9 col-sm-9 data">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.clientUserDropDownServiceCategoryValidation
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      {/* <Dropdown
                                        dropdownLabel=""
                                        name="clientServiceCategory"
                                        onChange={this.onDropDownSelected}
                                        selected={
                                          dropdown_clientServiceCategory
                                        }
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "pre",
                                          overflow: "hidden",
                                        }}
                                        disabled={isClientUser}
                                      >
                                        {clientServiceCategoryList == null
                                          ? null
                                          : clientServiceCategoryList.map(
                                              (item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              )
                                            )}
                                        ;
                                      </Dropdown> */}
                                      <MultiSelectCheckbox
                                        options={clientServiceCategoryList}
                                        onChange={this.onCheckBoxSeelcted}
                                        disabled={
                                          isClientUser ||
                                          this.state.userRolePermissionRead
                                        }
                                      />
                                      {!isStringEmpty(
                                        errorMessages.clientUserDropDownServiceCategoryValidation
                                      ) && (
                                          <div className="invalid-message-icon">
                                            <img
                                              src="images\icon material-error.png"
                                              alt="invalid"
                                            ></img>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="pb-3">
                            <div className="ship-detail-pb">
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-md-3 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5
                                      className={
                                        isClientUser ||
                                          this.state.userRolePermissionRead
                                          ? "label-class"
                                          : "label-class required-field"
                                      }
                                    >
                                      Role:
                                    </h5>
                                  </div>
                                  <div className="col-lg-9 col-md-9 col-sm-9 data">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div
                                          className={
                                            !isStringEmpty(
                                              errorMessages.clientUserDropDownRoleValidation
                                            )
                                              ? "invalid-position"
                                              : ""
                                          }
                                          id={"clientRole"}
                                        >
                                          <Dropdown
                                            dropdownLabel=""
                                            name="clientRole"
                                            onChange={this.onDropDownSelected}
                                            selected={dropdown_clientRole}
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                            disabled={
                                              isClientUser ||
                                              this.state.userRolePermissionRead
                                            }
                                          >
                                            {clientRoleList == null
                                              ? null
                                              : clientRoleList.map(
                                                (item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item.value}
                                                  >
                                                    {item.label}
                                                  </option>
                                                )
                                              )}
                                            ;
                                          </Dropdown>
                                          {dropdown_clientRole > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"clientRole"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {clientRoleList != null && clientRoleList.filter(x => x.value == dropdown_clientRole)[0]?.label}
                                            </UncontrolledTooltip>) : null}
                                          {!isStringEmpty(
                                            errorMessages.clientUserDropDownRoleValidation
                                          ) && (
                                              <div className="invalid-dropdown-icon">
                                                <img
                                                  src="images\icon material-error.png"
                                                  alt="invalid"
                                                ></img>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      {dropdown_clientRole == 5 &&
                                        !isClientUser && !this.state.userRolePermissionRead ? (
                                        <div className="col-lg-4 col-md-12 col-sm-12 data"
                                          style={{ alignSelf: "center" }}>
                                          <button
                                            className="btn roles-permissions-btn"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#clientrolespermission"
                                            onClick={() => this.getClientAndDefaultAccessPermissions(this.state.dropdown_clientRole, true)}
                                            style={{ height: "36px" }}
                                          >
                                            Set Roles & Permissions
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="col-xl-6 col-md-5"
                                          style={{ alignSelf: "center" }}>
                                          <a
                                            onClick={() =>
                                              this.goToRolesPermission()
                                            }
                                          >
                                            View Roles and Permissions
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="pb-3">
                            <div className="row">
                              <div className="col-md-3"
                                style={{ alignSelf: "center" }}>
                                <h5 className="label-class">Assign Ship:</h5>
                              </div>
                              <div className="col-md-9">
                                {/* <label type="name" className="form-control" disabled style={{ height: shipNames == "" || shipNames == undefined ? '40px' : 'auto' }} >{shipNames}</label> */}
                                <MultiSelectDropdown
                                  selectedOptions={
                                    dropdown_ship == undefined
                                      ? []
                                      : dropdown_ship
                                  }
                                  options={
                                    dropdownList_shipList_Filtered == undefined
                                      ? []
                                      : dropdownList_shipList_Filtered
                                  }
                                  onChange={this.onShipDropdownChange}
                                  isMulti={true}
                                  disabled={
                                    isClientUser ||
                                    this.state.userRolePermissionRead
                                  }
                                />
                              </div>
                              <div className="col-md-3" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 otherprofile-fixed">
                          <div className="admin-ship-settings-save-btn text-center">
                            {showErrorMessage && (
                              <div className="invalid-message-icon-box">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                                <p>Please fill in all the mandatory fields.</p>
                              </div>
                            )}
                            {showEmailValidationErrorMessage && (
                              <div className="invalid-message-icon-box">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                                <p>Please enter a valid email address.</p>
                              </div>
                            )}
                            {shortEmployeeNameError && (
                              <div className="invalid-message-icon-box">
                                <img
                                  src="images/icon material-error.png"
                                  alt="invalid-icon"
                                />
                                <p>
                                  Client PIC must be atleast 3 characters long.
                                </p>
                              </div>
                            )}
                            <button
                              type="button"
                              className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn"
                              onClick={() => this.onSaveClick()}
                              disabled={this.state.userRolePermissionRead}
                            >
                              Save
                            </button>

                            {clientUserId > 0 ? (
                              <button
                                type="button"
                                className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive add-user-btn manage-users-btn"
                                onClick={this.onSendEmail}
                                disabled={
                                  isClientUser ||
                                  this.state.userRolePermissionRead
                                }
                              >
                                Send Email
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.showDirtyPopup && (
                <DirtyModal
                  onModalCloseClick={this.onDirtyModalCloseClick}
                  onProceedClick={this.onProceedClick}
                />
              )}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
export default withRouter(AddEditClientUser);
