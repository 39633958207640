import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/Login/Login";
import UserProfile from "./pages/Profile/UserProfile";
import Communication from "./pages/Communication/Communication";
import CommunicationHistory from "./pages/Communication/CommunicationHistory";
import Test from "./pages/DemoTest/Test";
import ManageUsers from "./pages/Users/ManageUsers";
import Training from "./pages/Training/Training";
import MiscellaneousOperations from "./pages/MiscellaneousOperations/MiscellaneousOperations";
import ChartExample from "./pages/Statistics/ChartExample";
import Statistics from "./pages/Statistics/Statistics";
import AddUser from "./pages/Users/AddUser";
import ManageClient from "./pages/Client/ManageClient";
import AddEditClient from "./pages/Client/AddEditClient";
import ManageSuppliers from "./pages/Suppliers/ManageSuppliers";
import ManageIHMDatabaseRegistry from "./pages/IHMDatabaseRegistry/ManageIHMDatabaseRegistry";
import ManageShips from "./pages/Ship/ManageShips";
import AddEditShip from "./pages/Ship/AddEditShip";
import AddEditSupplier from "./pages/Suppliers/AddEditSupplier";
import OutlookTest from "./pages/DemoTest/OutlookTest";
import TrackingTool from "./pages/TrackingTool/TrackingTool";
import AddEditTrackingTool from "./pages/TrackingTool/AddEditTrackingTool";
import TestTrackingToolScreen from "./pages/DemoTest/TestTrackingToolScreen";

import ContactUs from "./pages/OtherLinks/ContactUs";
import ManageRolesAndPermission from "./pages/Users/ManageRolesAndPermission";
import ManageClientRolesAndPermission from "./pages/Client/ManageClientRolesAndPermission";
import ImportPO from "./pages/TrackingTool/ImportPO";
import Dashboard from "./pages/Dashboard/Dashboard";
import MultipleCommunicationReminder from "./pages/TrackingTool/MultipleCommunicationReminder";
import ManageClientUsers from "./pages/Client/ManageClientUsers";
import AddEditClientUser from "./pages/Client/AddEditClientUser";
import ManageChangeRequest from "./pages/Client/ManageChangeRequest";
import ActivityLog from "./pages/Notifications/ActivityLog";
import Settings from "./pages/Settings/Settings";
import MDSDoC from "./pages/MDSDoC/MDSDoC";
import TermsAndConditions from "./pages/Login/TermsAndCondition";
import ClientDashboard from "./pages/ClientShipPortal/ClientDashboard";
import TrainingDocument from "./pages/ClientShipPortal/TrainingDocument";
import ActionRequired from "./pages/ActionRequired/ActionRequired";
import SupplierDashboard from "./pages/SupplierPortal/SupplierDashboard";
import SupplierDocument from "./pages/SupplierPortal/SupplierDocument";
import ShipCrewDashboard from "./pages/ClientShipPortal/ShipCrewDashboard";
import Reports from "./pages/Reports/Reports";
import SupplierViewItems from "./pages/SupplierPortal/SupplierViewItems";
import ClientDocuments from "./pages/ClientShipPortal/ClientDocuments";
import ShipDocument from "./pages/ClientShipPortal/ShipDocuments";
import SupplierPendingCompletedItems from "./pages/SupplierPortal/SupplierPendingCompletedItems";
import ManageStatistics from "./pages/Statistics/ManageStatistics";
import StatisticsReport from "./pages/Statistics/StatisticsReport";
import AddSuspectedHazmat from "./pages/TrackingTool/AddSuspectedHazmat";
import ErrorPage from "./pages/OtherLinks/ErrorComponent";
import { GetLocalStorageDataWithParse, AddLocalStorageData, GetLocalStorageDataWithoutParse } from "./helpers";
import packageJson from '../package.json';
import TestPage from "./pages/Test";

export default class App extends Component {
  static displayName = App.name;

  /* <summary>
    date: 22/08/2023
    Name: SYJ
    description: Clearing Cache.
    <summary> */
  componentDidMount = () => {
    let version = GetLocalStorageDataWithParse("version");
    
    const packageVersion = packageJson.version;
    if (version != undefined && version !== packageVersion) {
      AddLocalStorageData("version", JSON.stringify(packageJson.version));
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  }

  render() {
    return (
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Communication" element={<Communication />} />
        <Route exact path="/Reports" element={<Reports />} />
        <Route
          exact
          path="/CommunicationHistory"
          element={<CommunicationHistory />}
        />
        <Route exact path="/UserProfile" element={<UserProfile />} />
        <Route exact path="/ManageUsers" element={<ManageUsers />} />
        <Route exact path="/Training" element={<Training />} />
        <Route
          exact
          path="/MiscellaneousOperations"
          element={<MiscellaneousOperations />}
        />
        <Route exact path="/AddUser" element={<AddUser />} />
        <Route exact path="/ManageClient" element={<ManageClient />} />
        <Route exact path="/AddEditClient" element={<AddEditClient />} />
        <Route exact path="/Test" element={<Test />} />
        <Route exact path="/Statistics" element={<Statistics />} />
        <Route exact path="/ManageSuppliers" element={<ManageSuppliers />} />
        <Route
          exact
          path="/ManageIHMDatabaseRegistry"
          element={<ManageIHMDatabaseRegistry />}
        />
        <Route exact path="/ManageShips" element={<ManageShips />} />
        <Route exact path="/AddEditShip" element={<AddEditShip />} />
        <Route exact path="/AddEditSupplier" element={<AddEditSupplier />} />
        <Route exact path="/OutlookTest" element={<OutlookTest />} />
        <Route
          exact
          path="/TestTrackingToolScreen"
          element={<TestTrackingToolScreen />}
        />
        <Route exact path="/ContactUs" element={<ContactUs />} />
        <Route exact path="/TrackingTool" element={<TrackingTool />} />
        <Route
          exact
          path="/AddEditTrackingTool"
          element={<AddEditTrackingTool />}
        />
        <Route
          exact
          path="/ManageRolesAndPermission"
          element={<ManageRolesAndPermission />}
        />
        <Route
          exact
          path="/ManageClientRolesAndPermission"
          element={<ManageClientRolesAndPermission />}
        />
        <Route exact path="/ImportPO" element={<ImportPO />} />
        <Route exact path="/Dashboard" element={<Dashboard />} />
        <Route
          exact
          path="/MultipleCommunicationReminder"
          element={<MultipleCommunicationReminder />}
        />
        <Route
          exact
          path="/ManageClientUsers"
          element={<ManageClientUsers />}
        />
        <Route
          exact
          path="/AddEditClientUser"
          element={<AddEditClientUser />}
        />
        <Route
          exact
          path="/MultipleCommunicationReminder"
          element={<MultipleCommunicationReminder />}
        />
        <Route
          exact
          path="/ManageChangeRequest"
          element={<ManageChangeRequest />}
          component
        />
        <Route exact path="/ActivityLog" element={<ActivityLog />} component />
        <Route exact path="/ActionRequired" element={<ActionRequired />} component />

        <Route exact path="/Settings" element={<Settings />} />
        <Route exact path="/MDSDoC" element={<MDSDoC />} />
        <Route exact path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route exact path="/ClientDashboard" element={<ClientDashboard />} />
        <Route exact path="/TrainingDocument" element={<TrainingDocument />} />
        {/*<Route exact path="/ManageClientUsers" element={<ManageClientUsers/>} />*/}

        <Route exact path="/SupplierDashboard" element={<SupplierDashboard />} />
        <Route exact path="/SupplierDocument" element={<SupplierDocument />} />
        <Route exact path="/ShipCrewDashboard" element={<ShipCrewDashboard />} />
        <Route exact path="/SupplierViewItems" element={<SupplierViewItems />} />
        <Route exact path="/SupplierPendingCompletedItems" element={<SupplierPendingCompletedItems />} />

        <Route exact path="/ClientDocuments" element={<ClientDocuments />} />
        <Route exact path="/ShipDocument" element={<ShipDocument />} />

        <Route exact path="/ManageStatistics" element={<ManageStatistics />} />
        <Route exact path="/StatisticsReport" element={<StatisticsReport />} />
        <Route exact path="/AddSuspectedHazmat" element={<AddSuspectedHazmat />} />

        <Route component={Test} />
        {/* <Route exact path="/TestPage" element={<TestPage />}  /> */}
        <Route path="*" element={<ErrorPage />} /> 
      </Routes>


    );
  }
}
