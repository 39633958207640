import React from 'react';
import { useState } from "react";
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';

import { LoginService } from "../services/login.service";
import Logout from "../helpers/Logout";
import { TitlePageNames } from "../helpers/enums";
import { GetLocalStorageDataWithoutParse, changePageTitle } from "../helpers/utilities";

function LogoutModal(props) {

    const { instance, accounts } = useMsal();
    const [isOutlookChecked, setIsOutlookChecked] = useState(true);
    const isAuthenticated = useIsAuthenticated();

    const onNoClicked = () => {
        props.closeLogoutModal();
    }

    const onYesClicked = async () => {
        try 
        {
            props.setIsLoading(true);

            if(isAuthenticated && isOutlookChecked)
            {

                var data = {
                    userId: props.userId,
                    userTypeId: props.userTypeId,  
                    jwtToken: GetLocalStorageDataWithoutParse('SessionToken'),
                }
            
                await LoginService.LogoutUserService(data);

                localStorage.clear();
                changePageTitle(TitlePageNames.MAINTITLE)

                instance.logoutPopup({
                    postLogoutRedirectUri: "/",
                    mainWindowRedirectUri: "/",
                }).catch(e => {
                    console.log(e);
                    // props.showErrorToast(e);
                });
            }
            else
            {            
                var data = {
                    userId: props.userId,
                    userTypeId: props.userTypeId,  
                    jwtToken: GetLocalStorageDataWithoutParse('SessionToken'),
                }
            
                await LoginService.LogoutUserService(data);
                Logout(props.navigate);                        
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="deletedoc" style={{ display: "block" }}>
            <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
        <div className="modal" id="deleteDoc-modal" style={{ display: "block" }}>
            <div className="modal-dialog modal-l modal-dialog-centered modal-box">
                <div className="modal-content box">             
                    <div className="modal-body">
                        <div className="block1">
                            Are you sure you want to logout ?
                        </div>
                        <AuthenticatedTemplate>
                            <div style={{marginTop:10, marginLeft:50, display:'flex'}}>
                                <input type="checkbox" name="chkoutlook" id="chkoutlook"  defaultChecked={isOutlookChecked} onChange={(e) => setIsOutlookChecked(prev => !prev)}/>
						        <label style={{marginLeft:5}} htmlFor="chkoutlook">Log out of Outlook</label>
                            </div>
                        </AuthenticatedTemplate>                    
                        <div className="text-center yes-no-btn">
                        <div><button type="button" className="btn btn-background-color yes-btn "onClick={onYesClicked}>Yes</button></div>
                            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                            <div><button type="button" className="btn btn-background-color  yes-btn" onClick={onNoClicked}>No</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default LogoutModal;