import React from "react";
import DatePicker from "../../components/DatePickerComponent";
import { isStringEmpty } from "../../helpers/string-helper";

const SupplierOnHold = ({
  showModal,
  onSaveClicked,
  fromDate,
  toDate,
  fromDateChanged,
  toDateChanged,
  comments,
  onCommentsChange,
  onCloseClick,
  showOnHoldErrorMessage,
  errorMessages,
}) => {

  return showModal && (
    <div className="supplier_on_hold">
      <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
      <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
        <div className="modal-dialog modal-l modal-dialog-centered modal-box">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Set On Hold Period</h4>
              <button type="button" data-bs-dismiss="modal">
                <img
                  src="images/modal-close.png"
                  alt="modal-close"
                  onClick={onCloseClick}
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="row content">
                <div className="  col-lg-6 col-md-6 col-sm-12">
                  <div className={
                    !isStringEmpty(
                      errorMessages.onHoldFromDateValidation
                    )
                      ? "invalid-position"
                      : ""
                  }>
                    <label className="required-field" htmlFor="fromdate">From:</label>
                    <br />
                    <DatePicker
                      maxDate={toDate}
                      selectedDate={fromDate}
                      onDateChange={fromDateChanged}
                      isNotRoot={true}
                    >
                      {" "}
                    </DatePicker>
                    {!isStringEmpty(
                      errorMessages.onHoldFromDateValidation
                    ) && (
                        <div style={{ marginTop: "24px" }} className="invalid-message-icon">
                          <img
                            src="images\icon material-error.png"
                            alt="invalid"
                          ></img>
                        </div>
                      )}
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <div className={
                    !isStringEmpty(
                      errorMessages.onHoldToDateValidation
                    )
                      ? "invalid-position"
                      : ""
                  }>
                    <label className="required-field" htmlFor="todate">To:</label>
                    <br />
                    <DatePicker
                      minDate={fromDate}
                      selectedDate={toDate}
                      onDateChange={toDateChanged}
                      isNotRoot={true}
                    >
                      {" "}
                    </DatePicker>
                    {!isStringEmpty(
                      errorMessages.onHoldToDateValidation
                    ) && (
                        <div style={{ marginTop: "24px" }} className="invalid-message-icon">
                          <img
                            src="images\icon material-error.png"
                            alt="invalid"
                          ></img>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row content">
                <div className=" col-lg-12 col-md-12 col-sm-12">
                  <label htmlFor="todate">Comments:</label>
                  <br />
                  <input
                    type="name"
                    className="form-control"
                    id="comments"
                    name="comments"
                    value={comments}
                    onChange={onCommentsChange}
                  />
                </div>
              </div>

              <div
                className="invalid-message-icon-box"
                style={{ display: showOnHoldErrorMessage ? "" : "none" }}
              >
                <img src="images\icon material-error.png" alt="invalid"></img>
                <p>Please fill in all the mandatory fields.</p>
              </div>

              <div className="admin-ship-settings-save-btn text-center">
                <button
                  type="button"
                  className="btn btn-background-color save-btn"
                  onClick={onSaveClicked}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierOnHold;
