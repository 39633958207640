import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { MiscellaneousOperationsService } from "../../services/miscellaneousoperations.service";

import admindownload from "../../assets/images/admin-download.png";
import admintrackingdownload from "../../assets/images/admin-tracking-download.png";
import admineye from "../../assets/images/admin-eye.png";
import deleteIcon from "../../assets/images/delete icon.png";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import MobileDropdown from "../../components/Mobile/MobileDropdown";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import Toast from "../../components/Toast";
import UploadMiscellaneousFileModal from "./UploadMiscellaneousFileModal";
import ViewModal from "../../components/ViewModal";
import DeleteModal from "../../components/DeleteModel";
import { UserAction, Module } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";

class MiscellaneousOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedMODocs: [],
      defaultMODocs: [],
      dropdownList: [],
      userId: 0,
      // colDefs: this.gridColumns,
      isLoading: false,
      toastVisible: false,
      toastResponseType: "",
      toastMessage: "",
      showUploadModal: false,
      filePathToView: "",
      showViewModal: false,
      trainingDocIDList: [],

      dropdown_client: "0",
      dropdown_ship: "0",
      dropdown_region: "0",
      dropdown_serviceCategory: "0",
      lstShips: [],
      searchedText: "",
      showDeleteModal: false,
      rowIdToDelete: 0,
      accessList: [],
      defaultShipNameList: [],
      typeId: 0,
      gridApi: null,
      userRolePermissionRead: false,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState({
        typeId: userData.typeId,
        userId: userData.id,
        accessList: GetLocalStorageDataWithParse("userRolePermission"),
      }, () => {
        this.GetMiscellaneousOperationData();

      });
    }
  }

  /* componentDidUpdate() {
    if (this.state.gridApi) {
      this.state.gridApi.paginationGoToPage(0);
    }
  } */

  gridColumns = () => {
    return [
      {
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        unSortIcon: false,
        cellStyle: {
          cursor: "pointer"
        },
      },
      {
        headerName: "Serial Number",
        // field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        tooltipField: "serialNumber",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "Document Name",
        field: "customFileName",
        tooltipField: "customFileName",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Type",
        field: "documentType",
        tooltipField: "documentType",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Client Name",
        field: "client",
        tooltipField: "client",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Ship Name",
        field: "ship",
        tooltipField: "ship",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Region",
        field: "region",
        tooltipField: "region",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Service Category",
        field: "serviceCategory",
        tooltipField: "serviceCategory",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Project Number",
        field: "projectValue",
        tooltipField: "projectValue",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Created By",
        field: "createdByvalue",
        tooltipField: "createdByvalue",
      },
      {
        headerName: "Date of Creation",
        field: "creationDate",
        tooltipField: "creationDate",
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={admineye}
            title="View"
            onClick={() =>
              this.viewTrainingDocData(params.data.miscellaneousOperationsId)
            }
            width="20px"
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={admindownload}
            title="Download"
            onClick={() =>
              this.downloadTrainingDocData(params.data.miscellaneousOperationsId)
            }
            width="15px"
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        hide: this.state.userRolePermissionRead,
        cellRenderer: (params) => (
          //(this.state.defaultMODocs.length != 0 ? !(this.state.defaultMODocs.filter((x) => x.documentTypeId === params.data.documentTypeId).length === 1) : true) ?
          <img
            className="cursor-class"
            src={deleteIcon}
            title="Delete"
            style={{ opacity: this.state.userRolePermissionRead ? "0.6" : "1", cursor: "pointer", width: "13px" }}
            onClick={(e) => {
              this.state.userRolePermissionRead
                ? e.preventDefault
                : this.onDeleteModalOpenClick(
                  params.data.miscellaneousOperationsId
                );
            }}
          />
          // :
          // <></>
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
    ];
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }

  viewTrainingDocData = (id) => {
    var filterList = [];
    filterList = this.state.defaultMODocs.filter(
      (x) => x.miscellaneousOperationsId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };

  /* <summary>
  date: 07-02-2023
  Name: GM
  description: Added Code to open downloaded doc in different tab
  <summary>*/
  downloadTrainingDocData = async (id) => {
    try {
      let tFileIDlist = [];
      if (id == 0) {
        tFileIDlist = this.state.trainingDocIDList.map((t) => {
          return t;
        });
        // this.state.trainingDocIDList.forEach((element) => {
        //   var tId = element.trainingDocsId;
        //   tFileIDlist.push(tId);
        // });
      } else {
        tFileIDlist.push(id);
      }
      if (tFileIDlist.length == 0) {
        this.setState({
          toastVisible: true,
          toastResponseType: helperText.global.toastError,
          toastMessage: helperText.global.noDocumentsSelected,
        });
        return;
      }

      this.setState({
        isLoading: true,
      });
      const response =
        await MiscellaneousOperationsService.DownloadMiscellaneousDoc(
          tFileIDlist
        );
      this.setState({
        isLoading: false,
      });
      if (response.data != null && response.data != undefined) {
        response.data.forEach((element) => {
          var fname = element.split("/").pop();
          var a = document.createElement("a");
          a.id = fname;
          a.href = element;
          a.download = fname;
          a.style.display = "none";
          a.target = "blank";
          document.body.appendChild(a);
          a.click();
        });

        this.setState({
          toastVisible: true,
          toastResponseType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastResponseType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
    } catch (error) { }
  };

  deleteMiscellaneousDocData = async (id) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteModal: false,
      });
      var response =
        await MiscellaneousOperationsService.DeleteMiscellaneousDocData(id);

      this.setState({
        isLoading: false,
      });
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
        });
      } else if (response != null) {
        var { miscellaneousOperationData, miscellaneousOperationDropdownList } =
          response.data;
        this.setState(
          {
            mappedMODocs: miscellaneousOperationData,
            defaultMODocs: miscellaneousOperationData,
            dropdownList: miscellaneousOperationDropdownList,
            toastVisible: true,
            toastMessage: helperText.global.deletedDocument,
            toastResponseType: helperText.global.toastSuccess,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };

  GetMiscellaneousOperationData = () => {
    this.setState({ isLoading: true, userRolePermissionRead: this.state.accessList != null && this.state.accessList.financeAndAccountAccess == UserAction.READ }, async () => {
      const response =
        await MiscellaneousOperationsService.GetMiscellaneousData(
          this.state.userId
        );
      if (response != null && response != undefined && response.status == 200) {
        var {
          miscellaneousOperationData,
          miscellaneousOperationData,
          miscellaneousOperationDropdownList,
        } = response.data;
        this.setState({
          mappedMODocs: miscellaneousOperationData,
          defaultMODocs: miscellaneousOperationData,
          dropdownList: miscellaneousOperationDropdownList,
          //lstShips: miscellaneousOperationDropdownList.shipList,
          defaultShipNameList: miscellaneousOperationDropdownList.shipList,
          isLoading: false,
        },
          () => {
            this.filterGridView();
          });
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
        });
      }
    });
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterGridView()
    );
  };

  filterGridView = () => {
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_region,
      dropdown_serviceCategory,
      defaultMODocs,
      dropdownList,
      searchedText,
    } = this.state;

    var filterList = [];
    var lstFilteredShips = [];
    var trainingList = defaultMODocs;
    if (
      dropdown_client == "0" &&
      dropdown_ship == "0" &&
      dropdown_region == "0" &&
      dropdown_serviceCategory == "0"
    ) {
      filterList = defaultMODocs;
      //lstFilteredShips = dropdownList.shipList;
    } else {
      var isFilterAdded = false;
      if (dropdown_client != "0") {
        filterList = trainingList.filter(
          (x) => x.clientId == parseInt(dropdown_client, 10)
        );

        // lstFilteredShips = dropdownList.shipList.filter((shiptItem) => {
        //   return filterList.some((clientItem) => {
        //     return clientItem.clientId === parseInt(dropdown_client);
        //   });
        // });
        lstFilteredShips = dropdownList.shipList.filter((x) => x.parentValue == parseInt(dropdown_client, 10));
        isFilterAdded = true;
      }

      if (dropdown_ship != "0") {
        filterList = isFilterAdded
          ? filterList.filter((x) => x.shipId == parseInt(dropdown_ship, 10))
          : trainingList.filter((x) => x.shipId == parseInt(dropdown_ship, 10));
        isFilterAdded = true;
      }

      if (dropdown_region != "0") {
        filterList = isFilterAdded
          ? filterList.filter((x) => x.regionId == parseInt(dropdown_region, 10))
          : trainingList.filter((x) => x.regionId == parseInt(dropdown_region, 10));
        isFilterAdded = true;
      }

      if (dropdown_serviceCategory != "0") {
        let finalArray = [];

        if (isFilterAdded) {
          filterList.map((x) => {
            let listCategory = x.serviceCategoryIdsList;
            return listCategory.map((y) => {
              if (parseInt(dropdown_serviceCategory, 10) == parseInt(y, 10)) {
                return finalArray.push(x);
              }
            });
          });
        } else {
          trainingList.map((x) => {
            let listCategory = x.serviceCategoryIdsList;
            return listCategory.map((y) => {
              if (parseInt(dropdown_serviceCategory, 10) == parseInt(y, 10)) {
                return finalArray.push(x);
              }
            });
          });
        }
        filterList = finalArray;
      }
    }
    if (searchedText == "") {
    } else {
      let value = searchedText.toString().toLowerCase();
      filterList = filterList.filter(
        (t) =>
          t.documentType.toString().toLowerCase().includes(value) ||
          t.customFileName.toString().toLowerCase().includes(value) ||
          t.client.toString().toLowerCase().includes(value) ||
          t.ship.toString().toLowerCase().includes(value) ||
          t.region.toString().toLowerCase().includes(value) ||
          t.serviceCategory.toString().toLowerCase().includes(value) ||
          t.projectValue.toString().toLowerCase().includes(value) ||
          t.applicableTo?.toString().toLowerCase().includes(value) ||
          t.createdByvalue?.toString().toLowerCase().includes(value) ||
          t.creationDate?.toString().toLowerCase().includes(value)
      );
    }
    this.setState({
      mappedMODocs: filterList,
      lstShips: lstFilteredShips,
    });
  };

  onSearch = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        searchedText: value,
      },
      () => {
        this.filterGridView();
        //this.searchFilter();
      }
    );
  };

  // searchFilter = () => {
  //   var { mappedMODocs, searchedText } = this.state;
  //   let value = searchedText.toString().toLowerCase();
  //   let filteredList = mappedMODocs.filter(
  //     (t) =>
  //       t.client?.toString().toLowerCase().includes(value) ||
  //       t.ship?.toString().toLowerCase().includes(value) ||
  //       t.region?.toString().toLowerCase().includes(value) ||
  //       t.documentType?.toString().toLowerCase().includes(value) ||
  //       t.customFileName?.toString().toLowerCase().includes(value) ||
  //       t.applicableTo?.toString().toLowerCase().includes(value)
  //   );

  //   this.setState({
  //     mappedMODocs: [...filteredList],
  //   });
  // };

  closeToast = () => {
    this.setState({ toastVisible: false, toastResponseType: "", toastMessage: "" });
  };

  toggleModal = (name, value) => {
    this.setState({ showUploadModal: false });
  };

  /* <summary>
      date: 30/01/2022
      Name: SYJ
      description: method invoked for adding training docs
      <summary> */
  onUploadFile = async (data) => {

    const { userId, dropdown_client, dropdown_year, typeId } = this.state;
    this.setState({
      showUploadModal: false,
      isLoading: true,
    });
    let moData = new FormData();
    moData.append("ClientID", data.dropdown_client);
    moData.append("ShipID", data.dropdown_ship);
    moData.append("DocumentTypeID", data.dropdown_documentType);
    moData.append("LogedInUserId", userId);
    moData.append("TFile", data.TFile);
    moData.append("Remark", data.remark);
    moData.append("DocumentName", data.documentName);
    moData.append("typeId", typeId);

    var responseSaved =
      await MiscellaneousOperationsService.AddMiscellaneousDocsData(moData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {

      const response =
        await MiscellaneousOperationsService.GetMiscellaneousData(this.state.userId);
      if (response != null && response != undefined && response.status == 200) {

        this.setState(
          {
            //mappedMODocs: response.data.miscellaneousOperationData,
            //defaultMODocs: response.data.miscellaneousOperationData,
            dropdownList: response.data.miscellaneousOperationDropdownList,
            toastVisible: true,
            toastMessage: helperText.MiscellaneousOperations.moDataSavedSuccess,
            toastResponseType: helperText.global.toastSuccess,
            isLoading: false,
          },
          () => {
            this.GetMiscellaneousOperationData();
          }
        );
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };
  onSaveNewTypeClick = async (typeName) => {

    this.setState({
      isLoading: true,
    });

    var docData = {
      TypeName: typeName,
      Module: Module.Miscellaneous
    }
    var responseSaved =
      await CommonService.AddNewDocumentType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data)
      const response =
        await MiscellaneousOperationsService.GetMiscellaneousData(
          this.state.userId
        );
      if (response != null && response != undefined && response.status == 200) {
        var {
          miscellaneousOperationData,
          miscellaneousOperationData,
          miscellaneousOperationDropdownList,
        } = response.data;

        this.setState({
          mappedMODocs: miscellaneousOperationData,
          defaultMODocs: miscellaneousOperationData,
          dropdownList: miscellaneousOperationDropdownList,
          defaultShipNameList: miscellaneousOperationDropdownList.shipList,
          isLoading: false,
        });
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
    });
  };

  viewPOFile = (filePath) => {
    this.setState({
      filePathToView: filePath,
      showViewModal: true,
    });
  };

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    })
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
  date: 07-02-2023
  Name: GM
  description: Fixed selected item id binding issue
  <summary>*/
  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = selectedRows.map((t) => {
        return t.miscellaneousOperationsId;
      });
      this.setState({
        trainingDocIDList: listSelectedTTId,
      });
    } else {
      this.setState({
        trainingDocIDList: [],
      });
    }
  };

  onViewFileDownload = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastResponseType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess
      })
    } catch (e) {
    }
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    } else {
      this.props.navigate("/Dashboard");
    }
  };

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_client: "0",
        dropdown_ship: "0",
        dropdown_region: "0",
        dropdown_serviceCategory: "0",
        searchedText: "",
      },
      () => {
        this.filterGridView();
      }
    );
  };
  onDeleteModalYesClick = () => {
    this.deleteMiscellaneousDocData(this.state.rowIdToDelete);
  };

  onDeleteModalCloseClick = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  onDeleteModalOpenClick = (id) => {
    this.setState({
      showDeleteModal: true,
      rowIdToDelete: id,
    });
  };
  render() {
    const {
      mappedMODocs,
      defaultMODocs,
      dropdownList,
      colDefs,
      isLoading,
      toastVisible,
      toastResponseType,
      toastMessage,
      showUploadModal,
      showViewModal,
      filePathToView,
      lstShips,
      dropdown_client,
      dropdown_region,
      dropdown_serviceCategory,
      dropdown_ship,
      showDeleteModal,
      rowIdToDelete,
      accessList,
      defaultShipNameList,
    } = this.state;
    const Buttons = (
      <div className="training-add-button" style={{ marginTop: "0px" }}>
        <div className="float-btn ClientSpecific-Document-Reports-button">
          <button
            type="button"
            disabled={
              accessList != null
                ? accessList.financeAndAccountAccess == UserAction.READ
                  ? true
                  : false
                : false
            }
            onClick={() => this.setState({ showUploadModal: true })}
            className="btn btn-background-color  manage-template-responsive btn-padding btn-responsive manage-roles-btn manage-users-btn"
          >
            Upload Document
          </button>
          {mappedMODocs.length > 0 ? (
            <span>
              <img
                title="Multi Download"
                className="cursor-class"
                src={admintrackingdownload}
                onClick={() => this.downloadTrainingDocData(0)}
                width="15px"
              />
            </span>
          ) : null}
        </div>
      </div>
    );

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"MiscellaneousOperations"} />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteModalCloseClick}
            onYesClick={this.onDeleteModalYesClick}
            onNoClick={this.onDeleteModalCloseClick}
            idToDelete={rowIdToDelete}
            modalheader="Delete Miscellaneous Item"
          ></DeleteModal>
        )}
        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          ></ViewModal>
        )}
        <main className="page-height">
          <section className="finance-accounts">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    <a onClick={this.goback}>
                      <img
                        className="back-btn-responsive"
                        src="images/back button image.png"
                        height="22px"
                      />
                    </a>{" "}
                    <h5>Miscellaneous Operations</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <Searchbar
                    placeholder={"Document Name, Service Category, etc..."}
                    onChange={this.onSearch}
                  />
                </div>
              </div>

              {/* mobile code */}
              <div className="accordion-filter-dropdown">
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="form-group dropdown-form form-drpdown-responsive manage-drpdown-responsive client-name">
                          <MobileDropdown
                            dropdownLabel="Client Name:"
                            name="client"
                            onChange={this.onFilterSelected}
                            selected={dropdown_client}
                          >
                            {dropdownList.clientList == null
                              ? null
                              : dropdownList.clientList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Ship Name:"
                            name="ship"
                            onChange={this.onFilterSelected}
                            selected={dropdown_ship}
                          >
                            {lstShips.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Region:"
                            name="region"
                            onChange={this.onFilterSelected}
                            selected={dropdown_region}
                          >
                            {dropdownList.regionList == null
                              ? null
                              : dropdownList.regionList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form form-drpdown-responsive manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Service Category:"
                            name="serviceCategory"
                            onChange={this.onFilterSelected}
                            selected={dropdown_serviceCategory}
                          >
                            {dropdownList.serviceCategories == null
                              ? null
                              : dropdownList.serviceCategories.map(
                                (obj, key) => (
                                  <option key={key} value={obj.value}>
                                    {obj.label}
                                  </option>
                                )
                              )}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 ">
                          <button
                            type="button"
                            className="btn btn-blue-bg "
                            onClick={this.clearAllFilter}
                            style={{ marginTop: 24 }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 doc">
                  Client Specific Document / Reports
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 ">{Buttons}</div>
              </div>
              {/* mobile code */}
              {/* desktop code */}
              <div className="Client Specific Document-Reports">
                <div className="desktop-class">
                  <div className="row mt-row">
                    <div className="col-12 col-md-4 col-lg-3">
                      <div className="form-group dropdown-form manage-drpdown-responsive" id={"clientName"}>
                        <Dropdown
                          dropdownLabel="Client Name:"
                          name="client"
                          onChange={this.onFilterSelected}
                          selected={dropdown_client}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.clientList == null
                            ? null
                            : dropdownList.clientList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_client > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"clientName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.clientList != null && dropdownList.clientList.filter(x => x.value == dropdown_client)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {/* <label className="label-class label-mobile">Client:</label> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 " id={"shipName"}>
                      <div className="form-group dropdown-form manage-drpdown-responsive" id={"shipName"}>
                        <Dropdown
                          dropdownLabel="Ship Name:"
                          name="ship"
                          onChange={this.onFilterSelected}
                          selected={dropdown_ship}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {lstShips.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                          ;
                        </Dropdown>
                        {dropdown_ship > 0 && dropdown_ship != undefined ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"shipName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {lstShips != null && lstShips.filter(x => x.value == dropdown_ship)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                      <div className="form-group dropdown-form manage-drpdown-responsive" id={"region"}>
                        <Dropdown
                          dropdownLabel="Region:"
                          name="region"
                          onChange={this.onFilterSelected}
                          selected={dropdown_region}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.regionList == null
                            ? null
                            : dropdownList.regionList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_region > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"region"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.regionList != null && dropdownList.regionList.filter(x => x.value == dropdown_region)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 ">
                      <div className="form-group dropdown-form manage-drpdown-responsive" id={"sc"}>
                        <Dropdown
                          dropdownLabel="Service Category:"
                          name="serviceCategory"
                          onChange={this.onFilterSelected}
                          selected={dropdown_serviceCategory}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.serviceCategories == null
                            ? null
                            : dropdownList.serviceCategories.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_serviceCategory > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"sc"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.serviceCategories != null && dropdownList.serviceCategories.filter(x => x.value == dropdown_serviceCategory)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 ">
                      <button
                        type="button"
                        className="btn btn-blue-bg "
                        onClick={this.clearAllFilter}
                        style={{ marginTop: 24 }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <div
                    className="row ClientSpecific-Document-Reports upload"
                    style={{ paddingTop: "20px" }}
                  >
                    <div className="col-lg-6 col-md-6 col-sm-12 doc">
                      Client Specific Document / Reports
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 ">
                      {Buttons}
                    </div>
                  </div>
                </div>
              </div>
              {/* desktop code */}

              {/* <div className="row table-mobile"> */}
              <div className="col-md-12 table-left-padding table-pl-responsive">
                <div className="table-responsive ">
                  <AGGrid
                    rowData={mappedMODocs}
                    colDefs={this.gridColumns()}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    onSelectionChanged={(e) => this.onSelectionChanged(e)}
                    pagination="10"
                    onSortChanged={(e) => this.onSortChanged(e)}
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>
        </main>
        {showUploadModal && (
          <UploadMiscellaneousFileModal
            onCloseClick={this.toggleModal}
            onUploadClick={this.onUploadFile}
            dropdownList={dropdownList}
            shipList={defaultShipNameList}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
          />
        )}

        <Footer />
      </>
    );
  }
}

export default withRouter(MiscellaneousOperations);
