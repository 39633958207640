import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { CommunicationService } from "../../services/communication.service";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import ViewHistoryModal from "../Communication/ViewHistoryModal.jsx";
import { helperText } from "../../helpers/helperText";
import Snackbar from "../../components/Snackbar";
import Loader from "../../components/Loader";
import {
  isStringEmpty,
  convertDate
} from "../../helpers/string-helper";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, GetLocalStorageDataWithoutParse } from "../../helpers/utilities";
import { withRouter } from "../../helpers";
import AgGrid from "../../components/AGGrid";
import viewIcon from "../../assets/images/Icon awesome-eye.png";
import Searchbar from "../../components/Searchbar";
import MobileDropdown from "../../components/Mobile/MobileDropdown";
import Logout from "../../helpers/Logout";
import DatePicker from "../../components/DatePickerComponent";
import { UncontrolledTooltip } from "reactstrap";

var currentDate = new Date();

class CommunicationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colDefs: this.gridColumns,
      historyDataInitial: [],
      historyData: [],
      toList: [],
      fromList: [],
      subjectList: [],
      emailTypeList: [],
      emailType: "",
      dropdown_toFilter: 0,
      dropdown_fromFilter: 0,
      dropdown_subjectFilter: "0",
      dropdown_emailTypeFilter: 0,
      dateFilter: "",
      textToSearch: "",
      toAddress: "",
      ccAddress: "",
      bccAddress: "",
      emailSubject: "",
      emailBody: "",
      attachedFileMedia: [],
      attachmentNames: [],
      showHistoryModal: false,
      toastVisible: false,
      isLoading: false,
      toastMessage: "",
      errorMessages: {},
      dateofbirth: "",
      userId: 0,
      gridApi: null,
      userTypeId: 0
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  gridColumns = [
    {
      headerName: "Serial Number",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "From",
      field: "fromAddress",
      cellRenderer: (params) => params.data.fromAddress,
      filter: "agTextColumnFilter",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "To",
      field: "toAddress",
      cellRenderer: (params) => params.data.toAddress,
      filter: "agTextColumnFilter",
      wrapText: true,
      tooltipField: "toAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Subject",
      field: "emailSubject",
      cellRenderer: (params) => params.data.emailSubject,
      filter: "agTextColumnFilter",
      wrapText: true,
      tooltipField: "emailSubject",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Type of Email",
      field: "emailType",
      cellRenderer: (params) => params.data.emailType,
      filter: "agTextColumnFilter",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Date",
      field: "createdOn",
      cellRenderer: (params) => params.data.createdOn,
      filter: "agNumberColumnFilter",
      wrapText: true,
    },
    {
      headerName: " ",
      field: "view",
      sortable: false,
      pinned: "right",
      width: 40,
      filter: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={viewIcon}
          style={{ width: "20px" }}
          data-bs-toggle="modal"
          data-bs-target="#view-history"
          onClick={() => this.onViewHistoryModal(params.data.communicationId)}
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
  ];

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Bind data on load
    <summary> */
  componentDidMount() {
    try {
      //HV - 20/02/2023 - changing browser title name
      changePageTitle("");
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      if (userData != null) {
        this.setState(
          {
            userId: userData.id,
            userTypeId: userData.typeId,
          },
          () => {
            this.GetAllCommunicationHistory();
          }
        );
      }
    } catch (error) { }
  }

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Get data of all communication to display
    <summary> */
  GetAllCommunicationHistory = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { userTypeId, userId } = this.state;

      var responseFilters = await CommunicationService.GetCommunicationHistory("All", userId, userTypeId);

      if (responseFilters == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (responseFilters != null) {
        if (responseFilters.status == 401) {
          Logout(this.props.navigate);
        } else if (responseFilters.status == 200) {
          let uniqueFromList = Array.from([
            ...new Set(responseFilters.data.map((l) => l.fromAddress)),
          ]);
          let uniqueToList = Array.from([
            ...new Set(
              responseFilters.data
                .map((l) => l.toAddress)
                .filter((x) => x != undefined && x != "")
            ),
          ]);
          let uniqueSubjectList = Array.from([
            ...new Set(responseFilters.data.map((l) => l.emailSubject)),
          ]);
          let uniqueEmailTypeList = Array.from([
            ...new Set(responseFilters.data.map((l) => l.emailType)),
          ]);
          this.setState({
            historyDataInitial: responseFilters.data,
            historyData: responseFilters.data,
            fromList: uniqueFromList.sort((a, b) => a.localeCompare(b)),
            toList: uniqueToList.sort((a, b) => a.localeCompare(b)),
            subjectList: uniqueSubjectList.sort((a, b) => a.localeCompare(b)),
            emailTypeList: uniqueEmailTypeList.sort((a, b) =>
              a.localeCompare(b)
            ),
            isLoading: false,
          });
        }
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      }
    } catch (error) { }
  };

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: On dropdown item selected
    <summary> */
  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterHistoryList()
    );
  };

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Filter by date
    <summary> */
  onDateSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        dateFilter: value,
      },
      () => this.filterHistoryList()
    );
  };

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Filter data by selected dropdowns
    <summary> */
  /* <summary>
  date: 3/02/2023
  Name: NGG
  description:fixed filter related issue with previous code
  <summary> */
  filterHistoryList = () => {
    const {
      dropdown_toFilter,
      dropdown_fromFilter,
      dropdown_subjectFilter,
      dropdown_emailTypeFilter,
      historyDataInitial,
      dateFilter,
    } = this.state;

    var filterList = [];
    var listToFilter = historyDataInitial;
    if (
      dropdown_toFilter == "0" &&
      dropdown_fromFilter == 0 &&
      dropdown_subjectFilter == "0" &&
      dropdown_emailTypeFilter == 0 &&
      dateFilter == ""
    ) {
      filterList = this.state.historyDataInitial;
    } else {
      var isFilterAdded = false;
      //To
      if (dropdown_toFilter != "0") {
        filterList =
          filterList.length > 0
            ? filterList.filter((e) => e.toAddress.includes(dropdown_toFilter))
            : listToFilter.filter((e) =>
              e.toAddress.includes(dropdown_toFilter)
            );
        isFilterAdded = true;
      }

      //Subject
      if (dropdown_subjectFilter != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.emailSubject == dropdown_subjectFilter)
          : listToFilter.filter(
            (e) => e.emailSubject == dropdown_subjectFilter
          );
        isFilterAdded = true;
      }
      //Email Type
      if (dropdown_emailTypeFilter != 0) {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.emailType == dropdown_emailTypeFilter)
          : listToFilter.filter((e) => e.emailType == dropdown_emailTypeFilter);
        isFilterAdded = true;
      }
      //From
      if (dropdown_fromFilter != "0") {
        filterList = isFilterAdded
          ? filterList.filter((e) => e.fromAddress == dropdown_fromFilter)
          : listToFilter.filter((e) => e.fromAddress == dropdown_fromFilter);
        isFilterAdded = true;
      }

      if (dateFilter != "") {
        let toDate = convertDate(dateFilter);

        filterList = isFilterAdded
          ? filterList.filter((e) => {
            let date = e.createdOn;
            return date == toDate;
          })
          : listToFilter.filter((e) => {
            let date = convertDate(e.createdOnDate);
            return date == toDate;
          });
        isFilterAdded = true;
      }
    }
    this.setState({
      historyData: filterList,
    });
  };

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Filter by the text searched
    <summary> */
  onSearchTextChange = (e) => {
    var inputValue = e.target.value;
    let listToSearch = this.state.historyDataInitial;
    if (!isStringEmpty(inputValue)) {
      inputValue = inputValue.toLowerCase();
      var filteredData = listToSearch.filter(
        (x) =>
          x.emailType.toLowerCase().includes(inputValue) ||
          x.emailSubject.toLowerCase().includes(inputValue) ||
          x.toAddress.toLowerCase().includes(inputValue) ||
          x.fromAddress.toLowerCase().includes(inputValue) ||
          x.createdOn != null && x.createdOn.toString().toLowerCase().includes(inputValue)
      );
    }

    this.setState({
      textToSearch: inputValue,
      historyData: inputValue == "" ? listToSearch : filteredData,
    });
  };

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Open view history and pass on data 
    <summary> */
  onViewHistoryModal = async (id) => {
    var responseFilters = this.state.historyDataInitial.filter(
      (x) => x.communicationId == id
    );
    if (responseFilters != null) {
      this.setState({
        toAddress: responseFilters[0]?.toAddress,
        ccAddress: responseFilters[0]?.ccaddress,
        bccAddress: responseFilters[0]?.bccaddress,
        emailSubject: responseFilters[0]?.emailSubject,
        emailBody: responseFilters[0]?.emailText,
        attachedFileMedia: responseFilters[0]?.attachedFileMedia,
        attachmentNames: responseFilters[0].attachedFileMedia.map(
          (l) => l.fileName
        ),
        showHistoryModal: true,
      });
    }
  };

  /* <summary>
    date: 17/11/2022
    Name: DN
    description: Delete the selected row from communication
    <summary> */
  onDeleteRowClicked = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });

      var responseFilters = await CommunicationService.DeleteCommunicationData(
        id,
        this.state.userId
      );
      if (responseFilters == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (responseFilters != null) {
        let uniqueFromList = Array.from([
          ...new Set(responseFilters.data.map((l) => l.fromAddress)),
        ]);
        let uniqueToList = Array.from([
          ...new Set(responseFilters.data.map((l) => l.toAddress)),
        ]);
        let uniqueSubjectList = Array.from([
          ...new Set(responseFilters.data.map((l) => l.emailSubject)),
        ]);
        let uniqueEmailTypeList = Array.from([
          ...new Set(responseFilters.data.map((l) => l.emailType)),
        ]);
        this.setState(
          {
            historyDataInitial: responseFilters.data,
            historyData: responseFilters.data,
            fromList: uniqueFromList.sort((a, b) => a.localeCompare(b)),
            toList: uniqueToList.sort((a, b) => a.localeCompare(b)),
            subjectList: uniqueSubjectList.sort((a, b) => a.localeCompare(b)),
            emailTypeList: uniqueEmailTypeList.sort((a, b) =>
              a.localeCompare(b)
            ),
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
 date: 13/02/2023
 Name: HV
 description: clearing filter
 <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_fromFilter: "0",
        dropdown_toFilter: "0",
        dropdown_subjectFilter: "0",
        dropdown_emailTypeFilter: "0",
        dateFilter: "",
        dateofbirth: "",
      },
      () => {
        this.filterHistoryList();
      }
    );
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  onDateChanged = (id, e) => {
    // const { errorMessages } = this.state;
    // let errors = errorMessages;
    if (id == "dateofbirth") {
      // var contractDate = convertDate(e);
      // errors["dateofbirth"] = contractDate == "" || contractDate == undefined ? "true" : "";
      this.setState(
        {
          dateFilter: e,
          dateofbirth: e,
        },
        () => this.filterHistoryList()
      );
    }
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/TrackingTool") {
      this.props.navigate("/TrackingTool");
    } else if (loc == "/MultipleCommunicationReminder") {
      this.props.navigate("/MultipleCommunicationReminder");
    } else if (loc == "/AddEditTrackingTool") {
      this.props.navigate("/AddEditTrackingTool");
    } else {
      this.props.navigate("/Communication");
    }

    AddLocalStorageData("location", null)
  };

  onGridReady = (params) => {

    params.api.sizeColumnsToFit();
    this.setState({
      gridApi: params.api,
    })
  }

  render() {
    const {
      isLoading,
      toastVisible,
      toastResponseType,
      toastMessage,
      toList,
      fromList,
      subjectList,
      emailTypeList,
      historyData,
      showHistoryModal,
      toAddress,
      ccAddress,
      bccAddress,
      emailSubject,
      emailBody,
      attachmentNames,
      historyColumnDefs,
      colDefs,
      dropdown_fromFilter,
      dropdown_toFilter,
      dropdown_subjectFilter,
      dropdown_emailTypeFilter,
    } = this.state;
    return (
      <div>
        <ViewHistoryModal
          ToData={toAddress}
          CCData={ccAddress}
          BCCData={bccAddress}
          SubjectData={emailSubject}
          MailBody={emailBody}
          Attachment={attachmentNames}
        />
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Snackbar
            showToast
            toastType={toastResponseType}
            message={toastMessage}
            CloseToast={this.closeToast}
          />
        )}

        <main className="page-height">
          <section className="communications communications-page">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8 heading-bottom-space">
                  <div className="page-title-className">
                    <img
                      className="back-btn-responsive"
                      src="images/back.png"
                      height="22px"
                      onClick={this.goback}
                    />
                    <span className="title-font">Communication History</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="searchBar">
                    <Searchbar
                      placeholder={"Name, Email, Subject, etc..."}
                      onChange={this.onSearchTextChange}
                    />
                    <button
                      id="searchQuerySubmit"
                      type="submit"
                      name="searchQuerySubmit"
                    >
                      <img src="images/magnifying-glass.png" width="27px" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="desktop-class">
                <div className="row communications-history-form">
                  <div className="col-lg-15 col-md-4 col-12">
                    <div className="form-group dropdown-form history-form-grp" id={"from"}>
                      <Dropdown
                        dropdownLabel="From:"
                        name="fromFilter"
                        onChange={this.onFilterSelected}
                        selected={dropdown_fromFilter}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {fromList == null
                          ? null
                          : fromList.map((item) => (
                            <option wrap="true" value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_fromFilter != undefined && dropdown_fromFilter != null && dropdown_fromFilter != 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"from"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdown_fromFilter}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-lg-15 col-md-4 col-12">
                    <div className="form-group dropdown-form history-form-grp" id={'to'}>
                      <Dropdown
                        dropdownLabel="To:"
                        name="toFilter"
                        onChange={this.onFilterSelected}
                        selected={dropdown_toFilter}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {toList == null
                          ? null
                          : toList.map((item) => (
                            <option wrap="true" value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_toFilter != undefined && dropdown_toFilter != null && dropdown_toFilter != 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"to"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdown_toFilter}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-lg-15 col-md-4 col-12">
                    <div className="form-group dropdown-form history-form-grp" id={"subject"}>
                      <Dropdown
                        dropdownLabel="Subject:"
                        name="subjectFilter"
                        onChange={this.onFilterSelected}
                        selected={dropdown_subjectFilter}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {subjectList == null
                          ? null
                          : subjectList.map((item) => (
                            <option wrap="true" value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_subjectFilter != undefined && dropdown_subjectFilter != null && dropdown_subjectFilter != 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"subject"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdown_subjectFilter}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-lg-15 col-md-4 col-12">
                    <div className="form-group dropdown-form  history-form-grp custom-responsive-drpdown" id={"email"}>
                      <Dropdown
                        dropdownLabel="Type of Email:"
                        name="emailTypeFilter"
                        onChange={this.onFilterSelected}
                        selected={dropdown_emailTypeFilter}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {emailTypeList == null
                          ? null
                          : emailTypeList.map((item) => (
                            <option wrap="true" value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>

                      {dropdown_emailTypeFilter != undefined && dropdown_emailTypeFilter != null && dropdown_emailTypeFilter != 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"email"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdown_emailTypeFilter}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-lg-15 col-md-4 col-12">
                    <div className="form-group dropdown-form  history-form-grp custom-responsive-drpdown">
                      <label className="label-className label-mobile">
                        {" "}
                        <b>Date:</b>{" "}
                      </label>{" "}
                      <br />
                      <div className="coc-block">
                        {/* <input
                          className="coc-input"
                          type="date"
                          name="dateofbirth"
                          id="dateofbirth"
                          onChange={this.onDateSelected}
                        /> */}
                        <DatePicker
                          isClearable
                          selectedDate={this.state.dateofbirth}
                          name="dateofbirth"
                          id="dateofbirth"
                          onDateChange={(e) =>
                            this.onDateChanged("dateofbirth", e)
                          }
                          className="form-control"
                          title="dateofbirth"
                          placeholder=""
                          maxDate={currentDate}
                        >
                          {" "}
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 "
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      type="button"
                      className="btn btn-blue-bg"
                      onClick={this.clearAllFilter}
                      style={{ marginTop: 24 }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="accordion-filter-dropdown communications-history-form">
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="form-group dropdown-form history-form-grp">
                          <MobileDropdown
                            dropdownLabel="From:"
                            name="fromFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_fromFilter}
                          >
                            {fromList == null
                              ? null
                              : fromList.map((item) => (
                                <option wrap="true" value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form history-form-grp">
                          <MobileDropdown
                            dropdownLabel="To:"
                            name="toFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_toFilter}
                          >
                            {toList == null
                              ? null
                              : toList.map((item) => (
                                <option wrap="true" value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form history-form-grp">
                          <MobileDropdown
                            dropdownLabel="Subject:"
                            name="subjectFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_subjectFilter}
                          >
                            {subjectList == null
                              ? null
                              : subjectList.map((item) => (
                                <option wrap="true" value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form  history-form-grp custom-responsive-drpdown">
                          <MobileDropdown
                            dropdownLabel="Type of Email:"
                            name="emailTypeFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_emailTypeFilter}
                          >
                            {emailTypeList == null
                              ? null
                              : emailTypeList.map((item) => (
                                <option wrap="true" value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form  history-form-grp custom-responsive-drpdown">
                          <label className="label-class label-mobile">
                            Date:{" "}
                          </label>{" "}
                          <br />
                          <div className="coc-block">
                            <input
                              className="coc-input"
                              type="date"
                              name="dateofbirth"
                              id="dateofbirth"
                            />
                          </div>
                        </div>
                        <div
                          className="col-12 col-md-4 col-lg-3 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            type="button"
                            className="btn btn-blue-bg"
                            onClick={this.clearAllFilter}
                            style={{ marginTop: 24 }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ag-theme-alpine"
                style={{
                  height: "500px",
                  marginTop: 30,
                }}
              >
                <AgGrid
                  onGridReady={this.onGridReady}
                  rowData={historyData}
                  colDefs={colDefs}
                  rowSelection="single"
                  pagination="10"
                  onSortChanged={(e) => this.onSortChanged(e)}
                />
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    );
  }
}

export default withRouter(CommunicationHistory);
