import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter } from "../../helpers";
import admindownload from "../../assets/images/admin-download.png";
import admineye from "../../assets/images/admin-eye.png";
import { ShipService } from "../../services/ship.service";
import ViewModal from "../../components/ViewModal";
import UploadFileModal from "../Ship/UploadFileModal";
import { convertDate } from "../../helpers/string-helper";
import Logout from "../../helpers/Logout";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";
import { Module, UserRole } from "../../helpers/enums";

class ShipDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedShipDocuments: [],
      colDefs: this.gridColumns,
      userId: 0,
      documentTypeList: [],
      dropdown_documentType: "0",
      defaultShipDocuments: [],
      selectedDocListIds: [],
      searchedText: "",
      showViewModal: false,
      filePathToView: "",
      showShipUploadModal: false,
      shipName: "",
      clientName: "",
      flagStateList: [],
      clientId: 0,
      requestType: "",
      gridApi: null,
      userTypeId: 0,
      isShip: false,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    //HV - 09/03/2023 - changing browser title name 
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState({ userId: userData.id, shipName: userData.name, userTypeId: userData.typeId, 
        isShip: userData.typeId == UserRole.SHIPCREW }, () => {
        this.getShipDocumentsData(false, userData.id)
      }
      )
    }
  }

  /* componentDidUpdate() {
    if (this.state.gridApi) {
      this.state.gridApi.paginationGoToPage(0);
    }
  } */

  /* <summary>
    date: 09/03/2023
    Name: HV
    description: document grid columns
    <summary> */

  gridColumns = [
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      unSortIcon: false,
    },
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Document Name",
      field: "documentName",
      tooltipField: "documentName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Document Type",
      field: "documentTypeName",
      tooltipField: "documentTypeName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Issued Date",
      field: "issueDate",
      tooltipField: "issueDate",
    },
    {
      headerName: "Expiry Date",
      field: "expiryDate",
      tooltipField: "expiryDate",
    },

    {
      headerName: "Issued By",
      field: "issuedByName",
      tooltipField: "issuedByName",
    },

    {
      headerName: "Approved By",
      field: "approvedBy",
      tooltipField: "approvedBy",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Flag State",
      field: "flagStateName",
      tooltipField: "flagStateName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admineye}
          onClick={() => this.viewShipDocData(params.data.shipDocumentBridgeId)}
          width="20px"
        />
      ),
    },
    {
      pinned: "right",
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admindownload}
          onClick={() =>
            this.downloadShipDocData(params.data.shipDocumentBridgeId)
          }
          width="15px"
        />
      ),
    },
  ];

  /* <summary>
    date: 09/03/2023
    Name: HV
    description: method for binding ship documents
    <summary> */

  getShipDocumentsData = async (closeModal, shipId) => {

    this.setState({ isLoading: true });
    const response = await ShipService.GetShipDocumentData(shipId);
    if (response.status == 200) {

      var documentsData = response.data;
      this.setState(
        {
          mappedShipDocuments: documentsData?.allShipDocumentList,
          defaultShipDocuments: documentsData?.allShipDocumentList,
          documentTypeList: documentsData?.documentTypeList,
          clientName: documentsData?.clientName,
          flagStateList: documentsData?.flagStateList,
          clientId: documentsData?.clientId,
          shipName: documentsData?.shipName,
          toastVisible: closeModal ? true : false,
          toastMessage: closeModal
            ? helperText.global.documnetUploadedSuccess
            : "",
          toastType: closeModal ? helperText.global.toastSuccess : "",
          isLoading: false,
        },
        () => {
          if (closeModal) this.closeDocumentUploadModal();
          this.filterGridView();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };

  /* <summary>
    date: 09/03/2023
    Name: HV
    description: method on view document icon clicked
    <summary> */
  viewShipDocData = (id) => {
    var filterList = [];
    filterList = this.state.defaultShipDocuments.filter(
      (x) => x.shipDocumentBridgeId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };

  /* <summary>
   date: 09/03/2023
   Name: HV
   description: method for downloading document
   <summary> */

  downloadShipDocData = async (id) => {

    try {
      setTimeout(async () => {

        if (this.state.selectedDocListIds.length > 0) {
          this.setState({
            isLoading: true,
          });
          let tFileIDlist = [];
          if (id == 0) {
            this.state.selectedDocListIds.forEach((element) => {
              var tId = element;
              tFileIDlist.push(tId);
            });
          } else {
            tFileIDlist.push(id);
          }

          if (tFileIDlist.length == 0) {
            this.setState({
              toastVisible: true,
              isLoading: false,
              toastResponseType: helperText.global.toastError,
              toastMessage: helperText.global.noDocumentsSelected,
            });
            return;
          }

          const response = await ShipService.DownloadShipDoc(tFileIDlist, this.state.userId);
          if (response.status == 200) {
            response.data.forEach((element) => {
              var fname = "";
              var lastItem = element.split("/").pop();
              fname = lastItem;
              const link = document.createElement("a");
              link.setAttribute("href", element);
              link.setAttribute("download", element);

              var ext = element.substring(element.lastIndexOf(".") + 1);
              if (ext == "pdf" || ext == "PDF") {
                link.setAttribute("target", "_blank");
              } else {
                link.setAttribute("target", "");
              }
              document.body.appendChild(link);
              link.download = fname;
              link.click();
            });

            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastSuccess,
              toastMessage: helperText.global.downloadSuccess,
            });
          } else {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.global.somethingWentWrong,
            });
          }
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.noDocumentsSelected,
            isLoading: false,
          });
        }
        this.setState({
          isLoading: false,
        });
      }, 500);
    } catch (error) { }
  };

  goBack = () => {
    this.props.navigate("/ShipCrewDashboard");
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
  date: 09/03/2023
  Name: HV
  description: setting dropdown value
  <summary> */

  onFilterSelected = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.filterGridView();
    });
  };

  /* <summary>
   date: 09/03/2023
   Name: HV
   description: filtering data based on values selected
   <summary> */

  filterGridView = () => {
    const { dropdown_documentType, defaultShipDocuments } = this.state;
    var filterList = [];

    if (dropdown_documentType == "0") filterList = defaultShipDocuments;
    else {
      if (dropdown_documentType != "" && dropdown_documentType != "0") {
        var documentTypeId = parseInt(dropdown_documentType, 10);
        filterList =
          filterList.length > 0
            ? filterList.filter((e) => e.documentTypeId == documentTypeId)
            : defaultShipDocuments.filter(
              (e) => e.documentTypeId == documentTypeId
            );
      }
    }

    this.setState({
      mappedShipDocuments: filterList,
    });
  };

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    })
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedDocumentId = [];
      selectedRows.forEach((element) => {
        listSelectedDocumentId.push(element.shipDocumentBridgeId);
      });
      this.setState({ selectedDocListIds: listSelectedDocumentId });
    } else {
      this.setState({
        selectedDocListIds: [],
      });
    }
  };

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_documentType: "0",
        searchedText: "",
      },
      () => {
        this.filterGridView();
      }
    );
  };

  /* <summary>
   date: 09/03/2023
   Name: HV
   description: filtering data based on search text
   <summary> */

  onSearch = (e) => {
    const { value } = e.target;
    this.setState(
      {
        searchedText: value,
      },
      () => {
        var inputValue = e.target.value;
        var filterList = this.state.defaultShipDocuments;

        if (inputValue.trim() !== "") {
          var value = inputValue.trim().toString().toLowerCase();
          filterList = filterList.filter(
            (t) =>
              t.documentName?.toString().toLowerCase().includes(value) ||
              t.documentType?.toString().toLowerCase().includes(value) ||
              t.issuedByName?.toString().toLowerCase().includes(value) ||
              t.approvedBy?.toString().toLowerCase().includes(value) ||
              t.flagStateName?.toString().toLowerCase().includes(value) ||
              t.documentTypeName?.toString().toLowerCase().includes(value)
          );
        }
        this.setState({
          mappedShipDocuments: filterList,
        });
      }
    );
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
    });
  };
  /* <summary>
       date: 09/03/2023
       Name: HV
       description: on view file clicked
       <summary> */

  onViewFileDownload = (filePath) => {
    var lastItem = filePath.split("/").pop();
    var fname = lastItem;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", filePath);
    document.body.appendChild(link);
    link.download = fname;
    link.click();
  };

  closeDocumentUploadModal = () => {
    this.setState({ showShipUploadModal: false });
  };

  /* <summary>
   date: 10/03/2023
   Name: HV
   description: method for saving data on upload clicked
   <summary> */

  onUploadClick = async (docData) => {
    const { userId, clientId, userTypeId } = this.state;

    this.setState({ isLoading: true });
    let shipDocumentData = new FormData();
    shipDocumentData.append("DocumentTypeId", docData.dropdown_documentType);
    shipDocumentData.append("FlagStateId", docData.dropdown_flagState);
    shipDocumentData.append("Remark", docData.remarks);
    var dateIssue =
      docData.issueDate == "" ? "" : convertDate(docData.issueDate);
    shipDocumentData.append("IssueDate", dateIssue);
    var dateExpiry =
      docData.expiryDate == "" ? "" : convertDate(docData.expiryDate);
    shipDocumentData.append("ExpiryDate", dateExpiry);
    shipDocumentData.append("IssueBy", docData.dropdown_issuedBy);
    shipDocumentData.append("ApprovedBy", docData.dropdown_approvedBy);
    shipDocumentData.append("loggedInUserID", userId);
    shipDocumentData.append("shipId", userId);
    shipDocumentData.append("clientId", clientId);
    shipDocumentData.append("userTypeId", userTypeId);


    shipDocumentData.append("ProjectNo", docData.project_num);
    let file = docData.TFile;
    if (file != "" && file.size !== null && file.size !== undefined) {
      shipDocumentData.append("ShipDocumentAttached", file);
      var fileName = file.name.substring(0, file.name.lastIndexOf("."));
      shipDocumentData.append("fileName", fileName);
    }

    var responseSaved = await ShipService.AddShipDocData(shipDocumentData);
    if (
      responseSaved.data != null &&
      responseSaved.data != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        isLoading: false
      }, async () => {
        this.getShipDocumentsData(true, userId);
        await this.SaveShipCrewChangeRequestData(fileName);
      });

    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  SaveShipCrewChangeRequestData = async (fileName) => {

    const { userId, requestType } = this.state;
    this.setState({ isLoading: true, showProceedModal: false })

    let shipChangeRequestData = new FormData();
    shipChangeRequestData.append("ShipDocument", true)
    shipChangeRequestData.append("RequestType", fileName)
    shipChangeRequestData.append("EditedBy", userId)
    shipChangeRequestData.append("ShipId", userId);
    shipChangeRequestData.append("ModuleName", "Documents");
    shipChangeRequestData.append("NavigateTo", "Ship Details");

    var responseSaved = await ShipService.SaveShipCrewChangeRequestData(shipChangeRequestData);
    if (responseSaved != null && responseSaved != undefined && responseSaved.status == 200) {
      this.setState({
        isLoading: false,
      }, () => {
        this.onCloseChangeRequestModalClick();
      });

    }
    else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    }
    else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  }

  onCloseChangeRequestModalClick = () => {
    this.setState({ showClientRequestModal: false, requestType: "" });
  };

  onSortChanged(e) {
    e.api.refreshCells();
  }

  onSaveNewTypeClick = async (typeName) => {
    this.setState({
      isLoading: true,
    });

    let docData = {
      TypeName: typeName,
      Module: Module.Ship
    }
    var responseSaved = await CommonService.AddNewDocumentType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data)
      const response = await ShipService.GetShipDropdownData();

      if (response != null && response != undefined && response.status == 200) {
        var shipDropdownData = response.data;
        this.setState(
          {
            documentTypeList: shipDropdownData.documentTypeList,
            isLoading: false,
          });
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };
  render() {
    const {
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      mappedShipDocuments,
      colDefs,
      documentTypeList,
      dropdown_documentType,
      showViewModal,
      filePathToView,
      showShipUploadModal,
      shipName,
      clientName,
      flagStateList,
      isShip
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Ship Document File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
          />
        )}

        {showShipUploadModal && (
          <UploadFileModal
            onCloseClick={this.closeDocumentUploadModal}
            clientName={clientName}
            shipName={shipName}
            documentTypeList={documentTypeList}
            flagStateList={flagStateList}
            onUploadClick={this.onUploadClick}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
            isShip={isShip}
          />
        )}

        <main className="page-height">
          <section className="manage-supplier">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    <a className="cursor-class" onClick={this.goBack}>
                      <img src="images/back.png" alt="Back" />
                    </a>
                    <h5>Documents</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <div className="searchBar">
                    <Searchbar
                      placeholder={"Document Name, Document Type, etc..."}
                      onChange={this.onSearch}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- Mobile View --> */}
              <div className="training-mobile">
                <div className="accordion-filter-dropdown">
                  <div id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="supplier-filter">
                            <div className="row">
                              <div className="col-lg-12 filter-flex-container wrap">
                                <div className="filter-box filter-flex-item">
                                  <div className="form-group form-label dropdown-form admin-client-form filter-flex-item" id={"docType"}>
                                    <Dropdown
                                      dropdownLabel="Document Type:"
                                      name="documentType"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_documentType}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {documentTypeList == null
                                        ? null
                                        : documentTypeList.map((item, key) => (
                                          <option
                                            key={key}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_documentType > 0 ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"docType"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documentType)[0]?.label}
                                      </UncontrolledTooltip>) : null}
                                  </div>
                                </div>
                                <div
                                  className="col-12 col-md-4 col-lg-3 "
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-blue-bg"
                                    onClick={this.clearAllFilter}
                                    style={{ marginTop: 15 }}
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- DESKTOP --> */}
              <div className="training-desktop">
                <div className="desktop-class">
                  <div className="supplier-filter">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-12 filter-flex-container wrap">
                          <div className="filter-box filter-flex-item" id={'docType1'}>
                            <Dropdown
                              dropdownLabel="Document Type:"
                              name="documentType"
                              onChange={this.onFilterSelected}
                              selected={dropdown_documentType}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {documentTypeList == null
                                ? null
                                : documentTypeList.map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>

                            {dropdown_documentType > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"docType1"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documentType)[0]?.label}
                              </UncontrolledTooltip>) : null}
                          </div>
                          <div
                            className="filter-box clear-all-btn"
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              type="button"
                              className="btn btn-blue-bg"
                              onClick={this.clearAllFilter}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 training-add-button"
                  style={{ marginTop: "10px" }}
                >
                  <button
                    type="button"
                    className="btn btn-background-color  buttons-alignment view-history-responsive view-btn-responsive "
                    onClick={() => this.setState({ showShipUploadModal: true })}
                  >
                    Upload Document
                  </button>
                  &nbsp;
                  {mappedShipDocuments != null && mappedShipDocuments.length > 0 ? <span>
                    <img
                      className="cursor-class"
                      id="training-admin-dwnld-icon"
                      src="./images/admin-tracking-download.png"
                      alt=""
                      title="Multi Download"
                      onClick={() => this.downloadShipDocData(0)}
                    />
                  </span> : null}
                </div>
              </div>

              <div className="training-grid">
                <div className="row">
                  <div className="col-md-12">
                    <AGGrid
                      rowData={mappedShipDocuments}
                      colDefs={colDefs}
                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                      rowSelection="multiple"
                      pagination="10"
                      onGridReady={this.onGridReady}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}
export default withRouter(ShipDocuments);
