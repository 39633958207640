import React, { useEffect, useState } from "react";
import { settingsService } from "../../services/settings.service";
import { GetLocalStorageDataWithParse, helperText } from "../../helpers";

const ReportsSettings = ({ SettingsData, ToggleLoader, ShowToastModal }) => {
  const [reportsData, setReportsData] = useState([]);
  const [reportsFrequency, setReportsFrequency] = useState([]);
  const [useID, setUseID] = useState(0);

  useEffect(() => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (SettingsData) {
      var { reportSettings, reportsFrequency } = SettingsData;
      setReportsData(reportSettings);
      setReportsFrequency(reportsFrequency);
    }
    setUseID(userData ? userData.id : 0);
    return () => {};
  }, [SettingsData]);
  const OnNewFrequencyChange = (e, index) => {
    var { value } = e.target;
    setReportsData((prevState) =>
      prevState.map((item, itemIndex) => {
        if (itemIndex == index) {
          return { ...item, reportsNewFrequencyID: value, editedBy: useID };
        } else return item;
      })
    );
  };

  const SaveReportsSettings = async () => {
    ToggleLoader();
    var response = await settingsService.UpdateReportsSettings(reportsData);
    ToggleLoader();
    if (response.status == 200) {
      ShowToastModal(
        helperText.SettingsPageText.reportsUdpateSuccess,
        helperText.global.toastSuccess
      );
    } else {
      ShowToastModal(
        helperText.global.somethingWentWrong,
        helperText.global.toastError
      );
    }
  };
  return (
    <div id="Reports-settings" className="tabcontent">
      <div className="tabcontent-data">
        <h5 className="heading">Reports Settings</h5>
        <p className="para-data">
          Default settings for report generation may be updated here.
        </p>
        <div className="table-responsive-xxl">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th>
                  <h5>Report Type</h5>
                </th>
                <th>
                  <h5>Default Frequency:</h5>
                </th>
                <th>
                  <h5>New Frequency:</h5>
                </th>
              </tr>
            </thead>
            <tbody className="reports-settings-body">
              {reportsData.map((item, index) => {
                let defaultFrequency = reportsFrequency.find(
                  (t) => t.id == item.reportsDefaultFrequencyID
                );
                return (
                  <tr key={item.settingsID}>
                    <td>
                      <p>{item.reportsType}</p>
                    </td>
                    <td>
                      <p>
                        {defaultFrequency == null ? "" : defaultFrequency.name}
                      </p>
                    </td>
                    <td>
                      <select
                        className="form-select"
                        aria-label="form-select-lg example"
                        value={item.reportsNewFrequencyID}
                        onChange={(e) => OnNewFrequencyChange(e, index)}
                      >
                        {reportsFrequency.map((fItem) => {
                          return (
                            <option key={fItem.id} value={fItem.id}>
                              {fItem.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className=" admin-ship-settings-save-btn settings-save-btn">
        <button
          className="btn btn-block"
          type="button"
          onClick={SaveReportsSettings}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ReportsSettings;
