import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import DatePicker from "../../components/DatePickerComponent";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import { GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, helperText, isValidFile, withRouter } from "../../helpers";
import PortRegistryModal from "./PortRegistryModal";
import { AddNewValue } from "../../helpers/enums";
import { ShipService } from "../../services/ship.service";
import Toast from "../../components/Toast";
import Logout from "../../helpers/Logout";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";
import { Module } from "../../helpers/enums";

const validFileFormatsReadable =
  "application/pdf,application/PDF, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const validFileFormatsExt = [".pdf, .xls, .xlsx, .docx,.PDF"];
const defaultAddNewItem = { label: "Add New", value: "0" };

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-ship-modal"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

class UploadFileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_documentType: "",
      dropdown_flagState: "",
      issuedBy: "",
      approvedBy: "",
      issueDate: "",
      expiryDate: "",
      remarks: "",
      errorMessages: {},
      showErrorMessage: false,
      fileInputKey: "",
      fileName: "",
      filePath: "",
      TFile: null,
      nextIssueDate: "",
      previousExpiryDate: "",
      //flagStateList: props.flagStateList,
      showAddNewFlagStateModal: false,
      loggedInUserID: 0,
      displayprojectno: false,
      projectno: "",
      showTypeNameModal: false,
      typeName: "",
      invalidFileSize: false,
      isShipCrew: this.props.isShip,
    };
    this.fileInput = React.createRef();
  }

  componentDidMount = async () => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    await this.rebindFlagStateDropdown("");
    /* var addNewItemToFlagStateList = this.state.flagStateList;
    var addnewItemExists =
      this.state.flagStateList.filter((x) => x.label == "Add New").length > 0;

    if (!addnewItemExists) {
      addNewItemToFlagStateList.push(defaultAddNewItem);
    } */
    this.setState({
      loggedInUserID: userData.id,
      //flagStateList: addNewItemToFlagStateList,
    });
  };

  //HV - 24/03/2023 - handled expiry date should be greater than issue date
  onDateChanged = (id, e) => {
    const { errorMessages } = this.state;

    if (id == "issueDate") {
      var nextIssueDate = new Date(e);
      nextIssueDate.setDate(e.getDate() + 1);
      nextIssueDate.toLocaleDateString();
      errorMessages["errorIssueDate"] = "";
      this.setState({
        issueDate: e,
        errorMessages: errorMessages,
        nextIssueDate: nextIssueDate,
      });
    }

    if (id == "expiryDate") {
      var previousExpiryDate = new Date(e);
      previousExpiryDate.setDate(e.getDate() - 1);
      previousExpiryDate.toLocaleDateString();
      errorMessages["errorExpiryDate"] = "";
      this.setState({
        expiryDate: e,
        errorMessages: errorMessages,
        previousExpiryDate: previousExpiryDate,
      });
    }
  };

  onDropdownItemSelect = async (e) => {
    const { showErrorMessage } = this.state;
    var { name, value } = e.target;

    if (
      name == "dropdown_flagState" &&
      parseInt(value, 10) == parseInt(AddNewValue.AddNew, 10)
    ) {
      this.setState({
        showAddNewFlagStateModal: true,
      });
    } else {

      if ((name != "dropdown_flagState") && (value == 14 || value == 9 || value == 12 || value == 36)) {
        this.setState({
          displayprojectno: true,
        });
      }
      else if ((name != "dropdown_flagState") && (value != 14 || value != 9 || value != 12 || value != 36)) {
        this.setState({
          displayprojectno: false,
          projectno: "",
        });
      }
      if (name == "dropdown_documentType") {

        var othersSelected = this.props.documentTypeList.filter(x => x.value == value)[0].label;
        this.setState({ showTypeNameModal: othersSelected === 'Other' });
      }
      this.setState(
        {
          [name]: value,
          showAddNewFlagStateModal: false,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  validateFields() {
    const {
      dropdown_documentType,
      dropdown_flagState,
      issuedBy,
      approvedBy,
      issueDate,
      expiryDate,
      remarks,
      errorMessages,
      TFile,
    } = this.state;

    let errors = errorMessages;
    var iDate = issueDate == "" ? "" : convertDate(issueDate);
    var eDate = expiryDate == "" ? "" : convertDate(expiryDate);
    errors["errorDocumentType"] = dropdown_documentType == 0 ? "true" : "";
    // errors["errorFlagState"] = dropdown_flagState == 0 ? "true" : "";
    // errors["errorIssuedBy"] = isStringEmpty(issuedBy) ? "true" : "";
    // errors["errorApprovedBy"] = isStringEmpty(approvedBy) ? "true" : "";
    // errors["errorremarks"] = isStringEmpty(remarks) ? "true" : "";
    // errors["errorIssueDate"] =
    //   iDate == null || iDate == undefined || iDate == "" ? "true" : "";
    // errors["errorExpiryDate"] =
    //   eDate == null || eDate == undefined || eDate == "" ? "true" : "";
    errors["errorEmptyFile"] = TFile == null ? "true" : "";

    if (
      errors["errorDocumentType"] != "" ||
      // errors["errorFlagState"] != "" ||
      // errors["errorIssuedBy"] != "" ||
      // errors["errorApprovedBy"] != "" ||
      // errors["errorremarks"] != "" ||
      // errors["errorIssueDate"] != "" ||
      // errors["errorExpiryDate"] != "" ||
      errors["errorEmptyFile"] != ""
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: true,
      });
      return true;
    }
    this.setState({
      errorMessages,
      showErrorMessage: false,
    });
    return false;
  }
  onCloseTypeClick = () => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    errors["errorTypeName"] = "";
    errorMessages["errorDocumentType"] = "";
    this.setState({ dropdown_documentType: 0, showTypeNameModal: false, typeName: "", errorMessages: errors, });
  };

  ontypeNameChange = (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;

    this.setState({ typeName: e.target.value, }, () => {
      if (!isStringEmpty(errorMessages.errorTypeName)) {
        errors["errorTypeName"] = e.target.value.trim() === "" ? "true" : "";
        this.setState({
          errorMessages: errors,
        })
      }
      else if (!isStringEmpty(errorMessages.DocTypeExist)) {
        this.CheckIfDocTypeExist(e.target.value.trim());
      }
    });
  }

  ontypeNameSaveClicked = async (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    if (this.state.typeName.trim() != "") {
      await this.CheckIfDocTypeExist(this.state.typeName.trim());
      if (isStringEmpty(errorMessages.DocTypeExist)) {
        errors["errorTypeName"] = "";
        errorMessages["errorDocumentType"] = "";
        await this.props.onSaveNewTypeClick(this.state.typeName.trim());

        this.setState({ showTypeNameModal: false, dropdown_documentType: GetLocalStorageDataWithoutParse('selecteddoctype') != null ? GetLocalStorageDataWithoutParse('selecteddoctype') : 0, typeName: "", errorMessages: errors, })
      }
    }
    else {
      errors["errorTypeName"] = "true";
      this.setState({
        errorMessages: errors,
      });
    }
  }
  CheckIfDocTypeExist = async (typeName) => {
    let errors = this.state.errorMessages;
    let docData = {
      TypeName: typeName,
      Module: Module.Ship
    }
    const response = await CommonService.CheckIfDocumentTypeExist(docData);
    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["DocTypeExist"] = "true";
      } else {
        errors["DocTypeExist"] = "";
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };
  onTextChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (showErrorMessage) this.validateFields();
      }
    );
  };

  onUploadClick = () => {
    this.validateFields();
    const { onUploadClick } = this.props;
    const {
      dropdown_documentType,
      dropdown_flagState,
      issuedBy,
      approvedBy,
      issueDate,
      expiryDate,
      remarks,
      TFile,
      projectno
    } = this.state;

    var hasErrors = this.validateFields();
    if (hasErrors) return;

    const data = {
      dropdown_documentType: dropdown_documentType,
      dropdown_flagState: dropdown_flagState,
      dropdown_issuedBy: issuedBy,
      dropdown_approvedBy: approvedBy,
      issueDate: issueDate,
      expiryDate: expiryDate,
      remarks: remarks,
      TFile: TFile,
      project_num: projectno
    };

    onUploadClick(data);
  };

  onFileUpload = async (e) => {
    const { name, value } = e.target;
    let files = e.target.files[0];
    const { showErrorMessage, errorMessages, isShipCrew } = this.state;
    let errors = errorMessages;
    const fileInput = e.target;
    const selectedFile = fileInput.files[0];

    if (value != "") {
      if (!isValidFile(value, validFileFormatsExt)) {
        errors["errorEmptyFile"] = "true";

        this.setState(
          {
            [name]: null,
            fileName: "",
            showErrorMessage: true,
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.wrongFileError,
          },
          () => {
            if (showErrorMessage) this.validateFields();
          }
        );
        return;
      }

      if (selectedFile) {
        const fileSizeInMB = selectedFile.size / (1024 ** 2); // Convert bytes to megabytes

        // File size is more than 250MB, display a message
        if (fileSizeInMB > 250 && isShipCrew) {
          this.fileInput.current.value = '';
          this.setState({ invalidFileSize: true, fileName: '', TFile: null });
          return;
        } else {
          this.setState(
            {
              [name]: files,
              fileName: files.name,
              filePath: value,
              invalidFileSize: false
            },
            () => {
              if (showErrorMessage) this.validateFields();
            }
          );
        }
      }
    }
  };

  hideModal = () => {
    this.setState({
      showAddNewFlagStateModal: false,
    });
  };

  rebindFlagStateDropdown = async (text) => {
    const response = await ShipService.GetShipDropdownData();
    if (response != null && response != undefined && response.status == 200) {
      var shipDropdownData = response.data;

      var addNewItemToFlagStateList = shipDropdownData.flagStateList;
      var addnewItemExists =
        shipDropdownData.flagStateList.filter((x) => x.label == "Add New").length > 0;

      if (!addnewItemExists) {
        addNewItemToFlagStateList.push(defaultAddNewItem);
      }

      this.setState({
        flagStateList: addNewItemToFlagStateList,
        dropdown_flagState: text == "" ? "" : shipDropdownData.flagStateList.filter(x => x.label == text)[0].value,
        toastVisible: text == "" ? false : true,
        toastMessage: helperText.global.saveSuccess,
        toastType: helperText.global.toastSuccess,
        toastResponseType: "success",
        isLoading: false,
      },
        () => {
          this.hideModal();
        });

    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  onPortRegistrySaveClicked = async (docData) => {
    this.setState({ isLoading: true });

    var data = {
      isFlagState: true,
      PortRegistryText: docData.PortRegistryText,
      loggedInUserID: this.state.loggedInUserID,
    };

    var responseSaved = await ShipService.AddPortOfRegistryData(data);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      await this.rebindFlagStateDropdown(docData.PortRegistryText);

    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  render() {

    const { onCloseClick, clientName, shipName, documentTypeList, selecteddoctype } = this.props;
    const {
      dropdown_documentType,
      dropdown_flagState,
      issuedBy,
      approvedBy,
      issueDate,
      expiryDate,
      remarks,
      errorMessages,
      showErrorMessage,
      fileInputKey,
      fileName,
      nextIssueDate,
      previousExpiryDate,
      flagStateList,
      showAddNewFlagStateModal,
      toastVisible,
      toastType,
      toastMessage,
      isShipCrew,
      invalidFileSize
    } = this.state;
    return (
      <div>
        <div className="shipdetailupload" style={{ display: "block" }}>
          <div
            className="modal-backdrop fade show"
            style={{ display: "block" }}
          ></div>
          <div
            className="modal"
            id="shipdetailupload-modal"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-box">
              <div className="modal-content ">
                {/* <!-- Modal Header --> */}
                <div className="modal-header">
                  <h4 className="modal-title">Upload Document</h4>
                  <button className="button" data-bs-dismiss="modal">
                    <img
                      src="images/modal-close.png"
                      alt="modal-close"
                      style={{ pointerEvents: "all" }}
                      onClick={() => onCloseClick()}
                    />
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="issue-date">
                        <label htmlFor="client">Client:</label>
                        <input
                          type="name"
                          className="form-control "
                          id="issuedate"
                          value={clientName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="issue-date">
                        <label htmlFor="ship">Ship:</label>
                        <input
                          type="name"
                          className="form-control "
                          id="issuedate"
                          value={shipName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="doc-type">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorDocumentType)
                              ? "invalid-position "
                              : ""
                          }
                          id={"docType"}
                        >
                          <label className="required-field">Document Type:</label>
                          <Dropdown
                            //dropdownLabel="Document Type:"
                            name="documentType"
                            onChange={this.onDropdownItemSelect}
                            selected={dropdown_documentType}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                            id={"docType"}
                          >
                            {documentTypeList == null
                              ? null
                              : documentTypeList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>

                          {dropdown_documentType > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"docType"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="right"
                              style={{ width: "350px" }}
                            >
                              {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documentType)[0]?.label}
                            </UncontrolledTooltip>) : null}
                          {!isStringEmpty(errorMessages.errorDocumentType) && (
                            <ErrorView errorFor="emptyDropdown" />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6" style={{ display: this.state.displayprojectno ? '' : 'none' }}>
                      <div className="approved-by">
                        <label htmlFor="projectno">Project Number:</label>
                        <input
                          type="text"
                          className="form-control "
                          id="projectno"
                          name="projectno"
                          value={this.state.projectno}
                          onChange={this.onTextChange}
                          maxLength={20}
                        />
                      </div>
                    </div>

                  </div>
                  <fieldset className="p-2 admin-ship-fieldset">
                    <legend className="float-none w-auto p-2 admin-ship-legend">
                      Additional Details
                    </legend>
                    <div className="row ">
                      <div className=" col-lg-4 col-md-6">
                        <div className="issue-date">
                          <label htmlFor="issuedate" >Issue Date:</label>

                          <div
                            className={
                              !isStringEmpty(errorMessages.errorIssueDate)
                                ? "invalid-position"
                                : ""
                            }
                          >
                            <DatePicker
                              min="0"
                              selectedDate={issueDate}
                              onDateChange={(e) =>
                                this.onDateChanged("issueDate", e)
                              }
                              isNotRoot={true}
                              maxDate={previousExpiryDate}
                            >
                              {" "}
                            </DatePicker>
                            {!isStringEmpty(errorMessages.errorIssueDate) && (
                              <ErrorView errorFor="emptyText" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-4 col-md-6">
                        <div className="expiry-date">
                          <label htmlFor="expirydate">Expiry Date:</label>
                          <div
                            className={
                              !isStringEmpty(errorMessages.errorExpiryDate)
                                ? "invalid-position"
                                : ""
                            }
                          >
                            <DatePicker
                              min="0"
                              selectedDate={expiryDate}
                              onDateChange={(e) =>
                                this.onDateChanged("expiryDate", e)
                              }
                              isNotRoot={true}
                              minDate={nextIssueDate}
                            >
                              {" "}
                            </DatePicker>
                            {!isStringEmpty(errorMessages.errorExpiryDate) && (
                              <ErrorView errorFor="emptyText" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="flag-state">
                          <div
                            className={
                              !isStringEmpty(errorMessages.errorFlagState)
                                ? "invalid-position"
                                : ""
                            }
                            id={"flagState"}
                          >
                            <label>Flag State:</label>
                            <Dropdown
                              //   dropdownLabel="Flag State:"
                              name="flagState"
                              onChange={this.onDropdownItemSelect}
                              selected={dropdown_flagState}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {flagStateList == null
                                ? null
                                : flagStateList.map((item, key) => (
                                  <option
                                    className={
                                      item.value == "0" ? "onhold-link" : ""
                                    }
                                    key={key}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_flagState > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"flagState"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="right"
                                style={{ width: "350px" }}
                              >
                                {flagStateList != null && flagStateList.filter(x => x.value == dropdown_flagState)[0]?.label}
                              </UncontrolledTooltip>) : null}
                            {!isStringEmpty(errorMessages.errorFlagState) && (
                              <ErrorView errorFor="emptyDropdown" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="approved-by">
                          <label htmlFor="client" >Issued By:</label>
                          <div
                            className={
                              !isStringEmpty(errorMessages.errorIssuedBy)
                                ? "invalid-position"
                                : ""
                            }
                          >
                            <input
                              type="text"
                              className="form-control "
                              id="issuedBy"
                              name="issuedBy"
                              value={issuedBy}
                              onChange={this.onTextChange}
                              maxLength={25}
                            />
                            {!isStringEmpty(errorMessages.errorIssuedBy) && (
                              <ErrorView errorFor="emptyText" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="approved-by">
                          <label htmlFor="client">Approved By:</label>
                          <div
                            className={
                              !isStringEmpty(errorMessages.errorApprovedBy)
                                ? "invalid-position"
                                : ""
                            }
                          >
                            <input
                              type="text"
                              className="form-control "
                              id="approvedBy"
                              name="approvedBy"
                              value={approvedBy}
                              onChange={this.onTextChange}
                              maxLength={25}
                            />
                            {!isStringEmpty(errorMessages.errorApprovedBy) && (
                              <ErrorView errorFor="emptyText" />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="approved-by">
                          <label htmlFor="client" >Remarks:</label>
                          <div
                            className={
                              !isStringEmpty(errorMessages.errorremarks)
                                ? "invalid-position"
                                : ""
                            }
                          >
                            <input
                              type="text"
                              className="form-control "
                              id="remarks"
                              name="remarks"
                              value={remarks}
                              onChange={this.onTextChange}
                            />
                            {!isStringEmpty(errorMessages.errorremarks) && (
                              <ErrorView errorFor="emptyText" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="row">
                    <div className="col-12 col-sm-6  col-lg-8">
                      <div className="choosefile-btn">
                        <div
                          className="file-upload-modal"
                          onClick={() => {
                            this.fileInput.current.click();
                            this.fileInput.current.value = '';
                          }}
                        >
                          <label
                            className={
                              "attach-btn attach-btn-responsive chs-btn " +
                              (!isStringEmpty(errorMessages.errorEmptyFile)
                                ? "invalid-position-border"
                                : "")
                            }
                          >
                            <img
                              className="upload-img"
                              src="images/Icon awesome-upload.png"
                              width="18px"
                            />
                            &nbsp;Choose File
                          </label>
                        </div>
                        {!isStringEmpty(errorMessages.errorEmptyFile) && (
                          <img
                            src="images\icon material-error.png"
                            alt="invalid"
                            className="invalid-position-img"
                            style={{ width: "16px" }}
                          />
                        )}
                        <label className="required-field">&nbsp; (Please select a PDF, Excel or Word file)</label>
                        {/*  &nbsp; (Please select a PDF, Excel or Word file) */}
                        <input
                          ref={this.fileInput}
                          type="file"
                          accept={validFileFormatsReadable}
                          id="fileInput"
                          name="TFile"
                          onChange={this.onFileUpload}
                          key={fileInputKey || ""}
                          style={{ display: "none", visibility: "hidden" }}
                        />
                      </div>
                      <label className="label-breakword" style={{ overflowWrap: "anywhere", margin: "10px" }}>
                        {" "}
                        {fileName}
                      </label>
                      <br />
                      {isShipCrew ? <div style={{ paddingBottom: '10px' }}>
                        <span style={{ fontSize: '13px', fontWeight: "bold", color: 'black' }}>Maximum 250 MB files can be uploaded.</span>
                      </div> : null}
                    </div>
                    <br /> <br />
                    {invalidFileSize && isShipCrew && <div className="invalid-message-icon-box">
                      <p>Please select a file upto 250MB.</p>
                    </div>}
                  </div>
                  <div className="upload-btn">


                    {showErrorMessage && (
                      <div className="invalid-message-icon-box">
                        <img
                          src="images/icon material-error.png"
                          alt="invalid-icon"
                        />
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                    )}

                    <button
                      type="button"
                      className="btn btn-background-color ship-detail-upload-btn"
                      id="uploadbtn"
                      onClick={this.onUploadClick}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAddNewFlagStateModal && (
          <PortRegistryModal
            onCloseClick={this.hideModal}
            showModal={showAddNewFlagStateModal}
            onPortRegistrySaveClicked={this.onPortRegistrySaveClicked}
            originalPortOfRegList={flagStateList}
            displayText={"Flag State"}
          />
        )}

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {
          this.state.showTypeNameModal && (
            <div className="supplier_on_hold">
              <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
              <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Add Type</h4>
                      <button type="button" data-bs-dismiss="modal">
                        <img
                          src="images/modal-close.png"
                          alt="modal-close"
                          onClick={this.onCloseTypeClick}
                        />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row content">
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                          <div className={
                            !isStringEmpty(errorMessages.errorTypeName) || !isStringEmpty(errorMessages.DocTypeExist)
                              ? "invalid-position"
                              : ""
                          }>
                            <label htmlFor="todate">Type Name:</label>
                            <br />
                            <input
                              type="typeName"
                              className="form-control"
                              id="typeName"
                              name="typeName"
                              placeholder="Enter Type Name"
                              value={this.state.typeName}
                              onChange={this.ontypeNameChange}
                              maxLength={50}
                            />
                            {(!isStringEmpty(errorMessages.errorTypeName)) || (!isStringEmpty(errorMessages.DocTypeExist)) && (
                              <div className="misc-invalid-message-icon ">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.errorTypeName) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.DocTypeExist) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Document type already exists.</p>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-background-color save-btn"
                          onClick={this.ontypeNameSaveClicked}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default withRouter(UploadFileModal);
