import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { generateRandomString, isValidFile, helperText, GetLocalStorageDataWithoutParse } from "../../helpers";
import { isStringEmpty } from "../../helpers/string-helper";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Toast from "../../components/Toast";
import { TrainingService } from "../../services/training.service";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";
import { Module } from "../../helpers/enums";

const validFileFormatsExt = [".pdf, .docx, .PDF"];
const validFileFormatsReadable =
  "application/pdf, application/PDF, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const defaultAllItem = { label: "All", value: "0" };

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon-modal"
              : "invalid-message-icon"
          }
          style={{ marginTop: "8px" }}
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

export default class UploadDataFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docID: 0,
      fileInputKey: "",
      filePath: "",
      validateError: false,
      fileName: "",
      errorMessages: {},
      TDataList: [],
      unreadableErrorMessages: [],
      dropdown_region: 0,
      dropdown_client: 0,
      dropdown_ship: 0,
      dropdown_documentType: 0,
      input_documentName: "",
      dropdownList: this.props.dropdownList,
      dropdownList_applicableToList_Filtered: [],
      dropdown_applicableTo: [],
      shipNameList: [],
      TFile: null,
      emptyFileError: false,
      invalidFileError: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      showTypeNameModal: false,
      typeName: "",
    };
  }

  componentDidMount() {
    this.setState({
      dropdown_applicableTo: [],
    }, () => {
      var appList = this.state.dropdownList.applicableToList;
      var checkIfAllValueExists = appList.filter(x => x.label == "All").length > 0;
      if (!checkIfAllValueExists) {
        appList.unshift(defaultAllItem);
      }

      this.setState({
        dropdownList_applicableToList_Filtered: appList,
        appList: appList,
      });
    }
    );
  }

  onUploadClick = () => {
    var {
      dropdown_region,
      dropdown_client,
      dropdown_ship,
      dropdown_documentType,
      input_documentName,
      dropdown_applicableTo,
      TFile,
    } = this.state;
    const { onUploadClick } = this.props;
    var hasErrors = this.validateFields();

    if (hasErrors) return;

    const data = {
      trainingDocID: this.state.docID,
      dropdown_region: dropdown_region,
      dropdown_client: dropdown_client,
      dropdown_ship: dropdown_ship,
      dropdown_documentType: dropdown_documentType,
      input_documentName: input_documentName,
      dropdown_applicableTo: dropdown_applicableTo,
      TFile: TFile,
    };
    onUploadClick(data);
  };

  validateFields() {
    var {
      dropdown_client,
      dropdown_documentType,
      input_documentName,
      dropdown_applicableTo,
      TFile,
      errorMessages,
    } = this.state;

    var hasErrors = false;

    let errors = errorMessages;
    errors["errorClient"] = dropdown_client == undefined || dropdown_client == 0 ? "true" : "";
    errors["errorDocumentType"] = dropdown_documentType == undefined || dropdown_documentType == 0 ? "true" : "";
    errors["errorDocumentName"] = dropdown_documentType > 0 && dropdown_documentType == "42" && (input_documentName == undefined || input_documentName == "") ? "true" : "";
    errors["errorApplicableTo"] = dropdown_applicableTo == undefined || dropdown_applicableTo.length == 0 ? "true" : "";
    errors["errorTFile"] = TFile == null ? "true" : "";


    if (
      errors["errorClient"] != "" ||
      errors["errorDocumentType"] != "" ||
      errors["errorDocumentName"] != "" ||
      errors["errorApplicableTo"] != []
    ) {
      hasErrors = true;

      this.setState({
        validateError: hasErrors,
        errorMessages: errors,
        emptyFileError: false,
        invalidFileError: false,
      });
    }
    else if (errors["errorTFile"] != "") {
      hasErrors = true;

      this.setState({
        validateError: false,
        emptyFileError: hasErrors,
        errorMessages: errors,
        invalidFileError: false,
      });
    }

    return hasErrors;
  }

  onFileUpload = async (e) => {
    const { name, value } = e.target;
    var {
      dropdown_client,
      dropdown_documentType,
      input_documentName,
      dropdown_applicableTo,
      errorMessages,
      validateError
    } = this.state;
    let errors = errorMessages;
    var hasErrors = false;

    /* errors["errorClient"] =
      dropdown_client == undefined || dropdown_client == 0 ? "true" : "";
    errors["errorDocumentType"] =
      dropdown_documentType == undefined || dropdown_documentType == 0
        ? "true"
        : "";
    errors["errorDocumentName"] =
      input_documentName == undefined || input_documentName == 0 ? "true" : "";
    errors["errorApplicableTo"] =
      dropdown_applicableTo == undefined || dropdown_applicableTo.length == 0
        ? "true"
        : ""; */

    /* if (
      errors["errorClient"] != "" ||
      errors["errorDocumentType"] != "" ||
      errors["errorDocumentName"] != "" ||
      errors["errorApplicableTo"] != ""
    )
      hasErrors = true;

    this.setState({
      validateError: hasErrors,
    }); */

    //if (!hasErrors) {
    if (!isValidFile(e.target.value, validFileFormatsExt)) {
      if (this.state.filePath == "") {
        this.setState({
          fileInputKey: generateRandomString,
          [name]: null,
          TFile: null,
          invalidFileError: true,
          emptyFileError: false,
          validateError: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.wrongFileError,
        });
      }
      return;
    }
    let files = e.target.files[0];
    errors["errorTFile"] = "";
    this.setState({
      [name]: files,
      fileName: files.name,
      filePath: value,
      TFile: files,
      invalidFileError: false,
      emptyFileError: false,
    }, () => {
      if (validateError)
        this.validateFields();
    });


    //}
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterGridView()
    );
  };

  filterGridView = () => { };

  onDropdownChangeClient = (e) => {
    const { validateError } = this.state;
    const { shipList, clientList, dropdownList, dropdown_client } = this.props;
    var { name, value } = e.target;
    this.setState({ dropdown_client: value });
    if (e.target.name == "dropdown_client" && e.target.value != "0") {
      var clientListRegionId = clientList.filter(
        (x) => x.value == e.target.value
      )[0].parentValue;
      var filteredShipList = shipList.filter(
        (x) => x.parentValue == e.target.value
      );
      var regionListid = dropdownList.regionList.filter(
        (x) => x.value == clientListRegionId
      )[0].value;
      this.setState({
        shipNameList: filteredShipList,
        dropdown_region: regionListid,
      });
    } else {
      this.setState({ shipNameList: shipList, dropdown_region: 0 });
    }
    this.setState({ [name]: value }, () => {
      if (validateError) this.validateFields();
    });
  };
  onDropdownChangeShip = (e) => {
    const { validateError } = this.state;
    const { value } = e.target;
    this.setState({ dropdown_ship: value }, () => {
      if (validateError) this.validateFields();
    });
  };

  onDropdownChangeRegion = (e) => {
    const { validateError } = this.state;
    const { value } = e.target;
    this.setState({ dropdown_region: value }, () => {
      if (validateError) this.validateFields();
    });
  };
  onDropdownChangeDocumentType = (e) => {
    const { validateError } = this.state;
    const { value } = e.target;
    var othersSelected = this.state.dropdownList.documentTypeList.filter(x => x.value == value)[0].label;
    this.setState({ dropdown_documentType: value, showTypeNameModal: othersSelected === 'Other' }, () => {
      if (validateError) this.validateFields();
    });
  };
  onCloseTypeClick = () => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    errors["errorTypeName"] = "";
    errorMessages["errorDocumentType"] = "";
    this.setState({ dropdown_documentType: 0, showTypeNameModal: false, typeName: "", errorMessages: errors, });
  };
  ontypeNameChange = (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;

    this.setState({ typeName: e.target.value, }, () => {
      if (!isStringEmpty(errorMessages.errorTypeName)) {
        errors["errorTypeName"] = e.target.value.trim() === "" ? "true" : "";
        this.setState({
          errorMessages: errors,
        })
      }
      else if (!isStringEmpty(errorMessages.DocTypeExist)) {
        this.CheckIfDocTypeExist(e.target.value.trim());
      }
    });
  }
  ontypeNameSaveClicked = async (e) => {
    var { errorMessages } =
      this.state;
    let errors = errorMessages;
    if (this.state.typeName.trim() != "") {

      await this.CheckIfDocTypeExist(this.state.typeName.trim());

      if (isStringEmpty(errorMessages.DocTypeExist)) {
        errors["errorTypeName"] = "";
        errorMessages["errorDocumentType"] = "";
        await this.props.onSaveNewTypeClick(this.state.typeName.trim());

        this.setState({ showTypeNameModal: false, dropdown_documentType: GetLocalStorageDataWithoutParse('selecteddoctype') != null ? GetLocalStorageDataWithoutParse('selecteddoctype') : 0, typeName: "", errorMessages: errors, })
      }
    }
    else {
      errors["errorTypeName"] = "true";
      this.setState({
        errorMessages: errors,
      });
    }
  }
  CheckIfDocTypeExist = async (typeName) => {

    let errors = this.state.errorMessages;
    var docData = {
      TypeName: typeName,
      Module: Module.Training
    }
    const response = await CommonService.CheckIfDocumentTypeExist(docData);

    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["DocTypeExist"] = "true";
      } else {
        errors["DocTypeExist"] = "";
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };
  onTextChange = (e) => {
    const { validateError } = this.state;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.setState({ input_documentName: value }, () => {
      if (validateError) this.validateFields();
    });
  };

  onDropdownChangeApplicableTo = (option) => {
    const { validateError } = this.state;
    var dropdown_applicableTolist = [];
    if (option.length > 0) {
      option
        //.filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_applicableTolist.push(item);
        });
    }

    this.setState({ dropdown_applicableTo: dropdown_applicableTolist }, () => {
      if (validateError) this.validateFields();
    });
  };

  addNewRow = (rowId, TDataList) => {
    const { dropdownList } = this.props;
    var addRow = {
      rowId: rowId,
      shipList: dropdownList.shipList,
      dropdown_modalShip: 0,
      poNumber: "",
      totalPOItems: "",
      totalSuspectedHazmatItems: "",
      poDate: "",
      customFileName: "",
      supplierList: dropdownList.supplierList,
      dropdown_modalSupplier: 0,
      tFile: null,
      tFileName: "",
      tFilePath: "",
      tFileInputKey: generateRandomString,
    };
    TDataList.push(addRow);
    return TDataList;
  };

  onRowDataChange = (rowId, e) => {
    var { TDataList, validateError } = this.state;
    var { name, value } = e.target;

    var rowIndex = TDataList.findIndex((t) => t.rowId == rowId);
    if (rowIndex > -1) {
      TDataList[rowIndex][name] = value;
      this.setState({ TDataList }, () => {
        if (validateError) this.validateFields();
      });
    }
  };

  onRowDateChanged = (rowId, e) => {
    var { TDataList, validateError } = this.state;
    var rowIndex = TDataList.findIndex((t) => t.rowId == rowId);

    if (rowIndex > -1) {
      TDataList[rowIndex].poDate = e;
      this.setState({ TDataList }, () => {
        if (validateError) this.validateFields();
      });
    }
  };

  onAddRowClick = () => {
    var { TDataList } = this.state;
    var lastInsertedRowId = 0;
    if (TDataList.length > 0) {
      lastInsertedRowId = TDataList[TDataList.length - 1].rowId;
    }
    TDataList = this.addNewRow(lastInsertedRowId + 1, TDataList);
    this.setState({ TDataList });
  };

  onDeleteRowClick = (rowId) => {
    var { TDataList } = this.state;
    if (TDataList.length > 0) {
      var itemIndex = TDataList.findIndex((t) => t.rowId == rowId);
      if (itemIndex > -1) TDataList.splice(itemIndex, 1);
      this.setState({ TDataList });
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  render() {
    const {
      onCloseClick,
      dropdownList
    } = this.props;

    const {
      fileInputKey,
      validateError,
      emptyFileError,
      fileName,
      errorMessages,
      shipNameList,
      dropdown_applicableTo,
      dropdown_ship,
      dropdown_region,
      dropdownList_applicableToList_Filtered,
      dropdown_client,
      toastVisible,
      toastType,
      toastMessage,
    } = this.state;

    return (
      <div className="Training_Evalution" style={{ display: "block" }}>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
          <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload Document</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick("showUploadModal", false)}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="PO-list-topLabel">
                  <div className="row top">
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorClient)
                            ? "invalid-position"
                            : ""
                        }
                        id={"clientName"}
                      >
                        <label className="required-field">Client:</label>
                        <Dropdown
                          //  dropdownLabel="Client:"
                          name="client"
                          onChange={this.onDropdownChangeClient}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                          selected={dropdown_client}
                        >
                          {dropdownList.clientList == null
                            ? null
                            : dropdownList.clientList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_client > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"clientName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.clientList != null && dropdownList.clientList.filter(x => x.value == dropdown_client)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.errorClient) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div id={"shipName"}>
                        <Dropdown
                          dropdownLabel="Ship:"
                          name="ship"
                          onChange={this.onDropdownChangeShip}
                          selected={dropdown_ship}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {shipNameList == null
                            ? null
                            : shipNameList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_ship > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"shipName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {shipNameList != null && shipNameList.filter(x => x.value == dropdown_ship)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 data" id={"regon"}>
                      <Dropdown
                        dropdownLabel="Region:"
                        name="region"
                        onChange={this.onDropdownChangeRegion}
                        disabled={true}
                        selected={dropdown_region}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {dropdownList.regionList == null
                          ? null
                          : dropdownList.regionList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_region > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"regon"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdownList.regionList != null && dropdownList.regionList.filter(x => x.value == dropdown_region)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorDocumentType)
                            ? "invalid-position"
                            : ""
                        }
                        id={"DocType"}
                      >
                        <label className="required-field">Document Type:</label>
                        <Dropdown
                          //  dropdownLabel="Document Type:"
                          name="documentType"
                          selected={this.state.dropdown_documentType}
                          onChange={this.onDropdownChangeDocumentType}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.documentTypeList == null
                            ? null
                            : dropdownList.documentTypeList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {this.state.dropdown_documentType != "" ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"DocType"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.documentTypeList != null && dropdownList.documentTypeList.filter(x => x.value == this.state.dropdown_documentType)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.errorDocumentType) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorDocumentName)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <label className={this.state.dropdown_documentType > 0 && this.state.dropdown_documentType == "42" ? "label-class label-mobile label-dropdown required-field" : "label-class label-mobile label-dropdown"}>
                          Document Name:
                        </label>

                        <input
                          autoFocus={false}
                          autoComplete={"off"}
                          className="form-control"
                          type="text"
                          id="documentName"
                          name="documentName"
                          value={this.state.input_documentName}
                          onChange={this.onTextChange}
                          maxLength={30}
                        />
                        {!isStringEmpty(errorMessages.errorDocumentName) && (
                          <div className="training-invalid-message-icon ">
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorApplicableTo)
                            ? "invalid-position invalid-position-multi-dropdown"
                            : ""
                        }
                      >
                        <label className="label-class required-field">Applicable To:</label>
                        <span className="info">
                          <img
                            src="images/info.png"
                            height="18px"
                            width="18px"
                            alt=""
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="who can view the document"
                          />
                        </span>
                        <MultiSelectDropdown
                          selectedOptions={dropdown_applicableTo == undefined ? [] : dropdown_applicableTo}
                          options={dropdownList_applicableToList_Filtered == undefined ? [] : dropdownList_applicableToList_Filtered}
                          onChange={this.onDropdownChangeApplicableTo}
                          isMulti={true}
                        />
                        {!isStringEmpty(errorMessages.errorApplicableTo) && (
                          <ErrorView errorFor="emptyDropdown" />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="choosefile-btn">
                      <label
                        htmlFor="myfile"
                        className={
                          "attach-btn attach-btn-responsive chs-btn " +
                          (!isStringEmpty(errorMessages.errorTFile)
                            ? "invalid-position-border"
                            : "")
                        }
                      >
                        <img
                          className="upload-img"
                          src="images/Icon awesome-upload.png"
                          width="18px"
                        />
                        &nbsp;Choose File
                      </label>
                      {!isStringEmpty(errorMessages.errorTFile) && (
                        <img
                          src="images\icon material-error.png"
                          alt="invalid"
                          className="invalid-position-img"
                        />
                      )}
                      <label className="required-field">&nbsp; (Please select a PDF or Document file)</label>
                      {/*  &nbsp; (Please select a PDF or Document file)*/}

                      <input
                        type="file"
                        accept={validFileFormatsReadable}
                        id="myfile"
                        name="TFile"
                        onChange={this.onFileUpload}
                        key={fileInputKey || ""}
                        style={{ display: "none", visibility: "none" }}
                      />

                    </div>
                    <label className="label-breakword">&nbsp;&nbsp;&nbsp;{fileName}</label>
                  </div>
                </div>

                {(validateError || emptyFileError) && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}

                {/* {emptyFileError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please upload a valid file.</p>
                  </div>
                )} */}

                {/* {invalidFileError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Wrong file attached!, Please upload a valid file.</p>
                  </div>
                )} */}


                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-background-color upload-btn"
                    onClick={this.onUploadClick}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showTypeNameModal && (
            <div className="supplier_on_hold">
              <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
              <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Add Type</h4>
                      <button type="button" data-bs-dismiss="modal">
                        <img
                          src="images/modal-close.png"
                          alt="modal-close"
                          onClick={this.onCloseTypeClick}
                        />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row content">
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                          <div className={
                            !isStringEmpty(errorMessages.errorTypeName) || !isStringEmpty(errorMessages.DocTypeExist)
                              ? "invalid-position"
                              : ""
                          }>
                            <label htmlFor="todate">Type Name:</label>
                            <br />
                            <input
                              type="typeName"
                              className="form-control"
                              id="typeName"
                              name="typeName"
                              placeholder="Enter Type Name"
                              value={this.state.typeName}
                              onChange={this.ontypeNameChange}
                              maxLength={50}
                            />
                            {(!isStringEmpty(errorMessages.errorTypeName)) || (!isStringEmpty(errorMessages.DocTypeExist)) && (
                              <div className="misc-invalid-message-icon ">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.errorTypeName) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                      <div
                        className="invalid-message-icon-box"
                        style={{ display: !isStringEmpty(errorMessages.DocTypeExist) ? "" : "none" }}
                      >
                        <img src="images\icon material-error.png" alt="invalid"></img>
                        <p>Document type already exists.</p>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-background-color save-btn"
                          onClick={this.ontypeNameSaveClicked}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
