import React from "react";
import { ErrorViewFor } from "../helpers/enums";
const ErrorElementView = ({ errorFor }) => {
  const GetDIVClassName = () => {
    switch (errorFor) {
      case ErrorViewFor.INVALID_DROPDOWN_ICON:
        return "invalid-dropdown-icon";
      case ErrorViewFor.INVALID_MESSAGE_ICON:
        return "invalid-message-icon";
      case ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON:
        return "invalid-dropdown-icon-modal";
      default:
        return "invalid-dropdown-icon-modal";
    }
  };
  return (
    <>
      {errorFor != "" && (
        <div className={GetDIVClassName()}>
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};
export default ErrorElementView;
