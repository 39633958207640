import React, { useEffect, useState } from "react";
import DatePickerComponent from "../../components/DatePickerComponent";
import DeleteModal from "../../components/DeleteModel";
import ErrorElementView from "../../components/ErrorElementView";
import ErrorMessageBox from "../../components/ErrorMessageBox";
import { GetLocalStorageDataWithParse, helperText } from "../../helpers";
import { ErrorViewFor } from "../../helpers/enums";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import { settingsService } from "../../services/settings.service";

const SDORegulationsSettings = ({
  SettingsData,
  ToggleLoader,
  ShowToastModal,
}) => {
  const [ctiRegulationsData, setCtiRegulationsData] = useState([]);
  const [numberOfAutomatedReminder, setNumberOfAutomatedReminder] = useState(0);
  const [reminderFrequency, setReminderFrequency] = useState([]);
  const [useID, setUseID] = useState(0);
  const [documentNumber, setDocumentNumber] = useState("");
  const [tittle, setTittle] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState(null);
  const [erroMessages, setErroMessages] = useState({
    errorDocumentNumber: false,
    errorTitle: false,
    errorDataOfIssue: false,
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState(0);

  useEffect(() => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (SettingsData) {
      var { ctiRegulationsModels } = SettingsData;
      setCtiRegulationsData(ctiRegulationsModels);
    }
    setUseID(userData ? userData.id : 0);
    return () => { };
  }, [SettingsData]);

  useEffect(() => {
    if (
      !erroMessages.errorDataOfIssue &&
      !erroMessages.errorDocumentNumber &&
      !erroMessages.errorTitle &&
      hasErrors
    ) {
      setHasErrors(false);
    }

    return () => { };
  }, [erroMessages]);

  const OnDocumentNumberChange = (e) => {
    var { value } = e.target;
    setDocumentNumber(value);
    setErroMessages((t) => ({ ...t, errorDocumentNumber: false }));
  };
  const OnTittleChange = (e) => {
    var { value } = e.target;
    setTittle(value);
    setErroMessages((t) => ({ ...t, errorTitle: false }));
  };
  const OnDateOfIssueChange = (e) => {
    setDateOfIssue(e);
    setErroMessages((t) => ({ ...t, errorDataOfIssue: false }));
  };
  const AddNewItem = () => {
    var { isValid, _errorMessages } = IsNewEntryValid();
    if (isValid) {
      setCtiRegulationsData((t) => [
        ...t,
        {
          documentNumber,
          tittle,
          dateOfIssue: dateOfIssue,
          ctiRegulationsID: 0,
          editedBy: useID,
        },
      ]);
      setDocumentNumber("");
      setTittle("");
      setDateOfIssue(null);
    } else {
      ShowErrorMessage(isValid, _errorMessages);
    }
  };

  const ShowErrorMessage = (isValid, _errorMessages) => {
    setErroMessages({ ..._errorMessages });
    setHasErrors(!isValid);
  };

  const IsNewEntryValid = () => {
    let isValid = true;
    let _errorMessages = erroMessages;
    if (documentNumber == undefined || documentNumber == "") {
      isValid = false;
      _errorMessages.errorDocumentNumber = true;
    }
    if (tittle == undefined || tittle == "") {
      isValid = false;
      _errorMessages.errorTitle = true;
    }
    if (dateOfIssue == undefined || dateOfIssue == "") {
      isValid = false;
      _errorMessages.errorDataOfIssue = true;
    }
    // setErroMessages({ ..._errorMessages });
    // setHasErrors(!isValid);
    return { isValid, _errorMessages };
  };

  const DeleteItem = (index) => {
    setShowDeleteModal(false);
    if (ctiRegulationsData[index].ctiRegulationsID == 0) {
      let _ctiRegulationsData = ctiRegulationsData;
      _ctiRegulationsData.splice(index, 1);
      setCtiRegulationsData([..._ctiRegulationsData]);
    } else {
      DeleteItemFromDb(index);
    }
  };

  const DeleteItemFromDb = async (indexToBeDelete) => {
    ToggleLoader();
    var response = await settingsService.DeleteCTIRegulationItem(
      ctiRegulationsData[indexToBeDelete].ctiRegulationsID
    );
    ToggleLoader();
    if (response.status == 200) {
      let _ctiRegulationsData = ctiRegulationsData;
      _ctiRegulationsData.splice(indexToBeDelete, 1);
      setCtiRegulationsData([..._ctiRegulationsData]);
      ShowToastModal(
        helperText.SettingsPageText.ctiRegulationDeleteSuccess,
        helperText.global.toastSuccess
      );
    } else {
      ShowToastModal(
        helperText.global.somethingWentWrong,
        helperText.global.toastError
      );
    }
  };
  const isAllEmpty = () => {
    let isEmpty = false;
    if (
      (documentNumber == undefined || documentNumber == "") &&
      (tittle == undefined || tittle == "") &&
      (dateOfIssue == undefined || dateOfIssue == "")
    ) {
      isEmpty = true;
    }
    return isEmpty;
  };
  const SaveCTIRegulationsSettings = async () => {
    var dataToSave = ctiRegulationsData.filter((t) => t.ctiRegulationsID == 0);
    var saveData = true;
    if (dataToSave.length == 0 && isAllEmpty()) {
      //Will display error on screen
      var { isValid, _errorMessages } = IsNewEntryValid();
      ShowErrorMessage(isValid, _errorMessages);
      saveData = false;
    } else if (!isAllEmpty()) {
      var { isValid, _errorMessages } = IsNewEntryValid();
      if (!isValid) {
        saveData = false;
        ShowErrorMessage(isValid, _errorMessages);
      }
    }

    if (saveData) {
      if (!isAllEmpty()) {
        dataToSave.push({
          documentNumber,
          tittle,
          dateOfIssue: convertDate(dateOfIssue),
          ctiRegulationsID: 0,
          editedBy: useID,
        });
      }
      if (dataToSave.length > 0) {
        ToggleLoader();
        var response = await settingsService.SaveCTIRegulationsSettings(
          dataToSave
        );
        ToggleLoader();
        if (response.status == 200) {
          ShowToastModal(
            helperText.SettingsPageText.ctiRegulationUpdateSuccess,
            helperText.global.toastSuccess
          );
          setCtiRegulationsData(response.data);
          setDateOfIssue(null);
          setDocumentNumber("");
          setTittle("");
        } else {
          ShowToastModal(
            helperText.global.somethingWentWrong,
            helperText.global.toastError
          );
        }
      }
    }
  };

  /* <summary>
     date: 24/02/2023
     Name: HV
     description: delete modal close.
    <summary> */

  const onDeleteModalCloseClick = () => {
    setShowDeleteModal(false);
  }

  /* <summary>
   date: 24/02/2023
   Name: HV
   description: delete modal open and setting rowid to delete.
  <summary> */

  const openDeleteConfirmationModal = (id) => {
    setRowIdToDelete(id);
    setShowDeleteModal(true);
  }


  return (
    <div id="SDoC-regulations" className="tabcontent">
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          onCloseClick={onDeleteModalCloseClick}
          onYesClick={() => DeleteItem(rowIdToDelete)}
          onNoClick={onDeleteModalCloseClick}
          modalheader="Delete Training Item"
        ></DeleteModal>
      )}
      <div className="tabcontent-data">
        <p>SDoC regulations can be added or edited here.</p>
        <div className="table-responsive-xxl">
          <table className="table sdoc-table table-borderless">
            <thead className="sdoc-table-head">
              <tr>
                <th className="doc-no">
                  <h5>Document Number</h5>
                </th>
                <th className="title">
                  <h5>Title</h5>
                </th>
                <th className="date-of-issue">
                  <h5>Date of Issue</h5>
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="sdoc-table-body">
              {ctiRegulationsData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="margin-number-icon table-validation">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        value={item.documentNumber}
                        title={item.documentNumber}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      />
                    </td>
                    <td className="margin-number-icon table-validation">
                      <input
                        disabled
                        type="name"
                        className="form-control"
                        value={item.tittle}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                        title={item.tittle}
                      />
                    </td>
                    <td className="table-validation table-date-validation">
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        value={item.dateOfIssue}
                      />
                    </td>
                    <td
                      className="margin-date-icon"
                    >
                      <img
                        src="images/delete icon.png"
                        alt="delete"
                        className="delete-icon"
                        onClick={() => openDeleteConfirmationModal(index)}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="margin-number-icon table-validation">
                  <input
                    type="text"
                    className="form-control"
                    value={documentNumber}
                    onChange={OnDocumentNumberChange}
                    maxLength={50}
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      overflow: "hidden",
                    }}
                  />
                  {erroMessages.errorDocumentNumber && (
                    <ErrorElementView
                      errorFor={ErrorViewFor.INVALID_MESSAGE_ICON}
                    />
                  )}
                </td>
                <td className="margin-number-icon table-validation">
                  <input
                    type="name"
                    className="form-control"
                    value={tittle}
                    onChange={OnTittleChange}
                    maxLength={150}
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      overflow: "hidden",
                    }}
                  />
                  {erroMessages.errorTitle && (
                    <ErrorElementView
                      errorFor={ErrorViewFor.INVALID_MESSAGE_ICON}
                    />
                  )}
                </td>
                <td className="table-validation" style={{ width: "20%" }}>
                  {/*
                   table-date-validation
                  <input
                    type="date"
                    className="form-control"
                    value={dateOfIssue}
                    onChange={OnDateOfIssueChange}
                  /> */}
                  <DatePickerComponent
                    selectedDate={dateOfIssue}
                    onDateChange={OnDateOfIssueChange}
                  />
                  {erroMessages.errorDataOfIssue && (
                    <ErrorElementView
                      errorFor={ErrorViewFor.INVALID_MESSAGE_ICON}
                    />
                  )}
                </td>
                <td className="margin-date-icon">
                  <img
                    onClick={AddNewItem}
                    src="images/add.png"
                    alt="add"
                    className="plus-icon"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {hasErrors && <ErrorMessageBox />}
        </div>
      </div>
      <div className=" admin-ship-settings-save-btn settings-save-btn">
        <button
          className="btn btn-block"
          type="button"
          onClick={SaveCTIRegulationsSettings}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default SDORegulationsSettings;
