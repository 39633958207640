import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { GetLocalStorageDataWithoutParse, withRouter } from "../../helpers";
import DatePicker from "../../components/DatePickerComponent";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import {
  generateRandomString,
  helperText,
  isEmptyObject,
  isValidFile,
} from "../../helpers";
import { IHMDatabaseRegistryService } from "../../services/ihmdatabaseregistry.service";
import { error } from "jquery";
import Toast from "../../components/Toast";
import { UncontrolledTooltip } from "reactstrap";
import { Module } from "../../helpers/enums";
import { CommonService } from "../../services/common.service";

const validFileFormats = ["xlsx,xls,docx,pdf,PDF"];
const validFileFormatsReadable =
  "application/pdf,application/PDF,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const validMdsdocFormat = ["pdf,PDF"];

const validMdsdocReadableFormat = "application/pdf,application/PDF";


const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon"
              : "invalid-message-icon-IHMDatabaseRegistry"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};
class IHMDatabaseRegistryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_client: 0,
      dropdown_ship: 0,
      dropdown_documenttype: 0,
      dropdown_hazmatpresent: 0,
      dropdown_supplier: 0,
      dropdown_flagstate: 0,
      issuedBy: "",
      preparedBy: "",
      issueDate: "",
      expiryDate: "",
      fileInputKey: "",
      fileName: "",
      filePath: "",
      TFile: null,
      showErrorMessage: false,
      errorMessages: {},
      dropdown_lineName: "",
      mdSoc: false,
      lineName: "",
      fileErrorMessage: false,
      shipNameList: [],
      defaultShipNameList: [],
      yearList: [],
      dropdown_year: 0,
      nextIssueDate: "",
      previousExpiryDate: "",
      projectno: "",
      displayprojectno: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      showTypeNameModal: false,
      typeName: "",
      defaultLineNameList: this.props.lineNameList || [],
    };
  }

  componentDidMount() {
    const { shipList, trackingToolYearList } = this.props;
    this.setState({
      defaultShipNameList: shipList,
      yearList: trackingToolYearList,
    });
  }

  /* <summary>
    date: 3/2/2023
    Name:NGG
    description:sets file state on File upload 
    <summary> */
  onFileUpload = async (e) => {
    const { errorMessages, dropdown_documenttype } = this.state;
    let errors = errorMessages;
    const { name, value } = e.target;
    let files = e.target.files[0];
    if (!isValidFile(e.target.value, dropdown_documenttype == 1 ? validMdsdocFormat : validFileFormats)) {
      if (this.state.filePath == "") {
        this.setState({
          fileInputKey: generateRandomString,
          [name]: null,
        });
      }

      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileError,
      });

      return;
    }
    //errors["errorClient"] = dropdown_client === 0 ? "true" : "";
    errors["errorTFile"] = "";

    this.setState({
      errorMessages,
      [name]: files,
      fileName: files.name,
      filePath: value,
      fileErrorMessage: false,
    });
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:validates the required fields
       <summary> */
  //HV - 22/03/2023 - removed client from mandatory fields
  validateFields = () => {
    const {
      errorMessages,
      dropdown_client,
      dropdown_documenttype,
      showErrorMessage,
      TFile,
    } = this.state;

    let errors = errorMessages;
    var fileCheck = false;
    if (TFile == null) {
      fileCheck = true;
    }
    //errors["errorClient"] = dropdown_client === 0 ? "true" : "";
    errors["errorDocumentType"] = dropdown_documenttype == 0 ? "true" : "";
    errors["errorTFile"] = fileCheck ? "true" : "";

    if (errors["errorDocumentType"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: true,
      });
      return true;
    } else if (fileCheck) {
      this.setState({
        showErrorMessage: false,
        fileErrorMessage: true,
      });
      return true;
    }

    this.setState({
      errorMessages,
      showErrorMessage: false,
      fileErrorMessage: false,
    });

    return false;
  };

  /* <summary>
       date: 3/2/2023
       Name:NGG
       description:sets date value on date change
       <summary> */
  //HV - 24/03/2023 - handled expiry date should be greater than issue date

  onDateChanged = (id, e) => {
    if (id == "issuedate") {
      var nextIssueDate = new Date(e);
      nextIssueDate.setDate(e.getDate() + 1);
      nextIssueDate.toLocaleDateString();
      this.setState({ issueDate: e, nextIssueDate: nextIssueDate }, () => { });
    }
    if (id == "expirydate") {
      var previousExpiryDate = new Date(e);
      previousExpiryDate.setDate(e.getDate() - 1);
      previousExpiryDate.toLocaleDateString();
      this.setState(
        { expiryDate: e, previousExpiryDate: previousExpiryDate },
        () => { }
      );
    }
  };

  /* <summary>
     date: 3/2/2023
     Name:NGG
     description:sets dropdown value on drodown value change
     <summary> */
  onDropdownItemSelect = async (e) => {
    const { showErrorMessage, TFile, fileName } = this.state;
    const {
      shipList,
      clientList,
      trackingToolYearList,
      lineNameList,
      trackingToolList,
    } = this.props;
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (e.target.name == "dropdown_documenttype") {
          if (e.target.value == "1"){

            if(TFile != null){

              if(!fileName?.toLowerCase()?.includes(".pdf")){
                this.setState({
                  fileInputKey: generateRandomString,
                  TFile: null,
                  fileName: "",
                  filePath: "",
                });
              }
            }
           this.setState({ mdSoc: true, displayprojectno: false });
          }
          else if (value == 14 || value == 9 || value == 12 || value == 36) {
            this.setState({
              mdSoc: false,
              dropdown_hazmatpresent: 0,
              dropdown_lineName: "",
              dropdown_year: 0,
              displayprojectno: true,
            });
          } else {
            this.setState({
              mdSoc: false,
              dropdown_hazmatpresent: 0,
              dropdown_lineName: "",
              dropdown_year: 0,
              displayprojectno: false,
              projectno: "",
            });
          }
          var othersSelected = this.props.documentTypeList.filter(
            (x) => x.value == value
          )[0].label;
          this.setState({ showTypeNameModal: othersSelected === "Other" });
        }
        if (e.target.name == "dropdown_client") {
          var filteredShipList = shipList.filter(
            (x) => x.parentValue == e.target.value
          );
          var filteredYearList = trackingToolYearList.filter(
            (x) => x.parentValue == e.target.value
          )?.sort((a, b) => a.year - b.year)?.reverse();

          this.setState({
            shipNameList: filteredShipList,
            yearList: filteredYearList,
            dropdown_ship: 0,
          });
        }

        if (name == "dropdown_client" || name == "dropdown_ship") {
          const { dropdown_client, dropdown_ship } = this.state;
          var filteredTrackingToolData = trackingToolList.filter(
            (t) => t.parentValue == dropdown_client && t.value != null
          );

          if (name == "dropdown_ship" && dropdown_ship != 0) {
            filteredTrackingToolData = filteredTrackingToolData.filter(
              (t) => t.shipId == dropdown_ship
            );
          }
          let filteredLineItem = [];
          lineNameList.forEach((item) => {
            var rowDataList = filteredTrackingToolData.filter((e) =>
              e.label.toLowerCase().includes(item.toLowerCase())
            );
            if (rowDataList.length > 0) {
              filteredLineItem.push(item);
            }
          });
          this.setState({
            defaultLineNameList:
              dropdown_client != 0 ? filteredLineItem : lineNameList,
          });
        }

        if (showErrorMessage) this.validateFields();
      }
    );
  };

  onCloseTypeClick = () => {
    var { errorMessages } = this.state;
    let errors = errorMessages;
    errors["errorTypeName"] = "";
    errorMessages["errorDocumentType"] = "";
    this.setState({
      dropdown_documenttype: 0,
      showTypeNameModal: false,
      typeName: "",
      errorMessages: errors,
    });
  };
  
  ontypeNameChange = (e) => {
    var { errorMessages } = this.state;
    let errors = errorMessages;

    this.setState({ typeName: e.target.value }, () => {
      if (!isStringEmpty(errorMessages.errorTypeName)) {
        errors["errorTypeName"] = e.target.value.trim() === "" ? "true" : "";
        this.setState({
          errorMessages: errors,
        });
      } else if (!isStringEmpty(errorMessages.DocTypeExist)) {
        this.CheckIfDocTypeExist(e.target.value.trim());
      }
    });
  };
  ontypeNameSaveClicked = async (e) => {
    var { errorMessages } = this.state;
    let errors = errorMessages;
    if (this.state.typeName.trim() != "") {
      await this.CheckIfDocTypeExist(this.state.typeName.trim());

      if (isStringEmpty(errorMessages.DocTypeExist)) {
        errors["errorTypeName"] = "";
        errorMessages["errorDocumentType"] = "";
        await this.props.onSaveNewTypeClick(this.state.typeName.trim());

        this.setState({
          showTypeNameModal: false,
          dropdown_documenttype:
            GetLocalStorageDataWithoutParse("selecteddoctype") != null
              ? GetLocalStorageDataWithoutParse("selecteddoctype")
              : 0,
          typeName: "",
          errorMessages: errors,
        });
      }
    } else {
      errors["errorTypeName"] = "true";
      this.setState({
        errorMessages: errors,
      });
    }
  };

  CheckIfDocTypeExist = async (typeName) => {
    let errors = this.state.errorMessages;

    var docData = {
      TypeName: typeName,
      Module: Module.IHMRegistry
    }

    const response = await CommonService.CheckIfDocumentTypeExist(docData);

    if (
      response.data != null &&
      response.data != undefined &&
      response.status == 200
    ) {
      if (response.data === true) {
        errors["DocTypeExist"] = "true";
      } else {
        errors["DocTypeExist"] = "";
      }
      this.setState({
        errorMessages: errors,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
  };
  /* <summary>
   date: 3/2/2023
   Name:NGG
   description:hits on file upload
   <summary> */
  onUploadBtnClick = () => {
    const { onUploadClick } = this.props;
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_documenttype,
      dropdown_hazmatpresent,
      dropdown_supplier,
      dropdown_flagstate,

      issueDate,
      expiryDate,

      TFile,
      dropdown_lineName,
      dropdown_year,
      lineName,
      mdSoc,
      issuedBy,
      preparedBy,
      projectno,
    } = this.state;

    var hasErrors = this.validateFields();
    if (hasErrors) return;

    const data = {
      dropdown_client: dropdown_client == 0 ? "" : dropdown_client,
      dropdown_ship: dropdown_ship == 0 ? "" : dropdown_ship,
      dropdown_documenttype: dropdown_documenttype,
      dropdown_hazmatpresent: dropdown_hazmatpresent,
      dropdown_supplier: dropdown_supplier == 0 ? "" : dropdown_supplier,
      dropdown_flagstate: dropdown_flagstate == 0 ? "" : dropdown_flagstate,
      lineName: dropdown_lineName,
      issueDate: issueDate,
      expiryDate: expiryDate,
      dropdown_year: dropdown_year,
      TFile: TFile,
      mdSoc: mdSoc,
      issuedBy: issuedBy,
      preparedBy: preparedBy,
      project_num: projectno,
    };
    onUploadClick(data);
  };

  onTextChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };

  render() {
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_documenttype,
      dropdown_supplier,
      dropdown_flagstate,
      issuedBy,
      preparedBy,
      issueDate,
      expiryDate,
      fileInputKey,
      fileName,
      errorMessages,
      showErrorMessage,
      dropdown_hazmatpresent,
      dropdown_lineName,
      mdSoc,
      fileErrorMessage,
      shipNameList,
      yearList,
      dropdown_year,
      nextIssueDate,
      previousExpiryDate,
      toastVisible,
      toastType,
      toastMessage,
      defaultLineNameList,
      displayprojectno
    } = this.state;

    const {
      onCloseClick,
      clientList,
      shipList,
      documentTypeList,
      supplierList,
      flagStateList,
      issuedByList,
      preparedByList,
      onUploadClick,
    } = this.props;
    return (
      <div className="IHMDBRegistryUpload" style={{ display: "block" }}>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div
          className="modal"
          id="IHMDBRegistryUpload-modal"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-box">
            <div className="modal-content box">
              {/* <!-- Modal Header --> */}
              <div className="modal-header">
                <h4 className="modal-title">Upload Document</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick()}
                  />
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 data">
                    <div className="flag-state">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorClient)
                            ? "invalid-position"
                            : ""
                        }
                        id={"clientName"}
                      >
                        <Dropdown
                          dropdownLabel="Client:"
                          name="client"
                          onChange={this.onDropdownItemSelect}
                          selected={dropdown_client}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {clientList == null
                            ? null
                            : clientList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_client > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"clientName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {clientList != null && clientList.filter(x => x.value == dropdown_client)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.errorClient) && (
                          <ErrorView errorFor="emptyText" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data" id={"shipName"}>
                    <Dropdown
                      dropdownLabel="Ship:"
                      name="ship"
                      onChange={this.onDropdownItemSelect}
                      selected={dropdown_ship}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre",
                        overflow: "hidden",
                      }}
                    >
                      {shipNameList == null
                        ? null
                        : shipNameList.map((item, key) => (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      ;
                    </Dropdown>
                    {dropdown_ship > 0 ? (
                      <UncontrolledTooltip
                        autohide={false}
                        target={"shipName"}
                        aria-haspopup="true"
                        role="example"
                        effect="solid"
                        placement="bottom"
                        style={{ width: "350px" }}
                      >
                        {shipNameList != null && shipNameList.filter(x => x.value == dropdown_ship)[0]?.label}
                      </UncontrolledTooltip>) : null}
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 data" id={"docType"}>
                    <div
                      className={
                        !isStringEmpty(errorMessages.errorDocumentType)
                          ? "invalid-position"
                          : ""
                      }
                      id={"docType"}
                    >
                      <label className="required-field">Document Type:</label>
                      <Dropdown
                        // dropdownLabel="Document Type:"
                        name="documenttype"
                        onChange={this.onDropdownItemSelect}
                        selected={dropdown_documenttype}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {documentTypeList == null
                          ? null
                          : documentTypeList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_documenttype > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"docType"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {documentTypeList != null && documentTypeList.filter(x => x.value == dropdown_documenttype)[0]?.label}
                        </UncontrolledTooltip>) : null}
                      {!isStringEmpty(errorMessages.errorDocumentType) && (
                        <ErrorView errorFor="emptyText" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 data" id={"supplierName"}>
                    <Dropdown
                      dropdownLabel="Supplier:"
                      name="supplier"
                      onChange={this.onDropdownItemSelect}
                      selected={dropdown_supplier}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "pre",
                        overflow: "hidden",
                      }}
                    >
                      {supplierList == null
                        ? null
                        : supplierList.map((item, key) => (
                          <option key={key} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      ;
                    </Dropdown>
                    {dropdown_supplier > 0 ? (
                      <UncontrolledTooltip
                        autohide={false}
                        target={"supplierName"}
                        aria-haspopup="true"
                        role="example"
                        effect="solid"
                        placement="bottom"
                        style={{ width: "350px" }}
                      >
                        {supplierList != null && supplierList.filter(x => x.value == dropdown_supplier)[0]?.label}
                      </UncontrolledTooltip>) : null}
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{
                      display: this.state.displayprojectno ? "" : "none",
                    }}
                  >
                    <div className="approved-by">
                      <label htmlFor="projectno">Project Number:</label>
                      <input
                        type="text"
                        className="form-control "
                        id="projectno"
                        name="projectno"
                        value={this.state.projectno}
                        onChange={this.onTextChange}
                        maxLength={20}
                      />
                    </div>
                  </div>

                  {mdSoc && (
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 data" id={"year"}>
                          <Dropdown
                            dropdownLabel="Tracking Tool Year:"
                            name="year"
                            onChange={this.onDropdownItemSelect}
                            selected={dropdown_year}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {yearList == null || isStringEmpty(dropdown_client) || dropdown_client == 0
                              ? null
                              : yearList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_year > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"year"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {yearList != null && yearList.filter(x => x.value == dropdown_year)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 data" id={"linenNo"}>
                          <Dropdown
                            dropdownLabel="Item Name:"
                            name="lineName"
                            onChange={this.onDropdownItemSelect}
                            selected={dropdown_lineName}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {defaultLineNameList == null
                              ? null
                              : defaultLineNameList.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_lineName != undefined && dropdown_lineName != null && dropdown_lineName != "" ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"linenNo"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {defaultLineNameList != null && defaultLineNameList.filter(x => x == dropdown_lineName)}
                            </UncontrolledTooltip>) : null}
                        </div>
                      </div>
                    </div>
                  )}

                  {mdSoc && (
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <Dropdown
                        dropdownLabel="Hazmat Present:"
                        name="hazmatpresent"
                        onChange={this.onDropdownItemSelect}
                        selected={dropdown_hazmatpresent}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        <option key={1} value={"yes"}>
                          {"Yes"}
                        </option>
                        <option key={2} value={"no"}>
                          {"No"}
                        </option>
                        ;
                      </Dropdown>
                    </div>
                  )}
                </div>

                <fieldset className=" p-2 admin-ship-fieldset">
                  <legend className="float-none w-auto p-2 admin-ship-legend">
                    Additional Details
                  </legend>
                  <div className="row">
                    <div className=" col-lg-4 col-md-12 ">
                      <div className="issue-date">
                        <label htmlFor="issuedate">Issue Date:</label>
                        <DatePicker
                          min="0"
                          selectedDate={issueDate}
                          onDateChange={(e) =>
                            this.onDateChanged("issuedate", e)
                          }
                          isNotRoot={true}
                          maxDate={previousExpiryDate}
                        >
                          {" "}
                        </DatePicker>
                      </div>
                    </div>
                    <div className=" col-lg-4 col-md-12">
                      <div className="expiry-date">
                        <label htmlFor="expirydate">Expiry Date:</label>
                        <DatePicker
                          min="0"
                          selectedDate={expiryDate}
                          onDateChange={(e) =>
                            this.onDateChanged("expirydate", e)
                          }
                          isNotRoot={true}
                          minDate={nextIssueDate}
                        >
                          {" "}
                        </DatePicker>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 ">
                      <div className="flag-state" id={"flag"}>
                        <Dropdown
                          dropdownLabel="Flag State:"
                          name="flagstate"
                          onChange={this.onDropdownItemSelect}
                          selected={dropdown_flagstate}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {flagStateList == null
                            ? null
                            : flagStateList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>

                        {dropdown_flagstate > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"flag"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {flagStateList != null && flagStateList.filter(x => x.value == dropdown_flagstate)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-4 col-md-12">
                      <div className="approved-by">
                        <label htmlFor="client">Issued By:</label>

                        <input
                          type="text"
                          className="form-control "
                          id="issuedBy"
                          name="issuedBy"
                          value={issuedBy}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="approved-by">
                        <label htmlFor="client">Prepared By:</label>

                        <input
                          type="text"
                          className="form-control "
                          id="preparedBy"
                          name="preparedBy"
                          value={preparedBy}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="choosefile-btn" style={{ marginTop: "20px" }}>
                  <input
                    type="file"
                    id="actual-btn"
                    accept={dropdown_documenttype == 1 ? validMdsdocReadableFormat : validFileFormatsReadable}
                    name="TFile"
                    onChange={this.onFileUpload}
                    key={fileInputKey || ""}
                    hidden
                  />
                  {/* <!-- our custom upload button --> */}
                  <label
                    htmlFor="actual-btn"
                    className={
                      "attach-btn attach-btn-responsive chs-btn " +
                      (!isStringEmpty(errorMessages.errorTFile)
                        ? "invalid-position-border"
                        : "")
                    }
                  >
                    <img
                      className="upload-img"
                      src="images/Icon awesome-upload.png"
                      width="16px"
                    />
                    &nbsp;
                    <span>Choose File</span>
                  </label>
                  {!isStringEmpty(errorMessages.errorTFile) && (
                    <img
                      src="images\icon material-error.png"
                      alt="invalid"
                      className="invalid-position-img"
                      style={{ width: "16px" }}
                    />
                  )}
                  <label className="required-field">
                    &nbsp; {dropdown_documenttype == 1 ? "Please select a PDF file" : "Please select Excel, Doc or PDF file"}
                  </label>
                  {/* &nbsp; (Please select Excel, Doc or PDF file) */}
                  <label className="label-breakword">&nbsp;&nbsp;&nbsp;{fileName}</label>
                </div>

                {fileErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please upload a file.</p>
                  </div>
                )}
                {showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                <div className="upload-btn">
                  <button
                    type="button"
                    className="btn btn-background-color ship-detail-upload-btn"
                    onClick={this.onUploadBtnClick}
                  >
                    {mdSoc &&
                      (this.state.dropdown_client != 0 ||
                        this.state.dropdown_ship != 0)
                      ? "Save and Navigate to Tracking Tool"
                      : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showTypeNameModal && (
          <div className="supplier_on_hold">
            <div
              className="modal-backdrop fade show"
              style={{ display: "block" }}
            ></div>
            <div
              className="modal"
              id="uploadPOlist"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-l modal-dialog-centered modal-box max-width-miscmodel">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Add Type</h4>
                    <button type="button" data-bs-dismiss="modal">
                      <img
                        src="images/modal-close.png"
                        alt="modal-close"
                        onClick={this.onCloseTypeClick}
                      />
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row content">
                      <div className=" col-lg-12 col-md-12 col-sm-12">
                        <div
                          className={
                            !isStringEmpty(errorMessages.errorTypeName) ||
                              !isStringEmpty(errorMessages.DocTypeExist)
                              ? "invalid-position"
                              : ""
                          }
                        >
                          <label htmlFor="todate">Type Name:</label>
                          <br />
                          <input
                            type="typeName"
                            className="form-control"
                            id="typeName"
                            name="typeName"
                            placeholder="Enter Type Name"
                            value={this.state.typeName}
                            onChange={this.ontypeNameChange}
                            maxLength={50}
                          />
                          {!isStringEmpty(errorMessages.errorTypeName) ||
                            (!isStringEmpty(errorMessages.DocTypeExist) && (
                              <div className="misc-invalid-message-icon ">
                                <img
                                  src="images\icon material-error.png"
                                  alt="invalid"
                                ></img>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div
                      className="invalid-message-icon-box"
                      style={{
                        display: !isStringEmpty(errorMessages.errorTypeName)
                          ? ""
                          : "none",
                      }}
                    >
                      <img
                        src="images\icon material-error.png"
                        alt="invalid"
                      ></img>
                      <p>Please fill in all the mandatory fields.</p>
                    </div>
                    <div
                      className="invalid-message-icon-box"
                      style={{
                        display: !isStringEmpty(errorMessages.DocTypeExist)
                          ? ""
                          : "none",
                      }}
                    >
                      <img
                        src="images\icon material-error.png"
                        alt="invalid"
                      ></img>
                      <p>Document type already exists.</p>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-background-color save-btn"
                        onClick={this.ontypeNameSaveClicked}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(IHMDatabaseRegistryModal);
