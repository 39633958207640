import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import YesNoDropdown from "../../components/YesNoDropdown";
import DatePicker from "../../components/DatePickerComponent";
import { helperText } from "../../helpers/helperText";
import { TrackingToolService } from "../../services/trackingTool.service";
import historyIcon from "../../assets/images/admin-history.png";
import viewIcon from "../../assets/images/Icon awesome-eye.png";
import deleteIcon from "../../assets/images/delete icon.png";
import downloadIcon from "../../assets/images/ship-download-document.png";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { convertDate, isStringEmpty, customSortDocumentStatusComparator } from "../../helpers/string-helper";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, withRouter } from "../../helpers";
import Toast from "../../components/Toast";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import { param } from "jquery";
import Logout from "../../helpers/Logout";
import StatusRenderer from "../../components/StatusRenderer";
import ViewModal from "../../components/ViewModal";
import {
  TitlePageNames,
  YesNoOptions,
  YesNoOptionsText,
} from "../../helpers/enums";
import ConfirmationModal from "../../components/ConfirmationModal";
import { UserAction, UserRole } from "../../helpers/enums";
import AlertModal from "../../components/AlertModal";
import SupplierDocumentModal from "../SupplierPortal/SupplierDocumentModal";
import { SupplierService } from "../../services/supplier.service";
import ViewDocumentHistoryModal from "../../components/ViewDocumentHistoryModal";
import eyeIcon from "../../assets/images/admin-eye.png";
import { UncontrolledTooltip } from "reactstrap";


const statusDataList = [
  { value: 1, label: "InComplete" },
  { value: 2, label: "Partially Complete" },
  { value: 3, label: "Completed" },
  { value: 4, label: "Other" },
];

const statusDocDataList = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Complete" },
  { value: 3, label: "Resend for correction" },
];

const tableAListPrePopulated = [
  { value: 1, label: "A-1 Asbestos" },
  { value: 2, label: "A-2 Polychlorinated biphenyls (PCBs)" },
  { value: 3, label: "A-3 Ozone depleting substances" },
  { value: 4, label: "A-4 Anti-fouling systems containing organotin compounds as a biocide" },
  { value: 5, label: "A-5 Perfluorooctane sulfonic acid(PFOS)" },
];

const tableBListPrePopulated = [
  { value: 1, label: "B-1 Cadmium and cadmium compounds" },
  { value: 2, label: "B-2 Hexavalent chromium and hexavalent chromium compounds" },
  { value: 3, label: "B-3 Lead and lead compounds" },
  { value: 4, label: "B-4 Mercury and mercury compounds" },
  { value: 5, label: "B-5 Polybrominated biphenyl (PBBs)" },
  { value: 6, label: "B-6 Polybrominated diphenyl ethers (PBDEs)" },
  { value: 7, label: "B-7 Polychlorinated naphthalenes (PCNs)" },
  { value: 8, label: "B-8 Radioactive substances" },
  { value: 9, label: "B-9 Certain shortchain chlorinated paraffins (SCCPs)" },
  { value: 10, label: "B-10 Brominated Flame Retardant (HBCDD)" },
];

class AddEditTrackingTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastMessage: null,
      toastType: "",
      toastVisible: false,
      errorMessages: {},
      showErrorMessage: false,

      poNumber: "",
      poDate: "",
      masterCategory: "",
      referenceNumber: "",
      lineItemNo: "",
      description: "",
      supplierName: "",
      supplierEmail: "",
      ttUnit: "",
      ttQty: "",
      partName: "",
      subPartName: "",
      equipmentName: "",
      supplierAddress: "",
      supplierCompany: "",
      supplierContact: "",
      supplierReminder: "",
      reminderCount: "",
      hazmatPart1: 0,
      labTesting: "",
      communicationStartDate: "",
      lastCommunicationDate: "",
      mdsdocRequestedDate: "",
      mdsdocReceivedDate: "",
      lastReminderDate: "",

      dropdown_remark: 0,
      dropdown_mdsdocFromCti: 0,
      mdsdocFromSupplier: "",
      dropdown_hazmatPresent: 0,
      dropdown_tableAFilter: [],
      tableAItem: [],
      dropdown_tableBFilter: [],
      tableBItem: [],

      ttoolData: [],
      messageText: null,
      emailSentModal: false,

      frameworkComponents: {
        tagRenderer: StatusRenderer,
      },

      trackingToolDocumentsData: [],
      //colDefs: this.gridColumns,
      colClientDefs: this.gridClientColumns,

      showViewModal: false,
      selectdDocDeleteId: 0,
      selectdDocDeleteMdsdocId: 0,
      selectedFileToDownload: [],
      showUploadModal: false,

      isClient: false,
      isShipCrew: false,
      showDocumentUploadModal: false,
      supplierId: 0,
      trackingToolId: 0,
      showHistoryModal: false,
      documentColumns: this.gridDocumentColumns,
      filesBasedDocumentType: [],
      allTrackingToolFiles: [],
      allTrackingToolPODocuments: [],
      allTrackingToolMDSDOCDocuments: [],
      allTrackingToolDeclationDocuments: [],
      showConfirmationModal: false,
      selectedTTData: [],
      modalText: "",
      clientId: 0,
      shipId: 0,
      typeId: 0,
      userRolePermissionRead: false,
      accessList: [],
      disableFields: false
    };
    this.gridRef = React.createRef();
  }

  gridColumns = () => [
    {
      headerName: "",
      field: "",
      width: 80,
      cellRenderer: (params) => (
        <StatusRenderer
          path={
            params.data.status === undefined || params.data.status === null
              ? ""
              : params.data.status
          }
        />
      ),
      sortable: false,
      filter: false,
    },
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        return params.data.fileMediaID != null;
      },
      width: 50,
      unSortIcon: false,
      suppressRowClickSelection: true,
    },
    {
      headerName: "Document Name",
      field: "documentName",
      tooltipField: "documentName",
      width: 200,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Document Type",
      field: "documentTypeName",
      tooltipField: "documentTypeName",
      width: 200,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Resend for Correction",
      field: "resendCorrectionCount",
      // tooltipField: "resendCorrectionCount",
      width: 150,

      cellRenderer: (params) =>
        params.data.documentType !== "PO" ? (
          <a
            style={{ opacity: this.state.userRolePermissionRead ? "0.6" : "1" }}
            onClick={(e) =>
              this.state.userRolePermissionRead
                ? e.preventDefault()
                : this.reSendMDSDoCForCorrection(params.data.documentType)
            }
          >
            Resend
          </a>
        ) : null,
    },
    {
      headerName: "Revised Document",
      field: "revisedDocument",
      //tooltipField: "revisedDocument",
    },
    {
      headerName: "Status Text",
      field: "statusText",
      hide: true,
    },
    {
      headerName: "Status",
      field: "status",
      //tooltipField: "statusText",
      sort: 'custom',
      comparator: customSortDocumentStatusComparator,
      cellRenderer: (params) =>
        !this.state.userRolePermissionRead &&
          params.data.documentType !== "PO" ? (
          <MultiSelectDropdown
            name="documentStatus"
            selectedOptions={params.data.status}
            options={statusDocDataList}
            disabled={false}
            onChange={(e) => this.onStatusSelected(e, params.data)}
            isMulti={false}
          />
        ) : (
          <MultiSelectDropdown
            name="documentStatus"
            selectedOptions={2}
            options={statusDocDataList}
            disabled={true}
            isMulti={false}
          />
        ),
    },
    {
      headerName: "Remark",
      field: "remarks",
      tooltipField: "remarks",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          title="View"
          src={viewIcon}
          width="20px"
          data-bs-toggle="modal"
          data-bs-target="#view-history"
          onClick={() => this.onViewClicked(params.data.filePath, params.data)}
        />
      ),
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      cellRenderer: (params) =>
        params.data.fileMediaID == null ? (
          <></>
        ) : (
          <img
            className="cursor-class"
            title="Download"
            src={downloadIcon}
            style={{ cursor: "pointer" }}
            width="20px"
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() => this.onViewFileDownload(params.data.filePath)}
          />
        ),
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      cellRenderer: (params) =>
        params.data.fileMediaID == null ? (
          <></>
        ) : (
          <img
            title="History"
            className="cursor-class"
            src={historyIcon}
            width="20px"
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() =>
              this.viewHistory(
                params.data.fileMediaID,
                params.data.documentType,
                params.data.documentTypeName
              )
            }
          />
        ),
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      hide: this.state.userRolePermissionRead,
      cellRenderer: (params) =>
        // params.data.fileMediaID == null ? (
        //   <></>
        // ) : (
        <img
          className="cursor-class"
          src={deleteIcon}
          title="Delete"
          width="15px"
          data-bs-toggle="modal"
          data-bs-target="#view-history"
          onClick={() =>
            this.showDeleteDocModal(
              params.data.fileMediaID == null ? helperText.global.deleteFilledMDSDoC : null,
              params.data.fileMediaID,
              params.data.mdSDocId
            )
          }
        />
      //),
    },
  ];

  gridClientColumns = [
    {
      headerName: "",
      field: "",
      width: 80,
      cellRenderer: (params) => (
        <StatusRenderer
          path={
            params.data.status === undefined || params.data.status === null
              ? ""
              : params.data.status
          }
        />
      ),
      sortable: false,
      filter: false,
    },
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      unSortIcon: false,
    },
    {
      headerName: "Document Name",
      field: "documentName",
      tooltipField: "documentName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 200,
    },
    {
      headerName: "Resend Correction Count",
      field: "resendCorrectionCount",
      //tooltipField: "resendCorrectionCount",
    },
    {
      headerName: "Revised Document",
      field: "revisedDocument",
      //tooltipField: "revisedDocument",
    },
    {
      headerName: "Status",
      field: "status",
      //tooltipField: "status",
      cellRenderer: (params) => (
        <MultiSelectDropdown
          name="documentStatus"
          selectedOptions={params.data.status}
          options={statusDocDataList}
          disabled={true}
          onChange={(e) => this.onStatusSelected(e, params.data)}
          isMulti={false}
        />
      ),
    },
    {
      headerName: "Remark",
      field: "remarks",
      tooltipField: "remarks",
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={viewIcon}
          title="View"
          width="20px"
          data-bs-toggle="modal"
          data-bs-target="#view-history"
          onClick={() => this.onViewClicked(params.data.filePath, params.data)}
        />
      ),
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      cellRenderer: (params) =>
        params.data.fileMediaID == null ? (
          <></>
        ) : (
          <img
            className="cursor-class"
            src={downloadIcon}
            title="Download"
            style={{ cursor: "pointer" }}
            width="20px"
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() => this.onViewFileDownload(params.data.filePath)}
          />
        ),
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 75,
      cellRenderer: (params) =>
        params.data.fileMediaID == null ? (
          <></>
        ) : (
          <img
            className="cursor-class"
            src={historyIcon}
            title="History"
            width="20px"
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() =>
              this.viewHistory(
                params.data.fileMediaID,
                params.data.documentType,
                params.data.documentTypeName
              )
            }
          />
        ),
    },
  ];

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.TRACKINGTOOLDETAILS);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          typeId: userData.typeId,
          userId: userData.id,
          isClient: userData.typeId == UserRole.CLIENT,
          isShipCrew: userData.typeId == UserRole.SHIPCREW,
          trackingToolId: parseInt(
            GetLocalStorageDataWithoutParse("SelectedTrackingTool"),
            10
          ),
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          disableFields: userData.typeId == UserRole.CLIENT || userData.typeId == UserRole.SHIPCREW || userData.typeId == UserRole.CLIENTUSER
        },
        () => {
          if (
            (parseInt(GetLocalStorageDataWithoutParse("SelectedTrackingTool"), 10) > 0, 10)
          ) {
            this.getTrackingToolData(
              parseInt(GetLocalStorageDataWithoutParse("SelectedTrackingTool"), 10)
            );
          }
        }
      );
    }
  }

  getTrackingToolData = async (ttId) => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.trackingToolAccess == UserAction.READ,
      });
      const response = await TrackingToolService.GetTrackingToolData(ttId);
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          this.setState({ ttoolData: response.data });
          await this.bindtrackingToolDetails(response.data);
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          poNumber: "",
          referenceNumber: "",
          supplierName: "",
          supplierId: 0,
          supplierEmail: "",
          ttUnit: "",
          ttQty: "",
          partName: "",
          subPartName: "",
          equipmentName: "",
          supplierAddress: "",
          supplierCompany: "",
          supplierContact: "",
          supplierReminder: "",
          reminderCount: "",
          hazmatPart1: 0,
          labTesting: "",
          communicationStartDate: "",
          lastCommunicationDate: "",
          dropdown_remark: 0,
          dropdown_mdsdocFromCti: 0,
          dropdown_hazmatPresent: 0,
          mdsdocRequestedDate: "",
          mdsdocReceivedDate: "",
          lastReminderDate: "",
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  bindtrackingToolDetails = async (ttData) => {
    this.setState({
      poNumber: ttData.ponumber,
      poDate: ttData.podate,
      masterCategory: ttData.masterCategory == null ? "" : ttData.masterCategory,
      referenceNumber: ttData.referenceNumber == null ? "" : ttData.referenceNumber,
      lineItemNo: ttData.lineItemNumber == null ? "" : ttData.lineItemNumber,
      description: ttData.description == null ? "" : ttData.description,
      supplierName: ttData.supplierName == null ? "" : ttData.supplierName,
      supplierId: ttData.supplierId == null ? 0 : ttData.supplierId,
      supplierEmail: ttData.supplierEmail == null ? "" : ttData.supplierEmail,
      ttUnit: ttData.unit == null ? "" : ttData.unit,
      ttQty: ttData.quantity,
      partName: ttData.partName == null ? "" : ttData.partName,
      subPartName: ttData.subPartName == null ? "" : ttData.subPartName,
      equipmentName: ttData.equipmentUsed == null ? "" : ttData.equipmentUsed,
      supplierAddress:
        ttData.supplierAddress == null ? "" : ttData.supplierAddress,
      supplierCompany:
        ttData.supplierCompanyName == null ? "" : ttData.supplierCompanyName,
      supplierContact:
        ttData.supplierContact == null ? "" : ttData.supplierContact,
      supplierReminder: ttData.averageCommunicationReminders,
      reminderCount: ttData.reminderCount,
      hazmatPart1: ttData.hazmatUpdatedIhmpart1,
      labTesting: ttData.labTestSample == null ? "" : ttData.labTestSample,
      communicationStartDate: ttData.supplierCommencementDate,
      lastCommunicationDate: ttData.lastCommunicationDate,
      dropdown_remark: parseInt(ttData.polineItemStatus, 10),
      dropdown_mdsdocFromCti: ttData.mdsDoCFromCti
        ? YesNoOptions.YES
        : YesNoOptions.NO,
      mdsdocFromSupplier: ttData.mdsDoCReceived
        ? YesNoOptionsText.YES
        : YesNoOptionsText.NO,
      dropdown_hazmatPresent: ttData.hazmatPresent
        ? YesNoOptions.YES
        : YesNoOptions.NO,
      mdsdocRequestedDate: ttData.mdsDoCRequestedDate,
      mdsdocReceivedDate: ttData.mdsDoCReceivedDate,
      lastReminderDate: ttData.lastReminderDate,
      dropdown_tableAFilter: ttData.listTableAHazmat,
      dropdown_tableBFilter: ttData.listTableBHazmat,
      trackingToolDocumentsData: ttData.trackingToolDocuments,
      allTrackingToolFiles: ttData.trackingToolDocuments,
      allTrackingToolPODocuments: ttData.allTrackingToolPODocuments,
      allTrackingToolMDSDOCDocuments: ttData.allTrackingToolMDSDOCDocuments,
      allTrackingToolDeclationDocuments: ttData.allTrackingToolDeclationDocuments,
      clientId: ttData.clientId,
      shipId: ttData.shipId,
      tableAItem: ttData.tableAHazmatItems,
      tableBItem: ttData.tableBHazmatItems,
      isLoading: false,
    });
  };

  onSaveDetailsClick = async () => {
    try {
      const {
        poDate,
        masterCategory,
        referenceNumber,
        description,
        ttQty,
        ttUnit,
        partName,
        subPartName,
        equipmentName,
        supplierEmail,
        supplierAddress,
        hazmatPart1,
        labTesting,
        dropdown_remark,
        dropdown_mdsdocFromCti,
        dropdown_hazmatPresent,
        communicationStartDate,
        lastCommunicationDate,
        tableAItem,
        tableBItem,
        userId
      } = this.state;

      this.setState({
        isLoading: true,
      });
      let ttData = new FormData();
      ttData.append(
        "trackingToolId",
        GetLocalStorageDataWithoutParse("SelectedTrackingTool")
      );
      ttData.append(
        "poDate",
        poDate == "" || poDate == null ? null : convertDate(poDate)
      );
      ttData.append("masterCategory", masterCategory);
      ttData.append("description", description);
      ttData.append("referenceNumber", referenceNumber);
      ttData.append("qty", ttQty);
      ttData.append("unit", ttUnit);
      ttData.append("partName", partName);
      ttData.append("subPartName", subPartName);
      ttData.append("equipmentName", equipmentName);
      ttData.append("supplierEmail", supplierEmail);
      ttData.append("supplierAddress", supplierAddress);
      ttData.append("hazmatPart1", hazmatPart1);
      ttData.append("labTesting", labTesting);
      ttData.append("remarkStatus", dropdown_remark);
      ttData.append(
        "supplierCommencementDate",
        communicationStartDate == "" || communicationStartDate == null
          ? null
          : convertDate(communicationStartDate)
      );
      ttData.append(
        "lastCommunicationDate",
        lastCommunicationDate == "" || lastCommunicationDate == null
          ? null
          : convertDate(lastCommunicationDate)
      );
      ttData.append("mdsdocFromCti", dropdown_mdsdocFromCti);
      ttData.append("hazmatPresent", dropdown_hazmatPresent);
      ttData.append("tableA", tableAItem);
      ttData.append("tableB", tableBItem);
      ttData.append("LoggedinUserId", userId);

      var response = await TrackingToolService.EditTrackingToolData(ttData);
      if (response != null && response != undefined && response.status == 200) {
        await this.bindtrackingToolDetails(response.data);
        this.setState(
          {
            toastVisible: true,
            toastMessage: helperText.TrackingTool.copyPasteRowData,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
          isLoading: false,
        });
      }
      //}
      this.setState({
        isLoading: false,
      });
    } catch (error) {
    }
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });
  };

  onDateChanged = (id, e) => {
    this.setState({ [id]: e }, () => { });
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  goback = () => {
    this.props.navigate("/TrackingTool");
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => { }
    );
  };

  onTableADropdownChange = (option) => {
    var tableAItemSelected = [];
    let tableAIdCommaSeperated = "";
    var selectedTableA = tableAListPrePopulated;
    var filter_rowData = [];

    if (option.length > 0) {
      option
        .filter((t) => t.value != 0)
        .forEach((item) => {
          tableAItemSelected.push(item);
        });
      if (option.some((t) => t.value == 0)) {
        filter_rowData = selectedTableA;
      } else {
        option
          .filter((t) => t.value != 0)
          .forEach((item) => {
            var rowDataList = selectedTableA.filter(
              (t) => t.value == item.value
            );
            rowDataList.forEach((item) => {
              filter_rowData.push(item);
            });
          });
      }
    }
    if (filter_rowData.length > 0) {
      filter_rowData.forEach((element) => {
        tableAIdCommaSeperated = tableAIdCommaSeperated.concat(
          element.value + ","
        );
      });
    }
    this.setState({ tableAItem: tableAIdCommaSeperated }, () => { });
  };

  onTableBDropdownChange = (option) => {
    var tableBItemSelected = [];
    let tableBIdCommaSeperated = "";
    var selectedTableB = tableBListPrePopulated;
    var filter_rowData = [];

    if (option.length > 0) {
      option
        .filter((t) => t.value != 0)
        .forEach((item) => {
          tableBItemSelected.push(item);
        });
      if (option.some((t) => t.value == 0)) {
        filter_rowData = selectedTableB;
      } else {
        option
          .filter((t) => t.value != 0)
          .forEach((item) => {
            var rowDataList = selectedTableB.filter(
              (t) => t.value == item.value
            );
            rowDataList.forEach((item) => {
              filter_rowData.push(item);
            });
          });
      }
    }
    if (filter_rowData.length > 0) {
      filter_rowData.forEach((element) => {
        tableBIdCommaSeperated = tableBIdCommaSeperated.concat(
          element.value + ","
        );
      });
    }
    this.setState({ tableBItem: tableBIdCommaSeperated }, () => { });
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
      emailSentModal: false,
      showConfirmationModal: false,
      selectedTTData: [],
      modalText: "",
    });
  };

  onViewFileDownload = (filePath) => {
    if (filePath != "") {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("href", filePath);
      link.setAttribute("download", filePath);

      var ext = filePath.substring(filePath.lastIndexOf(".") + 1);
      if (ext == "pdf" || ext == "PDF") {
        link.setAttribute("target", "_blank");
      } else {
        link.setAttribute("target", "");
      }

      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
      });
    } else {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.nofileToDownload,
      });
    }
  };

  onViewClicked = (filePath, documentData) => {
    if (filePath != null && filePath != "")
      this.setState({ showViewModal: true, filePathToView: filePath });
    else if (documentData?.mdSDocId != 0) {
      AddLocalStorageData("mdsdocId", documentData.mdSDocId);
      AddLocalStorageData("disableMdsdocFormFields", true);
      AddLocalStorageData("location", "AddEditTrackingTool");
      this.props.navigate(`/MDSDoC`);
    }
  };

  showDeleteDocModal = (txt, id, mdsdocId) => {
    this.setState({
      modalText: txt,
      showConfirmationPopup: true,
      selectdDocDeleteId: id == null ? 0 : id,
      selectdDocDeleteMdsdocId: mdsdocId == null ? 0 : mdsdocId,
    });
  };

  deleteShipDocData = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let selectedTTID = parseInt(GetLocalStorageDataWithoutParse("SelectedTrackingTool"), 10);
      var response = await TrackingToolService.DeleteTrackingToolDocData(
        this.state.selectdDocDeleteId,
        selectedTTID,
        this.state.selectdDocDeleteMdsdocId
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      } else if (response != null) {
        this.setState(
          {
            isLoading: false,
            toastVisible: true,
            toastMessage: helperText.global.deletedDocument,
            toastType: helperText.global.toastSuccess,
            showConfirmationPopup: false,
          },
          async () => {
            await this.bindtrackingToolDetails(response.data);
          }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };

  onDownloadAllClick = () => {
    const { selectedFileToDownload } = this.state;

    if (selectedFileToDownload.length > 0) {
      selectedFileToDownload.forEach((path) => {
        this.onViewFileDownload(path);
      });
    } else {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.noDocumentsSelected,
        isLoading: false,
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = [];
      selectedRows.forEach((element) => {
        if (element.filePath != null && element.filePath != "")
          listSelectedTTId.push(element.filePath);
      });

      this.setState({
        selectedFileToDownload: listSelectedTTId,
      });
    } else {
      this.setState({
        selectedFileToDownload: [],
      });
    }
  };

  onStatusSelected = async (e, ttDocData) => {
    ttDocData.status = e.value;

    const { ttoolData } = this.state;
    ttDocData.trackingToolId = ttoolData.trackingToolId;
    this.setState({
      isLoading: true,
    });
    var response = await TrackingToolService.SaveDocumentStatus(ttDocData);
    if (response === undefined || response === null) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
    await this.getTrackingToolData(ttoolData.trackingToolId);
    this.setState({
      isLoading: false,
    });
  };

  /* <summary>
  date: 21/03/2023
  Name: HV
  description: showing confirmation modal 
  <summary> */

  showHideConfirmationModal = (showModal, ttData, modalText) => {
    this.setState({
      showConfirmationModal: showModal,
      selectedTTData: ttData,
      modalText: modalText,
    });
  };

  sendMDSDoCRequest = async () => {
    try {
      const { userId, typeId } = this.state;
      this.setState({
        isLoading: true,
        showConfirmationModal: false,
      });
      let ttDataList = [];
      let ttSelectedList = [];

      let ttData = this.state.selectedTTData;

      if (ttData != null) {
        ttDataList.push(ttData.trackingToolId);
        ttSelectedList.push(ttData);
      } else ttDataList = this.state.selectedTrackingToolIdList;

      let trackingToolData = {
        TrackingToolIdList: ttDataList,
        userId: userId,
        userTypeId: typeId
      }

      const response = await TrackingToolService.SendMDSDoCRequest(trackingToolData);
      if (response.status == 200) {
        var frameAlertModalMessage =
          helperText.TrackingTool.multipleReminderEmail.replace(
            "[supplierNames]",
            ttData.supplierName
          );
        frameAlertModalMessage = frameAlertModalMessage.replace(
          "[poNumbers]",
          ttData.ponumber
        );

        this.setState({
          isLoading: true,
          emailSentModal: true,
          messageText: frameAlertModalMessage,
        });
      } else {
        this.setState({
          isLoading: true,
          emailSentModal: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
        modalText: "",
        selectedTTData: [],
      });
    } catch (error) { }
  };

  reSendMDSDoCForCorrection = async (documentType) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { userId, ttoolData } = this.state;
      const response = await TrackingToolService.ReSendMDSDoCForCorrection(
        ttoolData.trackingToolId,
        userId,
        documentType
      );
      if (response.status == 200) {
        let ttData =
          response.data != null ? response.data : this.state.selectedTTData;
        let multipleReminderEmailMeesage =
          helperText.TrackingTool.multipleReminderEmail;
        multipleReminderEmailMeesage = multipleReminderEmailMeesage.replace(
          "[supplierNames]",
          ttData?.supplierName ?? ""
        );
        multipleReminderEmailMeesage = multipleReminderEmailMeesage.replace(
          "[poNumbers]",
          ttData?.ponumber ?? ""
        );

        this.setState({
          isLoading: false,
          emailSentModal: true,
          messageText: multipleReminderEmailMeesage,
        });
      } else {
        this.setState({
          isLoading: false,
          emailSentModal: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
    }
  };

  showDocumentUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  onDocumentModalCloseClick = () => {
    this.setState({ showDocumentUploadModal: false });
  };

  viewHistory = (id, documentType, documentTypeName) => {
    const {
      filesBasedDocumentType,
      allTrackingToolFiles,
      allTrackingToolPODocuments,
      allTrackingToolMDSDOCDocuments,
      allTrackingToolDeclationDocuments
    } = this.state;
    if (documentType == "PO") {
      var listDocument = allTrackingToolPODocuments.filter(
        (x) => x.documentType == documentType && x.fileMediaID != id
      );

      this.setState({ filesBasedDocumentType: listDocument }, () => {
        this.setState({ showHistoryModal: true });
      });
    } else if (documentType == "MD/SDoC") {
      var listDocument = allTrackingToolMDSDOCDocuments.filter(
        (x) => x.documentTypeName == documentTypeName && x.fileMediaID != id
      );

      this.setState({ filesBasedDocumentType: listDocument }, () => {
        this.setState({ showHistoryModal: true });
      });
    }
    else if (documentType == "Declaration") {
      var listDocument = allTrackingToolDeclationDocuments.filter(
        (x) => x.documentTypeName == documentTypeName && x.fileMediaID != id
      );

      this.setState({ filesBasedDocumentType: listDocument }, () => {
        this.setState({ showHistoryModal: true });
      });
    }
    else {
      var list = allTrackingToolFiles.filter((x) => x.fileMediaID == id);
      this.setState({ filesBasedDocumentType: list }, () => {
        this.setState({ showHistoryModal: true });
      });
    }
  };

  onSaveMDSDOCDeclarationData = async (mdsdocData) => {
    this.setState({ showDocumentUploadModal: false, isLoading: true });

    const { clientId, shipId, userId } = this.state;
    mdsdocData.append("ClientId", clientId);
    mdsdocData.append("ShipId", shipId);
      mdsdocData.append("loggedInUserID", userId);
      mdsdocData.append("fromPage", "AddEditTrackingTool");

    var responseSaved = await SupplierService.SaveUploadMDSDocData(mdsdocData);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          isLoading: false,
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.TrackingTool.documnetUploadedSuccess,
        },
        async () => {
          this.getTrackingToolData(
            parseInt(GetLocalStorageDataWithoutParse("SelectedTrackingTool"), 10)
          );
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onViewHistoryModalClose = () => {
    this.setState({ showHistoryModal: false });
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
  date: 20/3/2023
  Name: DP
  description: history grid
  <summary> */
  gridDocumentColumns = [
    {
      headerName: "Serial Number",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Document Name",
      field: "documentName",
      tooltipField: "documentName",
      width: 290,
      wrapText: true,
    },
    {
      headerName: "Date of Creation",
      field: "createdOn",
      tooltipField: "createdOn",
      cellRenderer: (params) => convertDate(params.data.createdOn),
      width: 200,
      wrapText: true,
    },
    {
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={eyeIcon}
          onClick={() => this.onViewClicked(params.data.filePath, params.data)}
          width="20px"
        />
      ),
    },
    {
      headerName: "",
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) =>
        params.data.fileMediaID == null ? (
          <></>
        ) : (
          <img
            className="download-cursor-class"
            src={downloadIcon}
            data-bs-toggle="modal"
            data-bs-target="#download-icon"
            style={{ cursor: "pointer", width: "28px" }}
            onClick={() =>
              this.onViewFileDownload(
                params.data.filePath
                //params.data.clientId
              )
            }
          />
        ),
    },
  ];

  onViewEmailClick = () => {
    AddLocalStorageData("location", "/AddEditTrackingTool");
    this.props.navigate("/CommunicationHistory");
  };

  onUploadOrRequestMdSDocClicked = (type) => {
    const { supplierId, ttoolData, supplierName } = this.state;

    if (supplierId == 0 || supplierId == null) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.supplierDetailsMissing,
        toastType: helperText.global.toastError,
      });
    } else {
      if (type == "Upload") this.setState({ showDocumentUploadModal: true });
      else {
        this.showHideConfirmationModal(
          true,
          ttoolData,
          "request the MD/SDoc to the supplier " + supplierName + "?"
        );
      }
    }
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      showErrorMessage,

      errorMessages,
      poNumber,
      masterCategory,
      poDate,
      referenceNumber,
      lineItemNo,
      description,
      ttUnit,
      ttQty,
      partName,
      subPartName,

      supplierEmail,
      supplierAddress,
      supplierCompany,
      supplierContact,
      communicationStartDate,
      supplierReminder,
      mdsdocRequestedDate,
      mdsdocReceivedDate,
      lastReminderDate,
      reminderCount,
      hazmatPart1,
      labTesting,
      equipmentName,
      lastCommunicationDate,

      trackingToolDocumentsData,
      colDefs,
      colClientDefs,
      frameworkComponents,

      dropdown_remark,
      dropdown_mdsdocFromCti,
      mdsdocFromSupplier,
      dropdown_hazmatPresent,
      dropdown_tableAFilter,
      dropdown_tableBFilter,

      showViewModal,
      filePathToView,
      showConfirmationPopup,
      showUploadModal,

      ttoolData,
      emailSentModal,
      messageText,
      disableFields,
      showDocumentUploadModal,
      supplierName,
      supplierId,
      trackingToolId,
      showHistoryModal,
      filesBasedDocumentType,
      documentColumns,
      showConfirmationModal,
      modalText,
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {emailSentModal && (
          <AlertModal
            emailSentModal={emailSentModal}
            onOkClick={this.onModalCloseClick}
            msgToDisplay={messageText}
            viewLink={this.onViewEmailClick}
          />
        )}
        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Document"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          ></ViewModal>
        )}

        {showConfirmationPopup && (
          <ConfirmationModal
            modalText = {modalText}
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.deleteShipDocData}
          />
        )}

        {showConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.sendMDSDoCRequest}
            modalText={modalText}
          />
        )}

        <main className="page-height">
          <section className="EditTrackingTool">
            <div className="container ">
              <div className="block1">
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-8 ">
                    <div className="admin-profile-detail-back">
                      <img
                        src="images/back.png"
                        alt="Back"
                        style={{ pointerEvents: "all", cursor: "pointer" }}
                        onClick={this.goback}
                      />
                      <span className="title-font">Edit Tracking Tool</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="block2">
                Year 1 / IHMM Maintenance Period 02-Aug-2021 - 02-Aug-2022
              </div> */}
              <div className="box">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#shipdetails"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        PO Line Item Details
                      </button>
                    </h2>
                    <div
                      id="shipdetails"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Purchase Order Number:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      placeholder="Purchase Order Number"
                                      type="text"
                                      className="form-control grey"
                                      id="purchase-order-num"
                                      name="poNumber"
                                      value={poNumber}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Purchase Order Date:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="supplier-name"
                                      name="supplierName"
                                      value={poDate}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Reference Number:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Reference-num"
                                      autoComplete="new-password"
                                      name="referenceNumber"
                                      value={referenceNumber}
                                      onChange={this.onTextChange}
                                      maxLength={30}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Line Item Number:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      placeholder="N/A"
                                      type="text"
                                      min="0"
                                      className="form-control grey"
                                      id="line-item-num"
                                      autoComplete="new-password"
                                      name="lineItemNo"
                                      value={lineItemNo}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Description:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <textarea
                                      type="text"
                                      min="0"
                                      className="form-control "
                                      id="description"
                                      name="description"
                                      rows="2"
                                      autoComplete="new-password"
                                      value={description}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                      onChange={this.onTextChange}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Unit:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      min="0"
                                      className="form-control"
                                      id="unit"
                                      name="ttUnit"
                                      autoComplete="new-password"
                                      value={ttUnit}
                                      onChange={this.onTextChange}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Qty:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      placeholder="100"
                                      type="number"
                                      min="0"
                                      className="form-control"
                                      id="Qty"
                                      name="ttQty"
                                      autoComplete="new-password"
                                      value={ttQty}
                                      onChange={this.onTextChange}
                                      disabled={disableFields ||
                                        this.state.userRolePermissionRead
                                        ? true
                                        : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Part Name:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      placeholder="-"
                                      type="text"
                                      min="0"
                                      className="form-control "
                                      id="part-name"
                                      name="partName"
                                      autoComplete="new-password"
                                      value={partName}
                                      onChange={this.onTextChange}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Sub Part Name:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="sub-part-name"
                                      name="subPartName"
                                      autoComplete="new-password"
                                      value={subPartName}
                                      onChange={this.onTextChange}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Equipment Used:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="equipment-used"
                                      name="equipmentName"
                                      autoComplete="new-password"
                                      value={equipmentName}
                                      onChange={this.onTextChange}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Master Category:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="equipment-used"
                                      name="masterCategory"
                                      autoComplete="new-password"
                                      value={masterCategory}
                                      onChange={this.onTextChange}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#clientdetails"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Supplier Details
                      </button>
                    </h2>
                    <div
                      id="clientdetails"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Supplier Name:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="supplier-name"
                                      name="supplierName"
                                      value={supplierName}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Supplier Email Address:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="email"
                                      min="0"
                                      className="form-control grey"
                                      id="supplier-email"
                                      name="supplierEmail"
                                      value={supplierEmail}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Supplier Address:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <textarea
                                      type="text"
                                      min="0"
                                      className="form-control grey"
                                      id="supplier-address"
                                      name="supplierAddress"
                                      value={supplierAddress}
                                      rows="3"
                                      disabled={true}
                                      readOnly
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Supplier Company Name:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      min="0"
                                      className="form-control grey"
                                      id="supplier-company-name"
                                      name="supplierCompany"
                                      value={supplierCompany}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Supplier Contact Number:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="Supplier-Contact-No"
                                      name="supplierContact"
                                      value={supplierContact}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      Supplier Communication Commencement Date:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <DatePicker
                                      min="0"
                                      selectedDate={
                                        communicationStartDate
                                          ? new Date(communicationStartDate)
                                          : null
                                      }
                                      disabled={true}
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      Supplier Average Communication Reminders:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="number"
                                      min="0"
                                      className="form-control grey"
                                      id="communication-reminders"
                                      name="supplierReminder"
                                      value={supplierReminder}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ihmparticulars"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        MD/SDoC Details
                      </button>
                    </h2>
                    <div
                      id="ihmparticulars"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      MD/SDoC taken from CTI Database:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <YesNoDropdown
                                      dropdownLabel=""
                                      name="mdsdocFromCti"
                                      selected={dropdown_mdsdocFromCti}
                                      disabled={true}
                                      readOnly
                                    ></YesNoDropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      MD/SDoC Received from supplier:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="communication-reminders"
                                      name="mdsdocFromSupplier"
                                      value={mdsdocFromSupplier}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      Date of MD/SDoC requested from Suppliers:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="mdsdocRequestedDate"
                                      name="mdsdocRequestedDate"
                                      value={mdsdocRequestedDate}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      Date the MD/SDoC was Received:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="mdsdocReceivedDate"
                                      name="mdsdocReceivedDate"
                                      value={mdsdocReceivedDate}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Number of Reminders:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="number"
                                      className="form-control grey"
                                      id="No-of-Reminders"
                                      name="reminderCount"
                                      value={reminderCount}
                                      disabled={true}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Date of Last Reminder:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control grey"
                                      id="lastReminderDate"
                                      name="lastReminderDate"
                                      value={lastReminderDate}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#maritec-data"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Hazmat Details
                      </button>
                    </h2>
                    <div
                      id="maritec-data"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>
                                      Hazmat Items Updated in IHM Part1:
                                    </label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">

                                    <YesNoDropdown
                                      dropdownLabel=""
                                      name="hazmatPart1"
                                      onChange={this.onFilterSelected}
                                      selected={hazmatPart1}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    ></YesNoDropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Lab Testing of Samples:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Lab-Testing-Samples:"
                                      name="labTesting"
                                      value={labTesting}
                                      onChange={this.onTextChange}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Table A (Prohibited):</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <MultiSelectDropdown
                                      name="tableAGridFilter"
                                      selectedOptions={
                                        dropdown_tableAFilter == []
                                          ? []
                                          : dropdown_tableAFilter
                                      }
                                      options={tableAListPrePopulated}
                                      onChange={(e) =>
                                        this.onTableADropdownChange(e)
                                      }
                                      isMulti={true}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Table B Hazmat (Restricted):</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <MultiSelectDropdown
                                      name="tableBGridFilter"
                                      selectedOptions={
                                        dropdown_tableBFilter == []
                                          ? []
                                          : dropdown_tableBFilter
                                      }
                                      options={tableBListPrePopulated}
                                      onChange={(e) =>
                                        this.onTableBDropdownChange(e)
                                      }
                                      isMulti={true}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Hazmat Present:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <YesNoDropdown
                                      dropdownLabel=""
                                      name="hazmatPresent"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_hazmatPresent}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    ></YesNoDropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#status-data"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Status
                      </button>
                    </h2>
                    <div
                      id="status-data"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Last Communication Date:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <DatePicker
                                      min="0"
                                      selectedDate={
                                        lastCommunicationDate
                                          ? new Date(lastCommunicationDate)
                                          : null
                                      }
                                      onDateChange={(e) =>
                                        this.onDateChanged(
                                          "lastCommunicationDate",
                                          e
                                        )
                                      }
                                      disabled={true}
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <label>Remark/Status:</label>
                                  </div>
                                  <div className="col-xl-6 col-lg-8" id={"remark"}>
                                    <Dropdown
                                      dropdownLabel=""
                                      name="remark"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_remark}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                      disabled={
                                        disableFields ||
                                          this.state.userRolePermissionRead
                                          ? true
                                          : false
                                      }
                                    >
                                      {statusDataList == null
                                        ? null
                                        : statusDataList.map((item) => (
                                          <option
                                            wrap="true"
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_remark != "" ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"remark"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="right"
                                        style={{ width: "350px" }}
                                      >
                                        {statusDataList != null && statusDataList.filter(x => x.value == dropdown_remark)[0]?.label}
                                      </UncontrolledTooltip>) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block8">
                  <div className="row block-8">
                    <div className="col-lg-1 col-md-2 col-sm-12 doc">
                      Documents:
                    </div>
                    <div className=" col-12 col-lg-11 col-md-10 ">
                      <div className=" float-btn edit-tracking-tool-button">
                        {!disableFields ? (
                          <button
                            type="button"
                            className="btn btn-background-color btn-sm request-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#EmailRequestMdSdoc-modal"
                            disabled={this.state.userRolePermissionRead}
                            onClick={() =>
                              this.onUploadOrRequestMdSDocClicked("Request")
                            }
                          >
                            Request for MD/SDoC
                          </button>
                        ) : null}
                        {!disableFields ? (
                          <button
                            type="button"
                            className="btn btn-background-color btn-sm request-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#uploadtrackingtool-modal"
                            disabled={this.state.userRolePermissionRead}
                            onClick={() =>
                              this.onUploadOrRequestMdSDocClicked("Upload")
                            }
                          >
                            Upload Documents
                          </button>
                        ) : null}
                        {trackingToolDocumentsData != null && trackingToolDocumentsData.length > 0 ? (
                          <span>
                            <img
                              title="Multi Download"
                              src="images\admin-tracking-download.png"
                              alt="admin download icon"
                              className="admin-download green cursor-class"
                              onClick={this.onDownloadAllClick}
                            />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block9">
                  <div className="table-responsive">
                    <AGGrid
                      rowData={trackingToolDocumentsData}
                      colDefs={
                        !disableFields
                          ? this.gridColumns()
                          : colClientDefs
                      }
                      rowSelection="multiple"
                      pagination="10"
                      frameworkComponents={frameworkComponents}
                      onGridReady={this.onGridReady}
                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                      onSortChanged={(e) => this.onSortChanged(e)}
                      isRowSelectable={(params) => {
                        return params.data.fileMediaID != null;
                      }}
                    />
                  </div>
                </div>

                {!disableFields ? (
                  <div className="admin-ship-settings-save-btn text-center">
                    {showErrorMessage && (
                      <div className="invalid-message-icon-box">
                        <img
                          src="images\icon material-error.png"
                          alt="invalid"
                        ></img>
                        <p>Please fill in all the mandatory fields.</p>
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-background-color save-btn"
                      onClick={this.onSaveDetailsClick}
                    >
                      Save
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </main>

        {showDocumentUploadModal && (
          <SupplierDocumentModal
            onDocumentModalCloseClick={this.onDocumentModalCloseClick}
            supplierName={supplierName}
            supplierId={supplierId}
            TrackingToolId={trackingToolId}
            onSaveMDSDOCDeclarationData={this.onSaveMDSDOCDeclarationData}
          />
        )}

        {showHistoryModal && (
          <ViewDocumentHistoryModal
            onModalCloseClick={this.onViewHistoryModalClose}
            filesBasedDocumentType={filesBasedDocumentType}
            colDefs={documentColumns}
          />
        )}

        <Footer />
      </>
    );
  }
}

export default withRouter(AddEditTrackingTool);
