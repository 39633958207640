import React from "react";
import { isStringEmpty } from "../../helpers/string-helper";

const OtherStatusTrackingTool = ({
	showOtherValue,
	otherValueValidation,
	comments,
	onCommentsChange,
	onSaveClick,
	onCloseClick
}) => {
	return (

		<div className="supplier_on_hold">
			<div className="modal-backdrop fade show" style={{ display: showOtherValue ? 'block' : 'none' }}></div>
			<div className="modal" id="other-status-value-Modal" style={{ display: showOtherValue ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-box">
					<div className="modal-content ">

						<div className="modal-header">
							<h4 className="modal-title">Remark / Status</h4>
							<button type="button" data-bs-dismiss="modal">
								<img src="images/modal-close.png" alt="modal-close" onClick={onCloseClick}/>
							</button>
						</div>
						<div className="row content">
							<div className=" col-lg-12 col-md-12 col-sm-12">
								<label htmlFor="todate">Other Value:</label>
								<br />
								<div
									className={
										!isStringEmpty(otherValueValidation)
											? "invalid-position"
											: ""
									}
								>
									<input type="name" className="form-control" id="comments" name="comments" value={comments} onChange={onCommentsChange} />

									{!isStringEmpty(otherValueValidation) && (
										<div className="invalid-message-icon">
											<img
												src="images\icon material-error.png"
												alt="invalid"
											></img>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="admin-ship-settings-save-btn text-center">
							{!isStringEmpty(otherValueValidation) && (
								<div className="invalid-message-icon-box">
									<img
										src="images\icon material-error.png"
										alt="invalid"
									></img>
									<p>Please fill in all the mandatory fields.</p>
								</div>
							)}
							<button type="button" className="btn btn-background-color save-btn" onClick={onSaveClick}>Save</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	);
};

export default OtherStatusTrackingTool;