import React, { Component } from 'react';
import AGGrid from "../../components/AGGrid";
import admindownload from "../../assets/images/admin-download.png";
import admineye from "../../assets/images/admin-eye.png";

export default class ViewHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedHistoryDoc: [],
      colDefs: this.gridColumns,
    };
  }

  componentDidMount() {

  }
  onSortChanged(e) {
    e.api.refreshCells();
  }
  gridColumns = [
    {
      headerName: 'Serial Number',
      // field: "serialNumber",	
      valueGetter: "node.rowIndex + 1",
      tooltipField: "serialNumber",
      unSortIcon: false,
      sortable: false,
      filter: false,
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
    },
    {
      headerName: 'Document Name',
      field: "documentName",
      tooltipField: "documentName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: 'Version',
      field: "versionNumber",
      tooltipField: "versionNumber",
    },
    {
      headerName: 'Date of Creation',
      field: "createdOn",
      tooltipField: "createdOn",
    },
    {

      headerName: '',
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admineye}
          onClick={() => this.props.viewShipDocData(params.data.fileMediaId)}
          width="20px"
        />
      ),
      cellStyle: {textAlign:"center" , paddingInline: "0"}
    },
    {
      headerName: '',
      filter: false,
      width: 40,
      sortable: false,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admindownload}
          onClick={() => this.props.downloadShipDocData(params.data.fileMediaId, params.data.shipId)}
          width="15px"
        />
      ),
      cellStyle: {textAlign:"center" , paddingInline: "0"}
    },
  ]
  render() {

    const { mappedHistoryDoc, colDefs } = this.state;
    const { onModalCloseClick, filesBasedDocumentType, viewShipDocData,
      downloadShipDocData } = this.props;

    return (
      <div className="shipdetailupload" style={{ display: "block" }}>
        <div className="modal-backdrop fade show" style={{ display: "block" }}></div>
        <div className="modal Admin-Ship-ViewHistory" id="ViewHistory" style={{ display: "block" }}>

          <div className="modal-dialog modal-md modal-dialog-centered" style={{ maxWidth: "750px" }} >
            <div className="modal-content">
              {/* <!-- Modal Header --> */}
              <div className="modal-header">
                <h4 className="modal-title">View History</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img src="images/modal-close.png" alt="modal-close" onClick={() => { onModalCloseClick() }} />
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <AGGrid
                      rowData={filesBasedDocumentType}
                      colDefs={colDefs}
                      rowSelection="single"
                      pagination="10"
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}