import React, { Component } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { ClientService } from "../../services/client.service";
import Dropdown from "../../components/Dropdown";
import AGGrid from "../../components/AGGrid";
import viewIcon from "../../assets/images/admin-eye.png";
import editIcon from "../../assets/images/Icon feather-edit.png";
import historyIcon from "../../assets/images/admin-history.png";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Switch from "react-switch";
import Logout from "../../helpers/Logout";
import { CLIENT_STATUS_ID } from "../../helpers/enums";
import { convertDate } from "../../helpers/string-helper";
import ViewHistoryModal from "../../components/ViewHistoryModal";
import Toast from "../../components/Toast";
import { UserAction } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
let switchChecked = true;

class ManageClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedClient: [],
      serviceCategoryList: [],
      statusList: [],
      defaultClient: [],
      colDefs: this.gridColumns,
      showViewHistoryModal: false,
      historyColumns: this.gridHistoryColumns,
      listHistoryData: [],
      clientPendingChangeRequestCount: 0,
      accessList: [],
      gridApi: null,
      userRolePermissionRead: false,
      searchedText: "",
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  /* <summary>
    date: 23/12/2022
    Name: AN
    description: Get data to bind on the manage client screen
    <summary> */
  componentDidMount() {
    //DP - 23/2/2023 - passed userid
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          userId: userData.id,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        () => {
          this.getManageClientData(this.state.userId);
        }
      );
    }
  }

  componentDidUpdate() {
    // if (this.state.gridApi) {
    //   this.state.gridApi.paginationGoToPage(0);
    // }
  }

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
  };

  /* <summary>
  date: 11/01/2023
  Name: NGG
  description: Navigates to manage ships page nad passes the client object
  <summary> */
  manageShipClicked = (clientId) => {
    AddLocalStorageData("navigatedFromManageClient", true);
    AddLocalStorageData("selectedClient", JSON.stringify(clientId));
    //SYJ - Added Localstorage to set Clientcode
    AddLocalStorageData("selectedClientCode", clientId.clientCode);

    this.props.navigate("/ManageShips");
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
    date: 23/12/2022
    Name: AN
    description: Bind AG grid columns
    <summary> */
  gridColumns = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      unSortIcon: false,
      sortable: false,
      filter: false,
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
    },
    {
      headerName: "Client Name",
      field: "clientName",
      tooltipField: "clientName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client Code",
      field: "clientCode",
      tooltipField: "clientCode",
      wrapText: true,
    },
    {
      headerName: "Phone Number",
      field: "contactNumber",
      tooltipField: "contactNumber",
      wrapText: true,
    },
    {
      headerName: "Service Category",
      field: "serviceCategoryName",
      tooltipField: "serviceCategoryName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client Role",
      field: "clientRoleName",
      tooltipField: "clientRoleName",
      wrapText: true,
    },
    {
      headerName: "Primary Email Address",
      width: 250,
      field: "primaryEmailAddress",
      tooltipField: "primaryEmailAddress",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Ship",
      field: "shipNames",
      tooltipField: "shipNames",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Address",
      field: "address",
      tooltipField: "address",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Contractual Brief Terms",
      width: 250,
      field: "briefTerms",
      tooltipField: "briefTerms",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Maritec BDM",
      field: "maritecBDM",
      tooltipField: "maritecBDM",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "CTI Region",
      field: "regionName",
      tooltipField: "regionName",
      cellRenderer: (params) => <span>Region {params.data.regionId}</span>,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client PIC",
      field: "clientPicName",
      tooltipField: "clientPicName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Date Of Contract",
      field: "contractDateValue",
      tooltipField: "contractDateValue",
      cellRenderer: (params) => params.data.contractDateValue,
    },
    {
      headerName: "Date of Contract Expiry",
      width: 250,
      field: "contractExpiryDateValue",
      tooltipField: "contractExpiryDateValue",
      cellRenderer: (params) => params.data.contractExpiryDateValue,
    },
    {
      headerName: "Date Of Creation",
      field: "createdOnValue",
      tooltipField: "createdOnValue",
      cellRenderer: (params) => params.data.createdOnValue,
    },
    {
      headerName: "IHMM In Charge",
      field: "ihmminchargeName",
      tooltipField: "ihmminchargeName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "IHMM PIC Email Address",
      width: 300,
      field: "ihmminchargeEmail",
      tooltipField: "ihmminchargeEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Alternate IHMM In Charge",
      width: 280,
      field: "alternateIhmminchargeName",
      tooltipField: "alternateIhmminchargeName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: " Alternate IHMM PIC Email Address",
      width: 300,
      field: "alternateihmminchargeEmail",
      tooltipField: "alternateihmminchargeEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Manage Client Users",
      width: 163,
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      cellRenderer: (params) => (
        <a onClick={() => this.manageClientUserClicked(params.data)}>
          Manage Client Users
        </a>
      ),
    },
    {
      headerName: "Manage Ships",
      width: 125,
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      cellRenderer: (params) => (
        <a onClick={() => this.manageShipClicked(params.data)}>Manage Ships</a>
      ),
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={
            !params.data.isActive || this.state.userRolePermissionRead
              ? viewIcon
              : editIcon
          }
          style={{ width: "20px" }}
          title={
            !params.data.isActive || this.state.userRolePermissionRead
              ? "View"
              : "Edit"
          }
          data-bs-toggle="modal"
          data-bs-target="#edit-icon"
          onClick={(e) => {
            this.onAddEditClientClicked(params.data.clientId);
          }}
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 50,
      cellRenderer: (params) => (
        <div title={params.data.isActive ? "Active" : "Inactive"}>
          <Switch
            className="switch"
            height={20}
            width={45}
            disabled={
              this.state.accessList != null &&
                this.state.accessList.clientAndShipAccess == UserAction.READ
                ? true
                : false
            }
            id={params.data.clientId.toString()}
            onChange={(checked, e, id) =>
              this.onSwitchChange(checked, e, params.data.clientId)
            }
            checked={params.data.isActive ? true : false}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={"#C90000"}
          />
        </div>
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="history-cursor-class cursor-class"
          src={historyIcon}
          style={{ width: "20px" }}
          title="History"
          data-bs-toggle="modal"
          data-bs-target="#history-icon"
          onClick={() => this.onViewHistoryClick(params.data)}
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
  ];

  gridHistoryColumns = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Client Name",
      field: "clientName",
      tooltipField: "clientName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client Code",
      field: "clientCode",
      tooltipField: "clientCode",
      wrapText: true,
    },
    {
      headerName: "Phone Number",
      field: "contactNumber",
      tooltipField: "contactNumber",
      wrapText: true,
    },
    {
      headerName: "Service Category",
      field: "serviceCategoryName",
      tooltipField: "serviceCategoryName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client Role",
      field: "clientRoleName",
      tooltipField: "clientRoleName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Primary Email Address",
      field: "primaryEmailAddress",
      tooltipField: "primaryEmailAddress",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Ship",
      field: "shipNames",
      tooltipField: "shipNames",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Address",
      field: "address",
      tooltipField: "address",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Contractual Brief Terms",
      field: "briefTerms",
      tooltipField: "briefTerms",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Maritec BDM",
      field: "maritecBDM",
      tooltipField: "maritecBDM",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "CTI Region",
      field: "regionName",
      tooltipField: "regionName",
      cellRenderer: (params) => <span>Region {params.data.regionId}</span>,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Date Of Contract",
      field: "contractDate",
      tooltipField: "contractDate",
      cellRenderer: (params) => convertDate(params.data.contractDate),
    },
    {
      headerName: "Date of Contract Expiry",
      width: 250,
      field: "contractExpiryDate",
      tooltipField: "contractExpiryDate",
      cellRenderer: (params) => convertDate(params.data.contractExpiryDate),
    },
    {
      headerName: "Date Of Creation",
      field: "createdOn",
      tooltipField: "createdOn",
      cellRenderer: (params) => convertDate(params.data.createdOn),
    },
    {
      headerName: "IHMM In Charge",
      field: "ihmminchargeName",
      tooltipField: "ihmminchargeName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "IHMM PIC Email Address",
      field: "ihmminchargeEmail",
      tooltipField: "ihmminchargeEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Alternate IHMM In Charge",
      field: "alternateIhmminchargeName",
      tooltipField: "alternateIhmminchargeName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: " Alternate IHMM PIC Email Address",
      field: "alternateihmminchargeEmail",
      tooltipField: "alternateihmminchargeEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Status",
      field: "status",
      tooltipField: "status",
    },
    {
      headerName: "Action",
      field: "action",
      tooltipField: "action",
    },
    {
      headerName: "Edited By",
      field: "editedByName",
      tooltipField: "editedByName",
    },
    {
      pinned: "right",
      headerName: "Edited Date",
      field: "editedOn",
      tooltipField: "editedOn",
      cellRenderer: (params) => convertDate(params.data.editedOn),
    },
  ];

  /* <summary>
     date: 22/11/2022
     Name: AS
     description: Gets data for dropdowns and grid control
     <summary> */
  getManageClientData = async (userId) => {
    try {
      const { dropdown_status } = this.state;
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.clientAndShipAccess == UserAction.READ,
      });
      const response = await ClientService.GetManageClientData(userId);
      //SYJ - filter Client Grid List depending on the status Selected from DropDown
      var filterList = [];
      if (
        response.data.clients.length > 0 &&
        parseInt(dropdown_status, 10) > 0
      ) {
        var flag = null;
        if (parseInt(dropdown_status, 10) == CLIENT_STATUS_ID.ACTIVE) {
          flag = true;
        } else if (parseInt(dropdown_status, 10) == CLIENT_STATUS_ID.INACTIVE) {
          flag = false;
        }
        filterList = response.data.clients.filter((e) => e.isActive == flag);
      }

      if (response.status == 200) {
        this.setState(
          {
            mappedClient:
              parseInt(dropdown_status, 10) > 0
                ? filterList
                : response.data.clients,
            defaultClient: response.data.clients,
            serviceCategoryList: response.data.dropdownList.serviceCategoryList,
            statusList: response.data.dropdownList.statusList,
            clientPendingChangeRequestCount:
              response.data.clientPendingChangeRequestCount,
          },
          () => {
            this.setState({
              isLoading: false,
            });
          }
        );
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      }
    } catch (error) {
    }
  };

  /* <summary>
     date: 23/12/2022
     Name: AN
     description: On filter dropdown selected
     <summary> */
  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterGridView()
    );
  };

  /* <summary>
     date: 23/12/2022
     Name: AN
     description: Filter grid data using the dropdown selected available in the ui
     <summary> */
  filterGridView = () => {
    const { dropdown_serviceCategory, dropdown_status, searchedText } =
      this.state;
    var filterList = [];
    var service_Category =
      dropdown_serviceCategory == undefined
        ? 0
        : parseInt(dropdown_serviceCategory, 10);
    var status_dropdown =
      dropdown_status == undefined ? 0 : parseInt(dropdown_status, 10);
    var clientList = this.state.defaultClient;

    if (service_Category == 0 && status_dropdown == 0) {
      filterList = this.state.defaultClient;
    } else {
      if (parseInt(dropdown_serviceCategory, 10) > 0) {
        let finalArray = [];
        if (filterList.length > 0) {
          filterList.map((x) => {
            let listCategory = x.serviceCategoryIdsList;
            return listCategory.map((y) => {
              if (parseInt(dropdown_serviceCategory, 10) == y) {
                return finalArray.push(x);
              }
            });
          });
        } else {
          clientList.map((x) => {
            let listCategory = x.serviceCategoryIdsList;
            return listCategory.map((y) => {
              if (parseInt(dropdown_serviceCategory, 10) == y) {
                return finalArray.push(x);
              }
            });
          });
        }
        filterList = finalArray;
      }

      if (parseInt(dropdown_status, 10) > 0) {
        var flag = null;
        if (parseInt(dropdown_status, 10) == CLIENT_STATUS_ID.ACTIVE) {
          flag = true;
        } else if (parseInt(dropdown_status, 10) == CLIENT_STATUS_ID.INACTIVE) {
          flag = false;
        }
        filterList =
          filterList.length > 0
            ? filterList.filter((e) => e.isActive == flag)
            : clientList.filter((e) => e.isActive == flag);
      }
    }

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.clientName?.toString().toLowerCase().includes(value) ||
          t.clientCode?.toString().toLowerCase().includes(value) ||
          t.serviceCategoryName?.toString().toLowerCase().includes(value) ||
          t.clientRoleName?.toString().toLowerCase().includes(value) ||
          t.shipNames?.toString().toLowerCase().includes(value) ||
          t.clientPicName?.toString().toLowerCase().includes(value) ||
          t.contactNumber?.toString().toLowerCase().includes(value) ||
          t.address?.toString().toLowerCase().includes(value) ||
          t.city?.toString().toLowerCase().includes(value) ||
          t.postalCode?.toString().toLowerCase().includes(value) ||
          t.primaryEmailAddress?.toString().toLowerCase().includes(value) ||
          t.regionName?.toString().toLowerCase().includes(value) ||
          t.briefTerms?.toString().toLowerCase().includes(value) ||
          t.ihmminchargeName?.toString().toLowerCase().includes(value) ||
          t.ihmminchargeEmail?.toString().toLowerCase().includes(value) ||
          t.alternateIhmminchargeName
            ?.toString()
            .toLowerCase()
            .includes(value) ||
          t.alternateihmminchargeEmail
            ?.toString()
            .toLowerCase()
            .includes(value) ||
          t.contractDateValue?.toString().toLowerCase().includes(value) ||
          t.contractExpiryDateValue?.toString().toLowerCase().includes(value) ||
          t.createdOnValue?.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedClient: filterList,
    });
  };

  /* <summary>
     date: 11/1/2023
     Name: AN
     description: On click switch button it changes the active or inactive status of the client
     <summary> */
  onSwitchChange = async (checked, e, id) => {
    try {
      const { dropdown_serviceCategory, dropdown_status } = this.state;
      this.setState({
        isLoading: true,
      });
      var Client = {
        ClientId: id,
        IsActive: checked,
        EditedBy: this.state.userId,
      };
      let response = await ClientService.UpdateClientStatus(Client);

      if (response.status == 200 && response.status != "") {
        var clientRes = this.getManageClientData(this.state.userId);

        if (clientRes != null && clientRes != undefined) {
          var filterList = [];
          var service_Category =
            dropdown_serviceCategory == undefined
              ? 0
              : parseInt(dropdown_serviceCategory, 10);
          var status_dropdown =
            dropdown_status == undefined ? 0 : parseInt(dropdown_status, 10);
          var clientList = clientRes.data;

          if (service_Category == 0 && status_dropdown == 0) {
            filterList = this.state.defaultClient;
          } else {
            if (parseInt(dropdown_serviceCategory, 10) > 0) {
              let finalArray = [];
              if (filterList.length > 0) {
                filterList.map((x) => {
                  let listCategory = x.serviceCategoryIdsList;
                  return listCategory.map((y) => {
                    if (parseInt(dropdown_serviceCategory, 10) == y) {
                      return finalArray.push(x);
                    }
                  });
                });
              } else {
                clientList.map((x) => {
                  let listCategory = x.serviceCategoryIdsList;
                  return listCategory.map((y) => {
                    if (parseInt(dropdown_serviceCategory, 10) == y) {
                      return finalArray.push(x);
                    }
                  });
                });
              }
              filterList = finalArray;
            }

            if (parseInt(dropdown_status, 10) > 0) {
              var flag = null;
              if (parseInt(dropdown_status, 10) == CLIENT_STATUS_ID.ACTIVE) {
                flag = true;
              } else if (
                parseInt(dropdown_status, 10) == CLIENT_STATUS_ID.INACTIVE
              ) {
                flag = false;
              }
              filterList =
                filterList.length > 0
                  ? filterList.filter((e) => e.isActive == flag)
                  : clientList.filter((e) => e.isActive == flag);
            }
          }
          this.setState({
            mappedClient: filterList,
          });
        }
      } else if (response.status == 401) {
        this.setState({
          isLoading: false,
        });
        Logout(this.props.navigate);
      }
    } catch (error) {
    }
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    } else {
      this.props.navigate("/Dashboard");
    }
  };

  /* <summary>
     date: 26/11/2022
     Name: AN
     description: Filter data from grid using search bar
     <summary> */
  onSearch = (e) => {
    var inputValue = e.target.value;
    var clientList = this.state.defaultClient;

    var inputValue = e.target.value;
    this.setState(
      {
        searchedText: inputValue,
      },
      () => {
        this.filterGridView();
      }
    );

    //SYJ - Commented for
    // if (inputValue.trim() !== "") {
    //   var value = inputValue.trim().toString().toLowerCase();
    //   clientList = clientList.filter(
    //     (t) =>
    //       t.clientName?.toString().toLowerCase().includes(value) ||
    //       t.clientCode?.toString().toLowerCase().includes(value) ||
    //       t.serviceCategoryName?.toString().toLowerCase().includes(value) ||
    //       t.clientRoleName?.toString().toLowerCase().includes(value) ||
    //       t.ships?.toString().toLowerCase().includes(value) ||
    //       t.clientPicName?.toString().toLowerCase().includes(value) ||
    //       t.contactNumber?.toString().toLowerCase().includes(value) ||
    //       t.address?.toString().toLowerCase().includes(value) ||
    //       t.city?.toString().toLowerCase().includes(value) ||
    //       t.postalCode?.toString().toLowerCase().includes(value) ||
    //       t.primaryEmailAddress?.toString().toLowerCase().includes(value) ||
    //       t.regionName?.toString().toLowerCase().includes(value) ||
    //       t.briefTerms?.toString().toLowerCase().includes(value) ||
    //       t.ihmminchargeName?.toString().toLowerCase().includes(value) ||
    //       t.ihmminchargeEmail?.toString().toLowerCase().includes(value) ||
    //       t.alternateIhmminchargeName
    //         ?.toString()
    //         .toLowerCase()
    //         .includes(value) ||
    //       t.alternateihmminchargeEmail?.toString().toLowerCase().includes(value)
    //   );
    // }

    // this.setState({
    //   mappedClient: clientList,
    // });
  };

  /* <summary>
     date: 26/12/2022
     Name: AN
     description: OnAddClient button click or Edit icon click from grid, navigate to the new page and pass the data of client 
     <summary> */
  onAddEditClientClicked = (clientId) => {
    AddLocalStorageData("selectedClient", clientId);
    this.props.navigate("/AddEditClient");
  };

  onModalCloseClick = () => {
    this.setState({ showViewHistoryModal: false });
  };

  /* <summary>
  date: 20/01/2023
  Name: HV
  description: viewing client history
  <summary> */

  onViewHistoryClick = async (data) => {
    try {
      this.setState({
        isLoading: true,
      });
      const response = await ClientService.GetClientHistory(data.clientId);
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          if (response.data.length > 0) {
            this.setState({
              listHistoryData: response.data,
              showViewHistoryModal: true,
            });
          } else {
            this.setState({
              listHistoryData: [],
              showViewHistoryModal: false,
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.global.noHistoryData,
            });
          }
        }
      } else {
        this.setState({
          listHistoryData: [],
          showViewHistoryModal: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
  date: 23/01/2023
  Name: SYJ
  description: Navigates to manage Client User page and passes the client object
  <summary> */
  manageClientUserClicked = (clientId) => {
    AddLocalStorageData("selectedClient", JSON.stringify(clientId));
    this.props.navigate("/ManageClientUsers");
  };

  /* <summary>
 date: 13/02/2023
 Name: HV
 description: clearing filter
 <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_status: "0",
        dropdown_serviceCategory: "0",
        searchedText: ""
      },
      () => {
        this.filterGridView();
      }
    );
  };

  render() {
    const {
      mappedClient,
      colDefs,
      serviceCategoryList,
      statusList,
      isLoading,
      toastVisible,
      showViewHistoryModal,
      historyColumns,
      listHistoryData,
      toastMessage,
      toastType,
      clientPendingChangeRequestCount,
      dropdown_serviceCategory,
      dropdown_status,
      accessList,
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ManageClient"} />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {showViewHistoryModal && (
          <ViewHistoryModal
            showViewHistoryModal={showViewHistoryModal}
            mappedHistory={listHistoryData}
            colDefs={historyColumns}
            onCloseClick={this.onModalCloseClick}
          />
        )}

        <main className="page-height">
          <section className="communications">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                    onClick={this.goback}
                  />
                  <span className="title-font">Manage Clients</span>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <div className="searchBar">
                    <Searchbar
                      placeholder={"Client Name, Client Code, etc..."}
                      onChange={this.onSearch}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-filter-dropdown">
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"sc"}>
                          <Dropdown
                            dropdownLabel="Service Category:"
                            name="serviceCategory"
                            onChange={this.onFilterSelected}
                            selected={dropdown_serviceCategory}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {serviceCategoryList == null
                              ? null
                              : serviceCategoryList.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_serviceCategory > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"sc"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {serviceCategoryList != null && serviceCategoryList.filter(x => x.value == dropdown_serviceCategory)[0]?.label}
                            </UncontrolledTooltip>) : null}
                        </div>

                        <div className="form-group dropdown-form admin-client-form" id={"stats"}>
                          <Dropdown
                            dropdownLabel="Status:"
                            name="status"
                            onChange={this.onFilterSelected}
                            selected={dropdown_status}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                          >
                            {statusList == null
                              ? null
                              : statusList.map((item, key) => (
                                <option key={key} value={item.statusId}>
                                  {item.name}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {parseInt(dropdown_status, 10) > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"stats"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {statusList != null && statusList.filter(x => x.statusId == dropdown_status)[0]?.name}
                            </UncontrolledTooltip>) : null}
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 clear-all-btn">
                          <button
                            type="button"
                            className="btn btn-blue-bg"
                            onClick={this.clearAllFilter}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"sc1"}>
                      <Dropdown
                        dropdownLabel="Service Category:"
                        name="serviceCategory"
                        onChange={this.onFilterSelected}
                        selected={dropdown_serviceCategory}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {serviceCategoryList == null
                          ? null
                          : serviceCategoryList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_serviceCategory > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"sc1"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {serviceCategoryList != null && serviceCategoryList.filter(x => x.value == dropdown_serviceCategory)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form admin-client-form" id={"stat1"}>
                      <Dropdown
                        dropdownLabel="Status:"
                        name="status"
                        onChange={this.onFilterSelected}
                        selected={dropdown_status}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {statusList == null
                          ? null
                          : statusList.map((item, key) => (
                            <option key={key} value={item.statusId}>
                              {item.name}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_status > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"stat1"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {statusList != null && statusList.filter(x => x.statusId == dropdown_status)[0]?.name}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 desktop-clear-btn clear-all-btn">
                  <button
                    type="button"
                    className="btn btn-blue-bg"
                    onClick={this.clearAllFilter}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-12 btn-top-space">
                  <div className="float-btn manage-client-button">
                    <button
                      type="button"
                      disabled={
                        accessList != null &&
                          accessList.clientAndShipAccess == UserAction.READ
                          ? true
                          : false
                      }
                      onClick={() =>
                        this.props.navigate("/ManageClientRolesAndPermission")
                      }
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles manage-add-client"
                    >
                      Client Roles and Permissions
                    </button>

                    <button
                      type="button"
                      disabled={
                        accessList != null &&
                          accessList.clientAndShipAccess == UserAction.READ
                          ? true
                          : false
                      }
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-change-btn"
                      onClick={() =>
                        this.props.navigate("/ManageChangeRequest")
                      }
                    >
                      Manage Change Requests
                      {clientPendingChangeRequestCount > 0 && (
                        <span className="badge">
                          {clientPendingChangeRequestCount}
                        </span>
                      )}
                    </button>

                    {/* </a> */}
                    <button
                      type="button"
                      disabled={
                        accessList != null &&
                          accessList.clientAndShipAccess == UserAction.READ
                          ? true
                          : false
                      }
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() => this.onAddEditClientClicked(0)}
                    >
                      <img src="images/admin-plus-icon.png" alt="plus-icon" />
                      Add Client
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      onGridReady={this.onGridReady}
                      rowData={mappedClient}
                      colDefs={colDefs}
                      rowSelection="single"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <br />
        <br />
        <Footer />
      </>
    );
  }
}

export default withRouter(ManageClient);
