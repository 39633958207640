import React, { useEffect, useState } from "react";
import { settingsService } from "../../services/settings.service";
import { GetLocalStorageDataWithParse, helperText } from "../../helpers";

const LoginSettings = ({ SettingsData, ToggleLoader, ShowToastModal }) => {
  const [maxActiveLogins, setMaxActiveLogins] = useState(3);
  const [idleSessionTimeout, setIdleSessionTimeout] = useState(0);
  const [idleSessionTimeoutHour, setIdleSessionTimeoutHour] = useState(3);
  //const [idleSessionTimeoutMin, setIdleSessionTimeoutMin] = useState(0);
  const [settingsID, setSettingsID] = useState(0);
  const [errorMessage, setErrorMessage] = useState({
    errorMaxActiveLogins: false,
    errorIdleSessionTimeout: false,
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [useID, setUseID] = useState(0);

  useEffect(() => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    
    if (SettingsData) {
      
      var { maxActiveUser, idleSessionTimeout, settingsID } =
        SettingsData.loginSettings;
      setMaxActiveLogins(maxActiveUser);
      setIdleSessionTimeout(idleSessionTimeout);
      var response = timeConvert(idleSessionTimeout);
      
      setIdleSessionTimeoutHour(response.rhours);
      //setIdleSessionTimeoutMin(response.rminutes);
      setSettingsID(settingsID);
    }
    setUseID(userData ? userData.id : 0);
    return () => { };
  }, [SettingsData]);
  const OnMaxActiveUserChange = (e) => {
    var { value } = e.target;
    if (value <= 20 && value >= 1){
      setMaxActiveLogins(value);
    }
    else if(value == ""){
      value = 1;
      setMaxActiveLogins(value);
    } 
  };
  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return { rhours, rminutes };
  }
  const OnIdleSessionTimeoutHourChange = (e) => {
    var { value } = e.target;
    if (value >= 3 && value <= 8) {
      setIdleSessionTimeoutHour(value);
    }
    // if (value == 8) {
    //   setIdleSessionTimeoutMin(0);
    // }
  };
  // const OnIdleSessionTimeoutMinuteChange = (e) => {
  //   var { value } = e.target;
  //   if (value == 60 && idleSessionTimeoutHour <= 7) {
  //     setIdleSessionTimeoutMin(0);
  //     setIdleSessionTimeoutHour((t) => parseInt(t, 10) + 1);
  //   } else if (idleSessionTimeoutHour <= 7) {
  //     setIdleSessionTimeoutMin(value);
  //   }
  // };
  const GetIdleSessionTimeOut = () => {
    var totalMinutes = 0;
    if (idleSessionTimeoutHour != 0) {
      totalMinutes = idleSessionTimeoutHour * 60;
    }
    return parseInt(totalMinutes, 10);
  };

  const SaveLoginSettings = async () => {
    ToggleLoader();
    let _idleSessionTimeout = GetIdleSessionTimeOut();
    var response = await settingsService.UpdateLoginSettings({
      MaxActiveUser: maxActiveLogins,
      IdleSessionTimeout: _idleSessionTimeout,
      editedBy: useID,
      settingsID,
    });
    ToggleLoader();
    if (response.status == 200) {
      ShowToastModal(
        helperText.SettingsPageText.loginUpdateSuccess,
        helperText.global.toastSuccess
      );
    } else {
      ShowToastModal(
        helperText.global.somethingWentWrong,
        helperText.global.toastError
      );
    }
  };

  return (
    <div id="Login-settings" className="tabcontent">
      <div className="tabcontent-data mb-3">
        <div className="loginsrnlim table-responsive-xxl">
          <h5>Max Active Logins Per User</h5>
          <table>
            <tbody>
              <tr>
                <td>Number of active sessions allowed for single user:</td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    min={1}
                    max={20}
                    value={maxActiveLogins}
                    onChange={OnMaxActiveUserChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="tabcontent-data">
        <div className="loginsession">
          <h5>Session Timeout</h5>
          <div className="table-responsive-xxl">
            <table className="loginsessiontable">
              <tbody>
                <tr>
                  <td>Session timeout after:</td>
                  <td className="loginsessionhrs">
                    <input
                      type="number"
                      className="form-control"
                      min={3}
                      max={8}
                      value={idleSessionTimeoutHour}
                      onChange={OnIdleSessionTimeoutHourChange}
                    />
                    <span>{idleSessionTimeoutHour > 1 ? "hrs" : "hr"}</span>
                  </td>
                  {/* <td className="loginsessionmin">
                    <input
                      type="number"
                      className="form-control"
                      value={idleSessionTimeoutMin}
                      min={0}
                      max={60}
                      onChange={OnIdleSessionTimeoutMinuteChange}
                    />
                    <span>{idleSessionTimeoutMin > 1 ? "mins" : "min"}</span>
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="admin-ship-settings-save-btn settings-save-btn">
        <button
          className="btn btn-block"
          type="button"
          onClick={SaveLoginSettings}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default LoginSettings;
