import webserviceWrapper from "../helpers/webserviceWrapper";

export const NotificationService = {
  GetNotificationData,
  SaveNotificationIsViewedStatus,
};

function GetNotificationData(UserId, userTypeId, showAllNotification) {
  return webserviceWrapper.get(
    `/Notification/GetNotificationData?userId=${UserId}&userTypeId=${userTypeId}&showAllNotification=${showAllNotification}`
  );
}

function SaveNotificationIsViewedStatus(data) {
  return webserviceWrapper.post(
    `/Notification/SaveNotificationIsViewedStatus`,
    data
  );
}
