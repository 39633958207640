import Footer from "../../components/Footer";
import errorIcon from "../../assets/images/404-error.jpg";

const ErrorComponent = () => {
  return (
    <div>
      <main style={{ backgroundColor: "#ffffff", minHeight: "65vh" }}>
        <div className="admin-main-body error-page-body-header">
          {/* <div className="admin-main-body profile-page-header-bg contact-us-body-header"> */}
          <div className="container" style={{display:'flex', justifyContent: 'center'}}>
            <div className="row">
              <div className="content-alignment">
                <div>
                  <img width="450px" src={errorIcon} alt="Not Found" height="450px"  />
                </div>
                <div className="content-pad">
                  <h3 className="text-bold">Page Not Found</h3>
                </div>
                <div className="para-pad">
                  <p>We're sorry, the page you requested could not be found</p>
                </div>
                <a href="/Dashboard">
                  <button className="btn btn-block Contact_Us-btn">
                    Go to Dashboard
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        <Footer />
      </main>
    </div>
  );
};

export default ErrorComponent;
