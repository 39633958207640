import webserviceWrapper from "../helpers/webserviceWrapper";

export const ReportService = {
  GetReportData,
  AddReportData,
  DownloadReportDoc,
  DeleteReportDocData,
    GenerateReport,
    GenerateAnnualReport,
  GetFileName,
  AddReportDocType,
  CheckIfDocTypeExist
};

function GetReportData(userID, clientUserId,typeId) {
  return webserviceWrapper.get(`/Report/GetReportsData?userID=${userID}&clientUserId=${clientUserId}&typeId=${typeId}`);
}

function AddReportData(data) {
  return webserviceWrapper.postWithHeader(`/Report/AddReportData`, data, { "Content-Type": "multipart/form-data", }, 600000);
}
function AddReportDocType(docData) {
  return webserviceWrapper.post(`/Report/AddReportDocType`,docData);
}
function CheckIfDocTypeExist(docData) {  
  return webserviceWrapper.post(`/Report/CheckIfDocTypeExist`,docData);
}
function DownloadReportDoc(tFileIDlist) {
  return webserviceWrapper.post(
    `/Report/DownloadReportDoc`,
    tFileIDlist,
    600000
  );
}

function DeleteReportDocData(ihmDocID, userId, typeId) {
  return webserviceWrapper.get(
    `/Report/DeleteReportDocData?reportDocsId=${ihmDocID}&userID=${userId}&typeId=${typeId}`
  );
}

function GetFileName(documentFileModel) {
    return webserviceWrapper.post(
      `/Report/GetReportFileName`,
      documentFileModel, 60000
      );
}
function GenerateReport(data) {
  return webserviceWrapper.postWithHeader(`/Report/GenerateReport`, data,{ "Content-Type": "multipart/form-data", }, 600000);
}
function GenerateAnnualReport(data) {
    return webserviceWrapper.postWithHeader(`/Report/GenerateAnnualReport`, data, { "Content-Type": "multipart/form-data", }, 600000);
}
