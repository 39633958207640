import React, { Component } from "react";
import { helperText } from "../../helpers";
import { isStringEmpty } from "../../helpers/string-helper";
import { TrackingToolService } from "../../services/trackingTool.service";


const ErrorView = (props) => {
    return (
        <>
            {props.errorFor != "" && (
                <div
                    className={
                        props.errorFor == "emptyDropdown"
                            ? "invalid-dropdown-icon-modal"
                            : "invalid-message-icon"
                    }
                    style={{ marginTop: "8px" }}
                >
                    <img src="images\icon material-error.png" alt="invalid" />
                </div>
            )}
        </>
    );
};

export default class AddHazmatItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessages: {},
            emptyFileError: false,
        };
    }


    validateFields = async () => {
        var {
            hazmatvalue, errorMessages
        } = this.state;

        var hasErrors = false;

        let errors = errorMessages;
        errors["errorClient"] = isStringEmpty(hazmatvalue) ? "true" : "";


        if (errors["errorClient"] != "") {
            hasErrors = true;
            this.setState({
                validateError: hasErrors,
                errorMessages: errors,
                emptyFileError: false,
                invalidFileError: false,
                hazmatpresent: false,
            });
        }
        else {
            var isHazmatPresent = await TrackingToolService.CheckIfHazmatPresent(hazmatvalue.trim().toLocaleLowerCase());
            if (isHazmatPresent.data == true) {
                this.setState({
                    hazmatpresent: true,
                });
                hasErrors = true
            }
            else {
                hasErrors = false
                this.setState({
                    hazmatpresent: false,
                    validateError: hasErrors,
                });
            }
        }
        return hasErrors;
    }


    onTextChange = (e) => {
        const { validateError , hazmatpresent} = this.state;
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.setState({ hazmatvalue: value }
            , () => {
            if (validateError || hazmatpresent) this.validateFields();
        });
    };


    onUploadClickBtnClick = async () => {
        var {
            hazmatvalue,
        } = this.state;
        const { onUploadClick } = this.props;
        var hasErrors = await this.validateFields();
        if (hasErrors) return;
        const data = {
            hazmatvalue: hazmatvalue,
        };
        onUploadClick(data);
    };


    render() {
        const {
            onCloseClick
        } = this.props;

        const {
            validateError,
            hazmatvalue,
            errorMessages,           
            hazmatpresent
        } = this.state;

        return (
            <div className="Training_Evalution" style={{ display: "block" }}>               
                <div
                    className="modal-backdrop fade show"
                    style={{ display: "block" }}
                ></div>
                <div className="modal" id="uploadPOlist" style={{ display: "block" }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered modal-box">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add New Suspected Hazmat</h4>
                                <button type="button" data-bs-dismiss="modal">
                                    <img
                                        src="images/modal-close.png"
                                        alt="modal-close"
                                        style={{ pointerEvents: "all" }}
                                        onClick={() => onCloseClick("showUploadModal", false)}
                                    />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="PO-list-topLabel">
                                    <div className="row top">
                                        <div className="col-lg-12 col-md-12 col-sm-12 data">
                                            <div
                                                className={
                                                    !isStringEmpty(errorMessages.errorClient)
                                                        ? "invalid-position"
                                                        : ""
                                                }
                                            >
                                                <label className="required-field">Add New Suspected Hazmat</label>
                                                <input
                                                    autoFocus={false}
                                                    autoComplete="new-password"
                                                    className="form-control"
                                                    type="text"
                                                    id="hazmat"
                                                    name="hazmat"
                                                    value={hazmatvalue}
                                                    onChange={this.onTextChange}
                                                    maxLength={50}
                                                />
                                                {/* {!isStringEmpty(errorMessages.errorClient) && (
                                                    <ErrorView errorFor="emptyDropdown" />
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {(validateError && !isStringEmpty(errorMessages.errorClient)) && (
                                    <div className="invalid-message-icon-box">
                                        <img
                                            src="images/icon material-error.png"
                                            alt="invalid-icon"
                                        />
                                        <p>Please fill in all the mandatory fields.</p>
                                    </div>
                                )}


                                {(hazmatpresent) && (
                                    <div className="invalid-message-icon-box">
                                        <img
                                            src="images/icon material-error.png"
                                            alt="invalid-icon"
                                        />
                                        <p>Suspected Hazmat Item already Exist.</p>
                                    </div>
                                )}

                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-background-color upload-btn"
                                        onClick={this.onUploadClickBtnClick}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
