import React, { Component } from "react";
import Switch from "react-switch";
import Loader from "../components/Loader";
import { UserService } from "../services/user.service";
import { helperText, withRouter } from "../helpers";

const defaultArray = {
  clientAndShipAccess: 0,
  trackingToolAccess: 0,
  communicationAccess: 0,
  reportAccess: 0,
  statisticAccess: 0,
  supplierAccess: 0,
  trainingAccess: 0,
  manageUserAccess: 0,
  ihmdatabaseAccess: 0,
  financeAndAccountAccess: 0,
};

export default class UserRolesNPermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: this.props.clientId,
      isLoading: false,
      accessPermissionsLevel1: [],
      accessPermissionsLevel2: [],
      accessPermissionsLevel3: [],
    };
  }

  componentDidMount() {
    this.getDefaultAccessPermissions();
  }

  getDefaultAccessPermissions = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await UserService.GetDefaultPermissions();
    if (response != null && response != undefined && response.status == 200) {
      
      if (response.data.length > 0) {
        this.setState({
          accessPermissionsLevel1: response.data[0],
          accessPermissionsLevel2: response.data[1],
          accessPermissionsLevel3: response.data[2],
          isLoading: false,
        });
      } else {
        this.setState({
          accessPermissionsLevel1: { ...defaultArray },
          accessPermissionsLevel2: { ...defaultArray },
          accessPermissionsLevel3: { ...defaultArray },
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: "fail",
        isLoading: false,
      });
    }
  };

  render() {
    const { showModal, closeModal } = this.props;
    const {
      isLoading,
      accessPermissionsLevel1,
      accessPermissionsLevel2,
      accessPermissionsLevel3,
    } = this.state;
    const SwitchControl = (props) => {
      return (
        <td className="center">
          <Switch
            id={props.id}
            className="switch"
            height={20}
            width={40}
            disabled={true}
            checked={props.IsChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor={"#C90000"}
          />
        </td>
      );
    };
    const SectionRow = (props) => {
      return (
        <tr
          style={{
            backgroundColor: props.permissionAccess == 0 ? "#ff3b3b" : "",
          }}
        >
          <SwitchControl
            id={`read_${props.id}`}
            IsChecked={props.permissionAccess == 1}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />{" "}
          {/*Read */}
          <SwitchControl
            id={`readWrite_${props.id}`}
            IsChecked={props.permissionAccess == 2}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Read and Write */}
          <SwitchControl
            id={`deny_${props.id}`}
            IsChecked={props.permissionAccess == 3}
            currentPermission={props.permissionAccess}
            precedingPermission={props.precedingPermission}
          />
          {/*Deny */}
        </tr>
      );
    };

    return (
      <section className="communications role-permissions-class">
        {isLoading && <Loader></Loader>}
        <div
          className="modal-backdrop fade show"
          style={{ display: showModal ? "block" : "none" }}
        ></div>
        <div
          className="modal"
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog custom-modal-dialog-width modal-dialog-centered modal-custom-css">
            <div className="modal-content custom-modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">
                  {"View Roles & Permissions"}
                </h5>
                <img
                  className="close-img-icon"
                  src="images/modal-close.png"
                  width="25px"
                  style={{ pointerEvents: "all" }}
                  onClick={closeModal}
                />
              </div>
              <div className="modal-body">
                <div className="row  levels-table">
                  <div className="col-12 col-md-12 col-lg-12 table-left-padding table-pl-responsive">
                    <div className="level-boxes client-level-boxes">
                      <div className="level-boxes-spacing mobile-level-box">
                        <h5 className="level-heading"> Levels: </h5>
                        <table className="table table-bordered manage-users-table roles-permissions-tbl client-roles-permissions-tbl">
                          <thead className="level-table-header-color">
                            <tr>
                              <th className="table-one-text">Roles</th>
                            </tr>
                          </thead>
                          <tbody className="table-one-body">
                            <tr>
                              <td className="roles-table-heading">
                                Client and Ship Particulars
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Tracking Tool
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Communication
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">Reports</td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Statistics
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">Supplier</td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">Training</td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Manage User
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                IHM Database & Registry
                              </td>
                            </tr>
                            <tr>
                              <td className="roles-table-heading">
                                Miscellaneous Operation
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="table-responsive d-flex">
                        <div className="level-boxes-spacing mobile-level-box1">
                          <h5 className="level-heading"> Level 1: </h5>
                          <table className="table manage-users-table roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body" disabled={true}>
                              <SectionRow
                                id="clientAndShipAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.clientAndShipAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trackingToolAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.trackingToolAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="communicationAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.communicationAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="reportAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.reportAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="statisticAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.statisticAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="supplierAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.supplierAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="trainingAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.trainingAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="manageUserAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.manageUserAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="ihmdatabaseAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.ihmdatabaseAccess
                                }
                                precedingPermission={""}
                              />
                              <SectionRow
                                id="financeAndAccountAccess_Level1"
                                permissionAccess={
                                  accessPermissionsLevel1.financeAndAccountAccess
                                }
                                precedingPermission={""}
                              />
                            </tbody>
                          </table>
                        </div>
                        <div className="level-boxes-spacing">
                          <h5 className="level-heading"> Level 2: </h5>
                          <table className="table manage-users-table roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body">
                              <SectionRow
                                id="clientAndShipAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.clientAndShipAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.clientAndShipAccess
                                }
                              />
                              <SectionRow
                                id="trackingToolAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.trackingToolAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.trackingToolAccess
                                }
                              />
                              <SectionRow
                                id="communicationAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.communicationAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.communicationAccess
                                }
                              />
                              <SectionRow
                                id="reportAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.reportAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.reportAccess
                                }
                              />
                              <SectionRow
                                id="statisticAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.statisticAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.statisticAccess
                                }
                              />
                              <SectionRow
                                id="supplierAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.supplierAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.supplierAccess
                                }
                              />
                              <SectionRow
                                id="trainingAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.trainingAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.trainingAccess
                                }
                              />
                              <SectionRow
                                id="manageUserAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.manageUserAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.manageUserAccess
                                }
                              />
                              <SectionRow
                                id="ihmdatabaseAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.ihmdatabaseAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.ihmdatabaseAccess
                                }
                              />
                              <SectionRow
                                id="financeAndAccountAccess_Level2"
                                permissionAccess={
                                  accessPermissionsLevel2.financeAndAccountAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel1.financeAndAccountAccess
                                }
                              />
                            </tbody>
                          </table>
                        </div>
                        <div className="level-boxes-spacing">
                          <h5 className="level-heading"> Level 3: </h5>
                          <table className="table manage-users-table roles-permissions-table">
                            <thead className="level-table-header-color">
                              <tr>
                                <th className="table-header-text">Read</th>
                                <th className="table-header-text client-roles-header-text">
                                  <div className="tooltip p-0">
                                    R & W
                                    <span className="tooltiptext">
                                      Read & Write
                                    </span>
                                  </div>
                                </th>
                                <th className="table-header-text">Deny</th>
                              </tr>
                            </thead>
                            <tbody className="table-one-body">
                              <SectionRow
                                id="clientAndShipAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.clientAndShipAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.clientAndShipAccess
                                }
                              />
                              <SectionRow
                                id="trackingToolAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.trackingToolAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.trackingToolAccess
                                }
                              />
                              <SectionRow
                                id="communicationAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.communicationAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.communicationAccess
                                }
                              />
                              <SectionRow
                                id="reportAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.reportAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.reportAccess
                                }
                              />
                              <SectionRow
                                id="statisticAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.statisticAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.statisticAccess
                                }
                              />
                              <SectionRow
                                id="supplierAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.supplierAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.supplierAccess
                                }
                              />
                              <SectionRow
                                id="trainingAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.trainingAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.trainingAccess
                                }
                              />
                              <SectionRow
                                id="manageUserAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.manageUserAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.manageUserAccess
                                }
                              />
                              <SectionRow
                                id="ihmdatabaseAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.ihmdatabaseAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.ihmdatabaseAccess
                                }
                              />
                              <SectionRow
                                id="financeAndAccountAccess_Level3"
                                permissionAccess={
                                  accessPermissionsLevel3.financeAndAccountAccess
                                }
                                precedingPermission={
                                  accessPermissionsLevel2.financeAndAccountAccess
                                }
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
