import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { UserService } from "../../services/user.service";
import { UserAction } from "../../helpers/enums";
import editIcon from "../../assets/images/Icon feather-edit.png";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Snackbar from "../../components/Snackbar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import MobileDropdown from "../../components/Mobile/MobileDropdown";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import Toast from "../../components/Toast";
import eyeIcon from "../../assets/images/admin-eye.png";
import { UncontrolledTooltip } from "reactstrap";

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mappedUsers: [],
      defaultUsers: [],
      colDefs: this.gridColumns,
      isLoading: false,
      toastVisible: false,
      toastResponseType: "",
      toastMessage: "",

      dropdown_client: "0",
      dropdown_ship: "0",
      dropdown_region: "0",
      dropdown_employee: "0",
      dropdown_level: "0",
      dropdown_serviceCategory: "0",
      userId: 0,
      clientList: [],
      shipList: [],
      dropdownList: [],
      serviceCategoryList: [],
      regionList: [],
      userLevel: [],
      userList: [],
      gridApi: null,
      accessList: [],
      userRolePermissionRead: false,
      searchedText: "",
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name 
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState({ accessList: GetLocalStorageDataWithParse('userRolePermission'), userId: userData.id },
        () => {
          this.getDropdownData();
        });
    }
  }

  // componentDidUpdate() {
  //   if (this.state.gridApi) {
  //     this.state.gridApi.paginationGoToPage(0);
  //   }
  // }

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    })
  }

  /* <summary>
date: 21/02/2023
Name: HV
description: dropdown data binding
<summary> */

  getDropdownData = async () => {
    this.setState({ isLoading: true, userRolePermissionRead: this.state.accessList != null && this.state.accessList.manageUserAccess == UserAction.READ, })

    const response = await UserService.GetDropdownData(this.state.userId);
    if (response != null && response != undefined && response.status == 200) {
      this.setState({
        serviceCategoryList: response.data.serviceCategories,
        dropdownList: response.data,
        regionList: response.data.regionList,
        clientList: response.data.clientList,
        shipList: response.data.shipList,
        usersList: response.data.usersList,
        userLevel: response.data.userLevel
      }, () => {
        this.getManageUsersData(this.state.userId);
      })

    } else if (response.status == 401) {
      Logout(this.props.navigate);
    }
    else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
      });
    }
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  gridColumns = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Employee Name",
      field: "name",
      tooltipField: "name",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Designation",
      field: "designation",
      tooltipField: "designation",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Region",
      field: "regionName",
      tooltipField: "regionName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Client",
      field: "clientName",
      tooltipField: "clientName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Ship",
      field: "shipName",
      tooltipField: "shipName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Username",
      field: "userName",
      tooltipField: "userName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Employee Email",
      field: "emailAddress",
      tooltipField: "emailAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Level",
      field: "level",
      tooltipField: "level",
    },
    {
      headerName: "Employee Address",
      field: "address",
      tooltipField: "address",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 200,
    },
    {
      headerName: "Service Category",
      field: "serviceCategoryName",
      tooltipField: "serviceCategoryName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 200,
    },
    {
      headerName: "Date of Creation",
      field: "createdOn",
      tooltipField: "createdOn",
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={this.state.userRolePermissionRead ? eyeIcon : editIcon}
          title={this.state.userRolePermissionRead ? "View" : "Edit"}
          onClick={() => { this.addEditInternalUser(params.data.userId) }}
          style={{ width: "20px"}}
        />
      ),
      cellStyle: {textAlign:"center" , paddingInline: "0"}
    },
  ];

  getManageUsersData = (userId) => {
    this.setState({ isLoading: true }, async () => {
      const response = await UserService.GetManageUsersData(userId);
      if (response != null && response != undefined && response.status == 200) {
        this.setState({
          mappedUsers: response.data.users,
          defaultUsers: response.data.users,
          isLoading: false,
        });
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: "fail",
        });
      }
    });
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterGridView()
    );
  };

  filterGridView = () => {

    const {
      dropdown_client,
      dropdown_ship,
      dropdown_region,
      dropdown_employee,
      dropdown_level,
      dropdown_serviceCategory,
      dropdownList,
      searchedText
    } = this.state;

    var filterList = [];
    var lstFilteredShips = [];
    var userList = this.state.defaultUsers;
    lstFilteredShips = dropdownList.shipList;

    if (
      dropdown_client == "0" &&
      dropdown_ship == "0" &&
      dropdown_region == "0" &&
      dropdown_employee == "0" &&
      dropdown_level == "0" &&
      dropdown_serviceCategory == "0"
    )
      filterList = this.state.defaultUsers;
    else {
      var filterapplied = false;
      if (dropdown_client != "0") {
        filterList = userList.filter((e) =>
          e.clientId.includes(parseInt(dropdown_client, 10))
        );

        lstFilteredShips = dropdownList.shipList.filter((x) => x.parentValue == parseInt(dropdown_client, 10));
        filterapplied = true;
      }

      if (dropdown_ship != "0") {
        filterList =
          filterapplied
            ? filterList.filter((e) =>
              e.shipId.includes(parseInt(dropdown_ship, 10))
            )
            : userList.filter((e) =>
              e.shipId.includes(parseInt(dropdown_ship, 10))
            );
        filterapplied = true;
      }

      if (dropdown_region != "0") {
        filterList =
          filterapplied
            ? filterList.filter((e) =>
              e.regionId === parseInt(dropdown_region, 10)
            )
            : userList.filter((e) => e.regionId).filter((e) =>
              e.regionId === parseInt(dropdown_region, 10)
            );

        filterapplied = true;
      }

      if (dropdown_employee != "0") {
        filterList =
          filterapplied
            ? filterList.filter((e) => e.userId == dropdown_employee)
            : userList.filter((e) => e.userId == dropdown_employee);
        filterapplied = true;
      }


      if (dropdown_level != "0") {
        filterList =
          filterapplied
            ? filterList.filter((e) => e.levelId == dropdown_level)
            : userList.filter((e) => e.levelId == dropdown_level);
        filterapplied = true;
      }


      if (dropdown_serviceCategory != "0") {
        filterList =
          filterapplied
            ? filterList.filter((e) => e.serviceCategoryId == dropdown_serviceCategory)
            : userList.filter((e) => e.serviceCategoryId == dropdown_serviceCategory);
        filterapplied = true;
      }

    }

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.clientName?.toString().toLowerCase().includes(value) ||
          t.shipName?.toString().toLowerCase().includes(value) ||
          t.regionName?.toString().toLowerCase().includes(value) ||
          t.designation?.toString().toLowerCase().includes(value) ||
          t.emailAddress?.toString().toLowerCase().includes(value) ||
          t.level?.toString().toLowerCase().includes(value) ||
          t.name?.toString().toLowerCase().includes(value) ||
          t.userName?.toString().toLowerCase().includes(value) ||
          t.address?.toString().toLowerCase().includes(value) ||
          t.serviceCategoryName?.toString().toLowerCase().includes(value) ||
          t.createdOn?.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedUsers: filterList,
      shipList: lstFilteredShips,
    });
  };

  /* <summary>
date: 13/02/2023
Name: HV
description: clearing filter
<summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_client: "0",
        dropdown_ship: "0",
        dropdown_region: "0",
        dropdown_employee: "0",
        dropdown_level: "0",
        dropdown_serviceCategory: "0",
        searchedText: ""
      },
      () => {
        this.filterGridView();
      }
    );
  };

  //HV - 24/02/2023 - fixed crashing issue

  onSearch = (e) => {
    //SYJ - fix for https://jiraapp.edotteam.com/browse/IHMM-1158
    var inputValue = e.target.value;
    this.setState({
      searchedText: inputValue,
    }, () => {
      this.filterGridView();
    });

    //SYJ Added below code in FilterGridView for => https://jiraapp.edotteam.com/browse/IHMM-1214
    // var inputValue = e.target.value;
    // var userList = this.state.defaultUsers;

    // if (inputValue.trim() !== "") {
    //   var value = inputValue.trim().toString().toLowerCase();
    //   userList = userList.filter(
    //     (t) =>
    //       t.clientName?.toString().toLowerCase().includes(value) ||
    //       t.shipName?.toString().toLowerCase().includes(value) ||
    //       t.regionName?.toString().toLowerCase().includes(value) ||
    //       t.designation?.toString().toLowerCase().includes(value) ||
    //       t.emailAddress?.toString().toLowerCase().includes(value) ||
    //       t.level?.toString().toLowerCase().includes(value) ||
    //       t.name?.toString().toLowerCase().includes(value) ||
    //       t.userName?.toString().toLowerCase().includes(value) ||
    //       t.address?.toString().toLowerCase().includes(value) ||
    //       t.serviceCategoryName?.toString().toLowerCase().includes(value)
    //   );
    // }
    // this.setState({
    //   mappedUsers: userList,
    // });
  };

  addEditInternalUser = (userId) => {
    if (userId === 0) AddLocalStorageData("selectedUser", "0");
    else {
      var userData = this.state.defaultUsers.filter(
        (s) => s.userId === userId
      )[0];
      AddLocalStorageData("selectedUser", userData.userId);
    }

    this.props.navigate("/AddUser");
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");

    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    }
    else { this.props.navigate("/Dashboard"); }
  };

  render() {
    const {
      mappedUsers,
      defaultUsers,
      colDefs,
      isLoading,
      toastVisible,
      toastResponseType,
      toastMessage,
      dropdown_client,
      dropdown_level,
      dropdown_employee,
      dropdown_region,
      dropdown_serviceCategory,
      dropdown_ship,
      accessList,
      clientList,
      shipList,
      usersList,
      serviceCategoryList,
      regionList,
      userLevel
    } = this.state;

    const Buttons = (
      <div className="col-12"> {/* col-xl-6 btn-top-space */}
        <div className="float-btn float-button-responsive manage-users-main-btns mt-2 manage-client-button">
          <button
            type="button"
            disabled={accessList != null ? accessList.manageUserAccess == UserAction.READ ? true : false : false}
            onClick={() => this.props.navigate("/ManageRolesAndPermission")}
            className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn" >Manage Users Roles & Permissions</button>

          <button
            type="button"
            disabled={accessList != null ? accessList.manageUserAccess == UserAction.READ ? true : false : false}
            onClick={() => this.addEditInternalUser(0)}
            className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive add-user-btn manage-users-btn"
          >
            <img src="images/admin-plus-icon.png" alt="plus-icon" />
            Add Internal User
          </button>
        </div>
      </div>
    );
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ManageUsers"} />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="communications manage-users">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <div className="manage-users-title">
                    {/* <a href="Dashboard"> */}
                    <img
                      className="back-btn-responsive"
                      src="images/back button image.png"
                      height="22px"
                      onClick={this.goback}
                    />
                    {/* </a>{" "} */}
                    <span className="title-font">Manage Users</span>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <Searchbar
                    placeholder={"Employee Name, Designation, etc..."}
                    onChange={this.onSearch}
                  />
                </div>
              </div>
              {/* mobile code */}
              <div className="accordion-filter-dropdown">
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="form-group dropdown-form form-drpdown-responsive manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Client:"
                            name="client"
                            onChange={this.onFilterSelected}
                            selected={dropdown_client}
                          >
                            {clientList == null
                              ? null
                              : clientList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Ship:"
                            name="ship"
                            onChange={this.onFilterSelected}
                            selected={dropdown_ship}
                          >
                            {shipList == null
                              ? null
                              : shipList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Region:"
                            name="region"
                            onChange={this.onFilterSelected}
                            selected={dropdown_region}
                          >
                            {regionList == null
                              ? null
                              : regionList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive">
                          <MobileDropdown
                            dropdownLabel="Employee Name:"
                            name="employee"
                            onChange={this.onFilterSelected}
                            selected={dropdown_employee}
                          >
                            {usersList == null
                              ? null
                              : usersList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive dropdown-class-responsive">
                          <MobileDropdown
                            dropdownLabel="Level:"
                            name="level"
                            onChange={this.onFilterSelected}
                            selected={dropdown_level}
                          >
                            {userLevel == null
                              ? null
                              : userLevel.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive dropdown-class-responsive">
                          <MobileDropdown
                            dropdownLabel="Service Category:"
                            name="serviceCategory"
                            onChange={this.onFilterSelected}
                            selected={dropdown_serviceCategory}
                          >
                            {serviceCategoryList == null
                              ? null
                              : serviceCategoryList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            ;
                          </MobileDropdown>
                        </div>
                        <div className="form-group dropdown-form manage-drpdown-responsive" style={{ alignSelf: 'center' }}>
                          <button
                            type="button"
                            className="btn btn-blue-bg"
                            onClick={this.clearAllFilter}
                            style={{ marginTop: 24 }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Buttons}
              </div>
              {/* mobile code */}
              {/* desktop code */}
              <div className="desktop-class">
                <div className="row mt-row">
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form manage-drpdown-responsive" id={"clientLst"}>
                      <Dropdown
                        dropdownLabel="Client:"
                        name="client"
                        onChange={this.onFilterSelected}
                        selected={dropdown_client}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                      >
                        {clientList == null
                          ? null
                          : clientList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_client > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"clientLst"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {clientList != null && clientList.filter(x => x.value == dropdown_client)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form manage-drpdown-responsive" id={"ShipLst"}>
                      <Dropdown
                        dropdownLabel="Ship:"
                        name="ship"
                        onChange={this.onFilterSelected}
                        selected={dropdown_ship}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                      >
                        {shipList == null
                          ? null
                          : shipList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_ship > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"ShipLst"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {shipList != null && shipList.filter(x => x.value == dropdown_ship)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form manage-drpdown-responsive" id={"regionLst"}>
                      <Dropdown
                        dropdownLabel="Region:"
                        name="region"
                        onChange={this.onFilterSelected}
                        selected={dropdown_region}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                      >
                        {regionList == null
                          ? null
                          : regionList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_region > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"regionLst"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {regionList != null && regionList.filter(x => x.value == dropdown_region)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 client-dropdown-responsive">
                    <div className="form-group dropdown-form manage-drpdown-responsive" id={"empLst"}>
                      <Dropdown
                        dropdownLabel="Employee Name:"
                        name="employee"
                        onChange={this.onFilterSelected}
                        selected={dropdown_employee}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                      >
                        {usersList == null
                          ? null
                          : usersList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_employee > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"empLst"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {usersList != null && usersList.filter(x => x.value == dropdown_employee)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form manage-drpdown-responsive dropdown-class-responsive" id={"userlvl"}>
                      <Dropdown
                        dropdownLabel="Level:"
                        name="level"
                        onChange={this.onFilterSelected}
                        selected={dropdown_level}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                      >
                        {userLevel == null
                          ? null
                          : userLevel.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_level > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"userlvl"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {userLevel != null && userLevel.filter(x => x.value == dropdown_level)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form manage-drpdown-responsive dropdown-class-responsive" id={"sc"}>
                      <Dropdown
                        dropdownLabel="Service Category:"
                        name="serviceCategory"
                        onChange={this.onFilterSelected}
                        selected={dropdown_serviceCategory}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                      >
                        {serviceCategoryList == null
                          ? null
                          : serviceCategoryList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_serviceCategory > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"sc"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {serviceCategoryList != null && serviceCategoryList.filter(x => x.value == dropdown_serviceCategory)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 " style={{ alignSelf: 'center' }}>
                    <button
                      type="button"
                      className="btn btn-blue-bg"
                      onClick={this.clearAllFilter}
                      style={{ marginTop: 24 }}
                    >
                      Reset
                    </button>
                  </div>
                  {Buttons}
                </div>
              </div>

              {/* desktop code */}
              <div className="row table-mobile">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive ">
                    <AGGrid
                      onGridReady={this.onGridReady}
                      rowData={mappedUsers}
                      colDefs={colDefs}
                      rowSelection="single"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default withRouter(ManageUsers);
