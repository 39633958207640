import React, { Component, Fragment } from "react";
import { withRouter } from "../../helpers";
import { isStringEmpty } from "../../helpers/string-helper";

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

class PortRegistryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PortRegistryText: "",
      errorMessages: {},
      showErrorMessage: false,
      portRegistryNameExists: false,
      displayText: props.displayText,
    };
  }

  componentDidMount() {}

  validateFields() {
    const { PortRegistryText, errorMessages, displayText } = this.state;

    let errors = errorMessages;

    errors["errorPortRegistry"] = isStringEmpty(PortRegistryText) ? "true" : "";

    if (errors["errorPortRegistry"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: true,
      });
      return true;
    } else if (PortRegistryText != "") {
      const { originalPortOfRegList } = this.props;

      const portRegistryNameExists =
        originalPortOfRegList.length > 0
          ? originalPortOfRegList
              .map((x) => {
                if (
                  x.label.trim().toLowerCase() ===
                  PortRegistryText.trim().toLowerCase()
                )
                  return x;
              })
              .filter((t) => t != undefined)
          : null;

      if (
        portRegistryNameExists != null &&
        portRegistryNameExists.length != 0
      ) {
        this.setState({
          portRegistryNameExists: true,
        });
        return true;
      }
    }

    this.setState({
      errorMessages,
      showErrorMessage: false,
      portRegistryNameExists: false,
    });
    return false;
  }

  onTextChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (value != "") {
          const { originalPortOfRegList } = this.props;

          const portRegistryNameExists =
            originalPortOfRegList.length > 0
              ? originalPortOfRegList
                  .map((x) => {
                    if (
                      x.label.trim().toLowerCase() ===
                      value.trim().toLowerCase()
                    )
                      return x;
                  })
                  .filter((t) => t != undefined)
              : null;

          if (
            portRegistryNameExists != null &&
            portRegistryNameExists.length != 0
          ) {
            this.setState({
              portRegistryNameExists: true,
            });
            return true;
          } else {
            this.setState({
              portRegistryNameExists: false,
            });
          }
        }

        if (showErrorMessage) this.validateFields();
      }
    );
  };

  onPortRegistrySaveClicked = () => {
    this.validateFields();
    const { onPortRegistrySaveClicked } = this.props;
    const { PortRegistryText } = this.state;

    var hasErrors = this.validateFields();
    if (hasErrors) return;

    const data = { PortRegistryText: PortRegistryText };
    onPortRegistrySaveClicked(data);
  };

  render() {
    const { onCloseClick, showModal } = this.props;

    const {
      PortRegistryText,
      errorMessages,
      showErrorMessage,
      portRegistryNameExists,
    } = this.state;

    return (
      <div className="supplier_on_hold">
        <div
          className="modal-backdrop fade show"
          style={{ display: showModal ? "block" : "none" }}
        ></div>
        <div
          className="modal"
          id="other-status-value-Modal"
          style={{ display: showModal ? "block" : "none" }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-box">
            <div className="modal-content ">
              <div className="modal-header">
                <h4 className="modal-title">Add {this.state.displayText}</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    onClick={onCloseClick}
                  />
                </button>
              </div>
              <div className="row content">
                <div className="col-12 col-md-12 col-lg-5">
                  <label htmlFor="todate">Add {this.state.displayText}:</label>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div
                    className={
                      !isStringEmpty(errorMessages.errorPortRegistry)
                        ? "invalid-position"
                        : ""
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      id="PortRegistryText"
                      name="PortRegistryText"
                      value={PortRegistryText}
                      onChange={this.onTextChange}
                    />

                    {!isStringEmpty(errorMessages.errorPortRegistry) && (
                      <div className="invalid-message-icon">
                        <img
                          src="images\icon material-error.png"
                          alt="invalid"
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="admin-ship-settings-save-btn text-center">
                {portRegistryNameExists && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>{this.state.displayText} already exists.</p>
                  </div>
                )}

                {showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                <button
                  type="button"
                  className="btn btn-background-color save-btn"
                  onClick={this.onPortRegistrySaveClicked}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PortRegistryModal);
