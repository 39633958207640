import React, { Component } from "react";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, withRouter,GetLocalStorageDataWithoutParse } from "../../helpers";
import Logout from "../../helpers/Logout";
import { dashboardservice } from "../../services/dashboard.service";
import StatusRenderer from "../../components/StatusRenderer";
import { UserAction, UserLevel, UserRole } from "../../helpers/enums";
import Footer from "../../components/Footer";
import Dropdown from "../../components/Dropdown";
import { SUPPLIER_SUMMARY, TitlePageNames } from "../../helpers/enums";
import AGGrid from "../../components/AGGrid";
import downloadIcon from "../../assets/images/admin-download.png";
import downloadIconpdf from "../../assets/images/download_pdf.png";
import editIcon from "../../assets/images/profile-feather-edit.png";
import eyeIcon from "../../assets/images/admin-eye.png";
import { helperText } from "../../helpers/helperText";
import ViewModal from "../../components/ViewModal";
import viewIcon from "../../assets/images/Icon awesome-eye.png";
import { UncontrolledTooltip } from "reactstrap";

class SupplierViewItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      summaryClickValue: GetLocalStorageDataWithoutParse("SummaryClickValue"),
      supplierName: "",
      summarycColumnData: [],
      frameworkComponents: {
        tagRenderer: StatusRenderer,
      },
      documentIdPresent: false,
      PoItem: false,
      filePathToView: "",
      showViewModal: false,
      clientNameList: [],
      dropdown_clientName: 0,
      shipNameList: [],
      dropdown_shipName: 0,
      summarydefaultData: [],
    };
  }

  componentDidMount = () => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      changePageTitle(TitlePageNames.SUPPLIERVIEWITEMS);
      if (userData != null) {
        this.setState(
          { userId: userData.id, supplierName: userData.name },
          () => {
            AddLocalStorageData("loggedInUserName", userData.name);
            this.GetSupplierSummaryGridInfo(userData.id);
          }
        );
      }
    }
  };
  /* <summary>
   date: 09/03/2023
   Name:BP
   description:To get supplier summary data
   <summary> */
   GetSupplierSummaryGridInfo = async (supplierId) => {
    this.setState({ isLoading: true });
    var response = await dashboardservice.GetSupplierSummaryGridInfo(
      supplierId,
      this.state.summaryClickValue
    );
    if (response.status == 200 && response.data != null) {
      let supplierDashboardData = response.data;
      var client = response.data.dropdownClientList;
      var ship = response.data.dropdownShipList;
      this.setState({
        summarycColumnData: response.data.supplierSummaryList,
        clientNameList: client,
        shipNameList: ship,
        isLoading: false,
        summarydefaultData: response.data.supplierSummaryList,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
      this.setState({
        isLoading: false,
        clientNameList: [],
        shipNameList: [],
        summarycColumnData: [],
      });
    }
    this.setState({
      isLoading: false,
      documentIdPresent:
        this.state.summaryClickValue == SUPPLIER_SUMMARY.NUMBER_OF_PO_ITEMS ||
          this.state.summaryClickValue ==
          SUPPLIER_SUMMARY.NUMBER_OF_CONFIRMED_HAZMAT ||
          this.state.summaryClickValue ==
          SUPPLIER_SUMMARY.NUMBER_OF_SUSPECTED_HAZMAT
          ? true
          : false,
      PoItem:
        this.state.summaryClickValue == SUPPLIER_SUMMARY.NUMBER_OF_PO_ITEMS
          ? true
          : false,
    });
  };
  navigateTo = (path) => {
    this.props.navigate(path);
  };
  viewDocData = (path) => {
    this.setState({ showViewModal: true, filePathToView: path });
  };
  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
    });
  };
  onViewFileDownload = (filePath) => {
    var lastItem = filePath.split("/").pop();
    var fname = lastItem;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", filePath);
    document.body.appendChild(link);
    link.download = fname;
    link.click();
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  /* <summary>
   date: 09/03/2023
   Name:BP
   description:download ihm documents
   <summary> */
  downloadDocData = async (param) => {
    try {
      if (param.fileMediaID != null) {
        this.setState({
          isLoading: true,
        });
        let FileIDlist = [];

        if (param.fileMediaID != 0) {
          FileIDlist.push(param.fileMediaID);
        }

        if (FileIDlist.length == 0) {
          this.setState({
            toastVisible: true,
            isLoading: false,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.noDocumentsSelected,
          });
          return;
        }
        const response = await dashboardservice.DownloadPOSummaryDoc(
          FileIDlist
        );


        if (response.status == 200) {
          response.data.forEach((element) => {
            if (element != null) {
              var fname = "";
              var lastItem = element.split("/").pop();
              fname = lastItem;

              const link = document.createElement("a");
              // link.href = element;
              link.setAttribute("href", element);
              link.setAttribute("download", element);

              var ext = element.substring(element.lastIndexOf(".") + 1);
              if (ext == "pdf" || ext == "PDF") {
                link.setAttribute("target", "_blank");
              } else {
                link.setAttribute("target", "");
              }
              link.download = fname;
              document.body.appendChild(link);

              link.click();
            }
          });
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastSuccess,
            toastMessage: helperText.global.downloadSuccess,
          });
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
          });
        }

        this.setState({
          isLoading: false,
        });
      } else {
        AddLocalStorageData("location", "SupplierViewItems");
        AddLocalStorageData("mdsdocId", param.mdsdocId);
        AddLocalStorageData("disableMdsdocFormFields", true);
        this.props.navigate(`/MDSDoC`);
      }
    } catch (error) { }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  gridColumns = (documentIdPresent, PoItem) => {
    return [
      {
        headerName: "",
        field: "",
        width: 80,
        cellRenderer: (params) => (
          <StatusRenderer
            path={
              params.data.documentStatusID === undefined ||
                params.data.documentStatusID === null
                ? ""
                : params.data.documentStatusID
            }
          />
        ),
        sortable: false,
        filter: false,
        hide: documentIdPresent,
      },
      {
        headerName: "Serial Number",
        // field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "PO Number",
        field: "poNumber",
      },
      {
        headerName: "Description",
        field: "description",
        tooltipField: "description",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Client",
        field: "clientName",
        width: 300,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Ship",
        field: "shipName",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Hazmat Status",
        field: "hazmatStatus",
        hide: PoItem,
      },
      {
        headerName: "Date of Creation",
        field: "createdOn",
      },
      {
        headerName: "MD/SDoC Status",
        field: "documentStatusvalue",
        //"documentStatusvalue",
        // "mDSDoCStatus",
        // cellRenderer: (params) => (
        //   <div>
        //     {params.data.documentStatusID == null
        //       ? ""
        //       : params.data.documentStatusID == 1
        //       ? "Pending"
        //       : params.data.documentStatusID == 3
        //       ? "Resent"
        //       : ""}
        //   </div>
        // ),
        hide: documentIdPresent,
      },
      {
        headerName: "",
        field: "",
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="download-cursor-class"
            src={
              params.data.fileType == null || params.data.fileType == ""
                ? ""
                : params.data.fileType == "Filled MDSDoC"
                  ? viewIcon
                  : params.data.fileType == "pdf" || params.data.fileType == "PDF"
                    ? downloadIconpdf
                    : downloadIcon
            }
            style={{ cursor: 'pointer', width: "24px"}}
            onClick={() => this.downloadDocData(params.data)}
          />
        ),
        cellStyle: {textAlign:"center" , paddingInline: "0"}
      },
      // {
      //   headerName: "Add/Edit MD/SDoC",
      //   field: "",
      //   pinned: "right",
      //   filter: false,
      //   sortable: false,
      //   unSortIcon: false,
      //   width: this.state.summaryClickValue == SUPPLIER_SUMMARY.PENDING_MD_SDOC_WITH_CTI ? 0 : 125,
      //   hide: PoItem,
      //   cellRenderer: (params) => (
      //     <img
      //       src={
      //         params.data.isEditable == null
      //           ? ""
      //           : params.data.isEditable == 1
      //           ? editIcon
      //           : params.data.isEditable == 2
      //           ? eyeIcon
      //           : ""
      //       }
      //       width="20px"
      //       onClick={() =>
      //         params.data.isEditable == null
      //           ? null
      //           : params.data.isEditable == 1
      //           ? null
      //           : params.data.isEditable == 2
      //           ? this.viewDocData(params.data.filepath)
      //           : null
      //       }
      //     />
      //   ),
      // },
    ];
  };
  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };
  filterGridView = () => {
    const { dropdown_clientName, dropdown_shipName } = this.state;

    var filterList = [];
    var DocList = this.state.summarydefaultData;

    if (dropdown_clientName == 0 && dropdown_shipName == 0)
      filterList = this.state.summarydefaultData;
    else {
      if (dropdown_clientName != "" && dropdown_clientName != 0)
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.clientID == parseInt(dropdown_clientName, 10)
            )
            : DocList.filter(
              (x) => x.clientID == parseInt(dropdown_clientName, 10)
            );

      if (dropdown_shipName != "" && dropdown_shipName != "0")
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.shipID == parseInt(dropdown_shipName, 10)
            )
            : DocList.filter((x) => x.shipID == dropdown_shipName);
    }
    this.setState({
      summarycColumnData: filterList,
    });
  };
  clearAllFilter = () => {
    this.setState(
      {
        dropdown_clientName: "0",
        dropdown_shipName: "0",
      },
      () => {
        this.filterGridView();
      }
    );
  };

  render() {
    const {
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      accessList,
      dropdown_ship,
      shipList,
      totalClientPOItems,
      totalClientShips,
      totalClientUsers,
      summaryClickValue,
      summarycColumnData,
      clientNameList,
      dropdown_clientName,
      shipNameList,
      dropdown_shipName,
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {this.state.showViewModal && (
          <ViewModal
            showViewModal={this.state.showViewModal}
            modalheader="View Client File"
            filePath={this.state.filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() =>
              this.onViewFileDownload(this.state.filePathToView)
            }
          />
        )}
        <main className="page-height">
          <section className="communications po-items-list">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 heading-bottom-space">
                  <a onClick={() => this.navigateTo("/SupplierDashboard")}>
                    <img
                      className="back-btn-responsive manage-client-back-button"
                      src="images/back button image.png"
                      alt="Back"
                    />
                  </a>{" "}
                  <span className="title-font">
                    {summaryClickValue == SUPPLIER_SUMMARY.NUMBER_OF_PO_ITEMS
                      ? "Number of PO Items"
                      : summaryClickValue == SUPPLIER_SUMMARY.PENDING_MD_SDoC
                        ? "Pending MD/SDoC with Supplier"
                        : summaryClickValue ==
                          SUPPLIER_SUMMARY.NUMBER_OF_SUSPECTED_HAZMAT
                          ? "Number of Suspected Hazmat"
                          : summaryClickValue ==
                            SUPPLIER_SUMMARY.NUMBER_OF_CONFIRMED_HAZMAT
                            ? "Number of Confirmed Hazmat"
                            : summaryClickValue ==
                              SUPPLIER_SUMMARY.PENDING_MD_SDOC_WITH_CTI
                              ? "Pending MD/SDoC with CTI"
                              : ""}
                  </span>
                </div>
              </div>
              <div className="desktop-class">
                <div className="row mt-row">
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"clientName"}>
                      <Dropdown
                        dropdownLabel="Client Name:"
                        name="clientName"
                        onChange={this.onFilterSelected}
                        selected={dropdown_clientName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {clientNameList == null
                          ? null
                          : clientNameList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_clientName > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"clientName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {clientNameList != null && clientNameList.filter(x => x.value == dropdown_clientName)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"shipName"}>
                      <Dropdown
                        dropdownLabel="Ship Name:"
                        name="shipName"
                        onChange={this.onFilterSelected}
                        selected={dropdown_shipName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {shipNameList == null
                          ? null
                          : shipNameList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_shipName > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"shipName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="right"
                          style={{ width: "350px" }}
                        >
                          {shipNameList != null && shipNameList.filter(x => x.value == dropdown_shipName)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 "
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      type="button"
                      className="btn btn-blue-bg"
                      onClick={this.clearAllFilter}
                      style={{ marginTop: "27px" }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      rowData={summarycColumnData}
                      colDefs={this.gridColumns(
                        this.state.documentIdPresent,
                        this.state.PoItem
                      )}
                      rowSelection="single"
                      pagination="10"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <br />
        <br />
        <Footer />
      </>
    );
  }
}

export default withRouter(SupplierViewItems);
