import React, { useEffect, useState } from "react";
import { settingsService } from "../../services/settings.service";
import { GetLocalStorageDataWithParse, helperText } from "../../helpers";

const EmailSettings = ({ SettingsData, ToggleLoader, ShowToastModal }) => {
  const [emailSettingsData, setEmailSettingsData] = useState([]);
  const [numberOfAutomatedReminder, setNumberOfAutomatedReminder] = useState(0);
  const [reminderFrequency, setReminderFrequency] = useState([]);
  const [expiryReminderFrequency, setExpiryReminderFrequency] = useState([]);
  const [useID, setUseID] = useState(0);

  useEffect(() => {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (SettingsData) {
      var { emailSettings, emailFrequency } = SettingsData;
      setReminderFrequency(emailFrequency);
      setExpiryReminderFrequency(
        emailFrequency.slice(1)
      );
      setEmailSettingsData(
        emailSettings.map((t) => {
          return {
            ...t,
            emailReminderFrequencyID:
              t.emailReminderFrequencyID == 0 ? 1 : t.emailReminderFrequencyID,
          };
        })
      );
      setNumberOfAutomatedReminder(
        emailSettings[emailSettings.length - 1].numberOfAutomatedReminder
      );
    }
    setUseID(userData ? userData.id : 0);
    return () => { };
  }, [SettingsData]);

  const OnReminderFrequencyChange = (e, index) => {
    var { value } = e.target;
    //index check because of null value 
    index = index >= 5 ? (index + 1) : index;
    setEmailSettingsData((prevState) =>
      prevState.map((item, itemIndex) => {
        if (itemIndex == index) {
          return {
            ...item,
            emailReminderFrequencyID: parseInt(value, 10),
            editedBy: useID,
          };
        } else return item;
      })
    );
  };

  const OnEmailReminderStatusChange = (e, index) => {
    var { checked } = e.target;
    index = index >= 5 ? (index + 1) : index;
    setEmailSettingsData((prevState) =>
      prevState.map((item, itemIndex) => {
        if (itemIndex == index) {
          return { ...item, emailReminderStatus: checked, editedBy: useID };
        } else return item;
      })
    );
  };
  const OnEmailReminderValueChange = (e, index) => {
    var { value } = e.target;
    index = index >= 5 ? (index + 1) : index;
    setEmailSettingsData((prevState) =>
      prevState.map((item, itemIndex) => {
        if (itemIndex == index) {
          return {
            ...item,
            emailReminderValue: parseInt(value, 10),
            editedBy: useID,
          };
        } else return item;
      })
    );
  };

  const OnAutomatedReminderChange = (e) => {
    var { value } = e.target;
    setNumberOfAutomatedReminder(value);
    var _emailSettingsData = emailSettingsData;
    _emailSettingsData[emailSettingsData.length - 1].numberOfAutomatedReminder =
      value;
    _emailSettingsData[emailSettingsData.length - 1].editedBy = useID;
    setEmailSettingsData(_emailSettingsData);
  };
  const SaveEmailSettings = async () => {
    ToggleLoader();
    var response = await settingsService.UpdateEmailSettings(emailSettingsData);
    ToggleLoader();
    if (response.status == 200) {
      ShowToastModal(
        helperText.SettingsPageText.EmailReminderUpdateSuccess,
        helperText.global.toastSuccess
      );
    } else {
      ShowToastModal(
        helperText.global.somethingWentWrong,
        helperText.global.toastError
      );
    }
  };
  return (
    <div id="Email-settings" className="tabcontent">
      <div className="tabcontent-data">
        <h5 className="heading">Automated Email Generation</h5>
        <p className="heading-automated">
          Emails will be sent as per the frequency specified below.
        </p>
        <div className="table-responsive-xxl">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th />
                <th>
                  <h5 className="reminder">Reminder Type</h5>
                </th>
                <th>
                  <h5>Reminder Frequency</h5>
                </th>
              </tr>
            </thead>
            <tbody className="email-setting-body">
              {emailSettingsData.filter((x) => x.emailReminderType != "").map((item, index) => {
                return index == emailSettingsData.length - 1 ? (
                  <></>
                ) : (
                  <tr>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={item.emailReminderStatus}
                          onChange={(e) =>
                            OnEmailReminderStatusChange(e, index)
                          }
                        />
                        <span className="slider round" />
                      </label>
                    </td>
                    <td>
                      <p>{item.emailReminderType}</p>
                    </td>
                    <td>
                      <div className="email-setting-input">
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          value={item.emailReminderValue}
                          onChange={(e) => OnEmailReminderValueChange(e, index)}
                        />
                        {item.emailReminderType.includes('Expiry') ?
                          (<select
                              className="form-select"
                              aria-label="form-select example"
                              value={item.emailReminderFrequencyID}
                              onChange={(e) => OnReminderFrequencyChange(e, index)}
                            >
                              {expiryReminderFrequency.map((rItem) => {
                                return (
                                  <option key={rItem.id} value={rItem.id}>
                                    {item.emailReminderValue > 1
                                      ? `${rItem.name}`
                                      : rItem.name.substring(
                                        0,
                                        rItem.name.length - 1
                                      )}
                                  </option>
                                );
                              })}
                            </select>)
                          : (<select
                            className="form-select"
                            aria-label="form-select example"
                            value={item.emailReminderFrequencyID}
                            onChange={(e) => OnReminderFrequencyChange(e, index)}
                          >
                            {reminderFrequency.map((rItem) => {
                              return (
                                <option key={rItem.id} value={rItem.id}>
                                  {item.emailReminderValue > 1
                                    ? `${rItem.name}`
                                    : rItem.name.substring(
                                      0,
                                      rItem.name.length - 1
                                    )}
                                </option>
                              );
                            })}
                          </select>)
                        }
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="tabcontent-data-automated tabcontent-data mt-3">
        <div className="table table-responsive-xxl">
          <table>
            <tbody>
              <tr>
                <td className="tabcontent-data-width">
                  <h5 className="heading">
                    Number of Automated Reminder for MD/SDoC:
                  </h5>
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    value={numberOfAutomatedReminder}
                    onChange={OnAutomatedReminderChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="admin-ship-settings-save-btn settings-save-btn">
        <button
          className="btn btn-block"
          type="button"
          onClick={SaveEmailSettings}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EmailSettings;
