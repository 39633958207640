import React, { Component } from "react";
import DatePicker from "../../components/DatePickerComponent";
import Dropdown from "../../components/Dropdown";
import {
  generateRandomString,
  helperText,
  isEmptyObject,
  isValidFile,
} from "../../helpers";
import { isStringEmpty } from "../../helpers/string-helper";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import ErrorElementView from "../../components/ErrorElementView";
import { ErrorViewFor } from "../../helpers/enums";
import Toast from "../../components/Toast";

const validFileFormatsReadable = "application/pdf";

const validFileFormats = ["xlsx,xls,pdf,PDF"];
const defaultAllItem = { label: "All", value: "0" };
var currentDate = new Date();

const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon-modal"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

export default class TTMDSDoCUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docID: 0,
      fileInputKey: "",
      filePath: "",
      validateError: false,
      fileName: "",
      errorMessages: {},
      TDataList: [],
      unreadableErrorMessages: [],
      dropdown_region: 0,
      dropdown_client: 0,
      dropdown_ship: 0,
      dropdown_documentType: 0,
      sdocNumber: "",
      mdNumber: "",
      dropdown_supplierName: this.props.selectedPOUpload.length > 0 ? this.props.selectedPOUpload[0].supplierName : "",
      division: this.props.selectedPOUpload.length > 0 ? this.props.selectedPOUpload[0].supplierDivision : "",
      makerName: this.props.selectedPOUpload.length > 0 ? this.props.selectedPOUpload[0].partMakerName : "",
      objDeclaration: "",
      productName: this.props.selectedPOUpload.length > 0 ? this.props.selectedPOUpload[0].description : "",
      remarks: "",
      dateOfSDoCIssue: "",
      dateOfMDIssue: "",
      dropdownList: this.props.dropdownList,
      dropdownList_applicableToList_Filtered: [],
      TrackingToolId: this.props.selectedPOUpload.length > 0 ? this.props.selectedPOUpload[0].trackingToolId : "",
      SupplierId: this.props.selectedPOUpload.length > 0 ? this.props.selectedPOUpload[0].supplierId : "",
      toastVisible: false,
      toastMessage: "",
      toastType: ""
    };
  }

  componentDidMount() {
  }

  onUploadClick = () => {
    var {
      mdNumber,
      sdocNumber,
      dateOfMDIssue,
      dateOfSDoCIssue,
      dropdown_supplierName,
      division,
      makerName,
      productName,
      objDeclaration,
      remarks,
      dropdown_status,
      TFile,
      TrackingToolId,
      SupplierId
    } = this.state;
    const { onUploadClick } = this.props;
    var hasErrors = this.validateFields();

    if (hasErrors) return;

    const data = {
      mdNumber: mdNumber,
      sdocNumber: sdocNumber,
      dateOfMDIssue: dateOfMDIssue,
      dateOfSDoCIssue: dateOfSDoCIssue,
      dropdown_supplierName: dropdown_supplierName,
      division: division,
      makerName: makerName,
      productName: productName,
      objDeclaration: objDeclaration,
      remarks: remarks,
      dropdown_status: dropdown_status,
      TFile: TFile,
      TrackingToolId: TrackingToolId,
      SupplierId: SupplierId
    };
    onUploadClick(data);
  };

  validateFields() {
    var {
      mdNumber,
      sdocNumber,
      dateOfMDIssue,
      dateOfSDoCIssue,
      dropdown_supplierName,
      division,
      makerName,
      productName,
      objDeclaration,
      remarks,
      dropdown_status,
      errorMessages,
      TFile
    } = this.state;
    var hasErrors = false;
    let errors = errorMessages;
    errors["errorMDNumber"] =
      mdNumber == undefined || mdNumber == "" ? "true" : "";
    errors["errorSDoCNumber"] =
      sdocNumber == undefined || sdocNumber == "" ? "true" : "";
    errors["errorMDIssueDate"] =
      dateOfMDIssue == undefined || dateOfMDIssue == "" ? "true" : "";
    errors["errorSDoCIssueDate"] =
      dateOfSDoCIssue == undefined || dateOfSDoCIssue == "" ? "true" : "";
    // errors["errorSupplierName"] =
    //   dropdown_supplierName == undefined || dropdown_supplierName == ""
    //     ? "true"
    //     : "";
    // errors["errorDivision"] =
    //   division == undefined || division == "" ? "true" : "";
    // errors["errorMakerName"] =
    //   makerName == undefined || makerName == "" ? "true" : "";
    // errors["errorProductName"] =
    //   productName == undefined || productName == "" ? "true" : "";
    // errors["errorObjDeclaration"] =
    //   objDeclaration == undefined || objDeclaration == "" ? "true" : "";
    // errors["errorRemark"] = remarks == undefined || remarks == "" ? "true" : "";
    //errors["errorStatus"] =  dropdown_status == undefined || dropdown_status == "" ? "true" : "";
    errors["errorTFile"] = TFile == null || TFile == undefined ? "true" : "";
    if (
      errors["errorMDNumber"] != "" ||
      errors["errorSDoCNumber"] != "" ||
      errors["errorMDIssueDate"] != "" ||
      errors["errorSDoCIssueDate"] != "" ||
      // errors["errorSupplierName"] != "" ||
      // errors["errorDivision"] != "" ||
      // errors["errorMakerName"] != "" ||
      // errors["errorProductName"] != "" ||
      // errors["errorObjDeclaration"] != "" ||
      // errors["errorRemark"] != "" ||
      errors["errorTFile"] != ""
      //||   errors["errorStatus"] != ""
    ) {
      hasErrors = true;
    }

    this.setState({
      validateError: hasErrors,
      errorMessages: errors,
    });
    return hasErrors;
  }

  onFileUpload = async (e) => {
    const { name, value } = e.target;
    const { validateError } = this.state;
    if (value != "") {
      if (!isValidFile(value, validFileFormats)) {
        if (this.state.filePath == "") {
          this.setState({
            fileInputKey: generateRandomString,
            [name]: null,
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.TrackingTool.wrongPOFileTpeError,
          });
        }
        return;
      }

      let files = e.target.files[0];
      this.setState({
        [name]: files,
        fileName: files.name,
        filePath: value,
      }, () => {
        if (validateError)
          this.validateFields();
      });
    }
  };

  onDropdownStatusChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (this.state.validateError)
      this.validateFields();
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.validateError)
          this.validateFields();
      }
    );
  };

  onDateChanged = (name, e) => {

    this.setState({ [name]: e }, () => {
      if (this.state.validateError)
        this.validateFields();
    });

  };

  closeToast = () => {
    this.setState({ toastVisible: false, toastMessage: "", toastType: "" });
  };

  render() {
    const { onCloseClick, onUploadClick, dropdownList, selectedPOUpload } = this.props;


    const {
      fileInputKey,
      validateError,
      fileName,
      errorMessages,
      sdocNumber,
      mdNumber,
      dropdown_supplierName,
      division,
      makerName,
      objDeclaration,
      productName,
      remarks,
      dateOfMDIssue,
      dateOfSDoCIssue,
      toastVisible,
      toastMessage,
      toastType,
    } = this.state;

    return (
      <div className="Training_Evalution" style={{ display: "block" }}>
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div
          className="modal"
          id="uploadMDSDoCDocument"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-box modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload MD / SDoC</h4>
                <button type="button" data-bs-dismiss="modal">
                  <img
                    src="images/modal-close.png"
                    alt="modal-close"
                    style={{ pointerEvents: "all" }}
                    onClick={() => onCloseClick("showUploadModal", false)}
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="PO-list-topLabel">
                  <div className="row top">
                    <h6 className="template-title">
                      <span>
                        Upload MD/SDoC for all the selected Hazmat PO items
                      </span>{" "}
                    </h6>
                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown required-field">
                        MD Number:
                      </label>

                      <div
                        className={
                          !isStringEmpty(errorMessages.errorMDNumber)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <input
                          autoFocus={false}
                          autoComplete={"off"}
                          className="form-control"
                          type="text"
                          id="mdNumber"
                          name="mdNumber"
                          value={mdNumber}
                          onChange={this.onTextChange}
                        />
                        {!isStringEmpty(errorMessages.errorMDNumber) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown required-field">
                        SDoC Number:
                      </label>

                      <div
                        className={
                          !isStringEmpty(errorMessages.errorSDoCNumber)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <input
                          autoFocus={false}
                          autoComplete={"off"}
                          className="form-control"
                          type="text"
                          id="sdocNumber"
                          name="sdocNumber"
                          value={sdocNumber}
                          onChange={this.onTextChange}
                        />
                        {!isStringEmpty(errorMessages.errorSDoCNumber) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown required-field">
                        MD Issue Date:
                      </label>

                      <div
                        className={
                          !isStringEmpty(errorMessages.errorMDIssueDate)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <DatePicker
                          selectedDate={dateOfMDIssue}
                          name="dateOfMDIssue"
                          onDateChange={(e) =>
                            this.onDateChanged("dateOfMDIssue", e)
                          }
                          className="form-control"
                          title="dateOfMDIssue"
                          placeholder=""
                          isNotRoot={true}
                        >
                          {" "}
                        </DatePicker>

                        {!isStringEmpty(errorMessages.errorMDIssueDate) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown required-field">
                        SDoC Issue Date:
                      </label>

                      <div
                        className={
                          !isStringEmpty(errorMessages.errorSDoCIssueDate)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <DatePicker
                          selectedDate={dateOfSDoCIssue}
                          name="dateOfSDoCIssue"
                          onDateChange={(e) =>
                            this.onDateChanged("dateOfSDoCIssue", e)
                          }
                          className="form-control"
                          title="dateOfSDoCIssue"
                          placeholder=""
                          isNotRoot={true}
                        >
                          {" "}
                        </DatePicker>

                        {!isStringEmpty(errorMessages.errorSDoCIssueDate) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown">
                        Supplier Name:
                      </label>

                      <input className="form-control"
                        type="text"
                        name="supplierName"
                        value={dropdown_supplierName}
                        disabled
                      />

                      {/* <div
                        className={
                          !isStringEmpty(errorMessages.errorSupplierName)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <Dropdown
                          dropdownLabel="Supplier Name:"
                          name="supplierName"
                          onChange={this.onDropdownStatusChange}
                          selected={dropdown_supplierName}
                          style={{textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden'}}
                        >
                          {dropdownList.supplierList == null
                            ? null
                            : dropdownList.supplierList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                          ;
                        </Dropdown>
                        {!isStringEmpty(errorMessages.errorSupplierName) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                            }
                          />
                        )}
                      </div> */}
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown">
                        Division:
                      </label>

                      {/* <div
                        className={
                          !isStringEmpty(errorMessages.errorDivision)
                            ? "invalid-position"
                            : ""
                        }
                      > */}
                      <input
                        autoFocus={false}
                        autoComplete={"off"}
                        className="form-control"
                        type="text"
                        id="division"
                        name="division"
                        value={division}
                        onChange={this.onTextChange}
                        disabled
                      />
                      {/* {!isStringEmpty(errorMessages.errorDivision) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div> */}
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown">
                        Maker Name:
                      </label>

                      {/* <div
                        className={
                          !isStringEmpty(errorMessages.errorMakerName)
                            ? "invalid-position"
                            : ""
                        }
                      > */}
                      <input
                        autoFocus={false}
                        autoComplete={"off"}
                        className="form-control"
                        type="text"
                        id="makerName"
                        name="makerName"
                        value={makerName}
                        onChange={this.onTextChange}
                      />
                      {/* {!isStringEmpty(errorMessages.errorMakerName) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )} */}
                      {/* </div> */}
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown">
                        Product Name:
                      </label>

                      {/* <div
                        className={
                          !isStringEmpty(errorMessages.errorProductName)
                            ? "invalid-position"
                            : ""
                        }
                      > */}
                      <input
                        autoFocus={false}
                        autoComplete={"off"}
                        className="form-control"
                        type="text"
                        id="productName"
                        name="productName"
                        value={productName}
                        onChange={this.onTextChange}
                      />
                      {/* {!isStringEmpty(errorMessages.errorProductName) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div> */}
                    </div>

                    {/* <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <div
                        className={
                          !isStringEmpty(errorMessages.errorStatus)
                            ? "invalid-position"
                            : ""
                        }
                      >
                        <Dropdown
                          dropdownLabel="Hazmat Status:"
                          name="status"
                          onChange={this.onDropdownStatusChange}
                          style={{textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden'}}
                        >
                          {dropdownList.ttStatusList == null
                            ? null
                            : dropdownList.ttStatusList.map((obj, key) => (
                                <option key={key} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                          ;
                        </Dropdown>
                        {!isStringEmpty(errorMessages.errorStatus) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.DEFAULT_INVALID_DROPDOWN_ICON
                            }
                          />
                        )}
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile required-field">
                        Object of Declaration:
                      </label>

                      {/* <div
                        className={
                          !isStringEmpty(errorMessages.errorObjDeclaration)
                            ? "invalid-position"
                            : ""
                        }
                      > */}
                      <input
                        autoFocus={false}
                        autoComplete={"off"}
                        className="form-control"
                        type="text"
                        id="objDeclaration"
                        name="objDeclaration"
                        value={objDeclaration}
                        onChange={this.onTextChange}
                      />
                      {/* {!isStringEmpty(errorMessages.errorObjDeclaration) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div> */}
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 data">
                      <label className="label-class label-mobile label-dropdown"> 
                        Remarks:
                      </label>

                      {/* <div
                        className={
                          !isStringEmpty(errorMessages.errorRemark)
                            ? "invalid-position"
                            : ""
                        }
                      > */}
                      <input
                        autoFocus={false}
                        autoComplete={"off"}
                        className="form-control"
                        type="text"
                        id="remarks"
                        name="remarks"
                        value={remarks}
                        onChange={this.onTextChange}
                      />
                      {/* {!isStringEmpty(errorMessages.errorRemark) && (
                          <ErrorElementView
                            errorFor={
                              ErrorViewFor.INVALID_MESSAGE_ICON
                            }
                          />
                        )}
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="choosefile-btn">
                          <label
                            htmlFor="myfile"
                            className={
                              "attach-btn attach-btn-responsive chs-btn " +
                              (!isStringEmpty(errorMessages.errorTFile)
                                ? "invalid-position-border"
                                : "")
                            }
                          >
                            <img
                              className="upload-img"
                              src="images/Icon awesome-upload.png"
                              width="18px"
                            />
                            &nbsp;Choose File
                          </label>

                          {!isStringEmpty(errorMessages.errorTFile) && (
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                              className="invalid-position-img"
                            />
                          )}
                          <label className="required-field">  &nbsp; (Please select a PDF file)</label>
                          <input
                            type="file"
                            accept={validFileFormatsReadable}
                            id="myfile"
                            name="TFile"
                            onChange={this.onFileUpload}
                            key={fileInputKey || ""}
                            style={{ display: "none", visibility: "none" }}
                          />
                          <label className="label-breakword">&nbsp;&nbsp;&nbsp;{fileName}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {validateError && (
                    <div className="invalid-message-icon-box">
                      <img
                        src="images/icon material-error.png"
                        alt="invalid-icon"
                      />
                      <p>Please fill in all the mandatory fields.</p>
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-background-color upload-btn"
                      onClick={this.onUploadClick}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
