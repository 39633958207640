import React, { Component } from "react";
import * as html2pdf from "html2pdf.js";
import Dropdown from "../../components/Dropdown";
import { SupplierService } from "../../services/supplier.service";
import MDSDoC from "../MDSDoC/MDSDoC";
import admindownload from "../../assets/images/admin-download.png";
import supplierdownload from "../../assets/images/supplier-document.png";
import supplierdownloadpdf from "../../assets/images/supplier-document-pdf.png";
import supplierback from "../../assets/images/supplier-back.png";
import editIcon from "../../assets/images/Icon feather-edit.png";
import magnifyingglass from "../../assets/images/magnifying-glass.png";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import Toast from "../../components/Toast";
import ViewModal from "../../components/ViewModal";
import { UncontrolledTooltip } from "reactstrap";
import {
  DOCUMENT_STATUS,
  TitlePageNames,
} from "../../helpers/enums";
import SupplierDocumentModal from "./SupplierDocumentModal";

class SupplierDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedSupplierDocs: [],
      defaultSupplierDocs: [],
      dropdownList: [],
      userId: 0,
      colDefs: this.gridColumns,
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      showUploadModal: false,
      filePathToView: "",
      showViewModal: false,
      trainingDocIDList: [],
      dropdown_client: "0",
      dropdown_ship: "0",
      lstShips: [],
      searchedText: "",
      rowIdToDelete: 0,
      accessList: null,
      poNumberList: [],
      dropdown_poNoFilter: "",
      docTypeList: [],
      dropdown_docTypeFilter: "",
      isHTMLtoPDFDownload: false,
      selectedmdsdocId: 0,
      showDocumentUploadModal: false,
      uploadMDSDocClicked: false,
      onFillDigitalMDSDocClicked: false,
      onDeclarationSelected: false,
      supplierId: 0,
      supplierName: "",
      typeId: 0,
    };
  }

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.SUPPLIERDOCUMENTS);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      //DP - 17/03/2023 - passed supplier id and name
      this.setState({
        typeId: userData.typeId,
        userId: userData.id,
        accessList: GetLocalStorageDataWithParse("userRolePermission"),
        supplierId: userData.id,
        supplierName: userData.name,
      });
      this.GetSupplierDocumentData();
    }
  }

  viewTrainingDocData = (id) => {
    var filterList = [];
    filterList = this.state.defaultSupplierDocs.filter(
      (x) => x.miscellaneousOperationsId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;

    this.setState({ showViewModal: true, filePathToView: path });
  };

  GetSupplierDocumentData = () => {
    this.setState({ isLoading: true }, async () => {
      const { userId } = this.state;
      const response = await SupplierService.GetSupplierDocumentData(userId);

      if (response != null && response != undefined && response.status == 200) {
        var { supplierDocumentData, supplierDocumentDropdownList } =
          response.data;

        let uniquePONumberList = Array.from([
          ...new Set(
            supplierDocumentData
              .map((l) => l.poNumber)
              .filter((x) => x != undefined && x != "")
          ),
        ]);

        let uniqueDocTypeList = Array.from([
          ...new Set(
            supplierDocumentData
              .map((l) => l.documentType)
              .filter((x) => x != undefined)
          ),
        ]);

        this.setState({
          mappedSupplierDocs: supplierDocumentData,
          defaultSupplierDocs: supplierDocumentData,
          dropdownList: supplierDocumentDropdownList,
          lstShips: supplierDocumentDropdownList.shipList,
          isLoading: false,
          poNumberList: uniquePONumberList,
          docTypeList: uniqueDocTypeList,
        },
        () => {
          this.filterGridView();
        });

      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          isLoading: false,
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
          poNumberList: [],
          docTypeList: [],
        });
      }
    });
  };

  onFilterSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "dropdown_client") {
          this.setState({ dropdown_ship: "0" }, () => this.filterGridView());
        } else this.filterGridView();
      }
    );
  };

  filterGridView = () => {
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_poNoFilter,
      dropdown_docTypeFilter,
      defaultSupplierDocs,
      dropdownList,
      searchedText,
    } = this.state;

    var filterList = [];
    var lstFilteredShips = [];
    var tempSupplierDocList = defaultSupplierDocs;

    if (
      dropdown_client == "0" &&
      dropdown_ship == "0" &&
      (dropdown_poNoFilter == "0" || dropdown_poNoFilter == "") &&
      (dropdown_docTypeFilter == "0" || dropdown_docTypeFilter == "")
    ) {
      filterList = defaultSupplierDocs;
      lstFilteredShips = dropdownList.shipList;
    } else {
      var isClientFilterAdded = false;
      var filteradded = false;
      if (dropdown_client != "0") {
        filterList = tempSupplierDocList.filter(
          (x) => x.clientId == parseInt(dropdown_client, 10)
        );

        lstFilteredShips = dropdownList.shipList.filter(
          (x) => x.parentValue === parseInt(dropdown_client, 10)
        );

        isClientFilterAdded = true;
        filteradded = true;
      } else {
        lstFilteredShips = dropdownList.shipList;
      }

      if (dropdown_ship != "0") {
        filterList = isClientFilterAdded
          ? filterList.filter((x) => x.shipId == parseInt(dropdown_ship, 10))
          : tempSupplierDocList.filter(
            (x) => x.shipId == parseInt(dropdown_ship, 10)
          );
        filteradded = true;
      }

      if (dropdown_poNoFilter != "" && dropdown_poNoFilter != "0") {
        filterList = filteradded
          ? filterList.filter((e) => e.poNumber == dropdown_poNoFilter)
          : tempSupplierDocList.filter(
            (e) => e.poNumber == dropdown_poNoFilter
          );
        filteradded = true;
      }

      if (dropdown_docTypeFilter != "" && dropdown_docTypeFilter != "0") {
        filterList = filteradded
          ? filterList.filter((e) => e.documentType == dropdown_docTypeFilter)
          : tempSupplierDocList.filter(
            (e) => e.documentType == dropdown_docTypeFilter
          );
        filteradded = true;
      }
    }

    if (searchedText == "") {
    } else {
      let value = searchedText.toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.documentType?.toString().toLowerCase().includes(value) ||
          t.poNumber?.toString().toLowerCase().includes(value) ||
          t.client?.toString().toLowerCase().includes(value) ||
          t.ship?.toString().toLowerCase().includes(value) ||
          t.description?.toString().toLowerCase().includes(value)
      );
    }
    this.setState({
      mappedSupplierDocs: filterList,
      lstShips: lstFilteredShips,
    });
  };

  onSearch = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        searchedText: value,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  toggleModal = (name, value) => {
    this.setState({ showUploadModal: false });
  };

  
  onModalCloseClick = () => {
    this.setState({ showViewModal: false });
  };

  viewPOFile = (filePath) => {
    this.setState({
      filePathToView: filePath,
      showViewModal: true,
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
  date: 07-02-2023
  Name: GM
  description: Fixed selected item id binding issue
  <summary>*/
  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = selectedRows.map((t) => {
        return t.miscellaneousOperationsId;
      });
      this.setState({
        trainingDocIDList: listSelectedTTId,
      });
    } else {
      this.setState({
        trainingDocIDList: [],
      });
    }
  };

  onViewFileDownload = (filePath) => {
    var lastItem = filePath.split("/").pop();
    var fname = lastItem;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", filePath);
    document.body.appendChild(link);
    link.download = fname;
    link.click();
  };

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_client: "0",
        dropdown_ship: "0",
        dropdown_poNoFilter: "0",
        dropdown_docTypeFilter: "0",
        searchedText: "",
      },
      () => {
        this.filterGridView();
      }
    );
  };

  onDeleteModalYesClick = () => {
    this.deleteMiscellaneousDocData(this.state.rowIdToDelete);
  };


  /* <summary>
  date: 04-03-2023
  Name: SP
  description: Take print of MDSdoc page
  <summary>*/
  onPrintClicked = async (mdsdocId) => {
    let timeout = 0;
    AddLocalStorageData("mdsdocId", mdsdocId); //Fix For mdsdocId When Download Click
    this.setState(
      {
        isHTMLtoPDFDownload: true,
        selectedmdsdocId: mdsdocId,
        isLoading: true,
      },
      async () => {
        timeout = setTimeout(async () => {
          try {
            var element = await document.getElementById("printNewDesign");

            var opt = {
              margin: [15, 5, 15, 5], //top, left, bottom, right,portrait              
              image: { type: "jpeg", quality: 0.30 },
              filename: "MDSDoC_" + mdsdocId + ".pdf",
              html2canvas: { dpi: 300, scale: 4, letterRendering: true },
              pagebreak: {
                avoid: ["tr", "input"],
                before: ".sdoc-form",
              },
              jsPDF: { unit: "pt", format: "a4", orientation: "landscape" },
            };


            var worker = html2pdf();
            worker.set(opt).from(element).toPdf().get("pdf").save();
          } catch (e) {
          } finally {
            this.setState({
              isHTMLtoPDFDownload: false,
              selectedmdsdocId: 0,
              isLoading: false,
              toastVisible: true,
              toastType: helperText.global.toastSuccess,
              toastMessage: helperText.global.downloadSuccess,
            });
            if (timeout) {
              clearTimeout(timeout);
            }
          }
        }, 10000);
      }
    );
  };

  onDocumentModalCloseClick = () => {
    this.setState({ showDocumentUploadModal: false });
  };

  MDSDocEditButtonClick = (mdsdocId) => {
    AddLocalStorageData("mdsdocId", mdsdocId);
    AddLocalStorageData("FilledMdsdoc", "false");
    AddLocalStorageData("location", "SupplierDocument");

    this.navigateTo(`/MDSDoC`);
  };

  navigateTo(path) {
    this.props.navigate(path);
  }

  onSaveMDSDOCDeclarationData = async (mdsdocData) => {
    this.setState({ showDocumentUploadModal: false, isLoading: true });

      mdsdocData.append("fromPage", "Supplier");

    var responseSaved = await SupplierService.SaveUploadMDSDocData(mdsdocData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          isLoading: false,
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.TrackingTool.documnetUploadedSuccess,
        },
        () => {
          this.GetSupplierDocumentData();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
          isLoading: false,
        },
        () => {
          this.GetSupplierDocumentData();
        }
      );
    }
  };

  downloadSupplierTemplate = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const response = await SupplierService.DownloadSupplierTemplate();
      if (response.status == 200) {
        const filePaths = response.data;
        //Use the file paths to generate file URLs
        const fileUrls = filePaths.map((filePath) => {
          var fname = "";
          var lastItem = filePath.split("/").pop();
          fname = lastItem;
          const link = document.createElement("a");

          link.setAttribute("href", filePath);
          link.setAttribute("download", filePath);
          var ext = filePath.substring(filePath.lastIndexOf(".") + 1);
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.download = fname;
          link.click();
          document.body.removeChild(link);
        });
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  onFileDownload = (docs) => {
    try {
      var lastItem = docs.fileMediaPath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = docs.fileMediaPath;
      link.setAttribute("download", docs.fileMediaPath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
      });
    } catch (e) {
    }
  };

  render() {
    const {
      mappedSupplierDocs,
      defaultSupplierDocs,
      dropdownList,
      colDefs,
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      showViewModal,
      filePathToView,
      lstShips,
      dropdown_client,
      dropdown_ship,
      rowIdToDelete,
      accessList,
      poNumberList,
      dropdown_poNoFilter,
      docTypeList,
      dropdown_docTypeFilter,
      isHTMLtoPDFDownload,
      selectedmdsdocId,
      showDocumentUploadModal,
      supplierName,
      supplierId,
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"SupplierDocuments"} />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Training File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
          ></ViewModal>
        )}
        <div className="admin-main-body contact-us-body-header training-parent-class">
          <div className="training-banner" style={{ zIndex: "unset" }}>
            <div className="container-fluid">
              <div className="internal-page-banner-document">
                <div className="container training-banner-box">
                  <a onClick={() => this.props.navigate("/SupplierDashboard")}>
                    <img
                      className="back-btn-responsive"
                      src={supplierback}
                      height="22px"
                      style={{ cursor: "pointer" }}
                    />
                  </a>{" "}
                  <h3>
                    Documents
                    <hr />
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-8"></div>
              <div className="col-12 col-md-6 col-lg-4 heading-top-space">
                <div className="searchBar">
                  <Searchbar
                    placeholder={"PO Number, Client Name, Ship Name..."}
                    onChange={this.onSearch}
                  />
                  <button
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    <img src={magnifyingglass} width="27px" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-md-4 col-lg-2">
                <div className="form-group" id={"clientName"}>
                  <Dropdown
                    dropdownLabel="Client Name:"
                    name="client"
                    onChange={this.onFilterSelected}
                    selected={dropdown_client}
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      overflow: "hidden",
                    }}
                  >
                    {dropdownList.clientList == null
                      ? null
                      : dropdownList.clientList.map((obj, key) => (
                        <option key={key} value={obj.value}>
                          {obj.label}
                        </option>
                      ))}
                    ;
                  </Dropdown>

                  {dropdown_client > 0 ? (
                    <UncontrolledTooltip
                      autohide={false}
                      target={"clientName"}
                      aria-haspopup="true"
                      role="example"
                      effect="solid"
                      placement="bottom"
                      style={{ width: "350px" }}
                    >
                      {dropdownList.clientList != null && dropdownList.clientList.filter(x => x.value == dropdown_client)[0]?.label}
                    </UncontrolledTooltip>) : null}
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-2">
                <div className="form-group" id={"shipName"}>
                  <Dropdown
                    dropdownLabel="Ship Name:"
                    name="ship"
                    onChange={this.onFilterSelected}
                    selected={dropdown_ship}
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      overflow: "hidden",
                    }}
                  >
                    {lstShips.map((obj, key) => (
                      <option key={key} value={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                    ;
                  </Dropdown>
                  {dropdown_ship > 0 ? (
                    <UncontrolledTooltip
                      autohide={false}
                      target={"shipName"}
                      aria-haspopup="true"
                      role="example"
                      effect="solid"
                      placement="bottom"
                      style={{ width: "350px" }}
                    >
                      {lstShips != null && lstShips.filter(x => x.value == dropdown_ship)[0]?.label}
                    </UncontrolledTooltip>) : null}
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-2">
                <div className="form-group" id={"po"}>
                  <Dropdown
                    dropdownLabel="PO Number:"
                    name="poNoFilter"
                    onChange={this.onFilterSelected}
                    selected={dropdown_poNoFilter}
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      overflow: "hidden",
                    }}
                  >
                    {poNumberList == null
                      ? null
                      : poNumberList.map((item) => (
                        <option wrap="true" key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    ;
                  </Dropdown>                  
                  {dropdown_poNoFilter > 0 ? (
                    <UncontrolledTooltip
                      autohide={false}
                      target={"po"}
                      aria-haspopup="true"
                      role="example"
                      effect="solid"
                      placement="bottom"
                      style={{ width: "350px" }}
                    >
                      {lstShips != null && dropdown_poNoFilter}
                    </UncontrolledTooltip>) : null}
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-2">
                <div className="form-group" id={"docType"}>
                  <Dropdown
                    dropdownLabel="Document Type:"
                    name="docTypeFilter"
                    onChange={this.onFilterSelected}
                    selected={dropdown_docTypeFilter}
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      overflow: "hidden",
                    }}
                  >
                    {docTypeList == null
                      ? null
                      : docTypeList.map((item) => (
                        <option wrap="true" key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    ;
                  </Dropdown>
                  {dropdown_poNoFilter > 0 ? (
                    <UncontrolledTooltip
                      autohide={false}
                      target={"docType"}
                      aria-haspopup="true"
                      role="example"
                      effect="solid"
                      placement="bottom"
                      style={{ width: "350px" }}
                    >
                      {docTypeList != null && dropdown_docTypeFilter}
                    </UncontrolledTooltip>) : null}
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="training-reference document d-flex supplierDocumentTemplate">
                  <div className="clear-all-btn">
                    <button
                      type="button"
                      className="btn btn-blue-bg"
                      onClick={this.clearAllFilter}
                    >
                      Reset
                    </button>
                  </div>
                  <button
                    className="btn btn-background-color training-reference-doc"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#editclientupload-modal"
                    onClick={this.downloadSupplierTemplate}
                  >
                    Download Template
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="training-reference document mt-4 d-flex">
                  <h4 className="">Declaration</h4>
                  <button
                    className="btn btn-background-color training-reference-doc"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#editclientupload-modal"
                    onClick={() =>
                      this.setState({ showDocumentUploadModal: true })
                    }
                  >
                    Upload Document
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex training-flexrow document-flexbox">
              {mappedSupplierDocs
                .filter((doc) => doc.isMdsdoc == false)
                .map((docs, index) => (
                  <div
                    key={index}
                    className={
                      "training-flexbox supplierdocumentCard" +
                      (docs.documentStatusId == DOCUMENT_STATUS.COMPLETED
                        ? ""
                        : " pending-document-flexbox")
                    }
                  >
                    {docs.documentStatusId ==
                      DOCUMENT_STATUS.COMPLETED ? null : (
                      <div className="pending-status pending-status-div"></div>
                    )}
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={supplierdownload}
                          alt="document"
                          className="document-training"
                        />
                      </div>
                      <div className="col-10 ">
                        {/* <h4
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          <span data-tooltip={docs.documentType} >
                            {docs.documentType}
                          </span>
                        </h4> */}
                        <h4
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                          id={"doc1" + index}
                        >
                          {docs.documentType}
                          <UncontrolledTooltip
                            autohide={false}
                            target={"doc1" + index}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {docs.documentType}
                          </UncontrolledTooltip>
                        </h4>
                        {/* <div
                          style={{
                            textOverflow: "ellipsis",
                            wrap: "true",
                            whiteSpace: "pre",
                            overflow: "hidden",
                            paddingLeft: "10px",
                          }}
                        >
                          <span data-tooltip={docs.poNumber} style={{display:docs.poNumber != null && docs.poNumber != "" ?"":'none'}}>
                            PO Number:{docs.poNumber}
                          </span>
                        </div> */}
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                            paddingLeft: "10px",
                            display:
                              docs.poNumber != null && docs.poNumber != ""
                                ? ""
                                : "none",
                          }}
                          id={"doc2" + index}
                        >
                          PO Number:{docs.poNumber}
                          <UncontrolledTooltip
                            autohide={false}
                            target={"doc2" + index}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            PO Number:{docs.poNumber}
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex img-position">
                      {/* <a
                        href={docs.fileMediaPath}
                        download={docs.fileMediaName}
                        target="_blank"
                      > */}
                      <img
                        src={admindownload}
                        alt="download"
                        width="28px"
                        className="download-img"
                        onClick={() => this.onFileDownload(docs)}
                      />
                      {/* </a> */}
                    </div>
                  </div>
                ))}
              {mappedSupplierDocs.filter((doc) => doc.isMdsdoc == false)
                .length == 0 ? (
                <p style={{ width: "100%", textAlign: "center" }}>
                  No Rows To Show
                </p>
              ) : null}
            </div>
            <h4 className="training-reference document">MD/SDoC</h4>
            <div className="d-flex training-flexrow mb-4 document-flexbox">
              {mappedSupplierDocs
                .filter((doc) => doc.isMdsdoc == true)
                .map((docs, index) => (
                  <div
                    key={index}
                    className={
                      "training-flexbox supplierdocumentCard" +
                      (docs.documentStatusId == DOCUMENT_STATUS.COMPLETED
                        ? ""
                        : " pending-document-flexbox")
                    }
                  >
                    {docs.documentStatusId ==
                      DOCUMENT_STATUS.COMPLETED || docs.documentStatusId == null ? null : (
                      <div className="pending-status pending-status-div"></div>
                    )}
                    <div className="row">
                      <div className="col-2">
                        <img
                          src={
                            docs.isUploaded
                              ? supplierdownloadpdf
                              : supplierdownload
                          }
                          alt="document"
                          className="document-training"
                        />
                      </div>
                      <div className="col-10">
                        {/* <h4
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          <span data-tooltip={docs.description} className="tooltiptext" >
                            {docs.description}
                          </span>
                        </h4> */}
                        <h4
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                          id={"dvpcp1" + index}
                        >
                          {docs.description}
                          <UncontrolledTooltip
                            autohide={false}
                            target={"dvpcp1" + index}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {docs.description}
                          </UncontrolledTooltip>
                        </h4>
                        {/* <div
                          style={{
                            textOverflow: "ellipsis",
                            wrap: "true",
                            whiteSpace: "pre",
                            overflow: "hidden",
                            paddingLeft: "10px",
                          }}
                        >
                          <span data-tooltip={docs.poNumber}  style={{display:docs.poNumber != null && docs.poNumber != "" ?"":'none'}}>
                            PO Number:{docs.poNumber}
                          </span>{" "}
                        </div> */}
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                            display:
                              docs.poNumber != null && docs.poNumber != ""
                                ? ""
                                : "none",
                          }}
                          id={"dvpcp2" + index}
                        >
                          {" "}
                          PO Number:{docs.poNumber}
                          <UncontrolledTooltip
                            autohide={false}
                            target={"dvpcp2" + index}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            PO Number:{docs.poNumber}
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex img-position">
                      {docs.isUploaded ? (
                        // <a
                        //   href={docs.fileMediaPath}
                        //   download={docs.fileMediaName}
                        //   target="_blank"
                        // >
                        <img
                          style={{ cursor: "pointer" }}
                          src={admindownload}
                          alt="download"
                          width="28px"
                          className="download-img"
                          onClick={() => this.onFileDownload(docs)}
                        />
                      ) : // </a>
                        !docs.isComplete ? (
                          <>
                            <img
                              onClick={() =>
                                this.MDSDocEditButtonClick(docs.mdsdocId)
                              }
                              style={{ cursor: "pointer" }}
                              src={editIcon}
                              alt="edit"
                              width="28px"
                            />
                            <a
                              className="download-img"
                              onClick={() => this.onPrintClicked(docs.mdsdocId)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={admindownload}
                                alt="download"
                                width="28px"
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="download-img"
                              onClick={() => this.onPrintClicked(docs.mdsdocId)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={admindownload}
                                alt="download"
                                width="28px"
                              />
                            </a>
                          </>
                        )}
                    </div>
                  </div>
                ))}
              {mappedSupplierDocs.filter((doc) => doc.isMdsdoc == true)
                .length == 0 ? (
                <p style={{ width: "100%", textAlign: "center" }}>
                  No Rows To Show
                </p>
              ) : null}
            </div>
          </div>

          <Footer />
        </div>

        {isHTMLtoPDFDownload ? (
          <div style={{ display: "none" }}>
            <MDSDoC isHTMLtoPDFDownload={true} mdsdocId={selectedmdsdocId} />
          </div>
        ) : null}

        {showDocumentUploadModal && (
          <SupplierDocumentModal
            onDocumentModalCloseClick={this.onDocumentModalCloseClick}
            supplierName={supplierName}
            supplierId={supplierId}
            TrackingToolId={0}
            onSaveMDSDOCDeclarationData={this.onSaveMDSDOCDeclarationData}
          />
        )}
      </>
    );
  }
}

export default withRouter(SupplierDocument);
