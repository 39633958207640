import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import { helperText } from "../../helpers";
import { SettingsTab, ToastType } from "../../helpers/enums";
import { settingsService } from "../../services/settings.service";
import ActivityLogSettings from "./ActivityLogSettings";
import EmailSettings from "./EmailSettings";
import LoginSettings from "./LoginSettings";
import ReportsSettings from "./ReportsSettings";
import SDORegulationsSettings from "./SDORegulationsSettings";
import SettingsSideBar from "./SettingsSideBar";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(SettingsTab.ACTIVITY_LOG);
  const [toastType, setToastType] = useState(helperText.global.toastSuccess);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [settingsData, setSettingsData] = useState();
  const naviagete = useNavigate();
  const GoBack = () => {
    naviagete("/dashboard");
  };

  /* <summary>
  date: 13-02-2023
  Name: GM
  description: Use effect 
  <summary>*/
  useEffect(() => {
    var isMounted = true;
    const GetSettingsData = async () => {
      setIsLoading(true);
      var response = await settingsService.GetSettingsData();
      setIsLoading(false);
      if (response.status == 200 && isMounted) {
        setSettingsData(response.data);
        //setToastType(helperText.global.toastSuccess);
        //setToastMessage(helperText.SettingsPageText.settingsLoadSucess);
        //setShowToast(true);
      } else {
        setToastType(helperText.global.toastError);
        setToastMessage(helperText.global.somethingWentWrong);
        setShowToast(true);
      }
    };
    GetSettingsData();
    return () => {
      isMounted = false;
    };
  }, [activeTab]);

  const CloseToast = () => {
    setShowToast(false);
  };

  const ToggleLoader = () => {
    setIsLoading((prevState) => !prevState);
  };
  const ShowToastModal = (message, toastType) => {
    setToastType(toastType);
    setToastMessage(message);
    setShowToast(true);
  };

  const OnActiveTabChange = (newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Header />
      {showToast && (
        <Toast
          toastVisible={showToast}
          type={toastType}
          message={toastMessage}
          closeToast={CloseToast}
        />
      )}
      <div className="admin-main-body">
        <div className="container">
          <div className="settings-detail-back">
            <a onClick={GoBack}>
              <img src="images/back button image.png" alt="Back" />
            </a>
            <h5>Settings</h5>
          </div>
          <div className="admin-settings-tab">
            <div className="row">
              <SettingsSideBar
                OnActiveTabChange={OnActiveTabChange}
                activeTab={activeTab}
              />
              <div className="col-md-9">
                {activeTab == SettingsTab.ACTIVITY_LOG &&
                  settingsData != null && (
                    <ActivityLogSettings
                      SettingsData={settingsData}
                      ToggleLoader={ToggleLoader}
                      ShowToastModal={ShowToastModal}
                    />
                  )}
                {activeTab == SettingsTab.EMAIL_SETTINGS && (
                  <EmailSettings
                    SettingsData={settingsData}
                    ToggleLoader={ToggleLoader}
                    ShowToastModal={ShowToastModal}
                  />
                )}
                {activeTab == SettingsTab.SD_REGULATIONS && (
                  <SDORegulationsSettings
                    SettingsData={settingsData}
                    ToggleLoader={ToggleLoader}
                    ShowToastModal={ShowToastModal}
                  />
                )}
                {activeTab == SettingsTab.REPORTS_SETTINGS && (
                  <ReportsSettings
                    SettingsData={settingsData}
                    ToggleLoader={ToggleLoader}
                    ShowToastModal={ShowToastModal}
                  />
                )}
                {activeTab == SettingsTab.LOGIN_SETTINGS && (
                  <LoginSettings
                    SettingsData={settingsData}
                    ToggleLoader={ToggleLoader}
                    ShowToastModal={ShowToastModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Settings;
