import React, { Component } from "react";
//import { Report } from 'powerbi-report-component';
import { changePageTitle, withRouter, isEmailValid, AddLocalStorageData, GetLocalStorageDataWithParse } from "../../helpers";
import Axios from "axios";
import { StatisticsService } from "../../services/statistics.service";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service, Page } from "powerbi-client";
import "./custom.css";
import Dropdown from "../../components/Dropdown";
import StaticFileModal from "./StatisticsReport";


class Statistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statisticsReport: [],
      powerBIReportID: "",
      powerBIGroupId: "",
      tokenData: [],
      embeddedToken: "",
      accessToken: "",
      embeddedUrl: "",
    };
    this.report = null; // to store the loaded report's object to perform operations like print, full screen etc..
  }

  componentDidMount() {
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.getToken();
    }
  }

  getToken = async () => {
    const response = await StatisticsService.GetStatisticsReportToken();
    this.setState({ accessToken: response.data.access_token }, () => {
      //this.getDropdownData(0);
    });
  };

  getDropdownData = async (parValue) => {
    const response = await StatisticsService.GetStatisticsDropdownData();
    if (response.data != null) {
      this.setState({
        statisticsReport: response.data,
        powerBIReportID: response.data[parValue].powerBireportId,
        powerBIGroupId: response.data[parValue].powerBigroupId,
      });
      AddLocalStorageData(
        "powerBIReportID",
        response.data[parValue].powerBireportId
      );

      const reportData = await StatisticsService.GetReport(
        response.data[parValue].powerBireportId,
        this.state.accessToken,
        response.data[parValue].powerBigroupId
      ); //IHMM Stat - e5397e22-2936-4308-9df3-9cb8ad895eb4 //Asif - 75da48f5-1493-4898-8d6b-5a2f942ef852

      if (reportData.data != null) {
        this.setState({ embeddedUrl: reportData.data.embedUrl });
        AddLocalStorageData("embeddedUrl", reportData.data.embedUrl);

        const embeddedTokenData = await StatisticsService.GetEmbeddedTokenData(
          response.data[parValue].powerBireportId,
          this.state.accessToken,
          response.data[parValue].powerBigroupId
        ); //IHMM Stat - e5397e22-2936-4308-9df3-9cb8ad895eb4 //Asif - 75da48f5-1493-4898-8d6b-5a2f942ef852
        if (embeddedTokenData.data != null) {
          this.setState({ embeddedToken: embeddedTokenData.data.token });
          AddLocalStorageData("embeddedToken", embeddedTokenData.data.token);
        }
      }
    }
  };

  onButtonClicked = async (param) => {
    await this.getDropdownData(param);

    this.props.navigate("/StatisticsReport");
  };

  render() {
    const reportStyle = {
      width: 1400,
      height: 700,
    };
    const extraSettings = {
      filterPaneEnabled: true, //true
      navContentPaneEnabled: false, //true
      hideErrors: false, // Use this *only* when you want to override error experience i.e, use onError
    };

    return (
      <div className="root">
        <div className="row" style={{ padding: 20 }}>
          <div className="col-12 col-md-6 custom-column-class">
            <div className="form-group dropdown-form">
              <button
                className="btn btn-block mobile-client-roles-btn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#clientrolespermission"
                onClick={() => this.onButtonClicked(0)}
              >
                Button 1
              </button>

              <button
                className="btn btn-block mobile-client-roles-btn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#clientrolespermission"
                onClick={() => this.onButtonClicked(1)}
              >
                Button 2
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Statistics);
