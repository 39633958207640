import React, { Component, Fragment, useState } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import 'ag-grid-community/styles//ag-theme-balham.css';
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";

//import 'ag-grid-enterprise';
let setDate = null;

function EditIconComponent(props) {
    return (
        <i
            className="fa fa-regular fa-edit"
            style={{ cursor: 'pointer', fontSize: '24px', color: '#FAD02C' }}
        />)
}

function DeleteIconComponent(props) {
    return (
        <i
            className="fa fa-regular fa-trash"
            style={{ cursor: 'pointer', fontSize: '24px', color: 'red' }}
        />)
}

function FileImportNotation(props) {
    return (
        <div>
            <i className="fa fa-solid fa-file fa-5x" style={{ cursor: "pointer", color: "#FAD02C" }} />
            <span className="ag-overlay-loading-center"> Click here to import PO list </span>
        </div>
    )
};

function onChange(e) {
    setDate = convertDate(e);
}

function convertDate(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

var selectedOption = null;
const components = {
    MultiValueContainer: ({ selectProps, data }) => {
        const values = selectProps.value;
        if (values) {
            return values[values.length - 1].label === data.label
                ? data.label
                : data.label + ", ";
        } else return "";
    }
};


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [],
            rowData: [],
            selectedOption: null,
            originalRowData: [],
            copy: null,
            paste: null
        }

    }
    onSortChanged(e) {
        e.api.refreshCells();
      }
    componentDidMount = () => {
        this.setState({
            columnDefs: [
                { headerName: "Sr. No.",  /*field: "srNum", */ valueGetter: "node.rowIndex + 1",width: 130, sortable: true, editable: false, unSortIcon: true, checkboxSelection: true },//, cellRenderer: "pasteFromExcel"
                { headerName: "Month/Year PO ", field: "poMonth", width: 130, sortable: true, editable: false, filter: 'agTextColumnFilter', unSortIcon: true },
                { headerName: "Purchase Order No.", field: "poNum", sortable: true, editable: false, filter: 'agTextColumnFilter', unSortIcon: true }, //floatingFilter: true, //agSetColumnFilter 
                { headerName: "Supplier Details (Co & PIC Name & Branch Name)", field: "suppDetails", sortable: true, editable: false, filter: 'agTextColumnFilter', unSortIcon: true },
                { headerName: "Email ID", field: "email", sortable: true, editable: true, filter: 'agSetColumnFilter', unSortIcon: true, cellEditingStarted: true },
                { headerName: "Line Item No.", field: "lineNo", sortable: true, editable: true, filter: 'agTextColumnFilter', unSortIcon: true },
                { headerName: "Description (Item Name + Maker's Name + Equipment Name if mentioned in PO)", field: "desc", sortable: true, editable: true, filter: 'agTextColumnFilter', unSortIcon: true },
                { headerName: "Qty", field: "qty", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Unit", field: "unit", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "MD & SDOC Received from supplier", field: "mdRcvd", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "MD & SDOC Taken from CTI Dbse", field: "mdTaken", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Date MD, SDoC requested from Suppliers ", field: "mdSupp", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Date of Last Reminder", field: "lastReminder", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "No. of Reminders", field: "reminderCount", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Date the MD & SDOC was received ", field: "mdReceivedDt", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Lab Testing of Samples", field: "labTest", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Contain Hazardous Material (Y/N) Above Threshold Value", field: "abvThreshold", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Hazmat items updated in IHM PART I", field: "ihmPart1", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "TABLE A (PROHIBITED) ", field: "tableA", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "TABLE B HAZMAT (Restricted)", field: "tableB", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "REMARK / STATUS", field: "status", sortable: true, editable: true, filter: 'agNumberColumnFilter', unSortIcon: true },
                { headerName: "Edit", field: "edit", pinned: 'right', width: 70, filter: false, cellRenderer: "editIconComponent", unSortIcon: true },
                { headerName: "Delete", field: "delete", pinned: 'right', width: 90, filter: false, cellRenderer: "deleteIconComponent", unSortIcon: true }
            ],
            rowData: [
                {
                    rowId: 0,
                    srNum: '1',
                    poMonth: '08/04/2022',
                    poNum: 'ACE/V/001747',
                    suppDetails: 'Dintec Singapore Pte Ltd',
                    email: 'james.heo@dintec.com.sg, service@dintec.com.sg',
                    lineNo: '11',
                    desc: '"Item: O-ring Order Title: SPARES FOR AE CONSUMABLES"',
                    qty: '12',
                    unit: 'Pcs',
                    mdRcvd: 'N',
                    mdTaken: 'Y',
                    mdSupp: '11/15/2021',
                    lastReminder: '',
                    reminderCount: '',
                    mdReceivedDt: '11/16/2021',
                    labTest: '',
                    abvThreshold: 'N',
                    ihmPart1: 'N/A',
                    tableA: 'N',
                    tableB: 'N',
                    status: 'Completed',
                    edit: "Edit",
                    delete: "Delete"
                },
            ],

            originalRowData: this.state.rowData,
        });

        window.addEventListener('paste', this.handlePaste);
    }

    // handlePaste = async (e) => {
    //     var oldList = this.state.rowData;
    //     const text = await navigator.clipboard.readText();
    //     var result = "";
    //     var colId = 0;
    //     // // Simplified parsing of the TSV data with hard-coded columns

    //     if (text.includes('Email ID')) {
    //         colId = 4;
    //     }
    //     else if(text.includes('Line Item'))
    //     {
    //         colId = 5;
    //     }

    //     const rows = text.split("\r\n").filter(n => n).slice(1);
    //     result = rows.map((row) => {
    //         if (row != "") {
    //             const cells = row.replace('\n', '').split("\t");
    //             return {
    //                 srNum: colId == 0 ? cells[0] : '',
    //                 poMonth: colId == 1 ? cells[0] : '',
    //                 poNum: colId == 2 ? cells[0] : '',
    //                 suppDetails: colId == 3 ? cells[0] : '',
    //                 email: colId == 4 ? cells[0] : '',
    //                 lineNo: colId == 5 ? cells[0] : '',
    //                 desc: colId == 6 ? cells[0] : '',
    //                 qty: colId == 7 ? cells[0] : '',
    //                 unit: colId == 8 ? cells[0] : '',
    //                 mdRcvd: colId == 9 ? cells[0] : '',
    //                 mdTaken: colId == 10 ? cells[0] : '',
    //                 mdSupp: colId == 11 ? cells[0] : '',
    //                 lastReminder: colId == 12 ? cells[0] : '',
    //                 reminderCount: colId == 13 ? cells[0] : '',
    //                 mdReceivedDt: colId == 14 ? cells[0] : '',
    //                 labTest: colId == 15 ? cells[0] : '',
    //                 abvThreshold: colId == 16 ? cells[0] : '',
    //                 ihmPart1: colId == 17 ? cells[0] : '',
    //                 tableA: colId == 18 ? cells[0] : '',
    //                 tableB: colId == 19 ? cells[0] : '',
    //                 status: colId == 20 ? cells[0] : '',
    //             };
    //         }
    //     });
    //     const finalList = oldList.concat(result);
    //     this.setState({ rowData: finalList });

    // };
    onCellClicked = ((event) => {
        const rowId = event.rowIndex;
        const colId = event.column.colId;
        this.handleColumnPaste(colId, rowId);
    });


    handlePaste = async (e) => {
        var oldList = this.state.rowData;
        const text = await navigator.clipboard.readText();
        var result = "";
        // // Simplified parsing of the data with hard-coded columns

        const rows = text.split("\r\n").filter(n => n);
        result = rows.map((row) => {
            if (row != "") {
                const cells = row.replace('\n', '').split("\t");
                return {
                    srNum: cells[0],
                    poMonth: cells[1],
                    poNum: cells[2],
                    suppDetails: cells[3],
                    email: cells[4],
                    lineNo: cells[5],
                    desc: cells[6],
                    qty: cells[7],
                    unit: cells[8],
                    mdRcvd: cells[9],
                    mdTaken: cells[10],
                    mdSupp: cells[11],
                    lastReminder: cells[12],
                    reminderCount: cells[13],
                    mdReceivedDt: cells[14],
                    labTest: cells[15],
                    abvThreshold: cells[16],
                    ihmPart1: cells[17],
                    tableA: cells[18],
                    tableB: cells[19],
                    status: cells[20],
                };
            }
        });
        const finalList = oldList.concat(result);
        this.setState({ rowData: finalList });
    };

    handleColumnPaste = async (colId, rowId) => {
        var currentList = this.state.rowData;
        const text = await navigator.clipboard.readText();
        // // Simplified parsing of the TSV data with hard-coded columns
        const data = text.split("\r\n").filter(t => t != "" && t != null);


        data.forEach((item) => {
            const cells = item.replace('\n', '').split("\t");
            var record = currentList.filter(t => t.rowId == rowId);
            var recordIndex = currentList.findIndex(t => t.rowId == rowId);
            if (record.length == 0) {
                
                var objNew = [{
                    rowId: rowId,
                    srNum: colId == "srNum" ? cells[0] : "",
                    poMonth: colId == "poMonth" ? cells[0] : "",
                    poNum: colId == "poNum" ? cells[0] : "",
                    suppDetails: colId == "suppDetails" ? cells[0] : "",
                    email: colId == "email" ? cells[0] : "",
                    lineNo: colId == "lineNo" ? cells[0] : "",
                    desc: colId == "desc" ? cells[0] : "",
                    qty: colId == "qty" ? cells[0] : "",
                    unit: colId == "unit" ? cells[0] : "",
                    mdRcvd: colId == "mdRcvd" ? cells[0] : "",
                    mdTaken: colId == "mdTaken" ? cells[0] : "",
                    mdSupp: colId == "mdSupp" ? cells[0] : "",
                    lastReminder: colId == "lastReminder" ? cells[0] : "",
                    reminderCount: colId == "reminderCount" ? cells[0] : "",
                    mdReceivedDt: colId == "mdReceivedDt" ? cells[0] : "",
                    labTest: colId == "labTest" ? cells[0] : "",
                    abvThreshold: colId == "abvThreshold" ? cells[0] : "",
                    ihmPart1: colId == "ihmPart1" ? cells[0] : "",
                    tableA: colId == "tableA" ? cells[0] : "",
                    tableB: colId == "tableB" ? cells[0] : "",
                    status: colId == "status" ? cells[0] : "",
                }];
                currentList = currentList.concat(objNew).sort((a, b) => (a.rowId - b.rowId));
                this.gridApi.applyTransaction({ add: objNew })

            }
            else {

                var updateRecord = record;
                updateRecord[0][colId] = cells[0];
                currentList = currentList.filter(t => t.rowId != updateRecord[0].rowId);
                currentList = currentList.concat(updateRecord).sort((a, b) => (a.rowId - b.rowId));

                this.gridApi.applyTransaction({ update: updateRecord })
            }
            //console.log(this.state.rowData);
            rowId++;
        });
        this.setState({ rowData: currentList }, () => {
            console.log(this.state.rowData);
        });
    };

    onSelect(selectedList, selectedItem) {
        var selectedListItem = selectedList;
        var selectedItem = selectedItem;
    }

    onRemove(selectedList, removedItem) {
        var selectedListItem = selectedList;
        var selectedItem = removedItem;
    }

    handleChange = (selectedOptiondd) => {
        this.setState({
            selectedOption: selectedOptiondd,
        });
        selectedOption = selectedOptiondd;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    AddRecordClicked = () => {
        var currentList = this.state.rowData;
        var maxId = 1;
        if (currentList.length > 0) {
            maxId = parseInt(currentList.sort((a, b) => (b.rowId - a.rowId))[0].rowId, 10);
        }
        
        var newRecord = {
            rowId: maxId + 1,
            srNum: '',
            poMonth: '',
            poNum: '',
            suppDetails: '',
            email: '',
            lineNo: '',
            desc: '',
            qty: '',
            unit: '',
            mdRcvd: '',
            mdTaken: '',
            mdSupp: '',
            lastReminder: '',
            reminderCount: '',
            mdReceivedDt: '',
            labTest: '',
            abvThreshold: '',
            ihmPart1: '',
            tableA: '',
            tableB: '',
            status: '',
            edit: "Edit",
            delete: "Delete"
        };
        this.gridApi.applyTransaction({ add: [newRecord] })
        currentList.push(newRecord);
        this.setState({ rowData: currentList });
        console.log(this.state.rowData);
    }



    render() {
        return (
            <div>
                <Header />
                {/* <div className="col-12 col-md-2 col-lg-1 email-template-bottomspace">
                    <label className="label-class" for="copy">Copy:</label>
                </div>
                <div className="col-12 col-md-10 col-lg-11  email-template-bottomspace">
                    <input className="field-width" type="text" id="copy" name="copy" value={this.state.copy}
                        onChange={this.onCopyChange.bind(this)} />
                    <br />
                </div>

                <div className="col-12 col-md-2 col-lg-1 email-template-bottomspace">
                    <label className="label-class" for="paste">Paste:</label>
                </div>
                <div className="col-12 col-md-10 col-lg-11  email-template-bottomspace">
                    <input className="field-width" type="text" id="paste" name="paste" value={this.state.paste}
                        onChange={this.onPasteChange.bind(this)} />
                    <br />
                </div> */}

               
                <button type="button" onClick={this.AddRecordClicked}>Add Record</button>
                <div
                    className="ag-theme-alpine"
                    style={{
                        height: '500px',
                        width: '1500px',
                        padding: 10,
                        marginTop: 50,
                    }}
                //onPaste={this.handlePaste}
                //onPasteCapture={this.handlePaste}
                >
                    {/* <div onPaste={this.handlePaste} onClick={this.handlePaste} style={{ height: 20, color: 'red', flex: 1, margin: 20 }}>
                        <i className="fa fa-regular fa-paste" style={{ cursor: 'pointer', fontSize: '24px', color: 'red' }} />
                    </div> */}
                    <AgGridReact
                        headerHeight={40}
                        rowHeight={50}
                        frameworkComponents={{
                            editIconComponent: EditIconComponent,
                            deleteIconComponent: DeleteIconComponent,
                        }}
                        //onPasteStart={this.handlePaste}
                        //onPaste={this.handlePaste}
                        suppressMenuHide={true}
                        suppressAggFilteredOnly={true}
                        pagination={true}
                        paginationPageSize={10}
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        suppressNoRowsOverlay={false}
                        rowSelection='multiple'
                        checkboxSelection={true}
                        // rowMultiSelectWithClick={true}
                        // ensureDomOrder={true}
                        // enableCellTextSelection={true}
                        // enableRangeSelection={true}
                        // suppressCopySingleCellRanges={true}
                        overlayNoRowsTemplate={'<div><i className="fa fa-solid fa-file fa-3x" style={{ cursor: "pointer", color: "#FAD02C" }}/> </br></br> <span className= "ag-overlay-loading-center"> Click here to import PO list </span></div>'}
                        animateRows={true}
                        onGridReady={this.onGridReady}
                        onCellContextMenu={(e) => this.onCellClicked(e)}
                        onSortChanged={(e) => this.onSortChanged(e)}
                    >
                    </AgGridReact>
                </div >
                {/* <Footer /> */}
            </div>
        );
    }
}
